import React from 'react';
import { PropertyUnitsSummary } from '../../../types/generated-types';
import Typography from '@mui/material/Typography';

export interface AlertsIndicatorProps {
  unitsSummary?: PropertyUnitsSummary | null;
}

export function AlertsIndicator(props: AlertsIndicatorProps) {
  const { unitsSummary } = props;
  const { majorAlerts, minorAlerts } = unitsSummary || {};
  const commonStyles = { color: '#636363', fontSize: 14 };
  return (
    <div style={{ marginTop: '10px' }}>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: majorAlerts || 0 ? 500 : 300,
          color: '#636363', // majorAlerts || 0 ? '#FF2222' : '#636363',
        }}
      >
        {'Major Alerts: ' + majorAlerts}
      </Typography>
      <Typography
        sx={{ fontWeight: minorAlerts || 0 ? 500 : 300, ...commonStyles }}
      >
        {'Minor Alerts: ' + minorAlerts}
      </Typography>
    </div>
  );
}
