import * as React from 'react';
import { useZonePolicyDetailBreadcrumbQuery } from '../../../types/generated-types';

export function ZonePolicyDetailBreadcrumb({ id }: { id?: string }) {
  const { data } = useZonePolicyDetailBreadcrumbQuery({
    variables: { id: id || '' },
  });

  return (
    <span>{`${
      id
        ? data?.zonePolicyById?.name || 'un-named zone policy'
        : 'New Zone Policy'
    }`}</span>
  );
}
