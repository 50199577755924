import React, { useEffect } from 'react';
import { ThermostatScheduleTemplate } from '../../../types/generated-types';
import { ThermostatScheduleStatistics } from '../devices/thermostat/types/thermostat-schedule-statistics';
import BorderedSection from './borderedSection';

interface VerifyUpdateScheduleTemplateProps {
  thermostatScheduleTemplateId: ThermostatScheduleTemplate['_id'];
  scheduleStats: ThermostatScheduleStatistics;
  scheduleUpdateStats: any;
}

export const VerifyUpdateScheduleTemplate = (
  props: VerifyUpdateScheduleTemplateProps,
) => {
  const { scheduleStats, scheduleUpdateStats } = props;

  return (
    <div>
      <h3>Should you proceed, your update will have the following impact:</h3>
      <div style={{ margin: '16px 0px' }}>
        <BorderedSection title="Updated Thermostats">
          <ul style={{ paddingLeft: '16px', margin: 0 }}>
            <li>
              <strong>All:</strong> {scheduleStats.totalThermostats}
            </li>
            <li>
              <strong>Installed:</strong> {scheduleStats.installedThermostats}
            </li>
            <li>
              <strong>Installed and Online:</strong>{' '}
              <span>
                {scheduleStats.installedOnlineThermostats.length}
                {'+'}
              </span>
            </li>
            <li>
              <strong>Online and Using Custom Schedule:</strong>{' '}
              {
                scheduleStats.installedOnlineThermostatsAndUsesCustomSchedule
                  .length
              }
            </li>
            <li>
              <strong>Online and Using Schedule Template:</strong>{' '}
              <span>
                {
                  scheduleStats
                    .installedOnlineThermostatsAndUsesScheduleTemplate.length
                }
              </span>
            </li>
            <li>
              <strong>Online and Using Property-Level Template:</strong>{' '}
              <span>
                {
                  scheduleStats.thermostatsUsingPropertyLevelScheduleTemplate
                    ?.length
                }
              </span>
            </li>
            <li>
              <strong>Online and Using Unit-Level Template:</strong>{' '}
              <span>
                {
                  scheduleStats.thermostatsUsingUnitLevelScheduleTemplate
                    ?.length
                }
              </span>
            </li>
            <li>
              <strong>Net Thermostats to be Updated:</strong>{' '}
              <span>{scheduleUpdateStats.length}</span>
            </li>
          </ul>
        </BorderedSection>
      </div>
      <BorderedSection title="Updated Units">
        <ul style={{ paddingLeft: '16px', margin: 0 }}>
          <li>
            <strong>All:</strong> {scheduleStats?.totalUnits ?? 'N/A'}
          </li>
          <li>
            <strong>Net Units to be Updated:</strong>{' '}
            {scheduleStats?.unitsWithAppliedThermostatScheduleTemplate ?? 'N/A'}
          </li>
        </ul>
      </BorderedSection>
    </div>
  );
};
