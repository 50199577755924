import React from 'react';
/* MUI */
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
/* Icons */
import CancelIcon from '@mui/icons-material/CancelOutlined';
import SearchIcon from '@mui/icons-material/Search';

export interface SearchTextProps {
  handleChange: (s: string) => void;
  padding: string;
}

export const SearchText = (props: SearchTextProps) => {
  const { handleChange, padding } = props;
  const [searchTerm, setSearchTerm] = React.useState('');
  const onChange = (term: string) => {
    setSearchTerm(term);
    handleChange(term);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    handleChange('');
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f3f2f2',
        padding: `10px ${padding}`,
      }}
    >
      <TextField
        id="standard-search"
        label="Search"
        type="search"
        color="primary"
        fullWidth
        value={searchTerm}
        size={'small'}
        variant="outlined"
        onChange={(e) => {
          onChange(e.target.value);
        }}
        sx={{
          borderRadius: '4px',
          background: '#fff',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClearSearch}
              edge="end"
            >
              {searchTerm.length ? <CancelIcon /> : null}
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};
