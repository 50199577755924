import * as React from 'react';
import {
  useZonePolicyCountQuery,
  useZonePolicyListUpdateSubscription,
} from '../../../types/generated-types';
import { updateCacheFromSubscriptionEvent } from '../../../helpers/subscriptionUtils';

export function ZonePoliciesBreadcrumb() {
  useZonePolicyListUpdateSubscription({
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });
  const { data } = useZonePolicyCountQuery();

  return (
    <span>
      Zone Policies (
      {`${data?.zonePolicyCount ? data.zonePolicyCount : 'No Zone Policies'}`})
    </span>
  );
}
