import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Form, Formik, FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { Notifier } from '../../../system/services/notificationManager';
import { useAuthenticator } from '../../../auth/AuthenticationContext';

import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import BorderedSection from '../../shared/borderedSection';

import { processAlertTriggerSelectionCriterion } from '../property-alert-triggers-list';

import {
  AlertTrigger,
  CreateAlertTriggerMutationVariables,
  UpdateAlertTriggerMutationVariables,
  useCreateAlertTriggerMutation,
  useUpdateAlertTriggerMutation,
} from '../../../../types/generated-types';

import {
  AlertTriggerEditMode,
  AlertTriggerSelectionBasis,
} from '../../../../common/types';

import { FormValues } from '../types/propertyAlertTriggerDetailFormValues';
import { EditableAlertTriggerSelectionCriteria } from '../types/propertyAlertTriggerRowData';

export const PropertyAlertTriggerDetailForm = ({
  alertTrigger,
}: {
  alertTrigger: Partial<AlertTrigger>;
}) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user } = useAuthenticator();

  const [updateAlertTrigger] = useUpdateAlertTriggerMutation();
  const [createAlertTrigger] = useCreateAlertTriggerMutation();

  const initialValues: FormValues = {
    name: alertTrigger?.name ?? '',
    entityTypes: alertTrigger.entityTypes ?? [],
    triggerSelectionBasis:
      (alertTrigger.triggerCriteria
        ?.basisType as unknown as AlertTriggerSelectionBasis) ??
      AlertTriggerSelectionBasis.DEVICE,
    triggerCriteria: (alertTrigger.triggerCriteria?.selectionCriteria ?? [])
      .filter((criterion) => criterion !== undefined)
      .map((criterion) =>
        criterion
          ? processAlertTriggerSelectionCriterion(
              criterion,
              user,
              alertTrigger.triggerCriteria?.basisType,
            )?.convertedCriterion
          : undefined,
      ) as EditableAlertTriggerSelectionCriteria[],
    editMode: (alertTrigger.editMode ?? 0) as AlertTriggerEditMode,
  };

  const onSubmit = (
    values: FormValues,
    _helpers: FormikHelpers<FormValues>,
  ) => {
    if (alertTrigger?._id) {
      const alertTriggerData: UpdateAlertTriggerMutationVariables['alertTrigger'] =
        {
          name: values.name,
          entityTypes: alertTrigger.entityTypes ?? [],
          triggerCriteria: alertTrigger.triggerCriteria,
          editMode: alertTrigger.editMode ?? 0,
          propertyId: id,
        };

      updateAlertTrigger({
        variables: { id: alertTrigger._id, alertTrigger: alertTriggerData },
      })
        .then(() => {
          formik.setSubmitting(false);
          Notifier.success('AlertTrigger updated successfully');
          navigate(-1);
          // navigate(`/properties/${id}/alertTriggers`);
        })
        .catch((updateError) => {
          formik.setSubmitting(false);
          Notifier.error(updateError.message);
        });
    } else {
      const alertTriggerData: CreateAlertTriggerMutationVariables['alertTrigger'] =
        {
          name: values.name,
          entityTypes: alertTrigger.entityTypes ?? [],
          triggerCriteria: alertTrigger.triggerCriteria,
          editMode: alertTrigger.editMode ?? 0,
          propertyId: id,
        };

      createAlertTrigger({ variables: { alertTrigger: alertTriggerData } })
        .then(() => {
          formik.setSubmitting(false);
          Notifier.success('AlertTrigger added successfully');
          navigate(-1);
          // navigate(`/properties/${id}/alertTriggers`);
        })
        .catch((createError) => {
          formik.setSubmitting(false);
          Notifier.error(createError.message);
        });
    }
  };

  const validationSchema = yup.object({
    name: yup.string().required('AlertTrigger title is required'),
  });

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form
          style={{
            width: '100%',
            fontSize: '16px',
            height: '100%',
          }}
          onSubmit={formik.handleSubmit}
        >
          <div>
            <BorderedSection title="Alert Trigger">
              <TextField
                disabled={formik.values.editMode < 1}
                fullWidth
                autoFocus
                required
                id="name"
                name="name"
                variant="standard"
                label="Name"
                value={formik.values.name}
                onChange={(e) => {
                  formik.setFieldTouched('name', true);
                  formik.handleChange(e);
                }}
              />
              <br />
              <br />
              <TextField
                fullWidth
                disabled={formik.values.editMode < 1}
                autoFocus
                required
                id="entityType"
                name="entityType"
                variant="standard"
                label="Type"
                value={formik.values.entityTypes.join(', ')}
                onChange={(e) => {
                  formik.setFieldTouched('entityType', true);
                  formik.handleChange(e);
                }}
              />
              <BorderedSection title="Trigger Criteria">
                <div>hello</div>
              </BorderedSection>
            </BorderedSection>
          </div>
          <br />
          <div
            style={{
              marginLeft: '10px',
            }}
          >
            <Button
              disabled={!formik.isValid || !formik.dirty}
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ color: '#fff' }}
            >
              Save
            </Button>
            <Button
              sx={{ marginLeft: '10px' }}
              variant="text"
              onClick={() => navigate(-1)}
              color={!formik.isValid || !formik.dirty ? 'secondary' : 'primary'}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
