import { Icon } from '@mui/material';
import autoLogout from '../../../../assets/timed-exit.svg';
import React from 'react';

interface AutoLogoutIconProps {
  children?: React.ReactNode;
}

export const AutoLogoutIcon = ({ children }: AutoLogoutIconProps) => {
  return (
    <Icon
      sx={{
        height: '100%',
        pb: '.25rem',
        fontSize: 'inherit',
        textAlign: 'center',
        marginLeft: '-4px',
        flex: '1 1 auto',
        display: 'flex',
      }}
    >
      <img width="36px" src={autoLogout} alt="Inactivity logout in ..." />
      <span>{children}</span>
    </Icon>
  );
};
