import React from 'react';

import { RouteObject } from 'react-router-dom';
import {
  IndexRouteObject,
  NonIndexRouteObject,
} from 'react-router/dist/lib/context';

import {
  BreadcrumbComponentType,
  BreadcrumbsRoute,
} from 'use-react-router-breadcrumbs';

import { Actions, SubjectTypes } from '../auth/types/ability';
import { IAuthorizedRoute } from '../auth/types/can';

import { PropertiesMain } from '../features/properties/properties-main';
import { PropertiesList } from '../features/properties/properties-list';
import { PropertyDetail } from '../features/properties/property-detail';
import { PropertyView } from '../features/properties/ui/view/property-view';
import {
  PropertyDetailBreadcrumb,
  // EditPropertyDetailBreadcrumb,
} from '../features/properties/property-detail-breadcrumbs';

import { PropertyDetailHandler } from '../features/properties/ui/property-detail-handler';
import { UnitsMain } from '../features/units/units-main';
import { AlertTriggerDetailHandler } from '../features/properties/ui/alert-trigger-detail-handler';
import { PropertyAlertTriggerDetail } from '../features/properties/property-alert-trigger-detail';
import { PropertyBuildingDetail } from '../features/properties/property-building-detail';
import { UnitDetailHandler } from '../features/units/ui/unit-detail-handler';
import { UnitView } from '../features/units/ui/unit-view';
import { BuildingDetailHandler } from '../features/properties/ui/building-detail-handler';
import { PropertyScheduleList } from '../features/properties/components/property-schedule-list';
import { ScheduleTemplateList } from '../features/units/schedule/views/schedule-template-list';
import { ScheduleDetailHandler } from '../features/units/schedule/views/schedule-detail-handler';

type ParentBreadcrumbsRoute<ParamKey extends string = string> = (RouteObject &
  IAuthorizedRoute) & {
  breadcrumb?: BreadcrumbComponentType<ParamKey> | string | null;
} & {
  children: (
    | (IndexRouteObject & {
        authAction?: Actions;
        authType: SubjectTypes;
        breadcrumb?: BreadcrumbComponentType<ParamKey> | string | null;
      })
    | (NonIndexRouteObject & {
        authAction?: Actions;
        authType?: SubjectTypes;
        breadcrumb?: BreadcrumbComponentType<ParamKey> | string | null;
      })
  )[];
};

export const propertyRoutes: ParentBreadcrumbsRoute[] = [
  {
    path: '/',
    element: <PropertiesMain />,
    children: [
      {
        index: true,
        element: <PropertiesList />,
        authType: 'Property',
      },
      {
        path: ':id/view-property',
        element: <PropertyView />,
        authType: 'Property',
        authAction: 'view',
      },
      {
        path: ':id/schedule',
        element: <PropertyScheduleList />,
        authType: 'Property',
        authAction: 'view',
      },
      {
        path: ':id/schedule/templates',
        element: <ScheduleTemplateList mode="manage" />,
        authType: 'Property',
        authAction: 'view',
      },
      {
        path: ':id/schedule/templates/add',
        element: <ScheduleDetailHandler />,
        authType: 'Property',
        authAction: 'view',
      },
      {
        path: ':id/schedule/templates/:templateId/edit',
        element: <ScheduleDetailHandler />,
        authType: 'Property',
        authAction: 'view',
      },
      {
        path: ':id/edit-property',
        element: <PropertyDetailHandler />,
        authType: 'Property',
        authAction: 'update',
      },
      {
        path: ':id/edit-property/alerts/alert-triggers/:tid/edit-alert-trigger',
        element: <AlertTriggerDetailHandler />,
        authType: 'AlertTrigger',
        authAction: 'update',
      },
      {
        path: ':id/edit-property/alerts/alert-triggers/:tid/view-alert-trigger',
        element: <PropertyAlertTriggerDetail />,
        authType: 'AlertTrigger',
        authAction: 'view',
      },
      {
        path: ':id/edit-property/:viewId',
        element: <PropertyDetailHandler />,
        authType: 'Property',
        authAction: 'update',
      },
      {
        path: ':id/edit-property/:viewId/:alertViewId',
        element: <PropertyDetailHandler />,
        authType: 'Property',
        authAction: 'update',
      },
      {
        path: ':id/buildings/:bid',
        element: <PropertyBuildingDetail />,
        authType: 'Building',
        authAction: 'view',
      },
      {
        path: '/:id/units/add-unit',
        element: <UnitDetailHandler />,
        authType: 'Property',
        authAction: 'createUnit',
      },
      {
        path: ':id/units/:unitId/edit-unit',
        element: <UnitDetailHandler />,
        authType: 'Unit',
        authAction: 'update',
      },
      {
        path: ':id/units/:unitId/edit-unit/:viewId',
        element: <UnitDetailHandler />,
        authType: 'Unit',
        authAction: 'update',
      },
      {
        path: ':id/units/:unitId/view-unit',
        element: <UnitView />,
        authType: 'Property',
        authAction: 'view',
      },
      {
        path: ':id/buildings/add-building',
        element: <BuildingDetailHandler />,
        authType: 'Property',
        authAction: 'createBuilding',
      },
      {
        path: ':id/buildings/:bid/edit-building',
        element: <BuildingDetailHandler />,
        authType: 'Building',
        authAction: 'update',
      },
      {
        path: ':id/*',
        element: <PropertyDetail />,
        authType: 'Property',
        authAction: 'view',
        state: {
          ignore: [
            /^\/properties\/[a-zA-Z0-9]+\/$/,
            /^\/properties\/[a-zA-Z0-9]+$/,
          ],
        },
      },
      {
        path: 'create',
        element: <PropertyDetailHandler />,
        authType: 'Property',
        authAction: 'create',
      },
    ],
  },
];

export const propertyBreadcrumbRoutes: (BreadcrumbsRoute & { state?: any })[] =
  [
    {
      path: '/properties',
      element: <PropertiesMain />,
      breadcrumb: () => <span>Properties</span>,
    },
    {
      path: '/properties/create',
      element: <PropertyDetailHandler />,
      breadcrumb: () => <span>Create Property</span>,
    },
    {
      path: '/properties/:id',
      element: <PropertyDetail />,
      breadcrumb: ({
        match: {
          params: { id },
        },
      }) => <PropertyDetailBreadcrumb id={id} />,
      state: {
        ignore: [
          /^\/properties\/[a-zA-Z0-9]+$/,
          /^\/properties\/[a-zA-Z0-9]+\/$/,
        ],
      },
    },
    // TODO: Peter: do we need this?
    // {
    //   path: '/properties/:id/edit-property',
    //   element: <PropertyDetailHandler />,
    //   breadcrumb: ({
    //     match: {
    //       params: { id },
    //     },
    //   }) => <EditPropertyDetailBreadcrumb id={id} />,
    // },
    {
      path: '/properties/:id/installer',
      element: <UnitsMain />,
      breadcrumb: () => <span>Units Installer List</span>,
      state: {
        ignore: [
          /^\/properties\/[a-zA-Z0-9]+\/installer$/,
          /^\/properties\/[a-zA-Z0-9]+\/installer\/$/,
        ],
      },
    },
  ];
