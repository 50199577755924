import React, { CSSProperties, useEffect, useState } from 'react';
import {
  Thermistor,
  useThermistorDetailQuery,
  useThermistorDetailUpdateSubscription,
} from '../../../../types/generated-types';
import BorderedSection from '../../shared/borderedSection';
import { DisplayValue } from '../shared-ui';
import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
import {
  Celsius,
  Fahrenheit,
  TemperatureUnit,
} from '../../../system/models/temperatureUnits';

export function ThermistorPairComponent({
  supplyId,
  returnId,
  label,
  style = {},
  preferredUnits = Fahrenheit,
}: {
  supplyId: string;
  returnId: string;
  label: string;
  style?: CSSProperties | undefined;
  preferredUnits: TemperatureUnit;
}) {
  const [supplyDevice, setSupplyDevice] = useState<Partial<Thermistor>>();
  const [returnDevice, setReturnDevice] = useState<Partial<Thermistor>>();

  const [delta, setDelta] = useState<number>();

  const {
    data: supplyThermistor,
    // TODO: Loading_error: should we handle loading/error here for this query?
    // loading: loadingSupply,
    // error: supplyError,
  } = useThermistorDetailQuery({
    variables: { id: supplyId },
  });

  const {
    data: returnThermistor,
    // TODO: Loading_error: should we handle loading/error here for this query?
    // loading: loadingReturn,
    // error: returnError,
  } = useThermistorDetailQuery({
    variables: { id: returnId },
  });

  useThermistorDetailUpdateSubscription({
    variables: { ids: [supplyId, returnId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (supplyThermistor?.thermistorById) {
      setSupplyDevice(supplyThermistor.thermistorById as Partial<Thermistor>);
    }
  }, [supplyThermistor]);

  useEffect(() => {
    if (returnThermistor?.thermistorById) {
      setReturnDevice(returnThermistor.thermistorById as Partial<Thermistor>);
    }
  }, [returnThermistor]);

  useEffect(() => {
    if (
      supplyDevice?.temperature?.value !== undefined &&
      supplyDevice?.temperature?.value !== null &&
      returnDevice?.temperature?.value !== undefined &&
      returnDevice?.temperature?.value !== null
    ) {
      setDelta(
        Math.abs(
          supplyDevice.temperature.value - returnDevice.temperature.value,
        ),
      );
    }
  }, [supplyDevice, returnDevice]);

  return delta !== undefined && delta !== null ? (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        ...style,
      }}
    >
      <BorderedSection
        title={label}
        labelStyle={{ fontSize: '10px' }}
        style={{ height: '45px' }}
        ccStyle={{ padding: '6px' }}
      >
        <DisplayValue
          value={preferredUnits === Celsius ? delta : (delta * 9) / 5}
          units={preferredUnits}
          valueSize={'16px'}
          unitsSize={'12px'}
          style={{ width: '100%', textAlign: 'center' }}
          blankZeros={true}
        />
      </BorderedSection>
    </div>
  ) : (
    <DeviceLoadingSkeleton sx={{ margin: '2px' }} />
  );
}
