import React, { useEffect } from 'react';
import {
  User,
  useRemoveUserMutation,
  useUserDetailQuery,
} from '../../../types/generated-types';
import { useNavigate, useParams } from 'react-router-dom';
import { UserDetailForm } from './ui/user-detail-form';
import FullWidthLoadingSkeleton from '../shared/fullWidthLoadingSkeleton';
import { Notifier } from '../../system/services/notificationManager';
import { useInjectableComponents } from '../../system/services/injectableComponentsManager';
import { Remove } from '@mui/icons-material';

export const UserDetail = () => {
  const { userId } = useParams<{ userId: string }>();
  const {
    data: userData,
    loading: userDataLoading,
    error: userDataError,
  } = useUserDetailQuery({
    variables: { userId: userId || '' },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (userDataError) {
      Notifier.error(
        'There was a problem fetching the user information: ',
        userDataError,
      );
    }
  }, [userDataError]);

  const navigate = useNavigate();

  const [
    deleteUser,
    {
      // TODO: Loading_error: should we be handling loading here?
      // data,
      // loading,
      error,
    },
  ] = useRemoveUserMutation({
    refetchQueries: ['UserList'],
  });

  useEffect(() => {
    if (userDataError) {
      Notifier.error('There was a problem removing the selected user: ', error);
    }
  }, [error]);

  const handleRemoveUser = async () => {
    if (userId) {
      try {
        await deleteUser({
          variables: { userId },
        });
        Notifier.success('User profile deleted');
        navigate('/users');
      } catch (e) {
        Notifier.error('Unable to delete user profile');
      }
      Notifier.success('User profile deleted');
    } else {
      Notifier.error('No user profile id found: Check URL parameter');
    }
  };

  const confirmableAction = {
    actionLabel: 'Remove',
    cancelLabel: 'Cancel',
    title: 'Remove User Profile',
    prompt: 'Are you sure? This action cannot be reversed',
    action: handleRemoveUser,
  };

  const { setContextMenuItems } = useInjectableComponents();

  useEffect(() => {
    if (userData?.userById) {
      setContextMenuItems([
        {
          items: [
            {
              id: 'remove-user-menu-item',
              label: 'Remove User',
              icon: <Remove fontSize="small" />,
              confirmableAction,
              permit: {
                action: 'delete',
                subject: userData.userById as User,
              },
            },
          ],
        },
      ]);
    } else {
      setContextMenuItems(undefined);
    }
    return () => {
      setContextMenuItems(undefined);
    };
  }, [userData]);

  return userDataLoading ? (
    <FullWidthLoadingSkeleton />
  ) : userData?.userById ? (
    <UserDetailForm user={userData.userById as Partial<User>} />
  ) : userId === undefined ? (
    <UserDetailForm user={{} as Partial<User>} />
  ) : (
    <div>No data</div>
  );
};

// TODO: Peter: keeping this for now. I might decide to create a 'common' user input
//  field template from this.
// const StyledInput = styled.input`
//   height: 1.7em;
//   padding: 0.75em;
//   margin: 0.5em 0;
//   font-family: Arial;
//   font-size: 16px;
//   width: 80%;
//   border: 1px solid #aaaaaa;
// `;
//
// export const FormField = ({
//   field,
//   form,
//   helperText,
//   ...props
// }: {
//   field: FieldInputProps<any>;
//   form: FormikState<any>;
//   helperText?: string;
// }) => {
//   useEffect(() => {
//     console.log('formik', props);
//   }, [props]);
//   return (
//     <FormControl
//       variant="standard"
//       sx={{ m: 1, minWidth: 120, width: '100%', fontSize: '16px' }}
//     >
//       <>
//         <InputLabel
//           id={`${field.name}-label`}
//           style={{ transform: 'translate(0, -12.5px) scale(0.75)' }}
//         >
//           {capitalize(field.name)}
//         </InputLabel>
//         <StyledInput {...field} {...props} />
//         {helperText && (
//           <InputLabel
//             id={`${field.name}-help`}
//             style={{ transform: 'translate(0, 12.5px) scale(0.75)' }}
//           >
//             {helperText}
//           </InputLabel>
//         )}
//       </>
//     </FormControl>
//   );
// };
//
// export const SelectField = ({
//   field,
//   form,
//   ...props
// }: {
//   field: FieldInputProps<any>;
//   form: FormikState<any>;
// }) => {
//   return (
//     <FormControl
//       variant="standard"
//       sx={{
//         m: 1,
//         minWidth: 120,
//         width: '80%',
//         fontSize: '16px',
//         marginTop: '-2px',
//       }}
//     >
//       <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
//       <Select
//         labelId="demo-simple-select-standard-label"
//         id="status"
//         name={field.name}
//         value={field.value}
//         onChange={field.onChange}
//         label="Status"
//         style={{
//           border: '1px solid #aaaaaa',
//           paddingLeft: '0.75em',
//           height: '1.7em',
//           marginTop: '18px',
//         }}
//       >
//         <MenuItem value={'new'}>New</MenuItem>
//         <MenuItem value={'active'}>Active</MenuItem>
//         <MenuItem value={'inactive'}>Inactive</MenuItem>
//       </Select>
//     </FormControl>
//   );
// };
