import _ from 'lodash';
import { MyWeeklySchedule } from '../thermostat/types';

type MyMinMax = {
  min?: number;
  max?: number;
};

export const applySetpointLimitToWeeklyScheduleValues = (
  suppliedWeeklySchedule: MyWeeklySchedule | undefined,
  limits: {
    cool: MyMinMax;
    heat: MyMinMax;
  },
) => {
  const schedule = _.cloneDeep(suppliedWeeklySchedule);

  if (schedule) {
    // Loop through days of week
    for (const day in schedule) {
      // Loop through transitions in this day
      for (const start in schedule[day as keyof MyWeeklySchedule]) {
        // Convert setpoint min/max to Celsius with desired digits of precision
        const setpoint = schedule[day as keyof MyWeeklySchedule][start];

        // Cool setpoint
        if (setpoint?.maximum) {
          if (limits.cool?.max) {
            if (setpoint.maximum > limits.cool.max)
              setpoint.maximum = limits.cool.max;
          }
          if (limits.cool?.min) {
            if (setpoint.maximum < limits.cool.min)
              setpoint.maximum = limits.cool.min;
          }
        }
        // Heat setpoint
        if (setpoint?.minimum) {
          if (limits.heat?.max) {
            if (setpoint.minimum > limits.heat.max)
              setpoint.minimum = limits.heat.max;
          }
          if (limits.heat?.min) {
            if (setpoint.minimum < limits.heat.min)
              setpoint.minimum = limits.heat.min;
          }
        }
      }
    }
  }

  return schedule;
};
