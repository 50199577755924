import _ from 'lodash';
import { TemperatureUnit } from '../../../system/models/temperatureUnits';
import {
  DayOfWeek,
  EmptyWeeklySchedule,
  MyDailySchedule,
  MyWeeklySchedule,
} from '../thermostat/types';
import { convertToTempUnits, convertToCelsius } from './convert-temp-units';

// Formats the current daily schedule with preferred units
export const convertToPreferredDailyScheduleUnits = (
  dailySchedule: MyDailySchedule,
  targetUnit: TemperatureUnit,
  currentUnits: TemperatureUnit,
) => {
  let scheduleUpdate: MyDailySchedule = _.cloneDeep(EmptyWeeklySchedule['0']);

  for (const transition in dailySchedule) {
    const scheduleSetting = dailySchedule[transition];
    scheduleUpdate[transition] = {};
    if (scheduleSetting.maximum !== undefined) {
      scheduleUpdate[transition].maximum = convertToTempUnits(
        scheduleSetting.maximum,
        targetUnit,
        currentUnits,
        2,
      );
    }

    if (scheduleSetting.minimum !== undefined) {
      scheduleUpdate[transition].minimum = convertToTempUnits(
        scheduleSetting.minimum,
        targetUnit,
        currentUnits,
        2,
      );
    }
  }

  return scheduleUpdate;
};

// Formats the current weekly schedule with preferred units
export const convertToPreferredWeeklyScheduleUnits = (
  schedule: MyWeeklySchedule,
  targetUnit: TemperatureUnit,
  currentUnits: TemperatureUnit,
) => {
  let scheduleUpdate: MyWeeklySchedule = _.cloneDeep(EmptyWeeklySchedule);
  if (schedule) {
    for (const day in schedule) {
      const dailySchedule = schedule[day as DayOfWeek];
      scheduleUpdate[day as DayOfWeek] = convertToPreferredDailyScheduleUnits(
        dailySchedule,
        targetUnit,
        currentUnits,
      );
    }
  }
  return scheduleUpdate;
};

export const convertDailyScheduleToCelciusUnits = (
  dailySchedule: MyDailySchedule,
) => {
  let scheduleUpdate: MyDailySchedule = _.cloneDeep(EmptyWeeklySchedule['0']);

  for (const transition in dailySchedule) {
    const scheduleSetting = dailySchedule[transition];
    scheduleUpdate[transition] = {};
    if (scheduleSetting.maximum !== undefined) {
      scheduleUpdate[transition].maximum = convertToCelsius(
        scheduleSetting.maximum,
        2,
      );
    }

    if (scheduleSetting.minimum !== undefined) {
      scheduleUpdate[transition].minimum = convertToCelsius(
        scheduleSetting.minimum,
        2,
      );
    }
  }

  return scheduleUpdate;
};

export const convertWeeklyScheduleToCelciusUnits = (
  schedule: MyWeeklySchedule,
) => {
  let scheduleUpdate: MyWeeklySchedule = _.cloneDeep(EmptyWeeklySchedule);
  if (schedule) {
    for (const day in schedule) {
      const dailySchedule = schedule[day as DayOfWeek];
      scheduleUpdate[day as DayOfWeek] =
        convertDailyScheduleToCelciusUnits(dailySchedule);
    }
  }
  return scheduleUpdate;
};
