import React from 'react';
import {
  Property,
  SetpointProfile,
  useRemoveSetpointProfileMutation,
  useUnitSetpointProfilesQuery,
  usePropertySetpointProfilesQuery,
  useUpdatePropertySetpointProfileOverrideMutation,
  useUpdateUnitSetpointProfileOverrideMutation,
  UnitSetpointProfilesQuery,
  Maybe,
  Unit,
  useThermostatScheduleTemplateDetailQuery,
  ThermostatScheduleTemplate,
  useUpdatePropertyThermostatScheduleTemplateOverrideMutation,
  useUpdateUnitThermostatScheduleTemplateOverrideMutation,
  UpdatePropertyThermostatScheduleTemplateOverrideMutationVariables,
  UpdateUnitThermostatScheduleTemplateOverrideMutationVariables,
  useThermostatScheduleTemplateUpdateVerificationQuery,
  Thermostat,
} from '../../../types/generated-types';
import { useTheme } from '@mui/material/styles';
import { convertToTempUnits } from '../devices/util/convert-temp-units';
import { Notifier } from '../../system/services/notificationManager';
import { defaultAbsoluteLimits } from '../helpers';
import BorderedSection from './borderedSection';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  NativeSelect,
  Typography,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { Slider } from '../devices/thermostat/components/system-edit/slider';
import {
  Fahrenheit,
  TemperatureUnit,
} from '../../system/models/temperatureUnits';
import { BulkUpdateStatus } from './bulk-update-status';
import { VerifySetpointProfileUpdate } from './verify-setpoint-profile-update';
import { useNavigate } from 'react-router-dom';
import { SetpointProfileUpdateVerification } from '../setpoint-limit-profiles/types/setpointLimitProfileDetailFormValues';
import { SimpleSetpointProfileForm } from '../setpoint-limit-profiles/ui/setpoint-limit-profile-detail-form';
import { useAuthenticator } from '../../auth/AuthenticationContext';
import { useAuthorizer } from '../../auth/AuthorizationContext';
import { SetpointLimitChangeWarningDialog } from './setpoint-limit-change-warning-dialog';
import { ApolloError } from '@apollo/client';
import { ThermostatScheduleStatistics } from '../devices/thermostat/types/thermostat-schedule-statistics';
import { ThermostatScheduleUpdateStatistics } from '../devices/thermostat/types/thermostat-schedule-update-statistics';

type AssignableProfile = Exclude<
  UnitSetpointProfilesQuery['unitById'],
  undefined | null
>['assignableSetpointProfiles'][number] & { displayName: string };
type AssignableProfiles = AssignableProfile[];

export const SetpointProfileOverrideSelection = ({
  returnLabel,
  targetLevel,
  returnPath,
  propertyId,
  unitId,
  targetProfileId,
  value,
  onSetpointProfileSelected,
  preferredUnits,
}: {
  returnLabel: string;
  returnPath: string;
  targetLevel: 'property' | 'unit';
  propertyId?: string;
  unitId?: string;
  targetProfileId: string;
  value: string | undefined;
  onSetpointProfileSelected: (id: string) => void;
  preferredUnits: TemperatureUnit | undefined;
}) => {
  const auth = useAuthenticator();
  const theme = useTheme();
  const navigator = useNavigate();
  const { can } = useAuthorizer();
  const forProperty = targetLevel === 'property';
  const forUnit = targetLevel === 'unit';

  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [verifying, setVerifying] = React.useState(false);
  const [reverting, setReverting] = React.useState(false);
  const [
    thermostatScheduleUpdateStatistics,
    setThermostatScheduleUpdateStatistics,
  ] = React.useState<ThermostatScheduleUpdateStatistics>({
    netThermostatsToBeUpdated: [],
    thermostatsUsingCompatibleScheduleTemplate: [],
    thermostatsUsingIncompatibleScheduleTemplate: [],
  });
  const [verifierReady, setVerifierReady] = React.useState<boolean>(false);
  const [propagatingSetpointProfile, setPropagatingSetpointProfile] =
    React.useState(false);
  const [propagatingScheduleTemplate, setPropagatingScheduleTemplate] =
    React.useState(false);
  const [forceUpdate, setForceUpdate] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [property, setProperty] = React.useState<Partial<Property>>();
  const [scheduleStats, setScheduleStats] = React.useState<
    ThermostatScheduleStatistics | undefined
  >(undefined);
  const [scheduleStatsError, setScheduleStatsError] =
    React.useState<ApolloError>();

  const [
    openSetpointLimitChangeWarningDialog,
    setOpenSetpointLimitChangeWarningDialog,
  ] = React.useState(false);
  const [availableProfiles, setAvailableProfiles] =
    React.useState<AssignableProfiles>();
  const [assignableSetpointProfiles, setAssignableSetpointProfiles] =
    React.useState<Partial<AssignableProfile>[]>([]);
  const [selectedSetpointProfile, setSelectedSetpointProfile] = React.useState<
    Partial<SetpointProfile>
  >({});
  const [selectedSetpointProfileId, _setSelectedSetpointProfileId] =
    React.useState<string>('');
  const [verifierParams, setVerifierParams] =
    React.useState<SetpointProfileUpdateVerification>();
  const [thermostatScheduleTemplate, setThermostatScheduleTemplate] =
    React.useState<Partial<ThermostatScheduleTemplate>>({});

  const targetVariables =
    targetLevel === 'property' ? { propertyId } : { propertyId, unitId };

  // Schedule statistics
  const {
    data: scheduleStatsData,
    loading: scheduleStatsLoading,
    refetch: refetchThermostatScheduleTemplateUpdateVerification,
  } = useThermostatScheduleTemplateUpdateVerificationQuery({
    variables: targetVariables,
    fetchPolicy: 'network-only',
    onError(error) {
      console.error(error);
      setScheduleStatsError(error);
      Notifier.error(
        'An error occurred requesting verification data. Please retry.',
      );
    },
  });

  const { data: unitSetpointProfileData, refetch: unitRefetch } =
    useUnitSetpointProfilesQuery({
      variables: { id: unitId ?? '' },
      fetchPolicy: 'network-only',
    });

  const { data: propertySetpointProfileData, refetch: propertyRefetch } =
    usePropertySetpointProfilesQuery({
      variables: { id: propertyId ?? '' },
      fetchPolicy: 'network-only',
    });

  const {
    data: thermostatScheduleTemplateData,
    refetch: thermostatScheduleTemplateRefetch,
  } = useThermostatScheduleTemplateDetailQuery({
    variables: {
      id:
        targetLevel === 'unit'
          ? unitSetpointProfileData?.unitById?.thermostatScheduleTemplateId ??
            ''
          : targetLevel === 'property'
            ? property?.thermostatScheduleTemplateId ?? ''
            : '',
    },
    fetchPolicy: 'network-only',
  });

  const [deleteSetpointProfile] = useRemoveSetpointProfileMutation();

  const [
    updateUnitSetpointProfile,
    {
      data: unitData,
      error: unitError,
      // TODO: Loading_error: should we do something with loading status for this mutation?
      // loading: unitLoading,
    },
  ] = useUpdateUnitSetpointProfileOverrideMutation();

  const [
    updatePropertySetpointProfile,
    {
      data: propertyData,
      error: propertyError,
      // TODO: Loading_error: should we do something with loading status for this mutation?
      // loading: propertyLoading,
    },
  ] = useUpdatePropertySetpointProfileOverrideMutation();

  const [updatePropertyThermostatScheduleTemplate] =
    useUpdatePropertyThermostatScheduleTemplateOverrideMutation();
  const [updateUnitThermostatScheduleTemplate] =
    useUpdateUnitThermostatScheduleTemplateOverrideMutation();

  const setSelectedSetpointProfileId = React.useCallback(
    (id: string) => {
      if (id === '-1' && can('createSetpointProfile', property)) {
        setCreating(true);
      } else if (id === '0') {
        setCreating(false);
      }

      _setSelectedSetpointProfileId(id);
    },
    [can, property],
  );

  React.useEffect(() => {
    if (scheduleStatsData?.thermostatScheduleTemplateUpdateVerification) {
      const queryData: ThermostatScheduleUpdateStatistics = {
        thermostatsUsingIncompatibleScheduleTemplate: [],
        thermostatsUsingCompatibleScheduleTemplate: [],
        netThermostatsToBeUpdated: [],
      };

      // TODO: Calvin properly type this
      scheduleStatsData.thermostatScheduleTemplateUpdateVerification.installedOnlineThermostatsAndUsesScheduleTemplate.forEach(
        (thermostat: any) => {
          if (
            thermostat?.thermostatScheduleTemplate?.setpointLimitProfileId !==
              undefined &&
            thermostat?.thermostatScheduleTemplate?.setpointLimitProfileId !==
              ''
          ) {
            if (
              thermostat.thermostatScheduleTemplate.setpointLimitProfileId !==
              selectedSetpointProfileId
            ) {
              queryData.thermostatsUsingIncompatibleScheduleTemplate.push(
                thermostat._id as string,
              );
            } else {
              queryData.thermostatsUsingCompatibleScheduleTemplate.push(
                thermostat._id as string,
              );
            }
          }
        },
      );
      const thermostatsUsingTargetLevelPropertyOrUnitScheduleTemplate =
        targetLevel === 'property'
          ? scheduleStatsData.thermostatScheduleTemplateUpdateVerification
              .thermostatsUsingPropertyLevelScheduleTemplate
          : targetLevel === 'unit'
            ? scheduleStatsData.thermostatScheduleTemplateUpdateVerification
                .thermostatsUsingUnitLevelScheduleTemplate
            : [];
      queryData.netThermostatsToBeUpdated = [
        ...queryData.thermostatsUsingIncompatibleScheduleTemplate,
        ...thermostatsUsingTargetLevelPropertyOrUnitScheduleTemplate,
      ];

      setScheduleStats(
        scheduleStatsData.thermostatScheduleTemplateUpdateVerification,
      );
      setThermostatScheduleUpdateStatistics(queryData);
    }
  }, [scheduleStatsData, selectedSetpointProfileId]);

  React.useEffect(() => {
    if (thermostatScheduleTemplateData?.thermostatScheduleTemplateById) {
      setThermostatScheduleTemplate(
        thermostatScheduleTemplateData.thermostatScheduleTemplateById,
      );
    }
  }, [thermostatScheduleTemplateData]);

  React.useEffect(() => {
    if (unitId && unitSetpointProfileData?.unitById) {
      setProperty(
        unitSetpointProfileData.unitById.property as Partial<Property>,
      );
      setAvailableProfiles(
        (unitSetpointProfileData.unitById.assignableSetpointProfiles?.map(
          (profile) => {
            return {
              ...profile,
              displayName: profile?.name ?? 'Unnamed Profile',
            };
          },
        ) as AssignableProfiles) ?? [],
      );
    } else if (propertyId && propertySetpointProfileData?.propertyById) {
      setProperty(
        propertySetpointProfileData.propertyById as Partial<Property>,
      );
      setAvailableProfiles(
        (propertySetpointProfileData.propertyById.assignableSetpointProfiles?.map(
          (profile) => {
            return {
              ...profile,
              displayName: profile.name ?? 'Unnamed Profile',
            };
          },
        ) as AssignableProfiles) ?? [],
      );
    }
  }, [
    propertyId,
    unitId,
    targetProfileId,
    unitSetpointProfileData,
    propertySetpointProfileData,
  ]);

  React.useEffect(() => {
    if (unitError) {
      Notifier.error(
        `Unable to update unit setpoint profile override: ${unitError.message}`,
      );
      // TODO:  delete after testing
      // setPropagating(false);
    } else if (unitData) {
      Notifier.success('Unit setpoint profile override updated and propagated');
      onSetpointProfileSelected(selectedSetpointProfileId);
    }
  }, [unitData, unitError]);

  React.useEffect(() => {
    if (propertyError) {
      Notifier.error(
        `Unable to update property setpoint profile override: ${propertyError.message}`,
      );
      // TODO:  delete after testing
      // setPropagating(false);
    } else if (propertyData) {
      Notifier.success(
        'Property setpoint profile override updated and propagated',
      );
      onSetpointProfileSelected(selectedSetpointProfileId);
    }
  }, [propertyData, propertyError]);

  React.useEffect(() => {
    const myProfiles: Partial<SetpointProfile>[] = (
      availableProfiles ?? []
    ).filter((profile) => profile !== null) as Partial<SetpointProfile>[];

    const assignableProfiles: AssignableProfiles = [
      ...(myProfiles
        .filter((profile) => profile._id !== targetProfileId)
        .map((profile) => {
          return {
            ...profile,
            displayName: profile.name ?? 'Unnamed Profile',
            heat: {
              max: convertToTempUnits(profile.heat?.max, preferredUnits),
              min: convertToTempUnits(profile.heat?.min, preferredUnits),
            },
            cool: {
              max: convertToTempUnits(profile.cool?.max, preferredUnits),
              min: convertToTempUnits(profile.cool?.min, preferredUnits),
            },
          };
        }) as AssignableProfiles),
    ];

    if (targetProfileId) {
      if (
        targetProfileId !== '0' &&
        targetProfileId !== '-1' &&
        availableProfiles &&
        availableProfiles?.length > 0
      ) {
        const propProfile = (availableProfiles ?? []).find(
          (profile) => profile?._id === targetProfileId,
        );
        if (propProfile) {
          assignableProfiles.push({
            _id: propProfile._id,
            name: propProfile.name,
            displayName: forProperty
              ? propProfile.name ?? 'Unnamed Profile'
              : `Use property default profile: ${
                  propProfile.name ?? 'Unnamed Setpoint Limit Profile'
                }`,
            heat: {
              max: convertToTempUnits(propProfile.heat?.max, preferredUnits),
              min: convertToTempUnits(propProfile.heat?.min, preferredUnits),
            },
            cool: {
              max: convertToTempUnits(propProfile.cool?.max, preferredUnits),
              min: convertToTempUnits(propProfile.cool?.min, preferredUnits),
            },
            properties: property?._id ? [property._id] : [],
            assignedProperties: (propProfile.assignedProperties ??
              []) as Maybe<Property>[],
            assignedUnits: (propProfile.assignedUnits ?? []) as Maybe<Unit>[],
          } as AssignableProfile);
        } else {
          Notifier.error(
            'Setpoint profile retrieval resulted in inconsistent set.',
          );
        }
      }
    }

    if (!targetProfileId || targetProfileId === '0' || forProperty) {
      assignableProfiles.push({
        _id: '0',
        name: 'Use system default Setpoint Limit Profile',
        displayName: 'Use system default Setpoint Limit Profile',
        ...defaultAbsoluteLimits[preferredUnits ?? Fahrenheit],
      } as AssignableProfile);
      if (can('createSetpointProfile', property)) {
        assignableProfiles.push({
          _id: '-1',
          name: 'Create new Setpoint Limit Profile',
          displayName: 'Create new Setpoint Limit Profile',
          ...defaultAbsoluteLimits[preferredUnits ?? Fahrenheit],
        } as AssignableProfile);
      }
    }

    setAssignableSetpointProfiles(assignableProfiles);

    setTimeout(() => {
      setSelectedSetpointProfileId(value ?? '0');
    }, 200);
    // Todo:  delete logs after testing
    // console.log(
    //   '[splp-override] targetProfileId, value',
    //   targetProfileId,
    //   value,
    // );
  }, [
    availableProfiles,
    value,
    targetProfileId,
    forProperty,
    property,
    propertyData,
    unitData,
  ]);

  React.useEffect(() => {
    // Todo:  delete logs after testing
    // console.log(
    //   '[splp-override] selectedSetpointProfileId assignableSetpointProfiles',
    //   selectedSetpointProfileId,
    //   assignableSetpointProfiles,
    // );
    setSelectedSetpointProfile(
      ((assignableSetpointProfiles || []).find(
        (profile) => profile && profile._id === selectedSetpointProfileId,
      ) || {}) as Partial<SetpointProfile>,
    );
  }, [selectedSetpointProfileId, assignableSetpointProfiles, property]);

  React.useEffect(() => {
    setVerifierParams({
      _id: selectedSetpointProfileId ?? '',
      properties: propertyId !== undefined ? [propertyId] : undefined,
      unitId: unitId || undefined,
    });
  }, [selectedSetpointProfileId]);

  const showDeleteButton = () => {
    // Todo: Remove after testing
    // console.log(
    //   '[show-delete-btn] selectedSetpointProfileId, property.setpointProfileId, targetProfileId',
    //   selectedSetpointProfileId,
    //   property?.setpointProfileId,
    //   targetProfileId,
    // );
    return (
      !creating &&
      !editing &&
      selectedSetpointProfile?._id !== '0' &&
      selectedSetpointProfileId !== property?.setpointProfileId &&
      can('deleteSetpointProfile', property) &&
      (selectedSetpointProfile.assignedUnits ?? []).length === 0 &&
      (selectedSetpointProfile.assignedProperties ?? []).length === 0
    );
  };

  const showEditButton = () => {
    return (
      !creating &&
      can('updateSetpointProfile', property) &&
      selectedSetpointProfile &&
      selectedSetpointProfile?._id !== '0'
    );
  };

  const showAssignButton = () => {
    return value !== selectedSetpointProfileId || forceUpdate;
  };

  const showRevertButton = () => {
    return (
      !creating &&
      can('updateSetpointProfile', property) &&
      selectedSetpointProfile &&
      unitSetpointProfileData?.unitById?.setpointProfileId
    );
  };

  const formVariant = () => {
    if (propertyId && targetLevel === 'property') return 'property';
    if (unitId && targetLevel === 'unit') return 'unit';
    return 'general';
  };

  const handleSubmit = async () => {
    let thermostats: Array<string | null> = [];
    if (forProperty) {
      if (propertyId && targetLevel === 'property') {
        if (selectedSetpointProfile?._id) {
          setVerifying(false);
          setPropagatingSetpointProfile(true);
          await updatePropertySetpointProfile({
            variables: {
              id: propertyId,
              setpointProfileId: selectedSetpointProfile._id ?? '',
              force: forceUpdate,
            },
          })
            .then(() => {
              const propertyHasIncompatibleScheduleTemplate = Boolean(
                thermostatScheduleTemplate?._id &&
                  thermostatScheduleTemplate?.setpointLimitProfileId !==
                    selectedSetpointProfileId,
              );
              const thermostatsIncludeIncompatibleScheduleTemplate = Boolean(
                thermostatScheduleUpdateStatistics
                  .thermostatsUsingIncompatibleScheduleTemplate.length > 0,
              );
              if (
                propertyHasIncompatibleScheduleTemplate ||
                thermostatsIncludeIncompatibleScheduleTemplate
              ) {
                setPropagatingSetpointProfile(false);
                setPropagatingScheduleTemplate(true);
                if (
                  thermostatScheduleUpdateStatistics?.thermostatsUsingIncompatibleScheduleTemplate &&
                  scheduleStats?.thermostatsUsingPropertyLevelScheduleTemplate
                ) {
                  thermostats = [
                    ...thermostatScheduleUpdateStatistics?.thermostatsUsingIncompatibleScheduleTemplate,
                    ...scheduleStats?.thermostatsUsingPropertyLevelScheduleTemplate,
                  ];
                }
                handleClearThermostatSchedules(
                  thermostats,
                  propertyHasIncompatibleScheduleTemplate,
                );
                Notifier.info('Clearing Thermostat Schedules...');
              }
            })
            .catch((err) => {
              console.error('Error Updating Property Setpoint Profile: ', err);
              Notifier.warn('Unable to apply setpoint profile override.');
            });
        } else {
          Notifier.warn(
            'Unable to apply setpoint profile override. No setpoint profile selected.',
          );
        }
      } else {
        Notifier.warn(
          'Unable to update setpoint profile selection. No property specified.',
        );
      }
    } else if (forUnit) {
      if (unitId) {
        if (selectedSetpointProfile?._id) {
          setPropagatingSetpointProfile(true);
          setVerifying(false);
          await updateUnitSetpointProfile({
            variables: {
              id: unitId,
              setpointProfileId: selectedSetpointProfile._id,
              force: forceUpdate,
            },
          }).then(() => {
            const unitHasIncompatibleScheduleTemplate = Boolean(
              thermostatScheduleTemplate?._id &&
                thermostatScheduleTemplate?.setpointLimitProfileId !==
                  selectedSetpointProfileId,
            );
            const thermostatsIncludeIncompatibleScheduleTemplate = Boolean(
              thermostatScheduleUpdateStatistics
                .thermostatsUsingIncompatibleScheduleTemplate.length > 0,
            );
            if (
              thermostatsIncludeIncompatibleScheduleTemplate ||
              unitHasIncompatibleScheduleTemplate
            ) {
              setPropagatingSetpointProfile(false);
              setPropagatingScheduleTemplate(true);
              if (
                thermostatScheduleUpdateStatistics?.thermostatsUsingIncompatibleScheduleTemplate &&
                scheduleStats?.thermostatsUsingPropertyLevelScheduleTemplate
              ) {
                thermostats = [
                  ...thermostatScheduleUpdateStatistics.thermostatsUsingIncompatibleScheduleTemplate,
                  ...scheduleStats.thermostatsUsingPropertyLevelScheduleTemplate,
                ];
              }
              handleClearThermostatSchedules(
                thermostats,
                unitHasIncompatibleScheduleTemplate,
              );
              Notifier.info('Clearing Thermostat Schedules...');
            }
          });
        } else {
          Notifier.warn(
            'Unable to apply setpoint profile override. No setpoint profile selected.',
          );
        }
      } else {
        Notifier.warn(
          'Unable to update setpoint profile selection. No unit specified.',
        );
      }
    }
  };

  const handleClearThermostatSchedules = (
    thermostats: Array<string | null>,
    propertyOrUnitHasIncompatibleScheduleTemplate: boolean,
  ) => {
    if (forProperty) {
      if (propertyId && propertyId !== '') {
        const propertyThermostatUpdateData: UpdatePropertyThermostatScheduleTemplateOverrideMutationVariables =
          {
            id: propertyId,
            thermostatScheduleTemplateId: '0',
            propertyHasIncompatibleScheduleTemplate:
              propertyOrUnitHasIncompatibleScheduleTemplate,
            targetThermostatIds: thermostats.length > 0 ? thermostats : [],
            force: true,
          };
        updatePropertyThermostatScheduleTemplate({
          variables: propertyThermostatUpdateData,
        })
          .then((result) => {
            Notifier.success(
              'Removed property level thermostat schedule template',
            );
          })
          .catch((error) => {
            console.log(
              'Failed to remove property level thermostat schedule template: ',
              error,
            );
            Notifier.error(
              'Failed to remove property level thermostat schedule template',
            );
          });
      } else {
        Notifier.warn(
          'Unable to update setpoint profile selection. No property specified.',
        );
      }
    }

    if (forUnit) {
      if (unitId && unitId !== '') {
        const unitThermostatUpdateData: UpdateUnitThermostatScheduleTemplateOverrideMutationVariables =
          {
            id: unitId,
            thermostatScheduleTemplateId: '0',
            unitHasIncompatibleScheduleTemplate:
              propertyOrUnitHasIncompatibleScheduleTemplate,
            targetThermostatIds: thermostats.length > 0 ? thermostats : [],
            force: true,
          };

        updateUnitThermostatScheduleTemplate({
          variables: unitThermostatUpdateData,
        })
          .then((result) => {
            Notifier.success('Removed Unit Level Thermostat Schedule Template');
          })
          .catch((error) => {
            console.log(
              'Failed to remove unit level schedule template: ',
              error,
            );
            Notifier.error(
              'Failed to Remove Unit Level Thermostat Schedule Template',
            );
          });
      } else {
        console.log(
          'Failed to update unit level thermostat schedule template: No Unit found',
        );
        Notifier.error(
          'Failed to Update Unit Level Thermostat Schedule Template: No Unit Found',
        );
      }
    }
  };

  const handleSubmitVerification = () => {
    if (
      // TODO: Calvin refactor this after testing
      Boolean(
        thermostatScheduleUpdateStatistics
          .thermostatsUsingIncompatibleScheduleTemplate.length > 0,
      ) ||
      (thermostatScheduleTemplate?._id &&
        thermostatScheduleTemplate?.setpointLimitProfileId !==
          selectedSetpointProfileId)
    ) {
      setOpenSetpointLimitChangeWarningDialog(true);
    } else {
      handleSubmit();
    }
  };

  const handleCloseSetpointLimitChangeWarningDialog = () =>
    setOpenSetpointLimitChangeWarningDialog(false);

  return (
    <>
      <BorderedSection title="Setpoint Limit Profile Override">
        {(propagatingSetpointProfile && selectedSetpointProfile) ||
        (propagatingScheduleTemplate && thermostatScheduleTemplate) ? (
          <BulkUpdateStatus
            title={
              propagatingSetpointProfile
                ? 'Applying Setpoint Limit Profile ...'
                : 'Clearing Thermostat Schedules ...'
            }
            initiator={
              propagatingSetpointProfile
                ? selectedSetpointProfile
                : thermostatScheduleTemplate
            }
            completeCallback={async () => {
              // TODO:  noop - remove, or useful for something?
              // console.log('[bulk-update-status][onClose]');
            }}
            errorCallback={() => {
              setPropagatingSetpointProfile(false);
              setPropagatingScheduleTemplate(false);
            }}
            actions={[
              {
                label: returnLabel,
                handler: () => navigator(returnPath),
              },
              {
                label: 'Return to editing',
                handler: async () => {
                  if (unitRefetch) {
                    await unitRefetch();
                  }
                  if (propertyRefetch) {
                    await propertyRefetch();
                  }
                  onSetpointProfileSelected(selectedSetpointProfileId ?? '0');
                  setPropagatingSetpointProfile(false);
                  setPropagatingScheduleTemplate(false);
                },
              },
            ]}
          />
        ) : verifying && selectedSetpointProfile?._id && verifierParams ? (
          <div>
            <VerifySetpointProfileUpdate
              values={verifierParams}
              force={forceUpdate}
              dataReadyCallback={setVerifierReady}
            />
            <div
              style={
                verifying
                  ? {
                      marginTop: '10px',
                    }
                  : {}
              }
            >
              {verifierReady ? (
                <Button
                  variant={'contained'}
                  color={'warning'}
                  fullWidth
                  sx={{ p: 1, my: 1 }}
                  onClick={handleSubmitVerification}
                >
                  Commit Setpoint Profile Override selection and update
                  Thermostats
                </Button>
              ) : null}
              <Stack>
                <Button
                  variant={'contained'}
                  color={'info'}
                  sx={{ p: 1, my: 1 }}
                  fullWidth
                  onClick={(e) => {
                    setVerifying(false);
                    setVerifierReady(false);
                  }}
                >
                  Return to Select Setpoint Profile Override editor
                </Button>
                <FormControlLabel
                  label="Force Apply Setpoint Limit Profile"
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setForceUpdate(!forceUpdate);
                      }}
                      checked={forceUpdate}
                    />
                  }
                />
              </Stack>
            </div>
          </div>
        ) : (
          <div>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Select a Setpoint Limit Profile to override the default Profile
                for this Unit:
              </FormLabel>
              <NativeSelect
                disabled={creating || editing || verifying}
                style={{ width: '100%' }}
                value={selectedSetpointProfileId}
                onChange={(event) => {
                  setSelectedSetpointProfileId(event.target.value);
                }}
              >
                {assignableSetpointProfiles.map((profile) => (
                  <option key={profile._id} value={profile._id}>
                    {profile.displayName}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            {selectedSetpointProfile && preferredUnits && !creating ? (
              <>
                <hr />
                <BorderedSection
                  title={`Selected Setpoint Limits (in  \xB0${preferredUnits})`}
                  style={{ marginTop: '20px' }}
                >
                  <Box
                    sx={{
                      justifyContent: 'center',
                      px: 4,
                      py: 2,
                    }}
                  >
                    <Stack
                      spacing={2}
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Typography variant="button">
                        {defaultAbsoluteLimits[preferredUnits].heat.min}
                      </Typography>
                      <Slider
                        getAriaLabel={() => 'Heat Setpoint Limits'}
                        value={[
                          selectedSetpointProfile.heat?.min as number,
                          selectedSetpointProfile.heat?.max as number,
                        ]}
                        min={defaultAbsoluteLimits[preferredUnits].heat.min}
                        max={defaultAbsoluteLimits[preferredUnits].cool.max}
                        valueLabelDisplay="on"
                        disableSwap
                        sx={{
                          color: theme.embue.heating,
                          py: 2,
                        }}
                      />
                      <Typography variant="button">
                        {defaultAbsoluteLimits[preferredUnits].cool.max}
                      </Typography>
                    </Stack>

                    <Stack
                      spacing={2}
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Typography variant="button">
                        {defaultAbsoluteLimits[preferredUnits].heat.min}
                      </Typography>
                      <Slider
                        getAriaLabel={() => 'Cool Setpoint Limits'}
                        value={[
                          selectedSetpointProfile.cool?.min as number,
                          selectedSetpointProfile.cool?.max as number,
                        ]}
                        min={defaultAbsoluteLimits[preferredUnits].heat.min}
                        max={defaultAbsoluteLimits[preferredUnits].cool.max}
                        valueLabelDisplay="on"
                        disableSwap
                        sx={{
                          color: theme.embue.cooling,
                          py: 2,
                        }}
                      />
                      <Typography variant="button">
                        {defaultAbsoluteLimits[preferredUnits].cool.max}
                      </Typography>
                    </Stack>
                  </Box>
                </BorderedSection>
              </>
            ) : null}
          </div>
        )}
        <div
          style={
            !verifying
              ? {
                  marginTop: '10px',
                }
              : {}
          }
        >
          {/* hide during verifying and once propagating has started */}
          {!verifying &&
          !propagatingSetpointProfile &&
          !propagatingScheduleTemplate ? (
            <>
              {showEditButton() && (
                <Button
                  variant={'contained'}
                  color={'warning'}
                  fullWidth
                  sx={{ p: 1, my: 1 }}
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setEditing(true);
                  }}
                >
                  Edit Setpoint Profile
                </Button>
              )}

              {showRevertButton() && (
                <Button
                  variant={'contained'}
                  color={'success'}
                  fullWidth
                  sx={{ p: 1, my: 1 }}
                  onClick={() => {
                    /* open the 'reverting' dialog */
                    setReverting(true);
                  }}
                >
                  Revert To Property Level Default
                </Button>
              )}
              {showDeleteButton() && (
                <Button
                  variant={'contained'}
                  color={'error'}
                  fullWidth
                  sx={{ p: 1, my: 1 }}
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setDeleting(true);
                  }}
                >
                  Delete Setpoint Profile
                </Button>
              )}
            </>
          ) : null}
          {/* hide if displaying the selected profile or once propagating has started */}
          {!verifying &&
          !propagatingSetpointProfile &&
          !propagatingScheduleTemplate ? (
            <Stack>
              {showAssignButton() && (
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  fullWidth
                  sx={{ p: 1, my: 1 }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setVerifying(true);
                  }}
                >
                  Assign to this {unitId ? 'unit' : 'property'}
                </Button>
              )}
              <FormControlLabel
                label="Force Apply Setpoint Limit Profile"
                control={
                  <Checkbox
                    checked={forceUpdate}
                    onChange={(e) => {
                      setForceUpdate(!forceUpdate);
                    }}
                  />
                }
              />
            </Stack>
          ) : null}
        </div>
      </BorderedSection>
      <Dialog
        sx={{
          width: '100%',
          '& .MuiDialog-paper': {
            width: '100vw',
            minWidth: '100vw - 20px',
          },
          '& .MuiPaper-root': {
            width: '100vw',
            minWidth: '100vw - 20px',
          },
        }}
        open={creating}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {auth.user && property ? (
          <SimpleSetpointProfileForm
            variant={formVariant()}
            onClose={async (newId: string | undefined, refetch = true) => {
              setCreating(false);
              if (refetch && unitRefetch) {
                await unitRefetch();
              }
              if (refetch && propertyRefetch) {
                await propertyRefetch();
              }
              setTimeout(() => {
                // Todo:  delete logs after testing
                // console.log(
                //   '[splp-override][new-profile] newId',
                //   newId,
                //   targetProfileId,
                // );
                setSelectedSetpointProfileId(newId ?? targetProfileId ?? '0');
              }, 300);
            }}
            profile={
              {
                properties: [property._id],
              } as Partial<SetpointProfile>
            }
            user={auth.user}
          />
        ) : null}
      </Dialog>
      <Dialog
        sx={{
          width: '100%',
          '& .MuiDialog-paper': {
            width: '100vw',
            minWidth: '100vw - 20px',
          },
          '& .MuiPaper-root': {
            width: '100vw',
            minWidth: '100vw - 20px',
          },
        }}
        open={editing}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {auth.user && property ? (
          <SimpleSetpointProfileForm
            variant={formVariant()}
            onClose={async () => {
              const currentSetpointProfileId = selectedSetpointProfileId;
              setEditing(false);
              if (unitRefetch) {
                await unitRefetch();
              }
              if (propertyRefetch) {
                await propertyRefetch();
              }
              setTimeout(() => {
                setSelectedSetpointProfileId(currentSetpointProfileId);
              }, 300);
            }}
            profile={{ ...selectedSetpointProfile }}
            currentUnits={auth.user.preferredUnits}
            user={auth.user}
          />
        ) : null}
      </Dialog>
      <Dialog
        sx={{
          width: '100%',
          '& .MuiDialog-paper': {
            width: '100vw',
            minWidth: '100vw - 20px',
          },
          '& .MuiPaper-root': {
            width: '100vw',
            minWidth: '100vw - 20px',
          },
        }}
        open={deleting}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {auth.user && property && selectedSetpointProfile ? (
          <>
            <Typography variant="h4" sx={{ padding: '15px' }}>
              Delete Setpoint Profile
            </Typography>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete {selectedSetpointProfile.name}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setDeleting(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ color: '#fff' }}
                onClick={async () => {
                  if (selectedSetpointProfile?._id) {
                    try {
                      await deleteSetpointProfile({
                        variables: {
                          id: selectedSetpointProfile._id,
                        },
                      });
                      Notifier.success('Setpoint Profile Deleted');
                      setDeleting(false);
                      if (unitRefetch) {
                        await unitRefetch();
                      }
                      if (propertyRefetch) {
                        await propertyRefetch();
                      }
                    } catch (error) {
                      if (error instanceof Error) {
                        Notifier.error(error.message);
                      } else {
                        Notifier.error(
                          'Unknown error while deleting setpoint profile.',
                        );
                      }
                    }
                  } else {
                    Notifier.error('No setpoint profile selected.');
                  }
                }}
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </>
        ) : null}
      </Dialog>
      <Dialog open={reverting}>
        <Typography variant="h4" sx={{ padding: '15px' }}>
          Revert To Using The Property Default Setpoint Profile
        </Typography>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove the setpoint limit profile '
            {selectedSetpointProfile.name}' that is currently set explicitly for
            this unit, and instead revert to using the property wide setpoint
            limit profile?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={processing}
            onClick={() => {
              setReverting(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={processing}
            sx={{ color: '#fff' }}
            onClick={async () => {
              if (unitId && selectedSetpointProfile?._id) {
                setProcessing(true);
                try {
                  await updateUnitSetpointProfile({
                    variables: {
                      id: unitId,
                      setpointProfileId: selectedSetpointProfile._id,
                      force: forceUpdate,
                      revert: true,
                    },
                  });
                  Notifier.success('Setpoint Limit Profile Cleared');
                  if (unitRefetch) {
                    await unitRefetch();
                  }
                  // TODO:  needed?  this is only in the context of a unit
                  // if (propertyRefetch) {
                  //   await propertyRefetch();
                  // }
                  setTimeout(() => {
                    // Todo: remove after testing
                    // console.log(
                    //   '[splp-override] [reverting] property.setpointProfileId, targetProfileId, assignableProfiles',
                    //   property?.setpointProfileId,
                    //   targetProfileId,
                    //   assignableSetpointProfiles,
                    // );
                    setSelectedSetpointProfileId(
                      property?.setpointProfileId ?? '0',
                    );
                    setReverting(false);
                    setProcessing(false);
                  }, 300);
                } catch (error) {
                  setProcessing(false);
                  if (error instanceof Error) {
                    Notifier.error(error.message);
                  } else {
                    Notifier.error(
                      'Unknown error while clearing setpoint profile.',
                    );
                  }
                }
              } else {
                Notifier.error('No setpoint profile selected.');
              }
            }}
            autoFocus
          >
            {processing ? 'Reverting...' : 'Revert'}
          </Button>
        </DialogActions>
      </Dialog>
      <SetpointLimitChangeWarningDialog
        handleClose={handleCloseSetpointLimitChangeWarningDialog}
        open={openSetpointLimitChangeWarningDialog}
        handleSubmit={handleSubmit}
        thermostatScheduleUpdateStatistics={thermostatScheduleUpdateStatistics}
        scheduleStats={scheduleStats}
        scheduleStatsError={scheduleStatsError}
        scheduleStatsLoading={scheduleStatsLoading}
        targetLevel={targetLevel}
        selectedSetpointProfileId={selectedSetpointProfileId}
      />
    </>
  );
};
