import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

const BLANK = '\u00a0';

interface StatusMessageProps {
  message: string;
}

export function StatusMessage(props: StatusMessageProps) {
  const { message } = props;
  const color = message.match(/WARN/)
    ? 'warning'
    : message.match(/ERR/)
      ? 'error'
      : 'info';

  return (
    <Box
      sx={{ margin: '0px', marginTop: '4px', padding: '0px', width: '100%' }}
      height="auto"
      textAlign={'center'}
    >
      {(message !== BLANK && (
        <Chip
          sx={{ width: '95%', fontSize: '12px', height: '18px' }}
          color={color}
          label={message}
        />
      )) || <Typography sx={{ fontSize: '8px' }}>{BLANK}</Typography>}
    </Box>
  );
}
