import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Log, LogCategory } from '../../system/services/logger';
import { useLocation, useNavigate } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { unitBreadcrumbRoutes } from '../../routes/units-routes';
import { propertyBreadcrumbRoutes } from '../../routes/property-routes';
import { appRoutes } from '../../routes/app-routes';

type HistoryEntry = [string, React.ReactNode];

export interface ISystemHistoryManager {
  forward(): void;
  back(): void;
  clear(): void;
  hasNext: boolean;
  hasPrevious: boolean;
  stack: HistoryEntry[];
  currentIndex: number;
}

interface SystemHistoryProviderProps {
  children: React.ReactNode;
}

const defaultValue: ISystemHistoryManager = {
  forward: () => {},
  back: () => {},
  clear: () => {},
  hasNext: false,
  hasPrevious: false,
  stack: [],
  currentIndex: 0,
};

const SystemHistoryContext = createContext<ISystemHistoryManager>(defaultValue);

SystemHistoryContext.displayName = 'SystemHistoryContext';

export const SystemHistoryProvider: FC<SystemHistoryProviderProps> = ({
  children,
}) => {
  const { pathname, state, search } = useLocation();
  const navigate = useNavigate();

  const [history, setHistory] = useState<HistoryEntry[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const myRoutes = [
    ...propertyBreadcrumbRoutes,
    ...unitBreadcrumbRoutes,
    ...appRoutes,
  ];
  const breadcrumbs = useBreadcrumbs([...myRoutes]);

  useEffect(() => {
    const firstMatch = (breadcrumbs ?? [])[
      breadcrumbs ? breadcrumbs.length - 1 : 0
    ];

    const firstRoute = myRoutes.find(
      (x) =>
        x.path === firstMatch?.match?.pattern?.path ||
        x.path === `${firstMatch?.match?.pattern?.path ?? ''}/*`,
    );

    const fullPath = `${pathname}${search ?? ''}`;

    if (
      firstRoute?.state?.ignore &&
      firstRoute.state.ignore.find((spec: RegExp) => spec.test(fullPath))
    ) {
      // matched but intentionally ignore.
    } else if (firstRoute?.state?.to) {
      navigate(firstRoute.state.to);
    } else if (pathname) {
      if (history.length === 0) {
        setHistory([[fullPath, firstMatch.breadcrumb]]);
        setCurrentIndex(0);
      } else if (history[currentIndex][0] !== fullPath) {
        const newArray = [...history];

        newArray.splice(currentIndex + 1, 0, [fullPath, firstMatch.breadcrumb]);
        setHistory(newArray);
        setCurrentIndex(currentIndex + 1);
      }
    }
  }, [pathname, state, search]);

  const value = useMemo(() => {
    return {
      forward: () => {
        if (currentIndex < history.length - 1) {
          setCurrentIndex(currentIndex + 1);
          navigate(history[currentIndex + 1][0]);
        }
      },
      back: () => {
        if (currentIndex > 0) {
          setCurrentIndex(currentIndex - 1);
          navigate(history[currentIndex - 1][0]);
        }
      },
      clear: () => {
        setHistory([]);
        setCurrentIndex(0);
      },
      hasNext: history.length > currentIndex + 1,
      hasPrevious: currentIndex > 0,
      stack: history.map((item) => item),
      currentIndex,
    };
  }, [history, currentIndex, navigate]);

  Log.silly('rendering communications manager', null, LogCategory.RENDERING);

  return (
    <SystemHistoryContext.Provider value={value}>
      {children}
    </SystemHistoryContext.Provider>
  );
};

export const useSystemHistory = () => {
  const context = useContext(SystemHistoryContext);
  if (!context) {
    throw new Error(
      'useSystemHistory must be used within a SystemHistoryProvider',
    );
  }
  return context;
};
