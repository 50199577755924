/**
 * SetPoints Component
 * - display the thermostat setpoints at the center of the thermostat screen
 * - allow editing of setpoints if the 'editable' flag is set
 * - temperature changes are reported in deg C via handleChange
 * - increment and decrement are handled according to the 'units' preference
 */

import React from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ArrowUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowDnIcon from '@mui/icons-material/ArrowCircleDown';

import { DisplayValue } from '../../../shared-ui';

interface SetPointsProps {
  heatSetpoint?: Maybe<number>;
  coolSetpoint?: Maybe<number>;
  operatingMode?: Maybe<string>;
  units?: Maybe<string>;
  editable?: boolean;
  handleTargetChange: (targetMode: 'heat' | 'cool', delta: number) => void;
  handleChange: (changes: Record<string, number | string | boolean>) => void;
}
enum Operation {
  DEC,
  INC,
}

export function SetPoints(props: SetPointsProps) {
  const {
    heatSetpoint,
    coolSetpoint,
    units,
    editable,
    operatingMode,
    handleTargetChange,
    // TODO: Peter: do we need this any longer? if not, we should remove it from props.
    // handleChange,
  } = props;

  const supportsHeatSetpoint = ['Auto', 'Heat'].includes(
    operatingMode as string,
  );
  const supportsCoolSetpoint = ['Auto', 'Cool'].includes(
    operatingMode as string,
  );

  const handleClick = (
    key: string,
    value: Maybe<number>,
    operation: Operation,
  ) => {
    switch (operation) {
      case Operation.DEC:
        handleTargetChange(key.startsWith('heat') ? 'heat' : 'cool', -1);
        break;
      case Operation.INC:
        handleTargetChange(key.startsWith('heat') ? 'heat' : 'cool', 1);
        break;
      default:
        console.error('[SetPoints] handle-click: unknown operation');
        throw new Error('[SetPoints] handle-click: unknown operation');
    }
    // return handleChange(key, convertedValue);
  };

  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      divider={<Divider orientation="horizontal" flexItem />}
      // style={{ height: '119px' }}
    >
      {supportsHeatSetpoint && (
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {editable && (
            <IconButton
              size="large"
              style={{ padding: '12px 12px 12px 6px' }}
              onClick={() =>
                handleClick(`heatSetpoint`, heatSetpoint, Operation.DEC)
              }
            >
              <ArrowDnIcon style={{ height: '36px', width: '36px' }} />
            </IconButton>
          )}
          <DisplayValue
            value={heatSetpoint}
            units={units}
            label="Heat To"
            mode="Heat"
            valueSize="24px"
            unitsSize="16px"
            labelStyle={{ marginTop: '-5px', marginBottom: '6px' }}
          />
          {editable && (
            <IconButton
              size="large"
              style={{ padding: '12px 6px 12px 12px' }}
              onClick={() =>
                handleClick(`heatSetpoint`, heatSetpoint, Operation.INC)
              }
            >
              <ArrowUpIcon style={{ height: '36px', width: '36px' }} />
            </IconButton>
          )}
        </Stack>
      )}
      {supportsCoolSetpoint && (
        <Stack direction={'row'}>
          {editable && (
            <IconButton
              size="large"
              style={{
                marginTop: supportsHeatSetpoint ? '6px' : '0px',
                padding: '12px 12px 12px 6px',
              }}
              onClick={() =>
                handleClick(`coolSetpoint`, coolSetpoint, Operation.DEC)
              }
            >
              <ArrowDnIcon style={{ height: '36px', width: '36px' }} />
            </IconButton>
          )}
          <DisplayValue
            value={coolSetpoint}
            units={units}
            label="Cool To"
            mode="Cool"
            valueSize="24px"
            unitsSize="16px"
            labelStyle={{ marginTop: '-5px', marginBottom: '4px' }}
          />
          {editable && (
            <IconButton
              size="large"
              style={{ padding: '12px 6px 12px 12px' }}
              onClick={() =>
                handleClick(`coolSetpoint`, coolSetpoint, Operation.INC)
              }
            >
              <ArrowUpIcon style={{ height: '36px', width: '36px' }} />
            </IconButton>
          )}
        </Stack>
      )}
    </Stack>
  );
}
