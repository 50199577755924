import React from 'react';
import { Container } from '@mui/material';

import { ZonePolicyOverrideSelection } from '../../../shared/zone-policies-override-selection';
import { ViewUnitContentProps } from '../../types/viewUnitContentProps';

export const ViewZonePolicies = ({ unit, property }: ViewUnitContentProps) => {
  return unit && property ? (
    <Container style={{ marginTop: '20px' }}>
      <ZonePolicyOverrideSelection
        returnLabel="Return to Property List"
        returnPath={`/properties`}
        propertyId={property?._id}
        targetPolicyId={property?.zonePolicyId ?? '0'}
        availablePolicies={property?.assignableZonePolicies ?? []}
        value={property?.zonePolicyId ?? '0'}
      />
    </Container>
  ) : null;
};
