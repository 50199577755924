import React, { CSSProperties, useEffect, useState } from 'react';
import {
  Thermistor,
  useThermistorDetailQuery,
  useThermistorDetailUpdateSubscription,
} from '../../../../types/generated-types';
import BorderedSection from '../../shared/borderedSection';
import { DisplayValue } from '../shared-ui';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';

import { convertToTempUnits } from '../util';
// import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import { PanelFrame } from '../shared-ui/panel-frame';
import {
  Fahrenheit,
  TemperatureUnit,
} from '../../../system/models/temperatureUnits';
import { DeviceInformation } from '../shared-ui/device-info';
import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';
import moment from 'moment';

const SimpleThermistor = ({
  device,
  embedded,
  style,
  preferredUnits = Fahrenheit,
}: {
  device: Partial<Thermistor>;
  embedded: boolean;
  style: CSSProperties;
  preferredUnits: TemperatureUnit;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flex: '1 1 auto',
        width: '100%',
        ...style,
      }}
    >
      <DisplayValue
        value={convertToTempUnits(device?.temperature?.value, preferredUnits)}
        units={preferredUnits}
        valueSize={!embedded ? '30px' : '16px'}
        unitsSize={!embedded ? '15px' : '12px'}
        style={{ width: '100%', textAlign: 'center' }}
      />
    </div>
  );
};

export function ThermistorComponent({
  deviceId,
  label,
  embedded = true,
  style = {},
  preferredUnits = Fahrenheit,
}: {
  deviceId: string;
  label: string;
  embedded?: boolean;
  style?: CSSProperties | undefined;
  preferredUnits?: TemperatureUnit;
}) {
  const [device, setDevice] = useState<Partial<Thermistor>>();
  const {
    data,
    // TODO: Loading_error: should we handle loading/error here for this query?
    // loading,
    // error,
  } = useThermistorDetailQuery({
    variables: { id: deviceId },
  });
  const [deviceInfo, setDeviceInfo] = useState<DeviceInformation[]>([]);

  useThermistorDetailUpdateSubscription({
    variables: { ids: [deviceId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (data?.thermistorById) {
      setDevice({
        ...data.thermistorById,
        name: data.thermistorById.name ?? label,
        deviceAlerts: data.thermistorById.deviceAlerts.map((alert) => {
          return {
            ...alert,
            device: {
              ...alert?.device,
              name: alert?.device?.name ?? label,
            },
          };
        }) as (typeof data.thermistorById)['deviceAlerts'],
      } as Partial<Thermistor>);
    }
  }, [data]);

  useEffect(() => {
    if (device) {
      const panelInfo: DeviceInformation = {
        Panel: device.panel?.displayName ?? 'Unnamed',
        'Panel ID': device.panelId ?? 'Unidentified',
        'Panel Type': device.panel?.type ?? 'Unknown',
        Source: device.source?.name ?? 'Unnamed source',
      };

      const devicesInfo: DeviceInformation[] = [
        {
          'Device ID': device.deviceId ?? device._id ?? 'Unidentified',
          Name: device.name ?? 'Unnamed',
          Type: device.typeDisplayName ?? 'Unknown',
          'Last Updated': moment(device.timestamp).fromNow(),
        },
      ];

      setDeviceInfo([panelInfo, ...devicesInfo]);
    }
  }, [device]);

  return device ? (
    embedded ? (
      <BorderedSection
        title={label}
        labelStyle={{ fontSize: '10px' }}
        style={{
          height: '45px',
          width: '100%',
        }}
        ccStyle={{
          padding: '6px',
          width: '100%',
        }}
      >
        <SimpleThermistor
          device={device}
          embedded={true}
          style={style}
          preferredUnits={preferredUnits}
        />
      </BorderedSection>
    ) : (
      <PanelFrame
        iconType="ic:baseline-thermostat"
        panel={device.panel}
        infoEntries={deviceInfo}
        editingEnabled={false}
      >
        <SimpleThermistor
          device={device}
          embedded={false}
          style={style}
          preferredUnits={preferredUnits}
        />
      </PanelFrame>
    )
  ) : (
    <DeviceLoadingSkeleton sx={{ margin: '2px' }} />
  );
}
