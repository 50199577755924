import { BulkConfigPropertiesQuery } from '../../../types/generated-types';
import {
  Fahrenheit,
  TemperatureUnit,
} from '../../system/models/temperatureUnits';

export type Properties = BulkConfigPropertiesQuery['properties'];
export type Property = Properties[number];
export type Unit = Property['units'][number];
export type Building = Property['buildings'][number];
export type Floor = NonNullable<Building>['floors'][number];

// Steps for bulk configuring thermostats
export enum BulkThermostatsStep {
  SelectProperty,
  SelectUnitTypes,
  SelectBuildings,
  SelectFloors,
  SelectCUGroups,
  ConfigureTstats,
  BulkVerify,
  BulkCommit,
}

// Steps for bulk configuring setpoint limits
export enum BulkSetpointLimitsStep {
  SelectProperty,
  BulkVerify,
  BulkCommit,
}

// Tstat attributes that can be bulk configured
export interface TstatConfig {
  lockoutLevel: string | 'no-change';
  systemType: string | 'no-change';
  fanMode: string | 'no-change';
  fanButton: string | 'no-change';
  opMode: string | 'no-change';
  setpoints: {
    modify: boolean;
    heat: number;
    cool: number;
    units: TemperatureUnit;
  };
}

// Initial default tstat configuration
// The majority of users have Fahrenheit as their preferred temperature unit, so default to that.
// Bulk config includes a dropdown to easily switch between Fahrenheit and Celsius.
export const initialTstatConfig: TstatConfig = {
  lockoutLevel: 'no-change',
  systemType: 'no-change',
  fanMode: 'no-change',
  fanButton: 'no-change',
  opMode: 'no-change',
  setpoints: {
    modify: false,
    heat: 68,
    cool: 72,
    units: Fahrenheit,
  },
};

// Attributes used to filter tstats to apply changes to
export interface TstatFilters {
  systemType: string | 'any';
  opMode: string | 'any';
}

// Initial default tstat filters
export const initialTstatFilters: TstatFilters = {
  systemType: 'any',
  opMode: 'any',
};

// Attributes used to filter units to apply changes to
export interface UnitFilters {
  selectedUnitTypes: string[];
  selectedBuildings: string[];
  selectedFloors: string[];
  selectedCUGroups: string[];
}

// Progress while committing bulk config changes
export interface BulkCommitProgress {
  deviceIds: string[];
  numConfigured: number;
  numFailed: number;
  terminated: boolean;
}

// Initial commit progress
export const initialCommitProgress: BulkCommitProgress = {
  deviceIds: [],
  numConfigured: 0,
  numFailed: 0,
  terminated: false,
};
