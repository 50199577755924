import { SetpointProfile } from '../../../../types/generated-types';
import { TemperatureUnit } from '../../../system/models/temperatureUnits';
import { convertToTempUnits } from './convert-temp-units';

export const convertToPreferredSetpointLimitUnits = (
  setpointProfile: Partial<SetpointProfile>,
  preferredUnits: TemperatureUnit,
) => {
  const formattedLimits: {
    cool: SetpointProfile['cool'];
    heat: SetpointProfile['heat'];
  } = {
    cool: {
      min: convertToTempUnits(setpointProfile.cool?.min, preferredUnits, 'C'),
      max: convertToTempUnits(setpointProfile.cool?.max, preferredUnits, 'C'),
    },
    heat: {
      min: convertToTempUnits(setpointProfile.heat?.min, preferredUnits, 'C'),
      max: convertToTempUnits(setpointProfile.heat?.max, preferredUnits, 'C'),
    },
  };
  return { ...setpointProfile, ...formattedLimits };
};
