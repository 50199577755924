import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import DataTable from '../../shared/data-table';
/* MUI */
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

/* Data */
import {
  useUnitListUpdateSubscription,
  usePropertyBuildingsQuery,
  usePropertyScheduleListByPropertyIdQuery,
  Property,
} from '../../../../types/generated-types';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
import { Avatar, Card, CardHeader, useMediaQuery } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridComparatorFn,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid';
import FullWidthLoadingSkeleton from '../../shared/fullWidthLoadingSkeleton';
import {
  UnitScheduleRow,
  unitTypes,
  MySchedule,
} from '../../units/types/unitListTypes';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../../system/services/injectableComponentsManager';
/* ICONS */
import ScheduleIcon from '@mui/icons-material/CalendarMonth';
import { Icon } from '@iconify/react';

export function PropertyScheduleList({
  installerView = false,
}: {
  installerView?: boolean;
}) {
  /* State */
  const [units, setUnits] = useState<MySchedule[]>();

  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    setPrimaryBottomNavigationWidget,
    setContextMenuItems,
    setSubtitle,
    setSubtitlePath,
    setSubtitleActionWidget,
    setTitle,
    setTitlePath,
  } = useInjectableComponents();

  const { pathname } = useLocation();

  /* Data */

  const {
    data: scheduleList,
    loading: scheduleListLoading,
    error: scheduleListError,
  } = usePropertyScheduleListByPropertyIdQuery({
    variables: { propertyId: id },
    fetchPolicy: 'cache-and-network',
  });

  useUnitListUpdateSubscription({
    variables: { propertyId: id || '' },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  const {
    data: buildingsData,
    loading: buildingsLoading,
    error: buildingsError,
  } = usePropertyBuildingsQuery({
    variables: { id: id || '' },
    fetchPolicy: 'cache-and-network',
  });

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [property, setProperty] = useState<Partial<Property>>();

  // Map the filtered list of units to table rows
  const formattedData: UnitScheduleRow[] = useMemo(() => {
    return (
      units?.map((unit) => {
        return {
          ...unit,
          id: unit._id,
          scheduleType: unit.effectiveThermostatScheduleType,
        };
      }) ?? []
    );
  }, [units]);

  useEffect(() => {
    if (scheduleList?.unitsByPropertyId && unitTypes) {
      const units = scheduleList.unitsByPropertyId;
      setUnits(units);
    }
  }, [scheduleList]);

  useEffect(() => {
    if (buildingsData?.propertyById) {
      const myProperty: Partial<Property> =
        buildingsData.propertyById as Partial<Property>;
      setProperty(myProperty);
    }
  }, [buildingsData]);

  useEffect(() => {
    if (units && property && pathname) {
      const bottomMenu: BottomMenuItems = [
        {
          items: [
            {
              id: 'menu-item-add-schedule-template',
              label: 'Schedule Templates',
              icon: <Icon icon="akar-icons:paper" />,
              action: () =>
                navigate(`/properties/${property?._id}/schedule/templates`),
            },
          ],
        },
      ];

      setContextMenuItems(bottomMenu);

      const basePropertyName = property.title ?? 'Untitled Property';

      setTitle(
        pathname.includes('installer')
          ? isSmall
            ? basePropertyName.length < 19
              ? `Installing ${basePropertyName}`
              : basePropertyName.length > 24
                ? `${basePropertyName.slice(0, 24)} ...`
                : basePropertyName
            : basePropertyName
          : basePropertyName,
      );
      setTitlePath(
        pathname.includes('installer')
          ? pathname.replace('installer', 'units')
          : `/properties`,
      );

      setSubtitle(
        pathname.includes('installer') ? 'Installer Unit List' : 'Unit List',
      );
      setSubtitlePath(
        pathname.includes('installer')
          ? `/properties/${property._id}/units`
          : `/properties/${property._id}/summary`,
      );

      return () => {
        setTitle(undefined);
        setTitlePath(undefined);
        setSubtitle(undefined);
        setSubtitlePath(undefined);
        setSubtitleActionWidget(undefined);
        setContextMenuItems(undefined);
        setPrimaryBottomNavigationWidget(undefined);
      };
    }
  }, [units, property, pathname, isSmall]);

  const handleRowClick = (params: GridRowParams) => {
    const { row } = params;
    navigate(`/properties/${property?._id}/units/${row.id}/schedule`);
  };

  /* UI */
  const err = buildingsError || scheduleListError;
  const isLoading = scheduleListLoading || buildingsLoading;

  function padDigits(input: String): String {
    let digitsMatch = input.match(/^\d+/); // Match the sequence of digits at the beginning of the string

    if (digitsMatch) {
      const digits = digitsMatch[0]; // Extract the matched digits
      const paddedDigits = digits.padStart(5, '0'); // Pad the digits with leading zeros

      return input.replace(digits, paddedDigits); // Replace the original digits with the padded digits
    }

    digitsMatch = input.match(/\d+$/); // Match the sequence of digits at the end of the string
    if (digitsMatch) {
      const digits = digitsMatch[0]; // Extract the matched digits
      const paddedDigits = digits.padStart(5, '0'); // Pad the digits with leading zeros
      return input.replace(digits, paddedDigits); // Replace the original digits with the padded digits
    }

    return input; // Return the original string if no digits were found at the beginning
  }

  const unitNameColumnComparator: GridComparatorFn<String> = (v1, v2, a, b) => {
    return gridStringOrNumberComparator(padDigits(v1), padDigits(v2), a, b);
  };

  // Unit column. On small screens, all other columns will be hidden, and this will need to render
  // a custom card view with all relevant pieces of information for the row.

  const columns: GridColDef<UnitScheduleRow>[] = [
    {
      field: 'name',
      headerName: 'Unit',
      width: isSmall ? 120 : 200,
      sortComparator: unitNameColumnComparator,
      renderCell: (params: GridRenderCellParams<MySchedule>) => {
        return (
          <Typography
            sx={{
              whiteSpace: isSmall ? 'pre-wrap' : 'inherit',
              fontSize:
                isSmall && (params?.row?.name ?? '').length > 14
                  ? '12px'
                  : '14px',
            }}
          >
            {params?.row?.name ?? 'N/A'}
          </Typography>
        );
      },
    },
    {
      field: 'effectiveThermostatScheduleType',
      headerName: 'Schedule (C/T)',
      width: 150,
    },
  ];

  return err ? (
    err.message === 'Unauthorized' ? (
      <>
        <Typography variant="h4">
          You are not authorized to access the requested resource.
        </Typography>
        <Typography variant="body1">
          Please contact your administrator or Embue support for assistance.
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="h4">
          Error accessing the requested resource.
        </Typography>
        <Typography variant="body1">
          Please contact your administrator or Embue support for assistance.
        </Typography>
      </>
    )
  ) : isLoading ? (
    <FullWidthLoadingSkeleton />
  ) : (
    <Card
      elevation={4}
      sx={{
        background: 'inherit',
        margin: isSmall ? '0px' : '20px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        '& .MuiCardHeader-root': {
          padding: '4px 10px!important',
          borderBottom: '1px solid darkgray',
          borderRight: isSmall ? '1px solid lightgray' : 'inherit',
          boxShadow: '0px 8px 6px -6px rgba(0, 0, 0, 0.35)',
        },
        '& .MuiCardContent-root': {
          flex: 1,
          borderRight: isSmall ? '1px solid lightgray' : 'inherit',
          paddingTop: '0px !important',
          padding: isSmall ? '0px !important' : 'inherit',
        },
        cursor: 'pointer',
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            aria-label="schedule icon"
            sx={{
              color: theme.palette.secondary.main,
              backgroundColor: '#FFF',
            }}
          >
            <ScheduleIcon />
          </Avatar>
        }
        title={
          <Typography
            variant="h3"
            sx={{ color: 'rgba(0, 0, 0, 0.87)', marginLeft: '8px' }}
            noWrap
          >
            Property Schedule
          </Typography>
        }
      />
      <DataTable
        hideFilterButton
        sxProps={{ border: 'none' }}
        additionalProps={{
          onRowClick: (i: GridRowParams) => handleRowClick(i),
        }}
        density={'standard'} // {isSmall ? 'comfortable' : 'standard'}
        columns={columns}
        hideFooterPagination
        hideFooter
        suppressAutoPageSize={true}
        rows={formattedData}
        loading={isLoading}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [''],
            },
          },
        }}
        slotProps={{
          row: {
            'aria-label': 'unit-detail',
          },
        }}
      />
    </Card>
  );
}
