import React from 'react';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { DrawerHeader } from './components/drawer-header';
import { DrawerMain } from './components/drawer-main';
import { NavMenuItem } from '../app-bar';
import { Logo } from '../shared/logo';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

interface DrawerProps {
  theme: Theme;
  drawerWidth: number;
  drawerOpen: boolean;
}

interface LeftDrawerProps extends DrawerProps {
  primaryNavItems: NavMenuItem[];
  secondaryNavItems: NavMenuItem[];
  toggleDrawer: () => void;
  handleRouteChange: (route: string) => void;
}

export function LeftDrawer(props: LeftDrawerProps) {
  const { theme, drawerWidth, drawerOpen } = props;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  return (
    <DrawerMain
      variant="permanent"
      theme={theme}
      open={drawerOpen}
      width={drawerWidth}
    >
      <DrawerHeader theme={theme}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 36,
            width: '100%',
            marginTop: '2px',
          }}
        >
          <div style={{ width: '38%', height: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              // onClick={() => navigate('/properties')}
            >
              <IconButton onClick={() => navigate('/properties')}>
                <Logo />
              </IconButton>
            </div>
          </div>
        </Box>
        <IconButton onClick={props.toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List component="nav">
        {props.primaryNavItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={() => {
                props.handleRouteChange(item.path);
                if (isSmallScreen && drawerOpen) {
                  props.toggleDrawer();
                }
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </DrawerMain>
  );
}
