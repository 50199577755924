import * as React from 'react';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import DataTable from '../shared/data-table';
import Button from '@mui/material/Button';
import {
  Property,
  Unit,
  useZonePolicyListQuery,
  useZonePolicyListUpdateSubscription,
  ZonePolicy,
  // useRemoveZonePolicyMutation,
} from '../../../types/generated-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { updateCacheFromSubscriptionEvent } from '../../../helpers/subscriptionUtils';
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import {
  MobileListCard,
  MobileListDataTableProps,
} from '../shared/mobile-list-card';
import {
  useInjectableComponents,
  BottomMenuItems,
} from '../../system/services/injectableComponentsManager';
import { Add } from '@mui/icons-material';
import { Notifier } from '../../system/services/notificationManager';

export default function ZonePoliciesTable() {
  useZonePolicyListUpdateSubscription({
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const currentLocation = useLocation();
  const { pathname } = currentLocation;

  const { setContextMenuItems } = useInjectableComponents();

  const nameColumn = isSmall
    ? ({
        field: 'name',
        flex: 1,
        align: 'center',
        headerName: 'Policy Name',
        renderCell: (params: GridRenderCellParams<Partial<any>>) => {
          return (
            <MobileListCard>
              <Typography
                sx={{ fontSize: 14, fontWeight: 800 }}
                color="text.secondary"
                gutterBottom
              >
                {params.row?.name ?? 'Unnamed Zone Policy'}
              </Typography>
              <Typography variant="h5" component="div">
                ID: {params.row?._id ?? 'Missing ID'}
              </Typography>
            </MobileListCard>
          );
        },
      } as GridColDef)
    : {
        field: 'name',
        headerName: 'Policy Name',
        width: 250,
      };

  const columns = [
    { ...nameColumn },
    {
      field: '_id',
      headerName: 'Policy ID',
      width: 250,
    },
    {
      field: 'enabledProperties',
      headerName: 'Enabled Properties',
      width: 250,
      sortable: false,
      renderCell: (params: GridRenderCellParams<Partial<any>>) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {params.row.enabledProperties
              .filter((prop: Partial<Property>) => prop?._id)
              .map((prop: Partial<Property>) => (
                <div
                  style={{ display: 'flex', flexDirection: 'row' }}
                  key={prop.title}
                >
                  {prop.title ?? 'Unnamed Property'}
                </div>
              ))}
          </div>
        );
      },
    },
    {
      field: 'assignedProperties',
      headerName: 'Assigned Properties',
      width: 250,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return params.row?.assignedProperties
          .filter((prop: Partial<Property>) => prop?._id && prop?.title)
          .map((prop: Partial<Property>) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {prop.title}
            </div>
          ));
      },
    },
    {
      field: 'assignedUnits',
      headerName: 'Assigned Units',
      width: 250,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.assignedUnits
          .filter((unit: Partial<Unit>) => unit?._id)
          .map((unit: Partial<Unit>) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {unit.name ?? 'Unnamed Unit'}
            </div>
          ));
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 350,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<Partial<ZonePolicy>>) => (
        <div>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(`/zone-policies/${params.row?._id}`);
            }}
            variant="contained"
            size="small"
            style={{ marginLeft: 0 }}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const {
    data: zoneData,
    loading: zoneDataLoading,
    error: zoneDataError,

    // subscribeToMore,
  } = useZonePolicyListQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    if (zoneDataError) {
      Notifier.error(
        'There was a problem fetching the zone policies: ',
        zoneDataError,
      );
    }
  }, [zoneDataError]);

  useEffect(() => {
    const bottomMenu: BottomMenuItems = [
      {
        items: [
          {
            id: 'menu-item-add-zone-policy',
            label: 'Add Zone Policy',
            icon: <Add fontSize="small" />,
            action: () => navigate(pathname + '/create'),
            permit: {
              action: 'create',
              subject: 'ZonePolicy',
            },
          },
        ],
      },
    ];
    setContextMenuItems(bottomMenu);
    return () => {
      setContextMenuItems(undefined);
    };
  }, [pathname, navigate, setContextMenuItems]);

  const handleRowClick = (params: GridRowParams) =>
    navigate(`/zone-policies/${params.row?._id}`);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        cursor: 'pointer',
      }}
    >
      <DataTable
        {...(isSmall ? MobileListDataTableProps : {})}
        getRowId={(zonePolicy: any) => {
          // TODO: Peter: Include Type
          return zonePolicy._id;
        }}
        additionalProps={{
          onRowClick: (i: GridRowParams) => handleRowClick(i),
        }}
        rowHeight={isSmall ? 70 : undefined}
        getRowHeight={
          isSmall
            ? undefined
            : ({ model: setpointProfile }) => {
                return (
                  Math.max(
                    1,
                    (setpointProfile.enabledProperties ?? []).length,
                    (setpointProfile.assignedProperties ?? []).length,
                    (setpointProfile.assignedUnits ?? []).length,
                  ) *
                    25 +
                  15
                );
              }
        }
        columns={columns}
        columnVisibilityModel={{
          actions: false,
          _id: false,
          enabledProperties: !isSmall,
          assignedProperties: !isSmall,
          assignedUnits: !isSmall,
        }}
        rows={zoneData?.zonePolicies ?? []}
        loading={zoneDataLoading}
      />
    </div>
  );
}
