export const defaultAbsoluteLimits = {
  C: {
    heat: {
      min: 7,
      max: 30,
    },
    cool: {
      min: 16,
      max: 32,
    },
  },
  F: {
    heat: {
      min: 45,
      max: 86,
    },
    cool: {
      min: 61,
      max: 90,
    },
  },
};
