import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';

import DataTable from '../../../shared/data-table';
import { useNavigate } from 'react-router-dom';

import { ViewPropertyContentProps } from '../../types/viewPropertyContentProps';
import { MyUser as User } from './property-view';

export function PropertyAccessList({ data }: ViewPropertyContentProps) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const columns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      maxWidth: isSmall ? 200 : 350,
      renderCell: (params: GridRenderCellParams<User>) => {
        return params.row.email;
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      maxWidth: 200,
      renderCell: (params: GridRenderCellParams<User>) => {
        return params.row.name;
      },
    },
    {
      field: 'isViewer',
      headerName: isSmall ? 'View' : 'Viewer',
      flex: 1,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      maxWidth: isSmall ? 46 : 80,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.authorizationProfile.isViewer ? (
          <CheckIcon sx={{ fontSize: '16px' }} />
        ) : null;
      },
    },
    {
      field: 'isInstaller',
      headerName: isSmall ? 'Install' : 'Installer',
      flex: 1,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      maxWidth: isSmall ? 55 : 80,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.authorizationProfile.isInstaller ? (
          <CheckIcon sx={{ fontSize: '16px' }} />
        ) : null;
      },
    },
    {
      field: 'isManager',
      headerName: isSmall ? 'Manage' : 'Manager',
      flex: 1,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      maxWidth: isSmall ? 70 : 85,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.authorizationProfile.isManager ? (
          <CheckIcon sx={{ fontSize: '16px' }} />
        ) : null;
      },
    },
  ];

  const navigate = useNavigate();

  const handleRowClick = (params: GridRowParams) => {
    const { row } = params;
    const uid = row?._id;

    if (uid) {
      navigate(`/users/${uid}/tab/general`);
    } else {
      navigate('/users');
    }
  };

  const rowCount = (data?.authorizedUsers ?? []).length;

  return data ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      <DataTable
        getRowId={(user: Partial<User>) => {
          return user._id;
        }}
        sxProps={{ border: 'none' }}
        additionalProps={{
          onRowClick: (i: GridRowParams) => handleRowClick(i),
        }}
        hideFooterPagination={rowCount < 51}
        hideFooter={rowCount < 51}
        columns={columns}
        suppressAutoPageSize={true}
        columnVisibilityModel={{
          email: !isSmall,
        }}
        rowHeight={isSmall ? 50 : undefined}
        rows={data.authorizedUsers ?? []}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
      />
    </div>
  ) : null;
}
