import React, { useEffect } from 'react';
import { Gpio, useGpioDetailQuery } from '../../../../types/generated-types';
import BorderedSection from '../../shared/borderedSection';
import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';
import Typography from '@mui/material/Typography';

export const GpioComponent = ({
  deviceId,
  role,
}: {
  deviceId: string;
  role: string;
}) => {
  const [device, setDevice] = React.useState<Partial<Gpio>>();
  const {
    data,
    // TODO: Loading_error: deal with error/loading for GPIO detail query
    // error,
    // loading
  } = useGpioDetailQuery({
    variables: { id: deviceId ?? '' },
  });

  useEffect(() => {
    if (data?.gPIOById) {
      setDevice(data.gPIOById as Partial<Gpio>);
    }
  }, [data]);

  const isAlarm = role === 'Alarm';
  const isActive = device?.state?.value === '1';

  let color: string, backgroundColor: string, label: string;
  if (isAlarm) {
    color = isActive ? 'red' : 'limegreen';
    backgroundColor = isActive ? 'lightpink' : 'lightgreen';
    label = isActive ? 'Alert' : 'Ok';
  } else {
    color = isActive ? 'limegreen' : 'gray';
    backgroundColor = isActive ? 'lightgreen' : 'lightgray';
    label = isActive ? 'On' : 'Off';
  }

  return device ? (
    <div
      style={{
        flexDirection: 'column',
        marginTop: '-12px',
      }}
    >
      <BorderedSection
        error={isAlarm && isActive}
        title={device.name ?? `Unnamed ${role}`}
        style={{
          margin: '8px 4px 14px 4px',
          backgroundColor,
        }}
        ccStyle={{ padding: '4px', marginTop: '-6px' }}
      >
        <Typography align="center" sx={{ m: 0, color }} variant="h4">
          {label}
        </Typography>
      </BorderedSection>
    </div>
  ) : (
    <DeviceLoadingSkeleton width={80} sx={{ m: '2px' }} />
  );
};
