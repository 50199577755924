import React from 'react';
import { Skeleton, SkeletonProps } from '@mui/material';

interface DeviceLoadingSkeletonProps extends SkeletonProps {
  size?: 'small' | 'medium' | 'large';
}

/**
 * Skeleton to display while a zone or panel device is loading
 */
export default function DeviceLoadingSkeleton(
  props: DeviceLoadingSkeletonProps,
) {
  const { size, sx, ...rest } = props;
  let height = 50;
  if (size === 'medium') {
    height = 100;
  } else if (size === 'large') {
    height = 200;
  }
  return (
    <Skeleton
      variant="rounded"
      width="100%"
      height={height}
      sx={{
        mt: '4px',
        ...sx,
      }}
      {...rest}
    />
  );
}
