import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface UpdatePendingProps {
  status: 'submitted' | 'awaiting';
  padding?: number;
}

export function UpdatePending({
  status = 'submitted',
  padding = 2,
}: UpdatePendingProps) {
  return (
    <Box
      alignItems={'center'}
      justifyContent={'center'}
      sx={{
        padding: `${padding}px 10px`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CircularProgress color={status === 'submitted' ? 'info' : 'success'} />
      <div>
        {status === 'submitted'
          ? 'Sending request to device ...'
          : 'Awaiting configuration change confirmation'}
      </div>
    </Box>
  );
}
