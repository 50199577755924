import React from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

/* MUI */
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

/* Icons */
import CheckBoxOutlineBlankOutlined from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

/* Types */
import {
  ChartType,
  PowerMonitor,
  usePowerMonitorDetailQuery,
  usePowerMonitorDetailUpdateSubscription,
} from '../../../../types/generated-types';

/* Shared */
import { DeviceInformation } from '../shared-ui/device-info';
import BorderedSection from '../../shared/borderedSection';
import { PanelFrame } from '../shared-ui/panel-frame';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
import { Log } from '../../../system/services/logger';

const MatchChartType = {
  Power: [ChartType.PmPowerFactor, ChartType.PmActivePower],
  'Power Factor (%)': [ChartType.PmPowerFactor],
  'Voltage (V)': [ChartType.PmVoltage],
  'Current (A)': [ChartType.PmCurrent],
  'Active Power (W)': [ChartType.PmActivePower],
  'Active Energy FWD (kWh)': [ChartType.PmActiveEnergyFwd],
  'Active Energy REV (kWh)': [ChartType.PmActiveEnergyRev],
};

interface RowItem {
  label: string;
  value: string;
}

interface RowSpec {
  label: string;
  items: RowItem[];
}

type PowerMetric = {
  value: number;
};

type SiUnits = 'W' | 'V' | 'A' | 'kWh' | '%';

function makeItemsFor<T>(metric: string, device: T, units: SiUnits) {
  return ['A', 'B', 'C'].reduce((a, c) => {
    const value = ((d) => {
      const v = d[`phase${c}${metric}` as keyof T] as PowerMetric;
      if (v === null) return null;
      return v.value;
    })(device);

    if (value === null) return a;

    return a.concat({
      label: `Phase ${c}`,
      value: `${formatNumber(value, units)}`,
    });
  }, [] as RowItem[]);
}

function formatNumber(x: number | undefined | null = 0.0, units: SiUnits) {
  const IS_PERCENTAGE = units === '%';

  if (x === null) {
    Log.error('[format-number] invalid entry', x);
    return undefined;
  }
  if (IS_PERCENTAGE && x > 1) {
    Log.error('[format-number] invalid percentage');
    return undefined;
  }

  const _x = IS_PERCENTAGE ? x * 100 : x;

  if (_x < 100) {
    return `${_x.toLocaleString('en-US', { maximumFractionDigits: 1 })} ${units}`;
  }
  return `${_x.toLocaleString('en-US', {
    maximumFractionDigits: 0,
  })} ${units}`;
}

const rows = (device: Partial<PowerMonitor>) => {
  const res: RowSpec[] = [];
  if (device.supportsTotalPowerFactor || device.supportsTotalActivePower) {
    const items: RowItem[] = [];
    if (device.supportsTotalPowerFactor) {
      items.push({
        label: 'Total Power Factor',
        value: `${formatNumber(device.totalPowerFactor?.value, '%')}`,
      });
    }
    if (device.supportsTotalActivePower) {
      items.push({
        label: 'Total Active Power',
        value: `${formatNumber(device.totalActivePower?.value, 'W')}`,
      });
    }
    res.push({
      label: 'Power',
      items,
    });
  }
  if (device.supportsPhasedPowerFactors) {
    res.push({
      label: 'Power Factor (%)',
      items: makeItemsFor('PowerFactor', device, '%'),
    });
  }
  res.push({
    label: 'Voltage (V)',
    items: makeItemsFor('Voltage', device, 'V'),
  });

  res.push({
    label: 'Current (A)',
    items: makeItemsFor('Current', device, 'A'),
  });
  if (device.supportsPhasedActivePower) {
    res.push({
      label: 'Active Power (W)',
      items: makeItemsFor('ActivePower', device, 'W'),
    });
  }
  if (device.supportsPhasedActiveEnergyFwd) {
    res.push({
      label: 'Active Energy FWD (kWh)',
      items: makeItemsFor('ActiveEnergyFwd', device, 'kWh'),
    });
  }
  if (device.supportsPhasedActiveEnergyRev) {
    res.push({
      label: 'Active Energy REV (kWh)',
      items: makeItemsFor('ActiveEnergyRev', device, 'kWh'),
    });
  }

  if (
    device.supportsTotalActiveEnergyFwd ||
    device.supportsTotalActiveEnergyRev
  ) {
    const items: RowItem[] = [];
    if (device.supportsTotalActiveEnergyFwd) {
      items.push({
        label: 'Active Energy FWD',
        value: `${formatNumber(device.threePhaseTotalActiveEnergyFwd?.value, 'kWh')}`,
      });
    }
    if (device.supportsTotalActiveEnergyRev) {
      items.push({
        label: 'Active Energy REV',
        value: `${formatNumber(device.threePhaseTotalActiveEnergyRev?.value, 'kWh')}`,
      });
    }

    res.push({
      label: 'Total Energy',
      items,
    });
  }

  return res;
};

function handleIconSelect(types: ChartType[], selectedItems: ChartType[][]) {
  const isSelected = selectedItems.indexOf(types);
  if (isSelected !== -1) {
    return <CheckBoxIcon sx={{ fontSize: 'inherit' }} />;
  } else {
    return <CheckBoxOutlineBlankOutlined sx={{ fontSize: 'inherit' }} />;
  }
}

export function PowerMonitorComponent({ deviceId }: { deviceId: string }) {
  const navigate = useNavigate();
  const { data, loading } = usePowerMonitorDetailQuery({
    variables: { id: deviceId },
  });

  usePowerMonitorDetailUpdateSubscription({
    variables: { ids: [deviceId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });
  const [device, setDevice] = React.useState<Partial<PowerMonitor>>();
  const [deviceInfo, setDeviceInfo] = React.useState<
    Record<string, DeviceInformation>
  >({});

  type DeviceInfo = Record<string, string | Record<string, string>>;

  const [toggled, toggleMultiSelect] = React.useState(false);
  const [selectedItems, selectItems] = React.useState<ChartType[][]>([]);
  const [enableExecuteGraphWizard, setEnableExecuteGraphWizard] =
    React.useState(false);

  function loadCharts() {
    const selChartTypes = (selectedItems ?? []).reduce(
      (val: Set<ChartType>, chartTypes: ChartType[]) => {
        [...chartTypes].forEach((type) => val.add(type));
        return val;
      },
      new Set<ChartType>(),
    );
    const charts = [...selChartTypes].join(', ');
    const now = dayjs();
    const params = {
      devices: device?.deviceId || '',
      charts,
      period: '24h',
      start: now.subtract(1, 'day').format(),
      end: now.format(),
    };
    navigate({
      pathname: './charts',
      search: `?${createSearchParams(params)}`,
    });
  }

  function handleGraphWizardClicked() {
    if (toggled) {
      if (enableExecuteGraphWizard) {
        loadCharts();
      } else {
        return;
      }
    }
    toggleMultiSelect(!toggled);
  }

  function handleCancelGraphWizard() {
    if (toggled && selectedItems.length > 0) {
      selectItems([]);
    }
    toggleMultiSelect(!toggled);
  }

  const handleClick = (types: ChartType[]) => {
    const exists = selectedItems.indexOf(types);
    if (exists === -1) {
      selectItems((prevItems) => [...prevItems, types]);
    } else {
      selectItems(selectedItems.filter((item, i) => i !== exists));
    }
  };

  React.useEffect(() => {
    if (data?.powerMonitorById) {
      setDevice(data.powerMonitorById as Partial<PowerMonitor>);
    }
  }, [data]);

  React.useEffect(() => {
    if (device) {
      const zoneInfo: DeviceInfo = {
        Zone: device.panel?.name || 'Unnamed Panel',
        'Zone ID': device.panel?._id || 'Unidentified Panel',
        Source: device.source?.name || 'Unnamed Source',
      };

      const basicInfo: DeviceInfo = {
        baseInfo: {
          Model:
            device.meta?.model || device.meta?.modelNumber || 'Unknown model',
          Version: device.meta?.version || 'Unknown version',
        },
        Name: device.name || 'Unnamed Thermostat',
        'Device Type': device.typeDisplayName || 'Unknown device type',
        'Device ID': device.deviceId || 'Unknown ID',
      };

      const lastUpdated: DeviceInfo = {
        'Last Updated': device.timestamp
          ? dayjs(device.timestamp).fromNow()
          : 'Never reported',
      };

      const frequencyInfo: DeviceInformation = {};
      if (device.supportsPhasedFrequencies) {
        const phasedFrequencyUnits = (
          device.deviceMetrics?.find((m) => m?.key === 'phaseAFrequency')?.value
            ?.unit || 'Unknown frequency unit'
        )
          ?.toUpperCase()
          ?.startsWith('HERTZ')
          ? 'Hz'
          : '';

        frequencyInfo['Phased Frequencies'] = {
          'Phase A': `${
            device.phaseAFrequency?.value?.toFixed(2) || 'Unknown'
          } ${phasedFrequencyUnits}`,
          'Phase B': `${
            device.phaseBFrequency?.value?.toFixed(2) || 'Unknown'
          } ${phasedFrequencyUnits}`,
          'Phase C': `${
            device.phaseCFrequency?.value?.toFixed(2) || 'Unknown'
          } ${phasedFrequencyUnits}`,
        };
      }
      if (device.supportsTotalFrequency) {
        const frequencyUnits = (
          device.deviceMetrics?.find((m) => m?.key === 'frequency')?.value
            ?.unit || 'Unknown frequency unit'
        )
          ?.toUpperCase()
          ?.startsWith('HERTZ')
          ? 'Hz'
          : '';
        frequencyInfo['Total Frequency'] = `${
          device.frequency?.value?.toFixed(2) || 0
        } ${frequencyUnits}`;
      }

      setDeviceInfo({
        'Zone Info': zoneInfo,
        'Basic Info': basicInfo,
        'Last Updated At': lastUpdated,
        'Frequency Info': frequencyInfo,
      });
    }
  }, [device]);

  React.useEffect(() => {
    setEnableExecuteGraphWizard(selectedItems.length > 0 && toggled);
  }, [selectedItems, toggled]);

  return device ? (
    loading ? (
      <Card>
        <Skeleton variant="rectangular" />
      </Card>
    ) : (
      <PanelFrame
        iconType="ic:outline-power"
        panel={device.panel}
        infoEntries={deviceInfo}
        toggleGraphWizard={handleGraphWizardClicked}
        cancelGraphWizard={handleCancelGraphWizard}
        enableExecuteGraphWizard={enableExecuteGraphWizard}
        editingEnabled={false}
      >
        {rows(device).map((row, index) => {
          return (
            <div key={`${row.label}-row-div-${index}`}>
              <BorderedSection
                key={`${row.label}-section-${index}`}
                title={row.label}
                style={{
                  padding: '0px',
                  margin: '14px 4px 0px 4px',
                  backgroundColor: '#f4f5e6',
                }}
                ccStyle={{
                  padding: '2px 2px 6px 2px',
                  margin: '0px',
                  marginTop: '-3px',
                }}
              >
                <div
                  key={`${row.label}-section-div-${index}`}
                  style={{
                    display: 'inline-flex',
                    width: '100%',
                  }}
                >
                  {!toggled ? (
                    <></>
                  ) : (
                    <Button
                      key={`${row.label}-select-button-${index}`}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#000',
                        fontSize: '1.4rem',
                      }}
                      onClick={() => {
                        handleClick(
                          MatchChartType[
                            row.label as keyof typeof MatchChartType
                          ],
                        );
                      }}
                    >
                      {handleIconSelect(
                        MatchChartType[
                          row.label as keyof typeof MatchChartType
                        ],
                        selectedItems,
                      )}
                    </Button>
                  )}
                  {row.items.map((item, index2) => (
                    <BorderedSection
                      key={`${row.label}-phase-${index2}-section-${index}`}
                      title={item.label}
                      style={{
                        margin: '0px 2px',
                        backgroundColor: 'white',
                      }}
                      ccStyle={{ padding: '0px', marginTop: '-6px' }}
                    >
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {item.value}
                      </span>
                    </BorderedSection>
                  ))}
                </div>
              </BorderedSection>
            </div>
          );
        })}
      </PanelFrame>
    )
  ) : (
    <Card>
      <Skeleton variant="rectangular" />
    </Card>
  );
}
