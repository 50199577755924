import React, { useEffect, useState } from 'react';
import {
  Gpio,
  Panel,
  useGpioPanelDetailQuery,
  useGpioPanelDetailUpdateSubscription,
  useGpioDetailUpdateSubscription,
  useEditDeviceMutation,
} from '../../../../types/generated-types';

import { DeviceInformation } from '../shared-ui/device-info';
import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import ContrastIcon from '@mui/icons-material/Contrast';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
import { Notifier } from '../../../system/services/notificationManager';
import { GpioComponent } from '../gpio/gpio';
import moment from 'moment';
import { MenuAction, PanelFrame, ViewMode } from '../shared-ui/panel-frame';

type GpioNameMap = { [deviceId: string]: string };

export function GpioPanelComponent({
  panelId,
  role,
  gpioDevices,
}: {
  panelId: string;
  role: string;
  gpioDevices: Partial<Gpio>[];
}) {
  const [panel, setPanel] = useState<Partial<Panel>>();
  const [deviceInfo, setDeviceInfo] = useState<DeviceInformation[]>([]);
  const [viewMode, setViewMode] = useState(ViewMode.NORMAL);
  const [initialNames, setInitialNames] = useState<GpioNameMap>({});
  const [editedNames, setEditedNames] = useState<GpioNameMap>({});

  const { data } = useGpioPanelDetailQuery({
    variables: { id: panelId },
  });

  useGpioPanelDetailUpdateSubscription({
    variables: { ids: [panelId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  // Mutation to update GPIO names
  const [saveDevice] = useEditDeviceMutation();

  // Update cache on update of GPIO names
  useGpioDetailUpdateSubscription({
    variables: { ids: gpioDevices.map((d) => d._id ?? '') },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (data?.panel) {
      setPanel(data.panel as Partial<Panel>);
    }
  }, [data]);

  useEffect(() => {
    if (panel) {
      const panelInfo: DeviceInformation = {
        Panel: panel.displayName ?? 'Unnamed',
        'Panel ID': panel._id ?? 'Unidentified',
        'Panel Type': panel.type ?? 'Unknown',
        Source: panel.source?.name ?? 'Unnamed source',
      };

      const devicesInfo: DeviceInformation[] = [...(panel.devices || [])]
        // Sort based on deviceId so that order is always consistent, even if device names are edited
        .sort((a, b) => (a.deviceId ?? '').localeCompare(b?.deviceId ?? ''))
        .map((device: Partial<Gpio>) => {
          return {
            'Device ID': device.deviceId ?? device._id ?? 'Unidentified',
            Name: device.name ?? 'Unnamed',
            Type: device.typeDisplayName ?? 'Unknown',
            'Last Updated': moment(device.timestamp).fromNow(),
          };
        });

      setDeviceInfo([panelInfo, ...devicesInfo]);

      // Set initial GPIO names, and a copy that can be edited
      const names: GpioNameMap = {};
      panel.devices?.forEach((d) => {
        names[d.deviceId ?? ''] = d.name ?? '';
      });
      setInitialNames({ ...names });
      setEditedNames({ ...names });
    }
  }, [panel]);

  // Handle menu button clicks (initiating, saving, or reverting GPIO edits)
  async function handleMenuButtonClick(action: MenuAction) {
    switch (action) {
      case MenuAction.EDIT_VALUES:
        // Enter edit mode - no values changed yet
        setViewMode(ViewMode.EDIT_UNCHANGED);
        break;
      case MenuAction.CANCEL:
      case MenuAction.REVERT_CHANGES:
        // Exit edit mode - undo any changes
        setEditedNames({ ...initialNames });
        setViewMode(ViewMode.NORMAL);
        break;
      case MenuAction.SAVE_CHANGES:
        // Update any devices with edited names
        setViewMode(ViewMode.UPDATING);
        for (const deviceId in editedNames) {
          const name = editedNames[deviceId];
          if (hasNameChanges({ [deviceId]: name })) {
            try {
              await saveDevice({ variables: { deviceId, edits: { name } } });
            } catch (error) {
              // Set view mode back to edit so the user can retry
              console.error(error);
              Notifier.error('Error saving names for one or more devices.');
              setViewMode(ViewMode.EDIT);
              return;
            }
          }
        }
        Notifier.success('Successfully saved device names.');
        setInitialNames({ ...editedNames });
        setViewMode(ViewMode.NORMAL);
    }
  }

  // Handle GPIO name edit
  function handleNameChange(deviceId: string, newName: string) {
    const newNames = { ...editedNames, [deviceId]: newName };
    setEditedNames(newNames);
    setViewMode(
      hasNameChanges(newNames) ? ViewMode.EDIT : ViewMode.EDIT_UNCHANGED,
    );
  }

  // Whether any of the edited GPIO names differ from their origional values
  function hasNameChanges(newNames: GpioNameMap): boolean {
    for (const deviceId in newNames) {
      if (newNames[deviceId] !== initialNames[deviceId]) {
        return true;
      }
    }
    return false;
  }

  // Set each GPIO name to its default value, based on role
  function resetNamesToDefaults(gpios: Partial<Gpio>[]) {
    const names: GpioNameMap = {};
    gpios.forEach((gpio, i) => {
      names[gpio.deviceId ?? ''] = `${role} ${i + 1}`;
    });
    setEditedNames(names);
    setViewMode(
      hasNameChanges(names) ? ViewMode.EDIT : ViewMode.EDIT_UNCHANGED,
    );
  }

  // Sort based on deviceId so that order is always consistent, even if device names are edited
  const sortedDevices = [...gpioDevices].sort((a, b) =>
    (a.deviceId ?? '').localeCompare(b?.deviceId ?? ''),
  );
  const isAlarm = role === 'Alarm';
  const isEditMode =
    viewMode === ViewMode.EDIT || viewMode === ViewMode.EDIT_UNCHANGED;
  const title = isEditMode ? `Edit ${role} Names` : `${role} States`;

  return panel ? (
    <PanelFrame
      title={title}
      iconType={
        isAlarm
          ? 'ic:outline-notification-important'
          : 'material-symbols:contrast'
      }
      panel={panel}
      infoEntries={deviceInfo}
      viewMode={viewMode}
      editingEnabled={true}
      handleMenuButtonClick={handleMenuButtonClick}
    >
      {sortedDevices.length > 0 ? (
        !isEditMode ? (
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              flexFlow: 'wrap',
              justifyContent: 'center',
            }}
          >
            {sortedDevices.map((gpio, index) => (
              <GpioComponent
                deviceId={gpio._id ?? ''}
                key={gpio._id ?? index}
                role={role}
              />
            ))}
          </div>
        ) : (
          <div
            style={{
              flexDirection: 'column',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {sortedDevices.map((gpio, i) => (
              <TextField
                key={gpio._id ?? ''}
                label={`${role} ${i + 1} Name`}
                value={editedNames[gpio.deviceId ?? '']}
                onChange={(e) =>
                  handleNameChange(gpio.deviceId ?? '', e.target.value)
                }
                sx={{ marginTop: 1, marginBottom: 1 }}
              />
            ))}
            <Button
              color="info"
              sx={{ marginBottom: 1 }}
              onClick={() => resetNamesToDefaults(sortedDevices)}
            >
              Reset Names to Defaults
            </Button>
          </div>
        )
      ) : (
        <div>No {role} devices configured</div>
      )}
    </PanelFrame>
  ) : (
    <DeviceLoadingSkeleton size="large" />
  );
}
