import React from 'react';
import { Fade, Grid } from '@mui/material';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import TextField from '@mui/material/TextField';
import BorderedSection from '../../shared/borderedSection';
import { AuthorizedUser } from '../../../auth/models';
import { useFormik } from 'formik';
import { FormValues } from '../types/propertyDetailFormValues';
import defaultBuildingImage from '../../../assets/images/defaultBuilding.jpg';

export function PropertyGeneralTab({
  formik,
  onChange,
}: {
  formik: ReturnType<typeof useFormik<FormValues>>;
  onChange: StandardInputProps['onChange'];
}) {
  return (
    <Fade in={true} style={{ transitionDuration: '1100ms' }}>
      <div style={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="propTitle"
              name="propTitle"
              label="Title"
              value={formik.values.propTitle}
              onChange={onChange}
              error={
                formik.touched.propTitle && Boolean(formik.errors.propTitle)
              }
              helperText={formik.touched.propTitle && formik.errors.propTitle}
            />
            <br />
            <br />
            <TextField
              fullWidth
              required
              id="line1"
              name="line1"
              label="Address"
              value={formik.values.line1}
              onChange={onChange}
              error={formik.touched.line1 && Boolean(formik.errors.line1)}
              helperText={formik.touched.line1 && formik.errors.line1}
            />
            <br />
            <br />
            <TextField
              fullWidth
              id="line2"
              name="line2"
              label="Address 2"
              value={formik.values.line2}
              onChange={onChange}
              error={formik.touched.line2 && Boolean(formik.errors.line2)}
              helperText={formik.touched.line2 && formik.errors.line2}
            />
            <br />
            <br />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  required
                  id="city"
                  name="city"
                  label="City"
                  value={formik.values.city}
                  onChange={onChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              <br />
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  required
                  id="state"
                  name="state"
                  label="State"
                  value={formik.values.state}
                  onChange={onChange}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                />
              </Grid>
              <br />
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  required
                  id="postalCode"
                  name="postalCode"
                  label="Postal Code"
                  value={formik.values.postalCode}
                  onChange={onChange}
                  error={
                    formik.touched.postalCode &&
                    Boolean(formik.errors.postalCode)
                  }
                  helperText={
                    formik.touched.postalCode && formik.errors.postalCode
                  }
                />
              </Grid>
              <br />
            </Grid>
            <br />
            <br />
            <Grid container>
              <Grid item xs={9} sx={{ display: 'flex', alignItems: 'end' }}>
                <TextField
                  fullWidth
                  id="imageUrl"
                  name="imageUrl"
                  label="Image Url"
                  value={formik.values.imageUrl}
                  onChange={onChange}
                  error={
                    formik.touched.imageUrl && Boolean(formik.errors.imageUrl)
                  }
                  helperText={formik.touched.imageUrl && formik.errors.imageUrl}
                />
              </Grid>
              <Grid item xs={3}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 1 35%',
                    marginLeft: '10px',
                  }}
                >
                  <img
                    alt="Preview"
                    src={formik.values.imageUrl || defaultBuildingImage}
                    width="100%"
                    style={{
                      objectFit: 'cover',
                      objectPosition: '100% 0',
                      maxHeight: '135px',
                      marginTop: 'auto',
                    }}
                    loading="lazy"
                  />
                </div>
              </Grid>
            </Grid>
            <br />
          </Grid>
          <Grid item xs={12} md={6}>
            <BorderedSection title="Manager">
              <TextField
                fullWidth
                id="propManagerName"
                name="propManagerName"
                variant="standard"
                label="Name"
                value={formik.values.propManagerName}
                onChange={onChange}
                error={
                  formik.touched.propManagerName &&
                  Boolean(formik.errors.propManagerName)
                }
                helperText={
                  formik.touched.propManagerName &&
                  formik.errors.propManagerName
                }
              />
              <br />
              <TextField
                fullWidth
                id="propManagerEmail"
                name="propManagerEmail"
                variant="standard"
                label="Email"
                value={formik.values.propManagerEmail}
                onChange={onChange}
                error={
                  formik.touched.propManagerEmail &&
                  Boolean(formik.errors.propManagerEmail)
                }
                helperText={
                  formik.touched.propManagerEmail &&
                  formik.errors.propManagerEmail
                }
              />
              <br />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="propManagerPhone"
                    name="propManagerPhone"
                    variant="standard"
                    label="Phone"
                    value={formik.values.propManagerPhone}
                    onChange={onChange}
                    error={
                      formik.touched.propManagerPhone &&
                      Boolean(formik.errors.propManagerPhone)
                    }
                    helperText={
                      formik.touched.propManagerPhone &&
                      formik.errors.propManagerPhone
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="propManagerMobile"
                    name="propManagerMobile"
                    variant="standard"
                    label="Mobile"
                    value={formik.values.propManagerMobile}
                    onChange={onChange}
                    error={
                      formik.touched.propManagerMobile &&
                      Boolean(formik.errors.propManagerMobile)
                    }
                    helperText={
                      formik.touched.propManagerMobile &&
                      formik.errors.propManagerMobile
                    }
                  />
                </Grid>
              </Grid>
            </BorderedSection>
            <br />
          </Grid>
          <Grid item xs={12} md={6}>
            <BorderedSection title="Owner">
              <TextField
                fullWidth
                id="propOwnerName"
                name="propOwnerName"
                variant="standard"
                label="Name"
                value={formik.values.propOwnerName}
                onChange={onChange}
                error={
                  formik.touched.propOwnerName &&
                  Boolean(formik.errors.propOwnerName)
                }
                helperText={
                  formik.touched.propOwnerName && formik.errors.propOwnerName
                }
              />
              <br />
              <TextField
                fullWidth
                id="propOwnerEmail"
                name="propOwnerEmail"
                variant="standard"
                label="Email"
                value={formik.values.propOwnerEmail}
                onChange={onChange}
                error={
                  formik.touched.propOwnerEmail &&
                  Boolean(formik.errors.propOwnerEmail)
                }
                helperText={
                  formik.touched.propOwnerEmail && formik.errors.propOwnerEmail
                }
              />
              <br />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="propOwnerPhone"
                    name="propOwnerPhone"
                    variant="standard"
                    label="Phone"
                    value={formik.values.propOwnerPhone}
                    onChange={onChange}
                    error={
                      formik.touched.propOwnerPhone &&
                      Boolean(formik.errors.propOwnerPhone)
                    }
                    helperText={
                      formik.touched.propOwnerPhone &&
                      formik.errors.propOwnerPhone
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="propOwnerMobile"
                    name="propOwnerMobile"
                    variant="standard"
                    label="Mobile"
                    value={formik.values.propOwnerMobile}
                    onChange={onChange}
                    error={
                      formik.touched.propOwnerMobile &&
                      Boolean(formik.errors.propOwnerMobile)
                    }
                    helperText={
                      formik.touched.propOwnerMobile &&
                      formik.errors.propOwnerMobile
                    }
                  />
                </Grid>
              </Grid>
            </BorderedSection>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
}
