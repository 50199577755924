import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { GraphQLError } from 'graphql/error/GraphQLError';

import { MRT_Row } from 'material-react-table';

import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Fade from '@mui/material/Fade';

import BorderedSection from '../../shared/borderedSection';
import { FormValues } from '../types/propertyDetailFormValues';
import { AuthorizedUser } from '../../../auth/models';

import {
  useUpdateAlertTriggerMutation,
  usePropertyWideAlertSubscribersSubscription,
  SendOptInRequestSmsMutation,
  ClearOptInViaSmsMutation,
} from '../../../../types/generated-types';

import { Notifier } from '../../../system/services/notificationManager';

import { PropertyAlertTriggersList } from '../property-alert-triggers-list';
import { handleModelUpdateEvent } from '../../../../helpers/subscriptionUtils';
import { RowData } from '../types/propertyAlertTriggerRowData';
import { PropertyAlertTriggerUpdateDialog } from './property-alert-trigger-update-dialog';

import { SmsOptInStatus } from '../../../../common/types';
import { PropertySmsOptedOutHelpDialog } from './property-sms-opted-out-help-dialog';
import { SmsOptInMobileNumberInput } from './smsOptInMobileNumberInput';
import { EmailOptInEmailAddressInput } from './emailOptInEmailAddressInput';

const SpacerElement = ({
  innerRef = null,
}: {
  innerRef: React.RefObject<HTMLDivElement> | null;
}) => (
  <div
    ref={innerRef}
    style={{
      width: '100%',
    }}
  >
    &nbsp;
  </div>
);

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabPanel = ({
  children,
  value,
  index,
  ...other
}: {
  children?: React.ReactNode;
  index: number;
  value: number;
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>{children}</Box>
    )}
  </div>
);

export function PropertyAlertsTab(props: {
  propertyId: string;
  currentUser: AuthorizedUser;
  formik: ReturnType<typeof useFormik<FormValues>>;
  onChange: StandardInputProps['onChange'];
}) {
  const navigate = useNavigate();
  const [updateAlertTrigger] = useUpdateAlertTriggerMutation();
  const { viewId, alertViewId } = useParams<{
    viewId: string;
    alertViewId: string;
  }>();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const contactsElementRef = useRef<HTMLDivElement>(null);
  const triggersElementRef = useRef<HTMLDivElement>(null);
  const triggerListElementRef = useRef<HTMLDivElement>(null);
  const spacerElementRef = useRef<HTMLDivElement>(null);

  const [occupiedHeight, setOccupiedHeight] = useState(0);
  const [selectedAlertTriggerRow, setSelectedAlertTriggerRow] =
    useState<MRT_Row<RowData> | null>(null);
  const [alertTriggerEditorOpen, setAlertTriggerEditorOpen] = useState(false);

  const [value, setValue] = useState(0);

  const ValueToViewIdMap: Record<number, string> = {
    0: 'triggers',
    1: 'subscribers',
  };

  const ViewIdToValueMap: Record<string, number> = {
    triggers: 0,
    subscribers: 1,
  };

  usePropertyWideAlertSubscribersSubscription({
    variables: { propertyId: props.propertyId ?? '' },
    fetchPolicy: 'no-cache',
    onData: handleModelUpdateEvent(({ data: subscriptionData }) => {
      const newContact = subscriptionData?.data?.propertyEventsByIds
        ?.contact ?? {
        smsOptInStatus1: props.formik.values.smsOptInStatus1,
        smsOptInStatus2: props.formik.values.smsOptInStatus2,
        smsOptInStatus3: props.formik.values.smsOptInStatus3,
        smsOptInTimestamp1: props.formik.values.smsOptInTimestamp1,
        smsOptInTimestamp2: props.formik.values.smsOptInTimestamp2,
        smsOptInTimestamp3: props.formik.values.smsOptInTimestamp3,
      };
      props.formik.resetForm({
        values: {
          ...props.formik.values,
          smsOptInStatus1: newContact.smsOptInStatus1 ?? '',
          smsOptInStatus2: newContact.smsOptInStatus2 ?? '',
          smsOptInStatus3: newContact.smsOptInStatus3 ?? '',
          smsOptInTimestamp1: newContact.smsOptInTimestamp1 ?? 0,
          smsOptInTimestamp2: newContact.smsOptInTimestamp2 ?? 0,
          smsOptInTimestamp3: newContact.smsOptInTimestamp3 ?? 0,
        },
      });
    }),
  });

  useEffect(() => {
    if (alertViewId) {
      setValue(ViewIdToValueMap[alertViewId] ?? 0);
    } else if (viewId === 'alerts') {
      navigate('triggers', { replace: true });
    }
  }, [alertViewId]);

  const adjustTriggersElementHeight = useCallback(() => {
    if (
      (contactsElementRef.current || triggersElementRef.current) &&
      spacerElementRef.current
    ) {
      const remainingHeight = isSmall ? 350 : 395;

      setOccupiedHeight(remainingHeight);

      if (alertViewId === 'triggers') {
        if (triggerListElementRef.current) {
          triggerListElementRef.current.style.height = `calc(100vh - ${remainingHeight}px)`;
          triggerListElementRef.current.style.maxHeight = `calc(100vh - ${remainingHeight}px)`;
        }
        spacerElementRef.current.style.height = '0px';
        spacerElementRef.current.style.maxHeight = '0px';
      } else if (alertViewId === 'subscribers') {
        if (isSmall) {
          spacerElementRef.current.style.height = '0px';
          spacerElementRef.current.style.maxHeight = '0px';
        } else {
          spacerElementRef.current.style.height = `calc(100vh - ${
            remainingHeight + 290
          }px)`;
          spacerElementRef.current.style.maxHeight = `calc(100vh - ${
            remainingHeight + 290
          }px)`;
        }
      }
    } else {
      console.log('no refs');
    }
  }, [
    alertViewId,
    isSmall,
    alertViewId,
    contactsElementRef.current,
    triggersElementRef.current,
    spacerElementRef.current,
  ]);

  useEffect(() => {
    adjustTriggersElementHeight();
  }, [
    isSmall,
    alertViewId,
    contactsElementRef.current,
    triggersElementRef.current,
    spacerElementRef.current,
  ]);

  const handleRowClick = (row: MRT_Row<RowData>) => {
    const bid = row?.id;

    if (bid && row) {
      setSelectedAlertTriggerRow(row);
      setAlertTriggerEditorOpen(true);
    }
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    if (props.formik.dirty && newValue === 2) {
      Notifier.warn(
        'Please save all other changes before editing a setpoint profile override.',
      );
    } else if (props.formik.dirty && newValue === 3) {
      Notifier.warn(
        'Please save all other changes before editing a zone policy override.',
      );
    } else {
      if (newValue !== value && alertViewId) {
        const regex = /\/([^/]+)\/?$/;
        const newUrl = pathname.replace(
          regex,
          `/${ValueToViewIdMap[newValue]}`,
        );

        navigate(newUrl);
      }
    }
  };

  const updateSmsFormData = useCallback(
    (
      data:
        | SendOptInRequestSmsMutation['sendOptInRequestSms']
        | ClearOptInViaSmsMutation['clearOptInViaSms'],
      errors: readonly GraphQLError[] | undefined,
    ) => {
      if (errors?.length) {
        Notifier.error(`Unable to send test SMS: ${errors[0].message}`);
      } else if (data) {
        const newContact = data.contact ?? {
          smsOptInStatus1: props.formik.values.smsOptInStatus1,
          smsOptInStatus2: props.formik.values.smsOptInStatus2,
          smsOptInStatus3: props.formik.values.smsOptInStatus3,
          smsOptInTimestamp1: props.formik.values.smsOptInTimestamp1,
          smsOptInTimestamp2: props.formik.values.smsOptInTimestamp2,
          smsOptInTimestamp3: props.formik.values.smsOptInTimestamp3,
        };
        props.formik.resetForm({
          values: {
            ...props.formik.values,
            smsOptInStatus1: newContact.smsOptInStatus1 ?? '',
            smsOptInStatus2: newContact.smsOptInStatus2 ?? '',
            smsOptInStatus3: newContact.smsOptInStatus3 ?? '',
            smsOptInTimestamp1: newContact.smsOptInTimestamp1 ?? 0,
            smsOptInTimestamp2: newContact.smsOptInTimestamp2 ?? 0,
            smsOptInTimestamp3: newContact.smsOptInTimestamp3 ?? 0,
          },
        });
      }
      Notifier.success('Request completed successfully.');
    },
    [props.formik],
  );

  const [optOutHelpDialogOpen, setOptOutHelpDialogOpen] = useState(false);
  const [optedOutMobileNumber, setOptedOutMobileNumber] = useState<string>('');
  const handleCloseOptOutHelpDialog = () => {
    setOptedOutMobileNumber('');
    setOptOutHelpDialogOpen(false);
  };

  const handleOptedOutHelpRequest = (mobileNumber: string) => {
    setOptedOutMobileNumber(mobileNumber);
    setOptOutHelpDialogOpen(true);
  };

  return (
    <Fade in={true} style={{ transitionDuration: '1100ms' }}>
      <div
        style={
          isSmall
            ? { width: '100%' }
            : { width: '100%', paddingLeft: '20px', paddingRight: '20px' }
        }
      >
        <Tabs
          centered
          TabIndicatorProps={{
            style: { color: '#000' },
          }}
          sx={{
            marginBottom: '0px',
            marginTop: '-8px',
            minHeight: '0px',
            borderBottom: isSmall ? '1px solid lightgray' : 'none',
            paddingBottom: '8px',
            '.MuiButtonBase-root': {
              border: '1px solid gray',
              borderRadius: '4px',
              color: '#000',
              fontSize: '12px',
              fontWeight: 'bold',
              padding: '4px 6px',
              // margin: '0px',
              minHeight: '0px',
              minWidth: '0px',
              textTransform: 'none',
            },
            '.MuiButtonBase-root.Mui-selected': {
              backgroundColor: 'lightgreen',
              color: 'black',
            },
            '.MuiTabs-indicator': {
              display: 'none',
            },
          }}
          indicatorColor="secondary"
          textColor="secondary"
          value={value}
          onChange={handleTabChange}
          aria-label="Property Tabs"
        >
          <Tab
            sx={{ marginRight: '10px' }}
            label="Alert Triggers"
            {...a11yProps(0)}
          />
          <Tab label="Property-wide Subscriptions" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div
            ref={triggersElementRef}
            style={{
              border: '1px solid lightgray',
              borderRadius: '4px',
              boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.3)',
              display: 'flex',
              flexDirection: 'column',
              margin: '2px',
              padding: '4px',
              marginTop: isSmall ? '8px' : '24px',
            }}
          >
            <PropertyAlertTriggersList
              innerRef={triggerListElementRef}
              occupiedHeight={occupiedHeight}
              onRowClick={handleRowClick}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div
            ref={contactsElementRef}
            style={{
              border: '1px solid lightgray',
              borderRadius: '4px',
              boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.3)',
              display: 'flex',
              flexDirection: 'column',
              margin: '2px',
              padding: '4px',
              marginTop: isSmall ? '8px' : '24px',
            }}
          >
            <BorderedSection
              title="Contact 1"
              style={{ marginTop: '4px' }}
              ccStyle={{
                padding: '0px 4px 0px 8px',
                margin: '-6px 0px 0px 0px',
              }}
            >
              <EmailOptInEmailAddressInput
                id="alertEmailOne"
                index={0}
                propertyId={props.propertyId}
                email={props.formik.values.alertEmailOne}
                onChange={props.onChange}
                isSmall={isSmall}
                touched={!!props.formik.touched.alertEmailOne}
                errorText={props.formik.errors.alertEmailOne}
              />
              <br />
              <SmsOptInMobileNumberInput
                id="alertMobileOne"
                index={0}
                isDirty={props.formik.dirty}
                propertyId={props.propertyId}
                smsOptInStatus={
                  props.formik.values.smsOptInStatus1 as SmsOptInStatus
                }
                mobileNumber={props.formik.values.alertMobileOne}
                onChange={props.onChange}
                isSmall={isSmall}
                touched={!!props.formik.touched.alertMobileOne}
                errorText={props.formik.errors.alertMobileOne}
                postOpUpdater={updateSmsFormData}
                handleOptOutHelpRequest={handleOptedOutHelpRequest}
              />
            </BorderedSection>
            <BorderedSection
              title="Contact 2"
              style={{ marginTop: '10px' }}
              ccStyle={{
                padding: '0px 4px 0px 8px',
                margin: '-6px 0px 0px 0px',
              }}
            >
              <EmailOptInEmailAddressInput
                id="alertEmailTwo"
                index={1}
                propertyId={props.propertyId}
                email={props.formik.values.alertEmailTwo}
                onChange={props.onChange}
                isSmall={isSmall}
                touched={!!props.formik.touched.alertEmailTwo}
                errorText={props.formik.errors.alertEmailTwo}
              />
              <br />
              <SmsOptInMobileNumberInput
                id="alertMobileTwo"
                index={1}
                isDirty={props.formik.dirty}
                propertyId={props.propertyId}
                smsOptInStatus={
                  props.formik.values.smsOptInStatus2 as SmsOptInStatus
                }
                mobileNumber={props.formik.values.alertMobileTwo}
                onChange={props.onChange}
                isSmall={isSmall}
                touched={!!props.formik.touched.alertMobileTwo}
                errorText={props.formik.errors.alertMobileTwo}
                postOpUpdater={updateSmsFormData}
                handleOptOutHelpRequest={handleOptedOutHelpRequest}
              />
            </BorderedSection>
            <BorderedSection
              title="Contact 3"
              style={{ marginTop: '10px' }}
              ccStyle={{
                padding: '0px 4px 0px 8px',
                margin: '-6px 0px 0px 0px',
              }}
            >
              <EmailOptInEmailAddressInput
                id="alertEmailThree"
                index={2}
                propertyId={props.propertyId}
                email={props.formik.values.alertEmailThree}
                onChange={props.onChange}
                isSmall={isSmall}
                touched={!!props.formik.touched.alertEmailThree}
                errorText={props.formik.errors.alertEmailThree}
              />
              <br />
              <SmsOptInMobileNumberInput
                id="alertMobileThree"
                index={2}
                isDirty={props.formik.dirty}
                propertyId={props.propertyId}
                smsOptInStatus={
                  props.formik.values.smsOptInStatus3 as SmsOptInStatus
                }
                mobileNumber={props.formik.values.alertMobileThree}
                onChange={props.onChange}
                isSmall={isSmall}
                touched={!!props.formik.touched.alertMobileThree}
                errorText={props.formik.errors.alertMobileThree}
                postOpUpdater={updateSmsFormData}
                handleOptOutHelpRequest={handleOptedOutHelpRequest}
              />
            </BorderedSection>
          </div>
        </TabPanel>
        <SpacerElement innerRef={spacerElementRef} />

        <PropertySmsOptedOutHelpDialog
          mobileNumber={optedOutMobileNumber}
          dialogOpen={optOutHelpDialogOpen}
          handleClose={handleCloseOptOutHelpDialog}
        />
        <PropertyAlertTriggerUpdateDialog
          selectedAlertTriggerRow={selectedAlertTriggerRow}
          alertTriggerDialogOpen={alertTriggerEditorOpen}
          handleCancel={() => {
            setAlertTriggerEditorOpen(false);
          }}
          handleSave={async (updatedTrigger: RowData) => {
            if (updatedTrigger?.id) {
              try {
                await updateAlertTrigger({
                  variables: {
                    id: updatedTrigger.id,
                    alertTrigger: {
                      enabled: updatedTrigger.enabled,
                      triggerCriteria: {
                        basisType: updatedTrigger.basisType,
                        basisName: updatedTrigger.basisName,
                        selectionCriteria: updatedTrigger.rawCriteria
                          .map((criterion) => {
                            return criterion
                              ? {
                                  selectionPath: criterion.selectionPath,
                                  comparator: criterion.comparator,
                                  template: criterion.template,
                                  valueType: criterion.valueType,
                                  value: criterion.valueConverter(
                                    criterion.editableValue,
                                  ),
                                  editable: criterion.editable,
                                  legacyPath: criterion.legacyPath ?? '',
                                }
                              : null;
                          })
                          .filter((criterion) => criterion),
                      },
                    },
                  },
                });
                setAlertTriggerEditorOpen(false);
                Notifier.success('Alert Trigger successfully updated');
              } catch (error) {
                Notifier.error(
                  'Unable to update alert trigger. Please try again or contact support.',
                );
              }
            } else {
              Notifier.warn('No selected alert trigger to update');
            }
          }}
        />
      </div>
    </Fade>
  );
}
