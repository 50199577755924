import React from 'react';
import ZonePoliciesTable from './zone-policies-table';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

export function ZonePoliciesMain() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      style={{
        margin: isSmall ? '0px' : '20px 10px',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      <ZonePoliciesTable />
    </div>
  );
}
