import { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { useAuthenticator } from '../auth/AuthenticationContext';
import {
  appRoutes,
  AuthorizedRoute,
  AuthorizedRoutes,
  defaultRoutes,
} from './app-routes';
import { authRoutes } from './auth-routes';
import { unitRoutes } from './units-routes';
import { AuthorizedUser } from '../auth/models';
import { useAuthorizer } from '../auth/AuthorizationContext';
import { propertyRoutes } from './property-routes';
import { smartRoutes } from './smart-routes';
import { SERVER_TYPE } from '../../helpers/config';
import { commonRoutes } from './common-routes';

export function AuthRouter() {
  const routes =
    SERVER_TYPE === 'combined'
      ? [...commonRoutes, ...smartRoutes, ...authRoutes]
      : SERVER_TYPE === 'smart'
        ? [...commonRoutes, ...smartRoutes]
        : [...commonRoutes, ...authRoutes];
  return useRoutes(routes);
}

export function AppRouter() {
  const authenticator = useAuthenticator();
  const { isRoutePermitted } = useAuthorizer();
  const [user, setUser] = useState<AuthorizedUser | undefined>(undefined);
  const [authorizedRoutes, setAuthorizedRoutes] =
    useState<AuthorizedRoutes>(defaultRoutes);
  useEffect(() => {
    setUser(authenticator?.user);
  }, [authenticator]);

  useEffect(() => {
    if (user) {
      setAuthorizedRoutes([
        ...appRoutes.filter((route) => {
          return isRoutePermitted(route);
        }),
      ]);
    } else {
      setAuthorizedRoutes(defaultRoutes);
    }
  }, [user, isRoutePermitted]);

  return useRoutes(authorizedRoutes);
}

export function PropertyRouter() {
  const authenticator = useAuthenticator();
  const { isRoutePermitted } = useAuthorizer();
  const [user, setUser] = useState<AuthorizedUser | undefined>(undefined);
  const [authorizedRoutes, setAuthorizedRoutes] =
    useState<AuthorizedRoutes>(defaultRoutes);
  useEffect(() => {
    setUser(authenticator?.user);
  }, [authenticator]);

  useEffect(() => {
    if (user) {
      const authRoutes: AuthorizedRoute[] = [];

      propertyRoutes.forEach((route) => {
        if (isRoutePermitted(route)) {
          if (route.children) {
            const childRoutes = route.children.filter(
              (childRoute: Omit<(typeof route.children)[0], 'undefined'>) =>
                isRoutePermitted(childRoute),
            );
            if (childRoutes.length > 0) {
              authRoutes.push({
                ...route,
                children: childRoutes,
              });
            } else {
              authRoutes.push({
                ...route,
                children: [],
              });
            }
          } else {
            authRoutes.push(route);
          }
        }
      });
      setAuthorizedRoutes(authRoutes);
    } else {
      setAuthorizedRoutes(defaultRoutes);
    }
  }, [user, isRoutePermitted]);
  return useRoutes(authorizedRoutes);
}

export function UnitsRouter() {
  const authenticator = useAuthenticator();
  const { isRoutePermitted } = useAuthorizer();
  const [user, setUser] = useState<AuthorizedUser | undefined>(undefined);
  const [authorizedRoutes, setAuthorizedRoutes] =
    useState<AuthorizedRoute[]>(defaultRoutes);
  useEffect(() => {
    setUser(authenticator?.user);
  }, [authenticator]);

  useEffect(() => {
    if (user) {
      const authRoutes: AuthorizedRoute[] = [];

      unitRoutes.forEach((route) => {
        if (isRoutePermitted(route)) {
          if (route.children) {
            const childRoutes = route.children.filter(
              (childRoute: Omit<(typeof route.children)[0], 'undefined'>) =>
                isRoutePermitted(childRoute),
            );
            if (childRoutes.length > 0) {
              authRoutes.push({
                ...route,
                children: childRoutes,
              });
            } else {
              authRoutes.push({
                ...route,
                children: [],
              });
            }
          } else {
            authRoutes.push({
              ...route,
              children: [],
            });
          }
        }
      });
      setAuthorizedRoutes(authRoutes);
    } else {
      setAuthorizedRoutes(defaultRoutes);
    }
  }, [user, isRoutePermitted]);

  return useRoutes(authorizedRoutes);
}
