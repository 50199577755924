/**
 * Update Subscribed Values
 * Accepts device data from the graphql query and initializes all values according to
 * preferred units of measure and setpoint limit profiles.
 * Returns the object containing all formatted values for additional processing.
 **/
import { CurrentAttributes, MyModbusAirHandler } from '../types';
import { convertToTempUnits } from '../../util';
import { convertToAirFlowUnits } from '../../util';
import {
  AirFlowUnit,
  TemperatureUnit,
} from '../../../../system/models/temperatureUnits';
import { DEG_C, DEG_F } from '../../shared-ui/constants';

export function updateSubscribedValues(
  device: MyModbusAirHandler,
  preferredUnits: TemperatureUnit,
  preferredAirFlowUnits: AirFlowUnit,
): CurrentAttributes {
  const initialValues: CurrentAttributes = {
    name: device.name,
    deviceId: device.deviceId,
    sourceId: device.sourceId,
    timestamp: device.timestamp,
    zoneId: device.zoneId,
    hasPendingUpdates: device.hasPendingUpdates,

    // alarms
    alarmGlobalAlarm: device.alarmGlobalAlarm?.value ?? false,

    alarmLowPressure: device.alarmLowPressure?.value ?? false,
    alarmHighPressure: device.alarmHighPressure?.value ?? false,

    alarmChangeFilters: device.alarmChangeFilters?.value ?? false,
    alarmChangeHepa: device.alarmChangeHepa?.value ?? false,
    alarmAirRestriction: device.alarmAirRestriction?.value ?? false,

    alarmSupplyFanStopped: device.alarmSupplyFanStopped?.value ?? false,
    alarmExhaustFanStopped: device.alarmExhaustFanStopped?.value ?? false,

    alarmRefrigerantLeak: device.alarmRefrigerantLeak?.value ?? false,
    alarmCompressorFail: device.alarmCompressorFail?.value ?? false,

    alarmFanANegPressureOpt1: device.alarmFanANegPressureOpt1?.value ?? false,
    alarmFanANegPressureOpt2: device.alarmFanANegPressureOpt2?.value ?? false,
    alarmFanABReversed: device.alarmFanABReversed?.value ?? false,

    alarmClockBoard: device.alarmClockBoard?.value ?? false,
    alarmExtendedMemory: device.alarmExtendedMemory?.value ?? false,

    alarmB1SensorFail: device.alarmB1SensorFail?.value ?? false,
    alarmB2SensorFail: device.alarmB2SensorFail?.value ?? false,
    alarmB3SensorFail: device.alarmB3SensorFail?.value ?? false,
    alarmB4SensorFail: device.alarmB4SensorFail?.value ?? false,
    alarmB6SensorFail: device.alarmB6SensorFail?.value ?? false,

    zmbGlobalAlarm: device.zmbGlobalAlarm?.value ?? false,
    zmbThermostatAlarm: device.zmbThermostatAlarm?.value ?? false,
    zmbAirHandlerAlarm: device.zmbAirHandlerAlarm?.value ?? false,
    zmbLeakDetected: device.zmbLeakDetected?.value ?? false,

    // ventilation settings
    damperPosition: device.damperPosition?.value ?? 'Recirculation',
    reversibleValveOnWhen: device.reversibleValveOnWhen?.value ?? 'Heating',
    recirculationModeOn: device.recirculationModeOn?.value ?? false,
    airExchangeModeOn: device.airExchangeModeOn?.value ?? false,

    // ventilation state and readings
    supplyFanOn: device.supplyFanOn?.value ?? false,
    supplyFanAirflow:
      convertToAirFlowUnits(
        device.supplyFanAirflow?.value,
        preferredAirFlowUnits,
      ) ?? 0,
    supplyFanStaticPressureH2O: device.supplyFanStaticPressureH2O?.value ?? 0,

    exhaustFanOn: device.exhaustFanOn?.value ?? false,
    exhaustFanAirflow:
      convertToAirFlowUnits(
        device.exhaustFanAirflow?.value,
        preferredAirFlowUnits,
      ) ?? 0,

    // operating state
    systemOnOff: device.systemOnOff?.value ?? 'Off',
    systemAutoMode: device.systemAutoMode?.value ?? 'Automatic',

    heatPumpModeOn: device.heatPumpModeOn?.value ?? false,
    heatingElementOn: device.heatingElementOn?.value ?? false,
    compressorOn: device.compressorOn?.value ?? false,

    // current temperature and humidity readings
    indoorTemperature:
      convertToTempUnits(
        device.indoorTemperature?.value ?? 0,
        preferredUnits,
      ) ?? 0,
    outsideTemperature:
      convertToTempUnits(
        device.outsideTemperature?.value ?? 0,
        preferredUnits,
      ) ?? 0,
    indoorRH: device.indoorRH?.value ?? 0,
    defrostTemperature:
      convertToTempUnits(
        device.defrostTemperature?.value ?? 0,
        preferredUnits,
      ) ?? 0,
    conditionedTemperature:
      convertToTempUnits(
        device.conditionedTemperature?.value ?? 0,
        preferredUnits,
      ) ?? 0,

    // setpoints and limits
    rhSetpointRange:
      `${device.meta?.metrics?.freshAirSetpoint?.value}` ?? '[10, 40]',
    freshAirSetpointRange:
      `${device.meta?.metrics?.rhSetpoint?.value}` ?? '[10, 40]',
    ahuCoolSetpoint:
      convertToTempUnits(device.ahuCoolSetpoint?.value ?? 22, preferredUnits) ??
      0,
    ahuHeatSetpoint:
      convertToTempUnits(device.ahuHeatSetpoint?.value ?? 20, preferredUnits) ??
      0,
    ahuMinCoolSetpointLimit:
      convertToTempUnits(
        device.ahuMinCoolSetpointLimit?.value ?? 17,
        preferredUnits,
      ) ?? 0,
    ahuMaxHeatSetpointLimit:
      convertToTempUnits(
        device.ahuMaxHeatSetpointLimit?.value ?? 26,
        preferredUnits,
      ) ?? 0,

    // terminal states
    gTerminalOn: device.gTerminalOn?.value ?? false,
    hTerminalOn: device.hTerminalOn?.value ?? false,
    wTerminalOn: device.wTerminalOn?.value ?? false,
    yTerminalOn: device.yTerminalOn?.value ?? false,

    // tenant settings
    allowTenantOnOff: device.allowTenantOnOff?.value ?? false,
    enableTenantMode: device.enableTenantMode?.value ?? false,
    allowTenantChangeRHSetpoint:
      device.allowTenantChangeRHSetpoint?.value ?? false,
    allowTenantChangeTempSetpoints:
      device.allowTenantChangeTempSetpoints?.value ?? false,
    tenantModePassword: device.tenantModePassword?.value ?? '0000',

    // settable
    ventilationMode: device.ventilationMode?.value ?? 'Smart',
    ahuModbusId: device.ahuModbusId?.value ?? 3,
    altitude: device.altitude?.value ?? 500,
    freshAirSetpoint:
      convertToAirFlowUnits(
        device.freshAirSetpoint?.value,
        preferredAirFlowUnits,
      ) ??
      convertToAirFlowUnits(10, preferredAirFlowUnits) ??
      0,
    rhSetpoint: device.rhSetpoint?.value ?? 40,

    auxCoolingEnabled: device.auxCoolingEnabled?.value ?? false,
    winterRhAdjustEnabled: device.winterRhAdjustEnabled?.value ?? false,
    showerDetectorEnabled: device.showerDetectorEnabled?.value ?? false,

    filtersServiceTime: device.filtersServiceTime?.value ?? 0,
    hepaServiceTime: device.hepaServiceTime?.value ?? 0,

    resetFiltersServiceTime:
      device.resetFiltersServiceTime?.value !== 'Idle' ?? false,
    resetHepaServiceTime:
      device.resetHepaServiceTime?.value !== 'Idle' ?? false,

    zmbPollingFrequency: device.zmbPollingFrequency?.value ?? 30,
    zmbModbusRequestDelay: device.zmbModbusRequestDelay?.value ?? 500,

    // Software Version Information
    softwareReleaseType: device.softwareReleaseType?.value ?? 'Unknown',
    softwareVersion: device.softwareVersion?.value ?? 0,
    bootVersion: device.bootVersion?.value ?? 0,
    biosVersion: device.biosVersion?.value ?? 0,
    zmbFirmwareVersion: device.zmbFirmwareVersion?.value ?? 'Unknown',

    // ZMB configuration and metrics
    zmbSystemUptime: device.zmbSystemUptime?.value ?? 0,
    zmbTstatRequestsRecvd: device.zmbTstatRequestsRecvd?.value ?? 0,
    zmbAhuRequestsSent: device.zmbAhuRequestsSent?.value ?? 0,
    zmbAhuRequestsSentFailed: device.zmbAhuRequestsSentFailed?.value ?? 0,
    zmbCoreRequestsRecvd: device.zmbCoreRequestsRecvd?.value ?? 0,
    zmbRequestsRecvdInvalid: device.zmbRequestsRecvdInvalid?.value ?? 0,
    zmbLeakDetectorInstalled: device.zmbLeakDetectorInstalled?.value ?? false,
    zmbCpuTemperature:
      convertToTempUnits(
        device.zmbCpuTemperature?.value ?? 0,
        preferredUnits,
      ) ?? 0,

    airflowUnits:
      device.airflowUnits?.value === 'LPS'
        ? 'L/s'
        : device.airflowUnits?.value ?? 'CFM'
          ? 'CFM'
          : 'Unknown',
    temperatureUnits:
      device.temperatureUnits?.value?.toUpperCase() === 'CELSIUS'
        ? DEG_C
        : device.temperatureUnits?.value?.toUpperCase() === 'FAHRENHEIT'
          ? DEG_F
          : 'Unknown',
  };

  return initialValues;
}
