import React, {
  createContext,
  FC,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import * as Apollo from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';
import { OperationVariables } from '@apollo/client';
import { Collapse } from '@mui/material';

export interface IStatusNotifier {
  message?: string;
  manageAsyncOp(
    opName: string,
    inProgress: boolean,
    inProgressMessage?: string,
    transitionMessage?: string | undefined,
    transitionDelayMs?: number | undefined,
  ): void;
}

const StatusNotifierContext = createContext<IStatusNotifier>({
  message: 'test message',
  manageAsyncOp: (
    opName: string,
    inProgress: boolean,
    inProgressMessage?: string,
    transitionMessage?: string | undefined,
    transitionDelayMs?: number | undefined,
  ) => {
    console.log(
      opName,
      inProgress,
      inProgressMessage,
      transitionMessage,
      transitionDelayMs,
    );
  },
});

StatusNotifierContext.displayName = 'StatusNotifierContext';

interface StatusManagerProviderProps {
  children: React.ReactNode;
}

export const StatusNotifierProvider: FC<StatusManagerProviderProps> = ({
  children,
}) => {
  const [message, setMessage] = useState<string | undefined>();

  // TODO: might want to deal with this react hooks issue by utilizing its own useCallback?
  const manageAsyncOp = (
    opName: string,
    inProgress: boolean,
    inProgressMessage = 'Loading ...',
    transitionMessage: string | undefined = undefined,
    transitionDelayMs: number | undefined = undefined,
  ) => {
    if (inProgress) {
      setMessage(inProgressMessage);
    } else {
      if (transitionMessage) {
        setMessage(transitionMessage);
        setTimeout(() => {
          setMessage(undefined);
        }, transitionDelayMs || 1000);
      } else {
        setMessage(undefined);
      }
    }
  };

  const value: IStatusNotifier = useMemo(() => {
    return {
      message,
      manageAsyncOp,
    };
  }, [message, manageAsyncOp]);

  return (
    <StatusNotifierContext.Provider value={value}>
      {children}
    </StatusNotifierContext.Provider>
  );
};

export const useStatusNotifier = (): IStatusNotifier => {
  const context = useContext(StatusNotifierContext);
  if (context === undefined) {
    throw new Error(
      'useStatusNotifier must be used within a StatusNotifierProvider',
    );
  }
  return context;
};

export function useQueryHook<TData, TQueryVariables extends OperationVariables>(
  queryHook: (
    baseOptions: Apollo.QueryHookOptions<TData, TQueryVariables>,
  ) => QueryResult<TData, TQueryVariables>,
  queryHookOptions: Apollo.QueryHookOptions<TData, TQueryVariables> = {},
  loadingMessage = 'Loading ...',
  renderingMessage: string | undefined = undefined,
  renderingDelay: number | undefined = undefined,
): QueryResult<TData, TQueryVariables> {
  const res = queryHook(queryHookOptions);
  const { loading } = res;
  const { manageAsyncOp } = useStatusNotifier();

  useEffect(() => {
    manageAsyncOp(
      'propertiesList',
      loading,
      loadingMessage,
      !loading ? renderingMessage : undefined,
      !loading ? renderingDelay : undefined,
    );
  }, [loading]);

  return res;
}

export const StatusMessagePanel = () => {
  const { message } = useStatusNotifier();

  return (
    <Collapse orientation="vertical" in={!!message}>
      <div
        style={{
          borderTop: '1px solid green',
          height: '30px',
          textAlign: 'center',
          fontSize: '20px',
        }}
      >
        {message}
      </div>
    </Collapse>
  );
};
