import React, { useState } from 'react';
import { Dialog, Typography, DialogContent } from '@mui/material';
import { useAuthenticator } from '../../../../../auth/AuthenticationContext';
import { ThermostatScheduleTemplateRowData } from './thermostat-schedule';
import BorderedSection from '../../../../shared/borderedSection';
import { ScheduleStatusUpdate } from '../../../../shared/schedule-update-status';

interface ThermostatSchedulePropagationStatusProps {
  template: ThermostatScheduleTemplateRowData | undefined;
  handleClosePropagationStatusView: () => void;
  action?: string;
}

export function ThermostatSchedulePropagationStatus(
  props: ThermostatSchedulePropagationStatusProps,
) {
  const { template, handleClosePropagationStatusView, action } = props;

  let actionText = '';
  switch (action) {
    case 'apply':
      actionText = 'applying';
      break;
    case 'remove':
      actionText = 'removing';
      break;
    case 'update':
      actionText = 'updating';
      break;
    default:
      actionText = 'working';
  }

  const propagationText = `Please wait, ${actionText} schedule template`;

  const [titleText, setTitleText] = useState<string>(propagationText);

  const { user } = useAuthenticator();

  return user ? (
    <>
      <Typography variant="h4" sx={{ padding: '15px' }}>
        {titleText}
      </Typography>
      <DialogContent sx={{ padding: '10px 10px' }}>
        <BorderedSection title="Template Information">
          <Typography>Title: {template?.name ?? 'N/A'}</Typography>
        </BorderedSection>
        <br />
        <ScheduleStatusUpdate
          title="Schedule Template Propagation Status"
          initiator={template ?? {}}
          actions={[
            {
              label: 'Back To Template Detail',
              handler: () => {
                handleClosePropagationStatusView();
              },
            },
          ]}
          completeCallback={() => setTitleText('Completed')}
          errorCallback={() => {
            console.log('error callback');
            handleClosePropagationStatusView();
          }}
        />
      </DialogContent>
    </>
  ) : null;
}
