/**
 * Update Subscribed Values
 * Accepts device data from the graphql query and initializes all values according to
 * preferred units of measure and setpoint limit profiles.
 * Returns the object containing all formatted values for additional processing.
 **/
import {
  CurrentAttributes,
  Maybe,
  MyThermostat,
  SetpointProfile,
} from '../types';
import { convertToTempUnits } from '../../util/convert-temp-units';
import { configureBaseSetpointLimits } from './configure-base-setpoints';
import { TemperatureUnit } from '../../../../system/models/temperatureUnits';

export function updateSubscribedValues(
  device: MyThermostat,
  preferredUnits: TemperatureUnit,
  unitSetpointProfile?: Maybe<SetpointProfile>,
  propertySetpointProfile?: Maybe<SetpointProfile>,
): CurrentAttributes {
  let initialValues: CurrentAttributes = {
    deviceId: device.deviceId,
    name: device.name,
    setpointLimitBasis: '',
    baseMaxCoolSetpointLimit: 0,
    baseMaxHeatSetpointLimit: 0,
    baseMinCoolSetpointLimit: 0,
    baseMinHeatSetpointLimit: 0,
    followingSchedule: undefined,
    maxCoolSetpointLimit: device.maxCoolSetpointLimit?.value ?? 0,
    maxHeatSetpointLimit: device.maxHeatSetpointLimit?.value ?? 0,
    minCoolSetpointLimit: device.minCoolSetpointLimit?.value ?? 0,
    minHeatSetpointLimit: device.minHeatSetpointLimit?.value ?? 0,
    temperature: convertToTempUnits(
      device.meta?.display?.temperature ?? device.temperature?.value,
      preferredUnits,
    ),
    remoteSensorTempDelta: convertToTempUnits(
      device.remoteSensorTempDelta?.value,
      preferredUnits,
    ),
    humidity: device.meta?.display?.humidity ?? device.humidity?.value,
    heatSetpoint: device.heatSetpoint?.value ?? 0,
    coolSetpoint: device.coolSetpoint?.value ?? 0,

    fanModeInfo: device.fanModeInfo,
    fanMode: device.fanMode?.value,
    fanState: device.fanState?.value,
    fanSpeed: device.fanSpeed?.value,
    getFanState: device.getFanState,
    getHoldEnd: device.getHoldEnd,
    getSetpointChangeSource: device.getSetpointChangeSource,
    hasPendingUpdates: device.hasPendingUpdates,
    holdDuration: device.holdDuration?.value,
    holdEnd: device.holdEnd?.value,
    holdState: device.holdState?.value,
    isMultiSpeedThermostat: device.isMultiSpeedThermostat,
    keypadLockoutLevel: device.keypadLockoutLevel?.value,
    lastScheduleChange: device.lastScheduleChange?.value,
    lastSetpointChange: device.lastSetpointChange?.value,
    lastSetpointChangeValid: device.lastSetpointChangeValid,
    modbusRTU: device.modbusRTU,
    keypadLockoutLevels: device.keypadLockoutLevels ?? [],
    operatingMode: device.operatingMode?.value,
    operatingState: device.operatingState?.value,
    progMode: device.progMode?.value,
    passCode: device.passCode?.value,
    scheduleStatus: device.scheduleStatus,
    schedVisibilityMode: device.schedVisibilityMode?.value,
    setpointChangeSource: device.setpointChangeSource?.value,
    showFanButton: device.showFanButton?.value,
    sourceId: device.sourceId,
    supportsShowFanButton: device.supportsShowFanButton,
    supportsPasscode: device.supportsPasscode,
    supportsSystemType: device.supportsSystemType,
    supportsRemoteSensorTempDelta: device.supportsRemoteSensorTempDelta,
    supportsLastSetpointChange: device.supportsLastSetpointChange,
    supportsFanOperations: device.supportsFanOperations,
    supportsHoldExpiryTime: device.supportsHoldExpiryTime,
    systemType: device.modbusRTU ? 'SmartAhu' : device.systemType?.value,
    timestamp: device.timestamp,
    tempDisplayMode: device.tempDisplayMode?.value,
    uiOptions: device.uiOptions?.value,
    useCustomSchedule: device.useCustomSchedule,
    useCustomSetpointLimits: device.useCustomSetpointLimits,
    zoneId: device.zoneId,
  };

  initialValues = configureBaseSetpointLimits(
    initialValues,
    preferredUnits,
    unitSetpointProfile,
    propertySetpointProfile,
  );

  initialValues.coolSetpoint =
    convertToTempUnits(initialValues.coolSetpoint, preferredUnits) ??
    initialValues.baseMaxCoolSetpointLimit;

  initialValues.heatSetpoint =
    convertToTempUnits(initialValues.heatSetpoint, preferredUnits) ??
    initialValues.baseMinHeatSetpointLimit;

  return initialValues;
}
