import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Fade, useMediaQuery } from '@mui/material';

import logo from '../../assets/embue_logo.svg';
import './AuthenticationPanel.css';
import { useTheme } from '@mui/material/styles';

/**
 * Used as a container for login-related factor components
 * as well as for informational panels during server-disconnect
 * event processing.
 * @param title
 * @param subtitle: the subtitle of the panel.
 * @param children
 * @param loading: a boolean which, if true, displays 'flashing dots'
 *                 next to the title to indicate ongoing-processing or waiting.
 * @constructor
 */
export const AuthenticationPanel = ({
  title,
  subtitle,
  children,
  loading,
}: {
  title: string;
  subtitle?: string | undefined;
  children?: React.ReactNode;
  loading?: boolean;
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // backgroundColor: 'rgb(228 244 248 / 60%)', //'rgb(245 245 245 / 0.3)',
        paddingBottom: '8px',
        minWidth: isSmall ? 'unset' : '400px',
      }}
    >
      <img
        src={logo}
        alt="logo"
        width="100px"
        style={{ marginBottom: '8px' }}
      />
      <Typography
        color="lightseagreen"
        component="h1"
        variant="h4"
        align="center"
        fontWeight="bold"
        pb={isSmall ? '-8px' : '8px'}
      >
        Embue Super
      </Typography>
      <Fade
        in={true}
        style={{ transitionDuration: '500ms' }}
        mountOnEnter
        unmountOnExit
      >
        <div style={{ display: 'flex' }}>
          <Typography
            color="lightseagreen"
            component="h1"
            variant="subtitle1"
            align="center"
            pb="16px"
          >
            <>
              {title}
              {(subtitle?.length ?? 0) > 0 && subtitle !== 'undefined' ? (
                <div
                  style={{ marginTop: isSmall ? '-10px' : '6px' }}
                >{`${subtitle}`}</div>
              ) : null}
            </>
          </Typography>
          {loading && <div className="dots"></div>}
        </div>
      </Fade>
      {children}
    </Box>
  );
};
