import React, { useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';

import {
  Typography,
  Container,
  Divider,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useAuthenticator } from '../../../../auth/AuthenticationContext';
import { Notifier } from '../../../../system/services/notificationManager';
import { updateCacheFromSubscriptionEvent } from '../../../../../helpers/subscriptionUtils';

import {
  AlertTrigger,
  Property,
  useAlertTriggerListByPropertyIdQuery,
  useAlertTriggerListByPropertyIdUpdateSubscription,
} from '../../../../../types/generated-types';

import {
  Fahrenheit,
  TemperatureUnit,
} from '../../../../system/models/temperatureUnits';
import {
  convertToTempUnits,
  formatTemperatureString,
} from '../../../devices/util';

import { ViewPropertyContentProps } from '../../types/viewPropertyContentProps';
import BorderedSection from '../../../shared/borderedSection';
import { PropertyAlertTriggersList } from '../../property-alert-triggers-list';
import { formatPhoneNumber } from '../../../../../common/helpers';

const AlertTriggersSummary = () => {
  const [alertTriggers, setAlertTriggers] = React.useState<
    Partial<AlertTrigger>[]
  >([]);

  const { id } = useParams<{ id: string }>();
  const { data, error } = useAlertTriggerListByPropertyIdQuery({
    variables: { propertyId: id ?? '' },
    fetchPolicy: 'network-only',
  });

  useAlertTriggerListByPropertyIdUpdateSubscription({
    variables: { propertyId: id ?? '' },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (error) {
      Notifier.error('Error loading alert triggers: ' + error.message);
    } else if (data?.alertTriggersByPropertyId) {
      setAlertTriggers(data.alertTriggersByPropertyId ?? []);
    }
  }, [data, error]);

  return <div>Property defines {alertTriggers.length} alert triggers.</div>;
};

const CollapsedContacts = ({
  contacts,
}: {
  contacts: Partial<Property['contact']>;
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const alertEmailOne = contacts?.email;
  const alertEmailTwo = contacts?.email2;
  const alertEmailThree = contacts?.email3;
  const alertMobileOne = contacts?.smsNumber;
  const alertMobileTwo = contacts?.smsNumber2;
  const alertMobileThree = contacts?.smsNumber3;

  return (
    <div>
      {alertEmailOne || alertMobileOne ? (
        <BorderedSection
          title="Contact 1"
          style={{ margin: '6px 2px 2px 2px' }}
          ccStyle={
            isSmall
              ? { padding: '0px 0px 0px 6px', marginTop: '-6px' }
              : { padding: '8px', marginTop: '-6px' }
          }
        >
          {alertEmailOne ? <div>{alertEmailOne}</div> : null}
          {alertMobileOne ? (
            <div>{formatPhoneNumber(alertMobileOne)}</div>
          ) : null}
        </BorderedSection>
      ) : null}
      {alertEmailTwo || alertMobileTwo ? (
        <BorderedSection
          title="Contact 2"
          style={{ marginTop: isSmall ? '8px' : '16px' }}
          ccStyle={
            isSmall
              ? { padding: '0px 0px 0px 6px', marginTop: '-6px' }
              : { padding: '8px', marginTop: '-6px' }
          }
        >
          {alertEmailTwo ? <div>{alertEmailTwo}</div> : null}
          {alertMobileTwo ? (
            <div>{formatPhoneNumber(alertMobileTwo)}</div>
          ) : null}
        </BorderedSection>
      ) : null}
      {alertEmailThree || alertMobileThree ? (
        <BorderedSection
          title="Contact 3"
          style={{ marginTop: isSmall ? '8px' : '16px' }}
          ccStyle={
            isSmall
              ? { padding: '0px 0px 0px 6px', marginTop: '-6px' }
              : { padding: '8px', marginTop: '-6px' }
          }
        >
          {alertEmailThree ? <div>{alertEmailThree}</div> : null}
          {alertMobileThree ? (
            <div>{formatPhoneNumber(alertMobileThree)}</div>
          ) : null}
        </BorderedSection>
      ) : null}
      {!(
        alertEmailOne ||
        alertMobileOne ||
        alertEmailTwo ||
        alertMobileTwo ||
        alertEmailThree ||
        alertMobileThree
      ) ? (
        <div>No floating alert recipients defined</div>
      ) : null}
    </div>
  );
};

export function PropertyAlerts({ data }: ViewPropertyContentProps) {
  const auth = useAuthenticator();

  const [preferredUnits, setPreferredUnits] =
    React.useState<TemperatureUnit>('C');

  useEffect(() => {
    if (auth?.user?._id) {
      setPreferredUnits(auth?.user?.preferredUnits ?? Fahrenheit);
    }
  }, [auth]);

  const formatTemp = (tempInC: number) => {
    const temp = convertToTempUnits(tempInC, preferredUnits, 'C', 0) ?? 0;
    return formatTemperatureString(temp, preferredUnits);
  };

  const formatHumidity = (humidity: number) => {
    return Math.round(humidity) + '%';
  };

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  // const [occupiedHeight, setOccupiedHeight] = useState(0);

  const triggersElementRef = useRef<HTMLDivElement>(null);
  const triggerListElementRef = useRef<HTMLDivElement>(null);

  return data ? (
    <div
      style={{
        padding: isSmall ? '4px' : '8px',
      }}
    >
      <BorderedSection
        innerRef={triggersElementRef}
        // onExpandToggle={(isExpanded) => setIsTriggersExpanded(isExpanded)}
        title="Property-level Alert Triggers"
        collapsible
        initiallyExpanded={false}
        collapsedWidget={<AlertTriggersSummary />}
        style={{
          marginTop: isSmall ? '4px' : '-12px',
        }}
        ccStyle={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          ref={triggersElementRef}
          style={{
            border: '1px solid lightgray',
            borderRadius: '4px',
            boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 0.3)',
            display: 'flex',
            flexDirection: 'column',
            margin: '2px',
            padding: '4px',
            marginTop: '8px',
          }}
        >
          <PropertyAlertTriggersList
            innerRef={triggerListElementRef}
            // occupiedHeight={occupiedHeight}
            onRowClick={() => {}}
          />
        </div>
      </BorderedSection>
      <BorderedSection
        title=" Property-wide Alert Recipients"
        style={{
          marginTop: isSmall ? '12px' : '24px',
        }}
        ccStyle={
          isSmall ? { padding: '0px 2px 2px 2px', marginTop: '0px' } : undefined
        }
      >
        <CollapsedContacts contacts={data.contact} />
      </BorderedSection>
      {auth.user?.isSuper ? (
        <BorderedSection
          title="Legacy Alert Settings"
          collapsible
          initiallyExpanded={false}
          collapsedWidget={<div>Expand to view Legacy Settings</div>}
          style={{
            marginTop: isSmall ? '16px' : '24px',
          }}
          ccStyle={{ display: 'flex', flexDirection: 'column' }}
        >
          <Container
            sx={{
              marginBottom: '30px',
            }}
          >
            <Typography sx={{ marginBottom: '30px' }}>
              Alerts Contact Information
            </Typography>
            <Container>
              <Typography color="GrayText">Contact 1</Typography>
              <Divider />
              <Stack direction="column">
                <span>Email: {data.contact?.email ?? ''}</span>
                <span>Mobile: {data.contact?.smsNumber ?? ''} </span>
              </Stack>
            </Container>
            <br />
            <Container>
              <Typography color="GrayText">Contact 2</Typography>
              <Divider />
              <Stack direction="column">
                <span>Email: {data.contact?.email2 ?? ''}</span>
                <span>Mobile: {data.contact?.smsNumber2 ?? ''} </span>
              </Stack>
            </Container>
            <br />
            <Container>
              <Typography color="GrayText">Contact 3</Typography>
              <Divider />
              <Stack direction="column">
                <span>Email: {data.contact?.email3 ?? ''}</span>
                <span>Mobile: {data.contact?.smsNumber3 ?? ''} </span>
              </Stack>
            </Container>
            <br />
            <Divider />
            <br />
            <Typography sx={{ marginBottom: '30px' }}>
              Residence Alert Threshold
            </Typography>
            <Container>
              <Typography color="GrayText">{`Temperature (°${preferredUnits})`}</Typography>
              <Divider />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <span>Minimum</span>
                  <div>
                    {formatTemp(data.alertLimits?.units?.temperature?.min ?? 0)}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <span>Maximum</span>
                  <div>
                    {formatTemp(data.alertLimits?.units?.temperature?.max ?? 0)}
                  </div>
                </Grid>
              </Grid>
            </Container>
            <br />
            <Container>
              <Typography color="GrayText">{`Humidity (%)`}</Typography>
              <Divider />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <span>Minimum</span>
                  <div>
                    {formatHumidity(
                      data.alertLimits?.units?.humidity?.min ?? 0,
                    )}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <span>Maximum</span>
                  <div>
                    {formatHumidity(
                      data.alertLimits?.units?.humidity?.max ?? 0,
                    )}
                  </div>
                </Grid>
              </Grid>
            </Container>
            <br />
            <Divider />
            <br />
            <Typography sx={{ marginBottom: '20px' }}>
              Central Plant Alert Threshold
            </Typography>
            <Container>
              <Typography color="GrayText">{`Domestic Hot Water Temperature (°${preferredUnits})`}</Typography>
              <Divider />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <span>Minimum</span>
                  <div>
                    {formatTemp(
                      data.alertLimits?.plant?.dhw?.temperature?.min ?? 0,
                    )}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <span>Maximum</span>
                  <div>
                    {formatTemp(
                      data.alertLimits?.plant?.dhw?.temperature?.max ?? 0,
                    )}
                  </div>
                </Grid>
              </Grid>
            </Container>
            <br />
            <Container>
              <Typography color="GrayText">{`Loop Temperature (°${preferredUnits})`}</Typography>
              <Divider />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <span>Minimum</span>
                  <div>
                    {formatTemp(
                      data.alertLimits?.plant?.loops?.temperature?.min ?? 0,
                    )}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <span>Maximum</span>
                  <div>
                    {formatTemp(
                      data.alertLimits?.plant?.loops?.temperature?.max ?? 0,
                    )}
                  </div>
                </Grid>
              </Grid>
            </Container>
            <br />
            <Container>
              <Typography color="GrayText">{`Loop Pressure (PSI)`}</Typography>
              <Divider />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <span>Minimum</span>
                  <div>
                    {`${
                      data.alertLimits?.plant?.loops?.pressure?.min ?? 0
                    } (psi)`}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <span>Maximum</span>
                  <div>
                    {`${
                      data.alertLimits?.plant?.loops?.pressure?.max ?? 0
                    } (psi)`}
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </BorderedSection>
      ) : null}
    </div>
  ) : null;
}
