import {
  ZonePolicy,
  // CreateZonePolicyMutationVariables,
  UpdateZonePolicyMutationVariables,
  // useCreateZonePolicyMutation,
  useUpdateZonePolicyMutation,
} from '../../../../types/generated-types';
import React, { ReactElement, useState } from 'react';
import { FormValues } from '../types/zonePolicyDetailFormValues';
import { Form, Formik, FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ZonePolicyGeneralTab } from '../components/zone-policy-detail-general-tab';
import { ZonePolicyPropertiesTab } from '../components/zone-policy-detail-properties-tab';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '../../../auth/AuthenticationContext';
import { Notifier } from '../../../system/services/notificationManager';
import { ActivityIndicator } from '../../shared/activityIndicator';
import { BulkUpdateStatus } from '../../shared/bulk-update-status';
import { VerifyZonePolicyUpdate } from '../../shared/verify-zone-policy-update';
export const SimpleZonePolicyForm = ({
  policy,
}: {
  policy: Partial<ZonePolicy>;
}): ReactElement => {
  const navigator = useNavigate();
  const auth = useAuthenticator();
  const [propagating, setPropagating] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [verificationReady, setVerificationReady] = useState(false);

  const [updateZonePolicy] = useUpdateZonePolicyMutation();
  // const [
  //   createZonePolicy,
  //   { data: createdZonePolicy, loading: createZonePolicyLoading, error: createZonePolicyError },
  // ] = useCreateZonePolicyMutation();

  const initialValues: FormValues = {
    _id: policy._id ?? '',
    name: policy.name ?? '',
    rules: policy.rules ?? [],
    properties: (policy.properties ?? []) as string[],
  };

  const onSubmit = (
    values: FormValues,
    _helpers: FormikHelpers<FormValues>,
  ) => {
    if (policy._id) {
      setPropagating(true);
      setVerifying(false);
      setVerificationReady(false);
      const policyUpdateFields: UpdateZonePolicyMutationVariables['policy'] = {
        name: values.name,
        // TODO: ZonePolicyEditing: Implemement ability to edit rules
        // rules: values.rules,
        properties: values.properties,
      };

      updateZonePolicy({
        variables: {
          id: policy._id,
          policy: policyUpdateFields,
        },
      })
        .then((res) => {
          formik.setSubmitting(false);
          Notifier.success('Zone policy updated');
          // navigator('/zone-policies');
        })
        .catch((updateError) => {
          formik.setSubmitting(false);
          Notifier.error(updateError.message);
          setPropagating(false);
        });
    }
    // TODO: ZonePolicyEditing: Implement ability to create zone policies
    // else {
    // const zonePolicyFields: CreateZonePolicyMutationVariables['policy'] = {
    //   name: values.name,
    //   rules: values.rules,
    //   properties: values.properties,
    // };
    //
    // createZonePolicy({
    //   variables: {
    //     policy: zonePolicyFields
    //   },
    // })
    //   .then((res) => {
    //     formik.setSubmitting(false);
    //     Notifier.success('Zone policy created');
    //     navigator('/zone-policies');
    //   })
    //   .catch((createError) => {
    //     formik.setSubmitting(false);
    //     Notifier.error(createError.message);
    //   });
    //   console.log('Create Zone Policy', values);
    // }
  };

  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    // rules: yup.array().required('Rules are required'),
    properties: yup.array(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const [value, setValue] = React.useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabStyles = {
    error: {
      color: 'red',
      fontWeight: '600',
    },
    normal: {
      fontWeight: 'inherit',
    },
  };

  return auth.user ? (
    <div style={{ padding: '6px' }}>
      <h2>
        {policy._id ? 'Edit' : 'Create'} Zone Policy
        {formik.values.name ? `: ${formik.values.name}` : ''}
      </h2>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form
          style={{ width: '100%', fontSize: '16px' }}
          onSubmit={formik.handleSubmit}
        >
          {propagating ? (
            <div>
              <ActivityIndicator activityInProgress={formik.isSubmitting} />
              <BulkUpdateStatus
                title="Setpoint Profile Propagation Status"
                initiator={policy}
                completeCallback={() => {
                  setPropagating(false);
                  formik.resetForm({ values: formik.values });
                }}
                actions={[
                  {
                    label: 'Return to Zone Policy List',
                    handler: () => navigator('/zone-policies'),
                  },
                  {
                    label: 'Return to editing',
                    handler: () => {
                      setPropagating(false);
                      formik.resetForm({ values: formik.values });
                    },
                  },
                ]}
              />
            </div>
          ) : (
            <div>
              {verifying ? (
                <div
                  style={{
                    marginBottom: '6px',
                    paddingBottom: '6px',
                    borderBottom: '1px solid lightgray',
                  }}
                >
                  <VerifyZonePolicyUpdate
                    values={formik.values}
                    initialValues={initialValues}
                    dataReadyCallback={setVerificationReady}
                  />
                </div>
              ) : (
                <div>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      marginBottom: '18px',
                    }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleTabChange}
                      aria-label="View or Edit Zone Policy"
                      TabIndicatorProps={{
                        style: { color: '#000' },
                      }}
                      indicatorColor="secondary"
                      textColor="secondary"
                    >
                      <Tab
                        label="General"
                        style={
                          formik.errors.name || formik.errors.rules
                            ? tabStyles.error
                            : tabStyles.normal
                        }
                        id="zone-policy-general-tab"
                      />
                      <Tab
                        label="Properties"
                        style={
                          formik.errors.properties
                            ? tabStyles.error
                            : tabStyles.normal
                        }
                        id="zone-policy-properties-tab"
                      />
                    </Tabs>
                  </Box>
                  <ActivityIndicator activityInProgress={formik.isSubmitting} />
                  {value === 0 ? (
                    <ZonePolicyGeneralTab
                      currentUser={auth.user}
                      formik={formik}
                      onChange={(e) => {
                        formik.setFieldTouched((e.target as any).id, true);
                        formik.handleChange(e);
                      }}
                    />
                  ) : (
                    <ZonePolicyPropertiesTab formik={formik} />
                  )}
                </div>
              )}
              <br />
              <div
                style={{
                  marginTop: '10px',
                  marginLeft: '10px',
                  padding: '24px',
                }}
              >
                {verifying && verificationReady ? (
                  <Button
                    disabled={!formik.isValid || !formik.dirty}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{ color: '#fff', fontSize: '12px' }}
                  >
                    Commit Changes
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ color: '#fff', fontSize: '12px' }}
                    disabled={!formik.isValid || !formik.dirty}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setVerifying(true);
                    }}
                  >
                    Verify Changes
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ color: '#fff', marginLeft: '10px', fontSize: '12px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (verifying) {
                      setVerifying(false);
                      setVerificationReady(false);
                    } else {
                      navigator('/zone-policies');
                    }
                  }}
                >
                  {verifying ? 'Return To Editing' : 'Return To List'}
                </Button>
              </div>
            </div>
          )}
        </Form>
      </Formik>
    </div>
  ) : (
    <div>Unauthorized</div>
  );
};
