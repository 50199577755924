/**
 * App.tsx
 * Basic app configuration happens here:
 * - Apollo client
 * - Environment variables
 * - Router setup and configuration
 * - Authentication provider is initialized here
 */

import React, { useEffect, useState } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Theme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { mdLightTheme, mdTheme } from './helpers/globalTheme';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSessionState } from './app/system/SessionStateManager';
import { AuthenticationProvider } from './app/auth/AuthenticationContext';
import { AuthorizationProvider } from './app/auth/AuthorizationContext';
import { ConnectedApolloProvider } from './app/system/ConnectedApolloProvider';
import { SystemCommunicationsProvider } from './app/system/SystemCommunications';
import { SystemHistoryProvider } from './app/ui/history/history-manager';
import { SystemConnectionProvider } from './app/system/ConnectionManager';
import { PendoProvider } from './app/system/PendoContext';

import { generateConnectedClient } from './helpers/apolloClient';

import { LocalState } from './app/system/services/localStateManager';
import { Log, LogCategory } from './app/system/services/logger';

import {
  AUTH_SESSION_TOKEN_NAME,
  CommunicationAddresses,
  LOCAL_AUTH_USER_KEY,
  serverConfig,
} from './helpers/config';

import './App.css';

type Connection = ReturnType<typeof generateConnectedClient>;

export default function App() {
  const SessionStateManager = useSessionState();
  const [addresses, setAddresses] = useState<CommunicationAddresses>();
  const [connection, setConnection] = useState<Connection>();
  const [displayTheme, setDisplayTheme] = useState<Theme>(mdTheme);

  useEffect(() => {
    serverConfig.then(({ getServerConnection }) => {
      setAddresses(getServerConnection());
    });
  }, []);

  useEffect(() => {
    if (addresses) {
      Log.silly('Connection addresses:', addresses, LogCategory.CONNECTION);
      setConnection(
        generateConnectedClient(addresses, () => {
          Log.silly('updating tokens ... ', null, LogCategory.TOKENS);
          SessionStateManager.updateTokens();
        }),
      );
    } else {
      Log.silly('no addresses yet', null, LogCategory.CONNECTION);
    }
  }, [addresses]);

  useEffect(() => {
    setDisplayTheme(
      SessionStateManager[AUTH_SESSION_TOKEN_NAME] === undefined ||
        !LocalState.itemExists(LOCAL_AUTH_USER_KEY)
        ? mdTheme
        : mdLightTheme,
    );
  }, [SessionStateManager]);

  Log.silly('rendering app', null, LogCategory.RENDERING);

  return connection ? (
    <SystemConnectionProvider connection={connection}>
      <ConnectedApolloProvider>
        <SystemCommunicationsProvider>
          <ThemeProvider theme={displayTheme}>
            <CssBaseline />
            <BrowserRouter>
              <SystemHistoryProvider>
                <PendoProvider>
                  <AuthenticationProvider>
                    <AuthorizationProvider />
                  </AuthenticationProvider>
                </PendoProvider>
              </SystemHistoryProvider>
            </BrowserRouter>
          </ThemeProvider>
        </SystemCommunicationsProvider>
      </ConnectedApolloProvider>
      <ToastContainer />
    </SystemConnectionProvider>
  ) : (
    Log.silly('No connection yet ...', null, LogCategory.CONNECTION) && null
  );
}
