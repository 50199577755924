import React, { useEffect } from 'react';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { Grid, MenuItem, Fade } from '@mui/material';
import TextField from '@mui/material/TextField';
import BorderedSection from '../shared/borderedSection';
import { Property } from '../../../types/generated-types';
import { Notifier } from '../../system/services/notificationManager';
import { AuthorizedUser } from '../../auth/models';
import { useFormik } from 'formik';
import { FormValues } from './types/unitDetailFormValues';

/* TODO: Calvin reintegrate Authorized Users Refer to other forms */

export function UnitGeneralTab(props: {
  currentUser: AuthorizedUser;
  property: Partial<Property>;
  formik: ReturnType<typeof useFormik<FormValues>>;
  onChange: StandardInputProps['onChange'];
}) {
  const { property } = props;
  const hasBuildings = property.buildings && property.buildings.length > 0;
  const hasSources = property.sources && property.sources.length > 0;

  useEffect(() => {
    if (!(hasBuildings && hasSources)) {
      Notifier.error(
        'Must add at least 1 building and 1 source to the property before adding units',
      );
    }
  }, [hasBuildings, hasSources]);

  return (
    <Fade in={true} style={{ transitionDuration: '1100ms' }}>
      <div style={{ width: '100%', height: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              autoFocus
              required
              id="unitName"
              name="unitName"
              label="Unit"
              value={props.formik.values.unitName}
              onChange={(e) => {
                props.formik.setFieldTouched('unitName', true);
                props.formik.handleChange(e);
              }}
            />
            <br />
            <br />
            <TextField
              fullWidth
              required
              select
              id="unitType"
              name="unitType"
              label="Type"
              value={props.formik.values.unitType}
              onChange={(e) => {
                props.formik.setFieldTouched('unitType', true);
                props.formik.handleChange(e);
              }}
            >
              <MenuItem key="1" value="residential">
                Residential
              </MenuItem>
              <MenuItem key="2" value="commonArea">
                Common Area
              </MenuItem>
              <MenuItem key="3" value="centralEquipment">
                Central Equipment
              </MenuItem>
            </TextField>
            <br />
            <br />
            <TextField
              id="buildingId"
              name="buildingId"
              required
              select
              value={props.formik.values.buildingId}
              onChange={(e) => {
                props.formik.setFieldTouched('buildingId', true);
                props.formik.handleChange(e);
              }}
              label="Building"
              fullWidth
            >
              {(property.buildings ?? []).map((building) => {
                return building ? (
                  <MenuItem key={building._id} value={building._id}>
                    {building.name}
                  </MenuItem>
                ) : null;
              })}
            </TextField>
            <br />
            <br />
            <TextField
              id="sourceId"
              name="sourceId"
              required
              value={props.formik.values.sourceId}
              onChange={(e) => {
                props.formik.setFieldTouched('sourceId', true);
                props.formik.handleChange(e);
              }}
              select
              label="Source"
              fullWidth
            >
              {(property.sources ?? []).map((source) => {
                return source ? (
                  <MenuItem key={source._id} value={source._id}>
                    {source.name}
                  </MenuItem>
                ) : null;
              })}
            </TextField>
            {props.formik.values.unitType === 'residential' ? (
              <>
                <br />
                <br />
                <BorderedSection title="Resident">
                  <TextField
                    fullWidth
                    id="residentName"
                    name="residentName"
                    variant="standard"
                    label="Name"
                    value={props.formik.values.residentName}
                    onChange={(e) => {
                      props.formik.setFieldTouched('residentName', true);
                      props.formik.handleChange(e);
                    }}
                  />
                  <br />
                  <br />
                  <TextField
                    fullWidth
                    id="residentEmail"
                    name="residentEmail"
                    variant="standard"
                    label="Email"
                    value={props.formik.values.residentEmail}
                    onChange={(e) => {
                      props.formik.setFieldTouched('residentEmail', true);
                      props.formik.handleChange(e);
                    }}
                  />
                  <br />
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="residentPhone"
                        name="residentPhone"
                        variant="standard"
                        label="Phone"
                        value={props.formik.values.residentPhone}
                        onChange={(e) => {
                          props.formik.setFieldTouched('residentPhone', true);
                          props.formik.handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="residentMobile"
                        name="residentMobile"
                        variant="standard"
                        label="Mobile"
                        value={props.formik.values.residentMobile}
                        onChange={(e) => {
                          props.formik.setFieldTouched('residentMobile', true);
                          props.formik.handleChange(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                </BorderedSection>
              </>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
}
