import React, { useState } from 'react';
import './TestSubscriptions.css';

import { BoilerList } from './BoilerList';
import { PropertyList } from './PropertyList';
import axios from 'axios';
import { Notifier } from '../../system/services/notificationManager';

import {
  AUTH_SESSION_EXPIRATION_TOKEN_NAME,
  AUTH_SESSION_TOKEN_NAME,
} from '../../../helpers/config';
import { getAuthHeaders } from '../../../helpers/apolloClient';

export function TestSubscriptions() {
  const [targetUserId, setTargetUserId] = useState('');
  const [commandMessage, setCommandMessage] = useState('');

  function submitSystemCommand() {
    const headers: Record<string, string> = getAuthHeaders(
      {
        'Content-Type': 'application/json',
      },
      [AUTH_SESSION_EXPIRATION_TOKEN_NAME, AUTH_SESSION_TOKEN_NAME],
    );

    axios
      .post(
        `/system/commands`,
        {
          targetUserId,
          commandMessage,
        },
        { headers },
      )
      .then(() => {
        Notifier.info('[System Command] Command successfully posted!');
        setCommandMessage('');
      })
      .catch((commandPostError) => {
        Notifier.error(
          `[System Command] Unable to post command: ${commandPostError.message}.`,
        );
      });
  }

  return (
    <div className="Subscriptions">
      <BoilerList />
      <PropertyList />
      <div className="Subscriptions-col">
        <p>Post a system command</p>
        <input
          type="text"
          placeholder="userId"
          value={targetUserId}
          onChange={(e) => setTargetUserId(e.target.value)}
        />
        <input
          type="text"
          placeholder="message"
          value={commandMessage}
          onChange={(e) => setCommandMessage(e.target.value)}
        />
        <button onClick={submitSystemCommand}>Send Command</button>
      </div>
    </div>
  );
}
