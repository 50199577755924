import * as React from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

/* TYPES */
import {
  useRemoveCoreMutation,
  useRemoveHmmMutation,
  useRemoveBngMutation,
  SourceListQuery,
} from '../../../types/generated-types';

/* SHARED COMPONENTS */
import DataTable from '../shared/data-table';
import { Notifier } from '../../system/services/notificationManager';

/* MUI */
import {
  GridRowParams,
  GridRenderCellParams,
  useGridApiRef,
  GridColDef,
  GridFilterModel,
} from '@mui/x-data-grid';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  useMediaQuery,
  Typography,
  Theme,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

/* ICONS */
import DeleteIcon from '@mui/icons-material/Delete';

import {
  MobileListCard,
  MobileListDataTableProps,
} from '../shared/mobile-list-card';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../system/services/injectableComponentsManager';
import { Add } from '@mui/icons-material';
import { useEffect } from 'react';
import { useSystemConnection } from '../../system/ConnectionManager';

type Source = SourceListQuery['sources'][number];
type SourceRow = Source & {
  id: string;
  lastHeartbeat: string;
};

interface SourceListProps {
  data?: SourceListQuery['sources'];
  loading: boolean;
  showRefreshButton?: boolean;
  onRefresh?: () => Promise<unknown>;
}

interface RemoveSourceDialogProps {
  open: boolean;
  handleClose: () => void;
  removeSource: () => void;
}

function formatData(data: SourceListQuery['sources']) {
  const formattedData: SourceRow[] = [];
  data.forEach((row) => {
    const lastHeartbeat = row.timestamp
      ? moment(row.timestamp).format('MMM D, YYYY h:mm:ss A')
      : 'No heartbeats received';
    formattedData.push({
      ...row,
      id: row._id,
      lastHeartbeat,
    });
  });
  return formattedData;
}

const RemoveSourceDialog = (props: RemoveSourceDialogProps) => {
  const { open, handleClose, removeSource } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Remove Source'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure? this action cannot be reversed
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            removeSource();
            handleClose();
          }}
          autoFocus
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function SourceList(props: SourceListProps) {
  const { data, showRefreshButton, onRefresh } = props;
  const [open, setOpen] = React.useState(false);
  const rows = data || [];
  const formattedData = formatData(rows);
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const { type } = useParams<{ type: string }>();
  const { pathname } = currentLocation;
  const theme = useTheme();

  const apiRef = useGridApiRef();

  const [sourceId, setSelectedSourceId] = React.useState<Source['_id']>('');
  const [sourceType, setSelectedSourceType] = React.useState('');

  React.useEffect(() => {
    const filterModel: GridFilterModel = {
      items: [{ value: 'core', operator: 'equals', field: 'type' }],
    };
    apiRef.current.setFilterModel(filterModel, 'changeLogicOperator');
  }, [apiRef]);

  React.useEffect(() => {
    const filterType = (type ?? 'all').toLowerCase();
    let filterValue;
    switch (filterType) {
      case 'core':
        filterValue = 'Core';
        break;
      case 'bng':
        filterValue = 'BNG';
        break;
      case 'hmm':
        filterValue = 'HMM';
        break;
      default:
        filterValue = 'all';
        break;
    }
    if (filterValue !== 'all') {
      const filterModel: GridFilterModel = {
        items: [{ value: filterValue, operator: 'equals', field: 'type' }],
      };
      apiRef.current.setFilterModel(filterModel, 'changeLogicOperator');
    } else {
      apiRef.current.deleteFilterItem({ field: 'type', operator: 'equals' });
    }
    setSelectedSourceType(filterValue);
  }, [apiRef, type]);

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const handleRowClick = (params: GridRowParams) => {
    navigate(`/sources/${params.row?.id}/edit-source`);
  };
  const [deleteCore] = useRemoveCoreMutation();
  const [deleteHmm] = useRemoveHmmMutation();
  const [deleteBng] = useRemoveBngMutation();

  const handleRemoveSource = async () => {
    switch (sourceType) {
      case 'Core':
        try {
          await deleteCore({ variables: { id: sourceId } });
          Notifier.success('Source deleted');
        } catch (e) {
          Notifier.error('Unable to delete Source');
        }
        break;
      case 'BNG':
        try {
          await deleteBng({ variables: { id: sourceId } });
          Notifier.success('Source deleted');
        } catch (e) {
          Notifier.error('Unable to delete Source');
        }
        break;
      case 'HMM':
        try {
          await deleteHmm({ variables: { id: sourceId } });
          Notifier.success('Source deleted');
        } catch (e) {
          Notifier.error('Unable to delete Source');
        }
        break;
      default:
    }
  };

  function getHeartbeatColor(
    timestamp: number | null | undefined,
    theme: Theme,
  ): string {
    if (!timestamp) return theme.palette.grey[500];

    const timeDiff = Date.now() - timestamp;
    if (timeDiff < 75000) {
      return theme.palette.success.main;
    } else if (timeDiff < 300000) {
      return theme.palette.warning.main;
    } else {
      return theme.palette.error.main;
    }
  }

  const nameColumn = isSmall
    ? ({
        field: 'name',
        headerName: 'Name',
        align: 'center',
        flex: 1,
        renderCell: (params: GridRenderCellParams<SourceRow>) => {
          const localUrl =
            params.row.__typename === 'BNG' && params.row.localUrl;
          const heartbeatColor = getHeartbeatColor(params.row.timestamp, theme);
          return (
            <MobileListCard>
              <Typography
                sx={{ fontSize: 14, fontWeight: 800 }}
                color="text.secondary"
                gutterBottom
              >
                {params.value}
              </Typography>
              {params.row.url && (
                <Typography variant="body2" gutterBottom>
                  URL: {params.row.url}
                </Typography>
              )}
              {localUrl && (
                <Typography variant="body2" gutterBottom>
                  Local URL: {localUrl}
                </Typography>
              )}
              <Typography variant="body2">
                Last Heartbeat:{' '}
                <span style={{ color: heartbeatColor }}>
                  {params.row.lastHeartbeat}
                </span>
              </Typography>
            </MobileListCard>
          );
        },
      } as GridColDef)
    : { field: 'name', headerName: 'Name', width: 300 };

  const columns = [
    {
      field: 'action',
      headerName: 'Actions',
      filterable: false,
      sortable: false,
      width: 90,
      renderCell: (params: GridRenderCellParams<SourceRow>) => (
        <IconButton
          onClick={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClickOpen();
            setSelectedSourceType(params?.row?.type ?? '');
            setSelectedSourceId(params?.row?._id ?? '');
          }}
          disabled={params.row?.inUse}
          style={{ marginLeft: 16 }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
    { field: 'id', headerName: 'Source ID', width: 220 },
    { field: 'type', headerName: 'Type', width: 60 },
    { ...nameColumn },
    { field: 'url', headerName: 'URL', width: 310 },
    { field: 'localUrl', headerName: 'Local URL', width: 310 },
    {
      field: 'lastHeartbeat',
      headerName: 'Last Heartbeat',
      width: 200,
      renderCell: (params: GridRenderCellParams<SourceRow>) => {
        const heartbeatColor = getHeartbeatColor(params.row.timestamp, theme);
        return (
          <span style={{ color: heartbeatColor }}>
            {params.row.lastHeartbeat}
          </span>
        );
      },
    },
  ];
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { setContextMenuItems } = useInjectableComponents();

  const pathComponent = pathname.split('/').slice(0, 5);

  const { client } = useSystemConnection();
  useEffect(() => {
    const items: BottomMenuItems = [
      {
        items: [
          {
            id: 'menu-item-add-source',
            label: 'Add Source',
            icon: <Add fontSize="small" />,
            action: () => navigate('/sources/create'),
            permit: {
              action: 'create',
              subject: 'Source',
            },
          },
          {
            id: 'menu-item-refresh-sources',
            label: 'Refresh Source List',
            icon: <Add fontSize="small" />,
            action: async () => {
              await client?.refetchQueries({ include: ['SourceList'] });
              Notifier.success('Sources refreshed');
            },
          },
        ],
      },
    ];

    setContextMenuItems(items);
    return () => {
      setContextMenuItems(undefined);
    };
  }, [pathname]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        cursor: 'pointer',
      }}
    >
      <DataTable
        {...(isSmall ? MobileListDataTableProps : {})}
        additionalProps={{
          onRowClick: (i: GridRowParams) => handleRowClick(i),
        }}
        rowHeight={isSmall ? 115 : undefined}
        columnVisibilityModel={{
          action: false,
          type: !isSmall,
          id: false,
          url: !isSmall,
          localUrl: !isSmall,
          lastHeartbeat: !isSmall,
        }}
        rows={formattedData}
        suppressAutoPageSize={true}
        columns={columns}
        apiRef={apiRef}
        hideFilterSorting={pathComponent[3] === 'true'}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
        showRefreshButton={showRefreshButton}
        onRefresh={onRefresh}
        loading={props.loading}
      />
      <RemoveSourceDialog
        open={open}
        handleClose={() => handleClose()}
        removeSource={() => handleRemoveSource()}
      />
    </div>
  );
}
