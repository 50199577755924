import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { FormValues } from './types/sourceDetailFormValues';
import {
  CreateCoreMutationVariables,
  UpdateCoreMutationVariables,
  useCreateCoreMutation,
  useUpdateCoreMutation,
  CreateHmmMutationVariables,
  UpdateHmmMutationVariables,
  useCreateHmmMutation,
  useUpdateHmmMutation,
  CreateBngMutationVariables,
  UpdateBngMutationVariables,
  useCreateBngMutation,
  useUpdateBngMutation,
  usePropertyListForSourceEditorQuery,
  EnumDKeySourceType,
  Core,
  Bng,
  Hmm,
} from '../../../types/generated-types';
import { Form, Formik, FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';
// import { ActivityIndicator } from '../shared/activityIndicator';
import { Notifier } from '../../system/services/notificationManager';
import BorderedSection from '../shared/borderedSection';
import { useAuthorizer } from '../../auth/AuthorizationContext';

const toNumber = (value: string): number | undefined => {
  if (value.length === 0) {
    return undefined;
  }
  return parseFloat(value);
};

// TODO: need to add validation for the other fields conditionally based on type.
//  will do this shortly but wanted to get this up as is as it is basically working
//  for now. Also have to test authorization to ensure only super users are able to
//  view and edit these entities.
const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  type: yup.string().required('Type is required'),
  url: yup.string().url().required('URL is required'),
  localUrl: yup
    .string()
    .url()
    .when('type', {
      is: (type: string) => type === EnumDKeySourceType.Bng,
      then: yup.string().required('Local URL is required for BNG sources'),
    }),
  temperature: yup.string().when('type', {
    is: (type: string) => type === EnumDKeySourceType.Bng,
    then: yup
      .string()
      .length(
        0,
        'Temperature alert limit values are not present on BNG type sources.',
      ),
  }),
  humidity: yup.string().when('type', {
    is: (type: string) =>
      type === EnumDKeySourceType.Bng || type === EnumDKeySourceType.Hmm,
    then: yup
      .string()
      .length(
        0,
        'Humidity alert limit values are not present on BNG or HMM type sources.',
      ),
  }),
  voltage: yup.string().when('type', {
    is: (type: string) =>
      type === EnumDKeySourceType.Bng || type === EnumDKeySourceType.Hmm,
    then: yup
      .string()
      .length(
        0,
        'Voltage alert limit values are not present on BNG or HMM type sources.',
      ),
  }),
  maxReportingTime: yup.string().when('type', {
    is: (type: string) =>
      type === EnumDKeySourceType.Bng || type === EnumDKeySourceType.Hmm,
    then: yup
      .string()
      .length(
        0,
        'Humidity alert limit values are not present on BNG or HMM type sources.',
      ),
  }),
  propertyId: yup.string().required('Sources must have an assigned property.'),
});

const ToleranceView = ({ type, formik }: { formik: any; type: any }) => {
  switch (type) {
    case 'Core':
      return (
        <BorderedSection title="Measurement Tolerances">
          <TextField
            fullWidth
            id="temperature"
            name="temperature"
            type="string"
            label="Temperature (C)"
            value={formik.values.temperature}
            onChange={(e) => {
              formik.setFieldTouched('temperature', true);
              formik.handleChange(e);
            }}
            error={!!formik.touched.temperature && !!formik.errors.temperature}
            helperText={formik.touched.temperature && formik.errors.temperature}
          />
          <br />
          <TextField
            fullWidth
            id="humidity"
            name="humidity"
            type="number"
            label="Humidity (%)"
            value={formik.values.humidity}
            onChange={(e) => {
              formik.setFieldTouched('humidity', true);
              formik.handleChange(e);
            }}
            error={!!formik.touched.humidity && !!formik.errors.humidity}
            helperText={formik.touched.humidity && formik.errors.humidity}
          />
          <br />
          <TextField
            fullWidth
            id="voltage"
            type="number"
            name="voltage"
            label="Voltage (V)"
            value={formik.values.voltage}
            onChange={(e) => {
              formik.setFieldTouched('voltage', true);
              formik.handleChange(e);
            }}
            error={!!formik.touched.voltage && !!formik.errors.voltage}
            helperText={formik.touched.voltage && formik.errors.voltage}
          />
        </BorderedSection>
      );
    case 'HMM':
      return (
        <BorderedSection title="Measurement Tolerances">
          <TextField
            fullWidth
            type="number"
            id="temperature"
            name="temperature"
            label="Temperature (C)"
            value={formik.values.temperature}
            onChange={(e) => {
              formik.setFieldTouched('temperature', true);
              formik.handleChange(e);
            }}
          />
        </BorderedSection>
      );
    default:
      return <div></div>;
  }
};

export const SourceDetailForm = ({
  source,
}: {
  source?: Partial<Core | Bng | Hmm>;
}) => {
  const {
    data: propertyData,
    // TODO: Loading_error: should we do something with the loading state for this query?
    // loading: propertiesLoading,
    error: propertiesError,
  } = usePropertyListForSourceEditorQuery();

  useEffect(() => {
    if (propertiesError) {
      Notifier.error(
        'There was a problem fetching the property information: ',
        propertiesError,
      );
    }
  }, [propertiesError]);

  const navigate = useNavigate();

  const initialValues: FormValues = {
    ...(source
      ? {
          name: source.name ?? '',
          type: source.type ?? '',
          url: source.url ?? '',
          localUrl:
            source.__typename === EnumDKeySourceType.Bng
              ? source.localUrl
                ? source.localUrl
                : ''
              : '',
          temperature:
            source.__typename === EnumDKeySourceType.Hmm ||
            source.__typename === EnumDKeySourceType.Core
              ? source.tolerances?.temperature?.toString() ?? ''
              : '',
          humidity:
            source.__typename === EnumDKeySourceType.Core
              ? source.tolerances?.humidity?.toString() ?? ''
              : '',
          voltage:
            source.__typename === EnumDKeySourceType.Core
              ? source.tolerances?.voltage?.toString() ?? ''
              : '',
          maxReportingTime:
            source.__typename === EnumDKeySourceType.Hmm ||
            source.__typename === EnumDKeySourceType.Core
              ? source.maxReportingTime?.toString() ?? ''
              : '',
          propertyId: source.propertyId ?? '',
        }
      : {
          name: '',
          type: '',
          url: '',
          localUrl: '',
          temperature: '',
          humidity: '',
          voltage: '',
          maxReportingTime: '',
          propertyId: '',
        }),
  };

  const [createCore] = useCreateCoreMutation({
    refetchQueries: ['SourceList'],
  });
  const [updateCore] = useUpdateCoreMutation({
    refetchQueries: ['SourceList'],
  });

  const [createHmm] = useCreateHmmMutation({ refetchQueries: ['SourceList'] });
  const [updateHmm] = useUpdateHmmMutation({ refetchQueries: ['SourceList'] });

  const [createBng] = useCreateBngMutation({ refetchQueries: ['SourceList'] });
  const [updateBng] = useUpdateBngMutation({ refetchQueries: ['SourceList'] });

  const onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    if (!can('update', 'Source')) {
      Notifier.error('You do not have permission to update this source.');
      return;
    }
    switch (values.type) {
      case 'Core':
        if (source?._id) {
          if (values.name && values.url) {
            const sourceVars: UpdateCoreMutationVariables['core'] = {
              name: values.name,
              url: values.url,
              maxReportingTime: toNumber(values.maxReportingTime),
              tolerances: {
                temperature: toNumber(values.temperature),
                humidity: toNumber(values.humidity),
                voltage: toNumber(values.voltage),
              },
            };
            updateCore({ variables: { id: source._id, core: sourceVars } })
              .then(() => {
                formik.setSubmitting(false);
                Notifier.success('Source updated successfully');
                navigate('/sources');
              })
              .catch((updateError) => {
                formik.setSubmitting(false);
                Notifier.error(updateError.message);
              });
          } else {
            Notifier.error('Name, and URL are required');
          }
        } else {
          if (values.name && values.url && values.propertyId) {
            const sourceVars: CreateCoreMutationVariables['core'] = {
              name: values.name,
              url: values.url,
              propertyId: values.propertyId,
              maxReportingTime: toNumber(values.maxReportingTime),
              tolerances: {
                temperature: toNumber(values.temperature),
                humidity: toNumber(values.humidity),
                voltage: toNumber(values.voltage),
              },
            };
            createCore({
              variables: { core: sourceVars },
            })
              .then(() => {
                formik.setSubmitting(false);
                Notifier.success('Core added successfully');
                navigate(-1);
              })
              .catch((createError) => {
                formik.setSubmitting(false);
                Notifier.error(createError.message);
              });
          } else {
            Notifier.error('Name, URL and a selected property are required');
          }
        }
        break;
      case 'HMM':
        if (source?._id) {
          if (values.name && values.url) {
            const sourceVars: UpdateHmmMutationVariables['hmm'] = {
              name: values.name,
              url: values.url,
              maxReportingTime: toNumber(values.maxReportingTime),
              tolerances: {
                temperature: toNumber(values.temperature),
              },
            };
            updateHmm({ variables: { id: source._id, hmm: sourceVars } })
              .then(() => {
                formik.setSubmitting(false);
                Notifier.success('HMM updated successfully');
                navigate(-1);
              })
              .catch((updateError) => {
                formik.setSubmitting(false);
                Notifier.error(updateError.message);
              });
          } else {
            Notifier.error('Name, and URL are required');
          }
        } else {
          if (values.name && values.url && values.propertyId) {
            const sourceVars: CreateHmmMutationVariables['hmm'] = {
              name: values.name,
              url: values.url,
              propertyId: values.propertyId,
              maxReportingTime: toNumber(values.maxReportingTime),
              tolerances: {
                temperature: toNumber(values.temperature),
              },
            };
            createHmm({
              variables: { hmm: sourceVars },
            })
              .then(() => {
                formik.setSubmitting(false);
                Notifier.success('HMM added successfully');
                navigate(-1);
              })
              .catch((createError) => {
                formik.setSubmitting(false);
                Notifier.error(createError.message);
              });
          } else {
            Notifier.error('Name, URL and a selected property are required');
          }
        }
        break;
      case 'BNG':
        if (source?._id) {
          if (values.name && values.url && values.localUrl) {
            const sourceVars: UpdateBngMutationVariables['bng'] = {
              name: values.name,
              url: values.url,
              localUrl: values.localUrl,
            };
            updateBng({ variables: { id: source._id, bng: sourceVars } })
              .then(() => {
                formik.setSubmitting(false);
                Notifier.success('BNG updated successfully');
                navigate(-1);
              })
              .catch((updateError) => {
                formik.setSubmitting(false);
                Notifier.error(updateError.message);
              });
          } else {
            Notifier.error('Name, URL and Local URL are required');
          }
        } else {
          if (
            values.name &&
            values.url &&
            values.propertyId &&
            values.localUrl
          ) {
            const sourceVars: CreateBngMutationVariables['bng'] = {
              name: values.name,
              url: values.url,
              propertyId: values.propertyId,
              localUrl: values.localUrl,
            };
            createBng({
              variables: { bng: sourceVars },
            })
              .then(() => {
                formik.setSubmitting(false);
                Notifier.success('BNG added successfully');
                navigate(-1);
              })
              .catch((createError) => {
                formik.setSubmitting(false);
                Notifier.error(createError.message);
              });
          } else {
            Notifier.error(
              'Name, URL, a Local URL and a selected property are required',
            );
          }
        }
    }
  };

  const { can } = useAuthorizer();

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: '1fr auto',
        height: 'inherit',
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <Form
          style={{
            width: '100%',
            fontSize: '16px',
            height: 'inherit',
          }}
          onSubmit={formik.handleSubmit}
        >
          {/* <div>
              <ActivityIndicator activityInProgress={formik.isSubmitting} />
            </div> */}
          <div
            style={{
              paddingTop: '10px',
              paddingBottom: '10px',
              overflowY: 'scroll',
              height: '100%',
              paddingRight: '10px',
              paddingLeft: '10px',
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                {/* Display source ID, even if it can't be edited */}
                <TextField
                  fullWidth
                  id="sourceId"
                  name="sourceId"
                  label="Source ID"
                  value={source?._id || ''}
                  disabled={true}
                />
                <br />
                <br />
                <TextField
                  fullWidth
                  autoFocus
                  required
                  id="name"
                  name="name"
                  label="Name"
                  value={formik.values.name}
                  onChange={(e) => {
                    formik.setFieldTouched('name', true);
                    formik.handleChange(e);
                  }}
                  error={!!formik.touched.name && !!formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <br />
                <br />
                {propertyData?.properties && (
                  <TextField
                    id="propertyId"
                    name="propertyId"
                    disabled={!source?._id}
                    fullWidth
                    select
                    label="Property"
                    defaultValue="none"
                    value={formik.values.propertyId}
                    onChange={(e) => {
                      formik.setFieldTouched('propertyId', true);
                      formik.handleChange(e);
                    }}
                    error={
                      !!formik.touched.propertyId && !!formik.errors.propertyId
                    }
                    helperText={
                      formik.touched.propertyId && formik.errors.propertyId
                    }
                  >
                    <MenuItem key="none" value="none">
                      None
                    </MenuItem>
                    {propertyData.properties.map((property, index) => (
                      <MenuItem key={property._id} value={property._id}>
                        {property.title}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                <br />
                <br />
                <TextField
                  fullWidth
                  required
                  select
                  id="type"
                  name="type"
                  label="Type"
                  value={formik.values.type}
                  onChange={(e) => {
                    if (e.target.value === 'HMM') {
                      formik.setFieldValue('humidity', '');
                      formik.setFieldValue('voltage', '');
                      formik.setFieldValue(
                        'temperature',
                        formik.initialValues.temperature,
                      );
                      formik.setFieldValue(
                        'maxReportingTime',
                        formik.initialValues.maxReportingTime,
                      );
                      formik.setFieldValue('localUrl', '');
                    } else if (e.target.value === 'BNG') {
                      formik.setFieldValue('humidity', '');
                      formik.setFieldValue('voltage', '');
                      formik.setFieldValue('temperature', '');
                      formik.setFieldValue('maxReportingTime', '');
                      formik.setFieldValue(
                        'localUrl',
                        formik.initialValues.localUrl,
                      );
                    } else {
                      formik.setFieldValue(
                        'humidity',
                        formik.initialValues.humidity,
                      );
                      formik.setFieldValue(
                        'voltage',
                        formik.initialValues.voltage,
                      );
                      formik.setFieldValue(
                        'temperature',
                        formik.initialValues.temperature,
                      );
                      formik.setFieldValue(
                        'maxReportingTime',
                        formik.initialValues.maxReportingTime,
                      );
                      formik.setFieldValue('localUrl', '');
                    }
                    formik.setFieldTouched('type', true);
                    formik.handleChange(e);
                  }}
                  error={!!formik.touched.type && !!formik.errors.type}
                  helperText={formik.touched.type && formik.errors.type}
                >
                  <MenuItem key="1" value="Core">
                    Core
                  </MenuItem>
                  <MenuItem key="2" value="HMM">
                    HMM
                  </MenuItem>
                  <MenuItem key="3" value="BNG">
                    BNG
                  </MenuItem>
                </TextField>
                <br />
                <br />
                <TextField
                  id="url"
                  name="url"
                  value={formik.values.url}
                  onChange={(e) => {
                    formik.setFieldTouched('url', true);
                    formik.handleChange(e);
                  }}
                  error={!!formik.touched.url && !!formik.errors.url}
                  helperText={formik.touched.url && formik.errors.url}
                  label="URL"
                  fullWidth
                />
                {formik.values.type === 'BNG' ? (
                  <>
                    <br />
                    <br />
                    <TextField
                      id="localUrl"
                      name="localUrl"
                      value={formik.values.localUrl}
                      onChange={(e) => {
                        formik.setFieldTouched('localUrl', true);
                        formik.handleChange(e);
                      }}
                      error={
                        !!formik.touched.localUrl && !!formik.errors.localUrl
                      }
                      helperText={
                        formik.touched.localUrl && formik.errors.localUrl
                      }
                      label="Local URL"
                      fullWidth
                    />
                  </>
                ) : null}
                <br />
                <br />
                {formik.values.type === 'BNG' ? null : (
                  <>
                    <TextField
                      id="maxReportingTime"
                      name="maxReportingTime"
                      value={formik.values.maxReportingTime}
                      label="Maximum Reporting Time"
                      onChange={(e) => {
                        formik.setFieldTouched('maxReportingTime', true);
                        formik.handleChange(e);
                      }}
                      error={
                        !!formik.touched.maxReportingTime &&
                        !!formik.errors.maxReportingTime
                      }
                      helperText={
                        formik.touched.maxReportingTime &&
                        formik.errors.maxReportingTime
                      }
                      fullWidth
                      type="number"
                    />
                    <br />
                    <br />
                    <br />
                  </>
                )}
              </Grid>
              <ToleranceView formik={formik} type={formik.values.type} />
            </Grid>

            <div
              style={{ marginTop: '10px', marginLeft: '10px', padding: '24px' }}
            >
              {can('update', 'Source') ? (
                <Button
                  disabled={!formik.isValid || !formik.dirty}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  sx={{ color: '#fff' }}
                >
                  Save
                </Button>
              ) : null}
              <Button
                sx={{ marginLeft: can('update', 'Source') ? '10px' : '0px' }}
                variant="text"
                onClick={() => navigate(-1)}
                color={
                  !formik.isValid || !formik.dirty ? 'secondary' : 'primary'
                }
              >
                {can('update', 'Source') ? 'Cancel' : 'Return to List'}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
