import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAuthenticator } from './AuthenticationContext';
import { useBodyClass } from '../../helpers/bodyClass';
import { useSystemConnection } from '../system/ConnectionManager';

import { Log, LogCategory } from '../system/services/logger';
import { LocalState } from '../system/services/localStateManager';

import './AuthorizationContext.css';
import { mdTheme } from '../../helpers/globalTheme';

import {
  CONNECTION_STATE_TRANSITIONING_KEY,
  LOGGING_OUT_KEY,
} from '../../helpers/config';

import { Shell } from '../ui/shell/shell';
import { SystemShell } from '../system/SystemShell';
import {
  Actions,
  AppAbility,
  defineAbilityFor,
  SubjectTypes,
} from './types/ability';

import {
  AuthorizationChecker,
  isAuthorized,
  RouteAuthorizationChecker,
} from './types/can';

import { AnyAbility, subject } from '@casl/ability';
import { BoundCanProps } from '@casl/react/dist/types/Can';

import { createContextualCan } from '@casl/react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const AbilityContext = createContext<AppAbility>(undefined!);

const FrameworkCan = createContextualCan(AbilityContext.Consumer);

type Ability = Exclude<ReturnType<typeof defineAbilityFor>, undefined>;

export function Can<
  T extends AnyAbility,
  R extends { __typename?: string } | undefined | null,
>(props: BoundCanProps<T> & { my: R }) {
  const targetSubject = props.my;
  const newProps = { ...props, this: undefined };
  if (targetSubject) {
    newProps.this = targetSubject.__typename
      ? subject(targetSubject.__typename, { ...targetSubject })
      : targetSubject;
  }

  return (
    <FrameworkCan {...(newProps as any)}>{newProps.children}</FrameworkCan>
  );
}

export interface IAuthorizer {
  terminateSession: () => void;
  ability: Ability;
  can: AuthorizationChecker;
  cannot: AuthorizationChecker;
  isRoutePermitted: RouteAuthorizationChecker;
  Can: typeof Can;
}

const AuthorizationContext = createContext<IAuthorizer>({
  terminateSession() {
    return Promise.resolve(true);
  },
  ability: defineAbilityFor(undefined),
  isRoutePermitted: () => false,
  can: () => false,
  cannot: () => false,
  Can,
});

AuthorizationContext.displayName = 'AuthorizationContext';

export const AuthorizationProvider: FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { activeSession, sessionExpired, signOut, user } = useAuthenticator();
  const signingOut = LocalState.itemExists(LOGGING_OUT_KEY);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { connected } = useSystemConnection();
  const [ability, setAbility] = React.useState<Ability>(
    defineAbilityFor(undefined),
  );

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const isLoggingOut = pathname === '/logging-out';

  useBodyClass(
    !isLoggingOut && activeSession
      ? 'authenticated'
      : isSmall
        ? 'guest-mobile'
        : 'guest',
  );

  const logout = useCallback(() => {
    if (!LocalState.itemExists(CONNECTION_STATE_TRANSITIONING_KEY)) {
      try {
        LocalState.setLoggingOutState(
          () => {
            navigate('/login', { replace: true });
          },
          () => {
            if (connected) {
              signOut()
                .then(() => {
                  navigate('/login', { replace: true });
                })
                .catch((logoutError) => {
                  // TODO: Testing_required: Peter: try to simulate when this might happen and what to do about it if/when it does.
                  Log.warn('Logout attempt failed', logoutError);
                  LocalState.setItem('pending-logout', true);
                });
            } else {
              // TODO: Security: Peter: I don't think we should/can do any of this as, if there is no connection,
              //  this component is not rendered so I don't think we can ever even get here.
              // Cannot actually do a remote logout so, next best thing:
              //   1. Remove local auth user instance from storage
              //   2. Clear session-related tokens
              //   3. Clear client cache.
              // ... as in the following lines of code:
              //   LocalState.removeItems([LOCAL_AUTH_USER_KEY, LOGGING_OUT_KEY, AUTH_SESSION_TOKEN_NAME, AUTH_SESSION_EXPIRATION_TOKEN_NAME]);
              //   SessionStateManager.updateTokens();
              //   client?.clearStore();
              // TODO: Security: Peter: check if this EVER gets set ... I don't think it will.
              LocalState.setItem('pending-logout', true);
            }
          },
          () => {
            navigate('logging-out', { replace: true });
          },
        );
      } catch (logoutAttemptError) {
        // TODO: Testing_required: Peter: try to figure out what might cause this so we can simulate it.
        //  Then we can figure out what to do about it if it does happen.
        Log.warn('Master Logout attempt failed', logoutAttemptError);
        LocalState.setItem('pending-logout', true);
      }
    }
  }, [connected, navigate, signOut]);

  useEffect(() => {
    if (sessionExpired === true) {
      logout();
    } else {
      // TODO: Peter: don't think we need this any longer. Can't find where it might even be ever set ?!?!?!
      LocalState.removeItem('expired');
    }
  }, [logout, sessionExpired]);

  useEffect(() => {
    setAbility(defineAbilityFor(user));
  }, [user]);

  const can: AuthorizationChecker = (action, subject, field) => {
    if (ability) {
      return isAuthorized(ability, action, subject, field);
    } else {
      return false;
    }
  };
  const cannot: AuthorizationChecker = (action, subject, field) => {
    return !can(action, subject, field);
  };

  const isRoutePermitted: RouteAuthorizationChecker = (route) => {
    const subject: SubjectTypes | undefined = route.authType;
    const action: Actions | Actions[] | undefined = route.authAction;

    let res;
    if (action === undefined) {
      res = subject === undefined || can('index', subject);
    } else if (action instanceof Array) {
      res = action.some((testAction) => can(testAction, subject));
    } else {
      res = can(action, subject);
    }
    return res;
  };

  const value: IAuthorizer = {
    terminateSession: logout,
    isRoutePermitted,
    ability,
    can,
    cannot,
    Can,
  };

  Log.silly('rendering authorization context', null, LogCategory.RENDERING);
  return ability ? (
    <AuthorizationContext.Provider value={value}>
      <AbilityContext.Provider value={ability}>
        {!activeSession || signingOut ? (
          <SystemShell />
        ) : children ? (
          children
        ) : (
          <Shell theme={mdTheme} />
        )}
      </AbilityContext.Provider>
    </AuthorizationContext.Provider>
  ) : (
    <div>No user!</div>
  );
};

export const useAuthorizer = (): IAuthorizer => {
  const context = useContext(AuthorizationContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthorizationProvider');
  }
  return context;
};
