import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

/* MRT Imports */
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_VisibilityState,
} from 'material-react-table';

import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { AlertsListQuery } from '../../../types/generated-types';

type AlertsListData = AlertsListQuery['alerts'];

interface AlertsListProps {
  alerts: AlertsListData;
  loading: boolean;
}
interface AlertRow {
  deviceId: string;
  deviceName: string;
  deviceType: string;
  id: string;
  propertyId: string;
  propertyName: string;
  severity: string;
  timestamp: number;
  type: string;
  unitId?: string;
  unitName?: string;
}

export function AlertsList(props: AlertsListProps) {
  const { alerts } = props;
  const navigate = useNavigate();
  const { pathname, state: specificDevices } = useLocation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [screenBreakLevel, setScreenBreakLevel] = React.useState<number>(0);

  type AlertRowFieldName =
    | 'id'
    | 'deviceId'
    | 'propertyId'
    | 'unitId'
    | 'severity'
    | 'type'
    | 'deviceName'
    | 'deviceType'
    | 'propertyName'
    | 'unitName'
    | 'timestamp';

  enum ColumnVisibility {
    ALWAYS = 'always',
    SOMETIMES = 'sometimes',
    NEVER = 'never',
    NOT_ON_SMALL = 'not_on_small',
  }

  /**
   * fields: an array of field definition objects, holding configuration data about how columns are to be displayed.
   */
  const fields: Array<{
    name: AlertRowFieldName;
    title: string;
    smallTitle: string;
    mediumTitle: string;
    show: ColumnVisibility;
  }> = [
    {
      name: 'id',
      title: '',
      smallTitle: '',
      mediumTitle: '',
      show: ColumnVisibility.NEVER,
    },
    {
      name: 'unitId',
      title: '',
      smallTitle: '',
      mediumTitle: '',
      show: ColumnVisibility.NEVER,
    },
    {
      name: 'propertyId',
      title: '',
      smallTitle: '',
      mediumTitle: '',
      show: ColumnVisibility.NEVER,
    },
    {
      name: 'deviceId',
      title: '',
      smallTitle: '',
      mediumTitle: '',
      show: ColumnVisibility.NEVER,
    },
    {
      name: 'severity',
      title: '',
      smallTitle: '',
      mediumTitle: '',
      show: ColumnVisibility.NEVER,
    },
    {
      name: 'type',
      title: 'Type',
      smallTitle: 'Type',
      mediumTitle: 'Type',
      show: ColumnVisibility.ALWAYS,
    },
    {
      name: 'deviceName',
      title: 'Device Name',
      smallTitle: 'Dv Name',
      mediumTitle: 'Dev Name',
      show: ColumnVisibility.NOT_ON_SMALL,
    },
    {
      name: 'deviceType',
      title: 'Device Type',
      smallTitle: 'Dv Type',
      mediumTitle: 'Dev Type',
      show: ColumnVisibility.NOT_ON_SMALL,
    },
    {
      name: 'propertyName',
      title: 'Property Name',
      smallTitle: 'Prop',
      mediumTitle: 'Prop Name',
      show: ColumnVisibility.SOMETIMES,
    },
    {
      name: 'unitName',
      title: 'Unit Name',
      smallTitle: 'Unit',
      mediumTitle: 'Unit',
      show: ColumnVisibility.ALWAYS,
    },
    {
      name: 'timestamp',
      title: 'First Reported',
      smallTitle: 'Since',
      mediumTitle: 'Reported',
      show: ColumnVisibility.ALWAYS,
    },
  ];

  const handleRowClick = (row: AlertRow) => {
    const alertType = row.type;
    const propertyId = row.propertyId;
    const unitId = row.unitId;
    const deviceId = row.deviceId;

    switch (alertType) {
      case 'Source Offline':
        navigate(`/sources/${deviceId}/edit-source`);
        break;
      case 'Device Offline':
        navigate(`/properties/${propertyId}/units/${unitId}`);
        break;
    }
  };

  /**
   * formatRows
   * remap query data to match the 'AlertRow' format
   * @returns array of alert table rows - AlertRow[]
   * @param data - query data
   * @param _fields - array of field definition objects
   * @param _filter - used to pare down the list if specific devices were passed in using the route params state object
   */
  function formatRows(
    data: AlertsListData,
    _fields: typeof fields,
    _filter?: string[],
  ): AlertRow[] {
    const _data = data
      .filter(
        (dataRow) =>
          !_filter ||
          !_filter.length ||
          _filter?.includes(dataRow.device?.id ?? ''),
      )
      .map((dataRow) => {
        return _fields.reduce((acc, field) => {
          switch (field.name) {
            case 'id':
              acc.id = dataRow._id;
              break;
            case 'propertyName':
              acc.propertyName = dataRow.property?.name ?? '';
              break;
            case 'unitName':
              acc.unitName = dataRow.unit?.name ?? '';
              break;
            case 'deviceName':
              acc.deviceName = dataRow.device?.name ?? '';
              break;
            case 'deviceType':
              acc.deviceType = dataRow.device?.type ?? '';
              break;
            case 'severity':
              acc.severity = dataRow.severity ?? '';
              break;
            case 'timestamp':
              acc.timestamp = dataRow.initiated ?? -1;
              break;
            case 'type':
              acc.type = dataRow.type?.name ?? '';
              break;
            case 'deviceId':
              acc.deviceId = dataRow.device?.id ?? '';
              break;
            case 'propertyId':
              acc.propertyId = dataRow.property?.id ?? '';
              break;
            case 'unitId':
              acc.unitId = dataRow.unit?.id ?? '';
              break;
          }
          return acc;
        }, {} as AlertRow);
      });
    return [..._data];
  }

  /**
   * getHiddenColumns
   * Called from useMaterialReactTable.  Sets the visibility of columns based on screen size.  Columns are not permanently hidden,
   * but can instead be displayed by switching them to visible using the column menu tool.
   * @param _fields - list of all fields
   * @param isSmall - screen size for most mobile devices
   * @returns VisibilityState object
   */
  function getHiddenColumns(_fields: typeof fields, isSmall: boolean) {
    return _fields.reduce((acc, field) => {
      if (isSmall && field.show === ColumnVisibility.NOT_ON_SMALL) {
        acc[field.name] = false;
      }
      return acc;
    }, {} as MRT_VisibilityState);
  }

  /**
   * formatColumns
   * instead of removing hidden columns, set them as hidden so they can still be selected to be shown
   * also handle special case visibility, e.g. not showing the property name if the list is already filtered by property
   * @param _fields - the array of field definition objects
   * @param isSmall - whether this is a small display
   * @param isMedium - or if the display is medium sized (or larger)
   * @returns array of column definitions - MRT_ColumnDef<AlertRow>[]
   */
  function formatColumns(
    _fields: typeof fields,
    isSmall: boolean,
    isMedium: boolean,
  ) {
    return _fields.reduce((acc, field) => {
      /* return the accumulator object unchanged in order to hide certain fields */
      if (field.show === ColumnVisibility.NEVER) return acc;
      if (field.show === ColumnVisibility.SOMETIMES) {
        /* handle special-case visibility here */
        if (field.name === 'propertyName' && pathname.includes('properties'))
          return acc;
      }
      const column: MRT_ColumnDef<AlertRow> = {
        accessorKey: field.name,
        header: isSmall
          ? field.smallTitle
          : isMedium
            ? field.mediumTitle
            : field.title,
      };

      if (field.name === 'timestamp') {
        column.accessorFn = (row: AlertRow) => row.timestamp;
        column.Cell = ({ cell }) => {
          const date = cell.getValue<Date>();
          return isSmall || isMedium
            ? dayjs(date).format('l ')
            : dayjs(date).format('l LT');
        };
      }
      return acc.concat(column);
    }, [] as MRT_ColumnDef<AlertRow>[]);
  }

  // // Set display break point level on resize
  React.useEffect(() => {
    const handleResize = () => {
      if (isSmall) {
        setScreenBreakLevel(7);
      } else if (window.innerWidth < 670) {
        setScreenBreakLevel(6);
      } else if (window.innerWidth < 780) {
        setScreenBreakLevel(5);
      } else if (window.innerWidth < 870) {
        setScreenBreakLevel(4);
      } else if (window.innerWidth < 1000) {
        setScreenBreakLevel(3);
      } else if (window.innerWidth < 1170) {
        setScreenBreakLevel(2);
      } else if (window.innerWidth < 1220) {
        setScreenBreakLevel(1);
      } else {
        setScreenBreakLevel(0);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSmall]);

  /* --- Data Grid Utility Functions --- */
  const table = useMaterialReactTable({
    layoutMode: 'grid',
    defaultColumn: {
      minSize: 0,
      size: 50,
      enableColumnActions: false,
      sortingFn: 'alphanumeric',
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    columns: formatColumns(fields, isSmall, isMedium),
    data: formatRows(alerts, fields, specificDevices) ?? [],
    enableColumnFilterModes: false,
    enableColumnOrdering: false,
    enableFullScreenToggle: false,
    enableStickyHeader: true,
    enableDensityToggle: false,
    initialState: {
      density: 'spacious',
      showColumnFilters: false,
      columnOrder: [],
      columnVisibility: getHiddenColumns(fields, isSmall),
    },
    renderBottomToolbar: false,
    enablePagination: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiTableBodyProps: {
      sx: {
        px: 1,
        '& tr:nth-of-type(even)': {
          backgroundColor: 'rgba(241, 241, 241, 0.3) !important',
        },
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (_event) => {
        handleRowClick(row.original);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    muiTableHeadRowProps: {
      sx: {
        px: 1,
        height: screenBreakLevel > 4 ? '42px' : undefined,
        '& .Mui-TableHeadCell-Content-Labels': {
          paddingLeft: '0px',
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        '& .MuiTableHeadCell-Content': {
          minWidth: '100px',
        },
        '& .MuiTableHeadCell-Content-Labels': {
          paddingLeft: '0px',
        },
      },
      style: {
        padding: '2px 0px',
      },
    },
    muiTableBodyCellProps: {
      style: {
        padding: '0px',
        height: '42px',
      },
    },
    muiTableContainerProps: {
      style: {
        height: `${2 + ((alerts?.length ?? 0) + 1) * 42}px`,
      },
    },
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
  });

  return (
    <Box
      width={'100%'}
      sx={{
        height: 'auto',
        m: { sm: 0, md: 2 },
      }}
    >
      <MaterialReactTable table={table} />
    </Box>
  );
}
