import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  useMediaQuery,
  Zoom,
  Paper,
  Box,
  Card,
  CardHeader,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BorderedSection from '../../../../shared/borderedSection';
import { UnitSchedule } from '../../../../units/schedule/unit-schedule';
import { EmptyWeeklySchedule, MyThermostat } from '../../types';
import { ThermostatScheduleView } from '../schedule/thermostat-schedule-view';
import { ThermostatSchedule } from '../../../../../../types/generated-types';
import { applySetpointLimitToWeeklyScheduleValues } from '../../../util/apply-setpoint-limit-to-schedule-values';

type MySetpointLimits = {
  maxCoolSetpointLimit?: number | undefined;
  minCoolSetpointLimit?: number | undefined;
  maxHeatSetpointLimit?: number | undefined;
  minHeatSetpointLimit?: number | undefined;
};

interface ThermostatScheduleUpdateDialogProps {
  open: boolean;
  thermostat: MyThermostat;
  setpointLimits: MySetpointLimits;
  handleSetViewModeToNormal: () => void;
  handleCloseThermostatScheduleUpdateDialog: () => void;
  handleUpdateThermostat: (
    adjustScheduleToConformToSetpointLimits: boolean,
  ) => void;
}

export const ThermostatScheduleUpdateDialog = (
  props: ThermostatScheduleUpdateDialogProps,
) => {
  const {
    open,
    thermostat,
    setpointLimits,
    handleCloseThermostatScheduleUpdateDialog,
    handleUpdateThermostat,
    handleSetViewModeToNormal,
  } = props;

  const [autoAdjustScheduleValues, setAutoAdjustScheduleValues] =
    useState(false);
  const [adjustedScheduleValues, setAdjustedScheduleValues] =
    useState<ThermostatSchedule['value']>(EmptyWeeklySchedule);

  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (autoAdjustScheduleValues) {
      const limits = {
        cool: {
          min: setpointLimits.minCoolSetpointLimit,
          max: setpointLimits.maxCoolSetpointLimit,
        },
        heat: {
          min: setpointLimits.minHeatSetpointLimit,
          max: setpointLimits.maxHeatSetpointLimit,
        },
      };
      if (thermostat.schedule) {
        const newScheduleValues = applySetpointLimitToWeeklyScheduleValues(
          thermostat.schedule.value,
          limits,
        );
        setAdjustedScheduleValues(newScheduleValues);
      }
    } else {
      setAdjustedScheduleValues(EmptyWeeklySchedule);
    }
  }, [autoAdjustScheduleValues]);

  return (
    <Dialog
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      <>
        <Typography variant="h4" sx={{ padding: '15px' }}>
          Changing the Setpoint Limits may conflict with the current Schedule
          applied to the Thermostat
        </Typography>
        <DialogContent sx={{ padding: '10px 10px' }}>
          <BorderedSection title="Information">
            <Typography variant="h4" sx={{ padding: '15px' }}>
              "Auto Adjust Schedule" checked: Custom schedules will be
              automatically adjusted to within the setpoint limits and any
              schedule templates applied will be updated to a custom schedule
            </Typography>
            <Typography variant="h4" sx={{ padding: '15px' }}>
              "Auto Adjust Schedule" unchecked: Will clear any schedule applied
              to the thermostat
            </Typography>
          </BorderedSection>
          <br />
          <BorderedSection title="Current Schedule">
            <UnitSchedule />
          </BorderedSection>
          <br />
          <BorderedSection title="Adjusted Schedule">
            <div
              style={{
                background: 'inherit',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                alignItems: 'stretch',
                margin: isSmall ? '0px' : '20px 0px',
              }}
            >
              {thermostat?.scheduleType &&
              thermostat?.name &&
              thermostat.schedule ? (
                <ThermostatScheduleView
                  scheduleType={thermostat.scheduleType}
                  thermostatName={thermostat.name}
                  scheduleValue={adjustedScheduleValues}
                  showPlaceholder={!autoAdjustScheduleValues}
                />
              ) : (
                <div>
                  A problem occured while loading the adjusted schedule, please
                  refresh the page
                </div>
              )}
            </div>
          </BorderedSection>
        </DialogContent>
        <DialogActions>
          <Grid container rowSpacing={1} direction={'row'}>
            <Grid item xs={12}>
              <FormControlLabel
                label="Auto Adjust Schedule"
                sx={{ paddingLeft: '16px' }}
                control={
                  <Checkbox
                    color="secondary"
                    onChange={(e) => {
                      setAutoAdjustScheduleValues(!autoAdjustScheduleValues);
                    }}
                    checked={autoAdjustScheduleValues}
                  />
                }
              />
            </Grid>
            {!autoAdjustScheduleValues ? (
              <Typography variant="h5" sx={{ padding: '15px' }}>
                Note: The thermostat's schedule will be cleared
              </Typography>
            ) : (
              <Typography variant="h5" sx={{ padding: '15px' }}>
                Note: The thermostat's schedule will be automatically adjusted
              </Typography>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                color="warning"
                sx={{ color: '#fff' }}
                onClick={() => {
                  handleUpdateThermostat(autoAdjustScheduleValues);
                  setAutoAdjustScheduleValues(false);
                  handleCloseThermostatScheduleUpdateDialog();
                }}
              >
                Update Thermostat
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                sx={{ color: theme.embue.bad }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSetViewModeToNormal();
                  setAutoAdjustScheduleValues(false);
                  handleCloseThermostatScheduleUpdateDialog();
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    </Dialog>
  );
};
