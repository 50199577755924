import React, { useEffect } from 'react';
import {
  isAndroid,
  isChrome,
  isEdge,
  isFirefox,
  isIE,
  isIOS,
  isMacOs,
  isMobile,
  isOpera,
  isSafari,
  isWindows,
} from 'react-device-detect';

import {
  BiometricAuthError,
  NativeBiometric,
} from '@capgo/capacitor-native-biometric';
import {
  getCredentialBoundTo,
  getCredentialOS,
  useAuthenticator,
} from '../../../auth/AuthenticationContext';
import {
  browserSupportsWebAuthn,
  startRegistration,
} from '@simplewebauthn/browser';
import { useFormik } from 'formik';
import { FormValues } from '../types/userProfileFormValues';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControlLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from '@mui/material';
import { PasskeyFactor, PasskeyList } from '../passkey-list';
import { styled, useTheme } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {
  AuthenticationProfileFactors,
  AuthenticatorDevice,
  EnumAuthenticationProfileFactorsType,
  EnumAuthenticatorDeviceCredentialOs,
  useAddPasskeyMutation,
  useRemovePasskeyMutation,
  useUpdatePasskeyMutation,
  useUserProfilePasskeysChangesSubscription,
  useUserProfilePasskeysQuery,
} from '../../../../types/generated-types';
import { EmbueError } from '../../../system/models/embueError';
import TextField from '@mui/material/TextField';
import BorderedSection from '../../shared/borderedSection';
import { Notifier } from '../../../system/services/notificationManager';
import Checkbox from '@mui/material/Checkbox';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../../system/services/injectableComponentsManager';
import { Add } from '@mui/icons-material';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
// import { PublicKeyCredentialCreationOptionsJSON } from '@simplewebauthn/typescript-types';
import { WebAuthn } from '@embue/capacitor-native-webauthn';
import { LocalState } from '../../../system/services/localStateManager';

interface EditablePasskey {
  _id: string;
  name: string;
  preferred: boolean;
  credentialIsExternal: boolean;
  enforceUserVerification: boolean;
}

interface ConfirmPasskeyCreationDialogProps {
  open: boolean;
  enforceUserVerification: boolean;
  useExternalAuthenticator: boolean;
  handleClose: () => void;
  createPasskey: () => void;
  skipVerification: () => void;
}

const DisplayPasskeyDetailDialog = ({
  open,
  handleSave,
  handleUpdate,
  handleClose,
  handleCancel,
  handleRemove,
  passkey,
  onChange,
  formik,
}: {
  open: boolean;
  handleSave: () => void;
  handleUpdate: () => void;
  handleClose: () => void;
  handleCancel: () => void;
  handleRemove: () => void;
  passkey: EditablePasskey | undefined;
  onChange: StandardInputProps['onChange'];
  formik: ReturnType<typeof useFormik<FormValues>>;
}) =>
  open && passkey ? (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div
        id="alert-dialog-title"
        style={{ marginLeft: '22px', marginTop: '16px' }}
      >
        <span
          style={{
            fontSize: '18px',
            fontWeight: 'bolder',
          }}
        >
          {passkey?._id ? 'Editing Passkey' : 'Create new Passkey'}
        </span>
      </div>
      <DialogContent sx={{ marginTop: '-15px' }}>
        <div>
          <div
            style={{
              borderTop: '1px solid gray',
              paddingTop: '16px',
              marginTop: '6px',
              marginRight: '2px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <BorderedSection
              title={
                passkey._id === '-1' ? 'Create New Passkey' : 'Edit Passkey'
              }
              ccStyle={{ padding: '6px' }}
              style={{
                margin: '2px',
              }}
            >
              <div style={{ paddingTop: '8px' }}>
                <TextField
                  fullWidth
                  autoFocus
                  id="passkeyName"
                  name="passkeyName"
                  placeholder="e.g. Tom's MacBook Pro Touch ID"
                  label="Passkey Name"
                  value={formik.values.passkeyName}
                  onChange={onChange}
                  error={
                    formik.touched.passkeyName &&
                    Boolean(formik.errors.passkeyName)
                  }
                  helperText={
                    formik.touched.passkeyName && formik.errors.passkeyName
                  }
                />
                <br />
                <br />
                <BorderedSection title="Passkey Type">
                  <RadioGroup
                    style={{ marginBottom: '-16px' }}
                    aria-label="passkey type"
                    name="passkeyType"
                    value={formik.values.selectedAttachment}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue(
                        'selectedAttachment',
                        event.target.value,
                      );
                    }}
                  >
                    {(isAndroid
                      ? [
                          {
                            name: 'Built-in (Face/Finger/Iris/etc)',
                            _id: 'platform',
                          },
                        ]
                      : [
                          {
                            name: 'Built-in (Face/Finger/Iris/etc)',
                            _id: 'platform',
                          },
                          {
                            name: 'External Device (USB/YubiKey)',
                            _id: 'cross-platform',
                          },
                        ]
                    ).map((passkeyType, index) => (
                      <FormControlLabel
                        style={{ marginBottom: '16px' }}
                        key={`passkey-type-rb-${index}`}
                        value={passkeyType._id}
                        control={<Radio />}
                        label={passkeyType.name}
                      />
                    ))}
                  </RadioGroup>
                </BorderedSection>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isAndroid}
                      checked={formik.values.enforceUserVerification}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        formik.setFieldValue(
                          'enforceUserVerification',
                          event.target.checked,
                        );
                      }}
                    />
                  }
                  label="Require User Identification"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.preferredPasskey}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        formik.setFieldValue(
                          'preferredPasskey',
                          event.target.checked,
                        );
                      }}
                    />
                  }
                  label="Preferred?"
                />
              </div>
            </BorderedSection>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              //justifyContent: 'space-evenly',
              marginTop: '16px',
              width: '100%',
            }}
          >
            {passkey._id ? (
              <button
                // disabled={operationInProgress}
                onClick={async () => {
                  handleRemove();
                }}
              >
                Remove Passkey
              </button>
            ) : null}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              &nbsp;
            </div>
            <button
              // disabled={operationInProgress}
              onClick={async () => {
                handleCancel();
              }}
            >
              Cancel
            </button>
            <button
              style={{
                marginLeft: '6px',
              }}
              // disabled={operationInProgress}
              onClick={async () => {
                formik.values.selectedPasskeyId ? handleUpdate() : handleSave();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  ) : null;

const ConfirmPasskeyCreationDialog = (
  props: ConfirmPasskeyCreationDialogProps,
) => {
  const [showEnforceUserVerification, setShowEnforceUserVerification] =
    React.useState(props.enforceUserVerification);
  const { open, useExternalAuthenticator, handleClose, createPasskey } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div id="alert-dialog-title" style={{ marginLeft: '10px' }}>
        <h2>{'Ready to create Passkey'}</h2>
      </div>
      <DialogContent sx={{ marginTop: '-15px' }}>
        <div id="alert-dialog-description">
          {showEnforceUserVerification ? (
            <>
              <div>
                You have chosen to enforce user verification for your new
                Passkey. This is definitely recommended.
              </div>
              <div style={{ marginTop: '12px' }}>
                In order to verify your identify, you must previously have
                registered a fingerprint, face or iris scan with or set up a
                passcode or password for your{' '}
                {useExternalAuthenticator
                  ? 'external security device'
                  : 'computer or mobile device'}
                .
              </div>
              <div style={{ marginTop: '12px' }}>
                If you have already done this, click the 'Proceed' button below.
                If not, click the 'Skip' button below to proceed without
                requiring user verification (not recommended) or click 'Cancel'
                to cancel creation of your Passkey.
              </div>
              <br />
            </>
          ) : useExternalAuthenticator ? (
            <>
              <div>
                Please ensure your external authenticator device is connected
                or, if using NFC, ready-to-hand.
              </div>
              <div style={{ marginTop: '12px' }}>
                You will be prompted to activate your authenticator, usually by
                touching it or, for NFC devices, placing it near the top of and
                flat against your mobile device.
              </div>
              <div style={{ marginTop: '12px' }}>
                Note that you may be asked to enter your authenticator's
                passcode if one is set.
              </div>
              <div style={{ marginTop: '12px' }}>
                Click 'Register Passkey' to proceed or 'Cancel' to abort.
              </div>
              <br />
            </>
          ) : (
            <>
              <div>
                Please prepare to verify your identity using your device's
                biometric sensor or passcode.
              </div>
              <br />
              <div>
                Click 'Register Passkey' to proceed or 'Cancel' to abort.
              </div>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {showEnforceUserVerification ? (
          <>
            <Button
              variant="contained"
              onClick={() => {
                props.skipVerification();
                setTimeout(() => {
                  setShowEnforceUserVerification(false);
                });
              }}
            >
              Skip
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setShowEnforceUserVerification(false);
              }}
              autoFocus
            >
              Proceed
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              onClick={() => {
                createPasskey();
                handleClose();
              }}
              autoFocus
            >
              Register Passkey
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

interface RemovePasskeyDialogProps {
  open: boolean;
  handleClose: () => void;
  removePasskey: () => void;
}

const RemovePasskeyDialog = (props: RemovePasskeyDialogProps) => {
  const { open, handleClose, removePasskey } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="remove-passkey-dialog-title"
      aria-describedby="remove-passkey-dialog-description"
    >
      <DialogTitle id="remove-passkey-dialog-title">
        {'Remove Source'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="remove-passkey-dialog-description">
          Are you sure? This action cannot be reversed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            removePasskey();
            handleClose();
          }}
          autoFocus
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// noinspection RequiredAttributes
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const FactorPasskeys = (props: {
  userId: string;
  formik: ReturnType<typeof useFormik<FormValues>>;
  onChange: StandardInputProps['onChange'];
}) => {
  const {
    data: passkeysData,
    // TODO: Loading_error: handle loading/error for user profile passkeys query.
    // loading: passkeysLoading,
    // error: passkeysError,
  } = useUserProfilePasskeysQuery({
    variables: { userId: props.userId },
  });
  useUserProfilePasskeysChangesSubscription({
    variables: { userId: props.userId },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });
  const { formik, onChange } = props;
  const [showInfo, setShowInfo] = React.useState(false);
  const [passkeys, setPasskeys] = React.useState<PasskeyFactor[]>([]);
  const [open, setOpen] = React.useState(false);
  const [openRemove, setOpenRemove] = React.useState(false);

  const [deletePasskey] = useRemovePasskeyMutation();
  const [updatePasskey] = useUpdatePasskeyMutation();

  const [selectedPasskey, setSelectedPasskey] =
    React.useState<EditablePasskey>();
  const { setContextMenuItems } = useInjectableComponents();

  const [isWebAuthnAvailable, setWebAuthnIsAvailable] = React.useState(false);
  const [passcodeConfigured, setPasscodeConfigured] = React.useState(false);
  const [isPlatformAvailable, setIsPlatformAvailable] = React.useState(false);
  const [isAutofillAvailable, setIsAutofillAvailable] = React.useState(false);

  const [operationInProgress, setOperationInProgress] = React.useState(false);

  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const auth = useAuthenticator();

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseRemove = () => {
    setOpenRemove(false);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (browserSupportsWebAuthn()) {
      setWebAuthnIsAvailable(true);
    } else {
      setWebAuthnIsAvailable(false);
    }

    if (isMobile) {
      if (isIOS) {
      } else if (isAndroid) {
      } else {
      }
    } else if (isMacOs) {
      if (isChrome) {
      } else if (isSafari) {
      } else if (isEdge) {
      } else if (isIE) {
      } else if (isFirefox) {
      } else if (isOpera) {
      } else {
      }
    } else if (isWindows) {
      if (isChrome) {
      } else if (isSafari) {
      } else if (isEdge) {
      }
    }

    if (isMobile || isAndroid) {
      NativeBiometric.isAvailable({ useFallback: true })
        .then(({ errorCode, isAvailable, biometryType }) => {
          if (isAvailable) {
          } else {
            switch (errorCode) {
              case BiometricAuthError.BIOMETRICS_NOT_ENROLLED:
                NativeBiometric.isAvailable({ useFallback: true }).then(
                  ({ errorCode, isAvailable, biometryType }) => {
                    if (isAvailable) {
                      setPasscodeConfigured(true);
                    }
                    if (errorCode === BiometricAuthError.PASSCODE_NOT_SET) {
                    } else {
                    }
                  },
                );
            }
          }
          //
          // if(errorCode === BiometricAuthError.BIOMETRICS_NOT_ENROLLED
        })
        .catch((_error) => {
          // console.log('got error', error);
        });
    } else {
      if (window['PublicKeyCredential']) {
        PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then(
          (res) => {
            setIsPlatformAvailable(res);
          },
        );
      } else {
        setIsPlatformAvailable(false);
      }
    }
  }, [browserSupportsWebAuthn]);

  const [createPasskeyMutation] = useAddPasskeyMutation({
    refetchQueries: ['UserProfilePasskeys'],
  });

  const updateSelectedPasskey = React.useCallback(async () => {
    if (selectedPasskey?._id) {
      try {
        await updatePasskey({
          variables: {
            userId: props.userId,
            factorId: selectedPasskey._id,
            name: formik.values.passkeyName,
            enforceUserVerification: formik.values.enforceUserVerification,
            preferred: formik.values.preferredPasskey,
          },
          refetchQueries: ['UserProfilePasskeys'],
        });
        setSelectedPasskey(undefined);
        Notifier.success('Passkey updated');
        formik.resetForm();
        handleClose();
      } catch (e) {
        console.log(
          `[Update passkey failed] ${(e as unknown as Error).message}`,
        );
        Notifier.error('Unable to update passkey. Please try again.');
      }
    }
  }, [formik, updatePasskey, props, selectedPasskey]);

  const createPasskey = React.useCallback(
    async (_test = false) => {
      const supported = browserSupportsWebAuthn(); // browserSupportsWebAuthnAutofill();
      let polyfilled = false;
      try {
        polyfilled = (await WebAuthn.isWebAuthnAvailable()).value;
        polyfilled =
          polyfilled ||
          getCredentialOS() === EnumAuthenticatorDeviceCredentialOs.Android; // WebAuthn.isWebAuthnAutoFillAvailable();
      } catch (e) {
        // This is an intentional no-op.
      }
      if (supported || polyfilled) {
        setIsAutofillAvailable(true);
        try {
          const creationOptions = await auth.getPasskeyCreationOptions(
            formik.values.selectedAttachment !== 'platform',
            formik.values.enforceUserVerification,
          );

          if (creationOptions) {
            let registrationParams;

            if (supported) {
              try {
                registrationParams = await startRegistration(creationOptions);
              } catch (registrationError) {
                const errorAny: any = registrationError as unknown as any;
                if (errorAny.name === 'NotAllowedError') {
                  // User cancelled the registration. Ensure registrationParams are cleared and just continue.
                  registrationParams = undefined;
                } else {
                  Notifier.error(
                    `Unable to create passkey. Please try again or switch to another device: ${errorAny.code}: ${errorAny.name}: ${errorAny.message}`,
                    registrationError,
                  );
                }
              }
            } else {
              try {
                registrationParams =
                  await WebAuthn.startRegistration(creationOptions);
              } catch (registrationError) {
                Notifier.error(
                  'Unable to create passkey. Please try again or switch to another device.',
                  registrationError,
                );
              }
            }

            if (registrationParams) {
              try {
                await createPasskeyMutation({
                  variables: {
                    userId: auth.user?._id ?? 'hello',
                    name: formik.values.passkeyName,
                    preferred: formik.values.preferredPasskey,
                    data: JSON.stringify(registrationParams),
                    credentialIsExternal:
                      formik.values.selectedAttachment !== 'platform',
                    enforceUserVerification:
                      formik.values.enforceUserVerification,
                    credentialOS: getCredentialOS(),
                    credentialBoundTo: getCredentialBoundTo(),
                  },
                  refetchQueries: ['UserProfilePasskeys'],
                });

                setSelectedPasskey(undefined);
                Notifier.success('Registration successful');
                formik.resetForm();
                handleClose();
              } catch (factorSaveError) {
                Notifier.error(
                  `Registration failed: ${
                    (factorSaveError as unknown as Error).message
                  }`,
                );
              }
            }
          } else {
            throw new EmbueError('Unable to create passkey');
          }
        } catch (e) {
          if (e instanceof EmbueError) {
            Notifier.error(e.message);
            console.log(`[Create Passkey] Received Error: ${e.message}`);
          } else if (e instanceof Error) {
            Notifier.error(e.message);
            console.log(`[Create Passkey] Received Error: ${e.message}`);
          } else {
            Notifier.error(`${e}`);
            console.log(`[Create Passkey] Received Error: ${e}`);
          }
        }
      } else {
        setIsAutofillAvailable(false);
      }
    },
    [formik],
  );

  const handleRemovePasskey = async () => {
    if (!selectedPasskey) {
      Notifier.error('No passkey selected');
      return;
    }
    if (!auth.user?._id) {
      Notifier.error('You must be logged in to remove a passkey');
      return;
    }
    deletePasskey({
      variables: {
        userId: auth.user._id,
        factorId: selectedPasskey._id,
      },
      refetchQueries: ['UserProfilePasskeys'],
    })
      .then((res) => {
        if (res.data?.removePasskey) {
          Notifier.success('Passkey deleted');
          setSelectedPasskey(undefined);
          formik.resetForm();
          handleCloseRemove();
        } else {
          Notifier.error('Error deleting passkey');
        }
      })
      .catch((_err) => {
        Notifier.error('Error deleting passkey');
      });
  };

  useEffect(() => {
    if (passkeysData) {
      const validPasskeys: PasskeyFactor[] = [];
      const passkeyFactors = (
        passkeysData.userById?.editableAuthenticationProfile?.factors ?? []
      ).filter(
        (factor) =>
          factor &&
          factor.type &&
          factor.type === EnumAuthenticationProfileFactorsType.Passkey,
      ) as Partial<AuthenticationProfileFactors>[];
      const authenticatorDevices = (passkeysData.userById
        ?.editableAuthenticationProfile?.authenticatorDevices ??
        []) as Partial<AuthenticatorDevice>[];
      passkeyFactors.map((factor) => {
        const device = authenticatorDevices.find(
          (device) => device._id === factor.value,
        );
        if (factor && device) {
          validPasskeys.push({
            ...factor,
            createdAt: device.createdAt,
            lastUsed: device.lastUsed,
            userVerified: device.userVerified,
            credentialDeviceType: device.credentialDeviceType,
            credentialBackedUp: device.credentialBackedUp,
            transports: device.transports,
            credentialIsExternal: device.credentialIsExternal,
            enforceUserVerification: device.enforceUserVerification,
            // credentialBoundTo: device.credentialBoundTo,
            credentialEcosystem: device.credentialEcosystem,
            credentialOS: device.credentialOS,
          });
        }
      });
      setPasskeys(validPasskeys);
    }
  }, [passkeysData]);

  useEffect(() => {
    const bottomMenu: BottomMenuItems = [
      {
        items: [
          {
            id: 'menu-item-add-passkey',
            label: 'Add Passkey',
            icon: <Add fontSize="small" />,
            action: () => {
              const newPasskey: EditablePasskey = {
                _id: '',
                name: '',
                preferred: true,
                credentialIsExternal: false,
                enforceUserVerification: true,
              };
              setSelectedPasskey(newPasskey);
            },
          },
        ],
      },
    ];
    setContextMenuItems(bottomMenu);
    return () => {
      setContextMenuItems(undefined);
    };
  }, [setSelectedPasskey, setContextMenuItems]);

  return (
    <div
      style={{
        margin: isSmall ? '-10px 0px 0px 0px' : '-10px 8px 0px 8px',
        border: '1px solid gray',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
      }}
    >
      {showInfo ? (
        <Fade in={true} style={{ transitionDuration: '1100ms' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: '1 1 auto',
              width: '100%',
              padding: '10px',
            }}
          >
            <div
              style={{
                flex: '1 1 auto',
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <h4 style={{ margin: '0px 0px 10px 0px' }}>
                Passkey Help
                <Button
                  onClick={async (_e) => {
                    setShowInfo(false);
                  }}
                  variant="contained"
                  color="secondary"
                  sx={{ color: '#fff', fontSize: '12px', marginLeft: '10px' }}
                >
                  Return to managing Passkeys
                </Button>
              </h4>
              <div>
                <Accordion
                  expanded={expanded === 'panel1'}
                  onChange={handleChange('panel1')}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography>What are Passkeys?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Passkeys are the state-of-the-art, convenient,
                      cyber-secure replacement for passwords. Passkeys eliminate
                      the need to think up or remember passwords. Instead they
                      use highly secure, industry standard encryption technology
                      in combination with biometric sensors built in to your
                      computer or mobile device to securely identify log you in
                      to your Embue Account using a fingerprint or Facial / Iris
                      scan that you previously registered with your computer or
                      mobile device.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel2'}
                  onChange={handleChange('panel2')}
                >
                  <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <Typography>
                      Does Embue have access to my fingerprint or other
                      biometric information?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Absolutely not. Passkeys relay on your computer or mobile
                      device's biometric sensor to verify your identity. That
                      hardware then generates a unique isolated identifier which
                      it shares with Embue and which we then associate with your
                      Embue account. At no time does Embue ever have access to
                      any biometric or other sensitive data from your computer
                      or device. That all stays safely locked away within your
                      device or operating system account to which Embue has no
                      access.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel3'}
                  onChange={handleChange('panel3')}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <Typography>
                      What if I want to use passkeys but don't have or want to
                      use a built-in sensor?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Passkeys can also be created without having (or choosing
                      not to use) a biometric sensor in your computer or mobile
                      device. This is done by pairing the passkey with an
                      external 'security key' device in place of a built-in
                      biometric sensor in your computer or mobile device. While
                      you have to remember to carry the security key around with
                      you, these portable security keys allow their paired
                      passkey to be used to log you in on virtually any client
                      computer or mobile device whether those clients have a
                      biometric sensor or not and without having to create a
                      separate passcode for each client. To prevent the
                      unauthorized use of a security key, most can be configured
                      to require the entry of a numeric PIN before each use and
                      some even provide a built-in fingerprint sensor for
                      enhanced, multi-factor protection. This is an excellent
                      option if your computer or mobile device does not have a
                      biometric sensor.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel4'}
                  onChange={handleChange('panel4')}
                >
                  <AccordionSummary
                    aria-controls="panel4d-content"
                    id="panel4d-header"
                  >
                    <Typography>
                      Are there disadvantages to using an external security key
                      with passkeys?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Passkeys can either be 'device-bound' or 'sharable'.
                      Device-bound Passkeys can only be used by activating the
                      specific sensor or security device that was used to create
                      it. All Passkeys created using an external security key
                      device fall into this category and, as a result, if you
                      lose that device, you will have to create a new passkeys
                      when you replace the lost device or you can create new a
                      passkey using a built-in sensor on one of your computers
                      or mobile devices.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel5'}
                  onChange={handleChange('panel5')}
                >
                  <AccordionSummary
                    aria-controls="panel5d-content"
                    id="panel5d-header"
                  >
                    <Typography>
                      What are some of the advantages to using a built-in sensor
                      with passkeys?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Using built-in sensors often will allow your computer or
                      device to automatically back up your passkeys to your
                      associated operating system account. This makes them
                      'sharable' with any other computer or device that has
                      access to your operating system account. This means that,
                      for example, if you create a passkey on your iPhone, it
                      will be backed up to your Apple KeyChain. This has a
                      number of advantages. If you lose your iPhone, for
                      example, your Embue passkey will be automatically restored
                      to your replacement iPhone as soon as you get it. A
                      similar mechanism provides that same benefit when using an
                      Android mobile device. Another benefit is that if you use
                      multiple devices from the same 'family' (Apple/Mac vs
                      Google/Android), Passcodes created on one device will be
                      available automatically on any other device associated
                      with your Apple or Google platform account. This holds
                      true for any Passkeys you create, not just for the
                      Embue-related ones. All of this convenience comes without
                      sacrificing any of the enhanced security that comes
                      built-in to Passkeys.
                    </Typography>
                    <Typography>
                      The extent to which your Passkeys can be 'shared', backed
                      up or restored depends on your computer or mobile device's
                      operating system, and/or the browser that you are using
                      for web-based access when you create or, later, use your
                      Passkey. For the most up-to-date information, please refer
                      to the information provided at the following websites:
                      <a href="https://passkeys.dev/device-support">
                        Passkey Device Support
                      </a>{' '}
                      or{' '}
                      <a href="https://passkeys.dev/device-support">
                        Can I Use
                      </a>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel6'}
                  onChange={handleChange('panel6')}
                >
                  <AccordionSummary
                    aria-controls="panel6d-content"
                    id="panel6d-header"
                  >
                    <Typography>Can I have more than one Passkey?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      You can create as many Passkeys for your Embue account as
                      you would like. Users often will create one Passkey using
                      their mobile device and one using their laptop. They may
                      create one using their iPhone and another using their
                      Android-based tablet or mobile phone. They may decide to
                      purchase an external security device as a backup and
                      create a Passkey associated with that device. Depending on
                      which mobile devices you use in combination with which
                      browsers on your computer, your operating systems may
                      automatically share one passkey across all of your devices
                      and computers if you stay within the same ecosystem (all
                      Apple or all Google/Android)
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel7'}
                  onChange={handleChange('panel7')}
                >
                  <AccordionSummary
                    aria-controls="panel7d-content"
                    id="panel7d-header"
                  >
                    <Typography>
                      Do Passkeys work on Windows? Macs? Ubuntu/Linux?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Device-bound Passkeys have broad support across most
                      modern operating systems and browsers. You can check the
                      latest data for your specific configuration by visiting
                      the following link:
                      <a
                        href="https://www.passkeys.com/docs/windows-mac-ubuntu-linux"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.passkeys.com/docs/windows-mac-ubuntu-linux
                      </a>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'panel8'}
                  onChange={handleChange('panel8')}
                >
                  <AccordionSummary
                    aria-controls="panel8d-content"
                    id="panel8d-header"
                  >
                    <Typography>How do I create a Passkey?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <span>
                        To get started using passkeys, click the 'Return to
                        manage passkeys' button below. Then click the 'Create
                        Passkey' button.
                      </span>
                      <br />
                      <br />
                      <span>
                        If your platform supports both built-in and external
                        security key devices, you will be asked to choose
                        whether you would like to use a built-in biometric
                        sensor on your computer or device, or if you would
                        prefer to use an external security key/device to pair
                        with your new passkey. Choose which option you want to
                        use for this new Passkey. Otherwise, if only one of
                        those options are available, it will be selected for
                        you.
                      </span>
                      <br />
                      <br />
                      <span>
                        You must also enter a name to associate with your new
                        Passkey. You should choose something meaningful that
                        will help you identify what you used to create the
                        Passkey. "John's YubiKey" or "Chrome browser on
                        MacBookPro" or "Peter's iPhone" are good choices. Enter
                        your choice for a name and click the 'Create' button.
                      </span>
                      <br />
                      <br />
                      <span>
                        If you chose to use a built-in sensor, your computer or
                        mobile device will then prompt you select which
                        biometric identification method you wish to pair with
                        your new Passkey. Choose to choose which identification
                        method you wish to associate with your new passkey. If
                        you have previously registered a fingerprint, face or
                        retina scan with your device, you will be prompted to
                        confirm your identity by that method. If not, you will
                        be prompted to use an external security key or device to
                        associate with your new passkey.
                      </span>
                      <br />
                      <br />
                      <span>
                        Once your passkey has been created, you will be able to
                        log in to Embue Super in one step simply by confirming
                        your identity with the same biometric method or security
                        device you used to create your passkey without the need
                        to remember or enter a password!
                      </span>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div
                style={{
                  marginTop: '12px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <Button
                  onClick={async (_e) => {
                    setShowInfo(false);
                  }}
                  variant="contained"
                  color="secondary"
                  sx={{ color: '#fff', fontSize: '12px' }}
                >
                  Return to managing Passkeys
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      ) : (
        <Fade
          in={true}
          style={{
            transitionDuration: '1100ms',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: '1 1 auto',
              margin: '8px 8px 4px 8px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto',
                width: '100%',
              }}
            >
              {passkeys.length > 0 ? (
                <PasskeyList
                  formik={formik}
                  data={passkeys}
                  onChange={onChange}
                  onSelect={(selectedId: string | undefined) => {
                    formik.setFieldValue('selectedPasskeyId', selectedId);
                    const foundPasskey = passkeys.find(
                      (passkey: PasskeyFactor) => {
                        return passkey._id === selectedId;
                      },
                    );
                    if (foundPasskey) {
                      setSelectedPasskey({
                        _id: foundPasskey._id ?? 'no id',
                        name: foundPasskey.name ?? 'no name',
                        preferred: foundPasskey.preferred ?? false,
                        credentialIsExternal:
                          foundPasskey.credentialIsExternal ?? false,
                        enforceUserVerification:
                          foundPasskey.enforceUserVerification === undefined ||
                          foundPasskey.enforceUserVerification === null
                            ? false
                            : foundPasskey.enforceUserVerification,
                      });
                      formik.setFieldValue(
                        'selectedPasskeyId',
                        foundPasskey._id,
                      );
                      formik.setFieldValue('passkeyName', foundPasskey.name);
                      formik.setFieldValue(
                        'enforceUserVerification',
                        foundPasskey.enforceUserVerification,
                      );
                      formik.setFieldValue(
                        'preferredPasskey',
                        foundPasskey.preferred,
                      );
                      formik.setFieldValue(
                        'selectedAttachment',
                        foundPasskey.credentialIsExternal
                          ? 'cross-platform'
                          : 'platform',
                      );
                    }
                  }}
                />
              ) : (
                <div style={{ width: '100%', textAlign: 'left' }}>
                  No passkeys found.
                  <br />
                  Click 'Add Passkey' in context menu
                </div>
              )}
              {formik.values.selectedPasskeyId?.length ? (
                <div
                  style={{
                    display: 'block',
                    paddingTop: '6px',
                    marginTop: '6px',
                    marginBottom: '10px',
                    marginRight: '2px',
                    textAlign: 'center',
                  }}
                >
                  &nbsp;
                </div>
              ) : passkeys.length ? (
                <div
                  style={{
                    display: 'block',
                    paddingTop: '6px',
                    marginTop: '6px',
                    marginBottom: '10px',
                    marginRight: '2px',
                    textAlign: isSmall ? 'center' : 'left',
                  }}
                >
                  Click a Passkey (above) to edit
                </div>
              ) : null}
              <div
                style={{
                  width: isSmall ? 'unset' : '350px',
                  display: isSmall ? 'flex' : 'unset',
                  flexDirection: isSmall ? 'row' : 'unset',
                  flex: isSmall ? '0 1 auto' : 'unset',
                  cursor: 'pointer',
                  backgroundColor: 'lightseagreen',
                  borderRadius: '4px',
                  padding: '4px 10px',
                  marginTop: '16px',
                  marginBottom: '6px',
                }}
                onClick={() => setShowInfo(true)}
              >
                <div
                  style={{
                    marginRight: '3px',
                    width: '100%',
                    textAlign: 'center',
                    color: '#FFFFFF',
                  }}
                >
                  New to Passkeys? Click here for details
                </div>
              </div>
            </div>
          </div>
        </Fade>
      )}
      <DisplayPasskeyDetailDialog
        open={!!selectedPasskey}
        handleSave={() => setOpen(true)}
        handleUpdate={updateSelectedPasskey}
        handleClose={() => {
          setSelectedPasskey(undefined);
          formik.resetForm();
        }}
        passkey={selectedPasskey}
        onChange={onChange}
        handleCancel={() => {
          setSelectedPasskey(undefined);
          formik.resetForm();
        }}
        handleRemove={() => {
          setOpenRemove(true);
        }}
        formik={formik}
      />
      <ConfirmPasskeyCreationDialog
        open={open}
        enforceUserVerification={formik.values.enforceUserVerification}
        useExternalAuthenticator={
          formik.values.selectedAttachment !== 'platform'
        }
        handleClose={() => {
          handleClose();
        }}
        createPasskey={() => createPasskey()}
        skipVerification={() => {
          formik.setFieldValue('enforceUserVerification', false);
        }}
      />
      <RemovePasskeyDialog
        open={openRemove}
        handleClose={() => handleCloseRemove()}
        removePasskey={() => handleRemovePasskey()}
      />
    </div>
  );
};
