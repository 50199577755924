import React, { useEffect } from 'react';
import {
  useEntitySubscribersQuery,
  useEntitySubscriberUpdatesSubscription,
  useResetEntitySubscribersMutation,
} from '../../../types/generated-types';
import BorderedSection from '../shared/borderedSection';
import { Notifier } from '../../system/services/notificationManager';

interface SubscriberCountMap {
  [id: string]: number;
}

interface SubscriptionCriterion {
  [key: string]: SubscriberCountMap;
}
export interface EntitySubscribers {
  [collectionName: string]: SubscriptionCriterion;
}

export function SubscriptionMonitor() {
  const [subscribers, setSubscribers] = React.useState<EntitySubscribers>();
  const [submitted, setSubmitted] = React.useState<boolean>(false);

  const [
    resetEntitySubscribers,
    // TODO: Loading_error: should we handle the loading/error state for this mutation?
    // { data: resetData, error: resetError, loading: resetLoading },
  ] = useResetEntitySubscribersMutation();

  const {
    data,
    // TODO: Loading_error: should we handle the loading/error state for this query?
    // error,
    // loading,
  } = useEntitySubscribersQuery({
    fetchPolicy: 'network-only',
  });

  useEntitySubscriberUpdatesSubscription({
    fetchPolicy: 'no-cache',
    onData: ({ data }) => {
      if (data?.data?.entitySubscribersUpdated) {
        setSubscribers(
          data.data.entitySubscribersUpdated as unknown as EntitySubscribers,
        );
      }
    },
  });

  useEffect(() => {
    if (data?.entitySubscribers) {
      setSubscribers(data.entitySubscribers as unknown as EntitySubscribers);
    }
  }, [data]);

  return (
    <div style={{ padding: '20px 10px', margin: '10px' }}>
      <BorderedSection
        title="Active Subscribers"
        titleBubble="white"
        style={{ backgroundColor: 'lightblue' }}
      >
        <pre>{JSON.stringify(subscribers || {}, null, 2)}</pre>
      </BorderedSection>
      <div style={{ marginTop: '10px' }}>
        <button
          disabled={submitted}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setSubmitted(true);
            resetEntitySubscribers().then(() => {
              Notifier.info(
                'Entity subscribers reset ... awaiting auto-reload',
                {},
                { autoClose: 3000 },
              );
            });
          }}
        >
          Reset All Subscriptions
        </button>
      </div>
    </div>
  );
}
