/**
 * DetailHeader
 * - header component for property-detail component
 * - expands to show summary information about the property
 * - subheader hidden by default
 */

import React, { useState } from 'react';

/* MUI */
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

/* Icons */
import CheckCircle from '@mui/icons-material/CheckCircle';
import ReportProblem from '@mui/icons-material/ReportProblem';
import ApartmentIcon from '@mui/icons-material/Apartment';

/* Local */
import { MyProperty } from '../property-detail';
import { Unit } from '../../../../types/generated-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useInjectableComponents } from '../../../system/services/injectableComponentsManager';
import { useTheme } from '@mui/material/styles';
import { Avatar, useMediaQuery } from '@mui/material';

interface DetailHeaderProps {
  property: MyProperty;
  unit: Partial<Unit> | undefined;
}
interface DetailSubheaderProps {
  property: MyProperty;
}

export function DetailHeader(props: DetailHeaderProps) {
  const [showSubheader, setShowSubheader] = useState(false);
  const { subtitle, subtitleActionWidget, navigateToSubtitlePath } =
    useInjectableComponents();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { property, unit } = props;
  const { title, unitsSummary, _id, alerts } = property || {};
  const { majorAlerts, minorAlerts } = unitsSummary || {};
  const showPropertyAlertIndicator =
    (majorAlerts || 0) + (minorAlerts || 0) > 0;
  let showUnitAlertIndicator = false;
  if (alerts && alerts?.length > 0) {
    const unitsList = alerts?.map((alert) => {
      return alert?.unit?.name;
    });
    showUnitAlertIndicator = Boolean(unitsList?.includes(unit?.name));
  }
  let viewType: 'managerUnits' | 'installerUnits' | 'unknown';

  if (pathname.includes('units') && unit?._id) {
    viewType = 'managerUnits';
  } else if (pathname.includes('installer')) {
    viewType = 'installerUnits';
  } else {
    viewType = 'unknown';
  }

  const setView = () => {
    switch (viewType) {
      case 'managerUnits':
        return {
          avatar: (
            <Avatar
              sx={{
                backgroundColor: 'transparent',
                fontSize: '60px',
              }}
              aria-label="Property Icon"
            >
              <ApartmentIcon color="secondary" />
            </Avatar>
          ),
          headerTitle: (
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'start',
                textTransform: 'none',
                width: '100%',
              }}
              onClick={() => navigateToSubtitlePath(`/properties/${_id}`)}
            >
              <Typography
                variant="h3"
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
                noWrap
              >
                {subtitle ?? ''}
              </Typography>
            </Button>
          ),
        };
      case 'installerUnits':
        return {
          avatar: (
            <Avatar
              sx={{
                backgroundColor: 'transparent',
                fontSize: '60px',
              }}
              aria-label="Property Icon"
            >
              <ApartmentIcon color="secondary" />
            </Avatar>
          ),
          headerTitle: (
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'start',
                textTransform: 'none',
                width: '100%',
              }}
              onClick={() =>
                navigateToSubtitlePath(`/properties/${_id}/installer`)
              }
            >
              <Typography
                variant="h3"
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
                noWrap
              >
                {subtitle ?? ''}
              </Typography>
            </Button>
          ),
        };

      default:
        return {
          avatar: (
            <Avatar
              sx={{
                backgroundColor: 'transparent',
                fontSize: '60px',
              }}
              aria-label="Property Icon"
            >
              <ApartmentIcon color="secondary" />
            </Avatar>
          ),
          headerTitle: (
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'start',
                textTransform: 'none',
                width: '100%',
              }}
              onClick={() => navigateToSubtitlePath(`/properties/${_id}`)}
            >
              <Typography
                variant="h3"
                sx={{ color: 'rgba(0, 0, 0, 0.87)' }}
                noWrap
              >
                {subtitle ?? ''}
              </Typography>
            </Button>
          ),
        };
    }
  };
  const { avatar, headerTitle } = setView();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CardHeader
      avatar={avatar}
      action={subtitleActionWidget}
      title={headerTitle}
      subheader={showSubheader && <DetailSubheader property={property} />}
      spacing={0}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 'unset',
        borderTop: '1px solid #dff4f2',
        '& .MuiCardHeader-content': {
          maxWidth: isSmall ? 'unset' : 'calc(100vw - 150px)',
          marginLeft: '4px',
          marginRight: '4px',
        },
      }}
    />
  );
}

function DetailSubheader(props: DetailSubheaderProps) {
  const { property } = props;
  const { address, propMgr, owner } = property || {};
  const { line1, city, state, postalCode } = address || {};
  const addressLine = `${line1 || 'Unknown Address'}`;
  const cityStateZip = `${city || 'Unknown City'}, ${state || '??'}  ${
    postalCode || '00000'
  }`;

  return (
    <Stack direction="column">
      <span>{addressLine}</span>
      <span>{cityStateZip}</span>
      <span>Manager: {propMgr?.name ?? 'N/A'}</span>
      <span>Owner: {owner?.name ?? 'N/A'}</span>
    </Stack>
  );
}
