import React from 'react';

import { Navigate } from 'react-router-dom';

import { IAuthorizedRoute } from '../auth/types/can';

import { commonRoutes } from './common-routes';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { PropertyRouter } from '.';
import { AlertsMain } from '../features/alerts/alerts-main';
import { SourceMain } from '../features/sources/source-main';
import { TestSubscriptions } from '../features/gql-tests/TestSubscriptions';
import { NotFound } from '../features/not-found/not-found';
import { UsersMain } from '../features/users/users-main';
import { LoggingOut } from '../features/logging-out/logging-out';
import { UserDetail } from '../features/users/user-detail';
import { UserDetailBreadcrumb } from '../features/users/user-detail-breadcrumb';
import { UserProfileBreadcrumb } from '../features/userProfile/user-profile-breadcrumb';
import { UsersBreadcrumb } from '../features/users/users-breadcrumb';
import { Ingestions } from '../features/ingestions/ingestions';
import BulkConfig from '../features/bulkConfig/bulk-config';
import { UserProfile } from '../features/userProfile/user-profile';
import { SourceDetailHandler } from '../features/sources/ui/source-detail-handler';
import { ZonePoliciesMain } from '../features/zone-policies/zone-policies-main';
import { ZonePolicyDetail } from '../features/zone-policies/zone-policy-detail';
import { ZonePolicyDetailBreadcrumb } from '../features/zone-policies/zone-policy-detail-breadcrumb';
import { SetpointLimitProfilesMain } from '../features/setpoint-limit-profiles/setpoint-limit-profiles-main';
import { SetpointLimitProfileDetail } from '../features/setpoint-limit-profiles/setpoint-limit-profile-detail';
import { SetpointLimitProfileDetailBreadcrumb } from '../features/setpoint-limit-profiles/setpoint-limit-profile-detail-breadcrumb';
import { ZonePoliciesBreadcrumb } from '../features/zone-policies/zone-policies-breadcrumb';
import { SetpointLimitProfilesBreadcrumb } from '../features/setpoint-limit-profiles/setpoint-limit-profile-breadcrumb';
import { SubscriptionMonitor } from '../features/infrastructure/subscription-monitoring';

export type AuthorizedRoute = BreadcrumbsRoute & IAuthorizedRoute;
export type AuthorizedRoutes = AuthorizedRoute[];

export const DefaultRoute: AuthorizedRoute = {
  path: '*',
  element: <NotFound />,
  state: { to: '/not-found' },
};

export const defaultRoutes = [DefaultRoute];

// noinspection JSUnusedLocalSymbols
export const appRoutes: AuthorizedRoutes = [
  ...commonRoutes,
  {
    path: '/',
    // TODO: Peter: in the future, the page redirected to might be dependent on the user's settings
    element: <Navigate to="/properties" replace />,
    authType: 'Property',
  },
  {
    path: '/login',
    // TODO: Peter: in the future, the page redirected to might be dependent on the user's settings
    element: <Navigate to="/properties" replace />,
    authType: 'Property',
  },
  {
    path: '/properties/*',
    element: <PropertyRouter />,
    authType: 'Property',
  },
  {
    path: '/alerts',
    element: <AlertsMain />,
    authType: 'Alert',
  },
  {
    path: '/sources',
    element: <SourceMain />,
    authType: 'Source',
  },
  {
    path: '/sources/filter/:type',
    element: <SourceMain />,
    authType: 'Source',
  },
  {
    path: '/sources/create',
    element: <SourceDetailHandler />,
    authType: 'Source',
    authAction: 'create',
  },
  {
    path: '/sources/:id/edit-source',
    element: <SourceDetailHandler />,
    authType: 'Source',
    authAction: ['update', 'view'],
  },
  {
    path: '/users',
    element: <UsersMain />,
    breadcrumb: ({
      match: {
        params: { userId },
      },
    }) => <UsersBreadcrumb />,
    authType: 'User',
  },
  {
    path: '/users/create',
    element: <UserDetail />,
    breadcrumb: ({
      match: {
        params: { userId },
      },
    }) => <UserDetailBreadcrumb id={userId} />,
    authType: 'User',
    authAction: 'create',
  },
  {
    path: '/users/create/tab/general',
    element: <UserDetail />,
    authType: 'User',
    authAction: 'create',
  },
  {
    path: '/users/create/tab/manager',
    element: <UserDetail />,
    authType: 'User',
    authAction: 'create',
  },
  {
    path: '/users/create/tab/installer',
    element: <UserDetail />,
    authType: 'User',
    authAction: 'create',
  },
  {
    path: '/users/create/tab/resident',
    element: <UserDetail />,
    authType: 'User',
    authAction: 'create',
  },
  {
    path: '/users/create/tab/viewer',
    element: <UserDetail />,
    authType: 'User',
    authAction: 'create',
  },
  {
    path: '/users/:userId',
    element: <UserDetail />,
    breadcrumb: ({
      match: {
        params: { userId },
      },
    }) => <UserDetailBreadcrumb id={userId} />,
    authType: 'User',
    authAction: 'update',
    state: {
      ignore: [/^\/users\/[a-zA-Z0-9]+$/, /^\/users\/[a-zA-Z0-9]+\/$/],
    },
  },
  {
    path: '/users/:userId/tab/general',
    element: <UserDetail />,
    authType: 'User',
    authAction: 'update',
  },
  {
    path: '/users/:userId/tab/manager',
    element: <UserDetail />,
    authType: 'User',
    authAction: 'update',
  },
  {
    path: '/users/:userId/tab/installer',
    element: <UserDetail />,
    authType: 'User',
    authAction: 'update',
  },
  {
    path: '/users/:userId/tab/resident',
    element: <UserDetail />,
    authType: 'User',
    authAction: 'update',
  },
  {
    path: '/users/:userId/tab/viewer',
    element: <UserDetail />,
    authType: 'User',
    authAction: 'update',
  },
  {
    path: '/users/:userId/tab',
    element: <UserDetail />,
    state: {
      ignore: [
        /^\/users\/[a-zA-Z0-9]+\/tab$/,
        /^\/users\/[a-zA-Z0-9]+\/tab\/$/,
      ],
    },
  },
  {
    path: '/users/:userId/profile/:viewId',
    element: <UserProfile />,
    breadcrumb: ({
      match: {
        params: { userId },
      },
    }) => <UserProfileBreadcrumb id={userId} />,
    authType: 'User',
    authAction: 'updateProfile',
  },
  {
    path: '/users/:userId/profile',
    element: <UserProfile />,
    breadcrumb: ({
      match: {
        params: { userId },
      },
    }) => <UserProfileBreadcrumb id={userId} />,
    authType: 'User',
    authAction: 'updateProfile',
  },
  {
    path: '/bulk-config',
    element: <BulkConfig />,
    authType: 'BulkConfig',
  },
  {
    path: '/ingestions',
    element: <Ingestions />,
    authType: 'Ingestion',
  },
  {
    path: '/reports',
    element: <TestSubscriptions />,
    authType: 'Reports',
  },

  {
    path: '/setpoint-profiles',
    element: <SetpointLimitProfilesMain />,
    breadcrumb: ({
      match: {
        params: { setpointProfileId },
      },
    }) => <SetpointLimitProfilesBreadcrumb />,
    authType: 'SetpointProfile',
  },
  {
    path: '/setpoint-profiles/create',
    element: <SetpointLimitProfileDetail />,
    breadcrumb: () => <SetpointLimitProfileDetailBreadcrumb />,
    authType: 'SetpointProfile',
    authAction: 'create',
  },
  {
    path: '/setpoint-profiles/profile/:setpointProfileId',
    element: <SetpointLimitProfileDetail />,
    breadcrumb: ({
      match: {
        params: { setpointProfileId },
      },
    }) => <SetpointLimitProfileDetailBreadcrumb id={setpointProfileId} />,
    authType: 'SetpointProfile',
    authAction: 'update',
  },

  {
    path: '/zone-policies',
    element: <ZonePoliciesMain />,
    breadcrumb: ({
      match: {
        params: { zonePolicyId },
      },
    }) => <ZonePoliciesBreadcrumb />,
    authType: 'ZonePolicy',
  },
  {
    path: '/zone-policies/create',
    element: <ZonePolicyDetail />,
    breadcrumb: () => <ZonePolicyDetailBreadcrumb />,
    authType: 'ZonePolicy',
    authAction: 'create',
  },
  {
    path: '/zone-policies/:zonePolicyId',
    element: <ZonePolicyDetail />,
    breadcrumb: ({
      match: {
        params: { zonePolicyId },
      },
    }) => <ZonePolicyDetailBreadcrumb id={zonePolicyId} />,
    authType: 'ZonePolicy',
    authAction: 'update',
  },
  // TODO: Peter: might not actually need this?
  {
    path: '/logging-out',
    element: <LoggingOut />,
  },
  {
    path: '/subscription-monitoring',
    element: <SubscriptionMonitor />,
    authType: 'SubscriptionMonitoring',
    authAction: 'read',
  },
  DefaultRoute,
];
