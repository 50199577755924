import React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { Fade } from '@mui/material';

import { IAwaitParams } from '../types/resetPassword';

/**
 * This component represents the 'waiting' stage in the password reset workflow
 * in which the user has requested the password reset, and we are now waiting
 * for them to identify themselves to the system via a 'token' of some sort.
 * Currently, that token is provided by them clicking a link in their email
 * for a production system authenticated via Stytch ... or via a link in the
 * standard logs if you are running in development mode without Stytch configured.
 * The 'instructions' provided to the component will be displayed to the user
 * to explain what the next step should be and where they can find their 'token'
 * and how to present it to this system (usually a link they click somewhere).
 * By clicking the link (either in email or in the log), the user should be
 * directed to the 'AcceptNewPassword' component for the next step in the workflow.
 * @param instructions
 * @constructor
 */
export function AwaitResetToken({ instructions }: IAwaitParams) {
  const navigate = useNavigate();

  return (
    <Fade
      style={{ transitionDuration: '500ms' }}
      in={true}
      mountOnEnter
      unmountOnExit
    >
      <div>
        <Box component="div" sx={{ mt: 3, marginLeft: 15, marginRight: 15 }}>
          <Grid container spacing={2}>
            <Grid
              item
              style={{
                width: '100%',
                textAlign: 'center',
                paddingTop: '2px',
              }}
            >
              {instructions.map((line, index) => {
                return line.length ? (
                  <span key={index}>{line}</span>
                ) : (
                  <br key={index} />
                );
              })}
            </Grid>
          </Grid>
        </Box>
        <Grid container width="100%" sx={{ mt: '10px' }}>
          <Grid item xs>
            &nbsp;
          </Grid>
          <Grid item>
            <Link
              sx={{ cursor: 'pointer' }}
              component={Link}
              onClick={() => navigate('/login')}
              variant="body2"
            >
              Return to login screen
            </Link>
          </Grid>
          <Grid item xs>
            &nbsp;
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
}
