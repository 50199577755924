import {
  BarcodeScanner,
  TorchStateResult,
} from '@capacitor-community/barcode-scanner';

export const toggleTorch = async () => {
  /* TODO:  torch seems to work, but behavior is not correct */
  try {
    const torchState: TorchStateResult = await BarcodeScanner.getTorchState();
    // console.log('[toggle-torch] torch-state', torchState);
    if (!torchState.isEnabled) {
      // console.log('[torch-state]', torchState)
      await BarcodeScanner.enableTorch();
    }
    const isOn = await BarcodeScanner.toggleTorch();
    console.log('[toggle-torch] torch-on', isOn);
  } catch (e) {
    if (e instanceof Error) {
      console.log(`[toggle-torch] error: ${e.message}`);
    } else {
      console.log('[toggle-torch] error: ', e);
    }
  }
};

export const startQrScan = async () => {
  try {
    /* Make background of WebView transparent:  this sets the background property on
     * the <html> tag to 'transparent'.  Note that this property has to be inherited by
     * all DOM elements that would otherwise block the view of the scanner.
     */
    BarcodeScanner.hideBackground();
    const result = await BarcodeScanner.startScan();
    if (result.hasContent) {
      // setEuid(result.content);
      // if (InstallationStatus.SCAN_IN_PROGRESS) {
      //   setInstallationStatus(InstallationStatus.SCAN_SUCCESSFUL);
      // } else if (InstallationStatus.REPLACE_SCAN_IN_PROGRESS) {
      //  TODO:  Do these need to be separate?
      //   setInstallationStatus(InstallationStatus.REPLACE_SCAN_SUCCESSFUL);
      // }
      await BarcodeScanner.stopScan();
      return result.content;
    }
  } catch (e) {
    if (e instanceof Error) {
      console.log('[qr-scanner] error: ', e.message);
    } else {
      console.log('[qr-scanner] error: ', e);
    }
    throw e;
  }
  // finally {
  //   // console.log('[qr-scanner] exiting');
  // }
};

export const stopQrScan = async () => {
  try {
    await BarcodeScanner.showBackground();
    await BarcodeScanner.stopScan();
    await BarcodeScanner.disableTorch();
  } catch (e) {
    if (e instanceof Error) {
      console.log(`[stop-scan] error ${e.name}: ${e.message}`);
    } else {
      console.log('[stop-scan] error: ', e);
    }
  }
};

export const checkPermissionStatus = async () => {
  await BarcodeScanner.checkPermission({
    force: true,
  });
};
