export enum DayOfWeek {
  SU = '0',
  MO = '1',
  TU = '2',
  WE = '3',
  TH = '4',
  FR = '5',
  SA = '6',
}

export const eventLabels = ['Wake', 'Leave', 'Return', 'Sleep'];

export const dailyScheduleDefault: MyDailySchedule = {
  '23400000': {
    minimum: 20,
    maximum: 22.5,
  },
  '30600000': {
    minimum: 16.5,
    maximum: 25.5,
  },
  '63000000': {
    minimum: 20,
    maximum: 22.5,
  },
  '84600000': {
    minimum: 16,
    maximum: 23.5,
  },
};

export const dayOfWeekLabelsMobile: Record<DayOfWeek, string> = {
  [DayOfWeek.SU]: 'S',
  [DayOfWeek.MO]: 'M',
  [DayOfWeek.TU]: 'T',
  [DayOfWeek.WE]: 'W',
  [DayOfWeek.TH]: 'T',
  [DayOfWeek.FR]: 'F',
  [DayOfWeek.SA]: 'S',
};

export const dayOfWeekLabels: Record<DayOfWeek, string> = {
  [DayOfWeek.SU]: 'SU',
  [DayOfWeek.MO]: 'MO',
  [DayOfWeek.TU]: 'TU',
  [DayOfWeek.WE]: 'WE',
  [DayOfWeek.TH]: 'TH',
  [DayOfWeek.FR]: 'FR',
  [DayOfWeek.SA]: 'SA',
};

/* Empty Schedule Object */
export const EmptyWeeklySchedule: MyWeeklySchedule = {
  '0': {},
  '1': {},
  '2': {},
  '3': {},
  '4': {},
  '5': {},
  '6': {},
};

/* Default Schedule */
export const DefaultSchedule: MyScheduleObject = {
  id: '',
  units: '',
  timestamp: 0,
  value: EmptyWeeklySchedule,
  updatePending: false,
};

export const weekdayWeekendLabels: { [dayOfWeek: string]: string } = {
  [DayOfWeek.SU]: 'Weekend',
  [DayOfWeek.MO]: 'Weekday',
};

export interface MyScheduleObject {
  id: string; //reading id
  units: string; // unused
  timestamp: number; // reading timestamp
  value: MyWeeklySchedule;
  updatePending: boolean;
}

export type MyWeeklySchedule = Record<
  DayOfWeek, // represents days of the week starting with 0=Sunday
  MyDailySchedule // map of time of day (as a string) to target min/max setpoints
>;

export type TimeOfDay = string;
export type MyDailySchedule = Record<TimeOfDay, MyScheduleSetting>;
export interface MyScheduleSetting {
  minimum?: number;
  maximum?: number;
}
