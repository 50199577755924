/**
 * Unit Schedule
 * - main unit schedule component for rendering unit schedule child components
 */
import * as React from 'react';

/* COMPONENTS */
import { UnitScheduleDetailPanel } from './views/unit-schedule-detail-panel';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../../system/services/injectableComponentsManager';

/* MUI */
import { useTheme, useMediaQuery, IconButton } from '@mui/material/';

/* ICONS */
import { Icon } from '@iconify/react';

/* TYPES */
import { useUnitScheduleDetailQuery } from '../../../../types/generated-types';
import { MyUnitSchedule } from '../types/scheduleList';

/* VIEWS */
import { ViewOption, viewOptionsMap } from './views/unit-schedule-view-options';
/* NAVIGATION */
import { useLocation, useNavigate, useParams } from 'react-router-dom';

/* ALERTS */
import { Notifier } from '../../../system/services/notificationManager';

/* LOADING */
import FullWidthLoadingSkeleton from '../../shared/fullWidthLoadingSkeleton';
import { basePropertyMenuItems } from '../../properties/ui/base-property-context-menu';

export function UnitSchedule() {
  const [unit, setUnit] = React.useState<MyUnitSchedule>();
  const [selectedViewOption, setSelectedViewOption] =
    React.useState<ViewOption>(viewOptionsMap.Overview);

  const { search } = useLocation();
  const { pathname } = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const { unitId, id } = useParams<{
    id: string;
    unitId: string;
  }>();
  const { data, loading, error } = useUnitScheduleDetailQuery({
    variables: { id: unitId || '' },
    fetchPolicy: 'network-only',
  });

  if (error) {
    Notifier.error(`Encountered a problem loading unit schedule`);
  }

  const {
    setSubtitle,
    setSubtitlePath,
    setSubtitleActionWidget,
    setContextMenuItems,
  } = useInjectableComponents();

  React.useEffect(() => {
    if (data?.unitById) {
      const unit: MyUnitSchedule = data.unitById as unknown as MyUnitSchedule;
      setUnit(unit);
    }
  }, [data]);

  React.useEffect(() => {
    if (unit) {
      const items: BottomMenuItems = basePropertyMenuItems(id, pathname);
      setSubtitle(`Schedule: ${unit?.name ?? 'N/A'}`);
      setSubtitlePath(`/properties/${unit?.property?._id}/units/${unit?._id}`);
      setSubtitleActionWidget({
        widget: (
          <IconButton
            aria-label="view unit information"
            sx={{
              color: theme.palette.secondary.main,
              backgroundColor: '#FFF',
            }}
            onClick={() =>
              navigate(
                `/properties/${unit?.property?._id}/units/${unit?._id}/view-unit`,
              )
            }
          >
            <Icon icon="mdi:information-outline" />
          </IconButton>
        ),
      });
      setContextMenuItems(items);
    }
  }, [unit]);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const view = queryParams.get('view') ?? 'overview';
    const newValue =
      Object.values(viewOptionsMap).find(
        (option) => option.routeKey === view,
      ) ?? viewOptionsMap['Overview'];
    setSelectedViewOption(newValue);
  }, [search]);

  return (
    <div
      style={{
        background: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        margin: isSmall ? '0px' : '20px 0px',
      }}
    >
      {loading ? (
        <FullWidthLoadingSkeleton />
      ) : !error ? (
        <UnitScheduleDetailPanel
          viewOption={selectedViewOption}
          data={unit}
          theme={theme}
        />
      ) : (
        <div style={{ flex: 1, textAlign: 'center' }}>
          Error loading unit schedule: {error.message}
        </div>
      )}
    </div>
  );
}
