import { Maybe } from '../../../types/generated-types';
import { Base, CustomType } from '../../system/models/base';
import {
  Fahrenheit,
  TemperatureUnit,
  CubicFeetPerMinute,
  AirFlowUnit,
} from '../../system/models/temperatureUnits';

export interface FullName {
  firstName: Maybe<string>;
  middleName: Maybe<string>;
  lastName: Maybe<string>;
}

export interface IAuthorizedUser {
  _id: string;
  name: FullName;
  email: string;
  preferredUnits: TemperatureUnit;
  preferredAirFlowUnits: AirFlowUnit;
  isSuper: boolean;
  isManager: boolean;
  isResident: boolean;
  isInstaller: boolean;
  isViewer: boolean;
  managerProperties: string[];
  installerProperties: string[];
  residentProperty?: string;
  residentUnit?: string;
  viewerProperties: string[];
}

/**
 * This class represents an Authorized User of the system and is created and
 * stored in local storage when a user successfully authenticates to the system.
 * Currently, auth users only have 2 attributes, a full name and an email address.
 * Eventually, they will also have other attributes such as a preferences profile
 * and an Authorization Profile containing their various roles and permissions.
 */
export class AuthorizedUser extends Base implements IAuthorizedUser {
  public _id: string;
  public name: FullName;
  public email: string;
  public preferredUnits: TemperatureUnit;
  public preferredAirFlowUnits: AirFlowUnit;
  public isSuper: boolean;
  public isManager: boolean;
  public isResident: boolean;
  public isInstaller: boolean;
  public isViewer: boolean;
  public managerProperties: string[];
  public installerProperties: string[];
  public residentProperty?: string;
  public viewerProperties: string[];
  public residentUnit?: string;

  static EJSONTypeName = 'AuthenticatedUser';

  static fromJSONValue(deserializedUser: unknown) {
    return new AuthorizedUser(deserializedUser as IAuthorizedUser);
  }

  constructor(user: IAuthorizedUser) {
    super(user);
    this._id = user._id;
    this.name = user.name || { lastName: 'Unnamed User' };
    this.email = user.email || 'noone@test.com';
    this.preferredUnits = user.preferredUnits || Fahrenheit;
    this.preferredAirFlowUnits =
      user.preferredAirFlowUnits || CubicFeetPerMinute;

    this.isSuper = user.isSuper;
    this.isManager = user.isManager;
    this.isInstaller = user.isInstaller;
    this.isResident = user.isResident;
    this.isViewer = user.isViewer;
    this.managerProperties = user.managerProperties;
    this.installerProperties = user.installerProperties;
    this.residentProperty = user.residentProperty;
    this.residentUnit = user.residentUnit;
    this.viewerProperties = user.viewerProperties;
  }

  equals(other: CustomType): boolean {
    return (
      other instanceof AuthorizedUser &&
      this._id === other._id &&
      this.name === other.name &&
      this.email === other.email &&
      this.preferredUnits === other.preferredUnits &&
      this.preferredAirFlowUnits === other.preferredAirFlowUnits &&
      this.isSuper === other.isSuper &&
      this.isManager === other.isManager &&
      this.isInstaller === other.isInstaller &&
      this.isResident === other.isResident &&
      this.isViewer === other.isViewer &&
      this.managerProperties.filter((propId) =>
        other.managerProperties.includes(propId),
      ).length === 0 &&
      other.managerProperties.filter((propId) =>
        this.managerProperties.includes(propId),
      ).length === 0 &&
      this.installerProperties.filter((propId) =>
        other.installerProperties.includes(propId),
      ).length === 0 &&
      other.installerProperties.filter((propId) =>
        this.installerProperties.includes(propId),
      ).length === 0 &&
      this.viewerProperties.filter((propId) =>
        other.viewerProperties.includes(propId),
      ).length === 0 &&
      other.viewerProperties.filter((propId) =>
        this.viewerProperties.includes(propId),
      ).length === 0 &&
      this.residentProperty === other.residentProperty &&
      this.residentUnit === other.residentUnit
    );
  }

  toJSONValue(): IAuthorizedUser {
    return Object.assign(super.toJSONValue(), {
      _id: this._id,
      name: this.name,
      email: this.email,
      preferredUnits: this.preferredUnits,
      preferredAirFlowUnits: this.preferredAirFlowUnits,
      isSuper: this.isSuper,
      isManager: this.isManager,
      isInstaller: this.isInstaller,
      isResident: this.isResident,
      isViewer: this.isViewer,
      managerProperties: this.managerProperties,
      installerProperties: this.installerProperties,
      residentProperty: this.residentProperty,
      residentUnit: this.residentUnit,
      viewerProperties: this.viewerProperties,
    });
  }
}

AuthorizedUser.registerEJSONType();
