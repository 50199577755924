import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Fade } from '@mui/material';

import { AUTH_IDENTIFY_USER_TOKEN_NAME } from '../../../helpers/config';
import { Log } from '../../system/services/logger';
import { useToken } from '../../system/SessionStateManager';

import { EmailInputField } from './EmailInputField';

/**
 * UI panel to collect the email address for the usual first step in the
 * login / authentication and the reset password workflows.
 * @param email: initial value for the email field.
 * @param submitHandler: callback for the submit event.
 * @param submitLabel: string label for the submit button on the collection form.
 * @param children
 * @constructor
 */
export const IdentifyUserPanel = ({
  email,
  submitHandler,
  submitLabel,
  children,
}: {
  email?: string;
  submitHandler: (event: React.FormEvent<HTMLFormElement>) => void;
  submitLabel: string;
  children?: React.ReactNode;
}) => {
  const initialEmail = useToken<string>(AUTH_IDENTIFY_USER_TOKEN_NAME);

  const [emailValid, setEmailValid] = useState<boolean>(
    !!(email || initialEmail),
  );

  return (
    <Fade
      style={{ transitionDuration: '1000ms' }}
      in={true}
      mountOnEnter
      unmountOnExit
    >
      <div>
        <Box
          component="form"
          onSubmit={submitHandler}
          noValidate
          sx={{ width: 'auto', mt: 1 }}
        >
          <EmailInputField
            autoFocus
            onValidityChange={setEmailValid}
            initialValue={email || initialEmail}
          />
          {emailValid ? (
            <Button
              type="submit"
              disabled={!emailValid}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {submitLabel}
            </Button>
          ) : (
            <Typography
              component="p"
              color="rgba(0, 0, 0, 0.60)"
              pb="8px"
              style={{
                marginTop: '36px',
                marginBottom: '8px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              Enter your email address to begin
            </Typography>
          )}
        </Box>

        <Grid container width="100%">
          <Grid item xs>
            &nbsp;
          </Grid>
          {children ? (
            <>
              {children}
              <Grid item xs>
                &nbsp;
              </Grid>
            </>
          ) : null}
          <Grid item>
            <Link
              sx={{ cursor: 'pointer' }}
              component={Link}
              onClick={() => {
                Log.warn('Clicked help!');
              }}
              variant="body2"
            >
              Need Help?
            </Link>
          </Grid>
          <Grid item xs>
            &nbsp;
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};
