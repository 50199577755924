import React, { useCallback, useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';

import { useAuthorizer } from '../../auth/AuthorizationContext';
import { StatusNotifierProvider } from '../../system/services/statusNotifier';

import { Log, LogCategory } from '../../system/services/logger';
import _ from 'lodash';

import { AppRouter } from '../../routes';

import { BottomNavbar } from '../bottom-navbar/bottom-navbar';
import { LeftDrawer } from '../nav-drawer/left-drawer';
import { MainAppBar } from '../app-bar';
// import { ContentContainer } from '../content-container/content-container';
// import { Keyboard } from '@capacitor/keyboard';
// import { Capacitor } from '@capacitor/core';

import { appBarItems, primaryNavItems, secondaryNavItems } from '../app-bar';
import { capitalize, Fade } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import { useAuthenticator } from '../../auth/AuthenticationContext';
import { AuthorizedUser } from '../../auth/models';
import { NavMenuItem } from '../app-bar';
import { InjectableComponentsProvider } from '../../system/services/injectableComponentsManager';
import { useSafePadding } from '../helpers/safe-padding';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LocalState } from '../../system/services/localStateManager';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const humanize = (str: string) => {
  return _.capitalize(
    _.trim(_.snakeCase(str).replace(/_id$/, '').replace(/_/g, ' ')),
  );
};
const useCurrentPath = () => {
  const routes = [{ path: 'properties/:id/units/:unitId' }];
  const route = useMatch(routes[0].path);
  const currentLocation = useLocation();
  const stringPath = route?.pattern.path.toString();
  let icon;
  let value;

  switch (stringPath) {
    case 'properties/:id/units/:unitId':
    case 'properties/:id/units/:unitId/charts':
      icon = <QueryStatsIcon />;
      value = currentLocation.pathname + '/charts';
      return { icon, value };
    default:
      icon = <MenuIcon />;
      value = 'home';
      return { icon, value };
  }
};

export function Shell({ theme }: { theme: Theme }) {
  /**
   * App-wide constants and state:
   */
  const drawerWidth = 240;
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const cachedDrawerState = LocalState.getItem('drawerState') as
    | 'open'
    | 'closed'
    | undefined;
  // On small screens, the left drawer should always start out as closed.
  // On large screens, initialize it to the previous drawer state, if known, otherwise open.
  const [drawerOpen, setDrawerOpen] = useState(
    isSmall ? false : cachedDrawerState !== 'closed',
  );
  const toggleDrawer = () => {
    const newDrawerOpen = !drawerOpen;
    setDrawerOpen(newDrawerOpen);
    LocalState.setItem('drawerState', newDrawerOpen ? 'open' : 'closed');
  };
  const [currentRoute, setCurrentRoute] = useState('Properties');

  /**
   * navigate (hook from react-router-dom)
   * capture routes from distributed components and render them by calling
   * the useNavigate hook.  For debugging purposes we log the route information
   * to the console.
   */

  const authenticator = useAuthenticator();
  const { can } = useAuthorizer();
  const [user, setUser] = React.useState<AuthorizedUser | undefined>();
  const [userMenuItems, setUserMenuItems] = React.useState<NavMenuItem[]>([]);

  useEffect(() => {
    if (authenticator?.user) {
      setUser(authenticator.user);
    }
  }, [authenticator]);

  useEffect(() => {
    if (user) {
      setUserMenuItems(appBarItems(user));
    }
  }, [user]);

  const auth = useAuthorizer();
  const navigate = useNavigate();
  const location = useLocation();
  const handleRouteChange = useCallback((path: string | undefined) => {
    if (path) {
      if (path === 'sign-out') {
        Log.silly(`[Shell] Signing out via path: ${path}.`, null, [
          LogCategory.TIMEOUT,
          LogCategory.ROUTING,
        ]);
        auth.terminateSession();
      } else {
        Log.silly(`[Shell] Navigating to: ${path}.`, null, [
          LogCategory.TIMEOUT,
          LogCategory.ROUTING,
        ]);
        navigate(path, { replace: true });
        setCurrentRoute(humanize(path.charAt(0).toUpperCase() + path.slice(1)));
      }
    } else {
      Log.silly(
        `[Shell] No Route Change. Remaining at: ${path}.`,
        null,
        LogCategory.ROUTING,
      );
    }
  }, []);

  useEffect(() => {
    if (location?.pathname) {
      setCurrentRoute(
        humanize(
          location.pathname === '/'
            ? 'Properties'
            : capitalize(location.pathname.split('/')[1]),
        ),
      );
    }
  }, [location, handleRouteChange]);

  /**
   * Bottom Navbar Routes:
   * Bottom Nav values are used to select top level routes, but are otherwise not synchronized
   * with router state.
   */

  const baseProps = {
    theme,
    drawerWidth,
    drawerOpen,
  };

  const appBarProps = {
    ...baseProps,
    appBarItems: userMenuItems,
    toggleDrawer,
    handleRouteChange,
    currentRoute,
  };

  const leftDrawerProps = {
    ...baseProps,
    primaryNavItems: primaryNavItems.filter((item) => {
      if (user) {
        const targetType = item.indexType;
        if (targetType) {
          return can('index', targetType);
        } else {
          return true;
        }
      } else {
        return false;
      }
    }),
    secondaryNavItems,
    toggleDrawer,
    handleRouteChange,
  };

  const { safeTopPadding } = useSafePadding();

  // const [bottomNavbarVisibility, setBottomNavbarVisibility] =
  //   useState<boolean>(true);

  // useEffect(() => {
  //   if (Capacitor.isPluginAvailable('Keyboard')) {
  //     Keyboard.addListener('keyboardWillShow', () => {
  //       setBottomNavbarVisibility(false);
  //     });
  //   }
  // }, [bottomNavbarVisibility]);

  // useEffect(() => {
  //   if (Capacitor.isPluginAvailable('Keyboard')) {
  //     Keyboard.addListener('keyboardWillHide', () => {
  //       setBottomNavbarVisibility(true);
  //     });
  //   }
  // }, [bottomNavbarVisibility]);

  const bottomNavbarProps = {
    handleRouteChange,
    secondaryNavigationAction: useCurrentPath(),
  };

  return (
    <StatusNotifierProvider>
      <InjectableComponentsProvider>
        <Fade
          in={true}
          style={{
            transitionDuration: '1100ms',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              alignItems: 'stretch',
              paddingTop: safeTopPadding,
            }}
          >
            <Box>
              <LeftDrawer {...leftDrawerProps} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                backgroundColor: 'rgb(229 243 247)',
                background: 'inherit', //Needed for scanner to work
              }}
            >
              <MainAppBar {...appBarProps} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  overflowY: 'scroll',
                }}
              >
                <AppRouter />
                {/* <ContentContainer {...baseProps}>
                </ContentContainer> */}
              </Box>
              <Box sx={{ height: isSmall ? '80px' : '56px' }}>
                <BottomNavbar {...bottomNavbarProps} />
              </Box>
              {/* TODO: Calvin: Work on fix to hide bottom navigation on tables when keyboard appears */}
              {/* {bottomNavbarVisibility ? (
                <BottomNavbar {...bottomNavbarProps} />
              ) : null} */}
            </Box>
          </div>
        </Fade>
      </InjectableComponentsProvider>
    </StatusNotifierProvider>
  );
}
