import * as React from 'react';
import logoStacked from '../../assets/images/embue-color-tm.png';
import { Icon } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const Logo = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Icon
      sx={{
        width: isSmallScreen ? '64px' : '100%',
        padding: '0px',
        marginLeft: '14px',
        marginRight: '14px',
        height: '46px',
        textAlign: 'center',
      }}
    >
      <img src={logoStacked} alt="logo" width="100%" />
    </Icon>
  );
};
