import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/* MUI */
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';

/* Icons */
import HomeIcon from '@mui/icons-material/Home';
import ToolsIcon from '@mui/icons-material/Handyman';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

/* Data */
import {
  Device,
  DoorSensor,
  IndoorSensor,
  LeakDetector,
  OccupancySensor,
  SmartPowerOutlet,
  Thermostat,
  WindowSensor,
  useInstallerDeviceQuery,
  useInstallerDeviceUpdatesSubscription,
} from '../../../types/generated-types';
import { useAuthorizer } from '../../auth/AuthorizationContext';
import { updateCacheFromSubscriptionEvent } from '../../../helpers/subscriptionUtils';

/* Types */
import { InstallationStatus } from './types';

/* Local Components */
import { ConnectionIndicator } from './components/connection-indicator';
import { StatusIndicator } from './components/status-indicator';
import { StatusMessage } from './components/status-message';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../system/services/injectableComponentsManager';

type InstallationDevice =
  | Partial<Thermostat>
  | Partial<IndoorSensor>
  | Partial<SmartPowerOutlet>
  | Partial<WindowSensor>
  | Partial<DoorSensor>
  | Partial<OccupancySensor>
  | Partial<LeakDetector>;

interface InstallableDeviceProps {
  key: string;
  device: InstallationDevice;
}

export function InstallableDevice(props: InstallableDeviceProps) {
  const { device: _device } = props;

  const { data, loading, error } = useInstallerDeviceQuery({
    variables: {
      id: _device._id ?? '',
    },
    fetchPolicy: 'network-only',
  });

  useInstallerDeviceUpdatesSubscription({
    variables: {
      ids: [_device._id ?? ''],
    },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  const [device, setDevice] = React.useState<Partial<Device>>();
  const [isDone, setIsDone] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<InstallationStatus>(
    InstallationStatus.READY_TO_INSTALL,
  );
  const [isOffline, setIsOffline] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { can } = useAuthorizer();
  const { setContextMenuItems } = useInjectableComponents();

  React.useEffect(() => {
    if (!data || !data.deviceById) {
      return;
    }

    const _device = data.deviceById as Partial<Device>;
    setDevice(_device);
    setIsDone(_device.installation?.status === 3);
    setStatus(_device.installation?.status as InstallationStatus);
    setIsOffline(_device.alertTypes?.deviceOffline ?? false);
  }, [data]);

  React.useEffect(() => {
    const propertyId = pathname.split('/')[2];
    const bottomMenu: BottomMenuItems = [
      {
        items: [
          {
            id: 'installer-unit-menu-item',
            label: 'Manage Unit / View Devices',
            icon: <HomeIcon fontSize="small" />,
            action: () => {
              navigate(pathname.replace('installer', 'units'));
            },
          },
          {
            id: 'units-list-installer-view',
            label: 'Return To Units List',
            icon: <KeyboardReturnIcon fontSize="small" />,
            action: () => {
              navigate(`/properties/${propertyId}/installer`);
            },
          },
        ],
      },
    ];
    setContextMenuItems(bottomMenu);
    return () => {
      setContextMenuItems(undefined);
    };
  }, [pathname, can, navigate, setContextMenuItems]);

  return error ? (
    <Typography>Error: {error.name}</Typography>
  ) : loading ? (
    <Typography>Loading ...</Typography>
  ) : device ? (
    <ListItem sx={{ width: '100%', justifyContent: 'center' }} key={device._id}>
      <Card
        variant={'outlined'}
        sx={{
          p: 1,
          border: '2px solid darkgray',
          width: '90%',
        }}
      >
        <CardHeader
          title={device.name}
          subheader={device.typeDisplayName}
          sx={{
            py: 1,
            boxShadow: '0 px',
          }}
          action={
            <Stack direction={'row'} spacing={2}>
              <ConnectionIndicator show={isOffline} deviceOffline={isOffline} />
              <StatusIndicator status={status} />
            </Stack>
          }
        />
        <CardContent sx={{ py: 1 }}>
          <Stack>
            <StatusMessage
              status={device.installation?.status}
              message={device.installation?.message}
              plain={true}
            />
            {isOffline && (
              <Typography variant="caption">
                Warning: This device is offline
              </Typography>
            )}
          </Stack>
        </CardContent>
        <CardActions>
          <Button
            variant={'contained'}
            color={isOffline || !isDone ? 'error' : 'info'}
            size="small"
            startIcon={<ToolsIcon />}
            onClick={() => navigate(`${device._id}`)}
          >
            Installer Tools
          </Button>
        </CardActions>
      </Card>
    </ListItem>
  ) : null;
}
