import React, { useEffect } from 'react';
import {
  ZonePolicy,
  useZonePolicyDetailQuery,
} from '../../../types/generated-types';
import { useParams } from 'react-router-dom';
import { SimpleZonePolicyForm } from './ui/zone-policy-detail-form';
import FullWidthLoadingSkeleton from '../shared/fullWidthLoadingSkeleton';
import { Notifier } from '../../system/services/notificationManager';

export const ZonePolicyDetail = () => {
  const { zonePolicyId } = useParams<{ zonePolicyId: string }>();
  const { data, loading, error } = useZonePolicyDetailQuery({
    variables: { _id: zonePolicyId ?? '' },
    fetchPolicy: 'network-only',
  });
  const [policy, setPolicy] = React.useState<Partial<ZonePolicy>>();

  useEffect(() => {
    if (error) {
      Notifier.error('Error loading zone policy: ' + error.message);
    } else if (data) {
      setPolicy(data.zonePolicyById as Partial<ZonePolicy>);
    }
  }, [data, error]);

  return zonePolicyId === undefined ? (
    <SimpleZonePolicyForm policy={{} as Partial<ZonePolicy>} />
  ) : loading ? (
    <FullWidthLoadingSkeleton />
  ) : policy ? (
    <SimpleZonePolicyForm policy={policy} />
  ) : null;
};
