import React from 'react';
import { RouteObject, Navigate } from 'react-router-dom';
import { AuthenticateUser } from '../auth/workflows/AuthenticateUser';
import { ResetPassword } from '../auth/workflows/ResetPassword';
import { LoggingOut } from '../features/logging-out/logging-out';
import { NotFound } from '../features/not-found/not-found';
import { AuthorizedRoute } from './app-routes';
import { PrivacyPolicy } from '../features/smart/privacyPolicy';

export const DefaultRoute: AuthorizedRoute = {
  path: '*',
  element: <NotFound />,
  state: { to: '/not-found' },
};

export const commonRoutes: RouteObject[] = [
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
];
