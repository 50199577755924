import React, { useEffect } from 'react';
import { Typography } from '@mui/material';

export function NotFound() {
  const [show, setShow] = React.useState(false);
  useEffect(() => {
    const handle = setTimeout(() => {
      setShow(true);
    }, 100);
    return () => {
      clearTimeout(handle);
    };
  }, []);
  return show ? <Typography variant="h4">Not Found</Typography> : null;
}
