import React, { useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
  DataGridProps,
  GridFilterPanel,
  GridPaginationInitialState,
  GridFilterModel,
} from '@mui/x-data-grid';
import { Grid, IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FilterAltOff, FilterAlt, Refresh } from '@mui/icons-material';
import { Notifier } from '../../system/services/notificationManager';

export interface DataTableProps extends DataGridProps {
  rows: Array<{}>;
  columns: Array<GridColDef>;
  loading?: boolean;
  pageSize?: number;
  getRowId?: any;
  apiRef?: any;
  hideFooter?: boolean;
  initialState?: object;
  columnVisibilityModel?: {};
  filterModel?: GridFilterModel;
  sxProps?: {};
  hideFilterSorting?: boolean;
  hideFilterButton?: boolean;
  hideTextSearch?: boolean;
  additionalProps?: Partial<DataGridProps>;
  customToolbarElement?: React.ReactElement;
  suppressAutoPageSize?: boolean;
  showRefreshButton?: boolean;
  onRefresh?: () => Promise<unknown>;
}

// TODO: Calvin: we don't seem to use the hide filter sorting option.
//  Calvin, do we need this any longer? It seems to have been meant
//  to have been set externally via query parameters on certain screens?
function CustomToolbar(
  customToolbarElement: React.ReactElement,
  hideFilterSorting?: boolean,
  hideFilterButton?: boolean,
  hideTextSearch?: boolean,
) {
  const [hide, toggleHide] = React.useState(true);
  const theme = useTheme();
  const commonStyles = {
    color: theme.palette.secondary.dark,
    padding: '10px',
  };
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return !hideFilterSorting ? (
    <GridToolbarContainer sx={{ gap: 0 }}>
      <Grid container>
        {!hideTextSearch ? (
          <Grid
            item
            xs={hideFilterButton ? 12 : 11}
            md={hideFilterButton ? 12 : 11}
          >
            <GridToolbarQuickFilter sx={{ ...commonStyles, width: '100%' }} />
          </Grid>
        ) : null}
        {!hideFilterButton && (
          <Grid item xs={1} md={1}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton
                onClick={() => {
                  if (hide) {
                    toggleHide(false);
                  } else {
                    toggleHide(true);
                  }
                }}
                sx={{
                  color: theme.palette.secondary.dark,
                  justifyContent: 'center',
                }}
              >
                {!hide ? (
                  <FilterAltOff fontSize="small" />
                ) : (
                  <FilterAlt fontSize="small" />
                )}
              </IconButton>
            </div>
          </Grid>
        )}
        {hide ? null : (
          <>
            <Grid item xs={6}>
              <GridToolbarColumnsButton sx={commonStyles} />
            </Grid>
          </>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            {customToolbarElement}
          </div>
        </Grid>
        {!hide ? (
          <Grid item xs={12}>
            <div style={{ maxWidth: isSmall ? 'calc(100vw - 18px)' : 'unset' }}>
              <GridFilterPanel />
            </div>
          </Grid>
        ) : null}
      </Grid>
    </GridToolbarContainer>
  ) : null;
}

export default function DataTable(props: DataTableProps) {
  const {
    showCellVerticalBorder,
    rows,
    columns,
    loading,
    apiRef,
    getRowId,
    initialState,
    filterModel,
    columnVisibilityModel,
    additionalProps,
    hideFooter,
    hideFilterSorting,
    hideFilterButton,
    hideTextSearch,
    suppressAutoPageSize,
    customToolbarElement,
    showRefreshButton,
    sxProps,
    onRefresh,
    ...rest
  } = props;
  const pageSize: number = props.pageSize ?? 50;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  // On small screens, always display the footer. On large screens, display it unless `hideFooter` is explicitly set
  const hideTableFooter = !isSmall && hideFooter;
  // Disable pagination if the table footer with pagination controls is hidden
  const pagination: GridPaginationInitialState | undefined = hideTableFooter
    ? undefined
    : { paginationModel: { pageSize, page: 0 } };

  const [refreshing, setRefreshing] = useState(false);

  // Refresh table data
  async function handleRefresh() {
    if (onRefresh) {
      setRefreshing(true);
      try {
        await onRefresh();
      } catch (error) {
        Notifier.error('Error refreshing data');
        console.error(error);
      } finally {
        setRefreshing(false);
      }
    }
  }

  const slots = hideFooter
    ? {
        toolbar: () =>
          CustomToolbar(
            customToolbarElement || <div></div>,
            hideFilterSorting,
            hideFilterButton,
            hideTextSearch,
          ),
        footer: () => <div></div>,
      }
    : {
        toolbar: () =>
          CustomToolbar(
            customToolbarElement || <div></div>,
            hideFilterSorting,
            hideFilterButton,
            hideTextSearch,
          ),
      };

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'stretch',
        flex: 1,
      }}
    >
      {showRefreshButton && (
        <div
          style={
            // On mobile, display the button in the top right, outside the table.
            // On desktop, position it inside the table in the top right, inline with the filter button.
            isSmall
              ? { alignSelf: 'flex-end' }
              : { position: 'absolute', top: 5, right: 10, zIndex: 100 }
          }
        >
          <IconButton
            sx={{ color: theme.palette.secondary.dark }}
            onClick={handleRefresh}
            disabled={refreshing}
          >
            <Refresh />
          </IconButton>
        </div>
      )}
      <DataGrid
        slots={slots}
        sx={{
          '& .MuiDataGrid-row:hover': {
            color: theme.palette.secondary.main,
          },
          '& .MuiSvgIcon-root': {
            color: theme.palette.secondary.dark,
          },
          '& .MuiDataGrid-border:hover': {
            color: theme.palette.secondary.main,
          },
          '& .MuiDataGrid-columnHeader': {
            color: theme.palette.secondary.dark,
          },
          '& .MuiDataGrid-columnHeaders': {
            boxShadow: '0px 6px 6px 0px rgba(0,0,0,0.12)',
            borderBottom: '0px',
          },
          '& .css-12zdf0-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter':
            {
              padding: 0,
              paddingTop: '10px',
              paddingRight: '10px',
              paddingLeft: '10px',
            },
          '& .MuiDataGrid-cell': {
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            borderRight: '0px',
            fontSize: '14px',
            color: additionalProps?.getRowClassName ? '' : 'rgba(0, 0, 0, 0.6)',
          },
          ...sxProps,
        }}
        {...additionalProps}
        {...rest}
        hideFooter={hideTableFooter}
        showCellVerticalBorder={showCellVerticalBorder ?? true}
        initialState={{
          pagination,
          ...initialState,
        }}
        autoPageSize={isSmall && !suppressAutoPageSize}
        getRowId={getRowId}
        pageSizeOptions={[5, 10, 25, 50]}
        apiRef={apiRef}
        filterModel={filterModel}
        columnVisibilityModel={columnVisibilityModel}
        rows={rows}
        disableColumnSelector={true}
        columns={columns}
        paginationMode="client"
        loading={loading}
      />
    </div>
  );
}
