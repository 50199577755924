import React from 'react';
import { styled } from '@mui/material/styles';
import BottomNavDefault from '@mui/material/BottomNavigation';
import BottomNavActionDefault from '@mui/material/BottomNavigationAction';
import { useMediaQuery } from '@mui/material';

export const BottomNavigation = styled(BottomNavDefault)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  // backgroundColor: theme.palette.primary.main,
  backgroundColor: '#fff',
  boxShadow: '0px -2px 8px -1px rgb(0 0 0 / 12%)',
  position: 'fixed',
  display: 'flex',
  bottom: 0,
  left: 0,
  right: 0,
  [theme.breakpoints.up('md')]: {
    // display: 'none',
  },
}));

export const BottomNavigationAction = styled(BottomNavActionDefault)(
  ({ theme }) => ({
    display: 'flex',
    minWidth: '0px',
    padding: '0px',
    color: '#00BAAF',
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
    },
    '&.Mui-disabled': {
      color: theme.palette.grey[400],
    },
    '&.MuiTouchRipple-child': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.secondary.light,
      borderRadius: 25,
    },
  }),
);

export const ExpandoBottomNavigationAction = styled('div')(({ theme }) => {
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return {
    display: 'flex',
    flex: isSmall ? '1 1 auto' : '0 1 auto',
    minWidth: '180px',
    color: 'red',
    borderLeft: '1px solid lightgrey',
  };
});

interface MyStyledDivProps extends React.HTMLAttributes<HTMLDivElement> {
  showLabel?: boolean;
}

export const PrimaryBottomNavigationComponent: React.FC<MyStyledDivProps> = ({
  showLabel,
  ...rest
}) => <ExpandoBottomNavigationAction {...rest} />;
