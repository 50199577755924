import { Actions, Subjects, SubjectTypes } from './ability';
import { MongoAbility } from '@casl/ability';
import { subject as identifySubject, CanParameters } from '@casl/ability';

type AuthorizationParams<
  A extends Actions,
  S extends Subjects,
  T extends [A, S],
> = CanParameters<T>;

export type AuthorizationChecker<
  A extends Actions = Actions,
  S extends Subjects = Subjects,
  T extends [A, S] = [A, S],
> = (
  action: AuthorizationParams<A, S, T>[0],
  subject: SubjectTypes | { __typename?: string } | undefined | null,
  field?: AuthorizationParams<A, S, T>[2],
) => boolean;

export interface IAuthorizedRoute {
  authAction?: Actions | Actions[];
  authType?: SubjectTypes;
  // TODO: Peter: I think this (the state property) was left from an experiment. We should remove if not used.
  state?: Record<string, any>;
}

export type RouteAuthorizationChecker = (route: IAuthorizedRoute) => boolean;

export function isAuthorized<A extends Actions, S extends Subjects>(
  ability: MongoAbility<[Actions, Subjects]>,
  action: AuthorizationParams<Actions, Subjects, [A, S]>[0],
  subject: SubjectTypes | { __typename?: string } | undefined | null,
  field: AuthorizationParams<Actions, Subjects, [A, S]>[2] | undefined,
) {
  let mySubject = subject;
  if (subject !== undefined && subject !== null) {
    mySubject =
      typeof subject === 'string'
        ? subject
        : subject.__typename
          ? identifySubject(subject.__typename, { ...subject })
          : subject;
  }
  return ability.can(action, mySubject as any, field);
}
