import * as React from 'react';

/* MUI */
import { Dialog } from '@mui/material';

/* ICONS */
import { ScheduleDetailHandler } from '../../../../units/schedule/views/schedule-detail-handler';
import { MyThermostat } from '../../types/my-thermostat';
import { Unit } from '../../../../../../types/generated-types';

interface ThermostatScheduleDetailDialogProps {
  open: boolean;
  unit?: Partial<Unit>;
  thermostat?: MyThermostat;
  handleClose: (newTemplateId?: string) => void;
  templateId: string;
  disableSetpointProfileSelection?: boolean;
}

export function ThermostatScheduleDetailDialog(
  props: ThermostatScheduleDetailDialogProps,
) {
  const {
    handleClose,
    open,
    templateId,
    unit,
    thermostat,
    disableSetpointProfileSelection,
  } = props;

  return (
    <Dialog
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        margin: 0,
        '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
          margin: 0,
          width: '100%',
        },
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'stretch',
        }}
      >
        <ScheduleDetailHandler
          templateDialogId={templateId}
          unit={unit}
          disableSetpointProfileSelection={disableSetpointProfileSelection}
          thermostat={thermostat}
          handleClose={handleClose}
        />
      </div>
    </Dialog>
  );
}
