import React, { useState, useEffect } from 'react';
import { PropertyAlertTriggerDetailForm } from '../components/property-alert-trigger-detail-form';
import FullWidthLoadingSkeleton from '../../shared/fullWidthLoadingSkeleton';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  AlertTrigger,
  Property,
  useAlertTriggerDetailQuery,
  usePropertyDataForBuildingQuery,
  useRemoveAlertTriggerMutation,
} from '../../../../types/generated-types';
import { Typography } from '@mui/material';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../../system/services/injectableComponentsManager';
import { Remove } from '@mui/icons-material';
import { Notifier } from '../../../system/services/notificationManager';
import { basePropertyMenuItems } from './base-property-context-menu';

const styles = {
  stretch: {
    height: '98%',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '20px',
  },
};

export const AlertTriggerDetailHandler = () => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const { pathname } = currentLocation;
  const {
    subtitle,
    setContextMenuItems,
    setSubtitle,
    setTitle,
    setTitlePath,
    setSubtitlePath,
  } = useInjectableComponents();
  const { tid, id } = useParams<{ id: string; tid: string }>();
  const { data, error, loading } = useAlertTriggerDetailQuery({
    variables: { id: tid ?? '' },
  });
  useEffect(() => {
    if (error) {
      Notifier.error(
        'There was a problem fetching alert trigger information: ',
        error,
      );
    }
  }, [error]);
  const [removeAlertTrigger] = useRemoveAlertTriggerMutation();
  const [alertTrigger, setAlertTrigger] = useState<Partial<AlertTrigger>>();
  const [property, setProperty] = useState<Partial<Property>>();
  useEffect(() => {
    if (data?.alertTriggerById) {
      setAlertTrigger(data.alertTriggerById);
    }
  }, [data]);

  const { data: propertyData } = usePropertyDataForBuildingQuery({
    variables: { id: id ?? '' },
  });

  useEffect(() => {
    if (propertyData?.propertyById) {
      setProperty(propertyData.propertyById as Partial<Property>);
    }
  }, [propertyData]);

  useEffect(() => {
    if (alertTrigger && property) {
      const handleRemoveAlertTrigger = async () => {
        if (alertTrigger?._id) {
          try {
            await removeAlertTrigger({
              variables: {
                id: alertTrigger._id,
              },
            });
            Notifier.success('Alert Trigger removed successfully');
            const pathComponent = pathname.split('/').slice(0, 4);
            navigate(pathComponent.join('/'));
          } catch (e) {
            console.error(e);
          }
        } else {
          Notifier.warn('Alert Trigger not found');
        }
      };

      const confirmableAction = {
        actionLabel: 'Remove',
        cancelLabel: 'Cancel',
        title: `Remove Alert Trigger (${
          alertTrigger.name ?? 'Unnamed alert trigger'
        })`,
        prompt: 'Are you sure? This action cannot be reversed',
        action: handleRemoveAlertTrigger,
      };

      const bottomMenu: BottomMenuItems = [
        ...basePropertyMenuItems(property._id, pathname),
        {
          label: 'Alert Trigger Actions',
          items: [
            {
              id: 'remove-alertTrigger-menu-item',
              label: 'Remove AlertTrigger',
              icon: <Remove fontSize="small" />,
              confirmableAction,
              permit: {
                action: 'delete',
                subject: 'AlertTrigger',
              },
            },
          ],
        },
      ];

      setTitle(property.title ?? 'Untitled Property');
      setTitlePath(`/properties/${property._id}/units`);

      setSubtitle(
        `${alertTrigger.name ?? 'Unnamed alert trigger'} - Editing Details`,
      );
      setSubtitlePath(`/properties/${property._id}/alertTriggers`);

      setContextMenuItems(bottomMenu);
    } else if (property) {
      setTitle(property.title ?? 'Untitled Property');
      setTitlePath(`/properties/${property._id}/units`);

      setSubtitle(`Creating Alert Trigger`);
      setSubtitlePath(`/properties/${property._id}/alertTriggers`);

      setContextMenuItems(basePropertyMenuItems(property._id, pathname));
    } else {
      setTitle('Properties');
      setTitlePath(`/properties`);

      setSubtitle(`Click to return to property list`);
      setSubtitlePath(`/properties`);

      setContextMenuItems(undefined);
    }

    return () => {
      setContextMenuItems(undefined);
    };
  }, [
    pathname,
    setContextMenuItems,
    alertTrigger,
    property,
    removeAlertTrigger,
    navigate,
  ]);

  return (
    <div style={styles.stretch}>
      <Typography
        variant="h3"
        sx={{ marginBottom: '20px', paddingRight: '10px', paddingLeft: '10px' }}
      >
        {subtitle ? subtitle : ''}
      </Typography>
      {loading ? (
        <FullWidthLoadingSkeleton />
      ) : data?.alertTriggerById ? (
        <PropertyAlertTriggerDetailForm
          alertTrigger={data.alertTriggerById as Partial<AlertTrigger>}
        />
      ) : tid === undefined ? (
        <PropertyAlertTriggerDetailForm
          alertTrigger={{} as Partial<AlertTrigger>}
        />
      ) : (
        <div>No data</div>
      )}
    </div>
  );
};
