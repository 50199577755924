import React, { useState } from 'react';
import pluralize from 'pluralize';
import {
  useGetBulkSetpointLimitsLazyQuery,
  useValidateBulkConfigThermostatsChangesMutation,
  useCommitBulkConfigThermostatsChangesMutation,
  BulkConfigThermostatsInput,
} from '../../../../types/generated-types';
import SelectProperty from '../common/select-property';
import SelectUnitTypes from './select-unit-types';
import SelectBuildings from './select-buildings';
import SelectFloors from './select-floors';
import SelectCUGroups from './select-cu-groups';
import ConfigureTstats from './configure-tstats';
import BulkVerify from '../common/bulk-verify';
import BulkCommit from '../common/bulk-commit';
import {
  TstatConfig,
  TstatFilters,
  UnitFilters,
  BulkThermostatsStep,
  Properties,
  Property,
  Building,
  Floor,
  Unit,
  initialTstatConfig,
  initialTstatFilters,
  initialCommitProgress,
} from '../types';
import { convertSetpoints } from '../utils/bulk-utils';
import { Notifier } from '../../../system/services/notificationManager';
import { Celsius } from '../../../system/models/temperatureUnits';

// Flag indicating we should terminate the in-progress bulk config commit.
// Needs to exist outside of React state, as the function performing the commit
// only has access to a snapshot of state as it was at the time of the commit.
let terminateCommit = false;

export interface BulkThermostatsProps {
  properties: Properties;
}

// Top level container for thermostat bulk configuration. Contains the majority
// of state and logic shared between the various steps
export default function BulkThermostats(props: BulkThermostatsProps) {
  const { properties } = props;
  const [step, setBulkThermostatsStep] = useState(
    BulkThermostatsStep.SelectProperty,
  );
  const [tstatConfig, setTstatConfig] = useState(initialTstatConfig);
  const [tstatFilters, setTstatFilters] = useState(initialTstatFilters);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedUnitTypes, setSelectedUnitTypes] = useState<string[]>([]);
  const [selectedBuildings, setSelectedBuildings] = useState<string[]>([]);
  const [selectedFloors, setSelectedFloors] = useState<string[]>([]);
  const [selectedCUGroups, setSelectedCUGroups] = useState<string[]>([]);
  const [commitProgress, setCommitProgress] = useState(initialCommitProgress);
  const [error, setError] = useState('');

  // Combined units filters object - easier to share between child components and functions
  const unitFilters: UnitFilters = {
    selectedUnitTypes,
    selectedBuildings,
    selectedFloors,
    selectedCUGroups,
  };

  // Format current state for use in graphql operations
  function formatBulkThermostatInput(): BulkConfigThermostatsInput {
    return {
      propertyId: selectedProperty,
      unitTypes: selectedUnitTypes,
      buildingIds: selectedBuildings,
      floorIds: selectedFloors,
      cuGroups: selectedCUGroups,
      config: {
        ...tstatConfig,
        // ensure setpoints are sent to the server as celsius
        setpoints: convertSetpoints(tstatConfig.setpoints, Celsius),
      },
      filters: tstatFilters,
    };
  }

  const [loadSetpointLimits, { data: setpointsData }] =
    useGetBulkSetpointLimitsLazyQuery();

  const [validateChanges, { data: validateData }] =
    useValidateBulkConfigThermostatsChangesMutation({
      variables: {
        input: formatBulkThermostatInput(),
      },
    });

  const [commitConfig] = useCommitBulkConfigThermostatsChangesMutation();

  function handleSelectProperty(propertyId: string) {
    // Clear all filters when a new property is selected
    setSelectedProperty(propertyId);
    setSelectedUnitTypes([]);
    setSelectedBuildings([]);
    setSelectedFloors([]);
    setSelectedCUGroups([]);
  }

  function handleSelectUnitType(unitType: string, checked: boolean) {
    // Add or remove the given unit type
    const newUnitTypes = checked
      ? [...selectedUnitTypes, unitType]
      : selectedUnitTypes.filter((t) => t !== unitType);
    setSelectedUnitTypes(newUnitTypes);
    // Reset all filters that depend on selected unit types
    setSelectedBuildings([]);
    setSelectedFloors([]);
  }

  function handleSelectBuilding(buildingId: string, checked: boolean) {
    // Add or remove the given building
    const newBuildings = checked
      ? [...selectedBuildings, buildingId]
      : selectedBuildings.filter((b) => b !== buildingId);
    setSelectedBuildings(newBuildings);
    // Reset all filters that depend on selected buildings
    setSelectedFloors([]);
  }

  function handleSelectFloor(floorId: string, checked: boolean) {
    // Add or remove the given floor
    const newFloors = checked
      ? [...selectedFloors, floorId]
      : selectedFloors.filter((f) => f !== floorId);
    setSelectedFloors(newFloors);
  }

  function handleSelectCUGroup(cuGroup: string, checked: boolean) {
    // Add or remove the given CU group
    const newCUGroups = checked
      ? [...selectedCUGroups, cuGroup]
      : selectedCUGroups.filter((t) => t !== cuGroup);
    setSelectedCUGroups(newCUGroups);
  }

  function toggleUnitTypes(unitTypes: string[]) {
    if (unitTypes.length > selectedUnitTypes.length) {
      setSelectedUnitTypes(unitTypes);
    } else {
      setSelectedUnitTypes([]);
    }
    // Reset all filters that depend on selected unit types
    setSelectedBuildings([]);
    setSelectedFloors([]);
  }

  function toggleBuildings(buildings: string[]) {
    if (buildings.length > selectedBuildings.length) {
      setSelectedBuildings(buildings);
    } else {
      setSelectedBuildings([]);
    }
    // Reset all filters that depend on selected buildings
    setSelectedFloors([]);
  }

  function toggleFloors(floors: string[]) {
    if (floors.length > selectedFloors.length) {
      setSelectedFloors(floors);
    } else {
      setSelectedFloors([]);
    }
  }

  function toggleCUGroups(cuGroups: string[]) {
    if (cuGroups.length > selectedCUGroups.length) {
      setSelectedCUGroups(cuGroups);
    } else {
      setSelectedCUGroups([]);
    }
  }

  function handleTstatConfigChange(changes: Partial<TstatConfig>) {
    setTstatConfig({
      ...tstatConfig,
      ...changes,
    });
  }

  function handleTstatFilterChange(changes: Partial<TstatFilters>) {
    setTstatFilters({
      ...tstatFilters,
      ...changes,
    });
  }

  function handleNext() {
    // Navigate to the next step, if the selections on the current step are valid
    if (validateCurrentBulkThermostatsStep()) {
      const steps = getBulkThermostatsStepsForProperty();
      const currentIndex = steps.indexOf(step);
      const nextBulkThermostatsStep = steps[currentIndex + 1];
      // Kick off any operations to perform on the next step, then navigate to it.
      // No need to wait for operations to finish
      executeOperationsForBulkThermostatsStep(nextBulkThermostatsStep);
      setBulkThermostatsStep(nextBulkThermostatsStep);
    }
  }

  function handleBack() {
    // Navigate to the previous step
    setError('');
    const steps = getBulkThermostatsStepsForProperty();
    const currentIndex = steps.indexOf(step);
    const prevBulkThermostatsStep = steps[currentIndex - 1];
    setBulkThermostatsStep(prevBulkThermostatsStep);
  }

  function handleRestart() {
    // Reset state and navigate back to the first step
    handleSelectProperty('');
    setTstatConfig(initialTstatConfig);
    setTstatFilters(initialTstatFilters);
    setCommitProgress(initialCommitProgress);
    setError('');
    setBulkThermostatsStep(BulkThermostatsStep.SelectProperty);
  }

  // Verify that the selections made on the current step are valid, and that the user
  // is able to proceed to the next step. If not, display an appropriate error message.
  function validateCurrentBulkThermostatsStep(): boolean {
    let errMsg = '';
    switch (step) {
      case BulkThermostatsStep.SelectProperty:
        if (!selectedProperty) {
          errMsg = 'Must select a property to configure';
        }
        break;
      case BulkThermostatsStep.SelectUnitTypes:
        if (selectedUnitTypes.length === 0) {
          errMsg = 'Must select at least one unit type';
        }
        break;
      case BulkThermostatsStep.SelectBuildings:
        if (selectedBuildings.length === 0) {
          errMsg = 'Must select at least one building';
        }
        break;
      case BulkThermostatsStep.SelectFloors:
        if (selectedFloors.length === 0) {
          errMsg = 'Must select at least one ' + getFloorLabel(false);
        }
        break;
      case BulkThermostatsStep.SelectCUGroups:
        if (selectedCUGroups.length === 0) {
          errMsg = 'Must select at least one CU group';
        }
        break;
      case BulkThermostatsStep.BulkVerify:
        if (commitProgress.deviceIds.length === 0) {
          errMsg = 'No thermostats to configure';
        }
        break;
    }
    setError(errMsg);
    return !errMsg;
  }

  // Perform any operations that should be triggered on proceeding to the given step
  async function executeOperationsForBulkThermostatsStep(
    step: BulkThermostatsStep,
  ) {
    switch (step) {
      case BulkThermostatsStep.ConfigureTstats:
        // Load setpoint limits for the selected thermostats. Needed in case the user wants to modify setpoints
        try {
          const result = await loadSetpointLimits({
            variables: {
              input: formatBulkThermostatInput(),
            },
          });
          if (result.error) {
            throw new Error(result.error.message);
          }
        } catch (error) {
          console.error(error);
          setError('Error loading bulk setpoint limits');
        }
        break;
      case BulkThermostatsStep.BulkVerify:
        try {
          // Validate the current thermostat changes, and determine how many devices require configuring
          const result = await validateChanges();
          if (result.errors) {
            throw new Error(result.errors[0].message);
          } else if (result.data) {
            setCommitProgress((prevProgress) => {
              return {
                ...prevProgress,
                deviceIds:
                  result.data?.validateBulkConfigThermostatsChanges.deviceIds ||
                  [],
              };
            });
          }
        } catch (error) {
          console.error(error);
          setError('Error validating thermostat configuration');
        }
        break;
      case BulkThermostatsStep.BulkCommit:
        // Loop through each thermostat and commit the current changes
        // TODO: Bulk_setpoint_limits: Could this be extracted into a helper function? The logic
        // between this and the commit logic in bulk-setpoint-limits.tsx is nearly identical.
        // If we get around to finishing bulk setpoint limits, or implement any new bulk config
        // features we may want to consider refactoring this.
        for (const deviceId of commitProgress.deviceIds) {
          if (!terminateCommit) {
            try {
              const result = await commitConfig({
                variables: {
                  deviceId,
                  input: formatBulkThermostatInput().config,
                },
              });
              if (result.errors) {
                throw new Error(result.errors[0].message);
              } else if (result.data) {
                setCommitProgress((prevProgress) => {
                  return {
                    ...prevProgress,
                    numConfigured: prevProgress.numConfigured + 1,
                  };
                });
              }
            } catch (error) {
              console.error(error);
              setCommitProgress((prevProgress) => {
                return {
                  ...prevProgress,
                  numFailed: prevProgress.numFailed + 1,
                };
              });
            }
          } else {
            // User chose to terminate the commit before all devices were processed
            setCommitProgress((prevProgress) => {
              const { deviceIds, numConfigured, numFailed } = prevProgress;
              const numSkipped = deviceIds.length - numConfigured - numFailed;
              Notifier.info(
                `Bulk config terminated: ${numConfigured} configured, ${numFailed} failed, ${numSkipped} skipped.`,
              );
              // Set commit status to terminated
              return { ...prevProgress, terminated: true };
            });
            // Reset global terminate flag
            terminateCommit = false;
            return;
          }
        }
        break;
    }
  }

  function getSelectedProperty(): Property | undefined {
    return properties.find((p) => p._id === selectedProperty);
  }

  // Get filtered list of units that include a thermostat
  function getUnitsWithThermostats(): Unit[] {
    const property = getSelectedProperty();
    const tstatUnitIds = property?.devices.map((d) => d.unitId) || [];
    return (
      property?.units.filter((u) => tstatUnitIds.includes(u?._id || '')) || []
    );
  }

  // Get list of selectable units types, filtered to only include units with thermostats
  function getSelectableUnitTypes(): string[] {
    const tstatUnitIds = getUnitsWithThermostats();
    const unitTypes = tstatUnitIds.map((u) => u?.type || '');
    return Array.from(new Set(unitTypes));
  }

  // Get filtered list of units, based on the selected unit types
  function filterUnitsBySelectedUnitTypes(): Unit[] {
    const property = getSelectedProperty();
    return (
      property?.units.filter((u) => {
        if (selectedUnitTypes.length > 0) {
          return selectedUnitTypes.includes(u?.type || '');
        }
        return true;
      }) || []
    );
  }

  // Get list of selectable buildings, filtered base on selected unit types
  function getSelectableBuildings(): Building[] {
    const property = getSelectedProperty();
    const units = filterUnitsBySelectedUnitTypes();
    const buildingIdMap: { [id: string]: boolean } = {};
    units.forEach((u) => {
      if (u?.buildingId) {
        buildingIdMap[u.buildingId] = true;
      }
    });
    return property?.buildings.filter((b) => buildingIdMap[b?._id || '']) || [];
  }

  function getSelectedBuildings(): Building[] {
    const buildings = getSelectableBuildings();
    if (selectedBuildings.length > 0) {
      return buildings.filter((b) => selectedBuildings.includes(b?._id || ''));
    }
    return buildings;
  }

  // Get list of selectable floors, filtered base on selected unit types and buildings
  function getSelectableFloors(): Floor[] {
    const buildings = getSelectedBuildings();
    const floors: Floor[] = [];
    buildings.forEach((b) => {
      if (b?.floors && b.floors.length > 0) {
        floors.push(...b.floors);
      }
    });
    return floors;
  }

  function getSelectableCUGroups(): string[] {
    const property = getSelectedProperty();
    return property?.cuGroups || [];
  }

  function propertyHasMultipleUnitTypes() {
    return getSelectableUnitTypes().length > 1;
  }

  function propertyHasMultipleBuildings() {
    return getSelectableBuildings().length > 1;
  }

  function propertyHasMultipleFloors() {
    return getSelectableFloors().length > 1;
  }

  function propertyHasCUGroups() {
    return getSelectableCUGroups().length > 0;
  }

  function getFloorLabel(plural: boolean) {
    const label = getSelectedProperty()?.floorLabel || 'Floor';
    return pluralize(label, plural ? 2 : 1);
  }

  // Ordered list of bulk config steps, based on the selected property
  function getBulkThermostatsStepsForProperty(): BulkThermostatsStep[] {
    const steps = [BulkThermostatsStep.SelectProperty];
    if (propertyHasCUGroups()) {
      // If property has CU groups, only allow filtering based on those CU groups,
      // ignore any unit types, buildings, and floors
      steps.push(BulkThermostatsStep.SelectCUGroups);
    } else {
      if (propertyHasMultipleUnitTypes()) {
        steps.push(BulkThermostatsStep.SelectUnitTypes);
      }
      if (propertyHasMultipleBuildings()) {
        steps.push(BulkThermostatsStep.SelectBuildings);
      }
      if (propertyHasMultipleFloors()) {
        steps.push(BulkThermostatsStep.SelectFloors);
      }
    }
    steps.push(BulkThermostatsStep.ConfigureTstats);
    steps.push(BulkThermostatsStep.BulkVerify);
    steps.push(BulkThermostatsStep.BulkCommit);
    return steps;
  }

  const property = getSelectedProperty();
  if (!property || step === BulkThermostatsStep.SelectProperty) {
    return (
      <SelectProperty
        properties={properties}
        selectedProperty={selectedProperty}
        error={error}
        onSelectProperty={handleSelectProperty}
        onNext={handleNext}
      />
    );
  }

  switch (step) {
    case BulkThermostatsStep.SelectUnitTypes:
      return (
        <SelectUnitTypes
          unitTypes={getSelectableUnitTypes()}
          property={property}
          unitFilters={unitFilters}
          error={error}
          onSelectUnitType={handleSelectUnitType}
          toggleUnitTypes={toggleUnitTypes}
          onBack={handleBack}
          onNext={handleNext}
        />
      );
    case BulkThermostatsStep.SelectBuildings:
      return (
        <SelectBuildings
          buildings={getSelectableBuildings()}
          unitFilters={unitFilters}
          error={error}
          onSelectBuilding={handleSelectBuilding}
          toggleBuildings={toggleBuildings}
          onBack={handleBack}
          onNext={handleNext}
        />
      );
    case BulkThermostatsStep.SelectFloors:
      return (
        <SelectFloors
          floors={getSelectableFloors()}
          floorLabel={getFloorLabel(true)}
          buildings={getSelectedBuildings()}
          unitFilters={unitFilters}
          error={error}
          onSelectFloor={handleSelectFloor}
          toggleFloors={toggleFloors}
          onBack={handleBack}
          onNext={handleNext}
        />
      );
    case BulkThermostatsStep.SelectCUGroups:
      return (
        <SelectCUGroups
          cuGroups={getSelectableCUGroups()}
          units={getUnitsWithThermostats()}
          unitFilters={unitFilters}
          error={error}
          onSelectCUGroup={handleSelectCUGroup}
          toggleCUGroups={toggleCUGroups}
          onBack={handleBack}
          onNext={handleNext}
        />
      );
    case BulkThermostatsStep.ConfigureTstats:
      return (
        <ConfigureTstats
          setpointLimits={setpointsData?.getBulkSetpointLimits}
          tstatConfig={tstatConfig}
          tstatFilters={tstatFilters}
          error={error}
          onTstatConfigChange={handleTstatConfigChange}
          onTstatFilterChange={handleTstatFilterChange}
          onBack={handleBack}
          onNext={handleNext}
        />
      );
    case BulkThermostatsStep.BulkVerify:
      return (
        <BulkVerify
          validationResult={validateData?.validateBulkConfigThermostatsChanges}
          error={error}
          onBack={handleBack}
          onNext={handleNext}
        />
      );
    case BulkThermostatsStep.BulkCommit:
      return (
        <BulkCommit
          commitProgress={commitProgress}
          error={error}
          onTerminate={() => {
            terminateCommit = true;
          }}
          onRestart={handleRestart}
        />
      );
  }
}
