import React, { useEffect, useState } from 'react';

/* MUI */
import { Grid, Typography } from '@mui/material';

/* TYPES */
import {
  Property,
  useThermostatScheduleTemplateDetailQuery,
} from '../../../../../types/generated-types';

/* COMPONENTS */
import { ThermostatScheduleTemplateRowData } from '../../../devices/thermostat/components/schedule/thermostat-schedule';
import { ThermostatScheduleTemplateDetail } from '../../../devices/thermostat/components/schedule/thermostat-schedule-template-detail';

interface ViewPropertyScheduleTemplateProps {
  data: Property;
}

export function ViewPropertyScheduleTemplate(
  props: ViewPropertyScheduleTemplateProps,
) {
  /* State */
  const { data } = props;
  const [template, setTemplate] = useState<ThermostatScheduleTemplateRowData>();

  /* Data */
  const {
    data: templateData,
    loading: templateDataLoading,
    error: templateDataError,
  } = useThermostatScheduleTemplateDetailQuery({
    variables: { id: data?.thermostatScheduleTemplateId ?? '' },
    fetchPolicy: 'network-only',
  });

  /* HANDLERS */

  /* Property Schedule Template List */
  useEffect(() => {
    if (templateData?.thermostatScheduleTemplateById) {
      setTemplate(templateData.thermostatScheduleTemplateById);
    }
  }, [templateData]);

  return (
    <div>
      {templateDataLoading ? null : template ? (
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={{ textAlign: 'center', margin: '20px 0px' }}>
              Using Schedule Template: {template.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ThermostatScheduleTemplateDetail template={template} />
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={{ textAlign: 'center', margin: '20px 0px' }}>
              No Active Schedule Template
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
