import React, { useEffect } from 'react';
import {
  SetpointProfile,
  useRemoveSetpointProfileMutation,
  useSetpointProfileDetailQuery,
} from '../../../types/generated-types';
import { useNavigate, useParams } from 'react-router-dom';
import { SimpleSetpointProfileForm } from './ui/setpoint-limit-profile-detail-form';
import FullWidthLoadingSkeleton from '../shared/fullWidthLoadingSkeleton';
import { useAuthenticator } from '../../auth/AuthenticationContext';
import { Notifier } from '../../system/services/notificationManager';
import { useInjectableComponents } from '../../system/services/injectableComponentsManager';
import { Remove } from '@mui/icons-material';

export const SetpointLimitProfileDetail = ({ id }: { id?: string }) => {
  const { setpointProfileId } = useParams<{ setpointProfileId: string }>();
  const [profileId, setProfileId] = React.useState<string>();
  useEffect(() => {
    setProfileId(id ?? setpointProfileId ?? '');
  }, [id, setpointProfileId]);

  const { data, loading, error } = useSetpointProfileDetailQuery({
    variables: { _id: profileId ?? '' },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) {
      Notifier.error(
        'There was a problem fetching setpoint profile information: ',
        error,
      );
    }
  }, [error]);

  const [profile, setProfile] = React.useState<Partial<SetpointProfile>>();
  const auth = useAuthenticator();

  useEffect(() => {
    if (data) {
      setProfile(data.setpointProfileById as Partial<SetpointProfile>);
    }
  }, [data]);

  const navigate = useNavigate();

  const [
    removeSetpointProfile,
    {
      // TODO: Loading_error: do we need to do something with the data or loading here?
      // data: removedSetpointProfile,
      // loading: removeSetpointProfileLoading,
      error: removeSetpointProfileError,
    },
  ] = useRemoveSetpointProfileMutation();

  useEffect(() => {
    if (removeSetpointProfileError) {
      Notifier.error(
        'There was a problem removing the setpoint profile: ',
        removeSetpointProfileError,
      );
    }
  }, [removeSetpointProfileError]);

  const handleRemoveSetpointProfile = async () => {
    if (profileId) {
      try {
        await removeSetpointProfile({
          variables: { id: profileId },
        });
        Notifier.success('Setpoint limit profile deleted');
        navigate('/setpoint-profiles');
      } catch (e) {
        Notifier.error('Unable to delete Setpoint Limit Profile: ', e);
      }
      Notifier.success('Setpoint limit profile deleted');
    } else {
      Notifier.error('No setpoint profile id found: Check URL parameter');
    }
  };

  const confirmableAction = {
    actionLabel: 'Remove',
    cancelLabel: 'Cancel',
    title: 'Remove Setpoint Profile',
    prompt: 'Are you sure? This action cannot be reversed',
    action: handleRemoveSetpointProfile,
  };

  const { setContextMenuItems } = useInjectableComponents();

  useEffect(() => {
    if (profile) {
      setContextMenuItems([
        {
          items: [
            {
              id: 'remove-setpoint-profile-menu-item',
              label: 'Remove Setpoint Profile',
              icon: <Remove fontSize="small" />,
              confirmableAction,
              permit: {
                action: 'delete',
                subject: profile as SetpointProfile,
              },
            },
          ],
        },
      ]);
    } else {
      setContextMenuItems(undefined);
    }
    return () => {
      setContextMenuItems(undefined);
    };
  }, [profile]);

  return !auth?.user || loading ? (
    <FullWidthLoadingSkeleton />
  ) : profile ? (
    <SimpleSetpointProfileForm profile={profile} user={auth.user} />
  ) : profileId === undefined || profileId === '' ? (
    <SimpleSetpointProfileForm
      profile={{} as Partial<SetpointProfile>}
      user={auth.user}
    />
  ) : (
    <div>No data</div>
  );
};
