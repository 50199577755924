import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { Fade } from '@mui/material';

import { AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME } from '../../../helpers/config';

import { Notifier } from '../../system/services/notificationManager';
import { useAuthenticator } from '../AuthenticationContext';
import { useToken } from '../../system/SessionStateManager';

import { EmailInputField } from '../components/EmailInputField';
import { PasswordInputField } from '../components/PasswordInputField';

/**
 * This component is used in the 'reset password' workflow to accept the user's
 * new password, once they have satisfied some other identification 'factor'
 * such as clicking a link in their email or some other way of supplying the
 * required 'token' expected by the workflow. Currently, that token is provided
 * via a link sent to their email (in production auth mode via Stytch) or
 * contained in a link that is written out to the standard log (in development).
 * This component captures their desired password using a validated / checked
 * password capture field and, once a suitably complex password has been entered
 * and submitted, will send that password to the server via the authenticator.
 * If successful, the authenticator will log the user in and store their
 * authenticated user instance returned by the server in local storage. If
 * unsuccessful, the user stays on this form and is 'toasted' the error message.
 * @constructor
 */
export const AcceptNewPassword = () => {
  const auth = useAuthenticator();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialEmail = useToken<string>(AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME);

  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [passwordValid, setPasswordValid] = useState<boolean>(false);

  useEffect(() => {
    setFormValid(emailValid && passwordValid);
  }, [emailValid, passwordValid]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    const token = searchParams.get('token');

    if (email && password && token) {
      auth
        .resetPassword(email as string, password as string, token)
        .then(() => {
          Notifier.info(
            '[Password Reset] Successful! Reset complete and you are now logged in.',
          );
          navigate('/properties');
        })
        .catch((error) => {
          Notifier.error(`[Password Reset] Failed: ${error.message}.`);
        });
    } else {
      Notifier.error(
        '[Password Reset] Unable to reset password: Incomplete information. Provided',
      );
    }
  };

  return (
    <Fade
      style={{ transitionDuration: '500ms' }}
      in={true}
      mountOnEnter
      unmountOnExit
    >
      <Grid
        container
        spacing={2}
        style={{ width: 'unset', flexDirection: 'row' }}
      >
        <Grid item xs>
          &nbsp;
        </Grid>
        <Grid item style={{ padding: '0px' }}>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3, width: 'auto', padding: '0px' }}
          >
            <Grid item xs>
              <EmailInputField
                initialValue={initialEmail}
                onValidityChange={setEmailValid}
              />
            </Grid>
            <Grid item xs sx={{ mt: 2 }}>
              <PasswordInputField onValidityChange={setPasswordValid} />
            </Grid>
            <Button
              type="submit"
              disabled={!formValid}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Save New Password
            </Button>
          </Box>
        </Grid>
        <Grid item xs>
          &nbsp;
        </Grid>
        <Grid container width="100%">
          <Grid item xs>
            &nbsp;
          </Grid>
          <Grid item>
            <Link
              sx={{ cursor: 'pointer' }}
              component={Link}
              onClick={() => navigate('/login')}
              variant="body2"
            >
              Return to login screen
            </Link>
          </Grid>
          <Grid item xs>
            &nbsp;
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
};
