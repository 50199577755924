import React from 'react';

import { useFormik } from 'formik';

import { useTheme } from '@mui/material/styles';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  InputProps as StandardInputProps,
  Typography,
  useMediaQuery,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useRetrieveSmsNumberQuery } from '../../../../../types/generated-types';

import { FormikPhoneInput } from '../../../shared/formikPhoneInput';
import BorderedSection from '../../../shared/borderedSection';
import { FormValues } from '../../types/userProfileFormValues';
import { SmsOptInStatus } from '../../../../../common/types';

export interface AlertSmsConsentDialogProps {
  smsDialogOpen: boolean;
  handleConsent: () => void;
  onChange: StandardInputProps['onChange'];
  handleCancel: () => void;
  formik: ReturnType<typeof useFormik<FormValues>>;
}

export function AlertSmsConsentDialog(props: AlertSmsConsentDialogProps) {
  const { smsDialogOpen, handleConsent, onChange, handleCancel, formik } =
    props;
  const { data: smsData } = useRetrieveSmsNumberQuery();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return smsDialogOpen ? (
    <Dialog
      open={smsDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div
        id="alert-dialog-title"
        style={{ marginLeft: '22px', marginTop: '16px' }}
      >
        <span
          style={{
            fontSize: '18px',
            fontWeight: 'bolder',
          }}
        >
          Enable SMS Alerts
        </span>
      </div>
      <DialogContent
        sx={
          isSmall
            ? { margin: '2px ', padding: '2px 2px' }
            : { marginTop: '0px' }
        }
      >
        <div>
          {(formik.values.smsOptInStatus as SmsOptInStatus) ===
          SmsOptInStatus.stopped ? (
            <div
              style={{
                margin: isSmall ? '0px 0px 0px 0px' : '-10px 8px 6px 8px',
                // width: isSmall ? 'unset' : 'calc(100vw - 80px)',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                padding: '10px',
                border: '1px solid grey',
                borderRadius: '4px',
              }}
            >
              <div>
                You used the STOP command to opt out of SMS alerts. To re-enable
                receipt of SMS alerts on {props.formik.values.smsMobile}, send
                START as a text message from {props.formik.values.smsMobile} to{' '}
                {smsData?.retrieveSmsNumber}. Alternatively, to enable SMS
                messages for a different mobile number, change the mobile number
                in your profile on the {isSmall ? 'Profile' : 'Identification'}{' '}
                tab and then return here to this tab to enable SMS alerts for
                that new mobile number.
              </div>
              <Button
                fullWidth
                style={
                  isSmall
                    ? {
                        alignSelf: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        flex: '1 1 auto',
                        marginTop: '12px',
                        marginBottom: '0px',
                        width: '100%',
                        border: '1px solid darkgrey',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)',
                      }
                    : {
                        alignSelf: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: '8px',
                        maxWidth: '450px',
                        marginTop: '20px',
                        border: '1px solid darkgrey',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)',
                      }
                }
                variant="contained"
                color="primary"
                onClick={handleCancel}
              >
                Close
              </Button>
            </div>
          ) : (
            <div
              style={{
                margin: isSmall ? '0px 0px 0px 0px' : '-10px 8px 6px 8px',
                // width: isSmall ? 'unset' : 'calc(100vw - 80px)',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                padding: '10px',
                border: '1px solid grey',
                borderRadius: '4px',
              }}
            >
              <Typography variant="h5" pb={1.5}>
                Below, provide your mobile number, check the box, then click the
                'Enable' button to receive Alert notifications on your phone.
              </Typography>

              <BorderedSection
                title=""
                style={isSmall ? { marginLeft: '0px', marginRight: '0px' } : {}}
                ccStyle={
                  isSmall
                    ? {
                        display: 'flex',
                        flexDirection: 'column',
                        alignSelf: 'center',
                        // maxWidth: '610px',
                        marginTop: '0px',
                        marginBottom: '0px',
                      }
                    : {
                        display: 'flex',
                        flexDirection: 'column',
                        alignSelf: 'center',
                        // maxWidth: '610px',
                        marginTop: '4px',
                        marginBottom: '-24px',
                      }
                }
                error={!!formik.errors.validPasswordFactor}
                hint={formik.errors.validPasswordFactor || ''}
              >
                <FormGroup
                  style={{
                    marginTop: '4px',
                    marginLeft: isSmall ? '0px' : '6px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: isSmall ? 'column' : 'column',
                      width: '100%',
                    }}
                  >
                    {/*<div>*/}
                    {/*  <TextField*/}
                    {/*    fullWidth // ={isSmall}*/}
                    {/*    sx={{*/}
                    {/*      display: 'flex',*/}
                    {/*      flexDirection: isSmall ? 'row' : 'row',*/}
                    {/*      flex: isSmall ? 'unset' : '1 1 auto',*/}
                    {/*      marginBottom: isSmall ? '8px' : '16px',*/}
                    {/*    }}*/}
                    {/*    autoFocus*/}
                    {/*    id="mobile"*/}
                    {/*    name="mobile"*/}
                    {/*    placeholder="Enter Mobile Phone Number"*/}
                    {/*    label="Mobile Phone Number"*/}
                    {/*    value={formik.values.mobile}*/}
                    {/*    onChange={handleMobileChange}*/}
                    {/*    error={*/}
                    {/*      formik.touched.mobile && Boolean(formik.errors.mobile)*/}
                    {/*    }*/}
                    {/*    // helperText={formik.touched.mobile && formik.errors.mobile}*/}
                    {/*  />*/}
                    {/*  {formik.touched.mobile && formik.errors.mobile ? (*/}
                    {/*    <div*/}
                    {/*      style={{*/}
                    {/*        color: 'red',*/}
                    {/*        fontSize: '12px',*/}
                    {/*        marginTop: '-10px',*/}
                    {/*        marginBottom: '10px',*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      {formik.errors.mobile}*/}
                    {/*    </div>*/}
                    {/*  ) : null}*/}
                    {/*</div>*/}
                    <FormikPhoneInput
                      formik={formik}
                      label="Mobile"
                      fieldName="smsMobile"
                    />
                    <FormControlLabel
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: '12px',
                        },
                      }}
                      control={
                        <Checkbox
                          id="smsOptInConsent"
                          sx={{
                            alignSelf: 'flex-start',
                            fontSize: '10px',
                          }}
                          disabled={
                            formik.values.smsMobile?.length === 0 ||
                            !!formik.errors.smsMobile
                          }
                          checked={
                            formik.values.smsMobile?.length > 0 &&
                            !formik.errors.smsMobile &&
                            formik.values.smsOptInConsent
                          }
                          onChange={onChange}
                          color="primary"
                        />
                      }
                      label="I agree to receive SMS alert messages from Embue at the phone number provided above. I understand that message frequency varies depending on my account configuration, that message & data rates may apply and that I can opt out by replying STOP at any time."
                    />

                    <Button
                      fullWidth
                      disabled={formik.values.smsOptInConsent === false}
                      style={
                        isSmall
                          ? {
                              alignSelf: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              backgroundColor: formik.values.smsOptInConsent
                                ? '#3be73b'
                                : undefined,
                              color: formik.values.smsOptInConsent
                                ? 'black'
                                : undefined,
                              flex: '1 1 auto',
                              marginTop: '12px',
                              marginBottom: '0px',
                              width: '100%',
                              border: '1px solid #159015',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                            }
                          : {
                              alignSelf: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              backgroundColor: formik.values.smsOptInConsent
                                ? '#3be73b'
                                : undefined,
                              color: formik.values.smsOptInConsent
                                ? 'black'
                                : undefined,
                              border: '1px solid #159015',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                              marginLeft: '8px',
                              maxWidth: '450px',
                              marginTop: '20px',
                            }
                      }
                      variant="contained"
                      color="primary"
                      onClick={handleConsent}
                    >
                      {isSmall
                        ? 'Enable SMS Alerts'
                        : 'Click to enable SMS alerts'}
                    </Button>
                    <Button
                      fullWidth
                      style={
                        isSmall
                          ? {
                              alignSelf: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              flex: '1 1 auto',
                              marginTop: '12px',
                              marginBottom: '0px',
                              width: '100%',
                              border: '1px solid darkgrey',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)',
                            }
                          : {
                              alignSelf: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              marginLeft: '8px',
                              maxWidth: '450px',
                              marginTop: '20px',
                              border: '1px solid darkgrey',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)',
                            }
                      }
                      variant="contained"
                      color="primary"
                      onClick={handleCancel}
                    >
                      Cancel and Close
                    </Button>
                  </div>
                  <br />
                </FormGroup>
              </BorderedSection>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  ) : null;
}
