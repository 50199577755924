/**
 * ViewSelector
 * - UI component used by Unit component
 * - Renders drop-down view selector on small screens and tab selector on larger screens
 * - Used to select unit-detail edit.
 */
import React, { useEffect } from 'react';
import { ListItemText } from '@mui/material';

import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  IAuthorizer,
  useAuthorizer,
} from '../../../../auth/AuthorizationContext';
import { MyUnit } from '../unit-view';
import { ViewResidentContent } from './view-resident-content';
import { ViewUnitSetpointProfile } from './unit-view-setpoint-profile';
import { ViewZonePolicies } from './view-zone-policies-content';
import { ViewUnitContentProps } from '../../types/viewUnitContentProps';
import { ViewUnitScheduleTemplate } from './view-unit-schedule-template';

export type ViewOptionKey =
  | 'Resident'
  | 'Setpoint Profiles'
  | 'Zone Policies'
  | 'Schedule Template';

export interface ViewOption {
  type: ViewOptionKey;
  data?: any; // ViewUnitContentProps;
  view: (props: any) => JSX.Element | null;
  routeKey: string;
}

export const viewOptionsMap: Record<ViewOptionKey, ViewOption> = {
  Resident: {
    type: 'Resident',
    routeKey: 'resident',
    view: ViewResidentContent,
  },
  'Setpoint Profiles': {
    type: 'Setpoint Profiles',
    routeKey: 'setpoint-profiles',
    view: ViewUnitSetpointProfile,
  },
  'Zone Policies': {
    type: 'Zone Policies',
    routeKey: 'zone-policies',
    view: ViewZonePolicies,
  },
  'Schedule Template': {
    type: 'Schedule Template',
    routeKey: 'schedule-template',
    view: ViewUnitScheduleTemplate,
  },
};

export const authorizedViewOptions = (
  can: IAuthorizer['can'],
  unit: MyUnit,
): ViewOption[] => {
  let res: ViewOption[] = [];
  if (can('view', unit)) {
    res.push(viewOptionsMap['Resident']);
  }
  if (can('manage', unit)) {
    res = [
      ...res,
      viewOptionsMap['Setpoint Profiles'],
      viewOptionsMap['Zone Policies'],
      viewOptionsMap['Schedule Template'],
    ];
  }

  return res;
};

interface UnitViewDetailSelectorProps {
  selectedUnit?: MyUnit;
  value: ViewOption | undefined;
  changeHandler: (event?: any, newValue?: any) => void;
}

export function UnitViewDetailSelector(props: UnitViewDetailSelectorProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { value, changeHandler, selectedUnit } = props;
  const { can } = useAuthorizer();
  const [viewOptions, setViewOptions] = React.useState<ViewOption[]>([]);
  const selectHandler = (event: SelectChangeEvent<number>) => {
    const newVal = event.target.value as number;
    changeHandler(event, viewOptions[newVal].routeKey);
  };

  const startValue = value
    ? viewOptions.indexOf(value) >= 0
      ? viewOptions.indexOf(value)
      : 0
    : 0;

  useEffect(() => {
    setViewOptions(
      selectedUnit ? authorizedViewOptions(can, selectedUnit) : [],
    );
  }, [can, selectedUnit]);

  return selectedUnit && viewOptions.length ? (
    <FormControl fullWidth sx={{ padding: '0px 10px' }}>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        label="Unit Menu"
        variant="standard"
        onChange={selectHandler}
        // onClose={(event) => {
        //   const selectedIndex = (event.target as HTMLElement).getAttribute(
        //     'data-value',
        //   );
        //   const alreadySelected =
        //     (event.target as HTMLElement).getAttribute('aria-selected') ===
        //     'true';
        //   if (alreadySelected && selectedIndex === '2') {
        //     navigate(pathname.split('/').slice(0, -1).join('/'));
        //   }
        // }}
        value={startValue}
      >
        {viewOptions.map((option, index) => (
          <MenuItem key={index} value={index} sx={{ padding: '6px, 16px' }}>
            <ListItemText>{option.type}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : null;
}
