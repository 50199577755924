/**
 *
 */
export enum InstallationStatus {
  /* Server Set Codes - do not actively set - these codes are only
   * consumed through query and subscription updates
   */
  READY_TO_INSTALL = 0,
  NETWORK_OPEN = 1,
  NETWORK_OPEN_FAILED = 2,
  INSTALLATION_SUCCESSFUL = 3,
  INSTALLATION_FAILED = 4,
  CONFIGURATION_IN_PROGRESS = 8,
  CONFIGURATION_FAILED = 9,
  /* Client only status codes - do not push to server.  These codes denote
   * application state not described by device state codes.
   */
  ERROR_STATE = 10,
  SCAN_IN_PROGRESS = 11,
  SCAN_SUCCESSFUL = 12,
  SCAN_FAILED = 13,
  READY_TO_REPLACE = 14,
  NETWORK_OPEN_REQUESTED = 15,
}
