import React, { useState, ChangeEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import DoneIcon from '@mui/icons-material/Done';

import { Notifier } from '../../system/services/notificationManager';
import {
  useGetIngestionUploadInfoLazyQuery,
  useCreateIngestionMutation,
  useIngestionUpdatedSubscription,
  IngestionUploadInfo,
} from '../../../types/generated-types';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

enum FlowStatus {
  SELECTING = 'selecting',
  UPLOADING = 'uploading',
  IMPORTING = 'importing',
  PROCESSING = 'processing',
  COMPLETE = 'complete',
  ERROR = 'error',
}

/**
 * Allows us to treat the FlowStatus Enum like an array
 */
function flowStatusIndex(status: FlowStatus) {
  return Object.values(FlowStatus).indexOf(status);
}

/**
 * @returns array of FlowStatus items, with unwanted items filtered
 */
function listOfSteps() {
  return Object.keys(FlowStatus).filter(
    (f) =>
      FlowStatus[f as keyof typeof FlowStatus] !== FlowStatus.SELECTING &&
      FlowStatus[f as keyof typeof FlowStatus] !== FlowStatus.COMPLETE &&
      FlowStatus[f as keyof typeof FlowStatus] !== FlowStatus.ERROR,
  );
}

/**
 * IngestionUpload:  Handles the upload of ingestion spreadsheets to the S3 bucket, triggering the processing
 * of property data.  Displays a set of tools and instructions, as well as a progress report and error feedback.
 */
export function IngestionUpload(props: { cancelDialog: () => void }) {
  const { cancelDialog } = props;

  /* State */
  const [selectedFile, setSelectedFile] = useState<File>();
  const [currentFlowStatus, setCurrentFlowStatus] = useState<FlowStatus>(
    FlowStatus.SELECTING,
  );
  const [failedStatus, setFailedStatus] = useState<FlowStatus | undefined>();
  const [newIngestionId, setNewIngestionId] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [statusMsg, setStatusMsg] = useState<string[]>([]);

  /* Queries, Mutations, Subscriptions */
  const [createIngestion] = useCreateIngestionMutation();
  const [getUploadInfo] = useGetIngestionUploadInfoLazyQuery({
    fetchPolicy: 'network-only',
  });

  /**
   * Subscribe to updates for the newly triggered ingestion.
   * Update the UI according to data or error messages.
   **/
  useIngestionUpdatedSubscription({
    variables: {
      id: newIngestionId,
    },
    onError(error) {
      setErrorMsg(error.message);
      setFailedStatus(currentFlowStatus);
      setCurrentFlowStatus(FlowStatus.ERROR);
    },
    onData(options) {
      const { status, fileName } =
        options.data.data?.ingestionUpdatedByIds ?? {};
      if (!status) return;

      console.log('[ingestion-events] status -->', status);

      if (!status?.includes('[Error]')) {
        setStatusMsg((prev) => {
          const prevArr = [...prev];
          const lastEntry = prevArr.length - 1;
          if (prevArr.length > 0) {
            prevArr[lastEntry] = prevArr[lastEntry] + '[Done]';
          }
          return [...prevArr, status];
        });
      }

      if (status?.includes('[Error]')) {
        Notifier.error('Error ingesting ' + fileName);
        setStatusMsg((prev) => {
          const prevArr = [...prev];
          const lastEntry = prevArr.length - 1;
          if (prevArr.length > 0) {
            prevArr[lastEntry] = prevArr[lastEntry] + '[Failed]';
          }
          return [...prevArr];
        });
        setErrorMsg(status);
        setFailedStatus(currentFlowStatus);
        setCurrentFlowStatus(FlowStatus.ERROR);
      }

      if (status.includes('Importing')) {
        setCurrentFlowStatus(FlowStatus.IMPORTING);
      }

      if (status.includes('Processing')) {
        setCurrentFlowStatus(FlowStatus.PROCESSING);
      }

      if (status.includes('Property Ingested')) {
        setCurrentFlowStatus(FlowStatus.COMPLETE);
      }
    },
    skip: !newIngestionId,
    fetchPolicy: 'no-cache',
  });

  /* Util */
  function handleFileSelection(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  }

  /* used by the stepper component to determine whether a step was completed or failed */
  function isStepFailed(step: number) {
    if (failedStatus) {
      return step === flowStatusIndex(failedStatus);
    }
  }

  // Handle file submission
  async function handleSubmit() {
    setErrorMsg('');
    setCurrentFlowStatus(FlowStatus.UPLOADING);
    await getUploadInfo({
      async onCompleted(data) {
        if (data.getIngestionUploadInfo) {
          await createNewIngestion(data.getIngestionUploadInfo);
        }
      },
      onError(error) {
        console.error(error);
        setErrorMsg('Error loading required upload info');
        setFailedStatus(currentFlowStatus);
        setCurrentFlowStatus(FlowStatus.ERROR);
      },
    });
  }

  // Create new ingestion record in our database
  async function createNewIngestion(uploadInfo: IngestionUploadInfo) {
    const s3FileName = uuidv4() + '.xlsx';
    await createIngestion({
      variables: {
        record: {
          fileName: selectedFile?.name || '',
          s3FileName,
          date: new Date(),
          status: 'Uploading...',
        },
      },
      async onCompleted(data) {
        setNewIngestionId(data.createIngestion?._id || '');
        await uploadFile(uploadInfo, s3FileName);
      },
      onError(error) {
        console.error(error);
        setErrorMsg('Error creating new ingestion record');
      },
    });
  }

  // Upload spreadsheet to AWS S3
  async function uploadFile(
    uploadInfo: IngestionUploadInfo,
    s3FileName: string,
  ) {
    const { url, policy, credentials, date, signature } = uploadInfo;
    try {
      await axios.post(
        url,
        {
          key: s3FileName,
          acl: 'private',
          Policy: policy,
          'X-Amz-Credential': credentials,
          'X-Amz-Algorithm': 'AWS4-HMAC-SHA256',
          'X-Amz-Date': date,
          'X-Amz-Signature': signature,
          'Content-Type': selectedFile?.type,
          file: selectedFile,
        },
        {
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      /* Upon successful upload, local copy of the file is no longer needed. */
      setSelectedFile(undefined);
      Notifier.success('Uploaded file to S3. Ingestion in progress');
    } catch (error) {
      console.error(error);
      setErrorMsg(
        `Error uploading file to S3${
          error instanceof Error && ': ' && error.message
        }`,
      );
      setFailedStatus(currentFlowStatus);
      setCurrentFlowStatus(FlowStatus.ERROR);
    }
  }

  return (
    <Card sx={{ width: 400 }}>
      <CardContent>
        {/* --- Display messages for instructions, progress, or errors, based on the current flow status --- */}
        <Paper sx={{ px: 2, py: 1, my: 1 }}>
          {currentFlowStatus === FlowStatus.SELECTING && (
            <Typography gutterBottom variant={'body1'}>
              {selectedFile
                ? `Selected File: ${selectedFile.name}`
                : `Please select a property ingestion spreadsheet...`}
            </Typography>
          )}

          {currentFlowStatus === FlowStatus.UPLOADING && (
            <Typography gutterBottom variant={'body1'}>
              Uploading{selectedFile && ' ' + selectedFile.name}, please stand
              by...
            </Typography>
          )}

          {currentFlowStatus === FlowStatus.IMPORTING && (
            <>
              <Typography variant={'body1'}>
                Importing{selectedFile && ' ' + selectedFile.name}:
              </Typography>
              <ListMessages messages={statusMsg} />
            </>
          )}

          {currentFlowStatus === FlowStatus.PROCESSING && (
            <>
              <Typography variant={'body1'}>
                Processing{selectedFile && ' ' + selectedFile.name}:
              </Typography>
              <ListMessages messages={statusMsg} />
            </>
          )}
          {currentFlowStatus === FlowStatus.COMPLETE && (
            <>
              <Typography gutterBottom variant={'body1'}>
                Ingestion Complete
              </Typography>
              <ListMessages messages={statusMsg} />
            </>
          )}
          {currentFlowStatus === FlowStatus.ERROR && (
            <>
              <Typography gutterBottom variant={'body1'}>
                Ingestion Failed
              </Typography>
              <Typography gutterBottom variant={'body1'}>
                {errorMsg}
              </Typography>
              <ListMessages messages={statusMsg} />
            </>
          )}
        </Paper>
        <Box sx={{ pt: 2, width: '100%' }}>
          {/* --- Display a progress stepper showing the status of the ingestion workflow and completed or failed steps --- */}
          <Stepper
            activeStep={flowStatusIndex(currentFlowStatus)}
            alternativeLabel
            sx={{
              boxShadow: 1,
              padding: 1,
              '& .Mui-active .MuiSvgIcon-root': {
                color: '#54BDE1',
              },
              '& .Mui-completed .MuiSvgIcon-root': { color: '#80BB52' },
              '& .Mui-error .Mui-completed .MuiSvgIcon-root': {
                color: '#F26A4B',
              },
            }}
          >
            {listOfSteps().map((label, index) => {
              const stepProps: {
                style?: {};
              } = {};

              const labelProps: {
                optional?: React.ReactNode;
                error?: boolean;
              } = {};

              if (isStepFailed(index)) {
                labelProps.optional = (
                  <Typography variant="caption" color="#F26A4B">
                    FAILED
                  </Typography>
                );

                labelProps.error = true;

                stepProps.style = {
                  '& .Mui-error .Mui-completed': { color: '#F26A4B' },
                };
              }
              return (
                <Step sx={stepProps.style} key={index}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </CardContent>
      <CardActions sx={{ pb: 2, justifyContent: 'center' }}>
        <input
          hidden
          id="file-upload-input"
          accept=".xlsx"
          type="file"
          onChange={handleFileSelection}
        />
        {/* --- Display buttons for available actions, based on the status of the workflow --- */}
        <ButtonGroup>
          {currentFlowStatus === FlowStatus.SELECTING && (
            <>
              <Button
                startIcon={<SearchIcon />}
                color="secondary"
                variant="contained"
              >
                <label htmlFor="file-upload-input">Select File</label>
              </Button>
              <Button
                variant="contained"
                color="info"
                sx={{ display: selectedFile ? 'inherit' : 'none' }}
                startIcon={<UploadFileIcon />}
                onClick={handleSubmit}
              >
                Upload
              </Button>
              <Button
                variant="contained"
                color="error"
                startIcon={<CancelIcon />}
                onClick={cancelDialog}
              >
                Cancel
              </Button>
            </>
          )}
          {currentFlowStatus === FlowStatus.COMPLETE && (
            <Button
              variant="contained"
              color="success"
              startIcon={<CheckIcon />}
              onClick={cancelDialog}
            >
              OK
            </Button>
          )}
          {currentFlowStatus === FlowStatus.ERROR && (
            <Button
              variant="contained"
              color="info"
              startIcon={<CheckIcon />}
              onClick={cancelDialog}
            >
              OK
            </Button>
          )}
        </ButtonGroup>
      </CardActions>
    </Card>
  );
}

function ListMessages(props: { messages: string[] }) {
  return (
    <List dense>
      {props.messages.map((msg, index) => (
        <ListItem dense key={index}>
          <ListItemText>{msg}</ListItemText>
          <ListItemIcon>
            {msg.includes('[Done]') ? (
              <DoneIcon />
            ) : msg.includes('[Failed]') ? (
              <CancelIcon />
            ) : null}
          </ListItemIcon>
        </ListItem>
      ))}
    </List>
  );
}
