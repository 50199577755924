import React from 'react';
import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { LinearProgressProps } from '@mui/material/LinearProgress/LinearProgress';

export const ColoredLinearProgress = (props?: {
  color?: LinearProgressProps['color'];
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '14px',
        marginBottom: '8px',
        marginTop: '4px',
      }}
    >
      <LinearProgress color={props?.color || 'secondary'} />
    </Box>
  );
};
