import React, { useEffect, useState } from 'react';
import {
  ZonePolicyUpdateVerificationData,
  useZonePolicyUpdateVerificationQuery,
} from '../../../types/generated-types';
import BorderedSection from './borderedSection';
import { ZonePolicyUpdateVerification } from '../zone-policies/types/zonePolicyDetailFormValues';

export const VerifyZonePolicyUpdate = ({
  initialValues,
  values,
  force = false,
  dataReadyCallback,
}: {
  initialValues?: ZonePolicyUpdateVerification;
  values: ZonePolicyUpdateVerification;
  force?: boolean;
  dataReadyCallback: (dataReady: boolean) => void;
}) => {
  const policyId = initialValues?._id ?? values?._id;
  const [changedPropertyIds, setChangedPropertyIds] = useState<Array<string>>(
    [],
  );
  const [changedUnitId, setChangedUnitId] = useState<string | undefined>(
    undefined,
  );

  const {
    data,
    // TODO: Loading_error: do we need to handle loading/errors for this query?
    // error,
    // loading,
  } = useZonePolicyUpdateVerificationQuery({
    variables: {
      id: initialValues ? policyId ?? '0' : '',
      propertyIds: changedPropertyIds,
      unitId: changedUnitId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.zonePolicyUpdateVerification) {
      dataReadyCallback(true);
    }
  }, [data]);

  useEffect(() => {
    if (force || values?._id === '0') {
      setChangedUnitId(values.unitId ?? undefined);
      setChangedPropertyIds(values.properties ?? []);
    } else if (initialValues) {
      if (values.unitId || initialValues.unitId) {
        if (values.unitId !== initialValues.unitId) {
          setChangedUnitId(values.unitId);
        } else {
          setChangedUnitId(undefined);
        }
      } else {
        let changedPropIds;

        const initialPropIds = (values.properties ?? []) as Array<string>;
        const newPropIds = (initialValues.properties ?? []) as Array<string>;
        changedPropIds = newPropIds.filter(
          (id) => !initialPropIds.includes(id),
        );

        setChangedPropertyIds(changedPropIds ?? []);
      }
    } else {
      setChangedUnitId(values.unitId ?? undefined);
      setChangedPropertyIds(values.properties ?? []);
    }
  }, [values, force]);

  return data?.zonePolicyUpdateVerification ? (
    <div>
      <h3>Should you proceed, your update will have the following impact:</h3>
      <BorderedSection title="Affected Zone Statistics">
        <div>
          <ul>
            <li>
              <strong>All possible affected Zones:</strong>{' '}
              {data.zonePolicyUpdateVerification.totalZones}
            </li>
            <li>
              <strong>Zones that are online:</strong>{' '}
              {data.zonePolicyUpdateVerification.onlineZones}
            </li>
            <li>
              <strong>
                Online Zones using a different, unit-specified policy:
              </strong>{' '}
              {data.zonePolicyUpdateVerification.excludedZones}
            </li>
            <li>
              <strong>Net Zones to be updated:</strong>{' '}
              {(data.zonePolicyUpdateVerification.onlineZones ?? 0) -
                (data.zonePolicyUpdateVerification.excludedZones ?? 0)}
            </li>
          </ul>
        </div>
      </BorderedSection>
    </div>
  ) : (
    <div>Data loading ...</div>
  );
};
