import React from 'react';
import { RouteObject, Navigate } from 'react-router-dom';
import { AuthenticateUser } from '../auth/workflows/AuthenticateUser';
import { ResetPassword } from '../auth/workflows/ResetPassword';
import { LoggingOut } from '../features/logging-out/logging-out';
import { NotFound } from '../features/not-found/not-found';
import { AuthorizedRoute } from './app-routes';
import { PrivacyPolicy } from '../features/smart/privacyPolicy';

export const smartRoutes: RouteObject[] = [
  {
    path: '/smart/login',
    element: <AuthenticateUser />,
    // children: [
    //   {
    //     path: '/',
    //     element: <NotFound />,
    //   },
    //   {
    //     path: 'privacy-policy',
    //     element: <PrivacyPolicy />,
    //   },
    //   {
    //     path: 'login',
    //     element: <AuthenticateUser />,
    //   },
    //   {
    //     path: 'auth/passwords/reset',
    //     element: <ResetPassword />,
    //   },
    //   {
    //     path: 'logging-out',
    //     element: <LoggingOut />,
    //   },
    //   {
    //     path: '*',
    //     element: window.location.pathname.endsWith('login') ? (
    //       <AuthenticateUser />
    //     ) : (
    //       <NotFound />
    //     ),
    //   },
    // ],
  },
];

//
// export const smartRoutes: RouteObject[] = [
//   {
//     path: '/smart',
//     children: [
//       {
//         path: '/',
//         element: <NotFound />,
//       },
//       {
//         path: 'privacy-policy',
//         element: <PrivacyPolicy />,
//       },
//       {
//         path: 'login',
//         element: <AuthenticateUser />,
//       },
//       {
//         path: 'auth/passwords/reset',
//         element: <ResetPassword />,
//       },
//       {
//         path: 'logging-out',
//         element: <LoggingOut />,
//       },
//       {
//         path: '*',
//         element: window.location.pathname.endsWith('login') ? (
//           <AuthenticateUser />
//         ) : (
//           <NotFound />
//         ),
//       },
//     ],
//   },
// ];
