import * as React from 'react';
import {
  useSetpointProfileCountQuery,
  useSetpointProfileListUpdateSubscription,
} from '../../../types/generated-types';
import { updateCacheFromSubscriptionEvent } from '../../../helpers/subscriptionUtils';

export function SetpointLimitProfilesBreadcrumb() {
  useSetpointProfileListUpdateSubscription({
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });
  const { data } = useSetpointProfileCountQuery();

  return (
    <span>
      Setpoint Limit Profiles (
      {`${
        data?.setpointProfileCount
          ? data.setpointProfileCount
          : 'No Setpoint Limit Profiles'
      }`}
      )
    </span>
  );
}
