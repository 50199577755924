import React from 'react';
import { AuthenticationPanel } from '../../auth/components/AuthenticationPanel';

export function LoggingOut() {
  return (
    <AuthenticationPanel title="Logging Out ...">
      <div style={{ height: '164px' }}>&nbsp;</div>
    </AuthenticationPanel>
  );
}
