import React from 'react';
import UsersList from './users-list';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export function UsersMain() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      style={{
        margin: isSmall ? '0px' : '10px 4px',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      <UsersList />
    </div>
  );
}
