import React from 'react';
import { RouteObject, Navigate } from 'react-router-dom';
import { AuthenticateUser } from '../auth/workflows/AuthenticateUser';
import { ResetPassword } from '../auth/workflows/ResetPassword';
import { LoggingOut } from '../features/logging-out/logging-out';
import { SMSWorkflow } from '../features/compliance/smsWorkflow';

export const authRoutes: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        path: '/',
        element: <Navigate to="/login" replace />,
      },
      {
        path: 'login',
        element: <AuthenticateUser />,
      },
      {
        path: 'auth/passwords/reset',
        element: <ResetPassword />,
      },
      {
        path: 'logging-out',
        element: <LoggingOut />,
      },
      {
        path: '/sms-workflow',
        element: <SMSWorkflow />,
      },
      {
        path: '*',
        element: window.location.pathname.endsWith('login') ? (
          <AuthenticateUser />
        ) : (
          <Navigate to="/login" replace />
        ),
      },
    ],
  },
];
