import EJSON from 'ejson';
import {
  CONNECTION_STATE_TRANSITIONING_KEY,
  CONNECTION_STATE_TRANSITIONING_DELAY,
  LOGGING_OUT_KEY,
  LOGGING_OUT_TRANSITIONING_DELAY,
} from '../../../helpers/config';

export interface ILocalStorageListener {
  handleLocalStorageChange(itemName: string, value: any): void;
}

/**
 * This class provides a wrapper around local storage functions, giving us some
 * level of indirection/isolation so that if we need to do something 'different'
 * on Capacitor mobile devices, we can just deal with it in this class rather than
 * have to pepper the code with changes and conditional logic.
 */
class LocalStateManager {
  private storage = localStorage;
  private listeners: ILocalStorageListener[] = [];

  private notifyListeners(itemName: string, value: any): void {
    this.listeners.forEach((listener) =>
      listener.handleLocalStorageChange(itemName, value),
    );
  }

  public registerListener(listener: ILocalStorageListener | null): void {
    if (listener) {
      this.listeners.includes(listener) || this.listeners.push(listener);
    }
  }

  public unregisterListener(listener: ILocalStorageListener | null): void {
    if (listener) {
      const index = this.listeners.indexOf(listener);
      if (index > -1) {
        this.listeners.splice(index, 1);
      }
    }
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  public setItem(itemName: string, value: any): LocalStateManager {
    if (value?.remove === true || value?.remove === 'true') {
      this.storage.removeItem(itemName);
      this.notifyListeners(itemName, undefined);
      return this;
    }
    if (
      value?.expiration !== undefined &&
      value?.expiration !== null &&
      typeof value?.expiration === 'number' &&
      value?.expiration < Date.now()
    ) {
      this.storage.removeItem(itemName);
      this.notifyListeners(itemName, undefined);
      return this;
    }

    if (typeof value?.value === 'string' && value?.value?.length === 0) {
      this.storage.removeItem(itemName);
      this.notifyListeners(itemName, undefined);
      return this;
    }

    if (
      value?.$type === 'ServerToken' &&
      typeof value?.$value === 'object' &&
      value?.$value?.value === undefined
    ) {
      this.storage.removeItem(itemName);
      this.notifyListeners(itemName, undefined);
      return this;
    }

    this.storage.setItem(itemName, EJSON.stringify(value));
    this.notifyListeners(itemName, value);
    return this;
  }

  // TODO: Peter: do we still need this?
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  // public setItems(itemMap: { [itemName: string]: any }): LocalStateManager {
  //   for (const [itemName, value] of Object.entries(itemMap)) {
  //     this.setItem(itemName, value);
  //   }
  //
  //   return this;
  // }

  public getItem<T>(itemName: string): T | undefined {
    const val: string | null = this.storage.getItem(itemName);
    try {
      const newVal =
        val === null || val === undefined || val === 'undefined'
          ? undefined
          : (EJSON.parse(val) as T);
      return newVal;
    } catch (e) {
      return undefined;
    }
  }

  public ensureItem<T>(itemName: string, defaultValue: T): T {
    const value = this.getItem<T>(itemName);
    if (value === undefined || value === null) {
      this.setItem(itemName, defaultValue);
      this.notifyListeners(itemName, defaultValue);
    }
    return value ?? defaultValue;
  }

  public itemExists(itemName: string): boolean {
    return !!(this.storage.getItem(itemName) ?? false);
  }

  public removeItem(itemName: string): LocalStateManager {
    this.storage.removeItem(itemName);
    this.notifyListeners(itemName, undefined);
    return this;
  }

  public removeItems(itemNames: string[]): LocalStateManager {
    for (const itemName of itemNames) {
      this.storage.removeItem(itemName);
      this.notifyListeners(itemName, undefined);
    }
    return this;
  }

  public setLoggingOutState(
    postCallback?: () => void,
    main?: () => void,
    preCallback?: () => void,
  ) {
    this.setItem(LOGGING_OUT_KEY, true);
    if (preCallback) {
      preCallback();
    }
    if (main) {
      setTimeout(main, 1000);
    }
    setTimeout(() => {
      this.removeItem(LOGGING_OUT_KEY);
      if (postCallback) {
        postCallback();
      }
    }, LOGGING_OUT_TRANSITIONING_DELAY);
  }

  public transitionConnectionState() {
    this.setItem(CONNECTION_STATE_TRANSITIONING_KEY, true);
    setTimeout(() => {
      this.removeItem(CONNECTION_STATE_TRANSITIONING_KEY);
    }, CONNECTION_STATE_TRANSITIONING_DELAY);
  }

  public authStateTransitioning(): boolean {
    return this.itemExists(CONNECTION_STATE_TRANSITIONING_KEY);
  }
}

export const LocalState = new LocalStateManager();
