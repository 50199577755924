import Typography from '@mui/material/Typography';
import { Box, Container, Grid, Stack } from '@mui/material';
import { defaultAbsoluteLimits } from '../../../helpers';
import React from 'react';
import { SetpointProfile } from '../../../../../types/generated-types';
import {
  Celsius,
  Fahrenheit,
  TemperatureUnit,
} from '../../../../system/models/temperatureUnits';
import { useTheme } from '@mui/material/styles';
import { useAuthenticator } from '../../../../auth/AuthenticationContext';
import { convertToTempUnits } from '../../../devices/util/convert-temp-units';
import { Slider } from '../../../devices/thermostat/components/system-edit/slider';
import { ViewUnitContentProps } from '../../types/viewUnitContentProps';

export const ViewUnitSetpointProfile = ({
  unit,
  property,
}: ViewUnitContentProps) => {
  const theme = useTheme();
  const [preferredUnits, setPreferredUnits] =
    React.useState<TemperatureUnit>(Fahrenheit);
  const { user } = useAuthenticator();

  React.useEffect(() => {
    if (user?._id) {
      setPreferredUnits(user?.preferredUnits ?? Fahrenheit);
    }
  }, [user]);

  const defaultProfile: SetpointProfile = {
    _id: '0',
    name: 'System Default Setpoint Limit Profile',
    ...defaultAbsoluteLimits[Celsius],
  } as SetpointProfile;

  const selectedProfileId =
    unit?.setpointProfileId ?? property?.setpointProfileId ?? '0';

  const allProfiles: SetpointProfile[] = [
    ...(property?.assignableSetpointProfiles ?? []),
    defaultProfile,
  ];

  const selectedProfile = allProfiles.find((p) => p._id === selectedProfileId);

  return unit && property ? (
    <Container>
      {[selectedProfile].map((profile, index) => {
        return profile ? (
          <div key={index}>
            <Typography
              sx={{
                marginTop: '20px',
                marginBottom: '30px',
                textAlign: 'center',
              }}
            >
              {`Using Setpoint Profile: ${profile?.name ?? 'N/A'}`}
            </Typography>
            <Box
              sx={{
                justifyContent: 'center',
                px: 4,
                py: 2,
              }}
            >
              <Stack
                spacing={2}
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Typography variant="button">
                  {defaultAbsoluteLimits[preferredUnits].heat.min}
                </Typography>
                <Slider
                  getAriaLabel={() => 'Heat Setpoint Limits'}
                  value={[
                    convertToTempUnits(
                      profile?.heat?.min ?? 0,
                      preferredUnits,
                    ) ?? 0,
                    convertToTempUnits(
                      profile?.heat?.max ?? 0,
                      preferredUnits,
                    ) ?? 0,
                  ]}
                  min={defaultAbsoluteLimits[preferredUnits].heat.min}
                  max={defaultAbsoluteLimits[preferredUnits].cool.max}
                  valueLabelDisplay="on"
                  disableSwap
                  sx={{
                    color: theme.embue.heating,
                    py: 2,
                  }}
                />
                <Typography variant="button">
                  {defaultAbsoluteLimits[preferredUnits].cool.max}
                </Typography>
              </Stack>

              <Stack
                spacing={2}
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Typography variant="button">
                  {defaultAbsoluteLimits[preferredUnits].heat.min}
                </Typography>
                <Slider
                  getAriaLabel={() => 'Cool Setpoint Limits'}
                  value={[
                    convertToTempUnits(
                      profile?.cool?.min ?? 0,
                      preferredUnits,
                    ) ?? 0,
                    convertToTempUnits(
                      profile?.cool?.max ?? 0,
                      preferredUnits,
                    ) ?? 0,
                  ]}
                  min={defaultAbsoluteLimits[preferredUnits].heat.min}
                  max={defaultAbsoluteLimits[preferredUnits].cool.max}
                  valueLabelDisplay="on"
                  disableSwap
                  sx={{
                    color: theme.embue.cooling,
                    py: 2,
                  }}
                />
                <Typography variant="button">
                  {defaultAbsoluteLimits[preferredUnits].cool.max}
                </Typography>
              </Stack>
            </Box>
          </div>
        ) : (
          <Grid container key={5000}>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: 'center', margin: '20px 0px' }}>
                No Active Setpoint Profile is Applied
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Container>
  ) : null;
};
