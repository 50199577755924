/**
 * Unit View
 * - used specifically for displaying unit information
 * - includes several tabs for different information sections
 */
import React, { useEffect } from 'react';
/* TYPES */
import {
  Property,
  PropertyDetailEditQuery,
  Unit,
  UnitDetailEditQuery,
  usePropertyDetailEditQuery,
  useUnitDetailEditQuery,
} from '../../../../types/generated-types';
/* NAVIGATION */
import { useLocation, useNavigate, useParams } from 'react-router-dom';
/* ALERTS */
import { Notifier } from '../../../system/services/notificationManager';
/* MUI */
import {
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
  Tab,
  Box,
} from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
/* HELPERS */
import { UnitDetailHeader } from './unit-detail-header';
import {
  UnitViewDetailSelector,
  viewOptionsMap,
  ViewOption,
} from './view/unit-view-detail-selector';
import { UnitViewDetailPanel } from './view/unit-view-detail-panel';
/* CONTEXT MENU */
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../../system/services/injectableComponentsManager';

/* ICONS */
import EditIcon from '@mui/icons-material/Edit';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import { basePropertyMenuItems } from '../../properties/ui/base-property-context-menu';
import { useAuthorizer } from '../../../auth/AuthorizationContext';
import FullWidthLoadingSkeleton from '../../shared/fullWidthLoadingSkeleton';

export type MyUnit = UnitDetailEditQuery['unitById'];
export type MyProperty = PropertyDetailEditQuery['propertyById'];

export function UnitView() {
  const [property, setProperty] = React.useState<MyProperty>();
  const [unit, setUnit] = React.useState<MyUnit>();

  const [value, setValue] = React.useState('resident');
  const [selectedViewOption, setSelectedViewOption] =
    React.useState<ViewOption>(viewOptionsMap.Resident);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const { can, cannot } = useAuthorizer();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { search } = useLocation();

  const { id, unitId } = useParams<{ id: string; unitId: string }>();

  const {
    setSubtitle,
    setTitle,
    setContextMenuItems,
    setTitlePath,
    setSubtitlePath,
    setPrimaryBottomNavigationWidget,
    setSubtitleActionWidget,
  } = useInjectableComponents();

  const {
    data: unitData,
    error: unitError,
    loading: unitLoading,
  } = useUnitDetailEditQuery({
    variables: { uid: unitId ?? '' },
  });

  useEffect(() => {
    if (unitData) {
      setUnit(unitData.unitById);
    }
  }, [unitData]);

  useEffect(() => {
    if (unitError) {
      Notifier.error(
        'There was a problem fetching unit information: ',
        unitError,
      );
    }
  }, [unitError]);

  const {
    data: propertyData,
    error: propertyError,
    loading: propertyLoading,
  } = usePropertyDetailEditQuery({
    variables: { id: id ?? '' },
  });

  React.useEffect(() => {
    if (propertyData?.propertyById) {
      setProperty(propertyData.propertyById as Property);
    }
  }, [propertyData]);

  useEffect(() => {
    if (propertyError) {
      Notifier.error(
        'There was a problem fetching the property information: ',
        propertyError,
      );
    }
  }, [propertyError]);

  useEffect(() => {
    if (property && unit) {
      const contextMenuItems: BottomMenuItems = [
        ...basePropertyMenuItems(property._id, pathname),
        {
          label: 'Unit Actions',
          items: [
            {
              id: 'edit-unit-menu-item',
              label: 'Edit Unit Details',
              icon: <EditIcon fontSize="medium" />,
              action: () => {
                navigate(
                  `/properties/${property._id}/units/${unit._id}/edit-unit`,
                );
              },
              permit: {
                action: 'update',
                subject: unit as Unit,
              },
            },
            {
              id: 'manage-unit-menu-item',
              label: 'Manage Unit / View Devices',
              icon: <AutoAwesomeMosaicIcon fontSize="medium" />,
              action: () => {
                navigate(`/properties/${property._id}/units/${unit._id}`);
              },
              permit: {
                action: 'update',
                subject: unit as Unit,
              },
            },
          ],
        },
      ];

      setTitle(property.title ?? 'Untitled Property');
      setTitlePath((path: string) => `/properties/${property._id}/units`);

      setSubtitle(`${unit.name ?? 'Unnamed Unit'} - Viewing Details`);
      setSubtitlePath(
        (path: string) => `/properties/${property._id}/units/${unit._id}`,
      );

      setContextMenuItems(contextMenuItems, unit as Unit);
    }
    return () => {
      setTitle(undefined);
      setTitlePath(undefined);
      setSubtitle(undefined);
      setSubtitlePath(undefined);
      setContextMenuItems(undefined, undefined);
    };
  }, [unit, property, pathname]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    let view = queryParams.get('view') ?? 'resident';

    if (view === 'zone-policies' && cannot('manage', 'ZonePolicy')) {
      view = 'resident';
    } else if (
      view === 'setpoint-profiles' &&
      cannot('manage', 'SetpointProfile')
    ) {
      view = 'resident';
    }

    const newValue =
      Object.values(viewOptionsMap).find(
        (option) => option.routeKey === view,
      ) ?? viewOptionsMap['Resident'];
    setSelectedViewOption(newValue);
    setValue(newValue.routeKey);
  }, [search]);

  const handleViewChange = React.useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
      navigate(`?view=${newValue}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (isSmall) {
      if (property && unit) {
        setPrimaryBottomNavigationWidget(
          <div style={{ paddingTop: '8px', width: '100%' }}>
            <UnitViewDetailSelector
              selectedUnit={unit}
              value={selectedViewOption}
              changeHandler={handleViewChange}
            />
          </div>,
        );
      }
      return () => {
        setPrimaryBottomNavigationWidget(undefined);
      };
    }
  }, [property, unit, selectedViewOption, handleViewChange, isSmall]);

  const loading = propertyLoading || unitLoading;

  return loading ? (
    <FullWidthLoadingSkeleton />
  ) : unit && property ? (
    <Card
      elevation={4}
      sx={{
        background: 'inherit',
        margin: isSmall ? '0px' : '20px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        '& .MuiCardHeader-root': {
          padding: '4px 10px!important',
          borderBottom: '1px solid darkgray',
          borderRight: isSmall ? '1px solid lightgray' : 'inherit',
          boxShadow: '0px 8px 6px -6px rgba(0, 0, 0, 0.35)',
        },
        '& .MuiCardContent-root': {
          flex: 1,
          borderRight: isSmall ? '1px solid lightgray' : 'inherit',
          paddingTop: '0px !important',
          padding: isSmall ? '0px !important' : 'inherit',
        },
      }}
    >
      <UnitDetailHeader
        unitLoading={unitLoading}
        propertyLoading={propertyLoading}
        propertyId={property._id}
        unitId={unit._id}
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          p: 0,
          background: 'inherit',
          paddingBottom: '0px !important',
        }}
      >
        {!isSmall ? (
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                centered
                onChange={handleViewChange}
                aria-label="Unit View Tabs"
                indicatorColor="secondary"
                textColor="secondary"
              >
                <Tab
                  label="Resident"
                  value={viewOptionsMap.Resident.routeKey}
                />
                {can('manage', 'SetpointProfile') ? (
                  <Tab
                    label={isSmall ? 'Profile' : 'Setpoint Limit Profile'}
                    value={viewOptionsMap['Setpoint Profiles'].routeKey}
                  />
                ) : null}
                {can('manage', 'ZonePolicy') ? (
                  <Tab
                    label={isSmall ? 'Policy' : 'Zone Policies'}
                    value={viewOptionsMap['Zone Policies'].routeKey}
                  />
                ) : null}
                <Tab
                  label="Schedule Template"
                  value={viewOptionsMap['Schedule Template'].routeKey}
                />
              </TabList>
            </Box>
            <div
              style={{
                margin: isSmall ? '0px' : '10px 4px',
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                alignItems: 'stretch',
              }}
            >
              <UnitViewDetailPanel
                theme={theme}
                viewOption={selectedViewOption}
                unit={unit}
                property={property}
              />
            </div>
          </TabContext>
        ) : (
          <UnitViewDetailPanel
            theme={theme}
            viewOption={selectedViewOption}
            unit={unit}
            property={property}
          />
        )}
      </CardContent>
    </Card>
  ) : null;
}
