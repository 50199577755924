import React, { useState } from 'react';
import { Container, Skeleton, Typography } from '@mui/material';

import {
  usePropertySummaryUnitsQuery,
  PropertySummaryUnits,
} from '../../../../../types/generated-types';

import { Notifier } from '../../../../system/services/notificationManager';
import { ViewPropertyContentProps } from '../../types/viewPropertyContentProps';

export function PropertySummaryView({ data }: ViewPropertyContentProps) {
  const propertyId = data?._id || '';

  const [units, setUnits] = useState<PropertySummaryUnits>();

  const { loading } = usePropertySummaryUnitsQuery({
    variables: {
      propertyId,
    },
    skip: !propertyId,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (data.propertySummaryUnits) {
        setUnits(data.propertySummaryUnits);
      }
    },
    onError(error) {
      console.error(error);
      Notifier.error('Error loading unit summary info');
    },
  });

  const residential = units?.units[0];
  const commonArea = units?.units[1];
  const centralEquipment = units?.units[2];

  function unitOrUnits(count: number) {
    return count === 1 ? 'Unit' : 'Units';
  }

  const residentialName = `Residential ${unitOrUnits(residential?.count || 0)}`;
  const commonAreaName = `Common Area ${unitOrUnits(commonArea?.count || 0)}`;
  const centralEquipmentName = `Central Equipment ${unitOrUnits(
    centralEquipment?.count || 0,
  )}`;

  const totalCount =
    (residential?.count || 0) +
    (commonArea?.count || 0) +
    (centralEquipment?.count || 0);
  const subheader = `${totalCount} ${unitOrUnits(totalCount)}`;

  return (
    <div>
      <Typography>
        {loading ? <Skeleton /> : `Unit Count: ${subheader}`}
      </Typography>
      <br />
      <Container>
        <div>
          <span style={{ fontWeight: 'bold' }}>{residential?.count}</span>
          <span> {residentialName}</span>
        </div>
        <div>
          <span style={{ fontWeight: 'bold' }}>{commonArea?.count}</span>
          <span> {commonAreaName}</span>
        </div>
        <div>
          <span style={{ fontWeight: 'bold' }}>{centralEquipment?.count}</span>
          <span> {centralEquipmentName}</span>
        </div>
      </Container>
    </div>
  );
}
