import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Notifier } from '../../../system/services/notificationManager';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';

import { PropertyDetailForm } from '../property-detail-form';
import FullWidthLoadingSkeleton from '../../shared/fullWidthLoadingSkeleton';

import {
  Property,
  usePropertyDetailEditQuery,
  usePropertyWideAlertSubscribersSubscription,
} from '../../../../types/generated-types';

export const PropertyDetailHandler = () => {
  const { id, viewId } = useParams<{ id: string; viewId: string }>();
  const { data, error, loading } = usePropertyDetailEditQuery({
    variables: { id: id ?? '' },
    fetchPolicy: 'network-only',
  });

  usePropertyWideAlertSubscribersSubscription({
    variables: { propertyId: id ?? '' },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (error) {
      Notifier.error(
        'There was a problem fetching property information: ',
        error,
      );
    }
  }, [error]);

  const [property, setProperty] = useState<Partial<Property>>();
  useEffect(() => {
    if (data?.propertyById) {
      setProperty(data.propertyById as Partial<Property>);
    }
  }, [data]);

  return loading ? (
    <FullWidthLoadingSkeleton />
  ) : property ? (
    <PropertyDetailForm property={property} loading={loading} viewId={viewId} />
  ) : id === undefined ? (
    <PropertyDetailForm property={{} as Partial<Property>} />
  ) : (
    <div>No data</div>
  );
};
