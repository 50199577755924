import React from 'react';
import { Container, Divider, Stack, Typography } from '@mui/material';
import { PropertySummaryView } from './property-summary-view';
import { ViewPropertyContentProps } from '../../types/viewPropertyContentProps';

export function PropertyLocationContent({ data }: ViewPropertyContentProps) {
  const { address, propMgr, owner } = data || {};
  const { line1, city, state, postalCode } = address || {};
  const addressLine = `${line1 || 'Unknown Address'}`;
  const cityStateZip = `${city || 'Unknown City'}, ${state || '??'}  ${
    postalCode || '00000'
  }`;
  const fullAddress = `${addressLine}, ${cityStateZip}`;

  return (
    <Container
      style={{
        marginBottom: '30px',
      }}
    >
      <Typography>Address</Typography>
      <Stack direction="column" sx={{ p: '24px' }}>
        <span>{fullAddress}</span>
        <span>Manager: {propMgr?.name ?? 'N/A'}</span>
        <span>Owner: {owner?.name ?? 'N/A'}</span>
      </Stack>
      <br />
      <Divider />
      <br />
      <PropertySummaryView data={data} />
    </Container>
  );
}
