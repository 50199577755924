/**
 * DeviceHealthBar
 * - displays the summary alert status of a unit or property
 * - typically positioned at the start of a list item line
 * - has a default state of gray to indicate no props
 * - TODO: add segment for not-installed devices
 */

import React from 'react';
import { isNumber } from 'lodash';
import { styled, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

interface DeviceHealthBarProps {
  devicesCount?: number | null;
  majorAlerts?: number | null;
  minorAlerts?: number | null;
  height?: number;
}

export function DeviceHealthBar(props: DeviceHealthBarProps) {
  /* map alerts to colors */
  const theme = useTheme();
  const height = props.height ?? 35;
  const { red, yellow, green, gray } = (({
    devicesCount,
    majorAlerts,
    minorAlerts,
  }) => {
    const colorMap = { red: 0, yellow: 0, green: 0, gray: 0 };

    /* NAN condition - undefined | null | ... */
    if (
      !isNumber(devicesCount) ||
      !isNumber(majorAlerts) ||
      !isNumber(minorAlerts)
    ) {
      return {
        ...colorMap,
        gray: 100,
      };
    }

    /* normal non-zero alert condition */
    if (devicesCount > 0) {
      return {
        ...colorMap,
        red: (majorAlerts / devicesCount) * 100,
        yellow: (minorAlerts / devicesCount) * 100,
        green:
          ((devicesCount - majorAlerts - minorAlerts) / devicesCount) * 100,
      };
    }

    /* normal no alerts condition */
    if (devicesCount === 0) {
      return {
        ...colorMap,
        gray: 100,
      };
    }

    return colorMap;
  })(props);

  return (
    <Stack direction={'column'} spacing={0} sx={{ height: `${height}px` }}>
      <Segment
        message={`${red}% of devices in this unit show major alerts`}
        portion={red}
        color={theme.embue.bad}
      />
      <Segment
        message={`${yellow}% of devices in this unit show minor alerts`}
        portion={yellow}
        color={theme.palette.warning.main}
      />
      <Segment
        message={
          green === 100
            ? `No alerts on any devices in this unit`
            : `${green}% of devices in this unit show no alerts`
        }
        portion={green}
        color={theme.embue.good}
      />
      <Segment
        message={
          gray === 100
            ? `No devices are initialized or installed in this unit`
            : `${gray}% of devices are not initialized`
        }
        portion={gray}
        color={'lightgray'}
      />
    </Stack>
  );
}

interface ThingProps {
  myHeight: string;
  myColor: string;
}
const Thing = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'myHeight' && prop !== 'myColor',
})<ThingProps>(({ theme, myHeight, myColor }) => ({
  ...theme.typography.body2,
  backgroundColor: myColor,
  padding: 0,
  borderRadius: 0,
  margin: 0,
  height: myHeight,
  textAlign: 'center',
}));

interface SegmentProps {
  portion: number;
  color: string;
  message: string;
}
function Segment(props: SegmentProps) {
  const { portion, color, message } = props;
  return (
    <Tooltip title={message} placement={'right-end'}>
      <Thing myHeight={`${portion}%`} myColor={color}>
        &nbsp;
      </Thing>
    </Tooltip>
  );
}
