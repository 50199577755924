import React from 'react';
import { NavMenuItem } from './models/navMenuItem';
import PeopleIcon from '@mui/icons-material/People';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import SettingsIcon from '@mui/icons-material/Settings';
import WarningIcon from '@mui/icons-material/Warning';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import DeviceHub from '@mui/icons-material/DeviceHub';

// import DashboardIcon from '@mui/icons-material/Dashboard';
// import HelpIcon from '@mui/icons-material/Help';
// import SourceIcon from '@mui/icons-material/Source';
//import LayersIcon from '@mui/icons-material/Layers';

export const primaryNavItems: NavMenuItem[] = [
  {
    icon: <ApartmentIcon />,
    label: 'Properties',
    path: 'properties',
  },
  {
    icon: <PeopleIcon />,
    label: 'Users',
    path: 'users',
    indexType: 'User',
  },
  {
    icon: <WarningIcon />,
    label: 'Alerts',
    path: 'alerts',
    indexType: 'Alert',
  },
  {
    icon: <DeviceHub />,
    label: 'Sources',
    path: 'sources',
    indexType: 'Source',
  },
  {
    icon: <MiscellaneousServicesIcon />,
    label: 'Bulk Config',
    path: 'bulk-config',
    indexType: 'BulkConfig',
  },
  {
    icon: <UploadFileIcon />,
    label: 'Provisioning',
    path: 'ingestions',
    indexType: 'Ingestion',
  },
  {
    icon: <DisplaySettingsIcon />,
    label: 'Setpoint Profiles',
    path: 'setpoint-profiles',
    indexType: 'SetpointProfile',
  },
  {
    icon: <SettingsSuggestIcon />,
    label: 'Zone Policies',
    path: 'zone-policies',
    indexType: 'ZonePolicy',
  },
  {
    icon: <AutoGraphIcon />,
    label: 'Reports',
    path: 'reports',
    indexType: 'Reports',
  },
  {
    icon: <SettingsIcon />,
    label: 'Subscriptions',
    path: 'subscription-monitoring',
    indexType: 'SubscriptionMonitoring',
  },
  // {
  //   icon: <DashboardIcon />,
  //   label: 'Dashboard',
  //   path: 'dashboard',
  //   indexType: 'Dashboard',
  // },
  // {
  //   icon: <SettingsIcon />,
  //   label: 'Settings',
  //   path: 'settings',
  // },
  // {
  //   icon: <HelpIcon />,
  //   label: 'Help',
  //   path: 'help',
  // },
  // {
  //   icon: <LayersIcon />,
  //   label: 'Unit Details',
  //   path: 'properties/:pid/units/:uid',
  // },
];

export const secondaryNavItems: NavMenuItem[] = [
  // {
  //   icon: <SourceIcon />,
  //   label: 'Year To Date',
  //   path: 'year-to-date',
  // },
  // {
  //   icon: <SourceIcon />,
  //   label: 'Month To Date',
  //   path: 'month-to-date',
  // },
  // {
  //   icon: <SourceIcon />,
  //   label: 'Previous Year',
  //   path: 'previous-year',
  // },
];
