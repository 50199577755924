import React from 'react';
import { useFormik } from 'formik';
import { FormValues } from '../types/userProfileFormValues';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { Fade, Switch, useMediaQuery } from '@mui/material';
import BorderedSection from '../../shared/borderedSection';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { PasswordInputField } from '../../../auth/components/PasswordInputField';
import { useTheme } from '@mui/material/styles';

export function FactorPasswordTab(props: {
  formik: ReturnType<typeof useFormik<FormValues>>;
  onChange: StandardInputProps['onChange'];
}) {
  const {
    formik,
    // TODO: Peter: do we need this in this file?
    // onChange
  } = props;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Fade in={true} style={{ transitionDuration: '1100ms' }}>
      <div
        style={{
          margin: isSmall ? '-10px 0px 0px 0px' : '-10px 8px 6px 8px',
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '10px',
          border: '1px solid grey',
          borderRadius: '4px',
        }}
      >
        <h2>Manage your password</h2>

        <FormControlLabel
          disabled={!formik.values.usesPassword}
          control={
            <Switch
              size="small"
              onChange={props.onChange}
              name="changePassword"
              id="changePassword"
              checked={formik.values.changePassword}
            />
          }
          label={`${
            formik.values.hasPassword ? 'Change Password' : 'Set Password'
          }`}
        />
        <br />
        <br />
        {formik.values.changePassword ? (
          <BorderedSection
            title={`${
              formik.values.hasPassword
                ? 'Change Password'
                : 'Set Initial Password'
            }`}
            error={!!formik.errors.validPasswordFactor}
            hint={formik.errors.validPasswordFactor || ''}
          >
            <FormGroup style={{ marginTop: '4px', marginLeft: '6px' }}>
              {formik.values.hasPassword ? (
                <>
                  <TextField
                    fullWidth
                    required
                    autoFocus
                    id="oldPassword"
                    name="oldPassword"
                    placeholder="Old Password"
                    label="Old Password"
                    value={props.formik.values.oldPassword}
                    onChange={props.onChange}
                    error={
                      props.formik.touched.oldPassword &&
                      Boolean(props.formik.errors.oldPassword)
                    }
                    helperText={
                      props.formik.touched.oldPassword &&
                      props.formik.errors.oldPassword
                    }
                  />
                  <br />
                </>
              ) : null}
              <PasswordInputField
                name="newPassword"
                label="New Password"
                initialValue={formik.values.newPassword}
                onChange={formik.handleChange}
                onValidityChange={(valid) => {
                  formik.errors.newPassword = valid
                    ? undefined
                    : 'Please provide a valid password';
                }}
              />
              <br />
              <TextField
                fullWidth
                required
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                value={props.formik.values.confirmPassword}
                onChange={props.onChange}
                error={
                  props.formik.touched.confirmPassword &&
                  Boolean(props.formik.errors.confirmPassword)
                }
                helperText={
                  props.formik.touched.confirmPassword &&
                  props.formik.errors.confirmPassword
                }
              />
            </FormGroup>
          </BorderedSection>
        ) : (
          <div>
            {formik.values.hasPassword
              ? 'You have already set up a password on this account. To change it, toggle the slider control above.'
              : 'You have no password set for this account. To set one, click the slider control above.'}
          </div>
        )}
      </div>
    </Fade>
  );
}
