import React from 'react';

import {
  IAuthenticationResult,
  useAuthenticator,
} from '../AuthenticationContext';

import { Notifier } from '../../system/services/notificationManager';

import { EmbueError } from '../../system/models/embueError';

import { IdentifyUserPanel } from '../components/IdentifyUserPanel';

/**
 * Component representing 'step one' in an MFA authentication workflow.
 * Collects the user's identifier (email) and submits that to the server.
 * @param onAuthResult: called when the server responds to the auth request
 *           and provides the authenticated user object or, if required,
 *           the server's choice for the 'next factor' to be used
 *           in the MFA process. Currently, with only one factor type enabled
 *           or implemented, the resulting factor will always be 'password' but
 *           in the future it could be anything.
 * @constructor
 */
export const Email = ({
  onAuthResult,
}: {
  onAuthResult: (res: IAuthenticationResult | void) => void;
}) => {
  const auth = useAuthenticator();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;

    auth
      .authenticate({ value: email })
      .then((res) => {
        onAuthResult(res);
      })
      .catch((identifyError: EmbueError) => {
        Notifier.error(
          `[Identify User] Error received: ${identifyError.message}.`,
        );
      });
  };

  return (
    <IdentifyUserPanel
      submitHandler={handleSubmit}
      submitLabel="Continue with Email"
    />
  );
};
