import React, { FC } from 'react';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { AuthenticationPanel } from '../auth/components/AuthenticationPanel';

import { Log } from './services/logger';
import { Fade } from '@mui/material';
import { useSystemConnection } from './ConnectionManager';
import { SystemTimer } from './shared/SystemTimer';
import { LocalState } from './services/localStateManager';
import { Notifier } from './services/notificationManager';

interface EstablishConnectionProps {
  title: string;
  children: React.ReactNode;
}

// TODO: Peter: might want to add something on this screen to show that the user has
//  an active session (if the token is set) and show a countdown timer for
//  the session showing how long they have before it expires due to inactivity
//  and/or the inability to update it via server/client interactions. If/when
//  it expires, show a message to the user that informs them that, upon the
//  sever being available again, they will be automatically logged out and
//  will have to log in again to continue.

/**
 * This component is displayed when there is a disruption in connectivity to
 * the server. It provides a countdown to the next time the connection manager
 * will poll the server to see if it is again available as well as giving the
 * user the opportunity to check that connectivity immediately and reset that
 * timer again. Note that the timer cycles automatically using a multiplicative
 * backoff strategy. If the user kicks off a manual check, the timer is reset
 * to what it was at the start of the interrupted cycle rather than advancing
 * the length to the next calculated interval length. This prevents the user
 * from rapidly clicking the button and driving the wait times up to high
 * (long) values artificially.
 * @param title
 * @param children
 * @constructor
 */
export const EstablishConnection: FC<EstablishConnectionProps> = ({
  title,
  children,
}: EstablishConnectionProps) => {
  const { nextConnectionCheckAt, requestConnectionCheckNow, loading } =
    useSystemConnection();

  const transitioningAuthState = LocalState.authStateTransitioning();

  if (!loading && nextConnectionCheckAt && transitioningAuthState) {
    Notifier.warn('Server temporarily unavailable.');
  }
  return (
    <AuthenticationPanel title={title} loading={true}>
      <Fade
        style={{ transitionDuration: '2000ms' }}
        in={true}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <Box component="form" noValidate sx={{ width: 'auto', mt: 1 }}>
            <Typography
              component="p"
              color="rgba(0, 0, 0, 0.60)"
              pb="8px"
              style={{
                marginTop: '36px',
                marginBottom: '8px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {children}
            </Typography>
          </Box>

          {!loading && nextConnectionCheckAt && (
            <Grid container width="100%" style={{ marginBottom: '10px' }}>
              <Grid item xs>
                &nbsp;
              </Grid>
              <Grid item>
                <SystemTimer
                  expirationTimestamp={
                    new Date(nextConnectionCheckAt.valueOf() - 1000)
                  }
                  embedded={false}
                  label="Next check"
                />
              </Grid>
              <Grid item style={{ marginTop: '2px', marginLeft: '4px' }}>
                <Link
                  sx={{ cursor: 'pointer' }}
                  component={Link}
                  onClick={requestConnectionCheckNow}
                  variant="body2"
                >
                  Check now
                </Link>
              </Grid>
              <Grid item xs>
                &nbsp;
              </Grid>
            </Grid>
          )}

          <Grid container width="100%">
            <Grid item xs>
              &nbsp;
            </Grid>
            <Grid item>
              <Link
                sx={{ cursor: 'pointer' }}
                component={Link}
                onClick={() => {
                  Log.warn('Clicked help!');
                }}
                variant="body2"
              >
                Need Help?
              </Link>
            </Grid>
            <Grid item xs>
              &nbsp;
            </Grid>
          </Grid>
        </div>
      </Fade>
    </AuthenticationPanel>
  );
};
