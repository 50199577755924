import React, { useEffect } from 'react';
import {
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Grid,
  Button,
} from '@mui/material';
import BorderedSection from './borderedSection';
import { ThermostatScheduleUpdateStatistics } from '../devices/thermostat/types/thermostat-schedule-update-statistics';
import { ThermostatScheduleStatistics } from '../devices/thermostat/types/thermostat-schedule-statistics';

export const SetpointLimitChangeWarningDialog = (props: {
  handleClose: () => void;
  handleSubmit: () => void;
  open: boolean;
  scheduleStatsError: any;
  scheduleStatsLoading: boolean;
  scheduleStats: ThermostatScheduleStatistics | undefined;
  thermostatScheduleUpdateStatistics: ThermostatScheduleUpdateStatistics;
  selectedSetpointProfileId: string;
  targetLevel: 'property' | 'unit';
}) => {
  const {
    handleClose,
    handleSubmit,
    open,
    scheduleStats,
    thermostatScheduleUpdateStatistics,
    scheduleStatsError,
    scheduleStatsLoading,
    targetLevel,
  } = props;

  return (
    <Dialog
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      <Typography variant="h4" sx={{ padding: '15px' }}>
        Warning: Setpoint Limit Profile change will clear thermostat schedules
      </Typography>
      <DialogContent sx={{ padding: '10px 10px' }}>
        <Typography>
          Changing the setpoint limit profile will clear the current schedules
          applied to the thermostats, do you wish to continue?
        </Typography>
        <h3>
          Should you proceed, your update will clear schedules from the
          following thermostats:
        </h3>
        <div style={{ margin: '16px 0px' }}>
          {scheduleStatsError ? (
            <Typography>Error: {scheduleStatsError.name} </Typography>
          ) : scheduleStatsLoading ? (
            <Typography>Data loading ...</Typography>
          ) : scheduleStats ? (
            <BorderedSection title="Thermostats">
              <ul style={{ paddingLeft: '16px', margin: 0 }}>
                <li>
                  <strong>All:</strong> {scheduleStats.totalThermostats}
                </li>
                <li>
                  <strong>
                    Online and Using Incompatible Schedule Template:
                  </strong>{' '}
                  {
                    thermostatScheduleUpdateStatistics
                      .thermostatsUsingIncompatibleScheduleTemplate.length
                  }
                </li>
                {targetLevel === 'property' ? (
                  <li>
                    <strong>
                      Online and Using Property-Level Schedule Template:
                    </strong>{' '}
                    {
                      scheduleStats
                        .thermostatsUsingPropertyLevelScheduleTemplate.length
                    }
                  </li>
                ) : null}
                {targetLevel === 'unit' ? (
                  <li>
                    <strong>
                      Online and Using Unit-Level Schedule Template:
                    </strong>{' '}
                    {
                      scheduleStats.thermostatsUsingUnitLevelScheduleTemplate
                        .length
                    }
                  </li>
                ) : null}
                <li>
                  <strong>Net Thermostat Schedules to be Cleared:</strong>{' '}
                  {
                    thermostatScheduleUpdateStatistics.netThermostatsToBeUpdated
                      .length
                  }
                </li>
              </ul>
            </BorderedSection>
          ) : (
            <div>No Verification Data</div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Grid container rowSpacing={1} direction={'row'}>
          <Grid item xs={12}>
            <Button
              variant={'contained'}
              color={'warning'}
              fullWidth
              sx={{ p: 1, my: 1 }}
              onClick={() => {
                handleSubmit();
                handleClose();
              }}
            >
              Commit Setpoint Profile Override selection and update Thermostats
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              color="error"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClose();
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
