/**
 * Acts as a wrapper around a 'logging' service.
 * currently just logs to the console.
 */
import { LOGGING_LEVEL } from '../../../helpers/config';
import lodash from 'lodash';

export enum LogLevel {
  ERROR = 0,
  WARN = 1,
  INFO = 2,
  DEBUG = 3,
  METAL = 4,
}

export enum LogCategory {
  TIMEOUT = 'timeout',
  RENDERING = 'rendering',
  TOKENS = 'tokens',
  ROUTING = 'routing',
  CONNECTION = 'connection',
}

const watchCategories: string[] = [];

class LogService {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  public error(
    message: string,
    data?: any,
    categories?: LogCategory[] | LogCategory,
  ) {
    return this.log(message, data, LogLevel.ERROR, categories);
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  public warn(
    message: string,
    data?: any,
    categories?: LogCategory[] | LogCategory,
  ) {
    return this.log(message, data, LogLevel.WARN, categories);
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  public info(
    message: string,
    data?: any,
    categories?: LogCategory[] | LogCategory,
  ) {
    return this.log(message, data, LogLevel.INFO, categories);
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  public debug(
    message: string,
    data?: any,
    categories?: LogCategory[] | LogCategory,
  ) {
    return this.log(message, data, LogLevel.DEBUG, categories);
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  public silly(
    message: string,
    data?: any,
    categories?: LogCategory[] | LogCategory,
  ) {
    return this.log(message, data, LogLevel.METAL, categories);
  }

  public log(
    message: string,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    data: any = undefined,
    level: LogLevel = LogLevel.DEBUG,
    categories?: LogCategory[] | LogCategory,
  ) {
    const myCategories = Array.isArray(categories)
      ? categories
      : categories
        ? [categories]
        : [];
    if (
      lodash.isEmpty(categories) ||
      lodash.intersection(myCategories, watchCategories).length > 0
    ) {
      if (level <= LOGGING_LEVEL) {
        data === undefined ? console.log(message) : console.log(message, data);
      }
    }
    return this;
  }
}

export const Log = new LogService();
