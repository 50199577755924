import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { Subscription } from 'zen-observable-ts';
import axios from 'axios';
import {
  AUTH_ID_TOKEN_NAME,
  AUTH_REMEMBER_ME_TOKEN_NAME,
  AUTH_SESSION_TOKEN_NAME,
  AUTH_STATE_TOKEN_NAME,
  CommunicationAddresses,
  RECONNECT_TIMER_ID_KEY,
  RECONNECT_TIMER_OVERRIDE_ID_KEY,
  SERVER_CONNECTION_INITIALIZED_KEY,
} from '../../helpers/config';

import { LocalState } from './services/localStateManager';
import { Log, LogCategory } from './services/logger';
import { Notifier } from './services/notificationManager';
import { ReconnectAttemptTimer } from './models/reconnectAttemptTimer';
import {
  generateConnectedClient,
  generateLink,
} from '../../helpers/apolloClient';
import { useSessionState } from './SessionStateManager';
import { IServerToken } from '../auth/models';
import EJSON from 'ejson';

export const getLastWaitSettings = (): ReconnectAttemptTimer => {
  return ReconnectAttemptTimer.current();
};

export const getLastWaitTime = () => {
  return getLastWaitSettings().value;
};

// Multiplicative backoff wait time in milliseconds. Loosely inspired by:
// https://codereview.stackexchange.com/questions/272046/implementation-of-exponential-backoff-algorithm
export const calculateNextWaitTime = () => {
  return getLastWaitSettings().increment().value;
};

/* eslint-disable @typescript-eslint/no-empty-interface */
declare interface Timer extends ReturnType<typeof setTimeout> {}

export interface ISystemConnectionManager {
  connectedClient: ApolloClient<NormalizedCacheObject> | undefined;
  addresses: CommunicationAddresses | undefined;
  clientConnected: boolean;
  initialLoad: boolean;
  nextConnectionCheckAt: Date | undefined;
  requestConnectionCheckNow: (force: boolean) => void;
  refreshConnection: () => void;
}

const defaultValue: ISystemConnectionManager = {
  connectedClient: undefined,
  addresses: undefined,
  clientConnected: false,
  initialLoad: true,
  nextConnectionCheckAt: undefined,
  requestConnectionCheckNow: () => {
    // default - no-op
  },
  refreshConnection: () => {
    // default - no-op
  },
};

type Connection = ReturnType<typeof generateConnectedClient>;

interface SystemConnectionProviderProps {
  children: React.ReactNode;
  connection: Connection;
}

const SystemConnectionContext =
  createContext<ISystemConnectionManager>(defaultValue);

SystemConnectionContext.displayName = 'SystemConnectionContext';

export const SUBSCRIPTION_QUERY = gql`
  subscription SystemConnectivityTest {
    systemConnectivityTest {
      message
    }
  }
`;

export const SystemConnectionProvider: FC<SystemConnectionProviderProps> = ({
  children,
  connection: incomingConnection,
}) => {
  const SessionStateManager = useSessionState();

  // TODO: Plumbing_enhancements: Peter: this can likely be simplified or refactored. We never change the client
  //  anymore ... just reassign it's link. The link that we get as part of the initial
  //  'connection' is never really used as we immediately replace it in the useEffect
  //  that has [connection] as its dependency ... perhaps we shouldn't immediately replace
  //  it but wait until we get the 'bad' response, log in or (re)-connect?

  const [connection, setConnection] = useState<Connection>(incomingConnection);
  // const [connection, setConnection] = useState<Connection>(
  //   generateConnectedClient(),
  // );
  const [initialLoad, setInitialLoad] = useState<boolean>(
    defaultValue.initialLoad,
  );
  const [clientConnected, setClientConnected] = useState<boolean>(
    defaultValue.clientConnected,
  );
  const [testSubscription, setTestSubscription] = useState<Subscription>();
  const [reconnectAttemptTimer, setReconnectAttemptTimer] = useState<Timer>();
  const [nextConnectionCheckAt, setNextConnectionCheckAt] = useState<Date>();

  const generateUUID = () => `${Date.now()}.${Math.random()}`;

  const resetTimerDuration = () => {
    ReconnectAttemptTimer.reset();
  };

  const notifyOnConnection = (force?: boolean) => {
    if (!LocalState.authStateTransitioning()) {
      const recoveryInProgress = LocalState.itemExists(RECONNECT_TIMER_ID_KEY);

      if (force || recoveryInProgress) {
        Notifier.success(
          `Connection ${
            recoveryInProgress ? 're-established' : 'established'
          }!`,
        );
      }
    }
  };

  const verifyConnectivity = () => {
    const subscriptionObserver = connection.client
      ?.subscribe({
        query: SUBSCRIPTION_QUERY,
        variables: {},
      })
      .subscribe({
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        next(_data) {
          // This is intentionally a no-op. We are just hitting this API
          // to 'warm up' the websocket and establish the proxy behavior
          // of the HTTP connection by exercising this endpoint once.
          // This appears to be all that is needed to ensure everything
          // is property initialized and functioning. NOTE this only works
          // IFF the connection succeeds. If it does not, it must be re-tried
          // until it succeeds at least one time. Then the subscription can
          // be cleaned up and discarded as other subs will be active and
          // will ensure the socket remains open and monitored.
        },
        error(err) {
          console.error('subscription socket error', err);
        },
      });

    setTimeout(() => {
      subscriptionObserver?.unsubscribe();
    }, 20000);

    return subscriptionObserver;
  };

  const testConnection = (
    force = false,
    resetForcedKey: string | undefined = undefined,
    checkValue?: string,
    skipTimerUpdate?: boolean,
  ) => {
    if (reconnectAttemptTimer) {
      clearTimeout(reconnectAttemptTimer);
      setReconnectAttemptTimer(undefined);
    }

    const lastTimerId = LocalState.getItem<string>(RECONNECT_TIMER_ID_KEY);
    const lastForcedId = LocalState.getItem<string>(
      RECONNECT_TIMER_OVERRIDE_ID_KEY,
    );
    const serverInitialized = LocalState.getItem<boolean>(
      SERVER_CONNECTION_INITIALIZED_KEY,
    );

    const updateRetryTimer = () => {
      let newTimer: Timer | null = null;
      let newWait: number | undefined = undefined;
      const key = generateUUID();

      if (force) {
        LocalState.setItem(RECONNECT_TIMER_OVERRIDE_ID_KEY, key).removeItem(
          RECONNECT_TIMER_ID_KEY,
        );

        // Note that, in case of a forced polling, we do not increment the wait time.
        newTimer = setTimeout(() => {
          testConnection(false, key);
        }, getLastWaitTime());
      } else {
        if (resetForcedKey) {
          if (
            resetForcedKey ===
            LocalState.getItem<string>(RECONNECT_TIMER_OVERRIDE_ID_KEY)
          ) {
            Log.silly('resetting forced', null, LogCategory.TIMEOUT);
            newWait = calculateNextWaitTime();
            LocalState.removeItem(RECONNECT_TIMER_OVERRIDE_ID_KEY).setItem(
              RECONNECT_TIMER_ID_KEY,
              key,
            );
            newTimer = setTimeout(() => {
              testConnection(false, undefined, key);
            }, newWait);
          } else {
            Log.silly(
              'Skipping forced reset due to mismatched key',
              null,
              LogCategory.TIMEOUT,
            );
          }
        } else if (LocalState.itemExists(RECONNECT_TIMER_OVERRIDE_ID_KEY)) {
          Log.silly('skipping resetting timer', null, LogCategory.TIMEOUT);
        } else {
          newWait = calculateNextWaitTime();
          LocalState.setItem(RECONNECT_TIMER_ID_KEY, key);
          Log.silly('setting regular timer', null, LogCategory.TIMEOUT);
          newTimer = setTimeout(() => {
            testConnection(false, undefined, key);
          }, newWait);
        }
      }

      if (newTimer) {
        Log.silly(
          'resetting connection timer to',
          getLastWaitTime(),
          LogCategory.TIMEOUT,
        );
        setNextConnectionCheckAt(new Date(Date.now() + getLastWaitTime()));
        setReconnectAttemptTimer(newTimer);
        setClientConnected(false);
      }
    };

    if (
      force ||
      (resetForcedKey && resetForcedKey === lastForcedId) ||
      (resetForcedKey === undefined &&
        (checkValue === undefined || checkValue === lastTimerId))
    ) {
      if (serverInitialized) {
        const headers: Record<string, string> = {
          'Content-Type': 'application/json',
        };
        [
          AUTH_REMEMBER_ME_TOKEN_NAME,
          AUTH_ID_TOKEN_NAME,
          AUTH_STATE_TOKEN_NAME,
          AUTH_SESSION_TOKEN_NAME,
        ].forEach((key: string) => {
          const val = LocalState.getItem<IServerToken>(key);
          if (val) {
            headers[key] = EJSON.stringify(val);
          }
        });

        Log.silly('trying to reach server via web', null, LogCategory.TIMEOUT);
        try {
          axios
            .post(
              '/system/online',
              {},
              {
                headers,
              },
            )
            .then((res) => {
              if (res.status < 400) {
                Log.silly(
                  'connection re-established!',
                  null,
                  LogCategory.TIMEOUT,
                );
                notifyOnConnection(force);
                setInitialLoad(false);
                setClientConnected(true);
                setNextConnectionCheckAt(undefined);
                resetTimerDuration();
                LocalState.removeItem(RECONNECT_TIMER_ID_KEY);
              } else {
                Log.error(
                  `[System connectivity] Server test query returned bad status: ${res.status}.`,
                  null,
                  LogCategory.TIMEOUT,
                );
              }
            })
            .catch((testError) => {
              Log.error(
                `[System connectivity] Cannot reach server via post: ${testError.message}.`,
                null,
                LogCategory.TIMEOUT,
              );
              updateRetryTimer();
            });
        } catch (testError2) {
          Log.error(
            `[System connectivity] Cannot reach server via post: ${(testError2 as Error).message}.`,
            null,
            LogCategory.TIMEOUT,
          );
          updateRetryTimer();
        }
      } else {
        Log.silly('trying to reach server via socket');
        if (!skipTimerUpdate) {
          Log.silly(
            'updating timer ahead of checking for reconnection',
            null,
            LogCategory.TIMEOUT,
          );
          updateRetryTimer();
        } else {
          Log.silly(
            'Skipping timer update due to forced check',
            null,
            LogCategory.TIMEOUT,
          );
        }
        Log.silly('Re-checking connectivity');
        const connectivityCheckSubHandle = verifyConnectivity();
        Log.silly(
          'Connectivity test via socket initiated:',
          connectivityCheckSubHandle,
          LogCategory.TIMEOUT,
        );
        setTimeout(() => {
          Log.silly(
            'Un-subbing from connectivity test sub.',
            connectivityCheckSubHandle,
            LogCategory.TIMEOUT,
          );
          connectivityCheckSubHandle?.unsubscribe();
        }, 20000);
      }
    } else {
      Log.silly(
        'Skipping as timer checks do not match',
        {
          checkValue,
          lastTimerId,
          lastForcedId,
        },
        LogCategory.TIMEOUT,
      );
    }
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const handleConnectionOpened = (socket: any) => {
    connection.client.refetchQueries({ include: 'all' });
    if (socket) {
      const serverInitialized = LocalState.itemExists(
        SERVER_CONNECTION_INITIALIZED_KEY,
      );

      notifyOnConnection(!serverInitialized);
      LocalState.setItem(SERVER_CONNECTION_INITIALIZED_KEY, true).removeItems([
        RECONNECT_TIMER_OVERRIDE_ID_KEY,
        RECONNECT_TIMER_ID_KEY,
      ]);
      setNextConnectionCheckAt(undefined);
      // CHANGED -- old version had no surrounding 'if' statement

      // if (nextConnectionCheckAt) {
      //   setNextConnectionCheckAt(undefined);
      // }

      resetTimerDuration();

      if (initialLoad) {
        setInitialLoad(false);
      }

      if (testSubscription) {
        Log.silly(
          'clearing test subscription: opened',
          null,
          LogCategory.TIMEOUT,
        );
        testSubscription.unsubscribe();
        setTestSubscription(undefined);
      }

      Log.silly('New client connected!', socket, LogCategory.TIMEOUT);

      if (socket.readyState === socket.OPEN) {
        Log.silly('socket is Open', null, LogCategory.TIMEOUT);
      } else {
        Log.silly('socket is NOT open', null, LogCategory.TIMEOUT);
      }

      setClientConnected(true);
      // CHANGED -- old version had no if

      // if (!clientConnected) {
      //   setClientConnected(true);
      // }
    } else {
      Log.silly('open called with no socket', null, LogCategory.TIMEOUT);
    }
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const handleConnectionClosed = (event: any) => {
    Log.silly(
      'Connection closed event received: ',
      {
        event,
        timerOverride: LocalState.getItem<string>(
          RECONNECT_TIMER_OVERRIDE_ID_KEY,
        ),
      },
      LogCategory.TIMEOUT,
    );
    if (event) {
      switch (event.code) {
        case 1000:
          Log.silly('Got code 1000, doing nothing.', null, LogCategory.TIMEOUT);
          break;
        case 1001:
          Log.silly(
            'Got code 1001, initiating reconnect sequence',
            null,
            LogCategory.TIMEOUT,
          );
          Notifier.warn('Server connection lost!');

          testConnection();
          break;
        case 1006:
          /* eslint-disable-next-line no-case-declarations */
          const resetInProgress = ReconnectAttemptTimer.resetInProgress();
          Log.silly(
            `Got code ${
              event.code
            }: Connected? ${clientConnected}: Reset already in progress? ${
              resetInProgress ? 'true' : 'false'
            }.`,
          );
          if (!resetInProgress) {
            Log.silly(
              '(re) starting re-connection sequence.',
              null,
              LogCategory.TIMEOUT,
            );
            testConnection();
          }
          break;
        default:
          Log.silly(
            'default closed event handler engaged.',
            event.code,
            LogCategory.TIMEOUT,
          );
      }
    } else {
      Log.silly('closed called with no event', null, LogCategory.TIMEOUT);
    }

    return () => {
      if (reconnectAttemptTimer) {
        clearTimeout(reconnectAttemptTimer);
      }
    };
  };

  useEffect(() => {
    if (connection.client === undefined) {
      // TODO: Peter: Might want to do something more intelligent here.
      Log.silly('>>>> NO CLIENT <<<<<', null, LogCategory.TIMEOUT);
    } else {
      if (initialLoad) {
        setTimeout(() => {
          setInitialLoad(false);
        }, 4000);
      }
      if (testSubscription) {
        testSubscription.unsubscribe();
      }

      const subHandle = verifyConnectivity();
      setTestSubscription(subHandle);

      // connection.link.client.on('error', (error: any) => {
      //   // Log.silly('got link error', error);
      // });
      //
      // connection.link.client.on('opened', (newSocket: any) => {
      //   Log.silly('got opened xxx:', newSocket);
      //   // handleConnectionOpened(newSocket);
      // });

      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      connection.link.client.on('connected', (newSocket: any) => {
        // if (clientConnected) {
        //   Log.silly('Received socket connect. Already connected!');
        // } else {
        if (clientConnected) {
          Log.silly(
            'Connected event called while connection manager thinks it is already connected!',
            null,
            LogCategory.TIMEOUT,
          );
        }
        Log.silly(
          'Socket connected event while disconnected',
          newSocket,
          LogCategory.TIMEOUT,
        );
        handleConnectionOpened(newSocket);
        // }
      });

      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      connection.link.client.on('closed', (event: any) => {
        Log.silly('got socket closed event:', event, LogCategory.TIMEOUT);
        handleConnectionClosed(event);

        // CHANGED -- old version did not filter
        // if (event.code === 1000) {
        //   Log.silly('Got benign socket closed event');
        // } else {
        //   Log.silly('got socket closed event:', event);
        //   handleConnectionClosed(event);
        // }
      });

      return () => {
        if (testSubscription) {
          testSubscription.unsubscribe();
          // TODO: Peter: might be able to reduce re-renders by eliminating this next line. I don't think we need it anyway.
          setTestSubscription(undefined);
        }

        // TODO: Peter: Investigate if we need to do this or if it is already GCed.
        // if (reconnectAttemptTimer) {
        //   clearTimeout(reconnectAttemptTimer);
        // }
      };
    }
  }, [connection]);

  const refreshConnection = () => {
    // connection.client.clearStore();
    // connection.link.client.dispose();
    Log.silly('refreshing connection', null, LogCategory.CONNECTION);
    const { link, wsLink } = generateLink(connection.addresses, () => {
      // Log.silly('Refreshed connection ... updating tokens!', null, LogCategory.TIMEOUT);
      SessionStateManager.updateTokens();
    });
    connection.client.setLink(link);

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    wsLink.client.on('connected', (newSocket: any) => {
      Log.silly(
        'got socket connected event xxx',
        newSocket,
        LogCategory.TIMEOUT,
      );
      handleConnectionOpened(newSocket);
    });

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    wsLink.client.on('closed', (event: any) => {
      Log.silly('got socket closed event:', event, LogCategory.TIMEOUT);
      handleConnectionClosed(event);
    });
    // TODO: Peter: investigate if we can consolidate sockets even more.
    // connection.client.resetStore();
    // connection.client.re fetchQueries({ include: 'all' });
    // connection.client.reFetchObservableQueries(true).then();

    setConnection({
      client: connection.client,
      link: wsLink,
      addresses: connection.addresses,
    });
  };

  const value: ISystemConnectionManager = useMemo(
    () => ({
      connectedClient: connection.client,
      addresses: connection.addresses,
      clientConnected,
      initialLoad,
      nextConnectionCheckAt,
      requestConnectionCheckNow: () => {
        setTimeout(() => {
          testConnection(true);
        }, 1000);
        setNextConnectionCheckAt(new Date());
      },
      refreshConnection: refreshConnection,
    }),
    [connection, clientConnected, initialLoad, nextConnectionCheckAt],
  );

  Log.silly('rendering connection manager', null, LogCategory.RENDERING);

  return (
    <SystemConnectionContext.Provider value={value}>
      {children}
    </SystemConnectionContext.Provider>
  );
};

export interface ISystemConnectionInfo {
  client: ApolloClient<NormalizedCacheObject> | undefined;
  addresses: CommunicationAddresses | undefined;
  connected: boolean;
  loading: boolean;
  nextConnectionCheckAt: Date | undefined;
  requestConnectionCheckNow: () => void;
  refreshConnection: () => void;
}

export const useSystemConnection = (): ISystemConnectionInfo => {
  const context = useContext(SystemConnectionContext);
  if (context === undefined) {
    throw new Error(
      'useSystemConnection must be used within a SystemConnectionProvider',
    );
  }
  return {
    client: context.connectedClient,
    addresses: context.addresses,
    connected: context.clientConnected,
    loading: context.initialLoad,
    nextConnectionCheckAt: context.nextConnectionCheckAt,
    requestConnectionCheckNow: () => {
      context.requestConnectionCheckNow(true);
    },
    refreshConnection: () => {
      context.refreshConnection();
    },
  };
};
