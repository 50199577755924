import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { InstallationAction, InstallationStatus } from '../types';
import { Capacitor } from '@capacitor/core';
import { Log } from '../../../system/services/logger';

const MOBILE_ONLY = (() => {
  const platform = Capacitor.getPlatform();
  return platform === 'ios' || platform === 'android';
})();

const ALWAYS = true;

const ONLY_IF = (param: boolean) => param ?? false;

interface ActionButtonProps {
  status?: InstallationStatus;
  hasEuid: boolean;
  handleClick: (action: InstallationAction) => void;
}
interface ActionButton {
  label: string;
  color: string;
  action: InstallationAction;
  visible?: boolean;
}
export function ActionButtons(props: ActionButtonProps) {
  const { status, hasEuid, handleClick } = props;
  const actionButtons: Record<InstallationStatus, ActionButton[]> = {
    /**
     *  Status 0: Ready To Install
     *
     *  Description: The device is ready to install.  The user can begin the workflow by scanning
     *  or entering the device EUID.
     *
     *  Actions: [ 'Start Scan', 'Enter EUID', 'Exit' ]
     **/
    [InstallationStatus.READY_TO_INSTALL]: [
      {
        label: 'Start Scan',
        color: 'lightblue',
        action: InstallationAction.SCAN_START,
        visible: MOBILE_ONLY,
      },
      {
        label: 'Enter EUID',
        color: '#36afa5',
        action: InstallationAction.ENTER_EUID,
        visible: ALWAYS,
      },
      {
        label: 'Exit',
        color: '#80bb52',
        action: InstallationAction.INSTALLER_EXIT,
        visible: ALWAYS,
      },
    ],

    /**
     * Status 11: Scan In Progress
     *
     * Description:  Technically still the original status code (0, 3, 4, and 9 are possible values), but
     * the status is locally overwritten in order to display the scanning tools.  The user has the option of
     * cancelling the scan, or of activating the device flashlight for additional light.
     *
     * Actions:  [ 'Cancel', 'Flashlight' ]
     */
    [InstallationStatus.SCAN_IN_PROGRESS]: [
      {
        label: 'Cancel',
        color: '#f26a4b',
        action: InstallationAction.SCAN_CANCEL,
        visible: ALWAYS,
      },
      {
        label: 'Flashlight',
        color: 'f1c40f',
        action: InstallationAction.TOGGLE_TORCH,
        visible: MOBILE_ONLY,
      },
    ],

    /**
     * Status 12: Scan Successful
     *
     * Description:  Still technically the original status (0, 3, 4, 9), as in status 11.  At this point the device EUID
     * has been either scanned or keyed in.  The user now has the option to either proceed with or cancel the
     * join-device workflow.
     *
     * Actions:  [ 'Join Device', 'Exit' ]
     *
     * Results In:
     *   - JOIN_INITIATE: Status
     */
    [InstallationStatus.SCAN_SUCCESSFUL]: [
      {
        label: 'Join Device',
        color: '#36afa5',
        action: InstallationAction.JOIN_INITIATE,
        visible: ONLY_IF(hasEuid),
      },
      {
        label: 'Exit',
        color: '#80bb52',
        action: InstallationAction.INSTALLER_EXIT,
        visible: ALWAYS,
      },
    ],

    /**
     *  Status 1: Network Open
     *
     *  Description: A request was sent to the core to open the network for new joins.  The
     *  user may cancel at this point - without pushing the join button on the device - and
     *  the installation will time out.
     *
     *  Actions: [ 'Cancel' ]
     *
     *  Results In: InstallationStatus.INSTALLATION_FAILED
     **/
    [InstallationStatus.NETWORK_OPEN]: [
      {
        label: 'Cancel Join',
        color: '#36afa5',
        action: InstallationAction.JOIN_CANCEL,
        visible: ALWAYS,
      },
    ],

    /**
     * Status 2: Network Failed To Open
     *
     * Description: The call to the Core to open the network was rejected.  The user can retry with the
     * already scanned device, or restart the installation workflow, possibly with a different device,
     * or they can exit the workflow.
     *
     * Actions: [ 'Retry Join', 'Try With New Device', 'Exit' ]
     */
    [InstallationStatus.NETWORK_OPEN_FAILED]: [
      {
        label: 'Retry Join',
        color: '#36afa5',
        action: InstallationAction.JOIN_INITIATE,
        visible: ONLY_IF(hasEuid),
      },
      {
        label: 'Start Over',
        color: '#f26a4b',
        action: InstallationAction.RESTART_INSTALLATION,
        visible: ALWAYS,
      },
      {
        label: 'Exit',
        color: '#80bb52',
        action: InstallationAction.INSTALLER_EXIT,
        visible: ALWAYS,
      },
    ],

    /**
     * Status 3: Installation Successful (Installation Complete)
     *
     * Description: The device was installed successfully.  Under some circumstances it can be necessary
     * to repeat this process.  The user is can either re-join the currently installed device, or
     * replace it with a different device.
     *
     * Actions:  [ 'Re-Join', 'Replace', 'Exit' ]
     */
    [InstallationStatus.INSTALLATION_SUCCESSFUL]: [
      {
        label: 'Re-Join',
        color: '#36afa5',
        action: InstallationAction.JOIN_INITIATE,
        visible: ONLY_IF(hasEuid),
      },
      {
        label: 'Replace Device',
        color: '#6f61c2',
        action: InstallationAction.REPLACE_INITIATE,
        visible: ALWAYS,
      },
      {
        label: 'Exit',
        color: '#80bb52',
        action: InstallationAction.INSTALLER_EXIT,
        visible: ALWAYS,
      },
    ],

    /**
     * Status 4: Join Failed (Unable to Join Core ZigBee Network)
     *
     * Description: The network is opened, but the device was unable to join the Core ZigBee network for
     * unspecified reasons.  Likely the operation timed out.  User has the option to rejoin (recommended),
     * install a different device, or exit the workflow.
     *
     * Actions: [ 'Re-Join', 'Restart Installation', 'Exit' ]
     */
    [InstallationStatus.INSTALLATION_FAILED]: [
      {
        label: 'Re-Join',
        color: '#36afa5',
        action: InstallationAction.JOIN_INITIATE,
        visible: ONLY_IF(hasEuid),
      },
      {
        label: 'Try With New Device',
        color: '#f26a4b',
        action: InstallationAction.RESTART_INSTALLATION,
        visible: ALWAYS,
      },
      {
        label: 'Exit',
        color: '#80bb52',
        action: InstallationAction.INSTALLER_EXIT,
        visible: ALWAYS,
      },
    ],

    /**
     * No actions are available.  The configuration timer runs for 60 seconds.
     */
    [InstallationStatus.CONFIGURATION_IN_PROGRESS]: [],

    /**
     * Status 9: Configuration Failed
     *
     * Description:  The device communicated with the core, but the configuration during
     * Status 8 failed.  The user can either retry the join (recommended), restart the installation
     * workflow with a different device, or exit the installation.
     *
     * Actions: [ 'Retry Join', 'Replace Initiate', 'Exit' ]
     */
    [InstallationStatus.CONFIGURATION_FAILED]: [
      {
        label: 'Retry Join',
        color: '#36afa5',
        action: InstallationAction.JOIN_INITIATE,
        visible: ONLY_IF(hasEuid),
      },
      {
        label: 'Replace With Different Device',
        color: '#567345',
        action: InstallationAction.REPLACE_INITIATE,
        visible: ALWAYS,
      },
      {
        label: 'Exit',
        color: '#80bb52',
        action: InstallationAction.INSTALLER_EXIT,
        visible: ALWAYS,
      },
    ],
    [InstallationStatus.ERROR_STATE]: [
      {
        label: 'Exit',
        color: '#80bb52',
        action: InstallationAction.INSTALLER_EXIT,
        visible: ALWAYS,
      },
    ],
    /**
     * We don't know why the scan would have failed, and it has never happened, but the user
     * should have the same options as at the start of the installation workflow, i.e. try the
     * scan again, or enter the euid manually, or exit.
     */
    [InstallationStatus.SCAN_FAILED]: [
      {
        label: 'Start Scan',
        color: 'lightblue',
        action: InstallationAction.SCAN_START,
        visible: MOBILE_ONLY,
      },
      {
        label: 'Enter EUID',
        color: '#36afa5',
        action: InstallationAction.ENTER_EUID,
        visible: ALWAYS,
      },
      {
        label: 'Exit',
        color: '#80bb52',
        action: InstallationAction.INSTALLER_EXIT,
        visible: ALWAYS,
      },
    ],
    /**
     * This view is displayed if a user selects to replace a device.  The functionality is the same
     * as installing a new device, but the labels are different in order to illustrate the context of
     * a replacement workflow.
     */
    [InstallationStatus.READY_TO_REPLACE]: [
      {
        label: 'Scan Replacement Device',
        color: 'lightblue',
        action: InstallationAction.SCAN_START,
        visible: MOBILE_ONLY,
      },
      {
        label: 'Enter Replacement EUID',
        color: '#36afa5',
        action: InstallationAction.ENTER_EUID,
        visible: ALWAYS,
      },
      {
        label: 'Cancel Replacement',
        color: '#80bb52',
        action: InstallationAction.INSTALLER_EXIT,
        visible: ALWAYS,
      },
    ],
    /**
     * Network open has been requested, the UI shows a 30sec timer.  No user input makes sense at
     * this stage, so no buttons are displayed.
     */
    [InstallationStatus.NETWORK_OPEN_REQUESTED]: [],
  };

  return (
    <Stack
      alignContent={'center'}
      justifyContent={'space-around'}
      sx={{
        px: 8,
      }}
    >
      {status !== undefined && status in InstallationStatus ? (
        <>
          {actionButtons[status].map((button) => {
            return (
              <Button
                key={button.label}
                sx={{
                  display: button.visible ? 'flex' : 'none',
                  m: 1,
                  p: 1,
                  backgroundColor: button.color,
                  '&:hover': {
                    backgroundColor: button.color,
                  },
                }}
                variant={'contained'}
                onClick={() => handleClick(button.action)}
              >
                {button.label}
              </Button>
            );
          })}
        </>
      ) : (
        <>
          <Typography variant="h3">ERROR</Typography>
          <Typography variant="h4">Unknown Installation Status</Typography>
        </>
      )}
    </Stack>
  );
}
