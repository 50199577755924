import React from 'react';
import { AlertsList } from './alerts-list';
import {
  useAlertsListQuery,
  useAlertsListUpdateSubscription,
} from '../../../types/generated-types';
import { updateCacheFromSubscriptionEvent } from '../../../helpers/subscriptionUtils';
import { useMediaQuery, useTheme } from '@mui/material';

export function AlertsMain() {
  const { data, loading, error } = useAlertsListQuery();

  useAlertsListUpdateSubscription({
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      style={{
        margin: isSmall ? '0px' : '10px 4px',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      {error ? (
        <div style={{ flex: 1, textAlign: 'center' }}>
          Error loading alerts: {error.message}
        </div>
      ) : (
        <AlertsList alerts={data?.alerts ?? []} loading={loading} />
      )}
    </div>
  );
}
