/**
 * ViewSelector
 * - UI component used by Property component
 * - Renders drop-down view selector on small screens and tab selector on larger screens
 * - Used to select property-detail view.
 */
import React, { useEffect } from 'react';
import { ListItemText } from '@mui/material';

import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { PropertyUnits } from '../components/property-units';
import { PropertySummary } from '../components/property-summary';
import { PropertyAlerts } from '../components/property-alerts';
import { PropertyInstaller } from '../components/property-installer';
import { PropertyScheduleList } from '../components/property-schedule-list';
import { PropertyBuildings } from '../components/property-buildings';
import { PropertyAlertTriggers } from '../components/property-alert-triggers';
import { PropertyAlertSubscriptions } from '../components/property-alert-subscriptions';

export type ViewOptionKey =
  | 'Summary'
  | 'Alerts'
  | 'AlertTriggers'
  | 'AlertSubscriptions'
  | 'Units'
  | 'Installer'
  | 'Buildings'
  | 'Schedule';

export interface ViewOption {
  type: ViewOptionKey;
  data?: any;
  view: (props: any) => JSX.Element;
}

export const viewOptionsMap: Record<ViewOptionKey, ViewOption> = {
  Summary: {
    type: 'Summary',
    view: PropertySummary,
  },
  Alerts: {
    type: 'Alerts',
    view: PropertyAlerts,
  },
  AlertTriggers: {
    type: 'AlertTriggers',
    view: PropertyAlertTriggers,
  },
  AlertSubscriptions: {
    type: 'AlertSubscriptions',
    view: PropertyAlertSubscriptions,
  },
  Units: {
    type: 'Units',
    view: PropertyUnits,
  },
  Installer: {
    type: 'Installer',
    view: PropertyInstaller,
  },
  Buildings: {
    type: 'Buildings',
    view: PropertyBuildings,
  },
  Schedule: {
    type: 'Schedule',
    view: PropertyScheduleList,
  },
};

/* TODO: Peter are we still using this? */
