import React from 'react';
import { Fade, GlobalStyles } from '@mui/material';
import Container from '@mui/material/Container';
import { AuthRouter } from '../routes';
import { Copyright } from '../ui/shared/copyright';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSystemConnection } from './ConnectionManager';
import { EstablishConnection } from './EstablishConnection';
import { SERVER_TYPE } from '../../helpers/config';

/**
 * This component provides basic 'routing' based on whether the system has a
 * connection to the server (server is online and contactable by the client)
 * or if the UX is still loading. This shell is only displayed when:
 *   1. The system cannot reach the server.
 *   2. The user has not authenticated yet.
 *   // TODO: Peter: should verify that #3 is actually the case. I think that one might go through the normal Shell / Router.
 *   3. The system is logging the user out.
 * @constructor
 */
export function SystemShell(): JSX.Element {
  const { loading, connected } = useSystemConnection();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Fade
      in={true}
      style={{
        transitionDuration: '1100ms',
      }}
    >
      <div
        style={{
          backgroundColor: 'rgb(248 248 248 / 73%)',
          padding: '20px',
          border: '4px outset rgba(104,185,182,.25)',
          boxShadow:
            'rgba(104,185,182, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
          marginTop: isSmall ? '0px' : '25px',
          minHeight: isSmall ? '100%' : 'unset',
          maxWidth: isSmall ? '100%' : '400px',
          height: 'auto',
          display: isSmall ? 'flex' : 'flex',
          flexDirection: isSmall ? 'column' : 'column',
          alignItems: isSmall ? 'center' : 'center',
          width: isSmall ? '100%' : '400px',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: `translate(-50%, ${isSmall ? '-50%' : '-60%'})`,
        }}
      >
        <GlobalStyles
          styles={{
            body: {
              backgroundSize: Math.max(window.innerHeight, window.innerWidth),
              backgroundPosition: isSmall ? 'bottom' : 'center',
            },
          }}
        />
        {loading || !connected ? (
          <EstablishConnection
            title={
              loading
                ? 'Establishing server connection'
                : 'Awaiting server connection'
            }
          >
            {loading ? (
              <span>Contacting server. Please be patient.</span>
            ) : (
              <>
                <span>
                  {isSmall
                    ? 'Our server is temporarily unavailable.'
                    : 'We are unable to reach our server at this time.'}
                </span>
                <br />
                <span>
                  {isSmall
                    ? 'We will restore service ASAP.'
                    : 'We are working to restore service as soon as possible.'}
                </span>
              </>
            )}
          </EstablishConnection>
        ) : (
          <AuthRouter />
        )}
        <Copyright sx={{ mt: 5 }} />
      </div>
    </Fade>
  );
}
