import React from 'react';
import { NavMenuItem } from './models/navMenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { AuthorizedUser } from '../../auth/models';

export const appBarItems: (user: AuthorizedUser) => NavMenuItem[] = (user) => [
  {
    icon: <SettingsIcon />,
    label: 'Profile',
    path: `/users/${user._id}/profile`,
  },
  {
    icon: <ExitToAppIcon />,
    label: 'Sign Out',
    path: 'sign-out',
  },
];
