/**
 * removeFrom: remove a subset of objects from an array arr1 if they are also present in a second
 * array arr2.  array elements are expected to be generic objects of the same type.  the 'getValue'
 * function needs to provide a field that identifies the objects within the array, e.g. an 'ID' field.
 * @param {Object[]} arr1 - array of generic objects of the same type
 * @param {Object[]} arr2 - array of generic objects of the same type as 'arr1'
 * @callback getValue - retrieves a specific field that identifies the objects within the array
 * @returns {Object[]}
 */
export function removeFrom<T>(
  arr1: Array<T>,
  arr2: Array<T>,
  getValue: (item: T) => string,
) {
  return arr1.filter(
    (item1) => !arr2.some((item2) => getValue(item1) === getValue(item2)),
  );
}

/**
 * byName:  Used by generic sort function
 * @callback format - provides the values by which to sort
 * @returns {number}
 */
export function byName<T>(format: (r: T) => string): (a: T, b: T) => number {
  return (a, b) => format(a).localeCompare(format(b)) || 0;
}
