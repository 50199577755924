// noinspection JSUnusedLocalSymbols

import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  // useEffect,
  useMemo,
  useState,
} from 'react';
import {
  SystemCommand,
  SystemDirective,
  SystemMessage,
  SystemMessageType,
  useSystemCommandPostedSubscription,
  useSystemMessagePostedSubscription,
} from '../../types/generated-types';
import { Log, LogCategory } from './services/logger';
import { ApolloClient, ApolloError } from '@apollo/client';
import { Notifier } from './services/notificationManager';
import { useSystemConnection } from './ConnectionManager';

export type SystemCommandReceivedCallback = (command: SystemCommand) => void;
export type SystemMessageReceivedCallback = (message: SystemMessage) => void;

export interface ISystemCommunicationsManager {
  subscribeToCommands(
    subId: string,
    callback: SystemCommandReceivedCallback,
  ): void;
  subscribeToMessages(
    subId: string,
    callback: SystemMessageReceivedCallback,
  ): void;
}

const defaultValue: ISystemCommunicationsManager = {
  subscribeToCommands(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    _subId: string,
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    _callback: SystemCommandReceivedCallback,
  ) {
    // TODO: Peter: no-op ... for now.
  },
  subscribeToMessages(
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    _subId: string,
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    _callback: SystemMessageReceivedCallback,
  ) {
    // TODO: Peter: no-op ... for now.
  },
};

interface SystemCommunicationsProviderProps {
  children: React.ReactNode;
}

const SystemCommunicationsContext =
  createContext<ISystemCommunicationsManager>(defaultValue);

SystemCommunicationsContext.displayName = 'SystemCommunicationsContext';

/**
 * This context provides a manager for system-level communications including
 * system commands/directives as well as messages. To consume such server-side
 * push "notifications", you will need to define a callback that implements
 * either the SystemCommandReceivedCallback or the SystemMessageReceivedCallback
 * interface and then use the appropriate context-driven hook to subscribe to
 * the feed of incoming messages or commands.
 * @param children
 * @constructor
 */
export const SystemCommunicationsProvider: FC<
  SystemCommunicationsProviderProps
> = ({ children }) => {
  // TODO: Peter: maybe try using the setter when we register a new one? Might not rerender too badly.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const connection = useSystemConnection();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [commandCallbacks, _setCommandCallbacks] = useState<
    Record<string, SystemCommandReceivedCallback>
  >({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [messageCallbacks, _setMessageCallbacks] = useState<
    Record<string, SystemMessageReceivedCallback>
  >({});

  // const [messages, setMessages] = useState<SystemMessage[]>(
  //   defaultValue.messages,
  // );
  // const [commands, setCommands] = useState<SystemCommand[]>(
  //   defaultValue.commands,
  // );

  const handleErrorMessage = useCallback(
    (error: ApolloError | undefined, type: string) => {
      if (error) {
        Notifier.error(
          `[System ${type}] Unable to process system ${type}! ${error.message}`,
        );
        return true;
      } else {
        return false;
      }
    },
    [],
  );

  // noinspection JSUnusedLocalSymbols
  const handleIncomingCommand = useCallback(
    async (
      command: SystemCommand | undefined | null,
      client: ApolloClient<any>,
    ) => {
      if (command) {
        if (command.directive === SystemDirective.SubscriptionReset) {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          Log.info('[System Commands] Receiving system command', command);
          for (const callback of Object.values(commandCallbacks)) {
            callback(command);
          }
        }
        // setCommands([command, ...commands]);
      }
    },
    [commandCallbacks],
  );

  const handleIncomingMessage = useCallback(
    (message: SystemMessage | undefined | null) => {
      if (message) {
        if (messageCallbacks.length) {
          for (const callback of Object.values(messageCallbacks)) {
            callback(message);
          }
        } else {
          const messageText = `[System Message] ${message.message}`;

          switch (message.type) {
            case SystemMessageType.Error:
              Notifier.error(messageText);
              break;
            case SystemMessageType.Warning:
              Notifier.warn(messageText);
              break;
            default:
              Notifier.info(message.message);
          }
        }
        // setMessages([message, ...messages]);
      }
    },
    [messageCallbacks],
  );

  // const { error: incomingSystemCommandsError, data: incomingSystemCommands } =
  useSystemCommandPostedSubscription({
    variables: {},
    fetchPolicy: 'no-cache',
    onData: ({ client, data: { data, error } }) => {
      console.log('received: ', data);
      if (!handleErrorMessage(error, 'command')) {
        handleIncomingCommand(data?.systemCommandPosted, client).then(() => {
          // Intentional no op.
          // TODO: Peter: should we do something different here?
        });
      }
    },
  });

  //const { error: incomingSystemMessagesError, data: incomingSystemMessages } =
  useSystemMessagePostedSubscription({
    variables: {},
    fetchPolicy: 'no-cache',
    onData: ({ data: { data, error } }) => {
      if (!handleErrorMessage(error, 'messages')) {
        handleIncomingMessage(data?.systemMessagePosted);
      }
    },
  });

  // TODO: Peter: not sure if we want to store messages in local storage or in context state, if at all.
  //  we should probably log that they were received by the client and viewed?
  // useEffect(() => {
  //   if (incomingSystemCommands?.systemCommandPosted) {
  //     setCommands([incomingSystemCommands.systemCommandPosted, ...commands]);
  //   }
  // }, [incomingSystemCommands]);

  // useEffect(() => {
  //   handleIncomingMessage(incomingSystemMessages?.systemMessagePosted);
  // }, [incomingSystemMessages]);

  // useEffect(() => {
  //   handleErrorMessage(incomingSystemMessagesError, 'messages');
  //   handleErrorMessage(incomingSystemCommandsError, 'commands');
  // }, [incomingSystemCommandsError, incomingSystemMessagesError]);

  // const consumeCommand = (command: SystemCommand) => {
  //   const newCommands = commands.filter(
  //     (testCommand) => testCommand !== command,
  //   );
  //   setCommands([...newCommands]);
  //   // const commandToUpdate = commands.find(
  //   //   (testCommand) => testCommand === command,
  //   // );
  //   // if (commandToUpdate) {
  //   //   commandToUpdate.processed = true;
  //   //   setCommands([...commands]);
  //   // } else {
  //   //   Log.error('unable to find command');
  //   // }
  // };

  // const consumeMessage = (message: SystemMessage) => {};

  const value = useMemo(() => {
    return {
      subscribeToMessages: (
        subId: string,
        callback: SystemMessageReceivedCallback,
      ) => {
        messageCallbacks[subId] = callback;
      },
      subscribeToCommands: (
        subId: string,
        callback: SystemCommandReceivedCallback,
      ) => {
        commandCallbacks[subId] = callback;
      },
    };
  }, [messageCallbacks, commandCallbacks]);

  Log.silly('rendering communications manager', null, LogCategory.RENDERING);

  return (
    <SystemCommunicationsContext.Provider value={value}>
      {children}
    </SystemCommunicationsContext.Provider>
  );
};

// noinspection JSUnusedGlobalSymbols
export const useSystemMessages = (): {
  subscribeToMessages: ISystemCommunicationsManager['subscribeToMessages'];
  // consumeMessage: ISystemCommunicationsManager['consumeMessage'];
} => {
  const context = useContext(SystemCommunicationsContext);
  if (context === undefined) {
    throw new Error(
      'useSystemMessages must be used within a SystemCommunicationsProvider',
    );
  }
  return {
    subscribeToMessages: context.subscribeToMessages,
    // messages: context.messages,
  };
};

export const useSystemCommands = (): {
  subscribeToCommands: ISystemCommunicationsManager['subscribeToCommands'];
  // consumeCommand: ISystemCommunicationsManager['consumeCommand'];
} => {
  const context = useContext(SystemCommunicationsContext);
  if (context === undefined) {
    throw new Error(
      'useSystemCommands must be used within a SystemCommunicationsProvider',
    );
  }
  return {
    subscribeToCommands: context.subscribeToCommands,
    // consumeCommand: context.consumeCommand,
  };
};
