import React, { MutableRefObject, useEffect, useRef } from 'react';
import Countdown from 'react-countdown';
import Typography from '@mui/material/Typography';
import { CountdownRendererFn } from 'react-countdown/dist/Countdown';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

export interface SystemTimerProps {
  expirationTimestamp: Date | undefined;
  label: string;
  prefix?: string;
  completedMessage?: string;
  units?: boolean;
  embedded?: boolean;
}

/**
 * Component providing the services of a general 'countdown timer'.
 * @param expirationTimestamp
 * @param label
 * @param prefix
 * @param completedMessage
 * @param units
 * @param embedded
 * @constructor
 */
export const SystemTimer = ({
  expirationTimestamp,
  label,
  prefix = 'in',
  completedMessage = 'in progress',
  units = false,
  embedded = true,
}: SystemTimerProps) => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const timer = useRef<any>();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    (timer as MutableRefObject<Countdown | undefined>).current?.start();
  }, [expirationTimestamp]);

  const CompletionDisplay = () => (
    <div
      style={{
        width: isSmall ? (embedded ? '50px' : '100px') : '100%',
        textAlign: 'center',
        marginRight: isSmall ? '0px' : '20px',
      }}
    >
      <span>{label}</span>
      {isSmall ? <br /> : <>&nbsp;</>}
      <span>{completedMessage}</span>
    </div>
  );

  const renderer: CountdownRendererFn = ({
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      // Render a completed state
      return <CompletionDisplay />;
    } else {
      // Render a countdown
      const hrs = hours ? `${hours}${units ? 'h ' : ':'}` : '';
      const min = minutes
        ? `${minutes < 10 && hours ? '0' : ''}${minutes}${units ? 'm ' : ':'}`
        : '';
      const sec = `${seconds < 10 && minutes ? '0' : ''}${seconds}${
        units ? 's' : ''
      }`;
      return (
        <div
          style={{
            width: isSmall
              ? embedded
                ? '100%'
                : '100px'
              : embedded
                ? 'auto'
                : '200px',
            textAlign: 'center',
            marginRight: isSmall ? '0px' : '0px',
          }}
        >
          {label}
          {label ? (
            isSmall ? (
              embedded ? (
                <>&nbsp;</>
              ) : (
                <br />
              )
            ) : (
              <>&nbsp;</>
            )
          ) : null}
          {prefix}
          {prefix ? (
            isSmall ? (
              embedded ? (
                <>&nbsp;</>
              ) : (
                <>&nbsp;</>
              )
            ) : (
              <>&nbsp;</>
            )
          ) : null}
          {hrs}
          {min}
          {sec}
        </div>
      );
    }
  };

  return (
    <Typography
      component="div"
      color="rgba(0, 0, 0, 0.60)"
      pb="8px"
      style={{
        width: '100%',
        textAlign: 'center',
      }}
    >
      <div
        style={{
          width: isSmall
            ? embedded
              ? 'auto'
              : 'inherit'
            : embedded
              ? 'auto'
              : '180px',
          marginLeft: '4px',
          marginRight: '4px',
          justifyContent: 'right',
        }}
      >
        <Countdown
          renderer={renderer}
          ref={timer}
          autoStart={true}
          date={expirationTimestamp}
        ></Countdown>
      </div>
    </Typography>
  );
};
