import React from 'react';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';

import { useMediaQuery, useTheme } from '@mui/material';

import {
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';

import { Notifier } from '../../system/services/notificationManager';
import DataTable from '../shared/data-table';
import {
  MobileListCard,
  MobileListDataTableProps,
} from '../shared/mobile-list-card';

import {
  IngestionsQuery,
  useRemoveIngestionMutation,
} from '../../../types/generated-types';

export interface IngestionsTableProps {
  ingestions: IngestionsQuery['ingestions'];
  loading: boolean;
}

interface RemoveIngestionDialogProps {
  open: boolean;
  handleClose: () => void;
  removeIngestion: () => void;
}

const RemoveIngestionDialog = (props: RemoveIngestionDialogProps) => {
  const { open, handleClose, removeIngestion } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Typography variant="h4" sx={{ padding: '15px' }}>
        Remove Ingestion
      </Typography>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure? this action cannot be reversed
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ color: '#fff' }}
          onClick={() => {
            removeIngestion();
            handleClose();
          }}
          autoFocus
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Table that lists all in-progress or completed ingestions
export function IngestionsTable(props: IngestionsTableProps) {
  const { ingestions, loading } = props;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  /* State */
  // Sort records from most to least recent by default
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: 'date', sort: 'desc' },
  ]);
  const [ingestionId, setIngestionId] = React.useState<string>('');
  const [openDialog, setRemoveIngestionDialog] = React.useState(false);

  /* Queries, Mutations, Subscriptions */
  const [removeIngestion] = useRemoveIngestionMutation({
    onCompleted() {
      Notifier.success('Removed ingestion record');
    },
    onError() {
      Notifier.error('Error removing ingestion record');
    },
  });

  const handleRemoveIngestionClose = () => {
    setRemoveIngestionDialog(false);
  };

  const handleRemoveIngestion = async () => {
    await removeIngestion({ variables: { id: ingestionId } });
    setIngestionId('');
  };

  // Map ingestion records to rows
  const rows = ingestions.map((ingestion) => ({
    ...ingestion,
    id: ingestion._id,
    ingestedBy: ingestion.user?.name || 'Unknown user',
    removeIngestion: () => {
      setIngestionId(ingestion._id);
      setRemoveIngestionDialog(true);
    },
  }));

  const dateColumn = isSmall
    ? ({
        field: 'date',
        headerName: 'Date Ingested',
        align: 'center',
        flex: 1,
        renderCell: (params: GridRenderCellParams<Partial<any>>) => {
          return (
            <MobileListCard>
              <Typography
                sx={{ fontSize: 14, fontWeight: 800 }}
                color="text.secondary"
                gutterBottom
              >
                {dayjs(params.value).format('MM/DD/YYYY h:mma')}
              </Typography>
              <Typography variant="h5" component="div">
                File Name: {params.row.fileName}
              </Typography>
              <Typography sx={{ fontSize: 10 }} color="text.secondary">
                Ingested By: {params.row.ingestedBy}
              </Typography>
              <Typography sx={{ fontSize: 10 }} color="text.secondary">
                Status: {params.row.status}
              </Typography>
            </MobileListCard>
          );
        },
      } as GridColDef)
    : {
        field: 'date',
        headerName: 'Date ingested',
        width: 180,
        valueFormatter: (params: any) =>
          dayjs(params.value).format('MM/DD/YYYY h:mma'),
      };

  const columns: GridColDef[] = [
    { ...dateColumn },
    { field: 'fileName', headerName: 'File name', width: 250 },
    { field: 'ingestedBy', headerName: 'Ingested by', width: 200 },
    {
      field: 'status',
      headerName: 'Status',
      width: 170,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton
          // title="Remove ingestion record. The property will not be deleted."
          onClick={params.row?.removeIngestion}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        cursor: 'pointer',
        maxWidth: isSmall ? 'unset' : 'calc(100vw - 67px)',
      }}
    >
      <DataTable
        {...(isSmall ? MobileListDataTableProps : {})}
        columns={columns}
        rows={rows}
        rowHeight={isSmall ? 100 : undefined}
        columnVisibilityModel={{
          action: !isSmall,
          fileName: !isSmall,
          ingestedBy: !isSmall,
          status: !isSmall,
        }}
        sortModel={sortModel}
        onSortModelChange={(model: any) => {
          //TODO: Peter: INCLUDE TYPE
          setSortModel(model);
        }}
        loading={loading}
      />
      <RemoveIngestionDialog
        open={openDialog}
        handleClose={() => handleRemoveIngestionClose()}
        removeIngestion={() => handleRemoveIngestion()}
      />
    </div>
  );
}
