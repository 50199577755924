import React from 'react';

import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

import { Notifier } from '../../../system/services/notificationManager';
import { useSendTestEmailMutation } from '../../../../types/generated-types';

import { validateEmailAddress } from '../../../../common/helpers';

export const EmailOptInEmailAddressInput = (props: {
  id: string;
  index: number;
  propertyId: string;
  email: string;
  onChange: StandardInputProps['onChange'];
  isSmall: boolean;
  touched: boolean;
  errorText: string | undefined;
}) => {
  const {
    id,
    index,
    propertyId,
    email,
    onChange,
    isSmall,
    touched,
    errorText,
  } = props;

  const [sendTestEmail] = useSendTestEmailMutation();

  async function handleSendTestEmail(
    e:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    destinationEmail: string,
    propertyId: string,
  ) {
    e.preventDefault();

    try {
      await sendTestEmail({
        variables: {
          destinationEmail,
          propertyId,
        },
      });
      Notifier.success('Test email sent successfully.');
    } catch (mailSendError) {
      console.log(
        '[Property Editor][Alert Config] mailSendError',
        mailSendError,
      );
      Notifier.warn(
        'Unable to send test email. Check the system configuration and email address.',
      );
    }
  }
  return (
    <TextField
      sx={{
        padding: '0px',
        '& .MuiInputLabel-root': {
          margin: `${email ? 4 : -12}px 0px 0px 0px`,
        },
        '& .MuiInputLabel-root.Mui-focused': {
          margin: '4px 0px 0px 0px',
        },
        '& .MuiInputBase-root': {
          marginTop: email ? '12px' : '0px',
        },
        '& .MuiInputBase-root.Mui-focused': {
          marginTop: '12px',
        },
      }}
      fullWidth
      id={id}
      name={`alertEmail${index === 0 ? 'One' : index === 1 ? 'Two' : 'Three'}`}
      variant="standard"
      label="Email"
      value={email}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <Button
            color="info"
            sx={{
              width: isSmall ? '100px' : '220px',
              m: '6px',
              p: '0px 4px',
              border: '1px solid darkgrey',
            }}
            aria-label="directions"
            disabled={!propertyId || !validateEmailAddress(email)}
            onClick={async (e) => {
              await handleSendTestEmail(e, email, propertyId);
            }}
          >
            {isSmall ? 'Test' : 'Send Test Email'}
          </Button>
        ),
      }}
      error={touched && Boolean(errorText)}
      helperText={touched && errorText}
    />
  );
};
