import React from 'react';
import {
  SetpointProfileUpdateVerificationData,
  ThermostatScheduleTemplate,
  useScheduleTemplateBySetpointProfileIdQuery,
  useSetpointProfileUpdateVerificationQuery,
} from '../../../types/generated-types';
import { SetpointProfileUpdateVerification } from '../setpoint-limit-profiles/types/setpointLimitProfileDetailFormValues';
import BorderedSection from './borderedSection';
import { Notifier } from '../../system/services/notificationManager';
import { Typography } from '@mui/material';
import { ApolloError } from '@apollo/client';
import { useParams } from 'react-router-dom';

interface ThermostatScheduleTemplateRowData {
  _id: string;
}

export const VerifySetpointProfileUpdate = ({
  initialValues,
  values,
  force = false,
  dataReadyCallback,
}: {
  initialValues?: SetpointProfileUpdateVerification;
  values: SetpointProfileUpdateVerification;
  force?: boolean;
  dataReadyCallback: (dataReady: boolean) => void;
}) => {
  const profileId = initialValues?._id ?? values?._id;
  const { unitId } = useParams<{ unitId: string }>();

  const [changedPropertyIds, setChangedPropertyIds] = React.useState<string[]>(
    [],
  );
  const [changedUnitId, setChangedUnitId] = React.useState<string | undefined>(
    undefined,
  );
  const [queryData, setQuerydata] = React.useState<
    SetpointProfileUpdateVerificationData | undefined
  >(undefined);
  const [scheduleTemplates, setScheduleTemplates] = React.useState<
    ThermostatScheduleTemplateRowData[]
  >([]);
  const [queryError, setQueryError] = React.useState<ApolloError>();

  const { loading: loadingTemplateList } =
    useScheduleTemplateBySetpointProfileIdQuery({
      variables: {
        setpointProfileId: profileId || '0',
      },
      fetchPolicy: 'network-only',
      onCompleted(data) {
        if (data?.thermostatScheduleTemplates) {
          setScheduleTemplates(data?.thermostatScheduleTemplates);
        }
      },
    });

  const { loading } = useSetpointProfileUpdateVerificationQuery({
    variables: {
      id: profileId || '0',
      propertyIds: changedPropertyIds,
      unitId: changedUnitId,
    },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      console.log('[verify-splp] [on-completed] data', data);
      if (data?.setpointProfileUpdateVerification) {
        dataReadyCallback(true);
        setQuerydata(data.setpointProfileUpdateVerification);
      }
    },
    onError(error) {
      console.error(error);
      setQueryError(error);
      Notifier.error(
        'An error occurred validating your selection. Please retry.',
      );
    },
  });

  React.useEffect(() => {
    const myUnitId = values.unitId ?? unitId ?? undefined;
    if (force || values?._id === '0' || !values._id) {
      setChangedUnitId(myUnitId);
      setChangedPropertyIds(values.properties ?? []);
    } else if (initialValues) {
      if (myUnitId || initialValues.unitId) {
        if (myUnitId !== initialValues.unitId) {
          setChangedUnitId(myUnitId);
        } else {
          setChangedUnitId(undefined);
        }
      } else {
        const hasChangedSetpoints =
          initialValues.minCoolSetpointLimit !== values.minCoolSetpointLimit ||
          initialValues.minHeatSetpointLimit !== values.minHeatSetpointLimit ||
          initialValues.maxCoolSetpointLimit !== values.maxCoolSetpointLimit ||
          initialValues.maxHeatSetpointLimit !== values.maxHeatSetpointLimit;

        let changedPropIds = values.properties;

        if (!hasChangedSetpoints) {
          const initialPropIds = (values.properties ?? []) as Array<string>;
          const newPropIds = (initialValues.properties ?? []) as Array<string>;
          changedPropIds = newPropIds.filter(
            (id) => !initialPropIds.includes(id),
          );
        }
        setChangedPropertyIds(changedPropIds ?? []);
      }
    } else {
      setChangedUnitId(myUnitId);
      setChangedPropertyIds(values.properties ?? []);
    }
  }, [values, force, unitId]);

  return queryError ? (
    <Typography>Error: {queryError.name} </Typography>
  ) : loading ? (
    <Typography>Data loading ...</Typography>
  ) : queryData ? (
    <div>
      <h3>Should you proceed, your update will have the following impact:</h3>
      <BorderedSection title="Affected Device Statistics">
        <div>
          <ul>
            <li>
              <strong>All possible affected Thermostats:</strong>{' '}
              {queryData.totalDevices}
            </li>
            <li>
              <strong>Thermostats that have been installed:</strong>{' '}
              {queryData.configuredDevices}
            </li>
            <li>
              <strong>Installed thermostats that are online:</strong>{' '}
              {queryData.onlineDevices}
            </li>
            <li>
              <strong>Online Thermostats using unit-level profile:</strong>{' '}
              {queryData.excludedDevicesUnit}
            </li>
            <li>
              <strong>Online Thermostats using custom profile:</strong>{' '}
              {queryData.excludedDevicesCustom}
            </li>
            <li>
              <strong>Net Thermostats to be updated:</strong>{' '}
              {(force && (queryData.onlineDevices ?? 0)) ||
                (queryData.onlineDevices ?? 0) -
                  (queryData.excludedDevicesCustom ?? 0) -
                  (queryData.excludedDevicesUnit ?? 0)}
            </li>
          </ul>
        </div>
      </BorderedSection>
      <br />
      <BorderedSection title="Affected Schedule Template Statistics">
        <div>
          <ul>
            <li>
              <strong>All possible affected Schedule Templates:</strong>{' '}
              {!loadingTemplateList ? scheduleTemplates.length : null}
            </li>
          </ul>
        </div>
      </BorderedSection>
    </div>
  ) : null;
};
