import { UnitScheduleOverview } from './unit-schedule-overview';
import { UnitStatic } from './unit-static';

export type ViewOptionKey = 'Overview' | 'Static';

export interface ViewOption {
  type: ViewOptionKey;
  data?: any;
  view: (props: any) => JSX.Element;
  routeKey: string;
}

export const viewOptionsMap: Record<ViewOptionKey, ViewOption> = {
  Overview: {
    type: 'Overview',
    routeKey: 'overview',
    view: UnitScheduleOverview,
  },
  Static: {
    type: 'Static',
    routeKey: 'static',
    view: UnitStatic,
  },
};
