/** @jsxImportSource @emotion/react */
import React, { lazy, Suspense, useState, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import { css } from '@emotion/react';
import FormGroup from '@mui/material/FormGroup';

/**
 * Password collection field used on the password reset workflow screen.
 * collects the candidate password and determines if that value meets the
 * minimum complexity rules for the field.
 * @param onValidityChange
 * @param onChange
 * @param initialValue
 * @param id
 * @param required
 * @param fullWidth
 * @param label
 * @param name
 * @constructor
 */
export const PasswordInputField = ({
  onValidityChange,
  onChange,
  initialValue,
  id = 'password',
  required = true,
  fullWidth = true,
  label = id,
  name = id,
}: {
  id?: string;
  required?: boolean;
  fullWidth?: boolean;
  label?: string;
  name?: string;
  onValidityChange?: (valid: boolean) => void;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  initialValue?: string;
}) => {
  const [dirty, setDirty] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialValue ?? '');

  const handleValidityChange = (isValid: boolean) => {
    setValid(isValid);
    onValidityChange && onValidityChange(isValid);
  };

  const PasswordValueChecker = useMemo(
    () => lazy(() => import('../helpers/PasswordValueChecker')),
    [],
  );

  return (
    <FormGroup>
      <TextField
        required={required}
        fullWidth={fullWidth}
        id={id}
        name={name}
        label={label}
        value={value}
        type="text"
        autoComplete="new-password"
        onChange={(event) => {
          !dirty && setDirty(true);
          setValue(event.target.value);
          onChange && onChange(event);
        }}
        style={{ width: '100%', minWidth: '280px' }}
        css={
          !valid && dirty
            ? css`
                fieldset {
                  border-color: red !important;
                  border-width: 2px !important;
                }
              `
            : ''
        }
      />
      <Suspense fallback={<div>Loading validation library ...</div>}>
        <PasswordValueChecker
          value={value}
          dirty={dirty}
          onValidityChange={handleValidityChange}
        />
      </Suspense>
    </FormGroup>
  );
};
