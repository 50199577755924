import React from 'react';
import Paper from '@mui/material/Paper';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { MultiSelectProps } from './multi-select';
import { TransferList } from './transfer-list';

export function MultiSelectMany<T>(props: MultiSelectProps<T>) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>();
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Paper sx={{ display: 'flex' }}>
      <Stack>
        <InputLabel
          sx={{
            backgroundColor: '#ffffff',
            padding: '1px 4px',
            borderRadius: '4px',
          }}
          id="buildings-label"
        >
          Filter by {props.label} Name
        </InputLabel>
        <OutlinedInput
          sx={{ input: { cursor: 'pointer' } }}
          placeholder="Filter By Building Name"
          value={props.renderValue()}
          inputProps={{ 'aria-label': 'search google maps' }}
          endAdornment={<ArrowDropDown />}
          onClick={handleClick}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              sx: {
                maxWidth: '600px',
                width: '94%',
              },
            },
          }}
          onClose={handleClose}
        >
          <TransferList<T>
            {...props}
            onDone={props.onChange}
            onCancel={handleClose}
          />
        </Popover>
      </Stack>
    </Paper>
  );
}
