import React from 'react';
import { Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { ViewUnitContentProps } from '../../types/viewUnitContentProps';

export const ViewResidentContent = ({
  unit,
  property,
}: ViewUnitContentProps) => {
  const { address, propMgr, owner } = property || {};

  const { line1, city, state, postalCode } = address || {};
  const addressLine = `${line1 || 'Unknown Address'}`;
  const cityStateZip = `${city || 'Unknown City'}, ${state || '??'}  ${
    postalCode || '00000'
  }`;
  const fullAddress = `${addressLine}, ${cityStateZip}`;

  return unit && property ? (
    <Container>
      {unit.resident?.name || unit.resident?.email ? (
        <>
          <Typography sx={{ fontWeight: 'bolder' }}>
            Resident Contact
          </Typography>
          <br />
          <Stack direction="column">
            <span>Full Name: {unit.resident?.name ?? 'N/A'}</span>
            <span>Email: {unit.resident?.email ?? 'N/A'}</span>
          </Stack>
          <Stack>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <span>Phone: {unit.resident?.phone ?? 'N/A'} </span>
              </Grid>
              <Grid item xs={6}>
                <span>Mobile: {unit.resident?.mobile ?? 'N/A'}</span>
              </Grid>
            </Grid>
          </Stack>
          <br />
        </>
      ) : (
        <Typography sx={{ marginBottom: '10px' }}>
          <span style={{ fontWeight: 'bolder', marginRight: '4px' }}>
            Resident Contact:
          </span>
          <span>No registered resident</span>
        </Typography>
      )}
      <Divider />
      <Typography
        sx={{
          marginTop: '10px',
        }}
      >
        Property Information
      </Typography>
      <br />
      <Stack direction="column" style={{ marginLeft: '6px' }}>
        <div>
          <span style={{ fontWeight: 'bold', marginRight: '4px' }}>Title:</span>
          <span>{property?.title ?? 'N/A'}</span>
        </div>
        <div>
          <span style={{ fontWeight: 'bold', marginRight: '4px' }}>
            Address:
          </span>
          <span>{fullAddress}</span>
        </div>
        <div>
          <span style={{ fontWeight: 'bold', marginRight: '4px' }}>
            Manager:
          </span>
          <span>{propMgr?.name ?? 'Unnamed Manager'}</span>
        </div>
        <div>
          <span style={{ fontWeight: 'bold', marginRight: '4px' }}>Owner:</span>
          <span>{owner?.name ?? 'Unnamed Owner'}</span>
        </div>
      </Stack>
    </Container>
  ) : null;
};
