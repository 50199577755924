import React from 'react';
import { MultiSelectFew } from './multi-select-few';
import { MultiSelectMany } from './multi-select-many';

export interface MultiSelectProps<T> {
  selectables: T[];
  selected: T[];
  label: string;
  groupId?: keyof T;
  maxSelectSize?: number;
  onChange: (selection: string[]) => void;
  renderValue: () => string;
  itemLabel: (a: T) => string;
  itemValue: (a: T) => string;
  groupLabel?: (groupId: string) => string;
}

/**
 * MultiSelect:  A generic multi-select component that renders either a checkbox style drop-down or
 * a transfer-list, depending on the provided 'maxSelectSize' parameter.
 * @param props
 * @prop { object[] } selectables - a list of items {objects} from which to select
 * @prop { object[] } selected - the list of currently selected items
 * @prop groupId - optional: a key value of the 'selectables' type by which to group the list
 * @prop { number } maxSelectSize - the number above which the select control becomes a transfer-list
 * @callback onChange - takes as parameter an array of strings by which the selectable objects are identified
 * @callback renderValue - generates the label displayed on the parent input text-field
 * @callback itemLabel - getter function retrieves the 'display-name' displayed in the select list
 * @callback itemValue - getter function retrieves the 'identifier' field of the selectable object
 * @callback groupLabel - optional: given a 'groupId', this is the getter function that retrieves the 'group header label'
 * @returns <MultiSelectFew /> or <MultiSelectMany />
 */
export function MultiSelect<T>(props: MultiSelectProps<T>) {
  const MAX_SELECT_SET_SIZE = props.maxSelectSize ?? 5;
  return props.selectables.length > MAX_SELECT_SET_SIZE ? (
    <MultiSelectMany<T> {...props} />
  ) : (
    <MultiSelectFew<T> {...props} />
  );
}
