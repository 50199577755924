import React, { CSSProperties, useEffect, useState } from 'react';
import {
  PressureSensor,
  usePressureSensorDetailQuery,
  usePressureSensorDetailUpdateSubscription,
} from '../../../../types/generated-types';
import BorderedSection from '../../shared/borderedSection';
import { DisplayValue } from '../shared-ui';
import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';

export function PressureSensorPairComponent({
  supplyId,
  returnId,
  label,
  style = {},
}: {
  supplyId: string;
  returnId: string;
  label: string;
  style?: CSSProperties | undefined;
}) {
  const [supplyDevice, setSupplyDevice] = useState<Partial<PressureSensor>>();
  const [returnDevice, setReturnDevice] = useState<Partial<PressureSensor>>();

  const [delta, setDelta] = useState<number>();

  const {
    data: supplyPressureSensor,
    // TODO: Loading_error: handle loading/errors for query.
    // loading: loadingSupply,
    // error: supplyError,
  } = usePressureSensorDetailQuery({
    variables: { id: supplyId },
  });

  const {
    data: returnPressureSensor,
    // TODO: Loading_error: handle loading/errors for query.
    // loading: loadingReturn,
    // error: returnError,
  } = usePressureSensorDetailQuery({
    variables: { id: returnId },
  });

  usePressureSensorDetailUpdateSubscription({
    variables: { ids: [supplyId, returnId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (supplyPressureSensor?.pressureSensorById) {
      setSupplyDevice(
        supplyPressureSensor.pressureSensorById as Partial<PressureSensor>,
      );
    }
  }, [supplyPressureSensor]);

  useEffect(() => {
    if (returnPressureSensor?.pressureSensorById) {
      setReturnDevice(
        returnPressureSensor.pressureSensorById as Partial<PressureSensor>,
      );
    }
  }, [returnPressureSensor]);

  useEffect(() => {
    if (
      supplyDevice?.pressure?.value !== undefined &&
      supplyDevice?.pressure?.value !== null &&
      returnDevice?.pressure?.value !== undefined &&
      returnDevice?.pressure?.value !== null
    ) {
      setDelta(
        Math.abs(supplyDevice.pressure.value - returnDevice.pressure.value),
      );
    }
  }, [supplyDevice, returnDevice]);

  return delta !== undefined && delta !== null ? (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        ...style,
      }}
    >
      <BorderedSection
        title={label}
        labelStyle={{ fontSize: '10px' }}
        style={{ height: '45px' }}
        ccStyle={{ padding: '6px' }}
      >
        <DisplayValue
          value={delta}
          units={'psi'}
          valueSize={'16px'}
          unitsSize={'12px'}
          style={{ width: '100%', textAlign: 'center' }}
          blankZeros={true}
        />
      </BorderedSection>
    </div>
  ) : (
    <DeviceLoadingSkeleton sx={{ margin: '2px' }} />
  );
}
