import React, { useCallback, useEffect, useState } from 'react';
import { SourceDetailForm } from '../source-detail-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Bng,
  Hmm,
  Core,
  useSourceDetailEditQuery,
  useRemoveCoreMutation,
  useRemoveHmmMutation,
  useRemoveBngMutation,
  useInitializeSourceMutation,
  SourceDetailEditQuery,
  Source,
} from '../../../../types/generated-types';
import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Notifier } from '../../../system/services/notificationManager';
import {
  BottomMenuItem,
  useInjectableComponents,
} from '../../../system/services/injectableComponentsManager';
import { Remove, TaskAlt } from '@mui/icons-material';
import FullWidthLoadingSkeleton from '../../shared/fullWidthLoadingSkeleton';

type MySource = SourceDetailEditQuery['sourceById'];
export const SourceDetailHandler = () => {
  const { id } = useParams<{ id: string }>();
  const [source, setSource] = useState<MySource>();
  const { data, error, loading } = useSourceDetailEditQuery({
    variables: { id: id ?? '' },
  });

  useEffect(() => {
    if (error) {
      Notifier.error(
        'There was a problem fetching the source information: ',
        error,
      );
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setSource(data.sourceById);
    }
  }, [data]);

  const navigate = useNavigate();

  const [initializeSource] = useInitializeSourceMutation();
  const [deleteCore] = useRemoveCoreMutation();
  const [deleteHmm] = useRemoveHmmMutation();
  const [deleteBng] = useRemoveBngMutation();

  const [pushMeta, setPushMeta] = useState(false);
  const [pushHistoricReadings, setPushHistoricReadings] = useState(false);

  const handleInitializeSource = useCallback(async () => {
    try {
      await initializeSource({
        variables: {
          id: id ?? '',
          options: { pushMeta, pushHistoricReadings },
        },
      });

      Notifier.success('Source initialized');
    } catch (e) {
      Notifier.error('Error initializing Source');
      console.error(e);
    } finally {
      setPushMeta(false);
      setPushHistoricReadings(false);
    }
  }, [id, pushMeta, pushHistoricReadings, initializeSource]);

  const handleRemoveSource = async () => {
    if (id && source) {
      const type = source.type;
      switch (type) {
        case 'Core':
          try {
            await deleteCore({
              variables: { id },
              refetchQueries: ['SourceList'],
            });
            Notifier.success('Core deleted');
            navigate('/sources');
          } catch (e) {
            Notifier.error('Unable to delete Core');
          }
          break;
        case 'BNG':
          try {
            await deleteBng({
              variables: { id },
              refetchQueries: ['SourceList'],
            });
            Notifier.success('BNG deleted');
            navigate('/sources');
          } catch (e) {
            Notifier.error('Unable to delete BNG');
          }
          break;
        case 'HMM':
          try {
            await deleteHmm({
              variables: { id },
              refetchQueries: ['SourceList'],
            });
            Notifier.success('HMM deleted');
            navigate('/sources');
          } catch (e) {
            Notifier.error('Unable to delete HMM');
          }
          break;
        default:
      }
    } else {
      Notifier.error('No source found: Check URL parameter');
    }
  };

  const { setContextMenuItems } = useInjectableComponents();

  useEffect(() => {
    if (source && !error) {
      const initAction = {
        actionLabel: 'Initialize',
        cancelLabel: 'Cancel',
        title: `Initialize ${source.type ?? 'Source'}`,
        prompt:
          'Initializing this Source will set/update the push destination and configuration, and send all applicable Zone Policies. Are you sure?',
        customContent: (
          <>
            <h4>Advanced options</h4>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    value={pushMeta}
                    onChange={(_, checked) => {
                      setPushMeta(checked);
                    }}
                  />
                }
                label="Request Source to push Meta for all devices"
              />
            </FormGroup>
            {source.type === 'Core' && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      value={pushHistoricReadings}
                      onChange={(_, checked) => {
                        setPushHistoricReadings(checked);
                      }}
                    />
                  }
                  label="Request Source to push Historic Readings for all devices"
                />
              </FormGroup>
            )}
          </>
        ),
        action: handleInitializeSource,
      };
      const removeAction = {
        actionLabel: 'Remove',
        cancelLabel: 'Cancel',
        title: `Remove ${source.type ?? 'Source'}`,
        prompt: 'Are you sure? This action cannot be reversed',
        action: handleRemoveSource,
      };

      const items: BottomMenuItem[] = [];
      if (source.type === 'Core' || source.type === 'HMM') {
        // Only Cores and HMMs can be initialized
        items.push({
          id: 'initialize-source-menu-item',
          label: 'Initialize Source',
          icon: <TaskAlt fontSize="small" />,
          confirmableAction: initAction,
          permit: {
            action: 'update',
            subject: source as Source,
          },
        });
      }

      items.push({
        id: 'remove-source-menu-item',
        label: 'Remove Source',
        icon: <Remove fontSize="small" />,
        confirmableAction: removeAction,
        permit: {
          action: 'delete',
          subject: source as Source,
        },
      });

      setContextMenuItems(items.length > 0 ? [{ items }] : undefined);
    } else {
      setContextMenuItems(undefined);
    }
    return () => {
      setContextMenuItems(undefined);
    };
  }, [source, error, handleInitializeSource, pushMeta, pushHistoricReadings]);

  return error ? (
    <div>Unable to access requested source.</div>
  ) : loading ? (
    <FullWidthLoadingSkeleton />
  ) : (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        height: '100%',
        overflowY: 'scroll',
      }}
    >
      <Typography variant="h3" sx={{ fontSize: '1.4rem', margin: '1rem' }}>
        {data?.sourceById ? data?.sourceById?.name : 'Create Source'}
      </Typography>
      {data?.sourceById ? (
        <SourceDetailForm source={source as Partial<Core | Bng | Hmm>} />
      ) : (
        <SourceDetailForm />
      )}
    </div>
  );
};
