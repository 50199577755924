import { Base, CustomType } from '../../system/models/base';
import {
  AUTH_ID_TOKEN_NAME,
  AUTH_IDENTIFY_USER_TOKEN_NAME,
  AUTH_REMEMBER_ME_TOKEN_NAME,
  AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME,
  AUTH_SESSION_TOKEN_NAME,
  AUTH_SESSION_EXPIRATION_TOKEN_NAME,
  AUTH_STATE_TOKEN_NAME,
} from '../../../helpers/config';

// noinspection DuplicatedCode
export const tokenNames = [
  AUTH_REMEMBER_ME_TOKEN_NAME,
  AUTH_ID_TOKEN_NAME,
  AUTH_STATE_TOKEN_NAME,
  AUTH_SESSION_TOKEN_NAME,
  AUTH_SESSION_EXPIRATION_TOKEN_NAME,
  AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME,
  AUTH_IDENTIFY_USER_TOKEN_NAME,
];

export interface IServerToken {
  name: string;
  value?: unknown;
  expiration?: number;
  remove?: boolean;
}

export class ServerToken extends Base implements IServerToken {
  name: string;
  value?: unknown;
  expiration?: number;
  remove?: boolean;

  static EJSONTypeName = 'ServerToken';

  static fromJSONValue(deserializedToken: unknown) {
    return new ServerToken(deserializedToken as IServerToken);
  }

  constructor(token: IServerToken) {
    super(token);
    this.name = token.name ?? 'Unnamed token';
    this.value = token.value;
    this.expiration = token.expiration;
    this.remove = token.remove === true;
  }

  equals(other: CustomType): boolean {
    return (
      other instanceof ServerToken &&
      this.name === other.name &&
      this.value === other.value &&
      this.expiration === other.expiration &&
      this.remove === other.remove
    );
  }

  toJSONValue(): IServerToken {
    return Object.assign(super.toJSONValue(), {
      name: this.name,
      value: this.value,
      expiration: this.expiration,
      remove: this.remove,
    });
  }
}

ServerToken.registerEJSONType();
