import React from 'react';
import { useFormik } from 'formik';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { Fade, IconButton, Switch } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import BorderedSection from '../../shared/borderedSection';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormValues } from '../types/userDetailFormValues';
import { AuthorizedUser } from '../../../auth/models';
import useCopyToClipboard from '../../../ui/helpers/copy-to-clipboard';
import { Notifier } from '../../../system/services/notificationManager';
import CopyIcon from '@mui/icons-material/CopyAllRounded';
export function UserGeneralTab(props: {
  currentUser: AuthorizedUser;
  formik: ReturnType<typeof useFormik<FormValues>>;
  apiToken: string | null | undefined;
  onChange: StandardInputProps['onChange'];
}) {
  const [clipboardValue, copyToClipboard] = useCopyToClipboard();
  return (
    <Fade in={true} style={{ transitionDuration: '1100ms' }}>
      <div style={{ width: '100%' }}>
        <TextField
          fullWidth
          autoFocus
          id="name"
          name="name"
          placeholder="User Name"
          label="Full Name"
          value={props.formik.values.name}
          onChange={props.onChange}
          error={props.formik.touched.name && Boolean(props.formik.errors.name)}
          helperText={props.formik.touched.name && props.formik.errors.name}
        />
        <br />
        <br />
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={props.formik.values.email}
          onChange={props.onChange}
          error={
            props.formik.touched.email && Boolean(props.formik.errors.email)
          }
          helperText={props.formik.touched.email && props.formik.errors.email}
        />
        <br />
        <br />
        <TextField
          id="status"
          name="status"
          fullWidth
          select
          label="Status"
          defaultValue="new"
          value={props.formik.values.status}
          onChange={props.onChange}
          helperText={props.formik.touched.status && props.formik.errors.status}
        >
          <MenuItem key="new" value="new">
            New
          </MenuItem>
          <MenuItem key="active" value="active">
            Active
          </MenuItem>
          <MenuItem key="inactive" value="inactive">
            Inactive
          </MenuItem>
        </TextField>
        <br />
        <br />
        <BorderedSection
          title="Role Assignment"
          error={
            (props.formik.errors.roleSelected &&
              (props.formik.touched.isManager ||
                props.formik.touched.isInstaller ||
                props.formik.touched.isResident ||
                props.formik.touched.isSuper)) ||
            false
          }
          hint={props.formik.errors.roleSelected}
        >
          <FormGroup style={{ paddingLeft: '6px' }}>
            <FormControlLabel
              disabled={!props.currentUser.isSuper}
              control={
                <Switch
                  size="small"
                  onChange={props.onChange}
                  name="isSuper"
                  id="isSuper"
                  checked={props.formik.values.isSuper}
                />
              }
              label="Super User"
            />
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  name="isManager"
                  id="isManager"
                  onChange={props.onChange}
                  checked={props.formik.values.isManager}
                />
              }
              label="Manager"
            />
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  name="isInstaller"
                  id="isInstaller"
                  onChange={props.onChange}
                  checked={props.formik.values.isInstaller}
                />
              }
              label="Installer"
            />
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  name="isResident"
                  id="isResident"
                  onChange={props.onChange}
                  checked={props.formik.values.isResident}
                />
              }
              label="Resident"
            />
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  name="isViewer"
                  id="isViewer"
                  onChange={props.onChange}
                  checked={props.formik.values.isViewer}
                />
              }
              label="Viewer"
            />
          </FormGroup>
        </BorderedSection>
        {props.currentUser.isSuper && props.apiToken && (
          <BorderedSection style={{ marginTop: '16px' }} title="API Access">
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <div
                style={{
                  lineBreak: 'anywhere',
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1 1 auto',
                }}
              >
                {props.apiToken}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flex: '0 1 auto',
                  borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                  marginLeft: '10px',
                }}
              >
                <IconButton
                  onClick={async () => {
                    if (props.apiToken) {
                      await copyToClipboard(props.apiToken);
                      console.log('Copied to clipboard', clipboardValue);
                      Notifier.success('API Token copied to clipboard');
                    } else {
                      Notifier.error(
                        'No API Token available to copy to clipboard',
                      );
                    }
                  }}
                >
                  <CopyIcon />
                </IconButton>
              </div>
            </div>
          </BorderedSection>
        )}
      </div>
    </Fade>
  );
}
