import React, { useEffect } from 'react';

import {
  useParams,
  // useLocation,
} from 'react-router-dom';

import { Notifier } from '../../system/services/notificationManager';
import { useAuthenticator } from '../../auth/AuthenticationContext';
import { updateCacheFromSubscriptionEvent } from '../../../helpers/subscriptionUtils';
// import {
//   useInjectableComponents,
//   // BottomMenuItems,
// } from '../../system/services/injectableComponentsManager';
// import { basePropertyMenuItems } from './ui/base-property-context-menu';

import {
  MaterialReactTable,
  type MRT_ColumnDef,
  MRT_Row,
  useMaterialReactTable,
} from 'material-react-table';

import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import AddIcon from '@mui/icons-material/Add';

import {
  AlertTrigger,
  AlertTriggerTriggerCriteriaSelectionCriteria,
  Maybe,
  useAlertTriggerListByPropertyIdQuery,
  useAlertTriggerListByPropertyIdUpdateSubscription,
  // Property,
  // usePropertyQuery,
} from '../../../types/generated-types';

import {
  AlertTriggerEditMode,
  AlertTriggerComparatorValueType,
  AlertTriggerComparatorToStringMap,
  AlertTriggerSelectionBasis,
} from '../../../common/types';
import {
  EditableAlertTriggerSelectionCriteriaValue,
  RowData,
} from './types/propertyAlertTriggerRowData';
import { PropertyAlertTriggerCardView } from './property-alert-trigger-card-view';
import { AuthorizedUser } from '../../auth/models';

import {
  Celsius,
  CubicFeetPerMinute,
  Fahrenheit,
  LitersPerSecond,
} from '../../system/models/temperatureUnits';
import {
  convertToAirFlowUnits,
  formatAirflowString,
  convertToHumidityUnits,
  formatHumidityString,
  convertToPressureUnits,
  formatPressureString,
  convertToTempUnits,
  formatTemperatureString,
} from '../devices/util';

type DateFormatOptions = {
  day: '2-digit';
  month: '2-digit';
  year: 'numeric';
};
function formatDate(dateAsString: string): string {
  const date = new Date(dateAsString);
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  } as DateFormatOptions;
  const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
  return formattedDate;
}

// TODO: Peter: we should be converting between PSI and kPa using the conversion routine:
//  1 PSI = 6.89476 kPa.
//

export function processAlertTriggerSelectionCriterion(
  c: Maybe<AlertTriggerTriggerCriteriaSelectionCriteria>,
  user: AuthorizedUser | undefined,
  baseName: string = AlertTriggerSelectionBasis.DEVICE,
) {
  if (c === null) {
    return undefined;
  }
  const preferredTempUnits = user?.preferredUnits ?? Fahrenheit;
  const tempPrecision = preferredTempUnits === Fahrenheit ? 0 : 1;
  const attributeParts = (c.selectionPath ?? '').split('.');
  const attributeName =
    (attributeParts[attributeParts.length - 1] === 'value'
      ? attributeParts[0]
      : c.selectionPath) ?? 'Some attribute';
  const operator =
    AlertTriggerComparatorToStringMap[c.comparator] ?? 'Unknown comparator';
  let editableValue: EditableAlertTriggerSelectionCriteriaValue = c.value ?? '';
  let displayValue = c.value;
  let valueDisplayUnits = '';
  let valueConverter = (value: EditableAlertTriggerSelectionCriteriaValue) =>
    `${value}`;

  switch (c.valueType as unknown as AlertTriggerComparatorValueType) {
    case AlertTriggerComparatorValueType.BOOLEAN:
      editableValue = c.value === 'true';
      break;
    case AlertTriggerComparatorValueType.NUMBER:
      editableValue = parseFloat(c.value ?? '0');
      break;
    case AlertTriggerComparatorValueType.STRING:
      break;
    case AlertTriggerComparatorValueType.DATE:
      editableValue = new Date(c.value ?? 0);
      displayValue = formatDate(c.value ?? '0');
      break;
    case AlertTriggerComparatorValueType.AIRFLOW:
      editableValue =
        convertToAirFlowUnits(
          parseFloat(c.value ?? 0) ?? 0,
          user?.preferredAirFlowUnits ?? CubicFeetPerMinute,
          LitersPerSecond,
          0,
        ) ?? 0;
      displayValue = formatAirflowString(
        editableValue,
        user?.preferredAirFlowUnits ?? CubicFeetPerMinute,
      );
      valueDisplayUnits = `${
        user?.preferredAirFlowUnits ?? CubicFeetPerMinute
      }`;
      valueConverter = (value: EditableAlertTriggerSelectionCriteriaValue) => {
        const airflowValue = parseFloat(`${value}`);
        return `${
          convertToAirFlowUnits(
            airflowValue,
            LitersPerSecond,
            user?.preferredAirFlowUnits ?? CubicFeetPerMinute,
            0,
          ) ?? 0
        }`;
      };
      break;
    case AlertTriggerComparatorValueType.HUMIDITY:
      editableValue = convertToHumidityUnits(parseFloat(c.value ?? '0')) ?? 0;
      displayValue = formatHumidityString(editableValue);
      valueDisplayUnits = '%';
      break;
    case AlertTriggerComparatorValueType.PRESSURE:
      editableValue = convertToPressureUnits(parseFloat(c.value ?? '0')) ?? 0;
      displayValue = formatPressureString(editableValue);
      valueDisplayUnits = 'PSI';
      break;
    case AlertTriggerComparatorValueType.TEMPERATURE:
      editableValue =
        convertToTempUnits(
          parseFloat(c.value) ?? 0,
          preferredTempUnits,
          Celsius,
          tempPrecision,
        ) ?? 0;
      displayValue = formatTemperatureString(
        editableValue,
        user?.preferredUnits ?? Fahrenheit,
      );
      valueDisplayUnits = `\xB0${user?.preferredUnits ?? Fahrenheit}`;
      valueConverter = (value: EditableAlertTriggerSelectionCriteriaValue) => {
        const tempValue = parseFloat(`${value}`);
        return `${convertToTempUnits(
          tempValue,
          Celsius,
          preferredTempUnits,
          1,
        )}`;
      };
      break;
    default:
      break;
  }

  const formattedCriterion = `${baseName}'s ${attributeName} value ${operator} ${displayValue}`;
  const convertedCriterion = {
    ...c,
    displayValue: displayValue,
    editableValue: editableValue,
    valueDisplayUnits: valueDisplayUnits,
    valueConverter: valueConverter,
  } as RowData['rawCriteria'][number];
  return { formattedCriterion, convertedCriterion };
}

function formatData(
  data: Partial<AlertTrigger>[],
  user: AuthorizedUser | undefined,
): RowData[] {
  const formattedData: RowData[] = [];
  [...data]
    .sort((a, b) => {
      const nameA = a.name ?? '';
      const nameB = b.name ?? '';
      if (a.editMode !== b.editMode) {
        return a.editMode === AlertTriggerEditMode.LOCKED ? 1 : -1;
      } else {
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      }
    })
    .forEach((row) => {
      if (row?._id) {
        const criteria = row.triggerCriteria?.selectionCriteria || [];
        let entityTypes = row.entityTypes ? row.entityTypes : [];
        entityTypes = entityTypes.includes('Device')
          ? ['Any Device']
          : entityTypes.includes('Source')
            ? ['Any Source']
            : (entityTypes as Array<string>);

        const formattedCriteria: string[] = [];
        const convertedCriteria: RowData['rawCriteria'] = [];

        for (const c of criteria) {
          const processResult = processAlertTriggerSelectionCriterion(
            c,
            user,
            row.triggerCriteria?.basisType,
          );

          if (processResult) {
            const { convertedCriterion, formattedCriterion } = processResult;
            formattedCriteria.push(formattedCriterion);
            convertedCriteria.push(convertedCriterion);
          }
        }

        formattedData.push({
          id: row._id,
          name: row.name ?? 'Unnamed alert trigger',
          entityTypes: entityTypes,
          basisType: row.triggerCriteria?.basisType,
          basisName: row.triggerCriteria?.basisName,
          triggerCriteria: formattedCriteria,
          rawCriteria: convertedCriteria,
          enabled: !!row.enabled,
        });
      }
    });
  return formattedData;
}

export function PropertyAlertTriggersList({
  occupiedHeight = 0,
  innerRef = null,
  onRowClick = () => {},
}: {
  occupiedHeight?: number;
  innerRef?: React.RefObject<HTMLDivElement> | null;
  onRowClick?: (row: MRT_Row<RowData>) => void;
}) {
  const [alertTriggers, setAlertTriggers] = React.useState<RowData[]>([]);
  // TODO: Peter: Add this back in when we enhance trigger management (adding new ones, for example).
  //  const { setContextMenuItems } = useInjectableComponents();
  //  const { pathname } = useLocation();
  //  const { data: propertyData } = usePropertyQuery({ variables: { _id: id } });

  const { id } = useParams<{ id: string }>();

  const { data, error } = useAlertTriggerListByPropertyIdQuery({
    variables: { propertyId: id ?? '' },
    fetchPolicy: 'network-only',
  });

  const { user } = useAuthenticator();

  const [triggerColumns, setTriggerColumns] = React.useState<
    MRT_ColumnDef<RowData>[]
  >([]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useAlertTriggerListByPropertyIdUpdateSubscription({
    variables: { propertyId: id ?? '' },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (error) {
      Notifier.error('Error loading alert triggers: ' + error.message);
    } else if (data?.alertTriggersByPropertyId) {
      setAlertTriggers(formatData(data.alertTriggersByPropertyId, user));
    }
  }, [data, error, user]);

  useEffect(() => {
    const nameColumn: MRT_ColumnDef<RowData> = isSmall
      ? ({
          accessorKey: 'name',
          header: 'Name (* = disabled)',
          grow: true,
          Cell: PropertyAlertTriggerCardView,
        } as MRT_ColumnDef<RowData>)
      : {
          accessorKey: 'name',
          header: 'Name (* = disabled)',
          grow: true,
          muiTableBodyCellProps: ({ row }) => {
            return {
              sx: {
                '&::after': {
                  content: row.original.enabled ? '""' : '"\\00a0*"',
                },
              },
            };
          },
        };

    const columns: MRT_ColumnDef<RowData>[] = [
      nameColumn,
      ...((isSmall
        ? []
        : [
            {
              accessorKey: 'entityType',
              header: 'Type',
              size: 100,
              Cell: ({ row }) => {
                const types = row.original.entityTypes ?? [];
                return types.includes('Device') ? (
                  'Any Device'
                ) : types.includes('Source') ? (
                  'Any Source'
                ) : (
                  <div>
                    {types.map((type) => (
                      <div key={type}>{type}</div>
                    ))}
                  </div>
                );
              },
            },
            {
              accessorKey: 'triggerCriteria',
              header: 'Trigger Criteria',
              maxSize: 350,
              Cell: ({ row }) => {
                const triggerCriteria = row.original.triggerCriteria ?? [];
                return (
                  <div>
                    {triggerCriteria.map((type, index) => (
                      <div key={`${row.original.id}-${index}`}>{type}</div>
                    ))}
                  </div>
                );
              },
            },
          ]) as MRT_ColumnDef<RowData>[]),
    ];

    setTriggerColumns(columns);
  }, [isSmall]);

  // TODO: Peter: Alerts: Add this back in when we enhance the trigger editing feature.
  // useEffect(() => {
  //   if (propertyData?.property) {
  //     const contextMenuItems: BottomMenuItems = [
  //       ...basePropertyMenuItems(id, pathname, [
  //         {
  //           id: 'add-alert-trigger-menu-item',
  //           label: 'Add Alert Trigger',
  //           icon: <AddIcon />,
  //           navTarget: (path: string) => {
  //             return `/properties/${id}/alert-triggers/add-alert-trigger`;
  //             // const pathComponent = path.split('/').slice(0, 4);
  //             // return pathComponent.slice(0, 4).join('/') + '/add-alert-trigger';
  //           },
  //           permit: 'createAlertTrigger',
  //         },
  //       ]),
  //     ];
  //
  //     setContextMenuItems(contextMenuItems, propertyData.property as Property);
  //   }
  //   return () => {
  //     setContextMenuItems(undefined, undefined);
  //   };
  // }, [propertyData, id, pathname]);

  const table = useMaterialReactTable({
    layoutMode: 'grid',
    defaultColumn: {
      enableColumnActions: false,
      sortingFn: 'alphanumeric',
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    columns: triggerColumns,
    data: (alertTriggers ?? []) as RowData[],
    // enableColumnFilterModes: true,
    // enableColumnOrdering: true,
    // enableGrouping: true,
    // enableColumnPinning: true,
    // enableFacetedValues: true,
    // enableRowActions: true,
    // enableRowSelection: true,
    enableStickyHeader: true,
    enableDensityToggle: true,
    initialState: {
      density: 'compact',
      showColumnFilters: false,
      showGlobalFilter: false,
      columnOrder: ['name', 'entityType', 'triggerCriteria'],
    },
    renderTopToolbar: false,
    renderBottomToolbar: false,
    enablePagination: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiTableBodyProps: {
      sx: {
        '& tr:nth-of-type(even)': {
          backgroundColor: 'rgba(241, 241, 241, 0.3) !important',
        },
      },
    },
    muiTableBodyRowProps: ({ row }) => {
      return {
        onClick: (event) => {
          event.preventDefault();
          event.stopPropagation();
          onRowClick(row);
        },
        sx: {
          height: 'auto',
          fontStyle: !row.original.enabled ? 'italic !important' : 'inherit',
          '& td': {
            color: !row.original.enabled ? 'red !important' : 'inherit',
          },
          '& div': {
            color: !row.original.enabled ? 'red !important' : 'inherit',
          },
        },
      };
    },
    muiTableContainerProps: {
      style: {
        maxHeight: `calc(100vh - ${occupiedHeight}px)`,
      },
    },
  });

  return (
    <Box
      ref={innerRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        cursor: 'pointer',
        // height: '500px',
      }}
    >
      <MaterialReactTable table={table} />
    </Box>
  );
}
