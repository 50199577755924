import { Celsius, Fahrenheit } from '../../../system/models/temperatureUnits';

export function cToF(cValue?: number | null): number | undefined {
  typeof cValue !== 'number' &&
    console.warn('[temp conversion utility] invalid input', cValue);
  // eslint-disable-next-line prettier/prettier
  return cValue ? Math.round((cValue * 9) / 5 + 32) : undefined;
}

export function fToC(fValue?: number | null): number | undefined {
  typeof fValue !== 'number' &&
    console.warn('[temp conversion utility] invalid input', fValue);
  // eslint-disable-next-line prettier/prettier
  return fValue ? Math.round(((fValue - 32) * 5) / 9) : undefined;
}

// Rounds the profile temperatures to desired digits of precision
export const round = (value: number, digits: number) => {
  return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);
};

export const convertToCelsius = (value: number, digits = 0) => {
  const convertedValue = (value - 32) / 1.8;
  return round(convertedValue, digits);
};

const convertToFahrenheit = (value: number, digits = 0) => {
  const convertedValue = value * 1.8 + 32;
  return round(convertedValue, digits);
};

// Alternative that provides the temperature units and lets this class
// choose the correct conversion method
export const convertToTempUnits = (
  value?: number | null,
  targetUnits = Fahrenheit,
  currentUnits = Celsius,
  digits = 0,
): number | undefined => {
  if (value === undefined || value === null) {
    return undefined;
  }
  if (targetUnits !== Celsius && targetUnits !== Fahrenheit) {
    console.log(`[profile] invalid tempUnits: ${targetUnits}`);
    return undefined;
  }
  if (currentUnits === Celsius && targetUnits === Fahrenheit) {
    return convertToFahrenheit(value, digits);
  } else if (currentUnits === Fahrenheit && targetUnits === Celsius) {
    return convertToCelsius(value, digits);
  } else {
    // this is already in desired units, just do the desired rounding
    return round(value, digits);
  }
};

export const formatTemperatureString = (value: number, units: string) => {
  return `${value} \xB0${units}`;
};
