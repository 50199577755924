import React, { useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import Check from '@mui/icons-material/Check';
import BulkConfigStep from '../ui/bulk-config-step';
import { Floor, Building, UnitFilters } from '../types';

// Step for selecting specific floors to configure
export interface SelectFloorsProps {
  floors: Floor[];
  floorLabel: string;
  buildings: Building[];
  unitFilters: UnitFilters;
  error?: string;
  onSelectFloor: (floorId: string, checked: boolean) => void;
  toggleFloors: (floorIds: string[]) => void;
  onBack: () => void;
  onNext: () => void;
}

export default function SelectFloors(props: SelectFloorsProps) {
  const {
    floors,
    floorLabel,
    buildings,
    unitFilters,
    error,
    onSelectFloor,
    toggleFloors,
    onBack,
    onNext,
  } = props;

  function toggleAll() {
    toggleFloors(floors.map((f) => f?._id || ''));
  }

  // Select all floors by default
  useEffect(() => {
    if (unitFilters.selectedFloors.length === 0) {
      toggleAll();
    }
    // eslint-disable-next-line
  }, []);

  const topActions = [
    { name: 'Toggle All', action: toggleAll, startIcon: <Check /> },
  ];

  const bottomActions = [
    { name: 'Back', action: onBack, startIcon: <ArrowLeft /> },
    { name: 'Next', action: onNext, endIcon: <ArrowRight /> },
  ];

  return (
    <BulkConfigStep
      title={'Select ' + floorLabel}
      topActions={topActions}
      bottomActions={bottomActions}
      error={error}
    >
      <FormControl>
        <FormGroup>
          {floors.map((f) => {
            const showBuildingName = buildings.length > 1;
            const building = buildings.find((b) => b?._id === f?.buildingId);
            const label = showBuildingName
              ? building?.name + ' ' + f?.name
              : f?.name;
            return (
              <FormControlLabel
                key={f?._id}
                value={f?._id}
                control={
                  <Checkbox
                    checked={unitFilters.selectedFloors.includes(f?._id || '')}
                    onChange={(_, v) => onSelectFloor(f?._id || '', v)}
                    name={f?.name}
                    color="secondary"
                  />
                }
                label={label}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </BulkConfigStep>
  );
}
