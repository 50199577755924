/* eslint-disable-next-line no-useless-escape */
const phoneNumberRegex = /^(\()?\d{3}(\))?[-]?\d{3}[-]?\d{4}$/;

export const validatePhoneNumber = (phoneNumber: string) => {
  return phoneNumberRegex.test(phoneNumber);
};

export const formatPhoneNumber = (phoneNumber: string | null | undefined) => {
  // Remove all non-digit characters from the phone number
  const cleaned = (phoneNumber ?? '').replace(/\D/g, '');

  // Check if the phone number starts with '1' or '+1' and adjust the template accordingly
  let template = '($2) $3-$4';
  if (cleaned.startsWith('1') || cleaned.startsWith('01')) {
    template = '+1 ($2) $3-$4';
  }

  // Apply the desired formatting template
  const formattedPhoneNumber = cleaned.replace(
    /^(\+?1)?(\d{3})(\d{3})(\d{4})$/,
    template,
  );

  return formattedPhoneNumber;
};
