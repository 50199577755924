import * as React from 'react';
import {
  useUserCountQuery,
  useUserListUpdateSubscription,
} from '../../../types/generated-types';
import { updateCacheFromSubscriptionEvent } from '../../../helpers/subscriptionUtils';

export function UsersBreadcrumb() {
  useUserListUpdateSubscription({
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });
  const { data } = useUserCountQuery();

  return (
    <span>Users ({`${data?.userCount ? data.userCount : 'No users'}`})</span>
  );
}
