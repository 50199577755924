import React from 'react';
import { Outlet } from 'react-router-dom';

/**
 * UnitsMain - parent component for all unit specific views
 * @returns JSX.Element: router outlet
 */

export function UnitsMain() {
  return <Outlet />;
}
