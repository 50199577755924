import React, { useEffect } from 'react';
import { SourceList } from './source-list';
import { useSourceListQuery } from '../../../types/generated-types';
import { useInjectableComponents } from '../../system/services/injectableComponentsManager';
import { SourceTypeSelector } from './ui/source-type-selector';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Notifier } from '../../system/services/notificationManager';

export function SourceMain() {
  const { data, refetch, loading } = useSourceListQuery({
    fetchPolicy: 'cache-and-network',
    onError(error) {
      Notifier.error('Error loading sources. Please try refreshing the page');
      console.error(error);
    },
  });

  const { setPrimaryBottomNavigationWidget } = useInjectableComponents();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setPrimaryBottomNavigationWidget(<SourceTypeSelector />);
    return () => {
      setPrimaryBottomNavigationWidget(undefined);
    };
  }, []);

  return (
    <div
      style={{
        margin: isSmall ? '0px 4px' : '10px 4px',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      <SourceList
        data={data?.sources}
        loading={loading}
        showRefreshButton
        onRefresh={refetch}
      />
    </div>
  );
}
