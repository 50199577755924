import React, { useEffect, useState } from 'react';

import {
  IAuthenticationResult,
  useAuthenticator,
} from '../AuthenticationContext';

import { useNavigate } from 'react-router-dom';
import { Notifier } from '../../system/services/notificationManager';
import { SHA256 } from '../helpers/sha';

import { EmbueError } from '../../system/models/embueError';
import { IAuthState } from '../../system/SessionStateManager';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { Fade } from '@mui/material';
import TextField from '@mui/material/TextField';
import { ColoredLinearProgress } from '../../features/shared/linear-progress';
import { encodeEmail } from '../helpers/emailSanitizer';

/**
 * Represents the 'second step' in an MFA workflow. Collects the user's password
 * and submits it to the server. Returns the new factor requested by the server
 * for the next step in the MFA process via the provided callback ... OR ...,
 * if the server indicates that it is satisfied by populating the 'user' field
 * of the result, the authenticator will automatically store the returned user
 * object in local storage and sign the user in via the auth context which
 * triggers a re-rendering of the app which will activate the normal,
 * authenticated user home component.
 * @param onAuthResult
 * @param authState
 * @constructor
 */
export const Password = ({
  onAuthResult,
  authState,
  smart,
}: {
  onAuthResult: (res: IAuthenticationResult | void) => void;
  authState: IAuthState | undefined;
  smart: boolean;
}) => {
  const auth = useAuthenticator();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string | undefined>(
    (authState?.chain || [])[0]?.value ?? '',
  );
  const [password, setPassword] = useState<string | undefined>('');

  const [displayName, setDisplayName] = useState<string | undefined>();
  const [passwordValid, setPasswordValid] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setDisplayName(authState?.current?.context?.display);
    setEmail((authState?.chain || [])[0]?.value ?? '');
  }, [authState]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password) {
      setSubmitting(true);
      const hashedPassword = SHA256(password as string);
      auth
        .authenticate({ value: hashedPassword, smart })
        .then((authResult) => {
          onAuthResult(authResult);
        })
        .catch((authError: EmbueError) => {
          Notifier.error(`[Validate Password] Failed: ${authError.message}.`);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      Notifier.error(
        '[Password Reset] Unable to reset password: Incomplete information. Provided',
      );
    }
  };

  const ActivityIndicator = (props: { activityInProgress: boolean }) => {
    return props.activityInProgress ? (
      <ColoredLinearProgress />
    ) : (
      <Box style={{ height: '22px' }}>&nbsp;</Box>
    );
  };

  return (
    <Fade
      style={{ transitionDuration: '500ms' }}
      in={true}
      mountOnEnter
      unmountOnExit
    >
      <div>
        <ActivityIndicator activityInProgress={submitting} />
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ width: 'auto', marginTop: '8px' }}
        >
          <Grid
            container
            spacing={2}
            style={{ marginTop: '0px', paddingTop: '0px' }}
          >
            <input
              name="email"
              type="email"
              id="email"
              hidden={true}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              autoComplete="email"
            />
            <Grid item xs={12} style={{ marginTop: '0px', paddingTop: '0px' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                  setPasswordValid(event.target.value.length > 0);
                  setDirty(true);
                }}
                style={{
                  width: '100%',
                  minWidth: '280px',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
                error={dirty && !passwordValid}
                autoComplete="password"
                autoFocus
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            disabled={!passwordValid || submitting}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit Password
          </Button>
        </Box>

        <Grid container width="100%">
          <Grid item xs>
            &nbsp;
          </Grid>
          <Grid item>
            <Link
              sx={{ cursor: 'pointer' }}
              component={Link}
              onClick={() =>
                navigate(
                  `/auth/passwords/reset?email=${encodeEmail(email ?? '')}`,
                )
              }
              variant="body2"
            >
              Forgot password?
            </Link>
            {displayName && (
              <Link
                sx={{ marginLeft: '10px', cursor: 'pointer' }}
                component={Link}
                onClick={() =>
                  auth
                    .resetAuthState()
                    .then(onAuthResult)
                    .catch((resetError) => {
                      Notifier.error(
                        'Unable to reset authentication state. Please contact Embue support.',
                        { authState, resetError },
                      );
                    })
                }
                variant="body2"
              >
                Not {displayName}?
              </Link>
            )}
          </Grid>
          <Grid item xs>
            &nbsp;
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};
