import React from 'react';
import { styled, Theme } from '@mui/material/styles';
import { useSafePadding } from '../../helpers/safe-padding';

interface MyDrawerHeaderProps {
  theme: Theme;
  marginTop: number;
}

export const MyDrawerHeader = styled(
  'div',
  {},
)<MyDrawerHeaderProps>(({ theme, marginTop }) => ({
  marginTop: `${marginTop}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  ...theme.mixins.toolbar,
}));

interface DrawerHeaderProps {
  children: React.ReactNode;
  theme: Theme;
}

export const DrawerHeader = ({ children, theme }: DrawerHeaderProps) => {
  // used for 'Notch' safe top padding:
  const { safeTopPadding } = useSafePadding();

  return (
    <MyDrawerHeader marginTop={safeTopPadding} theme={theme}>
      {children}
    </MyDrawerHeader>
  );
};
