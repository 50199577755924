import { Log } from '../services/logger';

/**
 * Basic error class for client-side code. Auto-logs the message passed to the constructor.
 */
export class EmbueError extends Error {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  public constructor(message: string, context?: any) {
    Log.error(message, context);
    super(message);
  }
}
