import React from 'react';
import SetpointProfilesTable from './setpoint-limit-profiles-table';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

export function SetpointLimitProfilesMain() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      style={{
        margin: isSmall ? '0px' : '10px 4px',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      <SetpointProfilesTable />
    </div>
  );
}
