/**
 * Slider
 * - custom slider component with value label displayed in the thumb
 * - TODO: Peter: move to shared component folder
 **/

import MuiSlider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

export const Slider = styled(MuiSlider)(({ theme }) => ({
  '.MuiSlider-thumb': {
    height: 32,
    width: 32,
    backgroundColor: '#fff',
    border: '4px solid currentColor',
  },
  '.MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: 25,
    backgroundColor: 'unset',
    color: 'currentColor',
  },
}));
