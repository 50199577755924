import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { useAuthenticator } from '../../../auth/AuthenticationContext';

import { NavMenuItem } from '../../../ui/app-bar';
import { SystemTimer } from '../../../system/shared/SystemTimer';

import { AutoLogoutIcon } from './icons/AutoLogoutIcon';
import { LocalState } from '../../../system/services/localStateManager';
import { CONNECTION_STATE_TRANSITIONING_DELAY } from '../../../../helpers/config';

export function ProfileMenu({
  menuId,
  anchorEl,
  isMenuOpen,
  handleMenuClose,
  handleRouteChange,
  appBarItems,
}: {
  menuId: string;
  anchorEl: HTMLElement | null;
  isMenuOpen: boolean;
  handleMenuClose: () => void;
  handleRouteChange: (arg0: string) => void;
  appBarItems: NavMenuItem[];
}) {
  const { sessionExpiresAt } = useAuthenticator();
  const [transitioning, setTransitioning] = useState<boolean>(
    LocalState.authStateTransitioning(),
  );

  useEffect(() => {
    if (transitioning) {
      setTimeout(() => {
        setTransitioning(false);
      }, CONNECTION_STATE_TRANSITIONING_DELAY);
    }
  }, [transitioning, LocalState]);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{ minWidth: '150px' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {appBarItems.map((item, key) => {
        return (
          <MenuItem
            disabled={transitioning}
            key={key}
            onClick={() => {
              handleMenuClose();
              handleRouteChange(item.path);
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            {item.label}
          </MenuItem>
        );
      })}
      <MenuItem
        key="auto-logout"
        disabled={transitioning}
        onClick={() => {
          handleMenuClose();
        }}
      >
        <Tooltip title="Inactivity logout timer">
          <div style={{ display: 'flex' }}>
            <ListItemIcon>
              <AutoLogoutIcon />
            </ListItemIcon>
            <div
              style={{
                color: 'red !important',
                fontWeight: 'bold !important',
                marginTop: '4px',
              }}
            >
              <SystemTimer
                expirationTimestamp={sessionExpiresAt || new Date()}
                label=""
                prefix=""
                completedMessage="now"
                units={true}
              />
            </div>
          </div>
        </Tooltip>
      </MenuItem>
    </Menu>
  );
}
