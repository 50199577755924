import * as React from 'react';

/* COMPONENTS */
import DataTable from '../shared/data-table';

/* TYPES */
import {
  useSetpointProfileListQuery,
  useSetpointProfileListUpdateSubscription,
  SetpointProfile,
  useRemoveSetpointProfileMutation,
} from '../../../types/generated-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { updateCacheFromSubscriptionEvent } from '../../../helpers/subscriptionUtils';
import { Notifier } from '../../system/services/notificationManager';

/* ICONS */
import DeleteIcon from '@mui/icons-material/Delete';

/* MUI */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import {
  MobileListCard,
  MobileListDataTableProps,
} from '../shared/mobile-list-card';
import {
  useInjectableComponents,
  BottomMenuItems,
} from '../../system/services/injectableComponentsManager';
import { Add } from '@mui/icons-material';
import { useAuthorizer } from '../../auth/AuthorizationContext';

interface RemoveSetpointLimitProfileDialogProps {
  open: boolean;
  handleClose: () => void;
  removeSetpointProfile: () => void;
}

interface FormattedSetpointLimitProfileData {
  _id: string;
  name: string;
  enabledProperties: string;
  assignedProperties: string;
  assignedUnits: string;
}

const RemoveSetpointLimitProfileDialog = (
  props: RemoveSetpointLimitProfileDialogProps,
) => {
  const { open, handleClose, removeSetpointProfile } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography variant="h4" sx={{ padding: '15px' }}>
          Remove Setpoint Profile
        </Typography>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure? this action cannot be reversed
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              removeSetpointProfile();
              handleClose();
            }}
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default function SetpointLimitProfilesTable() {
  const [open, setOpen] = React.useState(false);
  const [formattedData, setFormattedData] =
    React.useState<FormattedSetpointLimitProfileData[]>();
  const [profileId, setSelectedProfileId] =
    React.useState<SetpointProfile['_id']>('');
  const { can } = useAuthorizer();

  const { setContextMenuItems } = useInjectableComponents();

  useSetpointProfileListUpdateSubscription({
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  const handleRowClick = (params: GridRowParams) => {
    if (can('update', 'SetpointProfile')) {
      navigate(`/setpoint-profiles/profile/${params.row?._id}`);
    }
  };

  const [
    removeSetpointProfile,
    {
      // data: removedSetpointProfile,
      // loading: removeSetpointProfileLoading,
      error: removeSetpointProfileError,
    },
  ] = useRemoveSetpointProfileMutation();

  useEffect(() => {
    if (removeSetpointProfileError) {
      Notifier.error(
        'There was a problem removing the setpoint profile: ',
        removeSetpointProfileError,
      );
    }
  }, [removeSetpointProfileError]);

  const handleRemoveSetpointProfile = async () => {
    try {
      await removeSetpointProfile({
        variables: { id: profileId },
      });
      Notifier.success('Setpoint limit profile deleted');
    } catch (e) {
      Notifier.error('Unable to delete Setpoint Limit Profile');
    }
    Notifier.success('Setpoint limit profile deleted');
  };

  const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    data: setpointProfileData,
    loading: setpointProfileDataLoading,
    error: setpointProfileDataError,

    // subscribeToMore,
  } = useSetpointProfileListQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    if (setpointProfileData?.setpointProfiles) {
      const myFormattedData: FormattedSetpointLimitProfileData[] = [];
      setpointProfileData.setpointProfiles.forEach((row) => {
        if (row) {
          myFormattedData.push({
            _id: row._id,
            name: row.name ?? 'unnamed',
            enabledProperties: row.enabledProperties
              .map((prop) => prop?.title ?? '')
              .join(' || '),
            assignedProperties: row.assignedProperties
              .map((prop) => prop?.title ?? '')
              .join(' || '),
            assignedUnits: row.assignedUnits
              .map((unit) => unit?.name ?? '')
              .join(' || '),
          });
        }
      });
      setFormattedData(myFormattedData);
    }
  }, [setpointProfileData]);

  const nameColumn = isSmall
    ? ({
        field: 'name',
        flex: 1,
        align: 'center',
        headerName: 'Profile Name',
        renderCell: (params: GridRenderCellParams<Partial<any>>) => {
          return (
            <MobileListCard>
              <Typography
                sx={{ fontSize: 14, fontWeight: 800 }}
                color="text.secondary"
                gutterBottom
              >
                {params.row?.name ?? 'Unnamed Setpoint Limit Profile'}
              </Typography>
              <Typography variant="h5" component="div">
                Created By: {params.row?.creator?.name ?? 'Unknown Creator'}
              </Typography>
              <Typography variant="h5">
                Last Updated: {params.row?.lastUpdatedBy?.name ?? 'Unknown'}
              </Typography>
            </MobileListCard>
          );
        },
      } as GridColDef)
    : {
        field: 'name',
        width: 250,
        headerName: 'Profile Name',
        renderCell: (params: GridRenderCellParams<Partial<SetpointProfile>>) =>
          params.row?.name ?? 'Unnamed Setpoint Limit Profile',
      };

  const columns = [
    {
      field: 'actions',
      id: 'actions',
      width: 90,
      headerName: 'Actions',
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<Partial<SetpointProfile>>) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              handleClickOpen();
              setSelectedProfileId(params?.row?._id ?? '');
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
    { ...nameColumn },
    {
      field: '_id',
      headerName: 'Profile ID',
      width: 250,
      hide: true,
    },
    {
      field: 'enabledProperties',
      headerName: 'Enabled Properties',
      width: 250,
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<FormattedSetpointLimitProfileData>,
      ) => (
        <div>
          {(params.row?.enabledProperties ?? '')
            .split(' || ')
            .map((prop, index) => (
              <div key={`enabled-properties-${prop}-${index}`}>{prop}</div>
            ))}
        </div>
      ),
    },
    {
      field: 'assignedProperties',
      headerName: 'Assigned Properties',
      width: 250,
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<FormattedSetpointLimitProfileData>,
      ) => (
        <div>
          {(params.row?.assignedProperties ?? '')
            .split(' || ')
            .map((prop, index) => (
              <div key={`assigned-properties-${prop}-${index}`}>{prop}</div>
            ))}
        </div>
      ),
    },
    {
      field: 'assignedUnits',
      headerName: 'Assigned Units',
      width: 250,
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<FormattedSetpointLimitProfileData>,
      ) => (
        <div>
          {(params.row?.assignedUnits ?? '')
            .split(' || ')
            .map((unit, index) => (
              <div key={`assigned-units-${unit}-${index}`}>{unit}</div>
            ))}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (setpointProfileDataError) {
      Notifier.error(
        'There was a problem fetching setpoint profile information: ',
        setpointProfileDataError,
      );
    }
  }, [setpointProfileDataError]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const currentLocation = useLocation();
  const { pathname } = currentLocation;
  const pathComponent = pathname.split('/').slice(0, 5);

  useEffect(() => {
    const bottomMenu: BottomMenuItems = [
      {
        items: [
          {
            id: 'menu-item-add-setpoint-profile',
            label: 'Add Setpoint Profile',
            icon: <Add fontSize="small" />,
            action: () => navigate('create'),
            permit: {
              action: 'create',
              subject: 'SetpointProfile',
            },
          },
        ],
      },
    ];
    setContextMenuItems(bottomMenu);
    return () => {
      setContextMenuItems(undefined);
    };
  }, [navigate, setContextMenuItems]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        cursor: 'pointer',
        maxWidth: isSmall ? 'unset' : 'calc(100vw - 67px)',
      }}
    >
      <DataTable
        {...(isSmall ? MobileListDataTableProps : {})}
        additionalProps={{
          onRowClick: (i: GridRowParams) => handleRowClick(i),
        }}
        suppressAutoPageSize={true}
        getRowId={(setpointProfile: any) => {
          //TODO: Peter: Include Type
          return setpointProfile._id ?? setpointProfile.name;
        }}
        columns={columns}
        rowHeight={isSmall ? 85 : undefined}
        // TODO: Calvin: Dynamic grid row height disabled while working on a solution for
        //  rendering collections for column data
        getRowHeight={
          isSmall
            ? undefined
            : ({ model: setpointProfile }) => {
                return (
                  Math.max(
                    1,
                    (setpointProfile.enabledProperties ?? '').split(' || ')
                      .length,
                    (setpointProfile.assignedProperties ?? '').split(' || ')
                      .length,
                    (setpointProfile.assignedUnits ?? '').split(' || ').length,
                  ) *
                    25 +
                  15
                );
              }
        }
        hideFilterSorting={pathComponent[2] === 'true'}
        columnVisibilityModel={{
          actions: false,
          _id: false,
          enabledProperties: !isSmall,
          assignedProperties: !isSmall,
          assignedUnits: !isSmall,
        }}
        rows={formattedData ?? []}
        loading={setpointProfileDataLoading}
      />
      <RemoveSetpointLimitProfileDialog
        open={open}
        handleClose={() => handleClose()}
        removeSetpointProfile={() => handleRemoveSetpointProfile()}
      />
    </div>
  );
}
