/**
 * Unit Zone Schedule
 * - display a list of thermostat schedules for all unit zones
 * - sort by schedule types like 'Custom', 'SameEveryDay' or 'Weekday/Weekend'
 */
import React, { useState } from 'react';
/* MUI */
import {
  Box,
  Card,
  CardHeader,
  Typography,
  useMediaQuery,
  useTheme,
  Fade,
} from '@mui/material';
/* HELPERS */
import {
  WdTab,
  WdTabs,
} from '../../../../devices/thermostat/components/schedule/weekday-tabs';
/* TYPES */
import { DayOfWeek } from '../../../../devices/thermostat/types/schedule-types';
import {
  EnumScheduleType,
  ThermostatSchedule,
} from '../../../../../../types/generated-types';
import { ThermostatScheduleTable } from '../../../../devices/thermostat/components/schedule/thermostat-schedule-table';

const dayOfWeekLabels: Record<DayOfWeek, string> = {
  [DayOfWeek.SU]: 'SU',
  [DayOfWeek.MO]: 'MO',
  [DayOfWeek.TU]: 'TU',
  [DayOfWeek.WE]: 'WE',
  [DayOfWeek.TH]: 'TH',
  [DayOfWeek.FR]: 'FR',
  [DayOfWeek.SA]: 'SA',
};
const label: string[] = ['Weekday', 'Weekend'];

interface ThermostatScheduleViewProps {
  scheduleType: EnumScheduleType;
  scheduleValue: ThermostatSchedule['value'];
  thermostatName: string;
  showPlaceholder?: boolean;
}

export function ThermostatScheduleView(props: ThermostatScheduleViewProps) {
  const { thermostatName, scheduleValue, scheduleType, showPlaceholder } =
    props;
  const selectedScheduleType = scheduleType ?? EnumScheduleType.SameEveryDay;
  const [selectedDay, setSelectedDay] = useState(
    selectedScheduleType === EnumScheduleType.WeekdayWeekend
      ? DayOfWeek.MO
      : DayOfWeek.SU,
  );
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const dailySchedule = scheduleValue[selectedDay] ?? {};

  // Get days of the week to display tabs for, depending on the schedule type
  function daysToDisplay(): DayOfWeek[] {
    if (selectedScheduleType === EnumScheduleType.Custom) {
      return Object.values(DayOfWeek);
    } else if (selectedScheduleType === EnumScheduleType.WeekdayWeekend) {
      return [DayOfWeek.MO, DayOfWeek.SU];
    } else {
      return [DayOfWeek.SU];
    }
  }
  // Get tab labels by schedule type
  // Example: If schedule type is 'SameEveryDay' then only display one tab label 'Every day'
  function getTabLabel(dayOfWeek: DayOfWeek): string {
    if (selectedScheduleType === EnumScheduleType.SameEveryDay) {
      return 'Everyday';
    } else {
      return dayOfWeekLabels[dayOfWeek];
    }
  }

  // View change handler
  function handleViewChange(event: React.SyntheticEvent, newValue: DayOfWeek) {
    setSelectedDay(newValue);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Card
        sx={{
          background: 'inherit',
          margin: isSmall ? '0px' : '0px 20px',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'stretch',
        }}
        elevation={4}
      >
        <CardHeader
          title={
            <Typography variant="h4" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
              {thermostatName ?? 'N/A'}
            </Typography>
          }
        />

        <>
          <WdTabs
            variant="fullWidth"
            value={selectedDay}
            onChange={handleViewChange}
          >
            {daysToDisplay().map((dayOfWeek, index) => {
              return (
                <WdTab
                  key={dayOfWeek}
                  value={dayOfWeek}
                  label={
                    daysToDisplay().length === 2 &&
                    selectedScheduleType === EnumScheduleType.WeekdayWeekend
                      ? label[index]
                      : getTabLabel(dayOfWeek)
                  }
                />
              );
            })}
          </WdTabs>
          <ThermostatScheduleTable dailySchedule={dailySchedule} />
        </>
        <br />
      </Card>
    </Box>
  );
}
