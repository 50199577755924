import React, { useEffect, useState } from 'react';

/* COMPONENTS */
import FullWidthLoadingSkeleton from '../../../shared/fullWidthLoadingSkeleton';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../../../system/services/injectableComponentsManager';
import DataTable from '../../../shared/data-table';

/* NAVIGATION */
import { useLocation, useNavigate, useParams } from 'react-router-dom';

/* MUI */
import {
  Avatar,
  Card,
  CardHeader,
  IconButton,
  useMediaQuery,
  useTheme,
  Typography,
} from '@mui/material';

/* Data */
import {
  usePropertyBuildingsQuery,
  Property,
  ThermostatScheduleTemplate,
  usePropertyScheduleTemplateListQuery,
} from '../../../../../types/generated-types';
import {
  GridColDef,
  GridRenderCellParams,
  GridComparatorFn,
  gridStringOrNumberComparator,
  GridRowParams,
} from '@mui/x-data-grid';

/* ICONS */
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ScheduleIcon from '@mui/icons-material/CalendarMonth';

export interface ScheduleTemplateListRowData {
  id: string;
  name: string;
  isPropertyDefault: boolean;
  assignedUnitCount: number;
  assignedDeviceCount: number;
}

function formatData(
  data: Partial<ThermostatScheduleTemplate>[],
): ScheduleTemplateListRowData[] {
  const formattedData: ScheduleTemplateListRowData[] = [];
  data.forEach((row) => {
    if (row?._id) {
      formattedData.push({
        id: row._id,
        name: row.name ?? 'N/A',
        isPropertyDefault: row.isPropertyDefault ?? false,
        assignedUnitCount: row.assignedUnitCount ?? 0,
        assignedDeviceCount: row.assignedDeviceCount ?? 0,
      });
    }
  });
  return formattedData;
}

interface ScheduleTemplateListProps {
  mode: 'select' | 'manage';
  onSelect?: (r: ScheduleTemplateListRowData) => void;
}

export function ScheduleTemplateList(props: ScheduleTemplateListProps) {
  const { mode, onSelect } = props;

  const theme = useTheme();
  const navigate = useNavigate();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  /* State */
  const { id } = useParams<{ id: string }>();
  const [property, setProperty] = useState<Partial<Property>>();
  const [templates, setTemplates] = useState<ScheduleTemplateListRowData[]>();

  const {
    setPrimaryBottomNavigationWidget,
    setContextMenuItems,
    setSubtitle,
    setSubtitlePath,
    setSubtitleActionWidget,
    setTitle,
    setTitlePath,
  } = useInjectableComponents();

  const { pathname } = useLocation();

  /* Data */
  const {
    data: buildingsData,
    loading: buildingsLoading,
    error: buildingsError,
  } = usePropertyBuildingsQuery({
    variables: { id: id || '' },
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: templateData,
    loading: templateLoading,
    error: templateError,
  } = usePropertyScheduleTemplateListQuery({
    variables: { propertyId: property?._id || '' },
    fetchPolicy: 'cache-and-network',
  });

  /* Property Schedule Template List */
  useEffect(() => {
    if (templateData?.thermostatScheduleTemplates) {
      setTemplates(formatData(templateData.thermostatScheduleTemplates));
    }
  }, [templateData]);

  useEffect(() => {
    if (buildingsData?.propertyById) {
      setSubtitleActionWidget({
        widget: (
          <IconButton
            aria-label="view unit thermostat schedule"
            sx={{
              color: theme.palette.secondary.main,
              backgroundColor: '#FFF',
            }}
            onClick={() =>
              navigate(
                `/properties/${buildingsData?.propertyById?._id}/schedule/templates/add`,
              )
            }
          >
            <ScheduleIcon />
          </IconButton>
        ),
      });
    }
  }, [buildingsData]);

  useEffect(() => {
    if (buildingsData?.propertyById) {
      const myProperty: Partial<Property> =
        buildingsData.propertyById as Partial<Property>;
      setProperty(myProperty);
    }
  }, [buildingsData]);

  useEffect(() => {
    if (property && pathname) {
      const items: BottomMenuItems = [
        {
          items: [
            {
              id: 'add-new-schedule-template-menu-item',
              label: 'Create a Schedule Template',
              icon: <AddIcon fontSize="small" />,
              navTarget: `/properties/${property._id}/schedule/templates/add`,
            },
          ],
        },
      ];

      const basePropertyName = property.title ?? 'Untitled Property';

      setTitle(
        pathname.includes('installer')
          ? isSmall
            ? basePropertyName.length < 19
              ? `Installing ${basePropertyName}`
              : basePropertyName.length > 24
                ? `${basePropertyName.slice(0, 24)} ...`
                : basePropertyName
            : basePropertyName
          : basePropertyName,
      );
      setTitlePath(
        pathname.includes('installer')
          ? pathname.replace('installer', 'units')
          : `/properties`,
      );

      setSubtitle(
        pathname.includes('installer') ? 'Installer Unit List' : 'Unit List',
      );
      setSubtitlePath(
        pathname.includes('installer')
          ? `/properties/${property._id}/units`
          : `/properties/${property._id}/summary`,
      );

      setContextMenuItems(items, property as Property);

      return () => {
        setTitle(undefined);
        setTitlePath(undefined);
        setSubtitle(undefined);
        setSubtitlePath(undefined);
        setSubtitleActionWidget(undefined);
        setContextMenuItems(undefined);
        setPrimaryBottomNavigationWidget(undefined);
      };
    }
  }, [property, pathname, isSmall]);

  // const handleRowClick = (params: GridRowParams) => {
  //   const { row } = params;
  //   navigate(`/properties/${property?._id}/units/${row.id}/schedule`);
  // };

  /* UI */
  const err = buildingsError;
  const isLoading = buildingsLoading;

  function padDigits(input: String): String {
    let digitsMatch = input.match(/^\d+/); // Match the sequence of digits at the beginning of the string

    if (digitsMatch) {
      const digits = digitsMatch[0]; // Extract the matched digits
      const paddedDigits = digits.padStart(5, '0'); // Pad the digits with leading zeros

      return input.replace(digits, paddedDigits); // Replace the original digits with the padded digits
    }

    digitsMatch = input.match(/\d+$/); // Match the sequence of digits at the end of the string
    if (digitsMatch) {
      const digits = digitsMatch[0]; // Extract the matched digits
      const paddedDigits = digits.padStart(5, '0'); // Pad the digits with leading zeros
      return input.replace(digits, paddedDigits); // Replace the original digits with the padded digits
    }

    return input; // Return the original string if no digits were found at the beginning
  }

  const unitNameColumnComparator: GridComparatorFn<String> = (v1, v2, a, b) => {
    return gridStringOrNumberComparator(padDigits(v1), padDigits(v2), a, b);
  };

  // Unit column. On small screens, all other columns will be hidden, and this will need to render
  // a custom card view with all relevant pieces of information for the row.

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 160,
      sortComparator: unitNameColumnComparator,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              whiteSpace: isSmall ? 'pre-wrap' : 'inherit',
              fontSize:
                isSmall && (params?.row?.name ?? '').length > 14
                  ? '9px'
                  : '13px',
            }}
          >
            {params?.row?.name ?? 'N/A'}
          </Typography>
        );
      },
    },
    {
      field: 'isPropertyDefault',
      headerName: 'Property Default',
      flex: 1,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      maxWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.isPropertyDefault ? (
          <CheckIcon sx={{ fontSize: '16px' }} />
        ) : null;
      },
    },
    {
      field: 'assignedUnitCount',
      headerName: 'Assigned Units',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              whiteSpace: isSmall ? 'pre-wrap' : 'inherit',
              fontSize: '16px',
            }}
          >
            {params?.row?.assignedUnitCount ?? 'N/A'}
          </Typography>
        );
      },
    },
    {
      field: 'assignedDeviceCount',
      headerName: 'Assigned Devices',
      width: 130,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              whiteSpace: isSmall ? 'pre-wrap' : 'inherit',
              fontSize: '16px',
            }}
          >
            {params?.row?.assignedDeviceCount ?? 'N/A'}
          </Typography>
        );
      },
    },
  ];

  const handleRowClick = (params: GridRowParams) => {
    switch (mode) {
      case 'select':
        if (onSelect) {
          onSelect(params?.row ?? {});
        }
        break;
      case 'manage':
        navigate(
          `/properties/${property?._id}/schedule/templates/${params?.row?.id}/edit`,
        );
        break;
      default:
    }
  };

  return err ? (
    err.message === 'Unauthorized' ? (
      <>
        <Typography variant="h4">
          You are not authorized to access the requested resource.
        </Typography>
        <Typography variant="body1">
          Please contact your administrator or Embue support for assistance.
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="h4">
          Error accessing the requested resource.
        </Typography>
        <Typography variant="body1">
          Please contact your administrator or Embue support for assistance.
        </Typography>
      </>
    )
  ) : isLoading ? (
    <FullWidthLoadingSkeleton />
  ) : (
    <Card
      elevation={4}
      sx={{
        background: 'inherit',
        margin: isSmall ? '0px' : '20px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        '& .MuiCardHeader-root': {
          padding: '4px 10px!important',
          borderBottom: '1px solid darkgray',
          borderRight: isSmall ? '1px solid lightgray' : 'inherit',
          boxShadow: '0px 8px 6px -6px rgba(0, 0, 0, 0.35)',
        },
        '& .MuiCardContent-root': {
          flex: 1,
          borderRight: isSmall ? '1px solid lightgray' : 'inherit',
          paddingTop: '0px !important',
          padding: isSmall ? '0px !important' : 'inherit',
        },
        cursor: 'pointer',
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            aria-label="schedule icon"
            sx={{
              color: theme.palette.secondary.main,
              backgroundColor: '#FFF',
            }}
          >
            <ScheduleIcon />
          </Avatar>
        }
        title={
          <Typography
            variant="h3"
            sx={{ color: 'rgba(0, 0, 0, 0.87)', marginLeft: '8px' }}
            noWrap
          >
            Schedule Templates
          </Typography>
        }
      />
      <DataTable
        hideFilterButton
        sxProps={{ border: 'none' }}
        additionalProps={{
          onRowClick: (i: GridRowParams) => handleRowClick(i),
        }}
        density={'standard'} // {isSmall ? 'comfortable' : 'standard'}
        columns={columns}
        hideFooterPagination
        hideFooter
        suppressAutoPageSize={true}
        rows={templates ?? []}
        // loading={isLoading}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [''],
            },
          },
        }}
        slotProps={{
          row: {
            'aria-label': 'unit-detail',
          },
        }}
      />
    </Card>
  );
}
