import { OnDataOptions } from '@apollo/client/react/types/types';
import { pluralize } from 'inflected';
import gql from 'graphql-tag';
// TODO: Peter: remove the merge cruft
// import { apolloClient } from './apolloClient';
import { Log } from '../app/system/services/logger';
import { FieldNode } from 'graphql';
import { capitalize } from '@mui/material';
import { uncapitalize } from './stringUtils';
import { Modifier } from '@apollo/client/cache';

/**
 * Internal function used to construct the payload for the generated GraphQL query used to update the ApolloClient
 * cache from the data component of the subscription event payload received when a MongoDB databased change event
 * triggers one or more subscriptions to which our client is subscribed. This function is called recursively as the
 * supplied JSON data structure is walked and the corresponding GraphQL payload structure is populated. In addition,
 * the second parameter representing an array of attributes which should be included in the "update GQL" which is
 * first generated to accompany the data payload. It is first created and then grown incrementally and recursively
 * and then used to generate the update attribute list for inclusion in the GQL used to update the cache.
 * @param subscriptionEventDataFragment - The JSON data fragment to 'convert' to its corresponding GraphQL payload.
 * @param attributeSelections - An array of attributes / paths which will be included in the generated GQL used to update the ApolloClient cache.
 */
function buildUpdateParameters<T extends { [s: string]: unknown }>(
  subscriptionEventDataFragment: T,
  attributeSelections: string[],
) {
  const sanitizedFragment = { ...subscriptionEventDataFragment };
  delete sanitizedFragment.modelChangeOperation;

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  return Object.entries(sanitizedFragment).reduce((result: any, entry) => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const [key, value]: [string, any] = entry;
    if (value === null || value === undefined) {
      // TODO: Peter: perhaps we should just 'skip' setting this if it is null? But then the cache would not update if we 'delete' something?
      result[key] = value;
      attributeSelections.push(key);
    } else if (
      key === 'schedule' ||
      value?.__typename === 'ThermostatScheduleTemplateValue'
    ) {
      // TODO: Peter: this is a special case to handle our weird way of dealing with schedules that are mapped using integer days (for now)
      result[key] = value;
      attributeSelections.push(key);
    } else if (Array.isArray(value)) {
      // TODO: Peter: have to deal with this case if it ever arises ...
      // Log.error(
      //   `[Building subscription update parameters] ERROR: ${key} is an array`,
      // );
      result[key] = value;
      attributeSelections.push(key);
    } else if (typeof value === 'object' && Object.keys(value).length > 0) {
      const myAttributeSelections: string[] = [];
      result[key] = buildUpdateParameters(value, myAttributeSelections);
      attributeSelections.push(
        `${key} { ${myAttributeSelections.join(', ')} }`,
      );
    } else {
      result[key] = value;
      attributeSelections.push(key);
    }
    return result;
  }, {});
}

/**
 * Call this function to return an appropriate callback function to use as the
 * #onData callback function when 'use'-ing a generated React hook
 * wrapping a GraphQL subscription. It will do two things for you:
 *   (1) It will automatically update the ApolloClient cache and
 *   (2) If you provide a callback function as an argument when calling this
 *   factory function, it will call your provided callback after completing (1).
 *
 *   Note that your callback function takes the same parameters as would a normal
 *   #onData callback function take.
 *
 *   ***NOTE*** ENSURE that you use the 'no-cache' fetch policy if you are going
 *   to use this callback generator. Otherwise, the cache may end up being
 *   updated twice: once in an automated way by the framework for existing documents
 *   and again using the mechanism provided by the #updateCacheFromSubscriptionEvent
 *   function which is called from within the generated callback produced by this
 *   factory.
 *
 *   Example usage:
 *
 *     const {
 *       error: propertyEventsError,
 *       data: propertyEventData,
 *       loading: propertyEventsLoading,
 *       variables: propertyEventsVariables,
 *   } = usePropertyListUpdateSubscription({
 *     variables: { _ids: [selectedProperty?._id || ''] },
 *     fetchPolicy: 'no-cache',
 *     onData: handleModelUpdateEvent(({ data: subscriptionData }) => {
 *       const { data } = subscriptionData;
 *       if (data.propertyEventsByIds._id === selectedProperty?._id) {
 *         selectProperty(data.propertyEventsByIds);
 *       }
 *     }),
 *   });
 * @param customCallback - Type: (options: OnDataOptions) => void
 *          An optional callback function that, if provided, will be called
 *          in addition to and AFTER the cache update function is called.
 *          NOTE: that, while the cache update call has been made by the time
 *          your optional callback is executed, the cache will likely not be
 *          actually updated until the 'next tick' and, therefore, any local
 *          reactive variables based on the cached data will likely be in their
 *          pre-change state.
 */
export function handleModelUpdateEvent(
  customCallback?: (options: OnDataOptions) => void,
) {
  return async (options: OnDataOptions) => {
    await updateCacheFromSubscriptionEvent(options);
    if (customCallback) {
      customCallback(options);
    }
  };
}

/**
 * Use this callback function directly as the #onData callback function
 * when 'use'-ing a generated React hook wrapping a GraphQL subscription
 * to automatically update the ApolloClient cache with the data contained in
 * the update event payload
 *
 *   ***NOTE*** ENSURE that you use the 'no-cache' fetch policy if you are going
 *   to use this callback generator. Otherwise, the cache may end up being
 *   updated twice: once in an automated way by the framework for existing documents
 *   and again using the mechanism provided by the #updateCacheFromSubscriptionEvent
 *   function which is called from within the generated callback produced by this
 *   factory.
 *
 *   Example usage:
 *
 *     const {
 *       error: propertyEventsError,
 *       data: propertyEventData,
 *       loading: propertyEventsLoading,
 *       variables: propertyEventsVariables,
 *      } = usePropertyListUpdateSubscription( {
 *            variables: { _ids: [selectedProperty?._id'] },
 *            fetchPolicy: 'no-cache',
 *            onData: updateCacheFromSubscriptionEvent,
 *           } );
 */
// export async function updateCacheFromSubscriptionEvent(
export async function updateCacheFromSubscriptionEvent(options: OnDataOptions) {
  const connectedClient = options.client;

  function updateObservableQueries(
    baseQueryName: string,
    cachedQueryName: string,
  ) {
    connectedClient.getObservableQueries().forEach((obQuery) => {
      obQuery.query.definitions.forEach((myDef) => {
        const underlyingBaseQuery = myDef as unknown as FieldNode;
        const underlyingQueryName = (
          underlyingBaseQuery?.selectionSet?.selections[0] as FieldNode
        ).name.value;
        if (
          [
            `${baseQueryName}Count`,
            `paginated${capitalize(cachedQueryName)}`,
            baseQueryName,
          ].includes(underlyingQueryName)
        ) {
          console.log(
            'Re-fetching observable query',
            cachedQueryName,
            baseQueryName,
          );
          obQuery.refetch().catch((error: any) => {
            console.log(
              `[Subscription Utils - updateObservableQueries] Got error during re-fetch of ${underlyingQueryName}`,
              error?.message,
            );
          });
        }
      });
    });
  }

  if (connectedClient) {
    const { data: subscriptionEventData } = options;
    const { data: subData, error } = subscriptionEventData;

    if (error) {
      Log.error(
        `Error processing subscription: ${Object.keys(subData)[0]}: ${
          error.message
        }.`,
      );
    }
    const data = subData[Object.keys(subData)[0]];

    // const baseQueryName: string = uncapitalize(data.__typename); // .toLowerCase();
    const cachedQueryName: string = uncapitalize(pluralize(data.__typename)); // .toLowerCase());

    const operation = data.modelChangeOperation;

    // TODO: Peter: Work on supporting the 'replace' action better (at all, actually).
    if (operation === 'delete') {
      /* make __typename match the entry in modelType to make sure that the cache is
       * updated correctly.
       */
      const myData = { ...data };
      if (data.modelType && data.modelType !== data.__typename) {
        myData.__typename = data.modelType;
      }

      // First get the reference ID for the item to be deleted from within the cache.
      const refId = connectedClient.cache.identify(myData);

      // Now update the 'existingItems' query result set to exclude the refId for the item to be removed.

      const cacheUpdate: {
        [fieldName: string]: Modifier<any>;
      } = {};

      connectedClient.getObservableQueries().forEach((observableQuery) => {
        const queryResultsMap = observableQuery.getLastResult()?.data;
        const queryIdentifiers = Object.keys(queryResultsMap);
        for (const queryIdentifier of queryIdentifiers) {
          const queryData = queryResultsMap[queryIdentifier];
          if (
            typeof queryData === 'object' &&
            queryData.__typename &&
            queryData.__typename.toLowerCase() ===
              myData.__typename.toLowerCase()
          ) {
            cacheUpdate[cachedQueryName] = (existingItems) => {
              const newItems = [];
              for (let i = 0; i < existingItems.length; i++) {
                if (
                  connectedClient.cache.identify(existingItems[i]) !== refId
                ) {
                  newItems.push(existingItems[i]);
                }
              }
              return newItems;
            };
          }
        }
      });

      if (Object.keys(cacheUpdate).length > 0) {
        connectedClient.cache.modify({
          fields: cacheUpdate,
        });
      }

      // TODO: Peter: What about result sets that depend on this now-to-be-removed item but don't (in the actual cache) reflect that dependency?
      //   something like a count query or a query that returns a subordinate collection of items that used to include the item to be removed?
      //   I think the 'count' case, is currently NOT covered yet here. The 'subordinate collection' case is likely covered IF we have registered
      //   a subscription dependency in the subscription manager whereby the 'holder' type of the subordinate collection is marked as a dependency
      //   of the type of the item to be removed and, thus, the 'holder' object will be 'refreshed' in the cache. We should be sure that the
      //   notification of the actual object removal is processed by the subscription manager BEFORE the 'holder' object refresh notification is
      //   processed -- although I wonder if that is even strictly required ... probably not. AND it would likely be difficult to ensure the order
      //   in which these notifications are processed. For the COUNT type case, we could use a naming convention for the query (since it is independent
      //   of the query identifier I think) where we agree to use a prefix of TYPENAME_ prepended to the query name which we could then detect here
      //   for the cases where the query data results contain a primitive or non '__typename' containing or relevant / misleading value.

      // Lastly, we now remove the actual item from the cache.
      if (!connectedClient.cache.evict({ id: refId })) {
        Log.silly(`Unable to evict item with id ${refId} from the cache!`);
      }

      // updateObservableQueries(baseQueryName, cachedQueryName);
    } else if (operation === 'insert') {
      // First, determine if this object is already in the cache.
      const refId = connectedClient.cache.identify(data);
      if (
        refId ||
        !refId ||
        !Object.keys((connectedClient.cache as any).data.data).includes(refId)
      ) {
        // Cache does not have the object. Add it.

        await Promise.all(
          [...connectedClient.getObservableQueries().values()]
            .filter((observableQuery) => {
              const latestResults = observableQuery.getLastResult()?.data ?? {};
              const queryIdentifiers = Object.keys(latestResults);
              const queryTargetTypes = new Set(
                Object.values(latestResults)
                  .map((latestResult) =>
                    Array.isArray(latestResult)
                      ? latestResult[0]
                      : latestResult,
                  )
                  .map((resultObj) => resultObj?.__typename ?? 'NoType')
                  .filter((typename) => typename !== 'NoType'),
              );
              return (
                queryTargetTypes.has(data.__typename) ||
                queryIdentifiers.some((queryIdentifier) =>
                  queryIdentifier
                    .toLowerCase()
                    .startsWith(data.__typename.toLowerCase()),
                )
              );
            })
            .map((observableQuery) => observableQuery.refetch()),
        );

        // As it is not in the cache already, add the new item to the cache via a 'write' query based on its ID.
        // let keys: string[] = [];
        //
        // let updateData = {
        //   [cachedQueryName]: buildUpdateParameters(data, keys),
        // };
        //
        // let attributeSelections = keys.join(', ');
        //
        // let gqlQuery = `
        //     query ProcessSubscriptionEvent($_id: Int!){
        //       ${cachedQueryName}(filter: { _id: $_id }) {
        //         ${attributeSelections}
        //       }
        //     }
        //   `;
        //
        // connectedClient.cache.writeQuery({
        //   query: gql`
        //     ${gqlQuery}
        //   `,
        //   data: updateData,
        //   variables: {
        //     _id: data._id,
        //   },
        // });
        //
        // // // Now update / get the reference ID for the new item within the cache.
        // // refId = connectedClient.cache.identify(data);
        // //
        // // const myData = { ...data };
        // // if (data.modelType && data.modelType !== data.__typename) {
        // //   myData.__typename = data.modelType;
        // // }
        // //
        // // // Now update any appropriate observable queries that should include the newly inserted item.
        // // const cacheUpdate: {
        // //   [fieldName: string]: Modifier<any>;
        // // } = {};
        // //
        // // connectedClient.getObservableQueries().forEach((observableQuery) => {
        // //   const queryResultsMap = observableQuery.getLastResult()?.data;
        // //   const queryIdentifiers = Object.keys(queryResultsMap);
        // //   for (let queryIdentifier of queryIdentifiers) {
        // //     let queryData = queryResultsMap[queryIdentifier];
        // //     if (
        // //       typeof queryData === 'object' &&
        // //       queryData.__typename &&
        // //       queryData.__typename.toLowerCase() ===
        // //       myData.__typename.toLowerCase()
        // //     ) {
        // //       const vars = observableQuery.options.variables ?? {};
        // //       if(typeof vars === 'object') {
        // //         Object.keys(vars).some((key) => {
        // //
        // //         })
        // //       }
        // //       cacheUpdate[cachedQueryName] = (existingItems) => [...existingItems, {__ref: refId}];
        // //     }
        // //   }
        // // });
        // //
        // // if(Object.keys(cacheUpdate).length > 0) {
        // //   connectedClient.cache.modify({
        // //     fields: cacheUpdate,
        // //   });
        // // }
        //
        // // Next, check any relevant queries to see if there are any that should be
        // // updated with this new entry.
        // //  updateObservableQueries(baseQueryName, cachedQueryName);
        //
        // const rootQuery: any = (connectedClient.cache as any).data.data
        //   .ROOT_QUERY;
        // const queryEntries =
        //   rootQuery[cachedQueryName] ||
        //   rootQuery[`${cachedQueryName}({"limit":0})`];
        // const queryEntry = (queryEntries ?? []).find(
        //   (item: any) => item.__ref === refId,
        // );
        //
        // if (!queryEntry) {
        //   // queries are missing the entry. Add it.
        //
        //   // Now update the 'existingItems' query result set to include the newly added item's refID.
        //   connectedClient.cache.modify({
        //     fields: {
        //       [cachedQueryName]: (existingItems) => {
        //         const newItems = [];
        //         for (let i = 0; i < existingItems.length; i++) {
        //           newItems.push(existingItems[i]);
        //         }
        //         newItems.push({ __ref: refId });
        //         return newItems;
        //       },
        //     },
        //   });
        // } else {
        //   // TODO: Peter: look for filtered collection to add to ... see if the filter condition matches? OR ... just look for filtered collection, if found, invalidate the cache and refetch.
        // }
      }
    } else if (operation === 'update') {
      const keys: string[] = [];

      /* make __typename match the entry in modelType to make sure that the cache is
       * updated correctly.
       */
      const myData = { ...data };
      if (data.modelType && data.modelType !== data.__typename) {
        myData.__typename = data.modelType;
      }

      // TODO: Peter: This still doesn't work completely right but it does for our specific and current use cases. Need to investigate further.
      //  for example, try this with thermostat-based queries including the modelType attribute in their subscription queries and you
      //  will find that auto-updates on thermostat views will stop working. But this is needed for 'parent'/'generic' based queries
      //  targeting the discriminator based child objects (such as for device installs updating their status during joining).
      const updateData = {
        [cachedQueryName]: buildUpdateParameters(myData, keys),
      };

      const attributeSelections = keys.join(', ');

      const gqlQuery = `
        query ProcessSubscriptionEvent($_id: Int!){
          ${cachedQueryName}(filter: { _id: $_id }) {
            ${attributeSelections}
          }
        }
      `;

      connectedClient.cache.writeQuery({
        query: gql`
          ${gqlQuery}
        `,
        data: updateData,
        variables: {
          _id: data._id,
        },
      });
    } else {
      console.log(
        '[subscription-utils][update-observable-queries] WARNING - unknown operation',
        operation,
      );
    }
  } else {
    Log.silly(
      '[subscription-utils][update-observable-queries] No connection to the server. Cannot process real-time update.',
    );
  }

  // TODO: Peter: remove if unused.
  // export function updateCachedDeviceFromMetricReadingSubscriptionEvent(
  //   options: OnDataOptions,
  // ) {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   // const { client: connectedClient } = useSystemConnection();
  //   // CHANGED -- for now, revert to above ---- iffy!!!
  //
  //   const connectedClient = options.client;
  //
  //   if (connectedClient) {
  //     const { data: subscriptionEventData } = options;
  //     const { data: subData, error } = subscriptionEventData;
  //
  //     if (error) {
  //       Log.error(
  //         `Error processing device reading subscription: ${
  //           Object.keys(subData)[0]
  //         }: ${error.message}.`,
  //       );
  //     }
  //     const data = subData[Object.keys(subData)[0]];
  //
  //     const cachedQueryName: string = 'device'; // pluralize(data.__typename.toLowerCase());
  //
  //     const operation = data.modelChangeOperation;
  //
  //     // TODO: Peter: Test whether the delete functionality works as expected
  //     // TODO: Peter: Work on supporting the 'replace' action better (at all, actually).
  //     if (operation === 'delete') {
  //       const cacheId = connectedClient.cache.identify(data);
  //       Log.silly(`Evicting item from cache: ${cacheId}`);
  //       if (!connectedClient.cache.evict({ id: cacheId })) {
  //         Log.silly(`Unable to evict item with id ${cacheId} from the cache!`);
  //       }
  //     } else {
  //       const keys: string[] = [];
  //
  //       const updateData = {
  //         [cachedQueryName]: buildUpdateParameters(data, keys),
  //       };
  //
  //       const attributeSelections = keys.join(', ');
  //
  //       const gqlQuery = `
  //       query ProcessSubscriptionEvent($_id: Int!){
  //         ${cachedQueryName}(filter: { _id: $_id }) {
  //           ${attributeSelections}
  //         }
  //       }
  //     `;
  //
  //       connectedClient.cache.writeQuery({
  //         query: gql`
  //           ${gqlQuery}
  //         `,
  //         data: updateData,
  //         variables: {
  //           _id: data._id,
  //         },
  //       });
  //     }
  //   } else {
  //     Log.silly('No connection to the server. Cannot process real-time update.');
  //   }
  // }
}
