/* convenience types */
import {
  UnitListByPropertyIdQuery,
  PropertyScheduleListByPropertyIdQuery,
} from '../../../../types/generated-types';
import { type MRT_ColumnDef } from 'material-react-table';

export type UnitTypeName = 'residential' | 'commonArea' | 'centralEquipment';

export interface UnitType {
  key: UnitTypeName;
  label: string;
  shortLabel: string;
}

export type UnitTypes = Record<UnitTypeName, UnitType>;

export const unitTypes: UnitTypes = {
  residential: {
    key: 'residential',
    label: 'Residential',
    shortLabel: 'Residential',
  },
  commonArea: {
    key: 'commonArea',
    label: 'Common Areas',
    shortLabel: 'Common',
  },
  centralEquipment: {
    key: 'centralEquipment',
    label: 'Central Equipment',
    shortLabel: 'Central',
  },
};

export interface UnitFloor {
  _id: string;
  name: string;
  number?: number | null | undefined;
  buildingId: string;
}

export type MySchedule = NonNullable<
  NonNullable<PropertyScheduleListByPropertyIdQuery>['unitsByPropertyId']
>[number];

export type MyUnit = NonNullable<
  NonNullable<UnitListByPropertyIdQuery>['unitsByPropertyId']
>[number];

export type UnitRow = MyUnit & {
  id: string;
  status: any;
  // building: UnitBuilding;
  buildingName: string;
  floorName: string;
  floor: UnitFloor | null | undefined;
  installationStatus: InstallationStatus;
  heatSetpoint: number | null | undefined;
  coolSetpoint: number | null | undefined;
  temperature: number | null | undefined;
  humidity: number | null | undefined;
  buildingSupplyTemp?: number;
  buildingReturnTemp?: number;
  buildingTempDelta?: number;
};

export type UnitScheduleRow = MySchedule & {
  id: string;
  scheduleType: string;
};

export type InstallationStatus =
  | 'allDevicesInstalled'
  | 'someDevicesInstalled'
  | 'noDevicesInstalled';

export interface EnabledUnitTypes {
  residential: boolean;
  commonArea: boolean;
  centralEquipment: boolean;
}

export type UnitDataColumn = MRT_ColumnDef<UnitDataRow>;

export interface UnitDataRow {
  id: string;
  name?: string | null;
  buildingName?: string;
  delta?: string;
  floorName?: string;
  humidity?: string;
  installation?: string;
  loopReturn?: string;
  loopSupply?: string;
  operatingState?: string | null;
  operatingMode?: string | null;
  setpoint?: string;
  coolSetpoint?: string;
  heatSetpoint?: string;
  status?: {
    count?: number | null;
    majorAlerts?: number | null;
    minorAlerts?: number | null;
  };
  temperature?: string;
}
