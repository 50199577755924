import EJSON, { CustomType } from 'ejson';

export type { CustomType } from 'ejson';

/**
 * Acts as the 'base class' for all client-side, manually coded model classes
 * that might want to be serialized back and forth to the server OR be stored
 * as an object in local storage via the 'LocalState' manager service.
 * This class enabled subclass instances to be de/serialized from/to EJSON.
 * See AuthenticatedUser and reconnectAttemptTimer as examples.
 */
export class Base extends Object implements CustomType {
  static EJSONTypeName = 'EmbueBase';

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  static fromJSONValue(jsonValue: any) {
    return new Base(jsonValue);
  }

  static registerEJSONType() {
    EJSON.addType(this.EJSONTypeName, this.fromJSONValue);
  }

  clone(): CustomType {
    return Object.getPrototypeOf(this).constructor.fromJSONValue(
      this.toJSONValue(),
    );
  }

  equals(other: CustomType): boolean {
    return this === other;
  }

  toJSONValue() {
    return {};
  }

  typeName(): string {
    return Object.getPrototypeOf(this).constructor.EJSONTypeName;
  }
}

Base.registerEJSONType();
