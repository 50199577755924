import React from 'react';
import Avatar from '@mui/material/Avatar';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { useTheme } from '@mui/material/styles';

interface ConnectionIndicatorProps {
  show?: boolean | null;
  deviceOffline?: boolean | null;
}
export function ConnectionIndicator(props: ConnectionIndicatorProps) {
  const { show, deviceOffline } = props;
  const status = Number(deviceOffline);
  const theme = useTheme();

  const connectionIcon: Record<number, JSX.Element> = {
    0: <WifiIcon color={'success'} />,
    1: <WifiOffIcon color={'error'} />,
  };

  const border = `2px solid ${
    status === 0 ? theme.embue.good : theme.embue.bad
  }`;

  return (
    (show && (
      <Avatar sx={{ border: { border }, backgroundColor: 'white' }}>
        {isNaN(status) ? <ErrorIcon color={'error'} /> : connectionIcon[status]}
      </Avatar>
    )) || <></>
  );
}
