import React from 'react';
/* MUI */
import { PaletteColor, useTheme } from '@mui/material/styles';
/* Icons */
import Avatar from '@mui/material/Avatar';
import DoneIcon from '@mui/icons-material/DoneRounded';
import NotDoneIcon from '@mui/icons-material/CancelRounded';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import PlayIcon from '@mui/icons-material/PlayCircleOutline';
import AntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
/* Types */
import { InstallationStatus } from '../types';

/**
 * StatusIndicator
 * - icon appearing at the top of the installer detail screen
 * - shows an icon representation of the current device status
 * - icons can be ambiguous, so an additional message will be displayed
 */
interface StatusIndicatorProps {
  status?: InstallationStatus;
}
export function StatusIndicator(props: StatusIndicatorProps) {
  const { status } = props;
  const theme = useTheme();

  const statusIcons: Record<
    number,
    { color: PaletteColor; icon: JSX.Element }
  > = {
    [InstallationStatus.READY_TO_INSTALL]: {
      color: theme.palette.info,
      icon: <PlayIcon />,
    },
    [InstallationStatus.NETWORK_OPEN]: {
      color: theme.palette.info,
      icon: <AntennaIcon />,
    },
    [InstallationStatus.NETWORK_OPEN_FAILED]: {
      color: theme.palette.warning,
      icon: <NotDoneIcon />,
    },
    [InstallationStatus.CONFIGURATION_IN_PROGRESS]: {
      color: theme.palette.info,
      icon: <CloudSyncIcon />,
    },
    [InstallationStatus.INSTALLATION_SUCCESSFUL]: {
      color: theme.palette.success,
      icon: <DoneIcon />,
    },
    [InstallationStatus.INSTALLATION_FAILED]: {
      color: theme.palette.warning,
      icon: <NotDoneIcon />,
    },
    [InstallationStatus.CONFIGURATION_FAILED]: {
      color: theme.palette.warning,
      icon: <NotDoneIcon />,
    },
  };

  const border = `2px solid ${
    (status && statusIcons[status].color.main) || theme.embue.bad
  }`;

  return status ? (
    <Avatar
      sx={{
        border: { border },
        color: statusIcons[status].color.main,
        backgroundColor: 'white',
      }}
    >
      {statusIcons[status].icon}
    </Avatar>
  ) : (
    <Avatar>
      <ErrorIcon color={'error'} />
    </Avatar>
  );
}
