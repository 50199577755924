import React, { useEffect, useState } from 'react';

/* NAVIGATION */
import { useParams } from 'react-router-dom';

/* COMPONENTS */
import { ScheduleTemplateDetail } from './schedule-template-detail';

/* LOADING */
import FullWidthLoadingSkeleton from '../../../shared/fullWidthLoadingSkeleton';

/* NOTIFICATIONS */
import { Notifier } from '../../../../system/services/notificationManager';

/* TYPES */
import {
  Unit,
  ThermostatScheduleTemplate,
  useThermostatScheduleTemplateDetailQuery,
  useThermostatScheduleTemplateDetailUpdatesSubscription,
} from '../../../../../types/generated-types';
import {
  handleModelUpdateEvent,
  updateCacheFromSubscriptionEvent,
} from '../../../../../helpers/subscriptionUtils';
import { MyThermostat } from '../../../devices/thermostat/types';

interface ScheduleDetailHandlerProps {
  templateDialogId?: string;
  unit?: Partial<Unit>;
  thermostat?: MyThermostat;
  handleClose?: () => void;
  disableSetpointProfileSelection?: boolean;
}

export const ScheduleDetailHandler = (props: ScheduleDetailHandlerProps) => {
  const {
    templateDialogId,
    handleClose,
    unit,
    thermostat,
    disableSetpointProfileSelection,
  } = props;
  const { templateId } = useParams<{ templateId: string }>();
  const [template, setTemplate] = useState<ThermostatScheduleTemplate>();
  const id = templateDialogId ? templateDialogId : templateId;

  /* DATA */
  const {
    data: templateData,
    loading: templateDataLoading,
    error: templateDataError,
    refetch: refetchThermostatScheduleTemplateDetail,
  } = useThermostatScheduleTemplateDetailQuery({
    variables: { id: id ?? '' },
    fetchPolicy: 'network-only',
  });

  /* SUBSCRIPTIONS */
  useThermostatScheduleTemplateDetailUpdatesSubscription({
    variables: { id: id ?? '' },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (templateDataError) {
      Notifier.error(
        'There was a problem fetching the schedule template information: ',
        templateDataError,
      );
    }
  }, [templateDataError]);

  useEffect(() => {
    if (templateData?.thermostatScheduleTemplateById) {
      setTemplate(
        templateData.thermostatScheduleTemplateById as ThermostatScheduleTemplate,
      );
    }
  }, [templateData]);

  return templateDataLoading ? (
    <FullWidthLoadingSkeleton />
  ) : template ? (
    <ScheduleTemplateDetail
      template={template}
      handleClose={handleClose}
      disableSetpointProfileSelection={disableSetpointProfileSelection}
    />
  ) : templateId === undefined ? (
    <ScheduleTemplateDetail
      template={null}
      unit={unit}
      thermostat={thermostat}
      disableSetpointProfileSelection={disableSetpointProfileSelection}
      handleClose={handleClose}
    />
  ) : (
    <div>No data</div>
  );
};
