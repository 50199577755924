import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import DataGrid, { Column, textEditor } from 'react-data-grid';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { AlertTrigger, Property } from '../../../../../types/generated-types';
import {
  SubscriptionMethods,
  SubscriptionPropertyTriggerMap,
  SubscriptionRow,
} from './types';

import BorderedSection from '../../../shared/borderedSection';
import './update-subscriptions-dialog.css';

interface UpdateSubscriptionsDialogProps {
  open: boolean;
  handleClose: () => void;
  properties: Partial<Property>[];
  smsEnabled: boolean;
  emailEnabled: boolean;
  subscriptions: SubscriptionPropertyTriggerMap;
  updateSubscriptions: (
    propertyId: string,
    subs: SubscriptionRow[],
  ) => Promise<void>;
}

export const UpdateSubscriptionsDialog = (
  props: UpdateSubscriptionsDialogProps,
) => {
  const {
    open,
    handleClose,
    updateSubscriptions,
    properties,
    smsEnabled,
    emailEnabled,
    subscriptions,
  } = props;
  const [selectedProperty, setSelectedProperty] =
    React.useState<Partial<Property> | null>(null);
  const [dirty, setDirty] = React.useState(false);

  const [rows, setRows] = useState<Array<SubscriptionRow>>([]);
  const [originalRows, setOriginalRows] = useState<Array<SubscriptionRow>>([]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (selectedProperty?._id) {
      const propId: string = selectedProperty._id;
      const newRows: Array<SubscriptionRow> = (
        (selectedProperty.alertTriggers ?? []) as Array<AlertTrigger>
      ).map((a) => {
        const existingSubscriptionMethods: SubscriptionMethods = (subscriptions[
          propId
        ] ?? { [a._id]: { sms: false, email: false } })[a._id] ?? {
          sms: false,
          email: false,
        };
        return {
          id: a._id ?? '0',
          name: a.name ?? 'No name',
          email: existingSubscriptionMethods.email,
          sms: existingSubscriptionMethods.sms,
        };
      });
      const originalRows: Array<SubscriptionRow> = (
        (selectedProperty.alertTriggers ?? []) as Array<AlertTrigger>
      ).map((a) => {
        const existingSubscriptionMethods: SubscriptionMethods = (subscriptions[
          propId
        ] ?? { [a._id]: { sms: false, email: false } })[a._id] ?? {
          sms: false,
          email: false,
        };
        return {
          id: a._id ?? '0',
          name: a.name ?? 'No name',
          email: existingSubscriptionMethods.email,
          sms: existingSubscriptionMethods.sms,
        };
      });
      setRows(newRows);
      setOriginalRows(originalRows);
    }
  }, [selectedProperty, subscriptions]);

  useEffect(() => {
    if (rows && originalRows) {
      setDirty(
        rows.some((r) => {
          const oRow = originalRows.find((o) => o.id === r.id);
          if (oRow) {
            return oRow.sms !== r.sms || oRow.email !== r.email;
          } else {
            console.log('bad row', r);
            return false;
          }
        }),
      );
    }
  }, [rows, originalRows]);

  interface RenderCheckboxProps
    extends Pick<
      React.InputHTMLAttributes<HTMLInputElement>,
      'aria-label' | 'aria-labelledby' | 'checked' | 'tabIndex' | 'disabled'
    > {
    onChange: (checked: boolean, shift: boolean) => void;
  }

  const MyCheckbox = ({ onChange, ...props }: RenderCheckboxProps) => {
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      onChange(e.target.checked, (e.nativeEvent as MouseEvent).shiftKey);
    }

    return (
      <label
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          inset: 0,
          marginInlineEnd: '1px',
        }}
      >
        <input type="checkbox" {...props} onChange={handleChange} />
      </label>
    );
  };

  const getColumns = useMemo((): Column<SubscriptionRow>[] => {
    const subscriptionColumns: Column<SubscriptionRow>[] = [
      {
        key: 'name',
        name: 'Name',
        // width: 'max-content',
        draggable: false,
        resizable: false,
        sortable: false,
      },
    ];
    if (emailEnabled) {
      const emailColumn: Column<SubscriptionRow> = {
        key: 'email',
        name: isSmall ? 'Email' : 'via Email',
        width: 'min-content',
        draggable: false,
        resizable: false,
        sortable: false,
        renderHeaderCell: ({ column }) => (
          <div
            onClick={() => {
              const newValue = !!rows.find((r) => !r.email);
              setRows(
                rows.map((r) => {
                  r.email = newValue;
                  return r;
                }),
              );
            }}
          >
            {column.name}
          </div>
        ),
        renderCell({ row, onRowChange, tabIndex }) {
          // @ts-ignore
          return (
            <MyCheckbox
              checked={row.email}
              onChange={() => {
                onRowChange({ ...row, email: !row.email });
              }}
              tabIndex={tabIndex}
            />
          );
        },
      };
      subscriptionColumns.push(emailColumn as any);
    }

    if (smsEnabled) {
      const smsColumn: Column<SubscriptionRow> = {
        key: 'sms',
        name: isSmall ? 'SMS' : 'via SMS',
        width: 'min-content',
        draggable: false,
        resizable: false,
        sortable: false,
        renderHeaderCell: ({ column }) => (
          <div
            onClick={() => {
              const newValue = !!rows.find((r) => !r.sms);
              setRows(
                rows.map((r) => {
                  r.sms = newValue;
                  return r;
                }),
              );
            }}
          >
            {column.name}
          </div>
        ),
        renderCell({ row, onRowChange, tabIndex }) {
          return (
            <MyCheckbox
              checked={row.sms}
              onChange={() => {
                onRowChange({ ...row, sms: !row.sms });
              }}
              tabIndex={tabIndex ?? 0}
            />
          ) as ReactElement;
        },
      };

      subscriptionColumns.push(smsColumn as any);
    }

    return subscriptionColumns;
  }, [rows, smsEnabled, emailEnabled, isSmall]);

  const resetRows = useMemo(
    () => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      if (selectedProperty?._id) {
        const propId: string = selectedProperty._id;
        const refreshedRows: Array<SubscriptionRow> = (
          (selectedProperty.alertTriggers ?? []) as Array<AlertTrigger>
        ).map((a) => {
          const existingSubscriptionMethods: SubscriptionMethods =
            (subscriptions[propId] ?? {
              [a._id]: { sms: false, email: false },
            })[a._id] ?? { sms: false, email: false };
          return {
            id: a._id,
            name: a?.name ?? 'No name',
            email: existingSubscriptionMethods.email,
            sms: existingSubscriptionMethods.sms,
          };
        });
        setRows(refreshedRows);
      }
    },
    [selectedProperty, subscriptions],
  );

  function rowKeyGetter(row: SubscriptionRow) {
    return row.id;
  }

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          width: isSmall ? '98vw' : '1000px',
          maxWidth: isSmall ? '98vw' : '90vw',
          height: isSmall ? '98vh' : '90vh',
          minHeight: isSmall ? '0vh' : '310px',
          margin: isSmall ? '8vh 0px 0px' : 'undefined',
          padding: isSmall ? '0px 0px 0px' : 'unset',
        },
        '& .MuiDialogTitle-root': {
          fontSize: isSmall ? '25px' : '30px',
        },
      }}
      open={open}
      onClose={() => {}}
      aria-labelledby="update-subscriptions-dialog-title"
      aria-describedby="add-subscriptions-dialog-description"
    >
      <div
        style={{
          fontSize: isSmall ? '14px' : '25px',
          fontWeight: 'bold',
          margin: isSmall ? '6px' : '10px',
        }}
        id="update-subscriptions-dialog-title"
      >
        Update Subscriptions
      </div>
      <DialogContent
        style={{
          paddingTop: '10px',
          marginLeft: isSmall ? '2px' : '10px',
          marginRight: isSmall ? '2px' : '10px',
          paddingRight: isSmall ? '2px' : 'unset',
          paddingLeft: isSmall ? '2px' : 'unset',
          marginBottom: '-20px',
        }}
      >
        <div style={{ width: '100%' }}>
          <FormControl fullWidth>
            <InputLabel
              id="schedule-type-label"
              style={{
                paddingRight: '4px',
                paddingTop: dirty ? '4px' : 'unset',
                paddingBottom: dirty ? '4px' : 'unset',
                marginLeft: dirty ? '-4px' : 'unset',
                paddingLeft: dirty ? '4px' : 'unset',
                marginRight: dirty ? '4px' : 'unset',
                border: dirty ? '1px solid red' : 'unset',
                backgroundColor: dirty ? 'beige' : 'white',
              }}
            >
              Selected Property
              {dirty ? ` (Click 'Update' or 'Reset' to re-enable)` : ''}
            </InputLabel>
            <Select
              disabled={dirty}
              sx={{
                width: '100%',
              }}
              labelId="select-property-label"
              id="selectedProperty"
              value={selectedProperty?._id ?? '0'}
              label="Selected Property"
              onChange={(e) => {
                setSelectedProperty(
                  properties.find(
                    (testProp) => testProp?._id === e.target.value,
                  ) ?? null,
                );
              }}
            >
              {properties
                .filter(
                  (property) =>
                    property !== undefined &&
                    (property?.alertTriggers?.length ?? 0) > 0,
                )
                .map((property: Partial<Property>, index) => (
                  <MenuItem key={property._id ?? index} value={property._id}>
                    {property.title}
                  </MenuItem>
                ))}
              <MenuItem key="0" value="0">
                <em>Select Property</em>
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        {selectedProperty ? (
          <BorderedSection
            style={{
              marginTop: isSmall ? '14px' : '18px',
              marginLeft: isSmall ? '2px' : 'unset',
              marginRight: isSmall ? '2px' : 'unset',
              paddingRight: isSmall ? '2px' : 'unset',
              paddingLeft: isSmall ? '2px' : 'unset',
              height: isSmall ? 'calc(98vh - 232px)' : 'calc(90vh - 212px)',
              minHeight: isSmall ? '0px' : '130px',
            }}
            ccStyle={{
              height: '100%',
              paddingRight: isSmall ? '2px' : 'unset',
              paddingLeft: isSmall ? '2px' : 'unset',
              backgroundColor: isSmall ? 'transparent' : '#e4f1e4',
            }}
            title="Update your alert subscriptions (scroll for more)"
          >
            <DataGrid
              className="rdg-light fill-grid subscriptions-update-grid"
              style={{
                height: isSmall ? 'calc(98vh - 256px)' : 'calc(90vh - 242px)',
                minHeight: isSmall ? '0px' : '100px',
                marginTop: isSmall ? 'unset' : '10px',
                marginLeft: isSmall ? '2px' : '10px',
                marginRight: isSmall ? '2px' : '10px',
                paddingRight: isSmall ? '2px' : 'unset',
                paddingLeft: isSmall ? '2px' : 'unset',
                boxShadow: isSmall
                  ? 'none'
                  : '0 8px 12px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
              rowKeyGetter={rowKeyGetter}
              columns={getColumns}
              rows={rows}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
              onRowsChange={setRows}
            />
          </BorderedSection>
        ) : (
          <DialogContentText
            sx={{ marginTop: isSmall ? '6px' : '12px', textAlign: 'center' }}
            id="update-subscriptions-dialog-select-text"
          >
            {isSmall
              ? 'Select a property to update your subscriptions'
              : 'Select a property to edit your related subscriptions.'}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{dirty ? 'Cancel' : 'Close'}</Button>
        {dirty ? <Button onClick={resetRows}>Reset</Button> : null}
        {dirty ? (
          <Button
            variant="contained"
            onClick={async () => {
              if (selectedProperty?._id) {
                await updateSubscriptions(selectedProperty._id, rows);
                handleClose();
              } else {
                alert('Property not selected');
              }
            }}
            autoFocus
          >
            {isSmall ? 'Update' : 'Update Subscriptions'}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
