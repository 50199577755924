export const Celsius = 'C';
export const Fahrenheit = 'F';
export type TemperatureUnit = typeof Celsius | typeof Fahrenheit;

export const LitersPerSecond = 'LPS';
export const CubicFeetPerMinute = 'CFM';
export type AirFlowUnit = typeof LitersPerSecond | typeof CubicFeetPerMinute;

// TODO: Is there a better location for this file?
// TODO: This type definition is in conflict with an enum type
// available through generated types, which can cause confusing imports and errors.
