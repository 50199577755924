import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ArrowRight from '@mui/icons-material/ArrowRight';
import BulkConfigStep from '../ui/bulk-config-step';
import { BulkConfigPropertiesQuery } from '../../../../types/generated-types';

export interface SelectPropertyProps {
  properties: BulkConfigPropertiesQuery['properties'];
  selectedProperty: string;
  error?: string;
  onSelectProperty: (propertyId: string) => void;
  onNext: () => void;
}

// Initial step to select a proeprty to bulk configure
export default function SelectProperty(props: SelectPropertyProps) {
  const { properties, selectedProperty, error, onSelectProperty, onNext } =
    props;
  const bottomActions = [
    { name: 'Next', action: onNext, endIcon: <ArrowRight /> },
  ];

  return (
    <BulkConfigStep
      title="Select Property"
      bottomActions={bottomActions}
      topActions={[]}
      error={error}
    >
      <FormControl>
        <RadioGroup
          value={selectedProperty}
          onChange={(_, v) => onSelectProperty(v)}
        >
          {properties.map((p) => {
            return (
              <FormControlLabel
                key={p._id}
                value={p._id}
                control={<Radio color="secondary" />}
                label={p.title + ', ' + p.address.line1}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </BulkConfigStep>
  );
}
