import React from 'react';
import { Box, Skeleton, SkeletonProps } from '@mui/material';

interface FullWidthLoadingSkeletonProps extends SkeletonProps {
  padding?: number;
}

// Rectangular loading skeleton that takes up the full width of available space
export default function FullWidthLoadingSkeleton(
  props: FullWidthLoadingSkeletonProps,
) {
  const { padding, sx, ...rest } = props;
  const defaultPadding = 3;
  const defaultHeight = 200;
  return (
    <Box p={padding ?? defaultPadding} sx={{ width: '100%' }}>
      <Skeleton
        variant="rounded"
        height={defaultHeight}
        sx={{ flex: 1, ...sx }}
        {...rest}
      />
    </Box>
  );
}
