import JsSHA from 'jssha';

/**
 * Converts the provided string into an encoded version as per the SHA256 algorithm.
 * @param s
 * @constructor
 */
export const SHA256 = (s: string): string => {
  const shaObj = new JsSHA('SHA-256', 'TEXT', { encoding: 'UTF8' });
  shaObj.update(s);
  return shaObj.getHash('HEX');
};
