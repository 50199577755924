import React, { useEffect } from 'react';

import { Fade, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import {
  IAuthenticationResult,
  useAuthenticator,
} from '../AuthenticationContext';
import { useSessionState } from '../../system/SessionStateManager';

import { Notifier } from '../../system/services/notificationManager';
import { Log } from '../../system/services/logger';
import { LocalState } from '../../system/services/localStateManager';

import {
  AUTH_SESSION_TOKEN_NAME,
  AUTH_SESSION_EXPIRATION_TOKEN_NAME,
  LOCAL_AUTH_USER_KEY,
} from '../../../helpers/config';
import { useTheme } from '@mui/material/styles';

export const Initiate = ({
  onAuthResult,
}: {
  onAuthResult: (res: IAuthenticationResult | void) => void;
}) => {
  const auth = useAuthenticator();
  const SessionStateManager = useSessionState();

  useEffect(() => {
    setTimeout(() => {
      if (
        !LocalState.itemExists(LOCAL_AUTH_USER_KEY) &&
        (SessionStateManager[AUTH_SESSION_TOKEN_NAME] ||
          SessionStateManager[AUTH_SESSION_EXPIRATION_TOKEN_NAME])
      ) {
        auth
          .signOut()
          .then(() => {
            // auth
            //   .authenticate({})
            //   .then(onAuthResult)
            //   .catch((authError) => Notifier.error(authError.message));
          })
          .catch((signOutError) => Notifier.error(signOutError.message));
      } else {
        auth
          .authenticate({})
          .then((res) => {
            onAuthResult(res);
          })
          .catch((authError) => {
            Notifier.error(authError.message);
          });
      }
    }, 3000);
  }, []);

  const instructions: string[] = [
    '',
    'Please be patient while we',
    '',
    `check your device for a saved`,
    '',
    'authentication profile.',
    '',
    '',
  ];

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Fade
      style={{ transitionDuration: '500ms' }}
      in={true}
      mountOnEnter
      unmountOnExit
    >
      <div>
        <Box
          component="div"
          sx={{
            marginTop: '-20px',
            // marginLeft: isSmall ? '30px' : '120px',
            // marginRight: isSmall ? '30px' : '120px',
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              style={{
                width: '100%',
                textAlign: 'center',
                paddingTop: '2px',
              }}
            >
              {instructions.map((line, index) => {
                return line.length ? (
                  <span key={index}>{line}</span>
                ) : (
                  <br key={index} />
                );
              })}
            </Grid>
          </Grid>
        </Box>
        <Grid container width="100%" sx={{ mt: '10px' }}>
          <Grid item xs>
            &nbsp;
          </Grid>
          <Grid item>
            <Link
              sx={{ cursor: 'pointer' }}
              component={Link}
              onClick={() => Log.info('Help button clicked!')}
              variant="body2"
            >
              Need Help?
            </Link>
          </Grid>
          <Grid item xs>
            &nbsp;
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};
