import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UnitDetailForm } from '../unit-detail-form';
import FullWidthLoadingSkeleton from '../../shared/fullWidthLoadingSkeleton';
import {
  Unit,
  Property,
  useUnitDetailEditQuery,
  usePropertyDataForUnitQuery,
} from '../../../../types/generated-types';
import { Notifier } from '../../../system/services/notificationManager';

export const UnitDetailHandler = () => {
  const { unitId } = useParams<{ unitId: string }>();
  const { id } = useParams<{ id: string }>();
  const { viewId } = useParams<{ viewId: string }>();

  const {
    data: unitData,
    error: unitError,
    loading: unitLoading,
  } = useUnitDetailEditQuery({
    variables: { uid: unitId ?? '' },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (unitError) {
      Notifier.error(
        'There was a problem fetching unit information: ',
        unitError,
      );
    }
  }, [unitError]);

  const {
    data: propertyData,
    error: propertyError,
    loading: propertyLoading,
  } = usePropertyDataForUnitQuery({
    variables: { id: id ?? '' },
  });

  useEffect(() => {
    if (propertyError) {
      Notifier.error(
        'There was a problem fetching the property information: ',
        propertyError,
      );
    }
  }, [propertyError]);

  const [unit, setUnit] = useState<Partial<Unit>>();
  const [property, setProperty] = useState<Partial<Property>>();
  useEffect(() => {
    if (unitData?.unitById) {
      setUnit(unitData.unitById as Partial<Unit>);
    }
  }, [unitData]);
  useEffect(() => {
    if (propertyData?.propertyById) {
      setProperty(propertyData.propertyById as Partial<Property>);
    }
  }, [propertyData]);
  return unitLoading || propertyLoading ? (
    <FullWidthLoadingSkeleton />
  ) : unit && property ? (
    <UnitDetailForm
      unit={unit as Partial<Unit>}
      property={property as Partial<Property>}
      unitLoading={unitLoading}
      propertyLoading={propertyLoading}
      viewId={viewId}
    />
  ) : unitId === undefined ? (
    <UnitDetailForm
      unit={{} as Partial<Unit>}
      property={property as Partial<Property>}
    />
  ) : (
    <div>No data </div>
  );
};
