/** DisplayValue
 *  - returns a metric displayed as value, unit, and label
 *  - text colored according to mode, and centered within the available space.
 *  - convert value to F or C according to units param
 *  - accepts styling props that match those of the MUI-Typography element
 */
import React, { CSSProperties } from 'react';
import { DEG_C, DEG_F } from './constants';

/* MUI Imports */
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

/* Type Imports */
import { Maybe } from 'graphql/jsutils/Maybe';
import { Celsius, Fahrenheit } from '../../../system/models/temperatureUnits';

function round(num: number, decimalPlaces = 0) {
  const p = Math.pow(10, decimalPlaces);
  const n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
}

interface DisplayValueProps {
  value?: Maybe<number | string>;
  digits?: number;
  units?: Maybe<string>;
  mode?: Maybe<string>;
  label?: string;
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  valueSize?: string;
  unitsSize?: string;
  labelStyle?: CSSProperties;
  showStatusMessage?: (message: string) => void;
  style?: CSSProperties | undefined;
  valueStyle?: CSSProperties | undefined;
  unitsStyle?: CSSProperties | undefined;
  blankZeros?: boolean;
}

export const displayUnits = (u?: Maybe<string>) => {
  switch (u) {
    case 'DEGREES_CELSIUS':
      return DEG_C;
    case 'DEGREES_FAHRENHEIT':
      return DEG_F;
    case Fahrenheit:
      return DEG_F;
    case Celsius:
      return DEG_C;
    case 'CFM':
      return 'CFM';
    case 'LPS':
      return 'LPS';
    case 'kW':
      return 'kW';
    case 'kWh':
      return 'kWh';
    case '%':
      return '%';
    case 'V':
      return 'V';
    case 'A':
      return 'A';
    case 'W':
      return 'W';
    case 'psi':
      return 'psi';
    case 'IN_H2O':
      return 'inches of water';
    default:
      return '';
  }
};

export function DisplayValue(props: DisplayValueProps) {
  const theme = useTheme();
  const {
    value,
    digits = 0,
    units,
    mode,
    label,
    size,
    style,
    valueSize,
    unitsSize,
    labelStyle,
    valueStyle = {},
    unitsStyle = {},
    blankZeros = false,
  } = props;
  const displayValue = (v?: Maybe<number | string>) => {
    let newValue = 0;
    if (typeof v === 'number') {
      newValue = v;
    } else if (typeof v === 'string') {
      if (v.toLowerCase() === 'off') {
        return 'Off';
      } else {
        try {
          newValue = parseFloat(v);
        } catch (e) {
          return undefined;
        }
      }
    } else {
      newValue = 0;
    }

    return round(newValue, digits);
  };
  const displayColor = (m?: Maybe<string>) => {
    /* includes overloads, so it can apply to either operatingState or operatingMode inputs */
    switch (m) {
      case 'Heat':
        return theme.embue.heating;
      case 'Heating':
        return theme.embue.heating;
      case 'Cool':
        return theme.embue.cooling;
      case 'Cooling':
        return theme.embue.cooling;
      case 'Humidity':
        return theme.embue.humidity;
      case 'Off':
        return theme.embue.off;
      default:
        return undefined;
    }
  };
  const displayLabel = (l?: string) => (l && l.toUpperCase()) || '';

  return (
    <Stack
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      style={style}
      spacing={0.5}
    >
      <Stack
        direction={'row'}
        alignItems={'flex-start'}
        justifyContent={'center'}
      >
        {blankZeros && !value ? (
          <Typography
            sx={
              valueSize
                ? { m: 0, color: displayColor(mode), fontSize: valueSize }
                : { m: 0, color: displayColor(mode) }
            }
            variant={size}
          >
            {'--'}
          </Typography>
        ) : (
          <>
            <Typography
              sx={
                valueSize
                  ? {
                      m: 0,
                      color: displayColor(mode),
                      fontSize: valueSize,
                    }
                  : { m: 0, color: displayColor(mode) }
              }
              variant={size}
              style={{ ...valueStyle }}
            >
              {displayValue(value) ?? value}
            </Typography>
            {[Fahrenheit, Celsius, '%'].includes(units ?? '') ? (
              <Typography
                sx={
                  unitsSize
                    ? { m: 0, color: displayColor(mode), fontSize: unitsSize }
                    : { m: 0, color: displayColor(mode) }
                }
                variant={unitsSize ? undefined : 'h6'}
              >
                <sup>{displayUnits(units)}</sup>
              </Typography>
            ) : units ? (
              <Typography
                sx={
                  valueSize
                    ? {
                        m: 0,
                        color: displayColor(mode),
                        fontSize: unitsSize ? unitsSize : valueSize,
                      }
                    : { m: 0, color: displayColor(mode) }
                }
                variant={size}
                style={{ marginLeft: '1.5px', marginTop: '3px', ...unitsStyle }}
              >
                <sup>{displayUnits(units)}</sup>
              </Typography>
            ) : null}
          </>
        )}
      </Stack>

      <Typography
        noWrap
        align="center"
        variant="caption"
        sx={{ marginTop: '0px !important', fontSize: 'xx-small' }}
        style={labelStyle}
      >
        {displayLabel(label)}
      </Typography>
    </Stack>
  );
}
