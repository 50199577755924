import React from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import DoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRight from '@mui/icons-material/KeyboardArrowRight';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import CardActions from '@mui/material/CardActions';
import { byName, removeFrom } from './lib';
import { SelectList } from './select-list';

interface TransferListProps<T> {
  selectables: T[];
  selected: T[];
  groupId?: keyof T;
  onDone: (selection: string[]) => void;
  onCancel: () => void;
  itemLabel: (a: T) => string;
  itemValue: (a: T) => string;
  groupLabel?: (groupId: string) => string;
}

export function TransferList<T>(props: TransferListProps<T>) {
  const [checked, setChecked] = React.useState<T[]>([]);
  const [unchecked, setUnchecked] = React.useState<T[]>([]);
  const [selected, setSelected] = React.useState<T[]>(props.selected);
  const [selectable, setSelectable] = React.useState<T[]>(
    removeFrom(props.selectables, props.selected, props.itemValue),
  );

  const handleChecked = (entry: T) => {
    const currentIndex = checked.indexOf(entry);
    const _checked = [...checked];
    if (currentIndex === -1) {
      _checked.push(entry);
    } else {
      _checked.splice(currentIndex, 1);
    }
    setChecked(_checked);
  };

  const handleUnchecked = (entry: T) => {
    const currentIndex = unchecked.indexOf(entry);
    const _unchecked = [...unchecked];
    if (currentIndex === -1) {
      _unchecked.push(entry);
    } else {
      _unchecked.splice(currentIndex, 1);
    }
    setUnchecked(_unchecked);
  };

  const handleSelect = () => {
    setSelected(selected.concat([...checked]).sort(byName(props.itemLabel)));
    setSelectable(removeFrom(selectable, checked, props.itemValue));
    setChecked([]);
  };
  const handleSelectAll = () => {
    setSelected(selected.concat([...selectable]).sort(byName(props.itemLabel)));
    setSelectable([]);
    setChecked([]);
    setUnchecked([]);
  };
  const handleUnselectAll = () => {
    setSelectable(
      selectable.concat([...selected]).sort(byName(props.itemLabel)),
    );
    setSelected([]);
    setChecked([]);
    setUnchecked([]);
  };

  const handleUnselect = () => {
    setSelectable(
      selectable.concat([...unchecked]).sort(byName(props.itemLabel)),
    );
    setSelected(removeFrom(selected, unchecked, props.itemValue));
    setUnchecked([]);
  };

  return (
    <Card sx={{ p: 1, width: '100%' }}>
      <CardContent sx={{ p: 1 }}>
        <Stack direction={'row'}>
          <SelectList<T>
            header="Available"
            records={selectable}
            checked={checked}
            groupId={props.groupId}
            handleClick={handleChecked}
            itemLabel={props.itemLabel}
            itemValue={props.itemValue}
            groupLabel={props.groupLabel}
          />
          <Stack
            alignItems={'center'}
            justifyContent={'space-evenly'}
            sx={{ px: 0 }}
          >
            <IconButton
              onClick={handleSelect}
              disabled={!checked.length}
              color={checked.length ? 'success' : 'default'}
            >
              <ArrowRight />
            </IconButton>
            <IconButton onClick={handleSelectAll}>
              <DoubleArrowRight />
            </IconButton>
            <IconButton onClick={handleUnselectAll}>
              <DoubleArrowLeft />
            </IconButton>
            <IconButton
              onClick={handleUnselect}
              disabled={!unchecked.length}
              sx={{
                backgroundColor: checked.length ? 'success' : 'info',
              }}
            >
              <ArrowLeft />
            </IconButton>
          </Stack>
          <SelectList<T>
            header="Selected"
            records={selected}
            checked={unchecked}
            groupId={props.groupId}
            handleClick={handleUnchecked}
            itemLabel={props.itemLabel}
            itemValue={props.itemValue}
            groupLabel={props.groupLabel}
          />
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          variant={'outlined'}
          endIcon={<CancelIcon />}
          color={'error'}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        <Button
          variant={'outlined'}
          endIcon={<DoneIcon />}
          color={'info'}
          onClick={() => {
            props.onDone(selected.map((s) => props.itemValue(s)));
            props.onCancel();
          }}
        >
          Apply
        </Button>
      </CardActions>
    </Card>
  );
}
