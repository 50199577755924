/**
 * SmartPowerOutletComponent
 * - display smart power outlet readings
 * - display 'pairing' information
 * - display device info
 * - control on/off status
 */

import React, { ChangeEvent, useEffect, useState } from 'react';

/* MUI */
import Card from '@mui/material/Card';
import _ from 'lodash';

/* Icons */
import PowerIcon from '@mui/icons-material/Power';

/* Types */
import {
  ChartType,
  SmartPowerOutlet,
  Thermostat,
  useSmartPowerOutletDetailQuery,
  useSmartPowerOutletDetailUpdateSubscription,
  useTurnSmartPowerOutletOnMutation,
  useTurnSmartPowerOutletOffMutation,
  useResetSmartPowerOutletCumulativePowerMutation,
  Device,
} from '../../../../types/generated-types';

import { formatVoltage } from '../../../ui/shared/battery';
import { Switch } from '@mui/material';

/* Shared */
import { DeviceInformation } from '../shared-ui/device-info';

import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
import moment from 'moment/moment';
import Skeleton from '@mui/material/Skeleton';
import BorderedSection from '../../shared/borderedSection';
import Button from '@mui/material/Button';
import { PanelFrame } from '../shared-ui/panel-frame';
import { Log } from '../../../system/services/logger';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankOutlined from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { createSearchParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Notifier } from '../../../system/services/notificationManager';
import { useAuthorizer } from '../../../auth/AuthorizationContext';

/* Utilities */
export function humanize(str: string) {
  return _.capitalize(
    _.trim(_.snakeCase(str).replace(/_id$/, '').replace(/_/g, ' ')),
  );
}

const formatDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };
  return date.toLocaleDateString('en-US', options);
};

interface SmartPowerOutletProps {
  deviceId: string;
  pairedThermostat?: Partial<Thermostat>;
}

function SmartPowerOutletIcon() {
  return <PowerIcon sx={{ fontSize: '1rem', margin: '0px -10px 0px -6px' }} />;
}

export function formatPower(
  value: number | null | undefined,
  digits = 2, // Default to two digits of precision
): string {
  // validate input
  if (!value || value <= 0) return '--';

  const power = value.toPrecision(digits);

  // Continuous voltage measurement case
  return `${power} kWh`;
}

export function formatCurrent(
  value: number | null | undefined,
  digits = 2, // Default to two digits of precision
): string {
  // validate input
  if (!value || value <= 0) return '--';

  const current = Math.round(value);

  // Continuous current measurement case
  return `${current} A`;
}

export function formatActivePower(
  value: number | null | undefined,
  digits = 1, // Default to two digits of precision
): string {
  // validate input
  if (!value || value <= 0) return '--';

  const activePower = Math.round(value);

  // Continuous Active Power measurement case
  return `${activePower} W`;
}

export function formatPowerFactor(
  value: number | null | undefined,
  digits = 1, // Default to two digits of precision
): string {
  // validate input
  if (!value || value <= 0) return '--';

  const powerFactor = Math.round(value);

  // Continuous Power Factor measurement case
  return `${powerFactor}%`;
}

const MatchChartType = {
  'Power Factor (%)': ChartType.SpoPowerFactor,
  'Voltage (V)': ChartType.SpoVoltage,
  'Current (A)': ChartType.SpoCurrent,
  'Active Power (kWh)': ChartType.SpoActivePower,
  'Cumulative Power (kWh)': ChartType.SpoCumulativePower,
  'Apparent Power (VA)': ChartType.SpoApparentPower,
  'Device State (On/Off)': ChartType.SpoIsOn,
};

interface RowItem {
  label: string;
  value: string;
}

interface RowSpec {
  label: string;
  items: RowItem[];
}

type PowerMetric = {
  value: number;
};

type SiUnits = 'VA' | 'W' | 'V' | 'A' | 'kWh' | '%';

function formatNumber(x: number | undefined | null = 0.0, units: SiUnits) {
  const IS_PERCENTAGE = units === '%';

  if (x === null) {
    Log.error('[format-number] invalid entry', x);
    return undefined;
  }
  if (IS_PERCENTAGE && x > 1) {
    Log.error('[format-number] invalid percentage');
    return undefined;
  }

  const _x = IS_PERCENTAGE ? x * 100 : x;

  if (_x < 100) {
    return `${_x.toLocaleString('en-US', { maximumFractionDigits: 1 })} ${units}`;
  }
  return `${_x.toLocaleString('en-US', {
    maximumFractionDigits: 0,
  })} ${units}`;
}

const rows = (device: Partial<SmartPowerOutlet>, toggled: boolean) => {
  const res: RowSpec[] = [];

  let items: RowItem[] = [];

  if (toggled) {
    res.push({
      label: 'Voltage (V)',
      items: [
        {
          label: '',
          value: `${formatNumber(device.rmsVoltage?.value, 'V')}`,
        },
      ],
    });
    res.push({
      label: 'Current (A)',
      items: [
        {
          label: '',
          value: `${formatNumber(device.rmsCurrent?.value, 'A')}`,
        },
      ],
    });
    res.push({
      label: 'Apparent Power (VA)',
      items: [
        {
          label: '',
          value: `${formatNumber(device.apparentPower?.value, 'VA')}`,
        },
      ],
    });
    res.push({
      label: 'Cumulative Power (kWh)',
      items: [
        {
          label: '',
          value: `${formatNumber(device.adjustedCumulativePower?.value, 'kWh')}`,
        },
      ],
    });
    res.push({
      label: 'Power Factor (%)',
      items: [
        {
          label: '',
          value: `${formatNumber((device.powerFactor?.value ?? 0) / 100, '%')}`,
        },
      ],
    });
    res.push({
      label: 'Active Power (kWh)',
      items: [
        {
          label: '',
          value: `${formatNumber(device.activePower?.value, 'kWh')}`,
        },
      ],
    });
    res.push({
      label: 'Device State (On/Off)',
      items: [
        {
          label: '',
          value: !!(device.isOn?.value ?? false) ? 'On' : 'Off',
        },
      ],
    });
  } else {
    items.push({
      label: 'Voltage',
      value: `${formatNumber(device.rmsVoltage?.value, 'V')}`,
    });

    items.push({
      label: 'Current',
      value: `${formatNumber(device.rmsCurrent?.value, 'A')}`,
    });

    res.push({
      label: 'Voltage / Current',
      items,
    });

    items = [];

    items.push({
      label: 'Apparent',
      value: `${formatNumber(device.apparentPower?.value, 'VA')}`,
    });

    items.push({
      label: `Cumulative (${device.cumulativePowerResetTime ? 'since ' + formatDate(new Date(device.cumulativePowerResetTime)) : 'all-time'})`,
      value: `${formatNumber(device.adjustedCumulativePower?.value, 'kWh')}`,
    });

    res.push({
      label: 'Power',
      items,
    });

    items = [];

    items.push({
      label: 'Power Factor',
      value: `${formatNumber((device.powerFactor?.value ?? 0) / 100, '%')}`,
    });

    items.push({
      label: 'Active Power',
      value: `${formatNumber(device.activePower?.value, 'W')}`,
    });

    res.push({
      label: 'Efficiency',
      items,
    });

    // items = [];
    //
    // items.push({
    //   widget: () => Button,
    // });
  }

  return res;
};

function handleIconSelect(type: string, selectedItems: string[]) {
  console.log('handleIconSelect', type, selectedItems);
  const isSelected = selectedItems.indexOf(type);
  if (isSelected !== -1) {
    return <CheckBoxIcon sx={{ fontSize: 'inherit' }} />;
  } else {
    return <CheckBoxOutlineBlankOutlined sx={{ fontSize: 'inherit' }} />;
  }
}

type UpdatingState = 'Updating' | 'Awaiting' | 'Blocked' | 'Idle';

export function SmartPowerOutletComponent({
  deviceId,
  pairedThermostat,
}: SmartPowerOutletProps) {
  const [device, setDevice] = useState<Partial<SmartPowerOutlet>>();
  const { data, loading, error } = useSmartPowerOutletDetailQuery({
    variables: { id: deviceId },
  });
  const [deviceInfo, setDeviceInfo] = useState<DeviceInformation[]>([]);
  const [updatingState, setUpdatingState] = useState<UpdatingState>('Idle');
  const [turnOutletOn] = useTurnSmartPowerOutletOnMutation();
  const [turnOutletOff] = useTurnSmartPowerOutletOffMutation();
  const [resetCumulativePower] =
    useResetSmartPowerOutletCumulativePowerMutation();

  useEffect(() => {
    if (data?.smartPowerOutletById) {
      setDevice(data.smartPowerOutletById as any);
    }
  }, [data]);

  useEffect(() => {
    if (device) {
      const panelInfo: DeviceInformation = {
        Zone: device.zone?.name ?? 'Unnamed',
        'Zone ID': device.zoneId ?? 'Unidentified',
        Source: device.source?.name ?? 'Unnamed source',
      };

      let deviceInfo: DeviceInformation = {
        'Device ID': device.deviceId ?? device._id ?? 'Unidentified',
        Name: device.name ?? 'Unnamed',
        Type: device.typeDisplayName ?? 'Unknown',
        firmwareInfo: {
          Model:
            device.meta?.model || device.meta?.modelNumber || 'Unknown model',
          Version: device.meta?.version || 'Unknown version',
        },
        State: device.isOn ? 'On' : 'Off',
        Voltage: formatVoltage(device.rmsVoltage?.value),
        Current: formatCurrent(device.rmsCurrent?.value),
        'Active Power': formatActivePower(device.activePower?.value),
        'Power Factor': formatPowerFactor(device.powerFactor?.value),
        'Cumulative Power': formatPower(device.cumulativePower?.value),
      };

      if (device.cumulativePowerResetTime) {
        deviceInfo = {
          ...deviceInfo,
          'Cumulative Power (since reset)': formatPower(
            device.adjustedCumulativePower?.value,
          ),
          'Cumulative Power Reset Time': formatDate(
            new Date(device.cumulativePowerResetTime),
          ),
          'Last Updated': moment(device.timestamp).fromNow(),
        };
      } else {
        deviceInfo = {
          ...deviceInfo,
          'Last Updated': moment(device.timestamp).fromNow(),
        };
      }

      const devicesInfo: DeviceInformation[] = [deviceInfo];

      setDeviceInfo([panelInfo, ...devicesInfo]);
    }
  }, [device]);

  useEffect(() => {
    if (device?.hasPendingUpdates) {
      if (updatingState === 'Idle') {
        setUpdatingState('Blocked');
        Notifier.warn('Someone else is updating this device.');
      } else if (updatingState === 'Updating') {
        setUpdatingState('Awaiting');
      }
    } else {
      if (updatingState === 'Updating' || updatingState === 'Blocked') {
        setUpdatingState('Idle');
      } else if (updatingState === 'Awaiting') {
        setUpdatingState('Idle');
        Notifier.success('Device update completed.');
      }
    }
  }, [device?.hasPendingUpdates]);

  useSmartPowerOutletDetailUpdateSubscription({
    variables: { ids: [deviceId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  const navigate = useNavigate();
  const { can, cannot } = useAuthorizer();

  const [toggled, toggleMultiSelect] = React.useState(false);
  const [selectedItems, selectItems] = React.useState<string[]>([]);
  const [enableExecuteGraphWizard, setEnableExecuteGraphWizard] =
    React.useState(false);

  function loadCharts() {
    const charts = selectedItems.join(',');
    const now = dayjs();
    const params = {
      devices: device?.deviceId || '',
      charts,
      period: '24h',
      start: now.subtract(1, 'day').format(),
      end: now.format(),
    };
    navigate({
      pathname: './charts',
      search: `?${createSearchParams(params)}`,
    });
  }

  function handleGraphWizardClicked() {
    if (toggled) {
      if (enableExecuteGraphWizard) {
        loadCharts();
      } else {
        return;
      }
    }
    toggleMultiSelect(!toggled);
  }

  function handleCancelGraphWizard() {
    if (toggled && selectedItems.length > 0) {
      selectItems([]);
    }
    toggleMultiSelect(!toggled);
  }

  const handleClick = (type: string) => {
    const exists = selectedItems.indexOf(type);
    if (exists === -1) {
      selectItems((prevItems) => [...prevItems, type]);
    } else {
      selectItems(selectedItems.filter((item, i) => i !== exists));
    }
  };

  React.useEffect(() => {
    setEnableExecuteGraphWizard(selectedItems.length > 0 && toggled);
  }, [selectedItems, toggled]);

  const handleOnOffToggleChange = async (checked: boolean) => {
    if (!device || !device.deviceId) {
      return;
    }

    try {
      setUpdatingState('Updating');
      if (checked) {
        await turnOutletOn({ variables: { deviceId: device.deviceId } });
      } else {
        await turnOutletOff({ variables: { deviceId: device.deviceId } });
      }
    } catch (updateError) {
      setUpdatingState('Idle');
      console.error('Error updating device state:', updateError);
      Notifier.error('Failed to update smart power outlet state.');
    }
  };

  const handleResetCumulativePower = async (revert: boolean) => {
    if (!device || !device.deviceId) {
      return;
    }

    try {
      await resetCumulativePower({
        variables: { deviceId: device.deviceId, revert: revert },
      });
    } catch (resetError) {
      console.error('Error resetting cumulative power:', resetError);
      Notifier.error('Failed to reset cumulative power.');
    }
  };

  return error ? (
    <div>Error: {JSON.stringify(error)}</div>
  ) : loading ? (
    <DeviceLoadingSkeleton />
  ) : device ? (
    <PanelFrame
      iconType="ic:outline-power"
      zone={device.zone}
      device={device as Partial<Device>}
      label={device.name}
      infoEntries={deviceInfo}
      toggleGraphWizard={handleGraphWizardClicked}
      cancelGraphWizard={handleCancelGraphWizard}
      enableExecuteGraphWizard={enableExecuteGraphWizard}
      editingEnabled={false}
    >
      {can('control', 'SmartPowerOutlet') ? (
        ['Updating', 'Blocked'].includes(updatingState) ? (
          <div style={{ marginLeft: '20px' }}>Device Updating ...</div>
        ) : updatingState === 'Awaiting' ? (
          <div style={{ marginLeft: '20px' }}>
            Awaiting update confirmation ...
          </div>
        ) : (
          <BorderedSection
            key={`control-buttons-1`}
            title="Outlet Control"
            style={{
              margin: '12px 4px 0px 4px',
              backgroundColor: 'rgb(244, 245, 230)',
            }}
            ccStyle={{
              padding: '4px',
              marginTop: '-12px',
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            <FormGroup
              style={{
                padding: '16px 6px 4px 16px',
                marginTop: '12px',
                marginBottom: '1px',
                borderRadius: '6px',
                backgroundColor:
                  device.isOn?.value ?? false ? 'lightgreen' : 'transparent',
              }}
            >
              <FormControlLabel
                // disabled={!editingEnabled}
                key="toggle-on-off-switch"
                control={
                  <Switch
                    size="small"
                    onChange={async (
                      event: ChangeEvent<HTMLInputElement>,
                      checked: boolean,
                    ) => {
                      await handleOnOffToggleChange(checked);
                    }}
                    name="toggle-on-off-switch"
                    id="toggle-on-off-switch"
                    checked={device.isOn?.value ?? false}
                  />
                }
                label={
                  <span
                    style={{
                      padding: device.isOn?.value ?? false ? '2px' : '0px',
                      fontSize: '18px',
                      color: device.isOn?.value ?? false ? 'green' : 'darkgray',
                      backgroundColor:
                        device.isOn?.value ?? false
                          ? 'lightgreen'
                          : 'transparent',
                    }}
                  >
                    {device.isOn?.value ?? false ? 'On' : 'Off'}
                  </span>
                }
              />
            </FormGroup>
            <BorderedSection
              key={`cumulative-buttons-1`}
              title="Cumulative Power Display"
              style={{
                margin: '12px 4px 0px 4px',
                backgroundColor: 'rgb(244, 245, 230)',
              }}
              ccStyle={{
                padding: '4px',
                marginTop: '-6px',
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <div style={{ marginBottom: '4px' }}>
                <Button
                  disabled={cannot('reset', 'SmartPowerOutlet')}
                  variant="contained"
                  color="primary"
                  onClick={() => handleResetCumulativePower(false)}
                >
                  {device.cumulativePowerResetTime
                    ? 'Reset'
                    : 'Reset Cumulative Power'}
                </Button>
              </div>
              {device.cumulativePowerResetTime ? (
                <div style={{ marginBottom: '4px' }}>
                  <Button
                    disabled={cannot('reset', 'SmartPowerOutlet')}
                    variant="contained"
                    color="primary"
                    onClick={() => handleResetCumulativePower(true)}
                  >
                    Restore
                  </Button>
                </div>
              ) : null}
            </BorderedSection>
          </BorderedSection>
        )
      ) : null}
      {rows(device, toggled).map((row, index) => {
        return (
          <div key={`${row.label}-row-div-${index}`}>
            <BorderedSection
              key={`${row.label}-section-${index}`}
              title={row.label}
              style={{
                padding: '0px',
                margin: '14px 4px 0px 4px',
                backgroundColor: '#f4f5e6',
              }}
              ccStyle={{
                padding: '2px 2px 6px 2px',
                margin: '0px',
                marginTop: '-3px',
              }}
            >
              <div
                key={`${row.label}-section-div-${index}`}
                style={{
                  display: 'inline-flex',
                  width: '100%',
                  paddingTop: toggled ? '10px' : 'inherit',
                }}
              >
                {!toggled ? (
                  <></>
                ) : (
                  <Button
                    key={`${row.label}-select-button-${index}`}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      color: '#000',
                      fontSize: '1.4rem',
                    }}
                    onClick={() => {
                      handleClick(
                        MatchChartType[
                          row.label as keyof typeof MatchChartType
                        ],
                      );
                    }}
                  >
                    {handleIconSelect(
                      MatchChartType[row.label as keyof typeof MatchChartType],
                      selectedItems,
                    )}
                  </Button>
                )}
                {row.items.map((item, index2) => (
                  <BorderedSection
                    key={`${row.label}-phase-${index2}-section-${index}`}
                    title={item.label}
                    style={{
                      margin: '0px 2px',
                      backgroundColor: 'white',
                    }}
                    ccStyle={{ padding: '0px', marginTop: '-6px' }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {item.value}
                    </span>
                  </BorderedSection>
                ))}
              </div>
            </BorderedSection>
          </div>
        );
      })}
    </PanelFrame>
  ) : (
    <Card>
      <Skeleton variant="rectangular" />
    </Card>
  );
}
