import React, { useCallback, useEffect, useState } from 'react';

import {
  IAuthenticationResult,
  useAuthenticator,
} from '../AuthenticationContext';
import { IAuthState, useSessionState } from '../../system/SessionStateManager';

import { Log } from '../../system/services/logger';
import { Notifier } from '../../system/services/notificationManager';

import { AUTH_STATE_TOKEN_NAME } from '../../../helpers/config';

import {
  AuthenticationProfileFactors,
  EnumAuthenticationProfileFactorsType,
} from '../../../types/generated-types';

import { AuthenticationPanel } from '../components/AuthenticationPanel';
import { Email } from '../factors/Email';
import { Initiate } from '../factors/Initiate';
import { Password } from '../factors/Password';
import { Passkey } from '../factors/Passkey';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { AlternateFactors } from '../factors/alternateFactors';
import { SmartAlexa } from '../factors/SmartAlexa';
import { AccountLinkingRequestData } from '../types/smart';

/**
 * This component acts as the container and coordinator for the MFA workflow,
 * displaying the appropriate 'factor' screens as indicated by context and / or
 * by the server responses to the user's input at each factor/stage.
 * @constructor
 */
export function AuthenticateUser() {
  const SessionStateManager = useSessionState();
  const auth = useAuthenticator();
  const [loginStateChangeTimer, setLoginStateChangeTimer] = useState<
    ReturnType<typeof setTimeout> | undefined
  >();
  const [authState, setAuthState] = useState<IAuthState | undefined>();
  const [alternate, setAlternate] = useState<boolean>(false);
  const [smartLinkLoginParams, setSmartLinkLoginParams] = useState<
    AccountLinkingRequestData | undefined
  >(undefined);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams) {
      const requestParams: AccountLinkingRequestData = {
        state: searchParams.get('state') || '',
        client_id: searchParams.get('client_id') || '',
        scope: (searchParams.get('scope') || '')
          .split('+')
          .join(' ')
          .split(' '),
        response_type: searchParams.get('response_type') || '',
        redirect_uri: searchParams.get('redirect_uri') || '',
      };

      if (
        requestParams.state &&
        requestParams.client_id &&
        requestParams.response_type &&
        requestParams.redirect_uri
      ) {
        setSmartLinkLoginParams(requestParams);
      }
    } else {
      setSmartLinkLoginParams(undefined);
    }
  }, [searchParams]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentFactor, setCurrentFactor] = useState<
    AuthenticationProfileFactors | undefined
  >(undefined);

  const [alternateFactors, setAlternateFactors] = useState<
    AuthenticationProfileFactors[] | undefined
  >(undefined);

  useEffect(() => {
    const newAuthState: IAuthState | undefined =
      SessionStateManager[AUTH_STATE_TOKEN_NAME];

    setAuthState(newAuthState);
  }, [SessionStateManager]);

  useEffect(() => {
    if (authState) {
      const { exp } = authState;
      if (exp) {
        const timerHandle = setTimeout(
          () => {
            auth
              .resetAuthState()
              .then(onAuthResult)
              .catch((authError) => {
                Log.error(authError.message, authError);
              });
          },
          exp * 1000 - Date.now(),
        );
        setLoginStateChangeTimer(timerHandle);
      }
    } else if (loginStateChangeTimer) {
      clearTimeout(loginStateChangeTimer);
      setLoginStateChangeTimer(undefined);
    }
  }, [authState]);

  const onAuthResult = useCallback(
    (res: IAuthenticationResult | void, viaReset = false) => {
      if (res) {
        const { factor, alternateFactors, user, message, reset, alternate } =
          res;
        setAlternate(alternate || false);
        if (message) {
          if (message.severity === 0) {
            Notifier.error(message.message);
          } else if (message.severity === 1) {
            Notifier.warn(message.message);
          } else {
            Notifier.info(message.message);
          }
        }
        if (reset && viaReset) {
          Notifier.error(
            'Unable to process request. Press the browser "refresh" button or restart your mobile app. If the problem persists, please contact support.',
          );
        } else if (reset) {
          auth
            .resetAuthState(true)
            .then(() => {
              auth
                .authenticate({})
                .then((result) => onAuthResult(result, true))
                .catch((authError) => {
                  Notifier.warn(authError.message);
                });
            })
            .catch((resetError) => {
              Notifier.warn(resetError.message);
            });
        } else {
          if (factor) {
            setAlternateFactors(undefined);
            setCurrentFactor(factor);
          } else if (alternateFactors && alternateFactors?.length > 0) {
            setCurrentFactor(undefined);
            setAlternateFactors(alternateFactors);
          }

          if (user) {
            if (smartLinkLoginParams) {
              setAlternateFactors(undefined);
              setCurrentFactor({
                __typename: 'AuthenticationProfileFactors',
                order: 0,
                type: EnumAuthenticationProfileFactorsType.SmartAlexa,
                value: JSON.stringify(smartLinkLoginParams),
              });
            } else {
              Notifier.success(
                'You are now logged in.',
                {},
                { autoClose: 1500 },
              );
              if (loginStateChangeTimer) {
                clearTimeout(loginStateChangeTimer);
                setLoginStateChangeTimer(undefined);
              }
              if (location.pathname === '/') {
                navigate('/properties', { replace: true });
              }
            }
          }
        }
      }
    },
    [authState],
  );

  const renderCurrentFactor = useCallback(
    (
      factor: AuthenticationProfileFactors | undefined,
      alternateFactors: AuthenticationProfileFactors[] | undefined,
    ) => {
      if (factor) {
        switch (factor?.type) {
          case EnumAuthenticationProfileFactorsType.Password:
            return (
              <Password
                onAuthResult={onAuthResult}
                authState={authState}
                smart={!!smartLinkLoginParams}
              />
            );
          case EnumAuthenticationProfileFactorsType.Email:
            return <Email onAuthResult={onAuthResult} />;
          case EnumAuthenticationProfileFactorsType.Passkey:
            return (
              <Passkey
                onAuthResult={onAuthResult}
                authState={authState}
                name={factor.name}
                promptImmediately={alternate}
                smart={!!smartLinkLoginParams}
              />
            );
          case EnumAuthenticationProfileFactorsType.SmartAlexa:
            return <SmartAlexa smartLinkLoginParams={smartLinkLoginParams} />;
          default:
            return <div>Please contact Embue Support</div>;
        }
      } else if (alternateFactors && alternateFactors?.length > 0) {
        return (
          <AlternateFactors
            onAuthResult={onAuthResult}
            authState={authState}
            alternateFactors={alternateFactors}
          />
        );
      } else {
        return <Initiate onAuthResult={onAuthResult} />;
      }
    },
    [authState],
  );

  const username = ((authState?.current?.context?.display || '').split(' ') || [
    '',
  ])[0];

  return (
    <AuthenticationPanel
      title={
        authState?.current?.context?.display
          ? isSmall
            ? 'Welcome back'
            : `Welcome back ${username}`
          : currentFactor?.type ===
              EnumAuthenticationProfileFactorsType.SmartAlexa
            ? 'Link Accounts?'
            : 'Please sign in'
      }
      subtitle={isSmall ? `${username}` : undefined}
    >
      {renderCurrentFactor(currentFactor, alternateFactors)}
    </AuthenticationPanel>
  );
}
