/**
 * Update Info Box
 * This is the effect handler function used to set device information shown in the info section
 * of the thermostat interface.
 * The function returns an array that is passed to the state setter function in the main component.
 */
import dayjs from 'dayjs';
import {
  CurrentAttributes,
  DeviceInfo,
  MyModbusAirHandler,
  ModbusAirHandlerComponentProps,
  ModbusAirHandler,
} from '../types';
import { DeviceMetricSpec } from '../../../../../types/generated-types';
import { DEG_C, DEG_F } from '../../shared-ui/constants';

type MyAttributes = Omit<
  CurrentAttributes,
  'rhSetpointRange' | 'freshAirSetpointRange'
>;

const temperatureTypes = [
  'DEGREES_CELSIUS',
  'DEGREES_FAHRENHEIT',
  DEG_F,
  DEG_C,
  'DEGREES_C',
  'DEGREES_F',
];

function infoFromKey(
  key: keyof MyAttributes,
  values: CurrentAttributes,
  device: Partial<ModbusAirHandler>,
): { label: string; value: string } {
  const meta: DeviceMetricSpec | undefined = (
    device.deviceMetrics?.find((m) => m?.key === key) ?? { value: undefined }
  ).value;

  let label = 'Unknown';
  let value = 'Unknown';
  if (
    meta
    // (!['FLOAT', 'INTEGER'].includes(meta.valueType?.toUpperCase() ?? '') ||
    //   meta.units?.toUpperCase() !== 'NONE')
  ) {
    if (meta.description) {
      label = meta.description;
    }
    switch (meta.valueType?.toUpperCase()) {
      case 'CHOICE':
        value = `${values[key]}`;
        break;
      case 'STRING':
        value = `${values[key]}`;
        break;
      case 'BOOLEAN':
        value = values[key] ? 'True' : 'False';
        break;
      case 'FLOAT':
        if (['CFM', 'LPS'].includes(meta.units?.toUpperCase() ?? '')) {
          value = `${values[key]} ${values.airflowUnits}`;
        } else if (temperatureTypes.includes(meta.units?.toUpperCase() ?? '')) {
          value = `${values[key]} ${values.temperatureUnits}`;
        } else if ('PERCENT' === meta.units?.toUpperCase()) {
          value = `${values[key]}%`;
        } else if (
          (meta.units?.toUpperCase() ?? 'NO_MATCH').startsWith('MILLISECOND')
        ) {
          value = `${values[key]} ms`;
        } else if (
          (meta.units?.toUpperCase() ?? 'NO_MATCH').startsWith('SECOND')
        ) {
          value = `${values[key]} s`;
        } else if (
          (meta.units?.toUpperCase() ?? 'NO_MATCH').startsWith('METER')
        ) {
          value = `${values[key]} m`;
        } else if (
          (meta.units?.toUpperCase() ?? 'NO_MATCH').startsWith('HOUR')
        ) {
          value = `${values[key]} hours`;
        } else if (
          (meta.units?.toUpperCase() ?? 'NO_MATCH').startsWith('IN_H2O')
        ) {
          value = `${values[key]} inches of water`;
        } else {
          if (label.split(' ').includes(meta.units ?? 'no chance to match')) {
            value = `${values[key]}`;
          } else {
            value = `${values[key]} ${meta.units}`;
          }
        }
        break;
      case 'INTEGER':
        if (['CFM', 'LPS'].includes(meta.units?.toUpperCase() ?? '')) {
          value = `${values[key]} ${values.airflowUnits}`;
        } else if (temperatureTypes.includes(meta.units?.toUpperCase() ?? '')) {
          value = `${values[key]} ${values.temperatureUnits}`;
        } else if ('PERCENT' === meta.units?.toUpperCase()) {
          value = `${values[key]}%`;
        } else if (
          (meta.units?.toUpperCase() ?? 'NO_MATCH').startsWith('MILLISECOND')
        ) {
          value = `${values[key]} ms`;
        } else if (
          (meta.units?.toUpperCase() ?? 'NO_MATCH').startsWith('SECOND')
        ) {
          value = `${values[key]} s`;
        } else if (
          (meta.units?.toUpperCase() ?? 'NO_MATCH').startsWith('METER')
        ) {
          value = `${values[key]} m`;
        } else if (
          (meta.units?.toUpperCase() ?? 'NO_MATCH').startsWith('HOUR')
        ) {
          value = `${values[key]} hours`;
        } else if (
          (meta.units?.toUpperCase() ?? 'NO_MATCH').startsWith('IN_H2O')
        ) {
          value = `${values[key]} inches of water`;
        } else {
          if (
            'NONE' === meta.units ||
            label.split(' ').includes(meta.units ?? 'no chance to match')
          ) {
            value = `${values[key]}`;
          } else {
            value = `${values[key]} ${meta.units}`;
          }
        }
        break;
      case 'NONE':
        value = `${values[key]}`;
        break;
      default:
        value = `${values[key]}`;
        break;
    }
  } else {
    label = key;
    value = 'Unknown';
  }
  return { label, value };
}

function infoForKeys(
  keys: (keyof MyAttributes)[],
  values: CurrentAttributes,
  device: Partial<ModbusAirHandler>,
): DeviceInfo {
  const res: DeviceInfo = {};
  return keys.reduce((result, key) => {
    const val = infoFromKey(key, values, device);
    result[val.label] = val.value;
    return result;
  }, res);
}

export const updateInfoBox = (
  props: ModbusAirHandlerComponentProps,
  device: MyModbusAirHandler,
  newValues?: CurrentAttributes,
): Record<string, DeviceInfo> | undefined => {
  const { pairedThermostat, preferredUnits, preferredAirFlowUnits } = props;
  const values = newValues ?? ({} as CurrentAttributes);
  if (device) {
    /* Zone Info */
    const zoneInfo: DeviceInfo = {
      Zone: device.zone?.name || 'Unnamed Zone',
      'Zone ID': device.zone?._id || 'Unidentified Zone',
      Source: device.source?.name || 'Unnamed Source',
    };

    /* Base Info */
    const baseInfo: DeviceInfo = {
      baseInfo: {
        Model:
          device.meta?.model || device.meta?.modelNumber || 'Unknown model',
        Version: device.meta?.version || 'Unknown version',
      },
      Name: device.name || 'Unnamed Modbus Air Handler',
      'Device ID': device.deviceId || 'Unknown ID',
    };

    if (pairedThermostat) {
      baseInfo['Remote Thermostat Device ID'] =
        pairedThermostat.deviceId ?? 'Unknown ID';
    }

    /* Time Info - timestamp, last setpoint change, etc. */
    const timeInfo: DeviceInfo = {
      'Last Updated': device.timestamp
        ? dayjs(device.timestamp).fromNow()
        : 'Never reported',
    };

    /* Device Type Info */
    const ventilationSettings: DeviceInfo = infoForKeys(
      [
        'damperPosition',
        'reversibleValveOnWhen',
        'recirculationModeOn',
        'airExchangeModeOn',
      ],
      values,
      device,
    );

    const ventilationStateAndReadings: DeviceInfo = infoForKeys(
      [
        'supplyFanOn',
        'supplyFanAirflow',
        'supplyFanStaticPressureH2O',
        'exhaustFanOn',
        'exhaustFanAirflow',
      ],
      values,
      device,
    );

    /* Operating State and Mode Info */
    const operatingStateInfo: DeviceInfo = infoForKeys(
      [
        'systemOnOff',
        'systemAutoMode',
        'heatPumpModeOn',
        'heatingElementOn',
        'compressorOn',
      ],
      values,
      device,
    );

    const currentTemperatureAndHumidityReadings: DeviceInfo = infoForKeys(
      [
        'indoorTemperature',
        'outsideTemperature',
        'defrostTemperature',
        'conditionedTemperature',
        'indoorRH',
      ],
      values,
      device,
    );

    const setpointAndLimits: DeviceInfo = infoForKeys(
      [
        'ahuCoolSetpoint',
        'ahuHeatSetpoint',
        'ahuMinCoolSetpointLimit',
        'ahuMaxHeatSetpointLimit',
      ],
      values,
      device,
    );

    const tenantSettings: DeviceInfo = infoForKeys(
      [
        'allowTenantOnOff',
        'enableTenantMode',
        'allowTenantChangeRHSetpoint',
        'allowTenantChangeTempSetpoints',
        'tenantModePassword',
      ],
      values,
      device,
    );

    const zmbMetrics: DeviceInfo = infoForKeys(
      [
        'zmbSystemUptime',
        'zmbTstatRequestsRecvd',
        'zmbAhuRequestsSent',
        'zmbAhuRequestsSentFailed',
        'zmbCoreRequestsRecvd',
        'zmbRequestsRecvdInvalid',
        'zmbLeakDetectorInstalled',
        'zmbCpuTemperature',
      ],
      values,
      device,
    );

    const systemMetricUnits: DeviceInfo = infoForKeys(
      [
        'airflowUnits',
        'temperatureUnits',
        'filtersServiceTime',
        'hepaServiceTime',
      ],
      values,
      device,
    );

    const terminalStates: DeviceInfo = infoForKeys(
      ['gTerminalOn', 'hTerminalOn', 'wTerminalOn', 'yTerminalOn'],
      values,
      device,
    );

    const settableValues: DeviceInfo = infoForKeys(
      [
        'ahuModbusId',
        'altitude',
        'ventilationMode',
        'freshAirSetpoint',
        'rhSetpoint',
        'winterRhAdjustEnabled',
        'showerDetectorEnabled',
        'zmbPollingFrequency',
        'zmbModbusRequestDelay',
      ],
      values,
      device,
    );

    const softwareVersion: DeviceInfo = infoForKeys(
      [
        'softwareReleaseType',
        'softwareVersion',
        'bootVersion',
        'biosVersion',
        'zmbFirmwareVersion',
      ],
      values,
      device,
    );

    const alarmStates: DeviceInfo = Object.fromEntries(
      Object.entries(
        infoForKeys(
          [
            'alarmGlobalAlarm',
            'alarmLowPressure',
            'alarmHighPressure',
            'alarmChangeFilters',
            'alarmChangeHepa',
            'alarmAirRestriction',
            'alarmSupplyFanStopped',
            'alarmExhaustFanStopped',
            'alarmRefrigerantLeak',
            'alarmCompressorFail',
            'alarmFanANegPressureOpt1',
            'alarmFanANegPressureOpt2',
            'alarmFanABReversed',
            'alarmClockBoard',
            'alarmExtendedMemory',
            'alarmB1SensorFail',
            'alarmB2SensorFail',
            'alarmB3SensorFail',
            'alarmB4SensorFail',
            'alarmB6SensorFail',

            'zmbGlobalAlarm',
            'zmbThermostatAlarm',
            'zmbAirHandlerAlarm',
            'zmbLeakDetected',
          ],
          values,
          device,
        ),
      ).map(([key, value]) => {
        const parts = key.split('Alarm - ');
        return [parts[parts.length - 1], value];
      }),
    );

    return {
      'Zone Information': zoneInfo,
      'Basic Information': baseInfo,
      'Last updated at': timeInfo,
      'Ventilation Settings': ventilationSettings,
      'Ventilation State And Readings': ventilationStateAndReadings,
      'Operating State': operatingStateInfo,
      'Current Temperature & Humidity Readings':
        currentTemperatureAndHumidityReadings,
      'Setpoints And Limits': setpointAndLimits,
      'Tenant Settings': tenantSettings,
      'Modbus Interface Metrics': zmbMetrics,
      'Terminal States': terminalStates,
      'Settable Values': settableValues,
      'System Metric Units': systemMetricUnits,
      'Software Version': softwareVersion,
      'Alarm States': alarmStates,
    };
  }
};
