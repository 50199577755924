import { Maybe, Scalars } from '../../../../types/generated-types';
import { TemperatureUnit } from '../../../system/models/temperatureUnits';
import { convertToTempUnits } from './convert-temp-units';

// Formats the selected setpoint profile with preferred heat and cool settings
export const convertToPreferredThermostatSetpointLimitUnits = (
  thermostatLimits: {
    maxCoolSetpointLimit: Maybe<Scalars['Float']>;
    minCoolSetpointLimit: Maybe<Scalars['Float']>;
    maxHeatSetpointLimit: Maybe<Scalars['Float']>;
    minHeatSetpointLimit: Maybe<Scalars['Float']>;
  },
  preferredUnits: TemperatureUnit,
) => {
  const {
    maxCoolSetpointLimit,
    minCoolSetpointLimit,
    maxHeatSetpointLimit,
    minHeatSetpointLimit,
  } = thermostatLimits;

  return {
    maxCoolSetpointLimit: convertToTempUnits(
      maxCoolSetpointLimit as Maybe<Scalars['Float']>,
      preferredUnits,
      'C',
    ),
    minCoolSetpointLimit: convertToTempUnits(
      minCoolSetpointLimit as Maybe<Scalars['Float']>,
      preferredUnits,
      'C',
    ),
    maxHeatSetpointLimit: convertToTempUnits(
      maxHeatSetpointLimit as Maybe<Scalars['Float']>,
      preferredUnits,
      'C',
    ),
    minHeatSetpointLimit: convertToTempUnits(
      minHeatSetpointLimit as Maybe<Scalars['Float']>,
      preferredUnits,
      'C',
    ),
  };
};
