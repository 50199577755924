import React from 'react';
import { BottomMenuItemGroup } from '../../system/services/injectableComponentsManager';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

export const refreshActionMenuItem = (
  client: ApolloClient<NormalizedCacheObject> | undefined,
): BottomMenuItemGroup => {
  return {
    label: 'System Actions',
    items: [
      {
        id: 'hardRefreshMenuItem',
        label: 'Refresh Current View',
        icon: <RefreshIcon fontSize="medium" />,
        action: () => {
          client?.resetStore();
        },
      },
    ],
  };
};
