import {
  SetpointProfile,
  CreateOneSetpointProfileInput,
  UpdateOneSetpointProfileInput,
  useUpdateSetpointProfileMutation,
  useCreateSetpointProfileMutation,
} from '../../../../types/generated-types';
import React, { ReactElement, useCallback, useState } from 'react';
import { FormValues } from '../types/setpointLimitProfileDetailFormValues';
import { Form, Formik, FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { SetpointLimitProfileGeneralTab } from '../components/setpoint-limit-profile-detail-general-tab';
import { SetpointLimitProfilePropertiesTab } from '../components/setpoint-limit-profile-detail-properties-tab';
import { useNavigate } from 'react-router-dom';
import { ActivityIndicator } from '../../shared/activityIndicator';
import { defaultAbsoluteLimits } from '../../helpers';
import { AuthorizedUser } from '../../../auth/models';
import { convertToTempUnits } from '../../devices/util/convert-temp-units';
import { Notifier } from '../../../system/services/notificationManager';
import {
  Celsius,
  TemperatureUnit,
} from '../../../system/models/temperatureUnits';
import { BulkUpdateStatus } from '../../shared/bulk-update-status';
import { VerifySetpointProfileUpdate } from '../../shared/verify-setpoint-profile-update';
import { Button } from '@mui/material';

const tabStyles = {
  error: {
    color: 'red',
    fontWeight: '600',
  },
  normal: {
    fontWeight: 'inherit',
  },
};
type FormVariant = 'general' | 'property' | 'unit';

export const SimpleSetpointProfileForm = ({
  profile,
  user,
  currentUnits = 'C',
  variant,
  onClose = undefined,
}: {
  profile: Partial<SetpointProfile>;
  user: AuthorizedUser;
  currentUnits?: TemperatureUnit;
  variant?: FormVariant;
  onClose?: (id?: string, refetch?: boolean) => void;
}): ReactElement => {
  const navigator = useNavigate();
  const [value, setValue] = React.useState<number>(0);
  const [propagating, setPropagating] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [verificationReady, setVerificationReady] = useState(false);

  const [preferredUnits] = useState<TemperatureUnit>(user.preferredUnits);
  const defaultLimits = defaultAbsoluteLimits[preferredUnits];

  const [updateSetpointProfile] = useUpdateSetpointProfileMutation();

  const [createSetpointProfile] = useCreateSetpointProfileMutation();

  const getActions = (variant?: FormVariant) => {
    switch (variant) {
      case 'property':
        return [
          {
            label: 'Back To Property SPLP Editor',
            handler: () => {
              setPropagating(false);
              formik.resetForm({ values: formik.values });
            },
          },
          {
            label: 'Back To Property Detail',
            handler: () => {
              console.log('[splp-detail-form][handler]');
              onClose && onClose(undefined, false);
            },
          },
        ];
      case 'unit':
        return [
          {
            label: 'Back To Unit SPLP Editor',
            handler: () => {
              setPropagating(false);
              formik.resetForm({ values: formik.values });
            },
          },
          {
            label: 'Back To Unit Detail',
            handler: () => {
              console.log('[splp-detail-form][handler]');
              onClose && onClose(undefined, false);
            },
          },
        ];
      case 'general':
      default:
        return [
          {
            label: 'Return to Setpoint Profile List',
            handler: () => navigator('/setpoint-profiles'),
          },
          {
            label: 'Return to editing',
            handler: () => {
              setPropagating(false);
              formik.resetForm({ values: formik.values });
            },
          },
        ];
    }
  };

  const initialValues: FormValues = {
    _id: profile._id ?? '',
    name: profile.name ?? '',
    maxHeatSetpointLimit:
      convertToTempUnits(profile.heat?.max, preferredUnits, currentUnits) ??
      defaultLimits.heat.max,
    minHeatSetpointLimit:
      convertToTempUnits(profile.heat?.min, preferredUnits, currentUnits) ??
      defaultLimits.heat.min,
    maxCoolSetpointLimit:
      convertToTempUnits(profile.cool?.max, preferredUnits, currentUnits) ??
      defaultLimits.cool.max,
    minCoolSetpointLimit:
      convertToTempUnits(profile.cool?.min, preferredUnits, currentUnits) ??
      defaultLimits.cool.min,
    properties: (profile.properties as string[]) ?? [],
  };

  const onSubmit = (
    values: FormValues,
    _helpers: FormikHelpers<FormValues>,
  ) => {
    if (profile._id) {
      setPropagating(true);
      setVerifying(false);
      setVerificationReady(false);
      const profileUpdateFields: UpdateOneSetpointProfileInput = {
        name: values.name,
        heat: {
          max: convertToTempUnits(
            values.maxHeatSetpointLimit,
            Celsius,
            preferredUnits,
          ),
          min: convertToTempUnits(
            values.minHeatSetpointLimit,
            Celsius,
            preferredUnits,
          ),
        },
        cool: {
          max: convertToTempUnits(
            values.maxCoolSetpointLimit,
            Celsius,
            preferredUnits,
          ),
          min: convertToTempUnits(
            values.minCoolSetpointLimit,
            Celsius,
            preferredUnits,
          ),
        },
        properties: values.properties,
      };

      updateSetpointProfile({
        variables: {
          id: profile._id,
          profile: profileUpdateFields,
        },
      })
        .then(() => {
          formik.setSubmitting(false);
          Notifier.success('Setpoint Limit Profile updated');
        })
        .catch((updateError) => {
          formik.setSubmitting(false);
          Notifier.error(updateError.message);
          setPropagating(false);
        });
    } else {
      const profileFields: CreateOneSetpointProfileInput = {
        name: values.name,
        heat: {
          max: convertToTempUnits(
            values.maxHeatSetpointLimit,
            Celsius,
            preferredUnits,
          ),
          min: convertToTempUnits(
            values.minHeatSetpointLimit,
            Celsius,
            preferredUnits,
          ),
        },
        cool: {
          max: convertToTempUnits(
            values.maxCoolSetpointLimit,
            Celsius,
            preferredUnits,
          ),
          min: convertToTempUnits(
            values.minCoolSetpointLimit,
            Celsius,
            preferredUnits,
          ),
        },
        properties: values.properties,
      };

      createSetpointProfile({
        variables: {
          profile: profileFields,
        },
      })
        .then((res) => {
          formik.setSubmitting(false);
          Notifier.success('Setpoint Limit Profile created');
          if (onClose) {
            onClose(res?.data?.createSetpointProfile?._id);
          } else {
            navigator('/setpoint-profiles');
          }
        })
        .catch((createError) => {
          formik.setSubmitting(false);
          Notifier.error(createError.message);
        });
    }
  };

  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    properties: yup.array(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleChange = useCallback(
    (changes: Partial<FormValues>) => {
      formik.setValues({ ...formik.values, ...changes });
    },
    [formik],
  );

  const conformToSetpointLimits = useCallback(
    (
      values?: FormValues,
      mode?: string,
      limit?: string,
      direction?: number,
    ) => {
      const myDefaultAbsoluteLimits = defaultAbsoluteLimits[preferredUnits];
      const myDirection = direction ?? 1;
      const myMode = mode ?? 'heat';
      // const myLimit = limit ?? 'min';

      const myValues: FormValues = values
        ? { ...values }
        : { ...formik.values };

      const deadband = preferredUnits === Celsius ? 2 : 4;

      // So, need first of all to enforce the deadband.
      // Choosing to modify cool setpoint to correct for deadband.

      // check absolute limits
      if (
        myDefaultAbsoluteLimits.heat.min + deadband >
        myDefaultAbsoluteLimits.cool.max
      ) {
        console.log(
          '[thermostat-conformToSetpointLimits] deadband impossible to honor',
        );
        return null;
      }

      if (mode === 'heat') {
        if (limit === 'max') {
          if (direction === 1) {
            if (
              myValues.maxHeatSetpointLimit > myDefaultAbsoluteLimits.heat.max
            ) {
              return null;
            }
          } else {
            if (
              myValues.maxHeatSetpointLimit < myDefaultAbsoluteLimits.heat.min
            ) {
              return null;
            }
          }
        } else if (limit === 'min') {
          if (direction === 1) {
            if (
              myValues.minHeatSetpointLimit > myDefaultAbsoluteLimits.heat.max
            ) {
              return null;
            }
          } else {
            if (
              myValues.minHeatSetpointLimit < myDefaultAbsoluteLimits.heat.min
            ) {
              return null;
            }
          }
        }
      } else if (mode === 'cool') {
        if (limit === 'max') {
          if (direction === 1) {
            if (
              myValues.maxCoolSetpointLimit > myDefaultAbsoluteLimits.cool.max
            ) {
              return null;
            }
          } else {
            if (
              myValues.maxCoolSetpointLimit < myDefaultAbsoluteLimits.cool.min
            ) {
              return null;
            }
          }
        } else if (limit === 'min') {
          if (direction === 1) {
            if (
              myValues.minCoolSetpointLimit > myDefaultAbsoluteLimits.cool.max
            ) {
              return null;
            }
          } else {
            if (
              myValues.minCoolSetpointLimit < myDefaultAbsoluteLimits.cool.min
            ) {
              return null;
            }
          }
        }
      }

      // Check absolute and internal relational values for heat limits.
      // min < a_min
      if (myValues.minHeatSetpointLimit < myDefaultAbsoluteLimits.heat.min) {
        myValues.minHeatSetpointLimit = myDefaultAbsoluteLimits.heat.min;
      }

      // max > a_max
      if (myValues.maxHeatSetpointLimit > myDefaultAbsoluteLimits.heat.max) {
        myValues.maxHeatSetpointLimit = myDefaultAbsoluteLimits.heat.max;
      }

      // min > a_max
      if (myValues.minHeatSetpointLimit > myDefaultAbsoluteLimits.heat.max) {
        myValues.minHeatSetpointLimit = myDefaultAbsoluteLimits.heat.min;
      }

      // max < a_min
      if (myValues.maxHeatSetpointLimit < myDefaultAbsoluteLimits.heat.min) {
        myValues.maxHeatSetpointLimit = myDefaultAbsoluteLimits.heat.max;
      }

      // max < min
      if (myValues.maxHeatSetpointLimit < myValues.minHeatSetpointLimit) {
        myValues.maxHeatSetpointLimit = myValues.minHeatSetpointLimit;
      }

      // Check absolute and internal relational values for cool limits.
      // min < a_min
      if (myValues.minCoolSetpointLimit < myDefaultAbsoluteLimits.cool.min) {
        myValues.minCoolSetpointLimit = myDefaultAbsoluteLimits.cool.min;
      }

      // max > a_max
      if (myValues.maxCoolSetpointLimit > myDefaultAbsoluteLimits.cool.max) {
        myValues.maxCoolSetpointLimit = myDefaultAbsoluteLimits.cool.max;
      }

      // min > a_max
      if (myValues.minCoolSetpointLimit > myDefaultAbsoluteLimits.cool.max) {
        myValues.minCoolSetpointLimit = myDefaultAbsoluteLimits.cool.min;
      }

      // max < a_min
      if (myValues.maxCoolSetpointLimit < myDefaultAbsoluteLimits.cool.min) {
        myValues.maxCoolSetpointLimit = myDefaultAbsoluteLimits.cool.max;
      }

      // max < min
      if (myValues.minCoolSetpointLimit > myValues.maxCoolSetpointLimit) {
        myValues.minCoolSetpointLimit = myValues.maxCoolSetpointLimit;
      }

      // we have to adjust the whole range as there is not enough room for the deadband.
      if (
        myValues.minHeatSetpointLimit >
        myValues.maxCoolSetpointLimit - deadband
      ) {
        // dominant movement: to the right
        if (myDirection > 0) {
          // not enough room for deadband to shove everything to the right.
          if (
            myValues.minHeatSetpointLimit + deadband >
            myDefaultAbsoluteLimits.cool.max
          ) {
            // move min heat to left of abs max cool by deadband.
            myValues.minHeatSetpointLimit =
              myDefaultAbsoluteLimits.cool.max - deadband;
            myValues.maxHeatSetpointLimit = myValues.minHeatSetpointLimit;
            myValues.maxCoolSetpointLimit = myDefaultAbsoluteLimits.cool.max;
            myValues.minCoolSetpointLimit = myDefaultAbsoluteLimits.cool.max;
          } else {
            // enough room to shove to the right.
            myValues.maxCoolSetpointLimit =
              myValues.minHeatSetpointLimit + deadband;
          }
        } else {
          // not enough room for deadband to shove everything to the left.
          if (
            myValues.maxCoolSetpointLimit - deadband <
            myDefaultAbsoluteLimits.heat.min
          ) {
            // move max cool to right of abs min heat by deadband.
            myValues.maxCoolSetpointLimit =
              myDefaultAbsoluteLimits.heat.min + deadband;
            myValues.minCoolSetpointLimit = myValues.maxCoolSetpointLimit;
            myValues.minHeatSetpointLimit = myDefaultAbsoluteLimits.heat.min;
            myValues.maxHeatSetpointLimit = myValues.minHeatSetpointLimit;
          } else {
            // enough room to shove to the left.
            myValues.minHeatSetpointLimit =
              myValues.maxCoolSetpointLimit - deadband;
          }
        }
      }
      if (
        myValues.minHeatSetpointLimit >
        myValues.minCoolSetpointLimit - deadband
      ) {
        if (myDirection > 0) {
          // need to shove min cool to right
          if (myMode === 'heat') {
            // if min cool + deadband > abs max cool (no room to shove it to the right)
            if (
              myValues.minCoolSetpointLimit + deadband >
              myDefaultAbsoluteLimits.cool.max
            ) {
              myValues.minCoolSetpointLimit = myDefaultAbsoluteLimits.cool.max;
              myValues.maxCoolSetpointLimit = myValues.minCoolSetpointLimit;
              myValues.minHeatSetpointLimit =
                myDefaultAbsoluteLimits.cool.max - deadband;
              myValues.maxHeatSetpointLimit = myValues.minHeatSetpointLimit;
            } else {
              myValues.minCoolSetpointLimit =
                myValues.minHeatSetpointLimit + deadband;
            }
          } else {
            // no room to shove min heat to left
            console.log(
              '[Thermostat Setpoint Autoadjust][Min heat at abs min] >>>>>>>>>>>>>> should not happen',
            );
            if (
              myValues.minHeatSetpointLimit - deadband <
              myDefaultAbsoluteLimits.heat.min
            ) {
              myValues.minHeatSetpointLimit = myDefaultAbsoluteLimits.heat.min;
              myValues.maxHeatSetpointLimit = myValues.minHeatSetpointLimit;
              myValues.minCoolSetpointLimit =
                myDefaultAbsoluteLimits.heat.min + deadband;
              myValues.maxCoolSetpointLimit = myValues.minCoolSetpointLimit;
            } else {
              myValues.minHeatSetpointLimit =
                myValues.minCoolSetpointLimit - deadband;
            }
          }
        } else {
          // need to shove min cool to right
          if (myMode === 'heat') {
            // if min cool + deadband > abs max cool (no room to shove it to the right)
            console.log(
              '[Thermostat Setpoint Autoadjust][Min cool at abs max] >>>>>>>>>>>>>> should not happen',
            );
            if (
              myValues.minCoolSetpointLimit + deadband >
              myDefaultAbsoluteLimits.cool.max
            ) {
              myValues.minCoolSetpointLimit = myDefaultAbsoluteLimits.cool.max;
              myValues.maxCoolSetpointLimit = myValues.minCoolSetpointLimit;
              myValues.minHeatSetpointLimit =
                myDefaultAbsoluteLimits.cool.max - deadband;
              myValues.maxHeatSetpointLimit = myValues.minHeatSetpointLimit;
            } else {
              myValues.minCoolSetpointLimit =
                myValues.minHeatSetpointLimit + deadband;
            }
          } else {
            // no room to shove min heat to left
            console.log(
              '[Thermostat Setpoint Autoadjust][Min heat at abs min] >>>>>>>>>>>>>> should not happen',
            );
            if (
              myValues.minHeatSetpointLimit - deadband <
              myDefaultAbsoluteLimits.heat.min
            ) {
              myValues.minHeatSetpointLimit = myDefaultAbsoluteLimits.heat.min;
              myValues.maxHeatSetpointLimit = myValues.minHeatSetpointLimit;
              myValues.minCoolSetpointLimit =
                myDefaultAbsoluteLimits.heat.min + deadband;
              myValues.maxCoolSetpointLimit = myValues.minCoolSetpointLimit;
            } else {
              myValues.minHeatSetpointLimit =
                myValues.minCoolSetpointLimit - deadband;
            }
          }
        }
      }

      // max heat and max cool not honoring deadband
      if (
        myValues.maxHeatSetpointLimit >
        myValues.maxCoolSetpointLimit - deadband
      ) {
        if (myDirection > 0) {
          // need to shove max cool to right
          if (myMode === 'heat') {
            // if max heat + deadband > abs max cool (no room to shove it to the right)
            if (
              myValues.maxHeatSetpointLimit + deadband >
              myDefaultAbsoluteLimits.cool.max
            ) {
              myValues.maxCoolSetpointLimit = myDefaultAbsoluteLimits.cool.max;
              myValues.minCoolSetpointLimit = myValues.maxCoolSetpointLimit;
              myValues.maxHeatSetpointLimit =
                myDefaultAbsoluteLimits.cool.max - deadband;
              myValues.minHeatSetpointLimit = myValues.maxHeatSetpointLimit;
            } else {
              myValues.maxCoolSetpointLimit =
                myValues.maxHeatSetpointLimit + deadband;
            }
          } else {
            console.log(
              '[Thermostat Setpoint Autoadjust][Max heat at abs max]>>>>>>>>>>>>>> should not happen',
            );
          }
        } else {
          // need to shove max heat to left
          if (myMode === 'heat') {
            console.log(
              '[Thermostat Setpoint Autoadjust][Max heat at abs max] should not happen',
            );
          } else {
            // no room to shove max heat to left
            if (
              myValues.maxHeatSetpointLimit - deadband <
              myDefaultAbsoluteLimits.heat.min
            ) {
              myValues.maxHeatSetpointLimit = myDefaultAbsoluteLimits.heat.min;
              myValues.minHeatSetpointLimit = myValues.maxHeatSetpointLimit;
              myValues.maxCoolSetpointLimit =
                myDefaultAbsoluteLimits.heat.min + deadband;
              myValues.minCoolSetpointLimit = myValues.maxCoolSetpointLimit;
            } else {
              myValues.maxHeatSetpointLimit =
                myValues.maxCoolSetpointLimit - deadband;
            }
          }
        }
      }
      // at this point, the min / max values are properly aligned within the
      // absolute limits and respect the deadband.

      const changes: Record<string, number | string | boolean | string[]> = {};
      [
        'maxHeatSetpointLimit',
        'minHeatSetpointLimit',
        'maxCoolSetpointLimit',
        'minCoolSetpointLimit',
      ].forEach((key) => {
        const myKey = key as keyof FormValues;
        if (formik.values[myKey] !== myValues[myKey]) {
          changes[myKey] = myValues[myKey] as number;
        }
      });

      return changes;
    },
    [formik.values, preferredUnits],
  );

  const handleSetpointLimitChange = useCallback(
    (
      key: string,
      newValue: number,
    ): Record<string, number | string | boolean | string[]> => {
      const myKey = key as keyof FormValues;
      let direction = 0;
      let mode;
      let limit;

      switch (key) {
        case 'maxCoolSetpointLimit':
          mode = 'cool';
          limit = 'max';
          break;
        case 'minCoolSetpointLimit':
          mode = 'cool';
          limit = 'min';
          break;
        case 'maxHeatSetpointLimit':
          mode = 'heat';
          limit = 'max';
          break;
        case 'minHeatSetpointLimit':
          mode = 'heat';
          limit = 'min';
          break;
        default:
          console.log(
            '[setpoint-profiles-handleSetpointLimitChange] unknown key:',
            key,
          );
          return {};
      }

      if (formik.values[myKey] === newValue) {
        return {};
      } else if (
        ((formik.values[myKey] as unknown as number) ?? 0) < newValue
      ) {
        direction = 1;
      } else {
        direction = -1;
      }

      const changes = { [key]: newValue };
      const myValues = { ...formik.values, ...changes };

      const conformingChanges = conformToSetpointLimits(
        myValues,
        mode,
        limit,
        direction,
      );

      if (conformingChanges !== null) {
        const newChanges = { ...changes, ...conformingChanges };

        handleChange(newChanges);
        return newChanges;
      } else {
        return {};
      }
    },
    [conformToSetpointLimits, formik.values, handleChange],
  );

  return user ? (
    <div style={{ padding: '6px', paddingBottom: '80px' }}>
      <h2>
        {profile._id ? 'Edit' : 'Create'} Setpoint Profile
        {formik.values.name ? `: ${formik.values.name}` : ''}
      </h2>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form
          style={{ width: '100%', fontSize: '16px' }}
          onSubmit={formik.handleSubmit}
        >
          {propagating ? (
            <div>
              <ActivityIndicator activityInProgress={formik.isSubmitting} />
              <BulkUpdateStatus
                title="Setpoint Profile Propagation Status"
                initiator={profile}
                completeCallback={() => {
                  setPropagating(false);
                }}
                actions={getActions(variant)}
              />
            </div>
          ) : (
            <div>
              {verifying ? (
                <div
                  style={{
                    marginBottom: '6px',
                    paddingBottom: '6px',
                    borderBottom: '1px solid lightgray',
                  }}
                >
                  <VerifySetpointProfileUpdate
                    values={formik.values}
                    initialValues={initialValues}
                    dataReadyCallback={setVerificationReady}
                  />
                </div>
              ) : (
                <div>
                  {!onClose ? (
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        marginBottom: '18px',
                      }}
                    >
                      <Tabs
                        value={value}
                        TabIndicatorProps={{
                          style: { color: '#000' },
                        }}
                        indicatorColor="secondary"
                        textColor="secondary"
                        onChange={(
                          event: React.SyntheticEvent,
                          newValue: number,
                        ) => {
                          if (!propagating) {
                            setValue(newValue);
                          }
                        }}
                        aria-label="View or Edit Setpoint Limit Profile"
                      >
                        <Tab
                          label="General"
                          style={
                            formik.errors.name ||
                            formik.errors.maxHeatSetpointLimit ||
                            formik.errors.minHeatSetpointLimit ||
                            formik.errors.maxCoolSetpointLimit ||
                            formik.errors.minCoolSetpointLimit
                              ? tabStyles.error
                              : tabStyles.normal
                          }
                          id="setpoint-profile-general-tab"
                        />
                        <Tab
                          label="Properties"
                          style={
                            formik.errors.properties
                              ? tabStyles.error
                              : tabStyles.normal
                          }
                          id="setpoint-profile-properties-tab"
                        />
                      </Tabs>
                    </Box>
                  ) : null}
                  {value === 0 ? (
                    <SetpointLimitProfileGeneralTab
                      formik={formik}
                      onChange={(e) => {
                        formik.setFieldTouched((e.target as any).id, true);
                        formik.handleChange(e);
                      }}
                      handleChange={handleChange}
                      handleSetpointLimitChange={handleSetpointLimitChange}
                      targetUnits={preferredUnits}
                      isSuper={user.isSuper}
                    />
                  ) : (
                    <SetpointLimitProfilePropertiesTab formik={formik} />
                  )}
                </div>
              )}
              <br />
              {verifying && verificationReady ? (
                <Button
                  disabled={!formik.isValid || !formik.dirty}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  sx={{ color: '#fff' }}
                >
                  Commit Changes
                </Button>
              ) : (
                <Button
                  disabled={!formik.isValid || !formik.dirty}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  sx={{ color: '#fff' }}
                  onClick={(e) => {
                    if (formik.values._id) {
                      e.preventDefault();
                      e.stopPropagation();
                      setVerifying(true);
                    }
                  }}
                >
                  Save
                </Button>
              )}
              <Button
                sx={{ marginLeft: '10px' }}
                variant="text"
                color={
                  !formik.isValid || !formik.dirty ? 'secondary' : 'primary'
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (verifying) {
                    setVerifying(false);
                    setVerificationReady(false);
                  } else {
                    if (onClose) {
                      onClose();
                    } else {
                      navigator('/setpoint-profiles');
                    }
                  }
                }}
              >
                Close
              </Button>
            </div>
          )}
        </Form>
      </Formik>
    </div>
  ) : (
    <div>Unauthorized</div>
  );
};
