import React, { FormEventHandler } from 'react';
import { useAuthenticator } from '../AuthenticationContext';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import { Notifier } from '../../system/services/notificationManager';

import { IAwaitParams } from '../types/resetPassword';
import { IdentifyUserPanel } from '../components/IdentifyUserPanel';

/**
 * This component represents the first stage in the 'reset password' workflow.
 * In this component, the user provides their user identifier (email address)
 * which is then sent off to the server for processing. The user is then
 * redirected to the 'AwaitResetToken' component as the next step.
 * @param onAwaitParams
 * @param initialEmail
 * @constructor
 */
export const IdentifyUser = ({
  onAwaitParams,
  initialEmail,
}: {
  initialEmail?: string;
  onAwaitParams: (awaitParams: IAwaitParams) => void;
}) => {
  const auth = useAuthenticator();
  const navigate = useNavigate();

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email: string = data.get('email') as string;
    if (email) {
      auth
        .initiatePasswordReset(email)
        .then((instructions) => {
          Notifier.success('[Password Reset] Request sent!');
          onAwaitParams({ email, instructions });
        })
        .catch((resetError: Error) => {
          Notifier.error(
            `[Password Reset] Unable to reset password: ${resetError.message}`,
          );
        });
    }
  };

  return (
    <IdentifyUserPanel
      email={initialEmail}
      submitHandler={handleSubmit}
      submitLabel="Submit Reset Request"
    >
      <Grid item>
        <Link
          sx={{ cursor: 'pointer' }}
          component={Link}
          onClick={() => {
            navigate('/login');
          }}
          variant="body2"
        >
          Return to Login
        </Link>
      </Grid>
    </IdentifyUserPanel>
  );
};
