/**
 * ViewSelector
 * - UI component used by Property component
 * - Renders drop-down view selector on small screens and tab selector on larger screens
 * - Used to select property-detail view.
 */
import React, { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import {
  IAuthorizer,
  useAuthorizer,
} from '../../../../auth/AuthorizationContext';

import { ListItemText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { ViewPropertySetpointProfile } from './property-setpoint-content';
import { PropertyZonePolicies } from './property-zone-policies';
import { PropertyAccessList } from './property-user-access-list';
import { PropertyLocationContent } from './property-location-content';

import { MyProperty } from './property-view';
import { ViewPropertyScheduleTemplate } from './view-property-schedule-template';
import { PropertyAlerts } from './property-alerts-content';

export type ViewOptionKey =
  | 'Location'
  | 'Alerts'
  | 'Setpoint Profiles'
  | 'Zone Policies'
  | 'Access List'
  | 'Schedule Template';

export interface ViewOption {
  type: ViewOptionKey;
  data?: any;
  view: (props: any) => JSX.Element | null;
  routeKey: string;
}

export const viewOptionsMap: Record<ViewOptionKey, ViewOption> = {
  Location: {
    type: 'Location',
    routeKey: 'location',
    view: PropertyLocationContent,
  },
  Alerts: {
    type: 'Alerts',
    routeKey: 'alerts',
    view: PropertyAlerts,
  },
  'Setpoint Profiles': {
    type: 'Setpoint Profiles',
    routeKey: 'setpoint-profiles',
    view: ViewPropertySetpointProfile,
  },
  'Zone Policies': {
    type: 'Zone Policies',
    routeKey: 'zone-policies',
    view: PropertyZonePolicies,
  },
  'Access List': {
    type: 'Access List',
    routeKey: 'access-list',
    view: PropertyAccessList,
  },
  'Schedule Template': {
    type: 'Schedule Template',
    routeKey: 'schedule-template',
    view: ViewPropertyScheduleTemplate,
  },
};

export const authorizedViewOptions = (
  can: IAuthorizer['can'],
  property: MyProperty,
): ViewOption[] => {
  let res: ViewOption[] = [];
  if (can('view', property)) {
    res.push(viewOptionsMap['Location']);
    res.push(viewOptionsMap['Alerts']);
  }
  if (can('manage', property)) {
    res = [
      ...res,
      // TODO: Peter: do we still need alerts here?
      // viewOptionsMap.Alerts,
      viewOptionsMap['Setpoint Profiles'],
      viewOptionsMap['Zone Policies'],
      viewOptionsMap['Access List'],
      viewOptionsMap['Schedule Template'],
    ];
  }

  return res;
};

interface PropertyViewDetailSelectorProps {
  selectedProperty?: MyProperty;
  value: ViewOption | undefined;
  changeHandler: (event?: any, newValue?: any) => void;
}

export function PropertyViewDetailSelector(
  props: PropertyViewDetailSelectorProps,
) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { value, changeHandler, selectedProperty } = props;
  const { can } = useAuthorizer();
  const [viewOptions, setViewOptions] = React.useState<ViewOption[]>([]);
  const selectHandler = (event: SelectChangeEvent<number>) => {
    const newVal = event.target.value as number;
    changeHandler(event, viewOptions[newVal].routeKey);
  };

  const startValue = value
    ? viewOptions.indexOf(value) >= 0
      ? viewOptions.indexOf(value)
      : 0
    : 0;

  useEffect(() => {
    setViewOptions(
      selectedProperty ? authorizedViewOptions(can, selectedProperty) : [],
    );
  }, [can, selectedProperty]);

  return selectedProperty && viewOptions.length ? (
    <FormControl fullWidth sx={{ padding: '0px 10px' }}>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        label="Property Menu"
        variant="standard"
        onChange={selectHandler}
        onClose={(event) => {
          const selectedIndex = (event.target as HTMLElement).getAttribute(
            'data-value',
          );
          const alreadySelected =
            (event.target as HTMLElement).getAttribute('aria-selected') ===
            'true';
          if (alreadySelected && selectedIndex === '2') {
            navigate(pathname.split('/').slice(0, -1).join('/'));
          }
        }}
        value={startValue}
      >
        {viewOptions.map((option, index) => (
          <MenuItem key={index} value={index} sx={{ padding: '6px, 16px' }}>
            <ListItemText>{option.type}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : null;
}
