import React, { useState, useEffect } from 'react';
import { PropertyBuildingDetailForm } from '../components/property-building-detail-form';
import FullWidthLoadingSkeleton from '../../shared/fullWidthLoadingSkeleton';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Building,
  Property,
  useBuildingDetailEditQuery,
  usePropertyDataForBuildingQuery,
  useRemoveBuildingMutation,
} from '../../../../types/generated-types';
import { Typography } from '@mui/material';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../../system/services/injectableComponentsManager';
import { Remove } from '@mui/icons-material';
import { Notifier } from '../../../system/services/notificationManager';
import { basePropertyMenuItems } from './base-property-context-menu';

const styles = {
  stretch: {
    height: '98%',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '20px',
  },
};

export const BuildingDetailHandler = () => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const { pathname } = currentLocation;
  const {
    subtitle,
    setContextMenuItems,
    setSubtitle,
    setTitle,
    setTitlePath,
    setSubtitlePath,
  } = useInjectableComponents();
  const { bid, id } = useParams<{ id: string; bid: string }>();
  const { data, error, loading } = useBuildingDetailEditQuery({
    variables: { bid: bid ?? '' },
  });
  useEffect(() => {
    if (error) {
      Notifier.error(
        'There was a problem fetching building information: ',
        error,
      );
    }
  }, [error]);
  const [removeBuilding] = useRemoveBuildingMutation();
  const [building, setBuilding] = useState<Partial<Building>>();
  const [property, setProperty] = useState<Partial<Property>>();
  useEffect(() => {
    if (data?.buildingById) {
      setBuilding(data.buildingById);
    }
  }, [data]);

  const {
    data: propertyData,
    error: propertyError,
    loading: propertyLoading,
  } = usePropertyDataForBuildingQuery({
    variables: { id: id ?? '' },
  });

  useEffect(() => {
    if (propertyData?.propertyById) {
      setProperty(propertyData.propertyById as Partial<Property>);
    }
  }, [propertyData]);

  useEffect(() => {
    if (building && property) {
      const handleRemoveBuilding = async () => {
        if (building?._id) {
          try {
            await removeBuilding({
              variables: {
                id: building._id,
              },
            });
            Notifier.success('Building removed successfully');
            const pathComponent = pathname.split('/').slice(0, 4);
            navigate(pathComponent.join('/'));
          } catch (e) {
            console.error(e);
          }
        } else {
          Notifier.warn('Building not found');
        }
      };

      const confirmableAction = {
        actionLabel: 'Remove',
        cancelLabel: 'Cancel',
        title: `Remove Building (${building.name ?? 'Unnamed building'})`,
        prompt: 'Are you sure? This action cannot be reversed',
        action: handleRemoveBuilding,
      };

      const bottomMenu: BottomMenuItems = [
        ...basePropertyMenuItems(property._id, pathname),
        {
          label: 'Building Actions',
          items: [
            {
              id: 'remove-building-menu-item',
              label: 'Remove Building',
              icon: <Remove fontSize="small" />,
              confirmableAction,
              permit: {
                action: 'delete',
                subject: 'Building',
              },
            },
          ],
        },
      ];

      setTitle(property.title ?? 'Untitled Property');
      setTitlePath(`/properties/${property._id}/units`);

      setSubtitle(`${building.name ?? 'Unnamed building'} - Editing Details`);
      setSubtitlePath(`/properties/${property._id}/buildings`);

      setContextMenuItems(bottomMenu);
    } else if (property) {
      setTitle(property.title ?? 'Untitled Property');
      setTitlePath(`/properties/${property._id}/units`);

      setSubtitle(`Creating Building`);
      setSubtitlePath(`/properties/${property._id}/buildings`);

      setContextMenuItems(basePropertyMenuItems(property._id, pathname));
    } else {
      setTitle('Properties');
      setTitlePath(`/properties`);

      setSubtitle(`Click to return to property list`);
      setSubtitlePath(`/properties`);

      setContextMenuItems(undefined);
    }

    return () => {
      setContextMenuItems(undefined);
    };
  }, [
    pathname,
    setContextMenuItems,
    building,
    property,
    removeBuilding,
    navigate,
  ]);

  return (
    <div style={styles.stretch}>
      <Typography
        variant="h3"
        sx={{ marginBottom: '20px', paddingRight: '10px', paddingLeft: '10px' }}
      >
        {subtitle ? subtitle : ''}
      </Typography>
      {loading ? (
        <FullWidthLoadingSkeleton />
      ) : data?.buildingById ? (
        <PropertyBuildingDetailForm
          building={data.buildingById as Partial<Building>}
        />
      ) : bid === undefined ? (
        <PropertyBuildingDetailForm building={{} as Partial<Building>} />
      ) : (
        <div>No data</div>
      )}
    </div>
  );
};
