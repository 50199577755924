import React, { useEffect } from 'react';
/* NAVIGATION */
import { useLocation, useNavigate } from 'react-router-dom';
/* MUI */
import {
  CardHeader,
  Avatar,
  IconButton,
  Typography,
  Button,
  Skeleton,
  useMediaQuery,
} from '@mui/material';
/* ICONS */
import ApartmentIcon from '@mui/icons-material/Apartment';
import EditIcon from '@mui/icons-material/Edit';
import { MyProperty } from './property-view';
import { useInjectableComponents } from '../../../../system/services/injectableComponentsManager';
import { useTheme } from '@mui/material/styles';
import { Property } from '../../../../../types/generated-types';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface PropertyViewHeaderProps {
  property: MyProperty;
  loading: boolean;
}

export function PropertyViewHeader(props: PropertyViewHeaderProps) {
  const { property, loading } = props;
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const {
    subtitle,
    navigateToSubtitlePath,
    setSubtitleActionWidget,
    subtitleActionWidget,
  } = useInjectableComponents();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (property) {
      const pathComponent = pathname.split('/').slice(0, 4);

      setSubtitleActionWidget({
        widget: (
          <IconButton
            aria-label="Edit Property Details"
            onClick={() =>
              navigate(
                `/properties/${property?._id}/${
                  pathComponent[3] === 'edit-property'
                    ? 'view-property'
                    : 'edit-property'
                }`,
              )
            }
          >
            {pathComponent[3] === 'edit-property' ? (
              <VisibilityIcon fontSize="small" />
            ) : (
              <EditIcon fontSize="small" />
            )}
          </IconButton>
        ),
        permit: {
          action: pathComponent[3] === 'edit-property' ? 'view' : 'update',
          subject: property as Property,
        },
      });

      return () => setSubtitleActionWidget(undefined);
    }
  }, [property, pathname]);

  return (
    <CardHeader
      avatar={
        <Avatar
          sx={{
            backgroundColor: 'transparent',
            fontSize: '60px',
          }}
          aria-label="Property Icon"
        >
          <ApartmentIcon color="secondary" />
        </Avatar>
      }
      action={subtitleActionWidget}
      title={
        loading ? (
          <Skeleton />
        ) : (
          <Button
            sx={{
              display: 'flex',
              justifyContent: 'start',
              textTransform: 'none',
              width: '100%',
              marginBottom: '0px',
            }}
            onClick={() =>
              navigateToSubtitlePath(`/properties/${property?._id}`)
            }
          >
            <Typography
              variant="h3"
              sx={{
                color: 'rgba(0, 0, 0, 0.87)',
              }}
              noWrap
            >
              {subtitle ?? ''}
            </Typography>
          </Button>
        )
      }
      spacing={0}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 'unset',
        padding: '4px',
        borderBottom: '1px solid darkgray',
        '& .MuiCardHeader-content': {
          maxWidth: isSmall ? 'unset' : 'calc(100vw - 150px)',
        },
      }}
    />
  );
}
