/**
 * Weekday Tabs
 * - small tabs used at the top of the schedule object
 */
import React from 'react';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export const WdTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    border: `4px solid ${theme.palette.secondary.dark}`,
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 30,
    // width: '100%',
  },
}));

interface StyledTabProps {
  label: string;
  value: any;
}
export const WdTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  padding: '2px',
  minWidth: '50px',
  '&.Mui-selected': {
    color: theme.palette.secondary.dark,
    backgroundColor: 'transparent',
    fontWeight: theme.typography.fontWeightBold,
  },
}));
