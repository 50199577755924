import React, { useEffect } from 'react';
import _ from 'lodash';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import Check from '@mui/icons-material/Check';
import BulkConfigStep from '../ui/bulk-config-step';
import { Property, UnitFilters } from '../types';

export interface SelectUnitTypesProps {
  unitTypes: string[];
  property: Property;
  unitFilters: UnitFilters;
  error?: string;
  onSelectUnitType: (unitType: string, checked: boolean) => void;
  toggleUnitTypes: (unitTypes: string[]) => void;
  onBack: () => void;
  onNext: () => void;
}

// Step for selecting specific unit types to configure
export default function SelectUnitTypes(props: SelectUnitTypesProps) {
  const {
    unitTypes,
    unitFilters,
    error,
    onSelectUnitType,
    toggleUnitTypes,
    onBack,
    onNext,
  } = props;

  function toggleAll() {
    toggleUnitTypes(unitTypes);
  }

  // Select all unit types by default
  useEffect(() => {
    if (unitFilters.selectedUnitTypes.length === 0) {
      toggleAll();
    }
    // eslint-disable-next-line
  }, []);

  const topActions = [
    { name: 'Toggle All', action: toggleAll, startIcon: <Check /> },
  ];

  const bottomActions = [
    { name: 'Back', action: onBack, startIcon: <ArrowLeft /> },
    { name: 'Next', action: onNext, endIcon: <ArrowRight /> },
  ];

  return (
    <BulkConfigStep
      title="Select Unit Types"
      topActions={topActions}
      bottomActions={bottomActions}
      error={error}
    >
      <FormControl>
        <FormGroup>
          {unitTypes.map((t) => {
            return (
              <FormControlLabel
                key={t}
                value={t}
                control={
                  <Checkbox
                    checked={unitFilters.selectedUnitTypes.includes(t)}
                    onChange={(_, v) => onSelectUnitType(t, v)}
                    name={t}
                    color="secondary"
                  />
                }
                label={_.upperFirst(_.snakeCase(t).replace('_', ' '))}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </BulkConfigStep>
  );
}
