import React from 'react';
import dayjs from 'dayjs';

/* GQL Types & Util */
import {
  useBoilerDetailQuery,
  useBoilerDetailUpdateSubscription,
  Boiler,
} from '../../../../types/generated-types';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
import {
  Fahrenheit,
  TemperatureUnit,
} from '../../../system/models/temperatureUnits';

/* Util */
import { convertToTempUnits } from '../util/convert-temp-units';

/* Shared UI */
import BorderedSection from '../../shared/borderedSection';
import { DisplayValue } from '../shared-ui';
import { DeviceInformation } from '../shared-ui/device-info';
import { PanelFrame } from '../shared-ui/panel-frame';
import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';

export function BoilerComponent({
  deviceId,
  preferredUnits = Fahrenheit,
}: {
  deviceId: string;
  preferredUnits: TemperatureUnit;
}) {
  const [device, setDevice] = React.useState<Partial<Boiler>>();
  const {
    data,
    // TODO: Loading_error: deal with loading/error for boiler details.
    // loading,
    // error
  } = useBoilerDetailQuery({
    variables: { id: deviceId },
  });
  const [deviceInfo, setDeviceInfo] = React.useState<DeviceInformation[]>([]);

  useBoilerDetailUpdateSubscription({
    variables: { ids: [deviceId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  React.useEffect(() => {
    if (data?.boilerById) {
      setDevice(data.boilerById as unknown as Partial<Boiler>);
    }
  }, [data]);

  React.useEffect(() => {
    if (device) {
      const panelInfo: DeviceInformation = {
        Panel: device.panel?.displayName ?? 'Unnamed',
        'Panel ID': device.panelId ?? 'Unidentified',
        'Panel Type': device.panel?.type ?? 'Unknown',
        Source: device.source?.name ?? 'Unnamed source',
      };

      const devicesInfo: DeviceInformation[] = [
        {
          'Device ID': device.deviceId ?? device._id ?? 'Unidentified',
          Name: device.name ?? 'Unnamed',
          Type: device.typeDisplayName ?? 'Unknown',
          'Last Updated': dayjs(device.timestamp).fromNow(),
        },
      ];

      setDeviceInfo([panelInfo, ...devicesInfo]);
    }
  }, [device]);

  /**
   * hasReadingsFor
   * Check whether 'devicemetrics' exist for a given reading or array of readings.
   * @param metricName string or array of strings to be evaluated
   * @returns boolean
   */
  const hasReadingsFor = React.useCallback(
    (metricName: string | Array<string>) => {
      const availableMetricNames = (device?.deviceMetrics ?? []).map(
        (metric) => metric?.key,
      );
      const targetMetrics = Array.isArray(metricName)
        ? metricName
        : [metricName];
      return availableMetricNames.some((n) => n && targetMetrics.includes(n));
    },
    [device],
  );

  return device && device.panel ? (
    <PanelFrame
      iconType="mdi:water-boiler"
      panel={device.panel}
      infoEntries={deviceInfo}
      editingEnabled={false}
    >
      {device.hasModules ? (
        <>
          <BoilerModule moduleName="Control Module 1">
            <BoilerReadings
              preferredUnits={preferredUnits}
              inletTemperature={device.inletTemperature1?.value ?? undefined}
              outletTemperature={device.outletTemperature1?.value ?? undefined}
              outdoorTemperature={device.outdoorTemperature?.value ?? undefined}
              firingRate={device.firingRate1?.value ?? undefined}
              flueTemperature={device.flueTemperature1?.value ?? undefined}
              boilerPumpSpeed={device.boilerPumpSpeed1?.value ?? undefined}
              hasReadingsFor={hasReadingsFor}
            />
          </BoilerModule>
          <BoilerModule moduleName="Control Module 2">
            <BoilerReadings
              preferredUnits={preferredUnits}
              inletTemperature={device.inletTemperature2?.value ?? undefined}
              outletTemperature={device.outletTemperature2?.value ?? undefined}
              // omit from module 2 outdoorTemperature={device.outdoorTemperature?.value ?? undefined}
              firingRate={device.firingRate2?.value ?? undefined}
              flueTemperature={device.flueTemperature2?.value ?? undefined}
              boilerPumpSpeed={device.boilerPumpSpeed2?.value ?? undefined}
              hasReadingsFor={hasReadingsFor}
            />
          </BoilerModule>
        </>
      ) : (
        <BoilerReadings
          preferredUnits={preferredUnits}
          inletTemperature={device.inletTemperature?.value ?? undefined}
          outletTemperature={device.outletTemperature?.value ?? undefined}
          outdoorTemperature={device.outdoorTemperature?.value ?? undefined}
          firingRate={device.firingRate?.value ?? undefined}
          flueTemperature={device.flueTemperature?.value ?? undefined}
          boilerPumpSpeed={device.boilerPumpSpeed?.value ?? undefined}
          hasReadingsFor={hasReadingsFor}
        />
      )}
    </PanelFrame>
  ) : (
    <DeviceLoadingSkeleton size="large" />
  );
}

interface BoilerModuleProps {
  moduleName: string;
  children: React.ReactNode;
}
function BoilerModule(props: BoilerModuleProps) {
  const { moduleName, children } = props;
  return (
    <BorderedSection style={{ marginTop: '10px' }} title={moduleName}>
      {children}
    </BorderedSection>
  );
}

interface BoilerReadingsProps {
  preferredUnits: TemperatureUnit;
  inletTemperature?: number;
  outletTemperature?: number;
  flueTemperature?: number;
  firingRate?: number;
  boilerPumpSpeed?: number;
  outdoorTemperature?: number;
  hasReadingsFor: (m: string | string[]) => boolean;
}
function BoilerReadings(props: BoilerReadingsProps) {
  const {
    preferredUnits,
    inletTemperature,
    outletTemperature,
    flueTemperature,
    firingRate,
    boilerPumpSpeed,
    outdoorTemperature,
    hasReadingsFor,
  } = props;
  return (
    <>
      <BorderedSection
        title="Temperature Readings"
        labelStyle={{ fontSize: '10px' }}
        ccStyle={{
          padding: '4px 4px 6px 4px',
          marginLeft: '4px',
          margin: '0px',
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
        style={{ width: '100%' }}
      >
        <BorderedSection
          title="Inlet"
          labelStyle={{ fontSize: '10px' }}
          style={{ height: '45px', flex: '1 1 auto', margin: '0px 3px' }}
          ccStyle={{ padding: '6px' }}
          visible={hasReadingsFor('inletTemperature')}
        >
          <DisplayValue
            value={convertToTempUnits(inletTemperature, preferredUnits)}
            units={preferredUnits}
            valueSize={'16px'}
            unitsSize={'12px'}
            style={{ width: '100%', textAlign: 'center' }}
          />
        </BorderedSection>
        <BorderedSection
          title="Delta"
          labelStyle={{ fontSize: '10px' }}
          ccStyle={{ padding: '6px' }}
          style={{ height: '45px', flex: '1 1 auto', margin: '0px 3px' }}
          visible={hasReadingsFor(['inletTemperature', 'outletTemperature'])}
        >
          <DisplayValue
            value={getAbsoluteDelta(
              convertToTempUnits(outletTemperature, preferredUnits) ?? 0,
              convertToTempUnits(inletTemperature, preferredUnits) ?? 0,
            )}
            units={preferredUnits}
            valueSize={'16px'}
            unitsSize={'12px'}
            blankZeros={true}
          />
        </BorderedSection>
        <BorderedSection
          title="Outlet"
          labelStyle={{ fontSize: '10px' }}
          ccStyle={{ padding: '4px', margin: '0px' }}
          style={{ height: '45px', flex: '1 1 auto', margin: '0px 3px' }}
          visible={hasReadingsFor('outletTemperature')}
        >
          <DisplayValue
            value={convertToTempUnits(outletTemperature, preferredUnits)}
            units={preferredUnits}
            valueSize={'16px'}
            unitsSize={'12px'}
          />
        </BorderedSection>
        <BorderedSection
          title="Flue"
          labelStyle={{ fontSize: '10px' }}
          ccStyle={{ padding: '6px' }}
          style={{ height: '45px', flex: '1 1 auto', margin: '0px 3px' }}
          visible={hasReadingsFor('flueTemperature')}
        >
          <DisplayValue
            value={convertToTempUnits(flueTemperature, preferredUnits)}
            units={preferredUnits}
            valueSize={'16px'}
            unitsSize={'12px'}
          />
        </BorderedSection>
        <BorderedSection
          title="Outdoor"
          labelStyle={{ fontSize: '10px' }}
          ccStyle={{ padding: '6px' }}
          style={{ height: '45px', flex: '1 1 auto', margin: '0px 3px' }}
          visible={
            hasReadingsFor('temperature') && outdoorTemperature !== undefined
          }
        >
          <DisplayValue
            value={convertToTempUnits(outdoorTemperature, preferredUnits)}
            units={preferredUnits}
            valueSize={'16px'}
            unitsSize={'12px'}
          />
        </BorderedSection>
      </BorderedSection>
      <BorderedSection
        title="Other Readings"
        labelStyle={{ fontSize: '10px' }}
        style={{ marginTop: '12px', marginBottom: '-7px' }}
        ccStyle={{
          margin: '0px',
          padding: '4px 6px 6px 6px',
          display: 'flex',
          width: '100%',
          justifyContent: 'space-evenly',
        }}
        visible={hasReadingsFor(['firingRate', 'boilerPumpSpeed'])}
      >
        <BorderedSection
          title="Firing Rate"
          labelStyle={{ fontSize: '10px' }}
          ccStyle={{ padding: '6px' }}
          style={{
            padding: '0px',
            height: '45px',
            width: 'auto',
            marginRight: '3px',
          }}
          visible={hasReadingsFor('firingRate')}
        >
          <DisplayValue
            value={firingRate}
            units={'%'}
            valueSize={'16px'}
            unitsSize={'12px'}
          />
        </BorderedSection>
        <BorderedSection
          title="Boiler Pump Speed"
          labelStyle={{ fontSize: '10px' }}
          ccStyle={{ padding: '6px' }}
          style={{
            padding: '0px',
            height: '45px',
            width: 'auto',
            marginLeft: '3px',
          }}
          visible={hasReadingsFor('boilerPumpSpeed')}
        >
          <DisplayValue
            value={boilerPumpSpeed}
            units={'%'}
            valueSize={'16px'}
            unitsSize={'12px'}
          />
        </BorderedSection>
      </BorderedSection>
    </>
  );
}

/**
 * getAbsoluteDelta - In the context of a boiler, the difference between outlet and inlet should always be a positive number.
 * A negative delta should trigger a warning, but this is not the place to do so.  Therefore we return an absolute value.
 * @param inlet
 * @param outlet
 * @returns
 */
function getAbsoluteDelta(inlet: number, outlet: number): number {
  return Math.abs(outlet - inlet);
}
