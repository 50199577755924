/**
 * ViewSelector
 * - UI component used by Property component
 * - Renders drop-down view selector on small screens and tab selector on larger screens
 * - Used to select property-detail view.
 */
import React, { useCallback, useEffect } from 'react';
import { ListItemText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation } from 'react-router-dom';

export type FormViewOptionKey =
  | 'General'
  | 'Manager'
  | 'Installer'
  | 'Resident'
  | 'Viewer';

export interface FormViewOption {
  name: FormViewOptionKey;
  data?: any;
  value: string;
}

interface BottomDetailSelectorProps {
  options: FormViewOption[];
  errors: FormViewOptionKey[];
}

export function UserDetailSelector(props: BottomDetailSelectorProps) {
  return <OptionDropdown options={props.options} errors={props.errors} />;
}

function OptionDropdown(props: BottomDetailSelectorProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { options, errors } = props;
  const [value, setValue] = React.useState<string>('general');

  useEffect(() => {
    if (pathname) {
      setValue(pathname.split('/')[4]);
    }
  }, [pathname]);

  const selectHandler = useCallback(
    (event: SelectChangeEvent) => {
      const newVal = event.target.value;

      //setValue(newVal);

      const newPath = pathname.split('/').slice(0, 4).concat(newVal).join('/');
      navigate(newPath);
    },
    [pathname, navigate],
  );

  return (
    <FormControl
      variant="standard"
      sx={{
        mx: 3,
        my: '.4rem',
        paddingLeft: '.3rem',
        border: errors.length > 0 ? '2px solid red' : undefined,
        backgroundColor: errors.length > 0 ? 'pink' : undefined,
        borderRadius: '4px',
      }}
    >
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        label="Property Menu"
        onChange={selectHandler}
        value={value}
        style={{ marginRight: '55', paddingRight: '0px' }}
        disableUnderline
      >
        {options.map((option, index) => (
          <MenuItem
            style={{
              border: errors.includes(option.name)
                ? '2px solid red'
                : undefined,
              borderRadius: '4px',
              margin: errors.includes(option.name) ? '4px' : undefined,
              backgroundColor: errors.includes(option.name)
                ? 'pink'
                : undefined,
            }}
            key={option.value}
            value={option.value}
          >
            <ListItemText>{option.name}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
