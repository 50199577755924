import * as React from 'react';

import { SxProps } from '@mui/system';
import { Card, CardContent, Theme, useTheme } from '@mui/material';

import { DataTableProps } from './data-table';

export const MobileListCard = ({
  children,
  cardSx = {},
  contentSx = {},
}: {
  children: React.ReactNode;
  cardSx?: SxProps<Theme>;
  contentSx?: SxProps<Theme>;
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        width: '100%',
        border: '1px inset ' + theme.palette.divider,
        '& .MuiCardContent-root:last-child': {
          padding: '10px !important',
        },
        ...cardSx,
      }}
    >
      <CardContent
        aria-label="unit-detail"
        sx={{
          padding: '10px',
          ...contentSx,
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};

export const MobileListDataTableProps: Partial<DataTableProps> = {
  showCellVerticalBorder: false,
  sx: {
    border: 'none',
    '& .MuiDataGrid-cell': {
      borderBottom: 'none !important',
      padding: '4px',
    },
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
};
