import React from 'react';
import { useLocation } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import UploadFileIcon from '@mui/icons-material/UploadFile';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { updateCacheFromSubscriptionEvent } from '../../../helpers/subscriptionUtils';

import {
  useIngestionsQuery,
  useIngestionUpdatesSubscription,
} from '../../../types/generated-types';

import { IngestionsTable } from './ingestions-table';
import { IngestionUpload } from './ingestion-upload';

import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../system/services/injectableComponentsManager';

export function Ingestions() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { pathname } = useLocation();
  const { setContextMenuItems } = useInjectableComponents();

  /* State */
  const [open, setOpen] = React.useState(false);

  /* Queries, Subscriptions */
  const { loading, error, data } = useIngestionsQuery({
    fetchPolicy: 'cache-first',
  });
  // Subscribe to all ingestion inserts, updates, and deletes, and update the cache accordingly
  useIngestionUpdatesSubscription({
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
    onError: (error) => console.error(error),
  });

  /* Effects: Manage Bottom Nav Menu */
  React.useEffect(() => {
    const bottomMenu: BottomMenuItems = [
      {
        items: [
          {
            id: 'menu-item-add-ingestion',
            label: 'Upload Ingestion',
            icon: <UploadFileIcon fontSize="small" />,
            action: () => handleClickOpen(),
            permit: {
              action: 'create',
              subject: 'Ingestion',
            },
          },
        ],
      },
    ];
    setContextMenuItems(bottomMenu);
    return () => {
      setContextMenuItems(undefined);
    };
  }, [pathname]);

  /* Util */
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{
        background: 'inherit',
        margin: isSmall ? '0px 4px' : '20px',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      {error && (
        <Typography color="error" sx={{ textAlign: 'center', marginBottom: 2 }}>
          Error loading ingested properties
        </Typography>
      )}
      <IngestionsTable ingestions={data?.ingestions || []} loading={loading} />
      <IngestionUploadDialog open={open} handleClose={() => handleClose()} />
    </div>
  );
}

interface IngestionUploadDialogProps {
  open: boolean;
  handleClose: () => void;
}

const IngestionUploadDialog = (props: IngestionUploadDialogProps) => {
  const { open, handleClose } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography>Upload a Property Spreadsheet</Typography>
      </DialogTitle>
      <IngestionUpload cancelDialog={handleClose} />
    </Dialog>
  );
};
