import React from 'react';

export const SMSWorkflow = () => {
  return (
    <div style={{ display: 'block', overflow: 'scroll', height: '75vh' }}>
      <h1 style={{ textAlign: 'center' }}>SMS Workflow Screenshots</h1>
      <div style={{ display: 'block', height: '100%', overflow: 'scroll' }}>
        <img height="100%" src="/Embue_Super_Alert_Management.jpg" />
        <img height="100%" src="/Embue_Super_SMS_Sign_Up.jpg" />
        <img height="100%" src="/Embue_Super_SMS_Post_Sign_Up.jpg" />
      </div>
    </div>
  );
};
