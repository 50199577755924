import React from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Form, Formik, FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { useAuthorizer } from '../../auth/AuthorizationContext';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../system/services/injectableComponentsManager';
import { useAuthenticator } from '../../auth/AuthenticationContext';
import { Notifier } from '../../system/services/notificationManager';

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  CreatePropertyMutationVariables,
  EnumPropertyContactSmsOptInStatus1,
  EnumPropertyContactSmsOptInStatus2,
  EnumPropertyContactSmsOptInStatus3,
  Property,
  UpdatePropertyMutationVariables,
  useCreatePropertyMutation,
  useRemovePropertyMutation,
  useUpdatePropertyMutation,
} from '../../../types/generated-types';

import { FormValues } from './types/propertyDetailFormValues';
import { MyProperty } from './ui/view/property-view';

import {
  Fahrenheit,
  TemperatureUnit,
} from '../../system/models/temperatureUnits';

import { PropertyGeneralTab } from './components/property-detail-general-tab';
import { ActivityIndicator } from '../shared/activityIndicator';
import { PropertyAlertsTab } from './components/property-detail-alerts-tab';
// import { SetpointProfileOverrideSelection } from '../shared/setpoint-profile-override-selection';
import { SetpointProfileOverrideSelection } from '../shared/setpoint-profile-override-selection';
import { ZonePolicyOverrideSelection } from '../shared/zone-policies-override-selection';
import { PropertyUnitDetailScheduleTemplates } from './components/property-unit-detail-schedule-templates';

import { basePropertyMenuItems } from './ui/base-property-context-menu';
import { PropertyViewHeader } from './ui/view/property-view-header';

import {
  validateEmailAddress,
  validatePhoneNumber,
} from '../../../common/helpers';

import './property-detail-form.css';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>{children}</Box>
      )}
    </div>
  );
}

export const PropertyDetailForm = (params: {
  property: Partial<Property>;
  loading?: boolean;
  viewId?: string;
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [preferredUnits, setPreferredUnits] =
    React.useState<TemperatureUnit>(Fahrenheit);
  const { property, loading = false, viewId } = params;
  const propertyExists = !!property._id;
  const auth = useAuthenticator();
  const { can } = useAuthorizer();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedSetpointProfileId, setSelectedSetpointProfileId] =
    React.useState<string>(property.setpointProfileId ?? '0');
  const [selectedZonePolicyId, setSelectedZonePolicyId] =
    React.useState<string>(property.zonePolicyId ?? '0');
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [ViewIdToValueMap, setViewIdToValueMap] = React.useState<
    Record<string, number>
  >({});
  const [ValueToViewIdMap, setValueToViewIdMap] = React.useState<
    Record<number, string>
  >({});

  const [updateProperty] = useUpdatePropertyMutation();
  const [createProperty] = useCreatePropertyMutation();
  const [removeProperty, { loading: deleteInProgress }] =
    useRemovePropertyMutation();

  const { alertViewId } = useParams<{ alertViewId: string }>();

  React.useEffect(() => {
    const myViewIdToValueMap: Record<string, number> = can(
      'manage',
      'SetpointProfile',
    )
      ? can('manage', 'ZonePolicy')
        ? {
            general: 0,
            alerts: 1,
            'setpoint-profiles': 2,
            'zone-policies': 3,
            'schedule-template': 4,
          }
        : {
            general: 0,
            alerts: 1,
            'setpoint-profiles': 2,
            'schedule-template': 3,
          }
      : can('manage', 'ZonePolicy')
        ? {
            general: 0,
            alerts: 1,
            'zone-policies': 2,
            'schedule-template': 3,
          }
        : {
            general: 0,
            alerts: 1,
            'schedule-template': 2,
          };

    const myValueToViewIdMap: Record<number, string> = can(
      'manage',
      'SetpointProfile',
    )
      ? can('manage', 'ZonePolicy')
        ? {
            0: 'general',
            1: 'alerts',
            2: 'setpoint-profiles',
            3: 'zone-policies',
            4: 'schedule-template',
          }
        : {
            0: 'general',
            1: 'alerts',
            2: 'setpoint-profiles',
            3: 'schedule-template',
          }
      : can('manage', 'ZonePolicy')
        ? {
            0: 'general',
            1: 'alerts',
            2: 'zone-policies',
            3: 'schedule-template',
          }
        : {
            0: 'general',
            1: 'alerts',
            3: 'schedule-template',
          };
    setViewIdToValueMap(myViewIdToValueMap);
    setValueToViewIdMap(myValueToViewIdMap);
  }, [can, auth?.user]);

  const {
    setContextMenuItems,
    setSubtitle,
    setTitle,
    setTitlePath,
    setSubtitlePath,
  } = useInjectableComponents();

  React.useEffect(() => {
    if (auth?.user?._id) {
      setPreferredUnits(auth?.user?.preferredUnits ?? Fahrenheit);
    }
  }, [auth]);

  const initialValues: FormValues = {
    propId: property._id ?? '',
    propTitle: property.title ?? '',
    line1: property.address?.line1 ?? '',
    line2: property.address?.line2 ?? '',
    city: property.address?.city ?? '',
    state: property.address?.state ?? '',
    postalCode: property.address?.postalCode ?? '',
    imageUrl: property.imageUrl ?? '',
    propManagerName: property.propMgr?.name ?? '',
    propManagerEmail: property.propMgr?.email ?? '',
    propManagerPhone: property.propMgr?.phone ?? '',
    propManagerMobile: property.propMgr?.mobile ?? '',
    propOwnerName: property.owner?.name ?? '',
    propOwnerEmail: property.owner?.email ?? '',
    propOwnerPhone: property.owner?.phone ?? '',
    propOwnerMobile: property.owner?.mobile ?? '',
    alertEmailOne: property.contact?.email ?? '',
    alertMobileOne: property.contact?.smsNumber ?? '',
    alertEmailTwo: property.contact?.email2 ?? '',
    alertMobileTwo: property.contact?.smsNumber2 ?? '',
    alertEmailThree: property.contact?.email3 ?? '',
    alertMobileThree: property.contact?.smsNumber3 ?? '',
    smsOptInStatus1:
      property.contact?.smsOptInStatus1 ??
      EnumPropertyContactSmsOptInStatus1.Unsent,
    smsOptInTimestamp1: property.contact?.smsOptInTimestamp1 ?? 0,
    smsOptInStatus2:
      property.contact?.smsOptInStatus2 ??
      EnumPropertyContactSmsOptInStatus2.Unsent,
    smsOptInTimestamp2: property.contact?.smsOptInTimestamp2 ?? 0,
    smsOptInStatus3:
      property.contact?.smsOptInStatus3 ??
      EnumPropertyContactSmsOptInStatus3.Unsent,
    smsOptInTimestamp3: property.contact?.smsOptInTimestamp3 ?? 0,
    unitMinTemp: property.alertLimits?.units?.temperature?.min ?? 0,
    unitMaxTemp: property.alertLimits?.units?.temperature?.max ?? 0,
    unitMaxHumidity: property.alertLimits?.units?.humidity?.max ?? 0,
    unitMinHumidity: property.alertLimits?.units?.humidity?.min ?? 0,
    plantMinDHWTemp: property.alertLimits?.plant?.dhw?.temperature?.min ?? 0,
    plantMaxDHWTemp: property.alertLimits?.plant?.dhw?.temperature?.max ?? 0,
    plantMaxLoopTemp: property.alertLimits?.plant?.loops?.temperature?.max ?? 0,
    plantMinLoopTemp: property.alertLimits?.plant?.loops?.temperature?.min ?? 0,
    plantMaxLoopPressure:
      property.alertLimits?.plant?.loops?.pressure?.max ?? 0,
    plantMinLoopPressure:
      property.alertLimits?.plant?.loops?.pressure?.min ?? 0,
  };

  React.useEffect(() => {
    if (property) {
      const initialPolicyId = property.zonePolicyId || '0';
      const initialSetpointProfileId = property.setpointProfileId || '0';
      setSelectedSetpointProfileId(initialSetpointProfileId);
      setSelectedZonePolicyId(initialPolicyId);
    }
  }, [property]);

  React.useEffect(() => {
    if (property?._id) {
      const bottomMenu: BottomMenuItems = [
        ...basePropertyMenuItems(property._id, pathname, [
          {
            id: 'delete-property-menu-item',
            label: 'Delete this property',
            icon: <DeleteIcon />,
            action: () => {
              setDeleteDialogOpen(true);
            },
            permit: {
              action: 'delete',
              subject: property as Property,
            },
          },
        ]),
      ];

      setTitle(property.title ?? 'Untitled Property');
      setTitlePath('/properties');

      setSubtitle('Editing Property');
      setSubtitlePath(`/properties/${property._id}`);

      setContextMenuItems(bottomMenu);
    } else {
      setTitle('Properties');
      setTitlePath('/properties');

      setSubtitle('Create New Property');
      setSubtitlePath('/properties');

      setContextMenuItems(undefined);
    }
    return () => {
      setTitle(undefined);
      setSubtitlePath(undefined);

      setSubtitle(undefined);
      setSubtitlePath(undefined);

      setContextMenuItems(undefined);
    };
  }, [pathname, navigate, property]);

  const onSubmit = (
    values: FormValues,
    _helpers: FormikHelpers<FormValues>,
  ) => {
    const propData:
      | CreatePropertyMutationVariables['property']
      | UpdatePropertyMutationVariables['property'] = {
      title: values.propTitle,
      address: {
        line1: values.line1,
        line2: values.line2,
        city: values.city,
        state: values.state,
        postalCode: values.postalCode,
      },
      imageUrl: values.imageUrl,
      propMgr: {
        name: values.propManagerName,
        email: values.propManagerEmail,
        phone: values.propManagerPhone,
        mobile: values.propManagerMobile,
      },
      owner: {
        name: values.propOwnerName,
        email: values.propOwnerEmail,
        phone: values.propOwnerPhone,
        mobile: values.propOwnerMobile,
      },
      contact: {
        email: values.alertEmailOne,
        email2: values.alertEmailTwo,
        email3: values.alertEmailThree,
        smsNumber: values.alertMobileOne,
        smsNumber2: values.alertMobileTwo,
        smsNumber3: values.alertMobileThree,
      },
      alertLimits: {
        units: {
          temperature: {
            min: Number(values.unitMinTemp),
            max: Number(values.unitMaxTemp),
          },
          humidity: {
            min: Number(values.unitMinHumidity),
            max: Number(values.unitMaxHumidity),
          },
        },
        plant: {
          loops: {
            temperature: {
              min: Number(values.plantMinLoopTemp),
              max: Number(values.plantMaxLoopTemp),
            },
            pressure: {
              min: Number(values.plantMinLoopPressure),
              max: Number(values.plantMaxLoopPressure),
            },
          },
          dhw: {
            temperature: {
              min: Number(values.plantMinDHWTemp),
              max: Number(values.plantMaxDHWTemp),
            },
          },
        },
      },
    };
    if (property?._id) {
      updateProperty({ variables: { id: property._id, property: propData } })
        .then(() => {
          formik.setSubmitting(false);
          Notifier.success('Property updated successfully');
          navigate('/properties');
        })
        .catch((updateError) => {
          formik.setSubmitting(false);
          Notifier.error(updateError.message);
        });
    } else {
      createProperty({ variables: { property: propData } })
        .then(() => {
          formik.setSubmitting(false);
          Notifier.success('Property created successfully');
          navigate('/properties');
        })
        .catch((createError) => {
          formik.setSubmitting(false);
          Notifier.error(createError.message);
        });
    }
  };

  const handleDeleteDialogClick = async (confirm: boolean) => {
    setDeleteDialogOpen(false);

    if (!confirm) {
      return null;
    }

    if (property && property._id) {
      await removeProperty({ variables: { id: property._id } });
      navigate('/properties');
    }
  };

  const validationSchema = yup.object({
    propTitle: yup.string().required('Property title is required'),
    line1: yup.string().required('Address is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    postalCode: yup.string().required('Postal Code is required'),
    alertEmailOne: yup
      .string()
      .test('alertEmailOne', 'Invalid email address', (value) => {
        return !value || validateEmailAddress(value);
      }),
    alertEmailTwo: yup
      .string()
      .test(
        'alertEmailTwo',
        'Invalid email address',
        (value) => !value || validateEmailAddress(value),
      ),
    alertEmailThree: yup
      .string()
      .test(
        'alertEmailThree',
        'Invalid email address',
        (value) => !value || validateEmailAddress(value),
      ),
    alertMobileOne: yup
      .string()
      .test(
        'alertMobileOne',
        'Invalid mobile number',
        (value) => !value || validatePhoneNumber(value),
      ),
    alertMobileTwo: yup
      .string()
      .test(
        'alertMobileTwo',
        'Invalid mobile number',
        (value) => !value || validatePhoneNumber(value),
      ),
    alertMobileThree: yup
      .string()
      .test(
        'alertMobileThree',
        'Invalid mobile number',
        (value) => !value || validatePhoneNumber(value),
      ),
  });

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const [value, setValue] = React.useState<number>(0);

  React.useEffect(() => {
    if (viewId) {
      const initialTabId: number = ViewIdToValueMap[viewId];
      setValue(initialTabId);
    } else {
      navigate('general', { replace: true });
    }
  }, [viewId, ViewIdToValueMap, navigate]);

  const handleTabChange = React.useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      if (formik.dirty && newValue === 2) {
        Notifier.warn(
          'Please save all other changes before editing a setpoint profile override.',
        );
      } else if (formik.dirty && newValue === 3) {
        Notifier.warn(
          'Please save all other changes before editing a zone policy override.',
        );
      } else {
        if (newValue !== value && viewId) {
          let newUrl = pathname;
          const pathParts = pathname.split('/');

          if ([5, 6].includes(pathParts.length)) {
            pathParts[4] = ValueToViewIdMap[newValue];
            if (pathParts.length === 6) {
              pathParts.pop();
            }
            newUrl = pathParts.join('/');
          }
          navigate(newUrl);
        }
      }
    },
    [ValueToViewIdMap],
  );

  const [myWidth, setMyWidth] = React.useState<number>(0);

  React.useEffect(() => {
    const handleResize = () => {
      setMyWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setMyWidth]);

  return value !== undefined ? (
    auth.user ? (
      <Card
        elevation={4}
        sx={{
          margin: isSmall ? '0px' : '20px',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'stretch',
          '& .MuiCardHeader-root': {
            padding: '4px 10px!important',
            borderBottom: '1px solid darkgray',
            borderRight: '1px solid lightgray',
            boxShadow: '0px 8px 6px -6px rgba(0, 0, 0, 0.35)',
          },
          '& .MuiCardContent-root': {
            paddingTop: '0px !important',
            borderRight: '1px solid lightgray !important',
            padding: isSmall ? '0px !important' : 'inherit',
          },
        }}
      >
        <PropertyViewHeader
          property={property as MyProperty}
          loading={loading}
        />
        <CardContent>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto',
                fontSize: '16px',
                marginRight: '-4px',
              }}
              onSubmit={formik.handleSubmit}
            >
              <div style={{ height: '100%' }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                  }}
                >
                  <Tabs
                    centered
                    TabIndicatorProps={{
                      style: { color: '#000' },
                    }}
                    indicatorColor="secondary"
                    textColor="secondary"
                    value={value}
                    onChange={handleTabChange}
                    aria-label="Property Tabs"
                    sx={{
                      minHeight: '26px',
                      marginBottom: '4px',
                      marginTop: '4px',
                      '& div[role="tabpanel"]': {
                        backgroundColor: 'green',
                      },
                      '.MuiTabs-indicator': {
                        height: '2px',
                      },
                      '.MuiTabs-flexContainer': {
                        minHeight: '26px',
                      },
                      '.MuiButtonBase-root': {
                        padding: isSmall ? '4px 10px' : '10px 10px',
                        // margin: '0px',
                        minHeight: '0px',
                        minWidth: '0px',
                        textTransform: myWidth < 775 ? 'none' : 'uppercase',
                      },
                    }}
                  >
                    <Tab label="General" {...a11yProps(0)} />
                    <Tab label="Alerts" {...a11yProps(1)} />
                    {/* Can't edit setpoint profiles or zone policies for a property that hasn't been created yet */}
                    {propertyExists && can('manage', 'SetpointProfile') && (
                      <Tab
                        label={isSmall ? 'Profile' : 'Setpoint Limit Profile'}
                        {...a11yProps(2)}
                      />
                    )}
                    {propertyExists && can('manage', 'ZonePolicy') && (
                      <Tab
                        label={isSmall ? 'Policy' : 'Zone Policy'}
                        {...a11yProps(3)}
                      />
                    )}
                    {propertyExists &&
                      can('manage', 'ThermostatScheduleTemplate') && (
                        <Tab
                          label={isSmall ? 'Schedule' : 'Schedule Template'}
                          {...a11yProps(4)}
                        />
                      )}
                  </Tabs>
                </Box>
                <ActivityIndicator activityInProgress={formik.isSubmitting} />
                <div
                  className="property-detail-tabs"
                  style={{
                    padding: '0px 6px 0px 2px',
                    // padding: `0px ${isSmall ? '8px' : '20px'} 0px ${
                    //   isSmall ? '6px' : '20px'
                    // }`,
                  }}
                >
                  <TabPanel value={value} index={0}>
                    <PropertyGeneralTab
                      formik={formik}
                      onChange={(e) => {
                        formik.setFieldTouched((e.target as any).id, true);
                        formik.handleChange(e);
                      }}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <PropertyAlertsTab
                      propertyId={property._id ?? ''}
                      currentUser={auth.user}
                      formik={formik}
                      onChange={(e) => {
                        formik.setFieldTouched((e.target as any).id, true);
                        formik.handleChange(e);
                      }}
                    />
                  </TabPanel>
                  {can('manage', 'SetpointProfile') ? (
                    <TabPanel value={value} index={2}>
                      <SetpointProfileOverrideSelection
                        returnLabel="Return to Property List"
                        targetLevel="property"
                        returnPath={`/properties`}
                        propertyId={property._id}
                        targetProfileId={property.setpointProfileId ?? '0'}
                        value={selectedSetpointProfileId}
                        onSetpointProfileSelected={(id: string) => {
                          setSelectedSetpointProfileId(id);
                        }}
                        preferredUnits={preferredUnits}
                      />
                    </TabPanel>
                  ) : null}
                  {can('manage', 'ZonePolicy') ? (
                    <TabPanel
                      value={value}
                      index={can('manage', 'SetpointProfile') ? 3 : 2}
                    >
                      <ZonePolicyOverrideSelection
                        returnLabel="Return to Property List"
                        returnPath={`/properties`}
                        propertyId={property._id}
                        targetPolicyId={property.zonePolicyId ?? '0'}
                        availablePolicies={
                          property.assignableZonePolicies ?? []
                        }
                        value={selectedZonePolicyId}
                        onZonePolicySelected={(id: string) => {
                          setSelectedZonePolicyId(id);
                        }}
                      />
                    </TabPanel>
                  ) : null}
                  {can('manage', 'ThermostatScheduleTemplate') ? (
                    <TabPanel
                      value={value}
                      index={
                        can('manage', 'SetpointProfile')
                          ? can('manage', 'ZonePolicy')
                            ? 4
                            : 3
                          : can('manage', 'ZonePolicy')
                            ? 3
                            : 2
                      }
                    >
                      <PropertyUnitDetailScheduleTemplates targetLevel="property" />
                    </TabPanel>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  marginTop: '10px',
                  marginLeft: '10px',
                  // paddingBottom: '75px',
                  paddingLeft: '24px',
                  // height: '70px',
                }}
              >
                {value < 2 ? (
                  value !== 1 || alertViewId === 'subscribers' ? (
                    <Button
                      disabled={!formik.isValid || !formik.dirty}
                      type="submit"
                      variant="contained"
                      color="secondary"
                      sx={{ color: '#fff', marginRight: '10px' }}
                    >
                      Save
                    </Button>
                  ) : null
                ) : null}
                <Button
                  sx={{ marginLeft: '10px' }}
                  variant="text"
                  onClick={() => navigate(-1)}
                  color={
                    !formik.isValid || !formik.dirty ? 'secondary' : 'primary'
                  }
                >
                  {value < 2 && formik.dirty ? 'Cancel' : 'Return'}
                </Button>
              </div>
            </Form>
          </Formik>
          <ConfirmDeleteDialog
            handleClick={handleDeleteDialogClick}
            isOpen={deleteDialogOpen}
          />
          <SpinnerDialog isOpen={deleteInProgress} />
        </CardContent>
      </Card>
    ) : (
      <Typography variant="h2">Unauthorized</Typography>
    )
  ) : (
    <></>
  );
};

interface ConfirmDeleteDialogProps {
  isOpen: boolean;
  handleClick: (value: boolean) => void;
}

const ConfirmDeleteDialog = (props: ConfirmDeleteDialogProps) => {
  const { isOpen, handleClick } = props;
  return (
    <Dialog onClose={() => handleClick(false)} open={isOpen}>
      <Box sx={{ p: 4 }}>
        <Typography variant="h2">Delete Property?</Typography>
        <Typography variant="h4">
          Warning: This action will completely remove this property and all
          related records. Press OK to confirm.
        </Typography>
        <DialogActions>
          <Button autoFocus onClick={() => handleClick(false)}>
            Cancel
          </Button>
          <Button onClick={() => handleClick(true)}>Ok</Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

const SpinnerDialog = (props: { isOpen: boolean }) => {
  const { isOpen } = props;
  return (
    <Dialog open={isOpen}>
      <Box sx={{ p: 4 }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h4">
            Removing Property, please wait ...
          </Typography>
          <CircularProgress />
        </Stack>
      </Box>
    </Dialog>
  );
};
