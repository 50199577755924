/**
 * Application Environment Configuration
 * Set and export constants serverUrl and wsUrl depending on the environment,
 * 'production', 'testbed', and 'local'.  For local builds, check the existence of
 * environment variables first - they will vary based on developer preference - before
 * assigning defaults.
 */

import { Capacitor } from '@capacitor/core';
import { envConfig } from '../plugins/env-config';

// Scheme & Flavor currently not needed in order to configure API and WS server URLs.
// type Flavor = 'Super' | 'Resident';
// type Scheme = Flavor;
type Platform = 'web' | 'android' | 'ios';
type Env = 'local' | 'testbed' | 'production';
type BuildType = 'debug' | 'staging' | 'release';
enum mobileBuildTypes {
  debug = 0,
  staging = 1,
  release = 2,
}
enum webBuildTypes {
  local = 0,
  testbed = 1,
  production = 2,
}

const ENVIRONMENT = (process.env.REACT_APP_ENVIRONMENT as Env) || 'local';
const PLATFORM = Capacitor.getPlatform() as Platform;
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

/**
 * Default settings for backend servers in case no environment variables present.
 * For production super.embue.com resolves to a dedicated server, not the NAT
 * so use data-viewer, which resolves to the NAT server. Need to straighten all
 * this out for super2022 development and eventual cut-over strategy
 */

/**
 * Root backend server URL & Root websocket server URL (for graphql subscriptions)
 * For development store the hostname value for REACT_APP_API_HOSTNAME in `.env` to allow for flexible build.
 * Production builds will use the static defaults defined above.
 **/

const hostMap = {
  0: {
    httpScheme: 'http',
    wsScheme: 'ws',
    hostname: HOSTNAME
      ? HOSTNAME
      : PLATFORM === 'android'
        ? '10.0.2.2'
        : 'localhost',
    port: 8000,
  },
  1: {
    httpScheme: 'https',
    wsScheme: 'wss',
    hostname: 'super.test.embue.com',
    port: 443,
  },
  2: {
    httpScheme: 'https',
    wsScheme: 'wss',
    // previously:
    // hostname: 'data-viewer.embue.com',
    // currently either:
    // hostname: 'super.app.embue.com'
    // or:
    hostname: 'super.prod.embue.com',
    port: 443,
  },
};

export interface CommunicationAddresses {
  serverUrl: string;
  wsUrl: string;
}

export interface ServerConfig {
  getServerConnection: () => CommunicationAddresses;
}

export const serverConfig = new Promise<ServerConfig>((resolve, reject) => {
  switch (PLATFORM) {
    case 'web':
      {
        const { httpScheme, wsScheme, hostname, port } =
          hostMap[webBuildTypes[ENVIRONMENT]];

        resolve({
          getServerConnection() {
            return {
              serverUrl: `${httpScheme}://${hostname}:${port}`,
              wsUrl: `${wsScheme}://${hostname}:${port}`,
            };
          },
        });
      }
      break;
    case 'android':
    case 'ios':
      (async () => {
        try {
          const { buildType } = (await envConfig.getBuildVariant()) as {
            buildType: BuildType;
          };

          const { httpScheme, wsScheme, hostname, port } =
            hostMap[mobileBuildTypes[buildType]];

          resolve({
            getServerConnection() {
              return {
                serverUrl: `${httpScheme}://${hostname}:${port}`,
                wsUrl: `${wsScheme}://${hostname}:${port}`,
              };
            },
          });
        } catch (e) {
          console.log(`[config - mobile] ${PLATFORM} ERROR`, e);
        }
      })();
      break;
    default:
      console.log('[config - default mobile] WARNING - unknown build type');
      break;
  }
});

// Server-side, GraphQL endpoint selector
export const SERVER_GQL_URI_SELECTOR =
  process.env.SERVER_GQL_SUBSCRIPTIONS_URI_SELECTOR || 'graphql';

// Server-side, GraphQL Subscriptions endpoint selector
export const SERVER_GQL_SUBSCRIPTIONS_URI_SELECTOR =
  process.env.SERVER_GQL_SUBSCRIPTIONS_URI_SELECTOR || 'subscriptions';

// Authentication settings
export const AUTH_REMEMBER_ME_TOKEN_NAME = 'remember_me';
export const AUTH_ID_TOKEN_NAME = 'pwl_token';
export const LOCAL_AUTH_USER_KEY = 'authUser';
export const AUTH_STATE_TOKEN_NAME = 'auth_state_token';
export const AUTH_SESSION_TOKEN_NAME = 'auth_session';
export const AUTH_SESSION_EXPIRATION_TOKEN_NAME = 'auth_session_expires_at';
export const AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME = 'reset-password-email';
export const AUTH_IDENTIFY_USER_TOKEN_NAME = 'auth-user-email';
export const AUTH_PENDING_LOGOUT_KEY = 'auth_pending_logout';
export const AUTH_SESSION_DURATION_MINUTES = 1440;
export const AUTH_SESSION_WARNING_MINUTES = 5;
export const RECONNECT_TIMER_OVERRIDE_ID_KEY = 'reconnect_timer_override_id';
export const RECONNECT_TIMER_ID_KEY = 'reconnect_timer_id';
export const LAST_RECONNECT_TIMER_DURATION_KEY = 'reconnect_timer_duration';
export const INITIAL_RECONNECT_TIMER_DURATION_MILLIS = 16000; // 15 seconds
export const MAXIMUM_RECONNECT_TIMER_DURATION_MILLIS = 30 * 60 * 1000; // 30 minutes
export const SERVER_CONNECTION_INITIALIZED_KEY =
  'server_connection_initialized';
export const CONNECTION_STATE_TRANSITIONING_KEY = 'connection_state_changing';
export const LOGGING_OUT_KEY = 'logging_out_state_changing';
export const CONNECTION_STATE_TRANSITIONING_DELAY = 5000;
export const LOGGING_OUT_TRANSITIONING_DELAY = 5000;
export const LOGGING_LEVEL = 4; // (4 - METAL, 3 - DEBUG, 2 - INFO , 1 - WARNING, 0 - ERROR)
export const SERVER_TYPE = process.env.SERVER_TYPE || 'combined';
