/**
 * ScheduleItem
 * - displays a ListItem populated with timestamp and schedule settings
 * - consumes the TimePicker and EditSettings components
 */

import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { TimePicker } from './time-picker';
import { EditSettings } from './edit-settings';
import { MyScheduleSetting, TimeOfDay } from '../../types';
import { defaultAbsoluteLimits } from '../../../../helpers';
import {
  Celsius,
  TemperatureUnit,
} from '../../../../../system/models/temperatureUnits';
import { SetpointLimitSettings } from '../../../../units/types/setpointLimitSettings';
import { Typography } from '@mui/material';
import { useAuthenticator } from '../../../../../auth/AuthenticationContext';

interface ScheduleItemProps {
  time?: TimeOfDay;
  setting?: MyScheduleSetting;
  setpointLimitSettings: SetpointLimitSettings; // TODO: Calvin ask Peter if all thermostats contain max, min setpoint limit values
  preferredUnits: TemperatureUnit;
  handleEdit: (
    setting: MyScheduleSetting,
  ) => (oldTime: TimeOfDay, newTime?: TimeOfDay) => void;
}

export function ScheduleItem(props: ScheduleItemProps) {
  const { time, setting, handleEdit, preferredUnits, setpointLimitSettings } =
    props;

  const { user } = useAuthenticator();

  const [newHeatValue, setHeatValue] = useState(
    setting?.minimum ??
      defaultAbsoluteLimits[user?.preferredUnits ?? 'F'].heat.max,
  );
  const [newCoolValue, setCoolValue] = useState(
    setting?.maximum ??
      defaultAbsoluteLimits[user?.preferredUnits ?? 'F'].cool.max,
  );

  const [anchorEl, setAnchorEl] = useState<
    Record<string, HTMLButtonElement | null>
  >({ heating: null, cooling: null });

  const theme = useTheme();

  const open = (mode: string) => Boolean(anchorEl[mode]);
  const id = (mode: string) => (open(mode) ? 'simple-popover' : undefined);

  // When setting is updated with "Revert changes" method
  // TODO: Calvin investigate this useEffect for Revert Changes method
  // Capture this change and update the min and max values
  // useEffect(() => {
  //   if (setting?.maximum && setting?.minimum) {
  //     console.log('SETTING HEAT VALUE USE EFFECT');
  //     setHeatValue(setting.minimum);
  //     setCoolValue(setting.maximum);
  //   }
  // }, [setting]);

  // useEffect(() => {
  //   console.log('SETTING: ', setting);
  //   if (setting?.minimum) {
  //     console.log('SETTING MIN HEAT: ', setting.minimum);
  //     setHeatValue(setting.minimum);
  //   }
  //   if (setting?.maximum) {
  //     console.log('SETTING MAX COOL: ', setting.maximum);
  //     setCoolValue(setting.maximum);
  //   }
  // }, [setting]);

  const handleClick = (
    mode: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl({ [mode]: event.currentTarget });
  };

  const handleClose = (mode: string, newSetting: MyScheduleSetting) => {
    setAnchorEl({ [mode]: null });
    if (time) {
      handleEdit({ ...setting, ...newSetting })(time);
    }
  };

  const handleDeadBandAdjustedTemperature = (
    leftValue: number,
    rightValue: number,
    direction?: number,
  ) => {
    let deadband = preferredUnits === Celsius ? 2 : 4;
    let adjustedValue: number = rightValue;
    const myDirection = direction ?? 1;
    if (Math.abs(leftValue - rightValue) < deadband) {
      if (myDirection > 0) {
        adjustedValue = rightValue + (leftValue - rightValue + deadband);
      } else {
        const rightAndLeftDiff = rightValue - leftValue;
        adjustedValue = rightValue - (rightAndLeftDiff + deadband);
      }
      return adjustedValue;
    }

    return adjustedValue;
  };

  const handleChange = (mode: string, value: number) => {
    let direction = 0;

    switch (mode) {
      case 'heating':
        if (newHeatValue === value) {
          return {};
        } else if (newHeatValue < value) {
          direction = 1;
        } else {
          direction = -1;
        }
        const adjustedDeadBandCoolValue = handleDeadBandAdjustedTemperature(
          value,
          newCoolValue,
          direction,
        );
        if (setpointLimitSettings.maxCoolSetpointLimit) {
          if (
            adjustedDeadBandCoolValue <=
            setpointLimitSettings.maxCoolSetpointLimit
          ) {
            setHeatValue(value);
            if (newHeatValue !== adjustedDeadBandCoolValue) {
              setCoolValue(adjustedDeadBandCoolValue);
            }
          }
        } else {
          setHeatValue(value);
          setCoolValue(adjustedDeadBandCoolValue);
        }
        break;
      case 'cooling':
        if (newCoolValue === value) {
          return {};
        } else if (newCoolValue < value) {
          direction = 1;
        } else {
          direction = -1;
        }
        const adjustedDeadBandHeatValue = handleDeadBandAdjustedTemperature(
          value,
          newHeatValue,
          direction,
        );
        if (setpointLimitSettings.maxHeatSetpointLimit) {
          if (
            adjustedDeadBandHeatValue <=
            setpointLimitSettings.maxHeatSetpointLimit
          ) {
            setCoolValue(value);
            if (newCoolValue !== adjustedDeadBandHeatValue) {
              setHeatValue(adjustedDeadBandHeatValue);
            }
          }
        } else {
          setCoolValue(value);
          setHeatValue(adjustedDeadBandHeatValue);
        }
        break;
      default:
    }
  };

  return (
    (time && (
      <>
        <ListItem
          key={time}
          sx={{
            pl: 1,
            justifyContent: 'space-evenly',
          }}
        >
          {/* <Material Time Picker - TODO: Calvin: Need alternate IOS style version */}
          <TimePicker
            time={time}
            // handleEdit={handleEdit({
            //   maximum: newCoolValue,
            //   minimum: newHeatValue,
            // })}
            handleEdit={handleEdit({
              ...setting,
            })}
          />
          <EditSettings
            id={id('heating')}
            mode={'heating'}
            handleChange={handleChange}
            maxHeatSetpointLimit={
              setpointLimitSettings?.maxHeatSetpointLimit ??
              defaultAbsoluteLimits['C'].heat.max
            }
            minHeatSetpointLimit={
              setpointLimitSettings?.minHeatSetpointLimit ??
              defaultAbsoluteLimits['C'].heat.min
            }
            value={newHeatValue}
            open={open('heating')}
            anchorEl={anchorEl.heating}
            handleClick={handleClick}
            handleClose={handleClose}
            theme={theme}
          />
          <EditSettings
            id={id('cooling')}
            mode={'cooling'}
            handleChange={handleChange}
            maxCoolSetpointLimit={
              setpointLimitSettings?.maxCoolSetpointLimit ??
              defaultAbsoluteLimits['C'].cool.max
            }
            minCoolSetpointLimit={
              setpointLimitSettings?.minCoolSetpointLimit ??
              defaultAbsoluteLimits['C'].cool.min
            }
            value={newCoolValue}
            open={open('cooling')}
            anchorEl={anchorEl.cooling}
            handleClick={handleClick}
            handleClose={handleClose}
            theme={theme}
          />
        </ListItem>
        <Divider />
      </>
    )) || (
      <ListItem key={'SCHEDULE_ERROR'}>
        <ListItemText primary={'Error - Invalid Timestamp'} />
      </ListItem>
    )
  );
}
