import React from 'react';

import { useFormik } from 'formik';

import { Notifier } from '../../../../system/services/notificationManager';

import {
  Button,
  InputProps as StandardInputProps,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';

import {
  AlertSubscription,
  useUpdateEmailAuthorizationMutation,
  useUpdateSmsAuthorizationMutation,
} from '../../../../../types/generated-types';

import { FormValues } from '../../types/userProfileFormValues';
import BorderedSection from '../../../shared/borderedSection';
import { AlertSmsConsentDialog } from './alert-sms-consent-dialog';
import { formatPhoneNumber } from '../../../../../common/helpers';
import { SmsOptInStatus } from '../../../../../common/types';
import { AlertOptOutConfirmationDialog } from './alert-opt-out-confirmationt-dialog';

interface AlertDeliveryMethodsProps {
  userId: string;
  formik: ReturnType<typeof useFormik<FormValues>>;
  alertSubscriptions: Partial<AlertSubscription>[];
  onChange: StandardInputProps['onChange'];
}

export const AlertDeliveryMethods = (props: AlertDeliveryMethodsProps) => {
  const { userId, formik, alertSubscriptions, onChange } = props;

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [smsDialogOpen, setSmsDialogOpen] = React.useState(false);
  const [openOptOutConfirmationDialog, setOpenOptOutConfirmationDialog] =
    React.useState<boolean>(false);
  const [optOutConfirmationMessage, setOptOutConfirmationMessage] =
    React.useState<string>('');
  const [optOutConfirmationMode, setOptOutConfirmationMode] =
    React.useState<string>('');

  const [updateEmailAuthorization] = useUpdateEmailAuthorizationMutation();
  const [updateSmsAuthorization] = useUpdateSmsAuthorizationMutation();

  const handleEmailOptOut = async () => {
    if (alertSubscriptions.some((subscription) => subscription?.viaEmail)) {
      setOptOutConfirmationMessage(
        'You are currently subscribed to email alerts. If you proceed, those subscriptions will be cancelled. Are you sure you want to opt out?',
      );
      setOptOutConfirmationMode('email');
      setOpenOptOutConfirmationDialog(true);
    } else {
      await updateEmailAuthorization({
        variables: {
          userId: userId,
          isAuthorized: false,
        },
      });
    }
  };

  const handleEmailConsent = async () => {
    await updateEmailAuthorization({
      variables: {
        userId: userId,
        isAuthorized: true,
      },
    });
    // setSmsDialogOpen(false);
  };

  const handleSmsOptOut = async () => {
    if (alertSubscriptions.some((subscription) => subscription?.viaSms)) {
      setOptOutConfirmationMessage(
        'You are currently subscribed to SMS alerts. If you proceed, those subscriptions will be cancelled. Are you sure you want to opt out?',
      );
      setOptOutConfirmationMode('sms');
      setOpenOptOutConfirmationDialog(true);
    } else {
      await updateSmsAuthorization({
        variables: {
          userId: userId,
          smsMobile: formik.values.smsMobile,
          isAuthorized: false,
        },
      });
    }
  };
  const handleSmsConsent = async () => {
    try {
      await updateSmsAuthorization({
        variables: {
          userId: userId,
          smsMobile: formik.values.smsMobile,
          isAuthorized: formik.values.smsOptInConsent,
        },
      });
      setSmsDialogOpen(false);
    } catch (e) {
      Notifier.warn((e as Error).message);
    }
  };

  const CollapsedDeliveryMethods = () => {
    const smsSubscribed = props.formik.values.smsOptInStatus === 'subscribed';
    const smsPending = props.formik.values.smsOptInStatus === 'pending';
    const emailSubscribed =
      props.formik.values.emailOptInStatus === 'subscribed';

    return (
      <div>
        {emailSubscribed
          ? 'Email: ' + formik.values.email
          : 'Email not enabled'}
        <br />
        {smsSubscribed ? 'SMS: ' : smsPending ? 'Awaiting opt-in from ' : ''}
        {smsSubscribed || smsPending
          ? formatPhoneNumber(formik.values.mobile)
          : 'SMS not enabled'}
      </div>
    );
  };

  return (
    <>
      <BorderedSection
        title="How your alerts are delivered?"
        collapsible
        initiallyExpanded={
          props.formik.values.emailOptInStatus !== 'subscribed' &&
          props.formik.values.smsOptInStatus !== 'subscribed'
        }
        collapsedIcon={<EditIcon style={{ fontSize: '20px' }} />}
        collapsedWidget={<CollapsedDeliveryMethods />}
      >
        <BorderedSection
          title="Via Email"
          style={{ marginTop: '4px', marginBottom: '12px' }}
        >
          {props.formik.values.emailOptInStatus !== 'subscribed' ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button
                style={
                  isSmall
                    ? {
                        alignSelf: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#3be73b',
                        color: 'black',
                        flex: '1 1 auto',
                        marginTop: '0px',
                        marginBottom: '0px',
                        width: '100%',
                        border: '1px solid #159015',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                      }
                    : {
                        alignSelf: 'center',
                        justifySelf: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#3be73b',
                        color: 'black',
                        border: '1px solid #159015',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                        marginLeft: '8px',
                        maxWidth: '450px',
                        marginTop: '0px',
                      }
                }
                variant="contained"
                color="primary"
                onClick={handleEmailConsent}
              >
                Click to receive alerts at {props.formik.values.email}
              </Button>
            </div>
          ) : (
            <div style={{ display: 'flex', width: '100%' }}>
              <div
                style={{
                  marginRight: '8px',
                  display: 'flex',
                  flex: '1 0 auto',
                }}
              >
                {isSmall ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignSelf: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        flex: '1 0 100',
                        textAlign: 'center',
                      }}
                    >
                      You receive Email alerts at
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignSelf: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        flex: '1 0 100',
                        textAlign: 'center',
                      }}
                    >
                      {props.formik.values.email}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flex: '1 0 auto',
                      }}
                    >
                      <Button
                        style={
                          isSmall
                            ? {
                                alignSelf: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: 'red',
                                color: 'black',
                                flex: '1 1 auto',
                                marginTop: '10px',
                                marginBottom: '0px',
                                width: '100%',
                                border: '1px solid #159015',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                                padding: '4px',
                              }
                            : {
                                alignSelf: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: 'red',
                                color: 'black',
                                border: '1px solid #159015',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                                marginLeft: '8px',
                                maxWidth: '450px',
                                marginTop: '0px',
                              }
                        }
                        onClick={handleEmailOptOut}
                      >
                        Click to Opt Out
                      </Button>
                    </div>
                  </div>
                ) : (
                  `You receive Email alerts at ${props.formik.values.email}`
                )}
              </div>
              {!isSmall ? (
                <div style={{ display: 'flex', flex: '0 0 auto' }}>
                  <Button
                    style={{
                      alignSelf: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: 'red',
                      color: 'black',
                      border: '1px solid #159015',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                      marginLeft: '8px',
                      maxWidth: '450px',
                      marginTop: '0px',
                    }}
                    onClick={handleEmailOptOut}
                  >
                    Click to Opt Out
                  </Button>
                </div>
              ) : null}
            </div>
          )}
        </BorderedSection>

        <BorderedSection title="Via SMS" style={{ marginTop: '6px' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
            }}
          >
            {[
              SmsOptInStatus.unsent,
              SmsOptInStatus.rejected,
              SmsOptInStatus.opted_out,
              SmsOptInStatus.removed,
              SmsOptInStatus.stopped,
            ].includes(props.formik.values.smsOptInStatus as SmsOptInStatus) ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Button
                  style={
                    isSmall
                      ? {
                          alignSelf: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          backgroundColor: '#3be73b',
                          color: 'black',
                          flex: '1 1 auto',
                          marginTop: '0px',
                          marginBottom: '0px',
                          width: '100%',
                          border: '1px solid #159015',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                        }
                      : {
                          alignSelf: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          backgroundColor: '#3be73b',
                          color: 'black',
                          border: '1px solid #159015',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                          marginLeft: '8px',
                          maxWidth: '450px',
                          marginTop: '0px',
                        }
                  }
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSmsDialogOpen(true);
                  }}
                >
                  Click to{' '}
                  {props.formik.values.smsOptInStatus === 'unsent'
                    ? 'Enable'
                    : 'Re-enable'}
                  {props.formik.values.smsMobile
                    ? isSmall
                      ? ''
                      : ` receipt at ${formatPhoneNumber(
                          props.formik.values.smsMobile,
                        )}`
                    : isSmall
                      ? ''
                      : ' and enter your mobile number'}
                </Button>
              </div>
            ) : [SmsOptInStatus.pending].includes(
                props.formik.values.smsOptInStatus as SmsOptInStatus,
              ) ? (
              <div style={{ display: 'flex', width: '100%' }}>
                <div
                  style={{
                    marginRight: '8px',
                    display: 'flex',
                    flex: '1 0 auto',
                  }}
                >
                  {isSmall ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignSelf: 'center',
                          alignItems: 'center',
                          alignContent: 'center',
                          flex: '1 0 100',
                          textAlign: 'center',
                        }}
                      >
                        Awaiting opt-in response
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignSelf: 'center',
                          alignItems: 'center',
                          alignContent: 'center',
                          flex: '1 0 100',
                          textAlign: 'center',
                        }}
                      >
                        from {props.formik.values.mobile}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          flex: '1 0 auto',
                        }}
                      >
                        <Button
                          style={
                            isSmall
                              ? {
                                  alignSelf: 'center',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  backgroundColor: 'red',
                                  color: 'black',
                                  flex: '1 1 auto',
                                  marginTop: '10px',
                                  marginBottom: '0px',
                                  width: '100%',
                                  border: '1px solid #159015',
                                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                                  padding: '4px',
                                }
                              : {
                                  alignSelf: 'center',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  backgroundColor: 'red',
                                  color: 'black',
                                  border: '1px solid #159015',
                                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                                  marginLeft: '8px',
                                  maxWidth: '450px',
                                  marginTop: '0px',
                                }
                          }
                          onClick={handleSmsOptOut}
                        >
                          Click to cancel
                        </Button>
                      </div>
                    </div>
                  ) : (
                    `Awaiting opt-in response from ${props.formik.values.mobile}`
                  )}
                </div>
                {!isSmall ? (
                  <div style={{ display: 'flex', flex: '0 0 auto' }}>
                    <Button
                      style={{
                        alignSelf: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: 'red',
                        color: 'black',
                        border: '1px solid #159015',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                        marginLeft: '8px',
                        maxWidth: '450px',
                        marginTop: '0px',
                      }}
                      onClick={handleSmsOptOut}
                    >
                      Click to cancel
                    </Button>
                  </div>
                ) : null}
              </div>
            ) : (
              <div style={{ display: 'flex', width: '100%' }}>
                <div
                  style={{
                    marginRight: '8px',
                    display: 'flex',
                    flex: '1 0 auto',
                  }}
                >
                  {isSmall ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignSelf: 'center',
                          alignItems: 'center',
                          alignContent: 'center',
                          flex: '1 0 100',
                          textAlign: 'center',
                        }}
                      >
                        You receive SMS alerts
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignSelf: 'center',
                          alignItems: 'center',
                          alignContent: 'center',
                          flex: '1 0 100',
                          textAlign: 'center',
                        }}
                      >
                        at {props.formik.values.mobile}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          flex: '1 0 auto',
                        }}
                      >
                        <Button
                          style={
                            isSmall
                              ? {
                                  alignSelf: 'center',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  backgroundColor: 'red',
                                  color: 'black',
                                  flex: '1 1 auto',
                                  marginTop: '10px',
                                  marginBottom: '0px',
                                  width: '100%',
                                  border: '1px solid #159015',
                                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                                  padding: '4px',
                                }
                              : {
                                  alignSelf: 'center',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  backgroundColor: 'red',
                                  color: 'black',
                                  border: '1px solid #159015',
                                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                                  marginLeft: '8px',
                                  maxWidth: '450px',
                                  marginTop: '0px',
                                }
                          }
                          onClick={handleSmsOptOut}
                        >
                          Click to Opt Out
                        </Button>
                      </div>
                    </div>
                  ) : (
                    `You receive SMS alerts at ${props.formik.values.mobile}`
                  )}
                </div>
                {!isSmall ? (
                  <div style={{ display: 'flex', flex: '0 0 auto' }}>
                    <Button
                      style={
                        isSmall
                          ? {
                              alignSelf: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              backgroundColor: 'red',
                              color: 'black',
                              flex: '1 1 auto',
                              marginTop: '0px',
                              marginBottom: '0px',
                              width: '100%',
                              border: '1px solid #159015',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                            }
                          : {
                              alignSelf: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              backgroundColor: 'red',
                              color: 'black',
                              border: '1px solid #159015',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                              marginLeft: '8px',
                              maxWidth: '450px',
                              marginTop: '0px',
                            }
                      }
                      onClick={handleSmsOptOut}
                    >
                      Click to Opt Out
                    </Button>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </BorderedSection>
      </BorderedSection>
      <AlertSmsConsentDialog
        smsDialogOpen={smsDialogOpen}
        handleConsent={handleSmsConsent}
        handleCancel={() => {
          props.formik.resetForm();
          setSmsDialogOpen(false);
        }}
        onChange={onChange}
        formik={formik}
      />
      <AlertOptOutConfirmationDialog
        open={openOptOutConfirmationDialog}
        handleCancel={() => {
          setOpenOptOutConfirmationDialog(false);
        }}
        handleConfirm={async () => {
          setOpenOptOutConfirmationDialog(false);
          if (optOutConfirmationMode === 'email') {
            await updateEmailAuthorization({
              variables: {
                userId: userId,
                isAuthorized: false,
              },
            });
          } else {
            await updateSmsAuthorization({
              variables: {
                userId: userId,
                smsMobile: formik.values.smsMobile,
                isAuthorized: false,
              },
            });
            setSmsDialogOpen(false);
          }
        }}
        message={optOutConfirmationMessage}
      />
    </>
  );
};
