import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import BorderedSection from '../../shared/borderedSection';
import { FormValues } from '../types/propertyBuildingDetailFormValues';
import {
  Building,
  CreateBuildingMutationVariables,
  UpdateBuildingMutationVariables,
  useCreateBuildingMutation,
  useUpdateBuildingMutation,
} from '../../../../types/generated-types';
import { Form, Formik, FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';
import { Notifier } from '../../../system/services/notificationManager';

export const PropertyBuildingDetailForm = (params: {
  building: Partial<Building>;
}) => {
  const { id } = useParams<{ id: string }>();
  const { building } = params;

  const navigate = useNavigate();
  const [updateBuilding] = useUpdateBuildingMutation();
  const [createBuilding] = useCreateBuildingMutation();

  const initialValues: FormValues = {
    name: building?.name ?? '',
    number: building?.number ?? 0,
  };

  const onSubmit = (
    values: FormValues,
    _helpers: FormikHelpers<FormValues>,
  ) => {
    const buildingData:
      | UpdateBuildingMutationVariables['building']
      | CreateBuildingMutationVariables['building'] = {
      name: values.name,
      number: Number(values.number),
      propertyId: id,
    };
    if (building?._id) {
      updateBuilding({
        variables: { id: building._id, building: buildingData },
      })
        .then(() => {
          formik.setSubmitting(false);
          Notifier.success('Building updated successfully');
          navigate(-1);
        })
        .catch((updateError) => {
          formik.setSubmitting(false);
          Notifier.error(updateError.message);
        });
    } else {
      createBuilding({ variables: { building: buildingData } })
        .then(() => {
          formik.setSubmitting(false);
          Notifier.success('Building added successfully');
          navigate(-1);
        })
        .catch((createError) => {
          formik.setSubmitting(false);
          Notifier.error(createError.message);
        });
    }
  };

  const validationSchema = yup.object({
    name: yup.string().required('Building title is required'),
  });

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form
          style={{
            width: '100%',
            fontSize: '16px',
            height: '100%',
          }}
          onSubmit={formik.handleSubmit}
        >
          <div>
            <BorderedSection title="Building">
              <TextField
                fullWidth
                autoFocus
                required
                id="name"
                name="name"
                variant="standard"
                label="Name"
                value={formik.values.name}
                onChange={(e) => {
                  formik.setFieldTouched('name', true);
                  formik.handleChange(e);
                }}
              />
              <br />
              <br />
              <TextField
                fullWidth
                id="number"
                name="number"
                type="number"
                variant="standard"
                label="Number"
                value={formik.values.number}
                onChange={(e) => {
                  formik.setFieldTouched('number', true);
                  formik.handleChange(e);
                }}
              />
              <br />
              <br />
            </BorderedSection>
          </div>
          <br />
          <div
            style={{
              marginLeft: '10px',
            }}
          >
            <Button
              disabled={!formik.isValid || !formik.dirty}
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ color: '#fff' }}
            >
              Save
            </Button>
            <Button
              sx={{ marginLeft: '10px' }}
              variant="text"
              onClick={() => navigate(-1)}
              color={!formik.isValid || !formik.dirty ? 'secondary' : 'primary'}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
