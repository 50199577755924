import React, { ReactElement, useCallback, useEffect, useState } from 'react';

/* NAVIGATION */
import { useLocation, useNavigate, useParams } from 'react-router-dom';

/* COMPONENTS */
import { ScheduleTemplateDetailForm } from './schedule-template-detail-form';
import { useInjectableComponents } from '../../../../system/services/injectableComponentsManager';

/* MUI */
import {
  Avatar,
  Card,
  CardHeader,
  IconButton,
  useMediaQuery,
  Typography,
  useTheme,
  Grid,
  Button,
  FormControl,
  FormLabel,
  NativeSelect,
  Box,
  Stack,
  Container,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import FullWidthLoadingSkeleton from '../../../shared/fullWidthLoadingSkeleton';

/* Data/Types */
import {
  usePropertyQuery,
  Property,
  useCreateThermostatScheduleTemplateMutation,
  useUpdateThermostatScheduleTemplateMutation,
  useRemoveThermostatScheduleTemplateMutation,
  CreateThermostatScheduleTemplateMutationVariables,
  UpdateThermostatScheduleTemplateMutationVariables,
  ThermostatScheduleTemplate,
  ThermostatScheduleTemplateValue,
  ThermostatScheduleTemplateEditVerificationData,
  useThermostatScheduleTemplateEditVerificationQuery,
  usePropertySetpointProfilesQuery,
  SetpointProfile,
  Unit,
  useThermostatScheduleTemplateUpdateVerificationQuery,
  TemperatureUnit,
} from '../../../../../types/generated-types';

/* ICONS */
import ScheduleIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Delete';

/* FORMIK */
import { Form, Formik, FormikHelpers, useFormik } from 'formik';
import { FormValues } from '../../types/scheduleDetailFormValues';
import { Notifier } from '../../../../system/services/notificationManager';
import {
  dailyScheduleDefault as _dailyScheduleDefault,
  DayOfWeek,
  EmptyWeeklySchedule,
  MyThermostat,
} from '../../../devices/thermostat/types';
import { Fahrenheit } from '../../../../system/models/temperatureUnits';
import { useAuthenticator } from '../../../../auth/AuthenticationContext';
import { ThermostatSchedulePropagationStatus } from '../../../devices/thermostat/components/schedule/thermostat-schedule-propagation-status';
import { VerifyUpdateScheduleTemplate } from '../../../shared/verify-update-schedule-template';
import { ApolloError } from '@apollo/client';
import BorderedSection from '../../../shared/borderedSection';
import { Slider } from '../../../devices/thermostat/components/system-edit/slider';

/* FORM VALIDATION */
import * as yup from 'yup';
import _ from 'lodash';

// HELPERS
import { defaultAbsoluteLimits } from '../../../helpers';
import { findAndSetTemplateById } from '../../../devices/thermostat/components/helpers/setters';
import { convertToPreferredSetpointLimitUnits } from '../../../devices/util/convert-to-preferred-setpoint-limits-units';
import {
  convertToPreferredDailyScheduleUnits,
  convertToPreferredWeeklyScheduleUnits,
} from '../../../devices/util/convert-to-preferred-schedule-units';
import { ThermostatScheduleStatistics } from '../../../devices/thermostat/types/thermostat-schedule-statistics';

export const ScheduleTemplateDetail = (props: {
  template: ThermostatScheduleTemplate | null;
  unit?: Partial<Unit>;
  thermostat?: MyThermostat;
  disableSetpointProfileSelection?: boolean;
  handleClose?: (newTemplateId?: string, update?: boolean) => void;
}): ReactElement | null => {
  const {
    template: originalTemplate,
    handleClose,
    unit,
    thermostat,
    disableSetpointProfileSelection,
  } = props;

  /* State */
  const { id } = useParams<{ id: string }>();
  const [property, setProperty] = useState<Partial<Property>>();
  const [template, setTemplate] = useState<ThermostatScheduleTemplate | null>(
    null,
  );
  const [
    thermostatUsesUnitSetpointLimits,
    setThermostatUsesUnitSetpointLimits,
  ] = useState<boolean>(false);

  const [
    thermostatUsesPropertySetpointLimits,
    setThermostatUsesPropertySetpointLimits,
  ] = useState<boolean>(false);

  const [scheduleValues, setScheduleValues] =
    React.useState<ThermostatScheduleTemplateValue>({
      id: '',
      units: 'N/A',
      timestamp: 0,
      value: EmptyWeeklySchedule,
      updatePending: false,
    });
  const [anchorElValue, setAnchorElValue] = useState<null | string>(null);
  const [selectedSetpointProfileId, setSelectedSetpointProfileId] =
    React.useState<string>('0');
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [propagationStatusView, setPropagationStatusView] = useState(false);
  // const [queryData, setQuerydata] = React.useState<
  //   ThermostatScheduleTemplateEditVerificationData | undefined
  // >(undefined);
  const [queryData, setQuerydata] =
    React.useState<ThermostatScheduleStatistics>();
  const [scheduleUpdateStatistics, setScheduleUpdateStatistics] =
    React.useState<any>([]);
  const [availableProfiles, setAvailableProfiles] = React.useState<
    Partial<SetpointProfile[]>
  >([]);
  const [queryError, setQueryError] = React.useState<ApolloError>();
  const [selectedSetpointProfile, setSelectedSetpointProfile] = React.useState<
    Partial<SetpointProfile>
  >({});

  const theme = useTheme();
  const navigate = useNavigate();
  const auth = useAuthenticator();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const handleRevertChanges = useCallback(() => {
    if (originalTemplate) {
      const formattedTemplateValues = convertToPreferredWeeklyScheduleUnits(
        _.cloneDeep(originalTemplate.value.value),
        preferredUnits,
        'C',
      );
      setTemplate(_.cloneDeep(originalTemplate));
      setScheduleValues({
        ..._.cloneDeep(originalTemplate.value),
        value: formattedTemplateValues,
      });
    }
  }, [originalTemplate]);

  const preferredUnits = auth.user?.preferredUnits ?? Fahrenheit;
  let loadingVerificationData: boolean = false;

  /* Mutations */
  const [createThermostatScheduleTemplate] =
    useCreateThermostatScheduleTemplateMutation();
  const [updateThermostatScheduleTemplate] =
    useUpdateThermostatScheduleTemplateMutation();

  const [
    deleteThermostatScheduleTemplate,
    {
      // TODO: Loading_error: should we be handling loading here?
      // data,
      // loading,
      error,
    },
  ] = useRemoveThermostatScheduleTemplateMutation({
    refetchQueries: ['PropertyScheduleTemplateList'],
  });

  const {
    setPrimaryBottomNavigationWidget,
    setContextMenuItems,
    setSubtitle,
    setSubtitlePath,
    setSubtitleActionWidget,
    setTitle,
    setTitlePath,
  } = useInjectableComponents();

  const { pathname } = useLocation();

  /* Data */
  const {
    data: propertyData,
    loading: propertyLoading,
    error: propertyError,
  } = usePropertyQuery({
    variables: { _id: id || '' },
    fetchPolicy: 'cache-and-network',
  });

  const { data: propertySetpointProfileData, refetch: propertyRefetch } =
    usePropertySetpointProfilesQuery({
      variables: { id: id ?? '' },
    });

  const {
    loading: scheduleStatsDataLoading,
    refetch: refetchThermostatScheduleTemplateUpdateVerification,
  } = useThermostatScheduleTemplateUpdateVerificationQuery({
    variables: {
      propertyId: id,
    },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data?.thermostatScheduleTemplateUpdateVerification) {
        const netThermostatsToBeUpdated =
          data.thermostatScheduleTemplateUpdateVerification.installedOnlineThermostats
            .filter((x) => {
              return !data.thermostatScheduleTemplateUpdateVerification?.installedOnlineThermostatsAndUsesCustomSchedule.find(
                (y) => {
                  return x === y;
                },
              );
            })
            .filter((a) => {
              return !data.thermostatScheduleTemplateUpdateVerification?.thermostatsUsingUnitLevelScheduleTemplate.find(
                (b) => {
                  return a === b;
                },
              );
            })
            .filter(() => {
              return data.thermostatScheduleTemplateUpdateVerification
                ?.installedOnlineThermostatsAndUsesScheduleTemplate;
            });
        setQuerydata(data.thermostatScheduleTemplateUpdateVerification);
        setScheduleUpdateStatistics(netThermostatsToBeUpdated);
      }
    },
    onError(err) {
      console.error(err);
      setQueryError(err);
      Notifier.error(
        'An error occurred requesting verification data. Please retry.',
      );
    },
  });

  /* FORM DATA & FORMIK */
  const [initialValues, setInitialValues] = useState<FormValues>({
    name: originalTemplate?.name || '',
    setpointLimitProfileId: originalTemplate?.setpointLimitProfileId || '',
  });

  useEffect(() => {
    if (originalTemplate) {
      const formattedTemplateValues = convertToPreferredWeeklyScheduleUnits(
        _.cloneDeep(originalTemplate.value.value),
        preferredUnits,
        TemperatureUnit.C,
      );
      setTemplate(_.cloneDeep(originalTemplate));
      setScheduleValues({
        ..._.cloneDeep(originalTemplate.value),
        value: formattedTemplateValues,
      });
      setInitialValues({
        name: originalTemplate?.name || '',
        setpointLimitProfileId: originalTemplate?.setpointLimitProfileId || '',
      });
    } else {
      const _newSchedule = _.cloneDeep(scheduleValues);
      const convertedDailyScheduleDefault =
        convertToPreferredDailyScheduleUnits(
          _dailyScheduleDefault,
          preferredUnits,
          TemperatureUnit.C,
        );
      // Set all daily schedules to the same value
      _newSchedule.value[DayOfWeek.SU] = convertedDailyScheduleDefault;
      _newSchedule.value[DayOfWeek.MO] = convertedDailyScheduleDefault;
      _newSchedule.value[DayOfWeek.TU] = convertedDailyScheduleDefault;
      _newSchedule.value[DayOfWeek.WE] = convertedDailyScheduleDefault;
      _newSchedule.value[DayOfWeek.TH] = convertedDailyScheduleDefault;
      _newSchedule.value[DayOfWeek.FR] = convertedDailyScheduleDefault;
      _newSchedule.value[DayOfWeek.SA] = convertedDailyScheduleDefault;

      setScheduleValues(_newSchedule);
    }
  }, [originalTemplate]);

  useEffect(() => {
    if (propertyData?.property?._id) {
      const myProperty: Partial<Property> =
        propertyData.property as Partial<Property>;
      setProperty(myProperty);
    }
  }, [propertyData]);

  // Preselect setpoint profile from template's setpoint limit profile
  useEffect(() => {
    if (propertySetpointProfileData?.propertyById) {
      let originalSetpointProfile: Partial<SetpointProfile> | null = null;
      let formattedSetpointProfileValueUnits: Partial<SetpointProfile> | null =
        null;
      let targetSetpointProfileId: string = '';
      if (template) {
        if (
          propertySetpointProfileData.propertyById &&
          template.setpointLimitProfileId
        ) {
          targetSetpointProfileId = template.setpointLimitProfileId;
        }
      } else if (
        unit?.setpointProfileId &&
        !thermostat?.useCustomSetpointLimits
      ) {
        setThermostatUsesUnitSetpointLimits(true);
        targetSetpointProfileId = unit.setpointProfileId;
      } else if (
        property?.setpointProfileId &&
        !thermostat?.useCustomSetpointLimits
      ) {
        setThermostatUsesPropertySetpointLimits(true);
        targetSetpointProfileId = property.setpointProfileId;
      }
      originalSetpointProfile = findAndSetTemplateById(
        targetSetpointProfileId,
        propertySetpointProfileData.propertyById
          ?.assignableSetpointProfiles ?? [{}],
      );
      if (originalSetpointProfile) {
        // We use preferred units for the selected setpoint profile heat and cool values
        formattedSetpointProfileValueUnits =
          convertToPreferredSetpointLimitUnits(
            originalSetpointProfile,
            preferredUnits,
          );
        setSelectedSetpointProfileId(targetSetpointProfileId);
        setSelectedSetpointProfile(formattedSetpointProfileValueUnits);
      }
    }
  }, [
    propertySetpointProfileData,
    availableProfiles,
    template,
    unit,
    thermostat,
  ]);

  useEffect(() => {
    const targetSetpointProfile =
      (availableProfiles || []).find(
        (profile) => profile && profile._id === selectedSetpointProfileId,
      ) || {};
    if (targetSetpointProfile) {
      setSelectedSetpointProfile(
        convertToPreferredSetpointLimitUnits(
          targetSetpointProfile,
          preferredUnits,
        ),
      );
    }
  }, [selectedSetpointProfileId, availableProfiles, property]);

  useEffect(() => {
    if (propertySetpointProfileData?.propertyById) {
      setAvailableProfiles(
        (propertySetpointProfileData.propertyById
          .assignableSetpointProfiles as Partial<SetpointProfile[]>) ?? [],
      );
    }
  }, [propertySetpointProfileData]);

  const handleRemoveTemplate = async () => {
    if (template?._id) {
      try {
        await deleteThermostatScheduleTemplate({
          variables: { removeThermostatScheduleTemplateId: template._id },
        });
        Notifier.success('Schedule template deleted');
        navigate(`/properties/${property?._id}/schedule/templates`);
      } catch (e) {
        Notifier.error('Unable to delete schedule template');
      }
      Notifier.success('Schedule template deleted');
    } else {
      Notifier.error('No template found');
    }
  };

  const deletePromptMessage =
    template?.isPropertyDefault ||
    (template?.assignedUnitCount ? template.assignedUnitCount > 0 : false)
      ? `This template is ${
          template?.isPropertyDefault
            ? 'currently in use by the property'
            : 'not in use by the property'
        } ${
          template?.isPropertyDefault &&
          (template?.assignedUnitCount ? template.assignedUnitCount > 0 : false)
            ? 'and'
            : 'but'
        } ${
          template?.assignedUnitCount
            ? template?.assignedUnitCount > 0
              ? `used by units (${template?.assignedUnitCount})`
              : 'not used by units'
            : 'not used by units'
        }`
      : `This template is not in use`;

  const confirmableAction = {
    actionLabel: 'Delete',
    cancelLabel: 'Cancel',
    title: 'Are you sure you want to delete this template?',
    prompt: deletePromptMessage,
    action: handleRemoveTemplate,
  };

  // Formats the selected setpoint profile with preferred heat and cool settings

  useEffect(() => {
    if (property && pathname && template && template._id) {
      /* Effects: Manage Bottom Nav Menu */
      setContextMenuItems([
        {
          items: [
            {
              id: 'remove-template-menu-item',
              label: 'Delete Schedule Template',
              icon: <RemoveIcon fontSize="small" />,
              confirmableAction,
              // TODO: Calvin: setup permissions
              // permit: {
              //   action: 'delete',
              //   subject: userData.userById as User,
              // },
            },
          ],
        },
      ]);

      return () => {
        setTitle(undefined);
        setTitlePath(undefined);
        setSubtitle(undefined);
        setSubtitlePath(undefined);
        setSubtitleActionWidget(undefined);
        setContextMenuItems(undefined);
        setPrimaryBottomNavigationWidget(undefined);
      };
    }
  }, [property, pathname, isSmall]);

  const err = propertyError;
  const isLoading = propertyLoading;

  const onSubmit = (
    values: FormValues,
    _helpers: FormikHelpers<FormValues>,
  ) => {
    const formattedScheduleValues = convertToPreferredWeeklyScheduleUnits(
      scheduleValues.value,
      'C',
      preferredUnits,
    );
    const templateData:
      | CreateThermostatScheduleTemplateMutationVariables['thermostatScheduleTemplate']
      | UpdateThermostatScheduleTemplateMutationVariables['thermostatScheduleTemplate'] =
      {
        _id: template?._id ?? '',
        propertyId: property?._id ?? '',
        name: values.name,
        setpointLimitProfileId: selectedSetpointProfileId ?? '0',
        value: {
          id: scheduleValues.id,
          units: scheduleValues.units,
          timestamp: scheduleValues.timestamp,
          updatePending: scheduleValues.updatePending,
          value: formattedScheduleValues,
        },
      };

    if (template?._id) {
      updateThermostatScheduleTemplate({
        variables: {
          thermostatScheduleTemplate: templateData,
        },
      })
        .then((res) => {
          Notifier.success('Updated thermostat schedule template');
        })
        .catch((error) => {
          Notifier.error(
            'A problem occurred while updating the schedule template',
          );
          console.log('ERROR updating thermostat schedule template: ', error);
        });
    } else {
      createThermostatScheduleTemplate({
        variables: { thermostatScheduleTemplate: templateData },
      })
        .then((res) => {
          const { data } = res;
          Notifier.success('Created thermostat schedule template');
          if (handleClose) {
            handleClose(data?.createThermostatScheduleTemplate, true);
          } else {
            navigate(`/properties/${property?._id}/schedule/templates`);
          }
        })
        .catch((error) => {
          Notifier.error(
            'A problem occurred while creating the thermostat schedule template',
          );
          console.log('ERROR creating thermostat schedule template: ', error);
        });
    }
  };

  const validationSchema = yup.object({
    name: yup.string().required('Template name is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit,
  });

  const handleOpenVerificationDialog = () => {
    setOpenVerificationDialog(true);
  };

  const handleOpenPropagationStatusView = () => {
    setPropagationStatusView(true);
  };

  const handleCloseVerificationDialog = () => {
    setOpenVerificationDialog(false);
  };

  const handleClosePropagationStatusView = () => {
    setPropagationStatusView(false);
  };

  const handleCloseSetpointLimitChangeWarningDialog = () => {
    setAnchorElValue(null);
  };

  const handleSetpointProfileSourceText = () => {
    let sourceText =
      'Select a Setpoint Limit Profile to adjust temperature limits:';
    if (thermostatUsesUnitSetpointLimits) {
      sourceText = 'Setpoint Profile Preselected by Unit Setpoint Limits';
    } else if (thermostatUsesPropertySetpointLimits) {
      sourceText = 'Setpoint Profile Preselected by Property Setpoint Limits';
    }
    return sourceText;
  };

  const handleClearScheduleForm = () => {
    setScheduleValues((current) => {
      const newSchedule = {
        ...current,
        value: _.cloneDeep(EmptyWeeklySchedule),
      };
      return newSchedule;
    });
  };

  return err ? (
    err.message === 'Unauthorized' ? (
      <>
        <Typography variant="h4">
          You are not authorized to access the requested resource.
        </Typography>
        <Typography variant="body1">
          Please contact your administrator or Embue support for assistance.
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="h4">
          Error accessing the requested resource.
        </Typography>
        <Typography variant="body1">
          Please contact your administrator or Embue support for assistance.
        </Typography>
      </>
    )
  ) : isLoading ? (
    <FullWidthLoadingSkeleton />
  ) : (
    <div>
      <Card
        elevation={4}
        sx={{
          background: 'inherit',
          margin: isSmall ? '0px' : '20px',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'stretch',
          '& .MuiCardHeader-root': {
            padding: '4px 10px!important',
            borderBottom: '1px solid darkgray',
            borderRight: isSmall ? '1px solid lightgray' : 'inherit',
            boxShadow: '0px 8px 6px -6px rgba(0, 0, 0, 0.35)',
          },
          '& .MuiCardContent-root': {
            flex: 1,
            borderRight: isSmall ? '1px solid lightgray' : 'inherit',
            paddingTop: '0px !important',
            padding: isSmall ? '0px !important' : 'inherit',
          },
          cursor: 'pointer',
        }}
      >
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          <Form
            style={{ width: '100%', fontSize: '16px', height: '100%' }}
            onSubmit={formik.handleSubmit}
          >
            <CardHeader
              avatar={
                <Avatar
                  aria-label="schedule icon"
                  sx={{
                    color: theme.palette.secondary.main,
                    backgroundColor: '#FFF',
                  }}
                >
                  <ScheduleIcon />
                </Avatar>
              }
              title={
                <Typography
                  variant="h3"
                  sx={{ color: 'rgba(0, 0, 0, 0.87)', marginLeft: '8px' }}
                  noWrap
                >
                  Schedule Template
                </Typography>
              }
              action={
                <IconButton
                  aria-label="cancel-schedule-template-form"
                  sx={{
                    color: theme.embue.bad,
                    backgroundColor: '#FFF',
                  }}
                  onClick={() => {
                    if (handleClose) {
                      handleClose();
                    } else {
                      navigate(
                        `/properties/${property?._id}/schedule/templates`,
                      );
                    }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
            />
            <div
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                flexGrow: 1,
                overflow: 'auto',
                paddingTop: '6px',
                marginTop: '20px',
              }}
            >
              <>
                <BorderedSection title="Setpoint Limits">
                  <FormControl sx={{ width: '100%' }}>
                    <FormLabel id="demo-radio-buttons-group-label">
                      {handleSetpointProfileSourceText()}
                    </FormLabel>
                    <NativeSelect
                      disabled={disableSetpointProfileSelection}
                      // disabled={creating || editing || verifying}
                      style={{ width: '100%', marginBottom: '20px' }}
                      value={selectedSetpointProfileId}
                      onChange={(event) => {
                        if (
                          event.target.value !== '0' &&
                          event.target.value !== ''
                        ) {
                          if (
                            event.target.value !==
                              template?.setpointLimitProfileId &&
                            !_.isEqual(
                              scheduleValues.value,
                              EmptyWeeklySchedule,
                            )
                          ) {
                            setAnchorElValue(event.currentTarget.value);
                          } else {
                            setSelectedSetpointProfileId(event.target.value);
                          }
                        } else {
                          setSelectedSetpointProfileId(event.target.value);
                        }
                      }}
                      fullWidth
                    >
                      <option key="0" value="0">
                        Select a Profile
                      </option>
                      {availableProfiles
                        ? availableProfiles.map((profile) => (
                            <option
                              key={profile?._id ?? ''}
                              value={profile?._id ?? ''}
                            >
                              {profile?.name ?? 'N/A'}
                            </option>
                          ))
                        : null}
                    </NativeSelect>
                    <Container>
                      {[selectedSetpointProfile].map((profile, index) => {
                        return profile ? (
                          <div key={index}>
                            <Box
                              sx={{
                                justifyContent: 'center',
                                px: 4,
                                py: 2,
                              }}
                            >
                              <Stack
                                spacing={2}
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                              >
                                <Typography variant="button">
                                  {
                                    defaultAbsoluteLimits[
                                      preferredUnits as TemperatureUnit
                                    ].heat.min
                                  }
                                </Typography>
                                <Slider
                                  getAriaLabel={() => 'Heat Setpoint Limits'}
                                  value={[
                                    profile?.heat?.min ?? 0,
                                    profile?.heat?.max ?? 0,
                                  ]}
                                  min={
                                    defaultAbsoluteLimits[preferredUnits].heat
                                      .min
                                  }
                                  max={
                                    defaultAbsoluteLimits[preferredUnits].cool
                                      .max
                                  }
                                  valueLabelDisplay="on"
                                  disableSwap
                                  sx={{
                                    color: theme.embue.heating,
                                    py: 2,
                                  }}
                                />
                                <Typography variant="button">
                                  {
                                    defaultAbsoluteLimits[preferredUnits].cool
                                      .max
                                  }
                                </Typography>
                              </Stack>

                              <Stack
                                spacing={2}
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                              >
                                <Typography variant="button">
                                  {
                                    defaultAbsoluteLimits[preferredUnits].heat
                                      .min
                                  }
                                </Typography>
                                <Slider
                                  getAriaLabel={() => 'Cool Setpoint Limits'}
                                  value={[
                                    profile?.cool?.min ?? 0,
                                    profile?.cool?.max ?? 0,
                                  ]}
                                  min={
                                    defaultAbsoluteLimits[preferredUnits].heat
                                      .min
                                  }
                                  max={
                                    defaultAbsoluteLimits[preferredUnits].cool
                                      .max
                                  }
                                  valueLabelDisplay="on"
                                  disableSwap
                                  sx={{
                                    color: theme.embue.cooling,
                                    py: 2,
                                  }}
                                />
                                <Typography variant="button">
                                  {
                                    defaultAbsoluteLimits[preferredUnits].cool
                                      .max
                                  }
                                </Typography>
                              </Stack>
                            </Box>
                          </div>
                        ) : null;
                      })}
                    </Container>
                  </FormControl>
                </BorderedSection>
                <br />
                <ScheduleTemplateDetailForm
                  setScheduleValues={setScheduleValues}
                  scheduleValues={scheduleValues}
                  onChange={(e) => {
                    formik.setFieldTouched((e.target as any).id, true);
                    formik.handleChange(e);
                  }}
                  formik={formik}
                  template={template}
                  scheduleTemplateSetpointProfileSettings={{
                    maxHeatSetpointLimit:
                      selectedSetpointProfile?.heat?.max ??
                      defaultAbsoluteLimits[preferredUnits].heat.max,
                    maxCoolSetpointLimit:
                      selectedSetpointProfile?.cool?.max ??
                      defaultAbsoluteLimits[preferredUnits].cool.max,
                    minHeatSetpointLimit:
                      selectedSetpointProfile?.heat?.min ??
                      defaultAbsoluteLimits[preferredUnits].heat.min,
                    minCoolSetpointLimit:
                      selectedSetpointProfile?.cool?.min ??
                      defaultAbsoluteLimits[preferredUnits].cool.min,
                  }}
                />
                {queryError ? (
                  <Typography>Error: {queryError.name} </Typography>
                ) : loadingVerificationData ? (
                  <Typography>Data loading ...</Typography>
                ) : queryData && template?._id && !propagationStatusView ? (
                  <VerifyUpdateScheduleTemplate
                    thermostatScheduleTemplateId={template?._id ?? ''}
                    scheduleStats={queryData}
                    scheduleUpdateStats={scheduleUpdateStatistics}
                  />
                ) : null}
                {propagationStatusView && template ? (
                  <ThermostatSchedulePropagationStatus
                    template={template}
                    handleClosePropagationStatusView={
                      handleClosePropagationStatusView
                    }
                    action="update"
                  />
                ) : null}
                <Grid
                  container
                  rowSpacing={1}
                  direction={'row'}
                  sx={{ marginTop: '10px' }}
                >
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      disabled={
                        (!formik.dirty &&
                          _.isEqual(
                            convertToPreferredWeeklyScheduleUnits(
                              scheduleValues.value,
                              'C',
                              preferredUnits,
                            ),
                            originalTemplate?.value.value,
                          )) ||
                        formik.values.name === ''
                      }
                      color={template?._id ? 'warning' : 'success'}
                      sx={{ color: '#fff' }}
                      onClick={() => {
                        handleOpenPropagationStatusView();
                      }}
                    >
                      {`${template?._id ? 'Update' : 'Create'}`}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      disabled={
                        formik.dirty
                          ? false
                          : _.isEqual(
                                convertToPreferredWeeklyScheduleUnits(
                                  scheduleValues.value,
                                  'C',
                                  preferredUnits,
                                ),
                                originalTemplate?.value.value,
                              )
                            ? true
                            : false
                      }
                      variant="contained"
                      sx={{
                        backgroundColor: theme.embue.cooling,
                        color: '#fff',
                      }}
                      onClick={() => {
                        handleRevertChanges();
                        formik.resetForm({
                          values: {
                            name: originalTemplate?.name || '',
                            setpointLimitProfileId:
                              originalTemplate?.setpointLimitProfileId || '',
                          },
                        });
                      }}
                      color="info"
                    >
                      Revert Changes
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      sx={{ color: theme.embue.bad }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleCloseVerificationDialog();
                        if (handleClose) {
                          handleClose();
                        } else {
                          navigate(
                            `/properties/${property?._id}/schedule/templates`,
                          );
                        }
                      }}
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </>
            </div>
          </Form>
        </Formik>
        <SetpointLimitChangeWarningDialog
          anchorElValue={anchorElValue}
          handleClose={handleCloseSetpointLimitChangeWarningDialog}
          handleSetSelectedSetpointProfileId={(value: string) => {
            setSelectedSetpointProfileId(value);
          }}
          handleClearScheduleForm={handleClearScheduleForm}
        />
      </Card>
    </div>
  );
};

function SetpointLimitChangeWarningDialog(props: {
  handleClose: () => void;
  anchorElValue: null | string;
  handleClearScheduleForm: () => void;
  handleSetSelectedSetpointProfileId: (value: string) => void;
}) {
  const {
    handleClose,
    handleClearScheduleForm,
    handleSetSelectedSetpointProfileId,
    anchorElValue,
  } = props;

  const open = Boolean(anchorElValue);

  return (
    <Dialog
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      <Typography variant="h4" sx={{ padding: '15px' }}>
        Warning: Setpoint Limit Profile change will clear schedule form
      </Typography>
      <DialogContent sx={{ padding: '10px 10px' }}>
        <Typography>
          Changing the setpoint limit profile will clear the current schedule
          form, do you wish to continue?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container rowSpacing={1} direction={'row'}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              color="warning"
              sx={{ color: '#fff' }}
              onClick={() => {
                handleSetSelectedSetpointProfileId(anchorElValue ?? '');
                handleClearScheduleForm();
                handleClose();
              }}
            >
              Continue
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              color="error"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClose();
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
