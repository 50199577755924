import React, { useEffect } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Notifier } from '../../../../system/services/notificationManager';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../../../system/services/injectableComponentsManager';
import { basePropertyMenuItems } from '../base-property-context-menu';
import { useAuthorizer } from '../../../../auth/AuthorizationContext';

import {
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
  Tab,
  Box,
} from '@mui/material';

import { TabContext, TabList } from '@mui/lab';

import {
  Property,
  PropertyQuery,
  usePropertyQuery,
  User,
} from '../../../../../types/generated-types';

import { PropertyViewHeader } from './property-view-header';
import {
  PropertyViewDetailSelector,
  viewOptionsMap,
  ViewOption,
} from './property-view-detail-selector';

import { PropertyViewDetailPanel } from './property-view-detail-panel';

export type MyProperty = PropertyQuery['property'];
export type MyUser = User;

export function PropertyView() {
  const [property, setProperty] = React.useState<MyProperty>();

  const [value, setValue] = React.useState('location');
  const [selectedViewOption, setSelectedViewOption] =
    React.useState<ViewOption>(viewOptionsMap.Location);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const { can, cannot } = useAuthorizer();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { search } = useLocation();

  const { id } = useParams<{ id: string }>();

  const {
    setSubtitle,
    setTitle,
    setContextMenuItems,
    setTitlePath,
    setSubtitlePath,
    setPrimaryBottomNavigationWidget,
  } = useInjectableComponents();

  const {
    // ..
    data,
    error,
    loading,
  } = usePropertyQuery({
    variables: { _id: id ?? '' },
  });

  useEffect(() => {
    const thisProperty = data?.property;
    if (thisProperty) {
      setProperty(thisProperty);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      Notifier.error(
        'There was a problem fetching property information: ',
        error,
      );
    }
  }, [error]);

  useEffect(() => {
    if (property?._id) {
      const contextMenuItems: BottomMenuItems = [
        ...basePropertyMenuItems(property._id, pathname),
      ];

      setTitle(property.title);
      setTitlePath(`/properties/${property._id}/summary`);

      setSubtitle('Viewing Property Details');
      setSubtitlePath(`/properties/${property._id}/units`);

      setContextMenuItems(contextMenuItems, property as Property);
    }
    return () => {
      setTitle(undefined);
      setTitlePath(undefined);
      setSubtitle(undefined);
      setSubtitlePath(undefined);
      setContextMenuItems(undefined);
    };
  }, [property, pathname]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    let view = queryParams.get('view') ?? 'location';

    if (view === 'zone-policies' && cannot('manage', 'ZonePolicy')) {
      view = 'location';
    } else if (
      view === 'setpoint-profiles' &&
      cannot('manage', 'SetpointProfile')
    ) {
      view = 'location';
    }

    const newValue =
      Object.values(viewOptionsMap).find(
        (option) => option.routeKey === view,
      ) ?? viewOptionsMap['Location'];
    setSelectedViewOption(newValue);
    setValue(newValue.routeKey);
  }, [search]);

  const handleViewChange = React.useCallback(
    (_event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
      navigate(`?view=${newValue}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (isSmall) {
      if (property) {
        setPrimaryBottomNavigationWidget(
          <div style={{ paddingTop: '8px', width: '100%' }}>
            <PropertyViewDetailSelector
              selectedProperty={property}
              value={selectedViewOption}
              changeHandler={handleViewChange}
            />
          </div>,
        );
      }
      return () => {
        setPrimaryBottomNavigationWidget(undefined);
      };
    }
  }, [property, selectedViewOption, handleViewChange, isSmall]);

  return property ? (
    <Card
      elevation={4}
      sx={{
        background: 'inherit',
        margin: isSmall ? '0px' : '20px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        '& .MuiCardHeader-root': {
          padding: '4px 10px!important',
          borderBottom: '1px solid darkgray',
          borderRight: isSmall ? '1px solid lightgray' : 'inherit',
          boxShadow: '0px 8px 6px -6px rgba(0, 0, 0, 0.35)',
        },
        '& .MuiCardContent-root': {
          flex: 1,
          borderRight: isSmall ? '1px solid lightgray' : 'inherit',
          paddingTop: '0px !important',
          padding: isSmall ? '0px !important' : 'inherit',
        },
      }}
    >
      <PropertyViewHeader property={property} loading={loading} />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          p: 0,
          background: 'inherit',
          paddingBottom: '0px !important',
        }}
      >
        <div>
          {!isSmall ? (
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleViewChange}
                  aria-label="Property View Tabs"
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="scrollable"
                  // visibleScrollbar
                >
                  <Tab
                    label="Location"
                    value={viewOptionsMap.Location.routeKey}
                  />
                  <Tab label="Alerts" value={viewOptionsMap.Alerts.routeKey} />
                  {can('manage', 'SetpointProfile') && (
                    <Tab
                      label="Setpoint Profiles"
                      value={viewOptionsMap['Setpoint Profiles'].routeKey}
                    />
                  )}
                  {can('manage', 'ZonePolicy') && (
                    <Tab
                      label="Zone Policies"
                      value={viewOptionsMap['Zone Policies'].routeKey}
                    />
                  )}
                  <Tab
                    label="Access List"
                    value={viewOptionsMap['Access List'].routeKey}
                  />
                  <Tab
                    label="Schedule Template"
                    value={viewOptionsMap['Schedule Template'].routeKey}
                  />
                </TabList>
              </Box>
              <div
                style={{
                  margin: isSmall ? '0px' : '10px 4px',
                  display: 'flex',
                  flexDirection: 'row',
                  flex: 1,
                  alignItems: 'stretch',
                }}
              >
                <PropertyViewDetailPanel
                  theme={theme}
                  viewOption={selectedViewOption}
                  data={property}
                />
              </div>
            </TabContext>
          ) : (
            <PropertyViewDetailPanel
              theme={theme}
              viewOption={selectedViewOption}
              data={property}
            />
          )}
        </div>
      </CardContent>
    </Card>
  ) : null;
}
