import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import moment from 'moment';
import { useResumeThermostatScheduleMutation } from '../../../../../../types/generated-types';
import { Notifier } from '../../../../../system/services/notificationManager';
import { useAuthorizer } from '../../../../../auth/AuthorizationContext';

interface ResumeScheduleProps {
  deviceId: string;
  briefScheduleStatus?: string;
  holdEnd?: number;
  updatePending?: boolean;
}

export function ResumeSchedule(props: ResumeScheduleProps) {
  const { deviceId, briefScheduleStatus, holdEnd, updatePending } = props;
  const { can } = useAuthorizer();

  const [resume, { loading }] = useResumeThermostatScheduleMutation({
    variables: {
      deviceId,
    },
    onError(error) {
      console.error(error);
      Notifier.error('Error resuming thermostat schedule');
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        p: 1,
      }}
    >
      {briefScheduleStatus && (
        <Typography
          sx={{ marginRight: 2, fontSize: 12 }}
          title={holdEnd ? moment(holdEnd).format('h:mma') : ''}
        >
          {briefScheduleStatus}
        </Typography>
      )}
      {can('update', 'Thermostat') ? (
        <Button
          disabled={loading || updatePending}
          onClick={() => resume()}
          color="info"
          size="small"
        >
          Resume Schedule
        </Button>
      ) : null}
    </Box>
  );
}
