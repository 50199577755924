import * as React from 'react';
import { usePropertyQuery } from '../../../types/generated-types';

export function PropertyDetailBreadcrumb({ id }: { id?: string }) {
  const { data } = usePropertyQuery({ variables: { _id: id } });
  return <span>{'View: ' + data?.property?.title || 'unamed property'}</span>;
}

export function EditPropertyDetailBreadcrumb({ id }: { id?: string }) {
  const { data } = usePropertyQuery({ variables: { _id: id } });
  return <span>{'Edit: ' + data?.property?.title || 'unamed property'}</span>;
}
