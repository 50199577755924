import {
  AbilityBuilder,
  MongoAbility,
  createMongoAbility,
} from '@casl/ability';
import {
  Alert,
  AlertCategory,
  AlertSubscription,
  AlertTrigger,
  Building,
  Device,
  Ingestion,
  Loop,
  Property,
  SetpointProfile,
  ThermostatScheduleTemplate,
  Source,
  Thermostat,
  Unit,
  User,
  ZonePolicy,
  SmartPowerOutlet,
} from '../../../types/generated-types';
import { AuthorizedUser } from '../models';

export type Actions =
  | 'viewSchedule'
  | 'viewCharts'
  | 'createUnit'
  | 'createBuilding'
  | 'createAlertTrigger'
  | 'createSetpointProfile'
  | 'updateSetpointProfile'
  | 'deleteSetpointProfile'
  | 'install'
  | 'view'
  | 'index'
  | 'manage'
  | 'create'
  | 'read'
  | 'update'
  | 'updateProfile'
  | 'delete'
  | 'administer'
  | 'control'
  | 'reset';

export type SubjectTypes =
  | 'all'
  | 'Property'
  | 'Unit'
  | 'SetpointProfile'
  | 'ZonePolicy'
  | 'ThermostatScheduleTemplate'
  | 'User'
  | 'Ingestion'
  | 'Reports'
  | 'Building'
  | 'Alert'
  | 'AlertCategory'
  | 'AlertTrigger'
  | 'AlertSubscription'
  | 'Source'
  | 'Device'
  | 'Loop'
  | 'Thermostat'
  | 'ModbusAirHandler'
  | 'BulkConfig'
  | 'SubscriptionMonitoring'
  | 'SmartPowerOutlet';

export type Subjects =
  | SubjectTypes
  | Pick<Property, '__typename' | '_id' | 'title' | 'unitsSummary'>
  | Pick<Unit, '__typename' | '_id' | 'propertyId'>
  | Pick<SetpointProfile, '__typename' | '_id' | 'properties'>
  | Pick<ZonePolicy, '__typename' | '_id' | 'properties'>
  | Pick<ThermostatScheduleTemplate, '__typename' | '_id'>
  | Pick<User, '__typename' | '_id'>
  | Pick<Ingestion, '__typename' | '_id'>
  | Pick<Building, '__typename' | '_id'>
  | Pick<Alert, '__typename' | '_id' | 'property' | 'unit'>
  | Pick<AlertCategory, '__typename' | '_id' | 'propertyId'>
  | Pick<AlertTrigger, '__typename' | '_id' | 'propertyId'>
  | Pick<
      AlertSubscription,
      '__typename' | '_id' | 'propertyId' | 'unitId' | 'userId'
    >
  | Pick<Source, '__typename' | '_id' | 'propertyId'>
  | Pick<Device, '__typename' | '_id' | 'propertyId' | 'unitId'>
  | Pick<Thermostat, '__typename' | '_id' | 'propertyId' | 'unitId'>
  | Pick<SmartPowerOutlet, '__typename' | '_id' | 'propertyId' | 'unitId'>
  | Pick<Loop, '__typename' | '_id' | 'propertyId' | 'unitId'>;

export type AppAbility = MongoAbility<[Actions, Subjects]>;

export function defineAbilityFor(user: AuthorizedUser | undefined) {
  const { can, cannot, build } = new AbilityBuilder<AppAbility>(
    createMongoAbility,
  );
  if (user) {
    if (user.isSuper) {
      can('manage', 'all');

      if (user.email.endsWith('@embue.com')) {
        can('administer', 'all');
        if (
          !['john', 'peter', 'nathan', 'calvin'].some((name: string) =>
            user.email.startsWith(name),
          )
        ) {
          cannot('manage', 'Reports');
          cannot('manage', 'SubscriptionMonitoring');
        }
      } else {
        cannot('manage', 'Reports');
        cannot('manage', 'SubscriptionMonitoring');
      }
    } else {
      can('index', 'Property').because(
        'Already restricted via filtering on the server',
      );

      can('manage', 'AlertSubscription', {
        userId: user._id,
      }).because('Already restricted via filtering on server');

      if (user.isManager) {
        can('manage', 'Property', {
          _id: { $in: user.managerProperties },
        });

        can('manage', 'AlertCategory', {
          propertyId: { $in: user.managerProperties },
        });

        can('manage', 'AlertTrigger', {
          propertyId: { $in: user.managerProperties },
        });

        can('manage', 'AlertSubscription', {
          propertyId: { $in: user.managerProperties },
        });

        cannot(['create', 'delete'], 'Property');

        can('manage', 'Unit', {
          propertyId: { $in: user.managerProperties },
        });

        cannot(['create', 'delete'], 'Unit');

        can('manage', [
          'BulkConfig',
          'SetpointProfile',
          // 'ZonePolicy',
          'User',
          'Alert',
          'Source',
          'Device',
          'Loop',
          'Thermostat',
          'SmartPowerOutlet',
          'ModbusAirHandler',
          'ThermostatScheduleTemplate',
        ]).because('Already restricted via filtering on server');

        cannot(['create', 'update', 'delete'], 'Source');
        cannot(['create', 'delete'], 'ZonePolicy');
      }

      if (user.isInstaller) {
        can('manage', [
          'BulkConfig',
          'SetpointProfile',
          // 'ZonePolicy',
          'User',
          'Alert',
          'Source',
          'Device',
          'Loop',
          'Thermostat',
          'SmartPowerOutlet',
          'ModbusAirHandler',
        ]).because('Already restricted via filtering on server');

        can(['install', 'read', 'view'], 'Property', {
          _id: { $in: user.installerProperties },
        });

        can(['read', 'view'], 'AlertCategory', {
          propertyId: { $in: user.installerProperties },
        });

        can(['read', 'view'], 'AlertTrigger', {
          propertyId: { $in: user.installerProperties },
        });

        can(
          ['install', 'read', 'view', 'index', 'viewCharts', 'viewSchedule'],
          'Unit',
          {
            propertyId: { $in: user.installerProperties },
          },
        );

        can(['read', 'view'], 'SetpointProfile').because(
          'Already restricted via filtering on server',
        );

        can(['read', 'view'], 'ZonePolicy').because(
          'Already restricted via filtering on server',
        );

        cannot(['reset'], 'SmartPowerOutlet');
      }

      if (user.isResident) {
        can('read', 'Property', { _id: user.residentProperty ?? '0' });
        can('view', 'Property', ['title', 'address.**'], {
          _id: user.residentProperty ?? '0',
        });

        can(['read', 'view'], 'AlertTrigger', {
          propertyId: user.residentProperty,
        });

        can('read', 'Unit', {
          propertyId: user.residentProperty ?? '0',
          _id: user.residentUnit ?? '0',
        });
        can('view', 'Unit', ['name'], {
          propertyId: user.residentProperty ?? '0',
          _id: user.residentUnit ?? '0',
        });
        can(['read', 'view', 'update'], 'Thermostat').because(
          'Already restricted via filtering on server',
        );
      }

      if (user.isViewer) {
        can('view', [
          'SetpointProfile',
          // 'ZonePolicy',
          'User',
          'Alert',
          'Source',
          'Device',
          'Loop',
          'Thermostat',
          'ModbusAirHandler',
          // 'ThermostatScheduleTemplate',
        ]).because('Already restricted via filtering on server');

        can(['read', 'view', 'index'], 'Property', {
          _id: { $in: user.viewerProperties },
        });

        can(['read', 'view'], 'AlertCategory', {
          propertyId: { $in: user.viewerProperties },
        });

        can(['read', 'view'], 'AlertTrigger', {
          propertyId: { $in: user.viewerProperties },
        });

        can(['read', 'view', 'viewCharts', 'viewSchedule', 'index'], 'Unit', {
          propertyId: { $in: user.viewerProperties },
        });

        cannot(['update'], 'Unit');

        can(['read', 'view', 'index'], 'SetpointProfile').because(
          'Already restricted via filtering on server',
        );

        // can(['read', 'view', 'index'], 'ZonePolicy').because(
        //   'Already restricted via filtering on server',
        // );
      }

      can('updateProfile', 'User').because(
        'Already restricted via filtering on server',
      );
      can('read', 'User').because('Already restricted via filtering on server');
      can('view', 'User', [
        'name',
        'email',
        'preferredUnits',
        'preferredAirFlowUnits',
      ]);
      cannot('delete', 'User', { _id: user._id });
    }

    // const routePermissionsFromApi: string[] = [];
    // can(routePermissionsFromApi, "route");
  }
  return build();
}
