import React from 'react';
import { Container } from '@mui/material';

import { ZonePolicyOverrideSelection } from '../../../shared/zone-policies-override-selection';
import { ViewPropertyContentProps } from '../../types/viewPropertyContentProps';
import { ZonePolicy } from '../../../../../types/generated-types';

export function PropertyZonePolicies({ data }: ViewPropertyContentProps) {
  return data ? (
    <Container style={{ marginTop: '20px' }}>
      <ZonePolicyOverrideSelection
        returnLabel="Return to Property List"
        returnPath={`/properties`}
        propertyId={data?._id}
        targetPolicyId={data?.zonePolicyId ?? '0'}
        availablePolicies={(data?.assignableZonePolicies as ZonePolicy[]) ?? []}
        value={data?.zonePolicyId ?? '0'}
      />
    </Container>
  ) : null;
}
