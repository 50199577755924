import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { Notifier } from '../../system/services/notificationManager';
import { updateCacheFromSubscriptionEvent } from '../../../helpers/subscriptionUtils';

import {
  User,
  useUserProfileAlertChangesSubscription,
  useUserProfileQuery,
} from '../../../types/generated-types';

import { SimpleUserForm } from './ui/user-profile-form';
import FullWidthLoadingSkeleton from '../shared/fullWidthLoadingSkeleton';

export interface UserDetailProps {}
export const UserProfile = (_props: UserDetailProps) => {
  const { userId, viewId } = useParams<{ userId: string; viewId: string }>();
  const {
    data: userData,
    loading: userDataLoading,
    error: userDataError,
  } = useUserProfileQuery({
    variables: { userId: userId || '' },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (userDataError) {
      Notifier.error(
        'There was a problem fetching the user information: ',
        userDataError,
      );
    }
  }, [userDataError]);

  useUserProfileAlertChangesSubscription({
    variables: { userId: userId ?? '' },
    // fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  return userDataLoading ? (
    <FullWidthLoadingSkeleton />
  ) : userData?.userById ? (
    <SimpleUserForm user={userData.userById as Partial<User>} viewId={viewId} />
  ) : userId === undefined ? (
    <SimpleUserForm user={{} as Partial<User>} />
  ) : (
    <div>No data</div>
  );
};
