// used for 'Notch' safe top padding:
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

export const useSafePadding = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const safeTopPadding = Math.max(
    Math.abs(
      parseInt(
        getComputedStyle(document.documentElement).getPropertyValue('--sat'),
      ) ?? 22,
    ),
    isSmall ? 0 : 0,
  );
  return {
    safeTopPadding,
  };
};
