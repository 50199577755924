/**
 * SetPoints Component
 * - display the thermostat setpoints at the center of the thermostat screen
 * - allow editing of setpoints if the 'editable' flag is set
 * - temperature changes are reported in deg C via handleChange
 * - increment and decrement are handled according to the 'units' preference
 */

import React from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowDnIcon from '@mui/icons-material/ArrowCircleDown';

import { DisplayValue } from '../../../shared-ui';
import BorderedSection from '../../../../shared/borderedSection';

interface SetPointsProps {
  freshAirSetpoint: Maybe<number>;
  rhSetpoint: Maybe<number>;
  units?: Maybe<string>;
  editable?: boolean;
  handleTargetChange: (
    targetMode: 'freshAirSetpoint' | 'rhSetpoint',
    delta: number,
  ) => void;
}
enum Operation {
  DEC,
  INC,
}

export function SetPoints(props: SetPointsProps) {
  const { freshAirSetpoint, rhSetpoint, units, editable, handleTargetChange } =
    props;

  const handleClick = (
    key: 'freshAirSetpoint' | 'rhSetpoint',
    value: Maybe<number>,
    operation: Operation,
  ) => {
    switch (operation) {
      case Operation.DEC:
        handleTargetChange(key, -1);
        break;
      case Operation.INC:
        handleTargetChange(key, 1);
        break;
      default:
        console.error('[SetPoints] handle-click: unknown operation');
        throw new Error('[SetPoints] handle-click: unknown operation');
    }
  };

  return (
    <BorderedSection
      style={{
        margin: '15px 6px 11px 6px',
        backgroundColor: '#f4f5e6',
      }}
      ccStyle={{
        margin: '4px',
        padding: '10px 0px 16px 0px',
        maxHeight: '152px',
      }}
      title={'Setpoints'}
    >
      <Stack alignItems={'center'} justifyContent={'center'}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {editable && (
            <IconButton
              size="large"
              style={{ padding: '12px 12px 12px 6px' }}
              onClick={() =>
                handleClick(`freshAirSetpoint`, freshAirSetpoint, Operation.DEC)
              }
            >
              <ArrowDnIcon style={{ height: '36px', width: '36px' }} />
            </IconButton>
          )}
          <DisplayValue
            value={freshAirSetpoint}
            units={units}
            label="Fresh Air"
            mode="Heat"
            valueSize="24px"
            unitsSize="20px"
            unitsStyle={{ marginTop: '8px' }}
            labelStyle={{ marginTop: '-5px', marginBottom: '6px' }}
          />
          {editable && (
            <IconButton
              size="large"
              style={{ padding: '12px 6px 12px 12px' }}
              onClick={() =>
                handleClick(`freshAirSetpoint`, freshAirSetpoint, Operation.INC)
              }
            >
              <ArrowUpIcon style={{ height: '36px', width: '36px' }} />
            </IconButton>
          )}
        </Stack>

        <Stack direction={'row'}>
          {editable && (
            <IconButton
              size="large"
              style={{
                marginTop: '6px',
                padding: '12px 12px 12px 6px',
              }}
              onClick={() =>
                handleClick(`rhSetpoint`, rhSetpoint, Operation.DEC)
              }
            >
              <ArrowDnIcon style={{ height: '36px', width: '36px' }} />
            </IconButton>
          )}
          <DisplayValue
            value={rhSetpoint}
            units="%"
            label="Relative Humidity"
            mode="Cool"
            valueSize="24px"
            unitsSize="16px"
            labelStyle={{ marginTop: '-5px', marginBottom: '4px' }}
          />
          {editable && (
            <IconButton
              size="large"
              style={{ padding: '12px 6px 12px 12px' }}
              onClick={() =>
                handleClick(`rhSetpoint`, rhSetpoint, Operation.INC)
              }
            >
              <ArrowUpIcon style={{ height: '36px', width: '36px' }} />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </BorderedSection>
  );
}
