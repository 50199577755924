import { Base, CustomType } from './base';
import {
  INITIAL_RECONNECT_TIMER_DURATION_MILLIS,
  LAST_RECONNECT_TIMER_DURATION_KEY,
  MAXIMUM_RECONNECT_TIMER_DURATION_MILLIS,
} from '../../../helpers/config';
import { Log } from '../services/logger';
import { LocalState } from '../services/localStateManager';

export interface IReconnectAttemptTimer {
  index: number;
  value: number;
}

const initialReconnectTimerValue: IReconnectAttemptTimer = {
  value: INITIAL_RECONNECT_TIMER_DURATION_MILLIS,
  index: -1,
};

/**
 * Class representing the meta info needed for the next re-connection attempt
 * polling in the ConnectionManager (SystemConnectionContext).
 *
 * The value is the number of milliseconds to use as the next polling interval
 * and the index is the number of iterations so far attempted since the last
 * successful connection. Note that the index starts as -1 to act as a 'marker'
 * value indicating that no attempts have yet been made. The initial default
 * delay value is given by an environment variable (16 seconds).
 */
export class ReconnectAttemptTimer
  extends Base
  implements IReconnectAttemptTimer
{
  public value: number;
  public index: number;

  static EJSONTypeName = 'ReconnectAttemptTimer';

  static defaultInstance() {
    return new ReconnectAttemptTimer(initialReconnectTimerValue);
  }

  static reset(): IReconnectAttemptTimer {
    const newVal = ReconnectAttemptTimer.defaultInstance();

    LocalState.setItem(LAST_RECONNECT_TIMER_DURATION_KEY, newVal);

    return newVal;
  }

  static current(): ReconnectAttemptTimer {
    return LocalState.ensureItem<ReconnectAttemptTimer>(
      LAST_RECONNECT_TIMER_DURATION_KEY,
      ReconnectAttemptTimer.defaultInstance(),
    );
  }

  static resetInProgress(): boolean {
    return ReconnectAttemptTimer.current().index > -1;
  }

  static fromJSONValue(deserializedTimer: unknown) {
    try {
      return new ReconnectAttemptTimer(
        deserializedTimer as IReconnectAttemptTimer,
      );
    } catch (error: unknown) {
      Log.error(
        `[Reconnection Attempt Timer] Unable to parse value from storage: ${
          error instanceof Error ? error.message : error
        }.`,
      );
      return ReconnectAttemptTimer.defaultInstance();
    }
  }

  constructor(timer: IReconnectAttemptTimer) {
    super(timer);
    this.value = timer.value ?? INITIAL_RECONNECT_TIMER_DURATION_MILLIS;
    this.index = timer.index ?? -1;
  }

  equals(other: CustomType): boolean {
    return (
      other instanceof ReconnectAttemptTimer &&
      this.value === other.value &&
      this.index === other.index
    );
  }

  toJSONValue(): IReconnectAttemptTimer {
    return Object.assign(super.toJSONValue(), {
      value: this.value,
      index: this.index,
    });
  }

  increment(): ReconnectAttemptTimer {
    const newIndex = this.index + 1;

    const increment = 5 * newIndex * 1000;
    const newValue = this.value + increment;

    if (newValue <= MAXIMUM_RECONNECT_TIMER_DURATION_MILLIS) {
      this.value = newValue;
      this.index = newIndex;

      LocalState.setItem(LAST_RECONNECT_TIMER_DURATION_KEY, this);
    }

    return this;
  }
}

ReconnectAttemptTimer.registerEJSONType();
