import React from 'react';
import { UnitsRouter } from '../../../routes';

/**
 * PropertyInstaller
 * @returns JSX.Element
 * TODO: Nathan: Likely temporary - currently used to route to installer list views.
 */
export function PropertyInstaller() {
  return <UnitsRouter />;
}
