/**
 * DetailPanel
 * - dynamically display the content selected in detail-selector
 * - pass property data as a prop to the selected detail view
 */
import React from 'react';
import { Theme } from '@mui/material/styles';
import { ViewOption } from './unit-schedule-view-options';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MyUnitSchedule } from '../../types/scheduleList';

interface DetailPanelProps {
  theme: Theme;
  viewOption: ViewOption;
  data?: MyUnitSchedule;
}
export function UnitScheduleDetailPanel(props: DetailPanelProps) {
  const { data, theme } = props;
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const Component = props.viewOption.view;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isLarge ? 'row' : 'column',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      <Component data={data} theme={theme} />
    </div>
  );
}
