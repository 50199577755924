import React, { ReactElement, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { Form, Formik, FormikHelpers, useFormik } from 'formik';
import * as yup from 'yup';

import { useAuthenticator } from '../../../auth/AuthenticationContext';
import { Notifier } from '../../../system/services/notificationManager';
import { useInjectableComponents } from '../../../system/services/injectableComponentsManager';

import { Button, Typography } from '@mui/material';

import {
  CreateUserMutationVariables,
  UpdateUserMutationVariables,
  useCreateUserMutation,
  User,
  useUpdateUserMutation,
} from '../../../../types/generated-types';

import { FormValues } from '../types/userDetailFormValues';

import { UserGeneralTab } from '../components/user-detail-general-tab';
import { UserManagerTab } from '../components/user-detail-manager-tab';
import { UserInstallerTab } from '../components/user-detail-installer-tab';
import { UserResidentTab } from '../components/user-detail-resident-tab';
import { UserViewerTab } from '../components/user-detail-viewer-tab';

import { ActivityIndicator } from '../../shared/activityIndicator';
import {
  UserDetailSelector,
  FormViewOption,
  FormViewOptionKey,
} from './user-detail-selector';

const defaultFormViewOption: FormViewOption = {
  name: 'General',
  value: 'general',
};

const formViewOptions: FormViewOption[] = [
  defaultFormViewOption,
  {
    name: 'Manager',
    value: 'manager',
  },
  {
    name: 'Installer',
    value: 'installer',
  },
  {
    name: 'Resident',
    value: 'resident',
  },
  {
    name: 'Viewer',
    value: 'viewer',
  },
];

export const UserDetailForm = (params: {
  user: Partial<User>;
}): ReactElement => {
  const { user } = params;
  const navigate = useNavigate();
  const auth = useAuthenticator();

  const currentLocation = useLocation();

  const { setPrimaryBottomNavigationWidget } = useInjectableComponents();

  const [
    updateUser,
    // TODO: Loading_error: should we handle loading/error for this mutation?
    // { data: updatedUser, loading: updatedUserLoading, error: updateUserError },
  ] = useUpdateUserMutation();
  const [
    createUser,
    // TODO: Loading_error: should we handle loading/error for this mutation?
    // { data: createdUser, loading: createUserLoading, error: createUserError },
  ] = useCreateUserMutation();

  const initialValues: FormValues = {
    name: user.name || '',
    email: user.email || '',
    mobile: user.mobile || '',
    status: user.state || 'new',
    isSuper: !!user.legacyAuthorizationProfile?.isSuper,
    isManager: !!user.legacyAuthorizationProfile?.isManager,
    isInstaller: !!user.legacyAuthorizationProfile?.isInstaller,
    isResident: !!user.legacyAuthorizationProfile?.isResident,
    isViewer: !!user.legacyAuthorizationProfile?.isViewer,
    managerProperties: (user.legacyAuthorizationProfile?.managerProperties ||
      []) as string[],
    installerProperties: (user.legacyAuthorizationProfile
      ?.installerProperties || []) as string[],
    residentProperty:
      user.legacyAuthorizationProfile?.residentProperty || undefined,
    residentUnit: user.legacyAuthorizationProfile?.residentUnit || undefined,
    viewerProperties: (user.legacyAuthorizationProfile?.viewerProperties ||
      []) as string[],
    roleSelected:
      user.legacyAuthorizationProfile?.isSuper ||
      user.legacyAuthorizationProfile?.isManager ||
      user.legacyAuthorizationProfile?.isInstaller ||
      user.legacyAuthorizationProfile?.isResident ||
      false,
    smsOptInStatus: user.smsOptInStatus || 'unsent',
    smsOptInStatusTimestamp: user.smsOptInStatusTimestamp || undefined,
  };

  const onSubmit = (
    values: FormValues,
    _helpers: FormikHelpers<FormValues>,
  ) => {
    if (user._id) {
      const userUpdateFields: UpdateUserMutationVariables['user'] = {
        profile: { name: values.name },
        emails: [{ address: values.email }],
        state: values.status,
      };
      const profileUpdateFields: UpdateUserMutationVariables['profile'] = {
        isSuper: values.isSuper,
        isManager: values.isManager,
        isInstaller: values.isInstaller,
        isResident: values.isResident,
        isViewer: values.isViewer,
        managerProperties: values.isManager ? values.managerProperties : [],
        installerProperties: values.isInstaller
          ? values.installerProperties
          : [],
        residentProperty: values.isResident ? values.residentProperty : '',
        residentUnit: values.isResident ? values.residentUnit : '',
        viewerProperties: values.isViewer ? values.viewerProperties : [],
      };

      updateUser({
        variables: {
          userId: user._id,
          user: userUpdateFields,
          profile: profileUpdateFields,
        },
      })
        .then(() => {
          navigate('/users');
        })
        .catch((updateError) => {
          formik.setSubmitting(false);
          Notifier.error(updateError.message);
        });
    } else {
      const userCreateFields: CreateUserMutationVariables['user'] = {
        profile: { name: values.name },
        emails: [{ address: values.email }],
        state: values.status,
      };
      const profileCreateFields: CreateUserMutationVariables['profile'] = {
        isSuper: values.isSuper,
        isManager: values.isManager,
        isInstaller: values.isInstaller,
        isResident: values.isResident,
        isViewer: values.isViewer,
        managerProperties: values.isManager ? values.managerProperties : [],
        installerProperties: values.isInstaller
          ? values.installerProperties
          : [],
        residentProperty: values.isResident ? values.residentProperty : '',
        residentUnit: values.isResident ? values.residentUnit : '',
        viewerProperties: values.isViewer ? values.viewerProperties : [],
      };

      createUser({
        variables: {
          user: userCreateFields,
          profile: profileCreateFields,
        },
      })
        .then(() => {
          formik.setSubmitting(false);
          /* TODO: Peter: display instructions on Notifier */
          Notifier.success(
            'A user account has been added.  A welcome email has been sent.',
          );
          navigate('/users');
        })
        .catch((createError) => {
          formik.setSubmitting(false);
          Notifier.error(createError.message);
        });
    }
  };

  const validationSchema = yup
    .object({
      name: yup.string().required('Name is required'),
      mobile: yup.string(),
      email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required'),
      status: yup.string().required('User status is required').default('new'),
      isSuper: yup.boolean(),
      isResident: yup.boolean(),
      isManager: yup.boolean(),
      isInstaller: yup.boolean(),
      isViewer: yup.boolean(),
      roleSelected: yup.boolean(),
      managerProperties: yup.array().when('isManager', {
        is: (isManager: boolean) => isManager,
        then: yup
          .array()
          .min(
            1,
            'Select at least one property for this user to manage or remove the manager role.',
          ),
      }),
      installerProperties: yup.array().when('isInstaller', {
        is: (isInstaller: boolean) => isInstaller,
        then: yup
          .array()
          .min(
            1,
            'Select at least one property for this user to install or remove the installer role.',
          ),
      }),
      residentProperty: yup.string().when('isResident', {
        is: (isResident: boolean) => isResident,
        then: yup
          .string()
          .required('You must select a property or remove the resident role.')
          .not(
            ['none', 'None', ''],
            'You must select a property or remove the resident role.',
          ),
      }),
      residentUnit: yup.string().when('isResident', {
        is: (isResident: boolean) => isResident,
        then: yup
          .string()
          .required('You must select a unit or remove the resident role.'),
      }),
      viewerProperties: yup.array().when('isViewer', {
        is: (isViewer: boolean) => isViewer,
        then: yup
          .array()
          .min(
            1,
            'Select at least one property for this view to view or remove the viewer role.',
          ),
      }),
    })
    .test('mustSelectRole', 'Must select at least one role', (obj) => {
      if (
        obj.isSuper ||
        obj.isManager ||
        obj.isResident ||
        obj.isInstaller ||
        obj.isViewer
      ) {
        return true; // everything is fine
      }

      return new yup.ValidationError(
        'Please select at least one role',
        null,
        'roleSelected',
      );
    })
    .test(
      'cannotSelectInstallerRole',
      'Cannot combine installer with other roles',
      (obj) => {
        if (
          obj.isInstaller &&
          (obj.isSuper || obj.isManager || obj.isResident || obj.isViewer)
        ) {
          return new yup.ValidationError(
            'Cannot combine installer role with any other role',
            null,
            'roleSelected',
          );
        } else {
          return true;
        }
      },
    );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const [value, setValue] = React.useState<string>('general');

  useEffect(() => {
    const errors: FormViewOptionKey[] = [];
    if (formik.errors.roleSelected) {
      errors.push('General');
    }
    if (formik.errors.managerProperties) {
      errors.push('Manager');
    }
    if (formik.errors.installerProperties) {
      errors.push('Installer');
    }
    if (formik.errors.residentProperty || formik.errors.residentUnit) {
      errors.push('Resident');
    }
    if (formik.errors.viewerProperties) {
      errors.push('Viewer');
    }
    const keys: string[] = ['General'];

    if (formik.values.isViewer) {
      keys.push('Viewer');
    }
    if (formik.values.isResident) {
      keys.push('Resident');
    }
    if (formik.values.isManager) {
      keys.push('Manager');
    }
    if (formik.values.isInstaller) {
      keys.push('Installer');
    }

    const options = formViewOptions.filter((option) =>
      keys.includes(option.name),
    );

    const myBottomMenu = (
      <UserDetailSelector options={options} errors={errors} />
    );
    setPrimaryBottomNavigationWidget(myBottomMenu);

    return () => {
      setPrimaryBottomNavigationWidget(undefined);
    };
  }, [formik]);

  useEffect(() => {
    if (currentLocation) {
      const { pathname } = currentLocation;
      const pathComponent = pathname.split('/').slice(0, 5);

      if (pathComponent.length < 5) {
        navigate(`${pathname}${pathname.endsWith('/') ? '' : '/'}tab/general`, {
          replace: true,
        });
      }
      const newVal = pathComponent[4] ?? 'general';

      switch (newVal) {
        case 'general':
          setValue('general');
          break;
        case 'manager':
          if (formik.values.isManager) {
            setValue('manager');
          } else {
            navigate(`${pathname}/tab/general`);
          }
          break;
        case 'viewer':
          if (formik.values.isViewer) {
            setValue('viewer');
          } else {
            navigate(`${pathname}/tab/general`);
          }
          break;
        case 'installer':
          if (formik.values.isInstaller) {
            setValue('installer');
          } else {
            navigate(`${pathname}/tab/general`);
          }
          break;
        case 'resident':
          if (formik.values.isResident) {
            setValue('resident');
          } else {
            navigate(`${pathname}/tab/general`);
          }
          break;
        default:
          navigate(`${pathname}/tab/general`);
          break;
      }
    }
  }, [currentLocation]);

  return auth.user ? (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h3" sx={{ fontSize: '1.4rem', margin: '1rem' }}>
        {user._id ? 'Edit' : 'Create'} User
      </Typography>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form
          style={{ width: '100%', fontSize: '16px', height: '100%' }}
          onSubmit={formik.handleSubmit}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <ActivityIndicator activityInProgress={formik.isSubmitting} />
            <div
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                flexGrow: 1,
                overflow: 'auto',
                paddingTop: '6px',
              }}
            >
              {value === 'general' ? (
                <UserGeneralTab
                  currentUser={auth.user}
                  formik={formik}
                  apiToken={user.legacyAuthorizationProfile?.apiToken}
                  onChange={(e) => {
                    formik.setFieldTouched((e.target as any).id, true);
                    formik.handleChange(e);
                  }}
                />
              ) : value === 'manager' ? (
                <UserManagerTab
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldTouched((e.target as any).id, true);
                    formik.handleChange(e);
                  }}
                />
              ) : value === 'installer' ? (
                <UserInstallerTab
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldTouched((e.target as any).id, true);
                    formik.handleChange(e);
                  }}
                />
              ) : value === 'resident' ? (
                <UserResidentTab
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldTouched((e.target as any).name, true);
                    formik.handleChange(e);
                  }}
                />
              ) : value === 'viewer' ? (
                <UserViewerTab
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldTouched((e.target as any).name, true);
                    formik.handleChange(e);
                  }}
                />
              ) : null}
            </div>
            <div
              style={{
                marginTop: '10px',
                marginLeft: '10px',
                paddingBottom: '75px',
                paddingLeft: '24px',
              }}
            >
              <Button
                disabled={!formik.isValid || !formik.dirty}
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ color: '#fff' }}
              >
                Save
              </Button>
              <Button
                sx={{ marginLeft: '10px' }}
                variant="text"
                onClick={() => navigate('/users')}
                color={
                  !formik.isValid || !formik.dirty ? 'secondary' : 'primary'
                }
              >
                Cancel
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  ) : (
    <div>Unauthorized</div>
  );
};
