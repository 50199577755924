import React from 'react';

/* COMPONENTS */
import { PropertySummary } from '../components/property-summary';
import { PropertyUnits } from '../components/property-units';
import { PropertyInstaller } from '../components/property-installer';
import { PropertyScheduleList } from '../components/property-schedule-list';
import { PropertyBuildings } from '../components/property-buildings';
import {
  BottomMenuItem,
  BottomMenuItemGroup,
} from '../../../system/services/injectableComponentsManager';

/* ICONS */
import ScheduleIcon from '@mui/icons-material/CalendarMonth';
import ReportProblem from '@mui/icons-material/ReportProblem';
import ListIcon from '@mui/icons-material/List';
import ConstructionIcon from '@mui/icons-material/Construction';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { Actions } from '../../../auth/types/ability';
import { Edit, Visibility, Help } from '@mui/icons-material';

export interface ViewOption {
  view: (props: any) => JSX.Element;
  icon: React.ReactElement;
  key: string;
  label: string;
  role: Actions;
}

const viewOptionsMap: ViewOption[] = [
  {
    key: 'summary',
    view: PropertySummary,
    icon: <SummarizeOutlinedIcon fontSize="medium" />,
    label: 'Summary Report',
    role: 'view',
  },
  {
    key: 'units',
    view: PropertyUnits,
    icon: <ListIcon fontSize="medium" />,
    label: 'Unit List',
    role: 'view',
  },
  {
    key: 'buildings',
    view: PropertyBuildings,
    icon: <ApartmentOutlinedIcon fontSize="medium" />,
    label: 'Building List',
    role: 'view',
  },
  {
    key: 'installer',
    view: PropertyInstaller,
    icon: <ConstructionIcon fontSize="medium" />,
    label: 'Installer Tools',
    role: 'install',
  },
  {
    key: 'schedule',
    view: PropertyScheduleList,
    icon: <ScheduleIcon fontSize="medium" />,
    label: 'Schedule',
    role: 'view',
  },
];

export const basePropertyMenuItems = (
  id: string | undefined | null,
  pathname: string,
  extraActions: BottomMenuItem[] = [],
): BottomMenuItemGroup[] => {
  const items: BottomMenuItemGroup[] = [];
  const subItems: BottomMenuItem[] = viewOptionsMap.reduce(
    (options, option) => {
      if (!pathname.includes(option.key)) {
        options.push({
          id: `prop-nav-menu-item-${option.key}`,
          label: option.label,
          icon: option.icon,
          navTarget:
            option.key !== 'buildings'
              ? `/properties/${id}/${option.key}`
              : pathname.includes('units')
                ? pathname.replace('units', 'installer')
                : pathname.includes('installer')
                  ? pathname.replace('installer', 'units')
                  : `/properties/${id}/${option.key}`,
          isVisible: (path: string) => !path.includes(option.key),
          permit: option.role,
        });
      }
      return options;
    },
    [] as BottomMenuItem[],
  );

  if (id) {
  }

  if (subItems.length > 0) {
    items.push({
      label: 'Property Reports & Tools',
      items: subItems,
    });
  }

  items.push({
    label: 'Alert Management',
    items: [
      {
        id: `prop-nav-menu-item-alerts`,
        label: 'Active Alert List',
        icon: <ReportProblem color="warning" />,
        navTarget: pathname.includes('units')
          ? pathname.replace('units', 'installer')
          : pathname.includes('installer')
            ? pathname.replace('installer', 'units')
            : `/properties/${id}/alerts`,
        isVisible: (path: string) => !path.includes('alerts'),
        permit: 'view',
      },
      // TODO: Peter: Alerts: add this back in when we enhance the trigger editing feature
      // {
      //   id: `prop-nav-menu-item-alert-triggers`,
      //   label: 'Define Alert Triggers',
      //   icon: <ReportProblem color="warning" />,
      //   navTarget: pathname.includes('units')
      //     ? pathname.replace('units', 'installer')
      //     : pathname.includes('installer')
      //     ? pathname.replace('installer', 'units')
      //     : `/properties/${id}/alert-triggers`,
      //   isVisible: (path: string) => !path.includes('alert-triggers'),
      //   permit: 'view',
      // },
    ],
  });

  if (id) {
    const propActions: BottomMenuItem[] = [];
    if (!pathname.includes('view-property')) {
      propActions.push({
        id: 'view-property-menu-item',
        label: 'View Property Details',
        icon: <Visibility fontSize="small" />,
        navTarget: (path: string) => {
          const pathComponent = path.split('/').slice(0, 4);
          return pathComponent.slice(0, 3).join('/') + '/view-property';
        },
        permit: 'view',
      });
    }
    if (!pathname.includes('edit-property')) {
      propActions.push({
        id: 'edit-property-menu-item',
        label: 'Edit Property Details',
        icon: <Edit fontSize="small" />,
        navTarget: `/properties/${id}/edit-property`,
        permit: 'update',
      });
    }
    if (extraActions) {
      extraActions.forEach((action) => {
        propActions.push(action);
      });
    }
    if (propActions.length > 0) {
      items.push({
        label: 'Property Actions',
        items: propActions,
      });
    }

    const helpActions: BottomMenuItem[] = [];
    // if (pathname.includes('edit-property')) {
    //   helpActions.push({
    //     id: 'pendo-help-property-alerts',
    //     label: 'Alerts Help',
    //     icon: <Help fontSize="small" />,
    //     permit: 'update',
    //   });
    // }
    if (helpActions.length > 0) {
      items.push({
        label: 'Help',
        items: helpActions,
      });
    }
  }
  return items;
};

export function getBasePropertyMenuItems(
  pathname: string,
  id: string | undefined,
) {
  const items: BottomMenuItem[] = viewOptionsMap.reduce((options, option) => {
    if (
      !pathname.includes(option.key)
      // && can('view', )
    ) {
      options.push({
        id: `prop-nav-menu-item-${option.key}`,
        label: option.label,
        icon: option.icon,
        navTarget: `/properties/${id}/${option.key}`,
        isVisible: (path: string) => !path.includes(option.key),
        // action: () => {
        //   navigate(`/properties/${id}/${option.key}`);
        // },
        permit: option.role,
      });
    }
    return options;
  }, [] as BottomMenuItem[]);

  return items;
}
