/**
 * DetailPanel
 * - dynamically display the content selected in detail-selector
 * - pass property data as a prop to the selected detail view
 */
import React from 'react';
import { Theme } from '@mui/material/styles';
import { MyProperty, MyUnit } from '../unit-view';
import { ViewOption } from './unit-view-detail-selector';
import useMediaQuery from '@mui/material/useMediaQuery';

interface DetailPanelProps {
  theme: Theme;
  viewOption: ViewOption;
  unit?: MyUnit;
  property?: MyProperty;
}
export function UnitViewDetailPanel(props: DetailPanelProps) {
  const { unit, property, theme } = props;
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const Component = props.viewOption.view;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isLarge ? 'row' : 'column',
        flex: 1,
        alignItems: 'stretch',
        paddingTop: isLarge ? '4px' : '12px',
      }}
    >
      <Component unit={unit} property={property} theme={theme} />
    </div>
  );
}
