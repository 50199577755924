import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

/* MUI */
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';

/* Icons */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

/* Local */
import { MyProperty } from '../property-detail';
import {
  convertToTempUnits,
  formatTemperatureString,
} from '../../devices/util/convert-temp-units';
import {
  usePropertySummaryUnitsQuery,
  usePropertySummaryAlertsQuery,
  usePropertySummaryDevicesQuery,
  usePropertySummaryStatsQuery,
  PropertySummaryUnits,
  PropertySummaryAlerts,
  PropertySummaryDevices,
  PropertySummaryStats,
  Property,
} from '../../../../types/generated-types';
import { Notifier } from '../../../system/services/notificationManager';
import { useAuthenticator } from '../../../auth/AuthenticationContext';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../../system/services/injectableComponentsManager';
import { basePropertyMenuItems } from '../ui/base-property-context-menu';
import BorderedSection from '../../shared/borderedSection';
import FullWidthLoadingSkeleton from '../../shared/fullWidthLoadingSkeleton';

type IconColor = SvgIconTypeMap['props']['color'];

interface PropertySummaryProps {
  theme: Theme;
  data?: MyProperty;
}

/**
 * General property-wide overview and statistics
 */
export function PropertySummary(props: PropertySummaryProps) {
  const { theme, data } = props;
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const {
    setContextMenuItems,
    setSubtitle,
    setTitle,
    setTitlePath,
    setSubtitlePath,
  } = useInjectableComponents();
  const { pathname } = useLocation();

  useEffect(() => {
    if (data?._id) {
      const contextMenuItems: BottomMenuItems = [
        ...basePropertyMenuItems(data._id, pathname),
      ];

      setTitle(data.title ?? 'Unnamed Property');
      setTitlePath(`/properties`);

      setSubtitle('Property Summary');
      setSubtitlePath(`/properties/${data?._id}/units`);

      setContextMenuItems(contextMenuItems, data as Property);
    }
    return () => {
      setTitle(undefined);
      setTitlePath(undefined);
      setSubtitle(undefined);
      setSubtitlePath(undefined);
      setContextMenuItems(undefined);
    };
  }, [
    data,
    pathname,
    setContextMenuItems,
    setSubtitle,
    setSubtitlePath,
    setTitle,
    setTitlePath,
  ]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: isLarge ? 1 : 'unset',
        alignItems: 'stretch',
        backgroundColor: '#edfcf4',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: isLarge ? 'row' : 'column',
          flexWrap: isLarge ? 'wrap' : 'unset',
          flex: 1,
          gap: '10px',
          justifyContent: 'start',
          padding: isLarge ? '10px 6px 6px 6px' : '0px 6px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: isLarge ? '1 1 auto' : 'unset',
            flexDirection: isLarge ? 'column' : 'row',
          }}
        >
          <UnitsSummary {...props} isLarge={isLarge} />
        </div>
        <div
          style={{
            display: 'flex',
            flex: isLarge ? '1 1 auto' : 'unset',
            flexDirection: isLarge ? 'column' : 'row',
          }}
        >
          <AlertSummary {...props} isLarge={isLarge} />
        </div>
        <div
          style={{
            display: 'flex',
            flex: isLarge ? '1 1 auto' : 'unset',
            flexDirection: isLarge ? 'column' : 'row',
          }}
        >
          <PropertyStats {...props} isLarge={isLarge} />
        </div>
      </div>
    </div>
  );
}

export interface PropertySummarySectionProps extends PropertySummaryProps {
  isLarge: boolean;
}

function UnitsSummary(props: PropertySummarySectionProps) {
  const { data, isLarge } = props;
  const propertyId = data?._id || '';
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [units, setUnits] = useState<PropertySummaryUnits>();

  const { loading } = usePropertySummaryUnitsQuery({
    variables: {
      propertyId,
    },
    skip: !propertyId,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (data.propertySummaryUnits) {
        setUnits(data.propertySummaryUnits);
      }
    },
    onError(error) {
      console.error(error);
      Notifier.error('Error loading unit summary info');
    },
  });

  const residential = units?.units[0];
  const commonArea = units?.units[1];
  const centralEquipment = units?.units[2];

  return loading ? (
    <FullWidthLoadingSkeleton />
  ) : (
    <Card
      sx={{
        backgroundColor: '#edfcf4',
        display: 'flex',
        flexDirection: 'column',
        flex: isLarge ? 'unset' : 1,
        margin: isLarge ? '0px 0px 0px 0px' : '4px 0px 0px 0px',
        '& .MuiCardActions-root': { margin: '0px', padding: '4px' },
      }}
    >
      <CardActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'stretch',
        }}
      >
        <BorderedSection
          title="View Filtered Unit List"
          style={
            isLarge
              ? { backgroundColor: 'white', marginTop: '4px' }
              : {
                  margin: '4px 0px 0px 0px',
                  padding: '0px',
                  backgroundColor: 'white',
                }
          }
          ccStyle={
            isLarge
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  padding: '4px 0px 16px',
                }
              : {
                  margin: '-2px 0px 4px 0px',
                  padding: '0px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                }
          }
        >
          <Button
            onClick={() =>
              navigate(
                pathname.replace('summary', 'units?unitType=residential'),
              )
            }
            color="secondary"
            //fullWidth
            variant="contained"
            disabled={(residential?.count ?? 0) === 0}
            aria-label="residential-units"
            sx={{ color: '#fff' }}
          >
            Residential
          </Button>
          <Button
            onClick={() =>
              navigate(pathname.replace('summary', 'units?unitType=commonArea'))
            }
            color="secondary"
            //fullWidth
            variant="contained"
            disabled={(commonArea?.count ?? 0) === 0}
            aria-label="common-units"
            sx={{ color: '#fff' }}
          >
            Common
          </Button>
          <Button
            onClick={() =>
              navigate(
                pathname.replace('summary', 'units?unitType=centralEquipment'),
              )
            }
            color="secondary"
            //fullWidth
            variant="contained"
            disabled={(centralEquipment?.count ?? 0) === 0}
            aria-label="central-units"
            sx={{ color: '#fff' }}
          >
            Central
          </Button>
        </BorderedSection>
      </CardActions>
    </Card>
  );
}

/* Alerts Summary Card */
function AlertSummary(props: PropertySummarySectionProps) {
  const { data, isLarge } = props;
  const propertyId = data?._id || '';
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navToAlerts: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      navigate(pathname.replace('summary', 'alerts'));
    },
    [pathname, navigate],
  );

  const [alerts, setAlerts] = useState<PropertySummaryAlerts>();

  const { loading } = usePropertySummaryAlertsQuery({
    variables: {
      propertyId,
    },
    skip: !propertyId,
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000, // 1m
    onCompleted(data) {
      if (data.propertySummaryAlerts) {
        setAlerts(data.propertySummaryAlerts);
      }
    },
    onError(error) {
      console.error(error);
      Notifier.error('Error loading alert summary info');
    },
  });

  const alertsCount =
    alerts?.alerts.reduce((sum, current) => sum + current.count, 0) || 0;
  const hasAlerts = alertsCount > 0;
  const subheader = hasAlerts ? `${alertsCount}` : 'None';

  return (
    <Card
      sx={{
        backgroundColor: '#edfcf4',
        display: 'flex',
        flexDirection: 'column',
        flex: isLarge ? 'unset' : 1,
        '& .MuiCardContent-root': {
          border: 'none',
          padding: '0px !important',
          margin: isLarge ? '10px 0px 0px 0px' : '2px 0px 0px 0px',
        },
      }}
    >
      <CardContent>
        <BorderedSection
          style={{ margin: '4px', backgroundColor: 'white' }}
          ccStyle={{ marginTop: '-6px', paddingBottom: '0px' }}
          title={`Alerts (${subheader})`}
        >
          {loading && (
            <>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </>
          )}
          <List sx={{ ml: 2 }}>
            {alerts?.alerts
              .filter((alert) => alert.label !== 'Devices Not Installed')
              .map((alert) => {
                return (
                  <div
                    key={alert.label}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 2,
                    }}
                  >
                    {alert.count === 0 ? (
                      <>
                        <CheckCircleIcon
                          color={(alert.color as IconColor) || 'success'}
                          sx={{ marginRight: 1 }}
                        />
                        <span>{alert.label}</span>
                      </>
                    ) : (
                      <>
                        <WarningIcon
                          color={(alert.color as IconColor) || 'warning'}
                          sx={{ marginRight: 1 }}
                        />
                        <Link
                          state={alert.options}
                          style={{
                            fontWeight: 'bold',
                            textDecoration: 'none',
                            color: '#000',
                          }}
                          to={alert.action ?? ''}
                          // onClick={() => {
                          //   console.log(
                          //     '[LINK-CLICK] action, options:',
                          //     alert.action,
                          //     alert.options,
                          //   );
                          //   navigate(alert.action ?? '', {
                          //     state: alert.options,
                          //   });
                          // }}
                        >
                          <span style={{ fontWeight: 'bold', marginRight: 4 }}>
                            {alert.count}
                          </span>
                          <span>{alert.label}</span>
                        </Link>
                      </>
                    )}
                  </div>
                );
              })}
          </List>

          {/*</Collapse>*/}
          {hasAlerts && (
            <CardActions
              sx={{
                p: 2,
                pt: isLarge ? 1 : 0,
                pb: 0,
                mb: isLarge ? '12px' : '6px',
                mt: 0,
              }}
            >
              <Button
                onClick={navToAlerts}
                fullWidth
                color="secondary"
                variant="contained"
                sx={{ color: '#fff' }}
              >
                View alerts
              </Button>
            </CardActions>
          )}
        </BorderedSection>
      </CardContent>
    </Card>
  );
}

/* Property Statistics Card */
function PropertyStats(props: PropertySummarySectionProps) {
  const { data, isLarge } = props;
  const propertyId = data?._id || '';
  const [devices, setDevices] = useState<PropertySummaryDevices>();
  const [stats, setStats] = useState<PropertySummaryStats>();
  const { user } = useAuthenticator();

  const { loading: devicesLoading } = usePropertySummaryDevicesQuery({
    variables: {
      propertyId,
    },
    skip: !propertyId,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (data.propertySummaryDevices) {
        setDevices(data.propertySummaryDevices);
      }
    },
    onError(error) {
      console.error(error);
      Notifier.error('Error loading devices summary info');
    },
  });

  const { loading: statsLoading } = usePropertySummaryStatsQuery({
    variables: {
      propertyId,
    },
    skip: !propertyId,
    fetchPolicy: 'cache-and-network',
    pollInterval: 300000, // 5m
    onCompleted(data) {
      if (data.propertySummaryStats) {
        setStats(data.propertySummaryStats);
      }
    },
    onError(error) {
      console.error(error);
      Notifier.error('Error loading stats summary info');
    },
  });

  const loading = devicesLoading || statsLoading;

  const { residentialDevices, centralDevices } = devices || {};
  const hasResidentialDevices =
    residentialDevices && residentialDevices.length > 0;
  const hasCentralDevices = centralDevices && centralDevices.length > 0;

  const { temperature, humidity } = stats || {};
  const showStats = temperature || humidity;

  function formatTemp(tempInC: number) {
    const displayUnits = user?.preferredUnits || 'F';
    const temp = convertToTempUnits(tempInC, displayUnits, 'C', 0) ?? 0;
    return formatTemperatureString(temp, displayUnits);
  }

  function formatHumidity(humidity: number) {
    return Math.round(humidity) + '%';
  }

  return (
    <Card
      sx={{
        backgroundColor: '#edfcf4',
        display: 'flex',
        flexDirection: 'column',
        flex: isLarge ? 'unset' : 1,
        marginBottom: '30px',
        '& .MuiCardContent-root': {
          border: 'none',
          padding: '0px ',
          margin: isLarge ? '10px 0px 0px 0px' : '2px 0px 0px 0px',
        },
      }}
    >
      <CardContent>
        <BorderedSection
          style={{ margin: '4px', backgroundColor: 'white' }}
          ccStyle={{
            paddingTop: isLarge ? '4px' : '0px',
            paddingBottom: isLarge ? '16px' : '4px',
          }}
          title={'Summary Statistics'}
        >
          {loading && (
            <>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </>
          )}
          {hasResidentialDevices && (
            <>
              <Typography color="GrayText">
                Residential and Common Area Devices
              </Typography>
              <List sx={{ ml: 2 }}>
                {residentialDevices.map((device) => {
                  return (
                    <div key={device.label}>
                      <span style={{ fontWeight: 'bold' }}>{device.count}</span>
                      <span> {device.label}</span>
                    </div>
                  );
                })}
              </List>
            </>
          )}
          {hasCentralDevices && (
            <>
              <Typography color="GrayText">Central Equipment</Typography>
              <List sx={{ ml: 2 }}>
                {centralDevices.map((device) => {
                  return (
                    <div key={device.label}>
                      <span style={{ fontWeight: 'bold' }}>{device.count}</span>
                      <span> {device.label}</span>
                    </div>
                  );
                })}
              </List>
            </>
          )}
          {showStats && (
            <>
              {isLarge ? <Divider sx={{ mb: isLarge ? 2 : 1, mt: 1 }} /> : null}
              {temperature && (
                <>
                  <Typography color="GrayText">Temperature</Typography>
                  <ListItem sx={{ paddingTop: isLarge ? '8px' : '2px' }}>
                    <Box sx={{ marginRight: 2 }}>
                      {`${isLarge ? 'Average' : 'Avg'}: ${formatTemp(
                        temperature.ave,
                      )}`}
                    </Box>
                    <Box sx={{ marginRight: 2 }}>
                      Min: {formatTemp(temperature.min)}
                    </Box>
                    <Box>Max: {formatTemp(temperature.max)}</Box>
                  </ListItem>
                </>
              )}
              {humidity && (
                <>
                  <Typography color="GrayText">Humidity</Typography>
                  <ListItem sx={{ paddingTop: isLarge ? '8px' : '2px' }}>
                    <Box sx={{ marginRight: 2 }}>
                      {`${isLarge ? 'Average' : 'Avg'}: ${formatHumidity(
                        humidity.ave,
                      )}`}
                    </Box>
                    <Box sx={{ marginRight: 2 }}>
                      Min: {formatHumidity(humidity.min)}
                    </Box>
                    <Box>Max: {formatHumidity(humidity.max)}</Box>
                  </ListItem>
                </>
              )}
            </>
          )}
        </BorderedSection>
      </CardContent>
      {/*</Collapse>*/}
    </Card>
  );
}
