import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { Warehouse } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Building,
  useBuildingDetailEditQuery,
} from '../../../types/generated-types';
import { useParams } from 'react-router-dom';
import { Notifier } from '../../system/services/notificationManager';

export function PropertyBuildingDetail() {
  const { bid } = useParams<{ bid: string }>();
  const {
    data,
    error,
    // TODO: Loading_error: handle loading state.
    // loading,
  } = useBuildingDetailEditQuery({
    variables: { bid: bid ?? '' },
  });
  useEffect(() => {
    if (error) {
      Notifier.error(
        'There was a problem fetching building information: ',
        error,
      );
    }
  }, [error]);
  const [building, setBuilding] = useState<Partial<Building>>();
  useEffect(() => {
    if (data?.buildingById) {
      setBuilding(data.buildingById);
    }
  }, [data]);

  const name = building?.name ?? '';
  const number = building?.number ?? '';

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe">
            <Warehouse />
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={name}
        subheader={`Building: ${number}`}
      />
    </Card>
  );
}
