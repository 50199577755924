/**
 * FilterButtons
 * - Provides options for filtering units on the units list page
 * - Includes tabs to switch between 'Unit Types'
 * - Multi-Select component used to select by 'Building' or 'Floor'
 */
import React from 'react';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { PropertyBuildingsQuery } from '../../../../types/generated-types';
import {
  EnabledUnitTypes,
  UnitTypes,
  UnitType,
  UnitTypeName,
} from '../types/unitListTypes';
import pluralize from 'pluralize';
import './filter-buttons.css';
import { MultiSelect } from '../../../ui/shared/multi-select';

interface FilterButtonsProps {
  theme: Theme;
  category: UnitTypes;
  enabledCategories: EnabledUnitTypes;
  selectedCategory: UnitType;
  buildings: PropertyBuildingsQuery['propertyById'];
  selectedBuildingIds: string[];
  selectedFloorIds: string[];
  handleSelect: (t: UnitTypeName) => void;
  handleBuildingSelect: (b: string[]) => void;
  handleFloorSelect: (f: string[]) => void;
}

type Building = Exclude<
  Exclude<
    PropertyBuildingsQuery['propertyById'],
    null | undefined
  >['buildings'][0],
  null | undefined
>;

type Floor = Exclude<
  Exclude<Building, null | undefined>['floors'][0],
  null | undefined
>;

export type SelectableFloor = Required<Floor>;

export type SelectableBuilding = {
  __typename: 'Building';
  _id: string;
  name: string;
  number?: number;
  floors: SelectableFloor[];
};

export function FilterButtons(props: FilterButtonsProps) {
  const {
    theme,
    category,
    enabledCategories,
    selectedCategory,
    buildings,
    selectedBuildingIds,
    selectedFloorIds,
    handleSelect,
    handleBuildingSelect,
    handleFloorSelect,
  } = props;

  const [screenBreakLevel, setScreenBreakLevel] = React.useState<number>(0);
  const [isSmall, setIsSmall] = React.useState(false);

  const _isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    const handleResize = () => {
      if (_isSmall) {
        setScreenBreakLevel(4);
      } else if (window.innerWidth < 1000) {
        setScreenBreakLevel(3);
      } else if (window.innerWidth < 1170) {
        setScreenBreakLevel(2);
      } else if (window.innerWidth < 1220) {
        setScreenBreakLevel(1);
      } else {
        setScreenBreakLevel(0);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [_isSmall]);

  React.useEffect(() => {
    setIsSmall(_isSmall || screenBreakLevel > 2);
  }, [screenBreakLevel, _isSmall]);

  // Sort buildings first by number, then alphabetically
  const selectableBuildings =
    (buildings &&
      buildings.buildings.length &&
      [...buildings.buildings]
        .map(
          (b) =>
            ({
              __typename: b?.__typename,
              _id: b?._id,
              name: b?.name,
              number: b?.number,
              floors: b?.floors ? [...b?.floors] : [],
            }) as SelectableBuilding,
        )
        .sort((a, b) => {
          return (
            (a?.number || 0) - (b?.number || 0) ||
            a?.name.localeCompare(b?.name || '') ||
            0
          );
        })) ||
    [];
  const showBuildingFilters = selectableBuildings.length > 1;

  const selectedBuildings = selectableBuildings.filter((b) =>
    selectedBuildingIds.includes(b._id),
  );

  // Map each selected building to an array of floors, sorted first by number, then alphabetically.
  // Then flatten into a single array of floors
  const selectableFloors =
    selectedBuildings
      .map(
        (b) =>
          b?.floors
            .filter((f) => selectedBuildingIds.includes(f?.buildingId || ''))
            .sort((a, b) => {
              return (
                (a?.number || 0) - (b?.number || 0) ||
                a?.name.localeCompare(b?.name || '') ||
                0
              );
            }) || [],
      )
      .flat(1) || [];

  const selectedFloors = selectableFloors.filter((f) =>
    selectedFloorIds.includes(f._id),
  );

  const showFloorFilters = selectableFloors.length > 1;

  function getItemId<T extends SelectableBuilding | SelectableFloor>(
    item: T,
  ): string {
    return item._id;
  }

  function formatSelectItem<T extends SelectableBuilding | SelectableFloor>(
    item: T,
  ): string {
    switch (item.__typename) {
      case 'Building':
        return formatBuildingName(item);
      case 'Floor':
        return formatFloorName(item);
    }
  }

  function formatFloorName(floor: SelectableFloor): string {
    const building = selectableBuildings.find(
      (b) => b?._id === floor.buildingId,
    );
    const label =
      (showBuildingFilters && !_isSmall ? `${building?.name} ` : '') +
      floor.name;
    return label;
  }

  function formatBuildingName(building: SelectableBuilding): string {
    const label = building.name;
    return label;
  }

  function getBuildingName(id: string): string {
    const building = selectableBuildings.find((b) => b._id === id);
    return building?.name ?? '';
  }

  const floorLabel = buildings?.floorLabel || 'Floors';

  const allBuildingsSelected =
    selectableBuildings.length === selectedBuildingIds.length;
  const allFloorsSelected = selectableFloors.length === selectedFloorIds.length;
  const buildingSelectLabel =
    (allBuildingsSelected ? 'All' : selectedBuildingIds.length) +
    ' Buildings Selected';

  const floorSelectLabel =
    (allFloorsSelected
      ? 'All'
      : selectedFloorIds.length === 0
        ? 'No'
        : selectedFloorIds.length) +
    ` ${
      allFloorsSelected || selectedFloorIds.length !== 1
        ? pluralize(floorLabel)
        : floorLabel
    } Selected`;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiStack-root': {
          width: '100%',
        },
        width: '100%',
        backgroundColor: '#f3f2f2',
        padding: isSmall ? 'inherit' : '4px 0px 8px',
      }}
    >
      <Stack
        direction={isSmall ? 'column' : 'row'}
        sx={{
          justifyContent: 'center',
          width: isSmall ? '100%' : 'unset',
          alignItems: isSmall ? 'unset' : 'center',
        }}
      >
        {!isSmall ? (
          <div
            style={{
              display: 'flex',
              flex: '0 1 auto',
              flexDirection: 'column',
              justifyContent: 'center',
              margin: '0px 6px',
            }}
          >
            {screenBreakLevel > 0 ? 'Filters:' : 'Quick Filters:'}
          </div>
        ) : null}
        <ToggleButtonGroup
          color="info"
          sx={{
            backgroundColor: isSmall ? 'transparent' : 'white',
            display: 'flex',
            flex: '0 1 auto',
            justifyContent: 'center',
            margin: '6px',
          }}
          aria-label="text button group"
          // size="small"
          value={selectedCategory.key}
          onChange={(_e, key: UnitTypeName) => {
            key && handleSelect(key);
          }}
          exclusive
        >
          {Object.values(category).map((value) => {
            const { key, label, shortLabel } = value;
            const enabled = enabledCategories[key];
            const selected = selectedCategory.key === key;
            return (
              <ToggleButton
                className="filter-button"
                sx={{
                  flex: isSmall ? '1 0 auto' : 'unset',
                  padding: isSmall ? '4px 0px' : '11px',
                }}
                key={key}
                value={key}
                disabled={!enabled}
                selected={selected}
              >
                {screenBreakLevel > 1 ? shortLabel : label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>

        {showBuildingFilters && (
          <div
            style={{
              marginTop: isSmall ? 'inherit' : '4px',
              display: 'flex',
              flex: '1 0 auto',
              justifyContent: 'center',
            }}
          >
            {!isSmall ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  margin: '0px 6px',
                }}
              >
                Building:
              </div>
            ) : null}
            <FormControl sx={{ m: 1, width: '100%' }} size="small" color="info">
              {isSmall ? (
                <InputLabel
                  sx={{
                    backgroundColor: '#f3f2f2',
                    padding: '1px 4px',
                    borderRadius: '4px',
                  }}
                  id="buildings-label"
                >
                  Filter by Building Name
                </InputLabel>
              ) : null}
              <MultiSelect<SelectableBuilding>
                selectables={selectableBuildings}
                selected={selectedBuildings}
                label={'Building'}
                maxSelectSize={4}
                onChange={(newValues) => handleBuildingSelect(newValues)}
                renderValue={() => buildingSelectLabel}
                itemLabel={formatSelectItem}
                itemValue={getItemId}
              />
            </FormControl>
          </div>
        )}
        {showFloorFilters && (
          <div
            style={{
              marginTop: isSmall ? 'inherit' : '4px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {!isSmall ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  margin: '0px 6px',
                }}
              >
                {floorLabel}:
              </div>
            ) : null}
            <FormControl sx={{ m: 1, width: '100%' }} size="small" color="info">
              {isSmall ? (
                <InputLabel
                  sx={{
                    backgroundColor: '#f3f2f2',
                    padding: '1px 4px',
                    borderRadius: '4px',
                  }}
                  id="floors-label"
                >{`Filter by ${floorLabel} name`}</InputLabel>
              ) : null}
              <MultiSelect<SelectableFloor>
                selectables={selectableFloors}
                selected={selectedFloors}
                label={floorLabel}
                groupId={'buildingId'}
                maxSelectSize={6}
                onChange={(newValues) => handleFloorSelect(newValues)}
                renderValue={() => floorSelectLabel}
                itemLabel={formatSelectItem}
                itemValue={getItemId}
                groupLabel={getBuildingName}
              />
            </FormControl>
          </div>
        )}
      </Stack>
    </Box>
  );
}
