import * as React from 'react';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import DataTable from '../shared/data-table';
import Button from '@mui/material/Button';
import {
  useCreateUserApiTokenMutation,
  User,
  useRevokeUserApiTokenMutation,
  useUserListQuery,
  useUserListUpdateSubscription,
} from '../../../types/generated-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { updateCacheFromSubscriptionEvent } from '../../../helpers/subscriptionUtils';
import { Notifier } from '../../system/services/notificationManager';
import { useAuthenticator } from '../../auth/AuthenticationContext';
import { AuthorizedUser } from '../../auth/models';
import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  MobileListCard,
  MobileListDataTableProps,
} from '../shared/mobile-list-card';
import {
  useInjectableComponents,
  BottomMenuItems,
} from '../../system/services/injectableComponentsManager';
import { Add } from '@mui/icons-material';

export default function UsersList() {
  useUserListUpdateSubscription({
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const auth = useAuthenticator();
  const [user, setUser] = React.useState<AuthorizedUser>();
  const [
    createApiToken,
    {
      // data: createApiTokenData,
      // loading: createApiTokenLoading,
      error: createApiTokenError,
    },
  ] = useCreateUserApiTokenMutation();

  const [
    revokeApiToken,
    {
      // data: revokeApiTokenData,
      // loading: revokeApiTokenLoading,
      error: revokeApiTokenError,
    },
  ] = useRevokeUserApiTokenMutation();

  const { setContextMenuItems } = useInjectableComponents();

  useEffect(() => {
    if (createApiTokenError || revokeApiTokenError) {
      Notifier.error('You are not authorized to perform the requested action.');
    }
  }, [createApiTokenError, revokeApiTokenError]);

  useEffect(() => {
    setUser(auth?.user ? auth.user : undefined);
  }, [auth]);

  const hasApiKey = (
    authorizationProfile: User['authorizationProfile'],
  ): boolean => !!authorizationProfile?.apiToken;

  const nameColumn = isSmall
    ? ({
        field: 'name',
        headerName: 'Name',
        align: 'center',
        flex: 1,
        renderCell: (params: GridRenderCellParams<Partial<any>>) => {
          return (
            <MobileListCard>
              <Typography
                sx={{ fontSize: 14, fontWeight: 800 }}
                color="text.secondary"
                gutterBottom
              >
                {params.value}
              </Typography>
              <Typography variant="h5" component="div">
                {params.row.state}
              </Typography>
              <Typography sx={{ fontSize: 10 }} color="text.secondary">
                {params.row.email}
              </Typography>
            </MobileListCard>
          );
        },
      } as GridColDef)
    : { field: 'name', headerName: 'Name', width: 220 };

  const columns = [
    { ...nameColumn },
    { field: 'email', headerName: 'Email Address', width: 250 },
    {
      field: 'state',
      headerName: 'Status',
      width: 90,
    },
  ];

  if (user?.isSuper) {
    columns.push({
      field: 'action',
      headerName: 'Actions',
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<Partial<User>>) => (
        <div>
          {user?.isSuper && !hasApiKey(params.row?.authorizationProfile) && (
            <Button
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await createApiToken({
                  variables: { userId: params.row?._id ?? '' },
                });
              }}
              variant="contained"
              size="small"
              color="secondary"
              style={{ marginLeft: 16, color: '#fff' }}
            >
              Create API Token
            </Button>
          )}
          {user?.isSuper && hasApiKey(params.row?.authorizationProfile) && (
            <Button
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await revokeApiToken({
                  variables: { userId: params.row?._id ?? '' },
                });
              }}
              variant="contained"
              size="small"
              style={{ marginLeft: 16, backgroundColor: 'red', color: '#fff' }}
            >
              Revoke API Token
            </Button>
          )}
        </div>
      ),
    });
  }

  const handleRowClick = (params: GridRowParams) =>
    navigate(`/users/${params.row?._id}`);

  const {
    data: userData,
    loading: userDataLoading,
    error: userDataError,

    // subscribeToMore,
  } = useUserListQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    if (userDataError) {
      Notifier.error(
        'There was a problem fetching user information: ',
        userDataError,
      );
    }
  }, [userDataError]);

  const currentLocation = useLocation();
  const { pathname } = currentLocation;
  const pathComponent = pathname.split('/').slice(0, 5);

  useEffect(() => {
    const bottomMenu: BottomMenuItems = [
      {
        items: [
          {
            id: 'menu-item-add-user',
            label: 'Add User',
            icon: <Add fontSize="small" />,
            action: () => navigate('/users/create/tab/general'),
            permit: {
              action: 'create',
              subject: 'User',
            },
          },
        ],
      },
    ];
    setContextMenuItems(bottomMenu);
    return () => {
      setContextMenuItems(undefined);
    };
  }, [pathname, navigate]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        cursor: 'pointer',
      }}
    >
      <DataTable
        {...(isSmall ? MobileListDataTableProps : {})}
        getRowId={(user: Partial<User>) => {
          return user._id;
        }}
        additionalProps={{
          onRowClick: (i: GridRowParams) => handleRowClick(i),
        }}
        columns={columns}
        suppressAutoPageSize={true}
        rowHeight={isSmall ? 85 : undefined}
        columnVisibilityModel={{
          action: !isSmall,
          email: !isSmall,
          state: !isSmall,
        }}
        rows={userData?.users ?? []}
        hideFilterSorting={pathComponent[3] === 'true'}
        loading={userDataLoading}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
      />
    </div>
  );
}
