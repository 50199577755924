import React, { useCallback, useEffect, useState } from 'react';

import { useAuthenticator } from '../AuthenticationContext';
import { Notifier } from '../../system/services/notificationManager';
import Box from '@mui/material/Box';
import { Fade } from '@mui/material';
import Grid from '@mui/material/Grid';

import { AccountLinkingRequestData } from '../types/smart';
import { LocalState } from '../../system/services/localStateManager';
import {
  AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME,
  AUTH_SESSION_EXPIRATION_TOKEN_NAME,
  AUTH_SESSION_TOKEN_NAME,
} from '../../../helpers/config';
import { IServerToken } from '../models';

const validPermissions: string[] = [
  'Device.read.operationalValues',
  'Device.write.operationalValues',
  'Devices.discoverConnectedDevices',
];

const permissionsText: Record<string, string> = {
  'Device.read.operationalValues': 'Read thermostat values',
  'Device.write.operationalValues': 'Set temperature, fan speed, etc.',
  'Devices.discoverConnectedDevices': 'List connected devices',
};

export const SmartAlexa = ({
  smartLinkLoginParams,
}: {
  smartLinkLoginParams: AccountLinkingRequestData | undefined;
}) => {
  const [permissions, setPermissions] = useState<string[]>();
  const [sessionToken, setSessionToken] = useState<string>();
  const [sessionExpiration, setSessionExpiration] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const authenticator = useAuthenticator();

  useEffect(() => {
    const sessionToken = LocalState.getItem<IServerToken>(
      AUTH_SESSION_TOKEN_NAME,
    )?.value as string;
    const tokenExpiration = LocalState.getItem<IServerToken>(
      AUTH_SESSION_EXPIRATION_TOKEN_NAME,
    )?.value as number;

    if (sessionToken) {
      setSessionToken(sessionToken);
    }
    if (tokenExpiration) {
      setSessionExpiration(tokenExpiration);
    }

    LocalState.removeItems([
      AUTH_RESET_PASSWORD_EMAIL_TOKEN_NAME,
      AUTH_SESSION_TOKEN_NAME,
      AUTH_SESSION_EXPIRATION_TOKEN_NAME,
    ]);
  }, []);

  useEffect(() => {
    if (smartLinkLoginParams) {
      if (!smartLinkLoginParams.redirect_uri) {
        setErrorMessage(
          'A redirect URI must be provided. Please contact Embue support.',
        );
      }
    }
  }, [smartLinkLoginParams]);

  useEffect(() => {
    if (smartLinkLoginParams && Object.keys(smartLinkLoginParams).length) {
      const permissionsArray: string[] = [];
      const testPermissions = smartLinkLoginParams.scope || [];
      let errorType: string;
      let errorMessage: string;

      testPermissions.forEach((permission) => {
        if (!validPermissions.includes(permission)) {
          errorType = 'invalid_scope';
          errorMessage =
            'Invalid%20request.%20Invalid520Permission%20specified%20in%20request.';
        } else {
          permissionsArray.push(permissionsText[permission]);
        }
        if (errorType) {
          console.log('[Account Linking Error]', errorType, errorMessage);
          window.location.href = `${
            smartLinkLoginParams.redirect_uri
          }?error=${errorType}&error_description=${(errorMessage || '')
            .split(' ')
            .join('%20')}`;
        }
      });
      setPermissions(permissionsArray);
    }
  }, [smartLinkLoginParams]);

  const brand = 'Amazon';
  const service = 'Alexa devices linked to your Amazon account';

  const handleLinkAccountsRequest = useCallback(
    async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (sessionToken && smartLinkLoginParams) {
        if (Date.now() < (sessionExpiration || 0)) {
          try {
            const res = await authenticator.linkAccounts(
              sessionToken,
              smartLinkLoginParams,
            );
            window.location.href = `${res}`;
          } catch (linkError) {
            console.log(`[Account Linking Error] ${JSON.stringify(linkError)}`);
            window.location.href = `${
              smartLinkLoginParams.redirect_uri
            }?error=link_error&error_description=${
              (linkError as Error).message ||
              'An error occurred while linking your account'
                .split(' ')
                .join('%20')
            }`;
          }
        } else {
          Notifier.error(
            'Unable to link accounts. You waited too long to press the button. Please try linking again or contact Embue support.',
          );
          setTimeout(() => {
            window.location.href = `${smartLinkLoginParams.redirect_uri}?state=${smartLinkLoginParams.state}&error=access_denied&error_description=You%20waited%20too%20long%20to%20press%20the%20button.%20Please%20try%20linking%20again.`;
          }, 5000);
        }
      }
    },
    [authenticator, smartLinkLoginParams],
  );

  const handleCancelLinkAccountsRequest = useCallback(() => {
    if (smartLinkLoginParams) {
      window.location.href = `${smartLinkLoginParams.redirect_uri}?state=${smartLinkLoginParams.state}&error=access_denied&error_description=User%20chose%20not%20to%20link%20accounts.`;
    }
  }, [smartLinkLoginParams]);

  return (
    <Fade
      style={{ transitionDuration: '500ms' }}
      in={true}
      mountOnEnter
      unmountOnExit
    >
      <div>
        <Box
          component="form"
          noValidate
          sx={{ width: 'auto', marginTop: '0px', marginBottom: '10px' }}
        >
          <Grid
            container
            spacing={2}
            style={{ marginTop: '0px', paddingTop: '0px' }}
          >
            <Grid item xs={12} style={{ marginTop: '0px', paddingTop: '0px' }}>
              <div style={{ textAlign: 'center', marginTop: '0px' }}>
                {errorMessage ? (
                  <div>
                    <h1>Error linking accounts</h1>
                    <p>Unable to link your accounts.</p>
                    <p>{errorMessage}</p>
                    <p>
                      Please try linking your accounts again or contact Embue
                      Support.
                    </p>
                  </div>
                ) : (
                  <div className="oauth-login-page-form">
                    <div className="oauth-login-page-instructions">
                      Your {brand} account would like to access your Embue
                      managed devices.
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      Click the 'Link Accounts' button below to allow {service}{' '}
                      to:
                    </div>
                    <ul style={{ marginLeft: '20px' }}>
                      {permissions &&
                        permissions.map((permission, index) => (
                          <li key={index} style={{ textAlign: 'left' }}>
                            {permission}
                          </li>
                        ))}
                    </ul>
                    <div style={{ display: 'inline-flex' }}>
                      <div style={{}}>
                        <button onClick={handleLinkAccountsRequest}>
                          Link Accounts
                        </button>
                      </div>
                      <div style={{}}>
                        <button onClick={handleCancelLinkAccountsRequest}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Fade>
  );
};
