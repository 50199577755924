/**
 * A simple function which returns a capitalized copy of the supplied string.
 * @param s
 */
export const capitalize = (s: string) =>
  (s && s[0].toUpperCase() + s.slice(1)) || '';

/**
 * A simple function which returns an un-capitalized copy of the supplied string.
 * @param s
 */
export const uncapitalize = (s: string) =>
  (s && s[0].toLowerCase() + s.slice(1)) || '';
