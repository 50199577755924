/**
 * UnitsList
 * - display a list of units
 * - render depending on 'installer' flag
 * - allow list to be filtered by type (residential | common area | central equipment)
 * - link list-items to detail page
 * - indicators for alert to list items
 * - sorting by column
 * - text search across all columns
 * - accept additional filters by ways of React router options
 */
import React from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

/* MRT Imports */
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';

/* MUI */
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AddIcon from '@mui/icons-material/Add';

/* Data */
import {
  useUnitListByPropertyIdQuery,
  useUnitListUpdateSubscription,
  usePropertyBuildingsQuery,
  Property,
} from '../../../../types/generated-types';

import { FilterButtons } from '../ui/filter-buttons';
import {
  Fahrenheit,
  TemperatureUnit,
} from '../../../system/models/temperatureUnits';

import { useAuthenticator } from '../../../auth/AuthenticationContext';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
import FullWidthLoadingSkeleton from '../../shared/fullWidthLoadingSkeleton';
import {
  EnabledUnitTypes,
  MyUnit,
  UnitTypeName,
  unitTypes,
  UnitType,
  UnitFloor,
  UnitDataRow,
} from '../types/unitListTypes';

import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../../system/services/injectableComponentsManager';

import { UnitFilterSelector } from '../ui/unit-filter-selector';
import { basePropertyMenuItems } from '../../properties/ui/base-property-context-menu';
import {
  columnsForUnitType as _columnsForUnitType,
  toUnitRow as _toUnitRow,
  bySearchTerm,
  bySelectedBuildings,
  bySelectedFloors,
  bySpecificDevices,
  byUnitType,
  onBuildingCount,
  onFloorCount,
  onScreenSize,
} from './lib';
import { SearchText } from '../ui/search-text';
import { useSafePadding } from '../../../ui/helpers/safe-padding';

export function UnitsList({
  installerView = false,
}: {
  installerView?: boolean;
}) {
  const { id } = useParams<{ id: string }>();
  const [filterParams, setFilterParams] = useSearchParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const auth = useAuthenticator();
  /* specific devices are those passed in from another component. They are 
     used here to filter the number of units displayed without affecting the pathname. */
  const { pathname, state: specificDevices } = useLocation();
  const {
    setPrimaryBottomNavigationWidget,
    setContextMenuItems,
    setSubtitle,
    setSubtitlePath,
    setSubtitleActionWidget,
    setTitle,
    setTitlePath,
  } = useInjectableComponents();

  const { safeTopPadding } = useSafePadding();

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const currentUnitTypeName = filterParams.get('unitType') as UnitTypeName;
  const selectedUnitType =
    unitTypes[currentUnitTypeName || ''] || unitTypes.residential;

  /* State */
  const [property, setProperty] = React.useState<Partial<Property>>();
  const [floors, setFloors] = React.useState<UnitFloor[]>([]);
  const [hasMultipleBuildings, setHasMultipleBuildings] =
    React.useState<boolean>(false);
  const [hasMultipleFloors, setHasMultipleFloors] =
    React.useState<boolean>(false);
  const [showSearch, setShowSearch] = React.useState<boolean>(true);
  const [showFilter, setShowFilter] = React.useState<boolean>(!isSmall);

  /* enabled unit are those that exist in a property and can therefore be selected using the 
     filter buttons - initialize all as false and then modify according to query data */
  const [enabledUnitTypes, setEnabledUnitTypes] =
    React.useState<EnabledUnitTypes>({
      residential: false,
      commonArea: false,
      centralEquipment: false,
    });

  const [units, setUnits] = React.useState<MyUnit[]>();
  const [selectedBuildingIds, setSelectedBuildingIds] = React.useState<
    string[]
  >([]);
  const [selectedFloorIds, setSelectedFloorIds] = React.useState<string[]>([]);
  const [hasFloors, setHasFloors] = React.useState<boolean>(false);
  const [hasBuildings, setHasBuildings] = React.useState<boolean>(false);
  const [preferredUnits, setPreferredUnits] =
    React.useState<TemperatureUnit>(Fahrenheit);
  const [tableColumns, setTableColumns] = React.useState<
    MRT_ColumnDef<UnitDataRow>[]
  >([]);
  const [tableRows, setTableRows] = React.useState<UnitDataRow[]>();

  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [screenBreakLevel, setScreenBreakLevel] = React.useState<number>(0);

  /* --- Cached Functions --- */
  const columnsForUnitType = React.useCallback(
    (type: UnitType) =>
      _columnsForUnitType(
        type,
        installerView,
        preferredUnits,
        screenBreakLevel,
      ),
    [installerView, preferredUnits, screenBreakLevel],
  );

  const toUnitRow = React.useCallback(
    (t: UnitType) => _toUnitRow(t, preferredUnits, installerView),
    [preferredUnits, installerView],
  );

  // Update URL search params based on the new selected unit types
  const handleCategoryButtons = React.useCallback(
    (newSelectedType: UnitTypeName) => {
      if (newSelectedType !== selectedUnitType.key) {
        setFilterParams(
          { unitType: newSelectedType },
          { state: specificDevices },
        );
      }
    },
    [selectedUnitType.key, setFilterParams, specificDevices],
  );

  /* --- Event Handlers --- */
  const handleSearchChange = (term: string) => setSearchTerm(term);

  const handleBuildingSelect = (buildingIds: string[]) => {
    // Reselect all applicable floors whenever the selected buildings change
    const newFloorIds = floors
      .filter((floor: UnitFloor) =>
        buildingIds.includes(floor.buildingId || ''),
      )
      .map((f) => f?._id || '');
    setSelectedBuildingIds(buildingIds);
    setSelectedFloorIds(newFloorIds || []);
  };

  const handleFloorSelect = (floorIds: string[]) => {
    setSelectedFloorIds(floorIds);
  };

  /* Data */
  const { data, loading, error } = useUnitListByPropertyIdQuery({
    variables: { propertyId: id },
    fetchPolicy: 'network-only',
    onCompleted(_data) {
      // TODO: Peter: since this is a no-op ... perhaps we don't need this at all? or does it prevent something else that is undesirable from happening?
      // no-op: query updates are handled in the effect loop in order to manage subscription updates
    },
  });

  useUnitListUpdateSubscription({
    variables: { propertyId: id || '' },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  const {
    data: buildingsData,
    loading: buildingsLoading,
    error: buildingsError,
  } = usePropertyBuildingsQuery({
    variables: { id: id || '' },
    fetchPolicy: 'cache-and-network',
  });

  /* --- Effects --- */

  // Set display break point level on resize
  React.useEffect(() => {
    const handleResize = () => {
      if (isSmall) {
        setScreenBreakLevel(7);
      } else if (window.innerWidth < 670) {
        setScreenBreakLevel(6);
      } else if (window.innerWidth < 780) {
        setScreenBreakLevel(5);
      } else if (window.innerWidth < 870) {
        setScreenBreakLevel(4);
      } else if (window.innerWidth < 1000) {
        setScreenBreakLevel(3);
      } else if (window.innerWidth < 1170) {
        setScreenBreakLevel(2);
      } else if (window.innerWidth < 1220) {
        setScreenBreakLevel(1);
      } else {
        setScreenBreakLevel(0);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSmall]);

  // Initialize preferred units
  React.useEffect(() => {
    if (auth?.user) {
      setPreferredUnits(auth.user.preferredUnits);
    }
  }, [auth]);

  /**
   * Initialize 'enabled unit types', units list according to filters,
   * floors and building settings from query results.
   * Enabled unit are those that exist in a property and can therefore
   * be selected using the filter buttons.
   **/
  React.useEffect(() => {
    /* unitTypes is a constant.  why would it ever not exist? */
    if (data?.unitsByPropertyId && unitTypes) {
      const units = data.unitsByPropertyId.filter(
        bySpecificDevices(specificDevices),
      );

      setUnits(units);

      const _enabledUnitTypes: EnabledUnitTypes = Object.values(
        unitTypes,
      ).reduce((acc, unitType) => {
        acc[unitType.key] = units.some((u) => u.type === unitType.key);
        return acc;
      }, {} as EnabledUnitTypes);
      setEnabledUnitTypes(_enabledUnitTypes);

      const uniqueIds = units.reduce(
        (
          uniqueSets: { floors: Set<string>; buildings: Set<string> },
          unit: MyUnit,
        ) => {
          if (unit.floorId && (unit.floorId?.length ?? 0) > 0) {
            uniqueSets.floors.add(unit.floorId);
          }
          if (unit.buildingId && (unit.buildingId?.length ?? 0) > 0) {
            uniqueSets.buildings.add(unit.buildingId);
          }
          return uniqueSets;
        },
        { floors: new Set<string>(), buildings: new Set<string>() },
      );

      setHasFloors(uniqueIds.floors.size > 0);
      setHasBuildings(uniqueIds.buildings.size > 0);
    }
  }, [data, specificDevices]);

  // Initialize building and floor settings, i.e. whether there are multiple floors or buildings
  React.useEffect(() => {
    if (buildingsData?.propertyById) {
      const myProperty: Partial<Property> =
        buildingsData.propertyById as Partial<Property>;

      // Select all buildings and floors by default
      setSelectedBuildingIds(
        myProperty.buildings?.map((b) => b?._id || '') ?? [],
      );
      setSelectedFloorIds(myProperty.floors?.map((f) => f?._id || '') ?? []);
      // setBuildings((myProperty?.buildings as UnitBuilding[]) ?? []); -- unused state
      setFloors((myProperty?.floors as UnitFloor[]) ?? []);
      setHasMultipleBuildings((myProperty?.buildings || []).length > 1);
      setHasMultipleFloors((myProperty?.floors || []).length > 1);
      setProperty(myProperty);
    }
  }, [buildingsData]);

  // Set up bottom navigation, title, subtitle and search & filter widgets
  React.useEffect(() => {
    if (units && property && pathname) {
      const searchWidget = (_showSearch: boolean) => (
        <div
          style={{
            borderRadius: '4px',
            backgroundColor: _showSearch ? 'forestgreen' : 'transparent',
          }}
        >
          <IconButton
            sx={{
              color: _showSearch ? 'white' : theme.palette.primary.main,
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowSearch(!_showSearch);
            }}
          >
            <SearchIcon fontSize="small" />
          </IconButton>
        </div>
      );

      const filterWidget = (_showFilter: boolean) => (
        <div
          style={{
            marginLeft: '4px',
            borderRadius: '4px',
            backgroundColor: _showFilter ? 'forestgreen' : 'transparent',
          }}
        >
          <IconButton
            sx={{
              color: _showFilter ? 'white' : theme.palette.primary.main,
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowFilter(!_showFilter);
            }}
          >
            <FilterAltIcon fontSize="small" />
          </IconButton>
        </div>
      );

      const specificDevicesWidget = (_showFilter: boolean) =>
        _showFilter && (
          <Chip
            sx={{
              mx: '4px',
              color: 'white',
              background: 'forestgreen',
              '& .MuiChip-deleteIcon': {
                color: 'white',
              },
            }}
            label={`Filtered ${!isSmall ? 'by specific devices' : ''}`}
            variant="outlined"
            onDelete={() => {
              setFilterParams(
                { unitType: selectedUnitType.key },
                { state: null },
              );
            }}
          />
        );

      const items: BottomMenuItems = basePropertyMenuItems(
        property._id,
        pathname,
        [
          {
            id: 'add-unit-menu-item',
            label: 'Add Unit',
            icon: <AddIcon fontSize="small" />,
            navTarget: `/properties/${property._id}/units/add-unit`,
            permit: 'administer',
          },
        ],
      );

      const basePropertyName = property.title ?? 'Untitled Property';

      setTitle(
        pathname.includes('installer')
          ? isSmall
            ? basePropertyName.length < 19
              ? `Installing ${basePropertyName}`
              : basePropertyName.length > 24
                ? `${basePropertyName.slice(0, 24)} ...`
                : basePropertyName
            : basePropertyName
          : basePropertyName,
      );

      setTitlePath(
        pathname.includes('installer')
          ? pathname.replace('installer', 'units')
          : `/properties`,
      );

      setSubtitle(
        pathname.includes('installer') ? 'Installer Unit List' : 'Unit List',
      );

      setSubtitlePath(
        pathname.includes('installer')
          ? `/properties/${property._id}/units`
          : `/properties/${property._id}/summary`,
      );

      setSubtitleActionWidget({
        widget: (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {specificDevicesWidget(
              Array.isArray(specificDevices) && specificDevices.length > 0,
            )}
            {searchWidget(showSearch)}
            {filterWidget(showFilter)}
          </div>
        ),
      });

      setContextMenuItems(items, property as Property);

      setPrimaryBottomNavigationWidget(
        isSmall ? (
          <UnitFilterSelector
            selectedUnitType={selectedUnitType}
            enabledUnitTypes={enabledUnitTypes}
            changeHandler={handleCategoryButtons}
          />
        ) : undefined,
      );
      return () => {
        setTitle(undefined);
        setTitlePath(undefined);
        setSubtitle(undefined);
        setSubtitlePath(undefined);
        setSubtitleActionWidget(undefined);
        setContextMenuItems(undefined);
        setPrimaryBottomNavigationWidget(undefined);
      };
    }
  }, [
    enabledUnitTypes,
    handleCategoryButtons,
    isSmall,
    pathname,
    property,
    selectedUnitType,
    setContextMenuItems,
    setFilterParams,
    setPrimaryBottomNavigationWidget,
    setSubtitle,
    setSubtitleActionWidget,
    setSubtitlePath,
    setTitle,
    setTitlePath,
    showFilter,
    showSearch,
    specificDevices,
    theme.palette.primary.main,
    units,
  ]);

  React.useEffect(() => {
    if (selectedUnitType) {
      /**
       * Select columns to display:
       * Filter out 'Buildings' and 'Floors' columns on small devices, or if a property doesn't have multiple of either.
       */
      const columns = columnsForUnitType(selectedUnitType)
        .filter(onScreenSize(isSmall))
        .filter(onFloorCount(hasFloors))
        .filter(onBuildingCount(hasBuildings));

      setTableColumns(columns);
    }
  }, [selectedUnitType, isSmall, hasFloors, hasBuildings, columnsForUnitType]);

  // Set up rows and columns of the DataGrid
  React.useEffect(() => {
    if (units) {
      /**
       * Select rows to display:
       * Leave the search-filter for last, so that the columns are set and
       * there are fewer records to search and sort
       */
      const rows = units
        .filter(byUnitType(selectedUnitType))
        .filter(bySelectedBuildings(selectedBuildingIds))
        .filter(bySelectedFloors(selectedFloorIds))
        .map(toUnitRow(selectedUnitType))
        .filter(bySearchTerm(searchTerm));

      setTableRows(rows);
    }
  }, [
    units,
    property,
    selectedUnitType,
    hasMultipleBuildings,
    selectedBuildingIds,
    hasMultipleFloors,
    selectedFloorIds,
    searchTerm,
    preferredUnits,
    installerView,
    hasBuildings,
    hasFloors,
    toUnitRow,
    specificDevices,
  ]);

  /* --- Data Grid Utility Functions --- */
  const table = useMaterialReactTable({
    layoutMode: 'grid',
    defaultColumn: {
      minSize: 100,
      size: 100,
      enableColumnActions: false,
      sortingFn: 'alphanumeric',
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    columns: tableColumns,
    data: (tableRows ?? []) as UnitDataRow[], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // enableColumnFilterModes: true,
    // enableColumnOrdering: true,
    // enableGrouping: true,
    // enableColumnPinning: true,
    // enableFacetedValues: true,
    // enableRowActions: true,
    // enableRowSelection: true,
    enableStickyHeader: true,
    enableDensityToggle: true,
    initialState: {
      density: 'compact',
      showColumnFilters: false,
      showGlobalFilter: false,
      sorting: [
        {
          id: 'name', //sort by age by default on page load
          desc: false,
        },
      ],
      columnOrder: [
        'status',
        'name',
        'buildingName',
        'floorName',
        'temperature',
        'setpoint',
        'humidity',
        'installation',
        'loopSupply',
        'loopReturn',
        'delta',
      ],
    },
    renderTopToolbar: false,
    renderBottomToolbar: false,
    enablePagination: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiTableBodyProps: {
      sx: {
        '& tr:nth-of-type(even)': {
          backgroundColor: 'rgba(241, 241, 241, 0.3) !important',
        },
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (row.original?.id) {
          navigate(`${row.original.id}`);
        }
      },
      sx: {
        height: screenBreakLevel > 4 ? '37px' : undefined,
      },
    }),
    muiTableHeadRowProps: {
      sx: {
        height: screenBreakLevel > 4 ? '37px' : undefined,
        '& .Mui-TableHeadCell-Content-Labels': {
          paddingLeft: '0px',
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        '& .MuiTableHeadCell-Content': {
          minWidth: '100px',
        },
        '& .MuiTableHeadCell-Content-Labels': {
          paddingLeft: '0px',
        },
      },
      style: {
        padding: '2px 0px',
      },
    },
    muiTableBodyCellProps: {
      style: {
        padding: '0px',
      },
    },
    muiTableContainerProps: {
      style: {
        height: `${2 + ((tableRows?.length ?? 0) + 1) * 37}px`,
        maxHeight: `calc(100vh - ${
          (isSmall ? 208 + safeTopPadding : 242) +
          (showSearch ? 60 : 0) +
          (showFilter ? (isSmall ? 46 : screenBreakLevel > 2 ? 46 : 72) : 0) +
          (showFilter &&
          (buildingsData?.propertyById?.buildings?.length ?? 0) > 1
            ? isSmall
              ? 56
              : screenBreakLevel > 2
                ? 56
                : 0
            : 0) +
          (showFilter && (buildingsData?.propertyById?.floors?.length ?? 0) > 1
            ? isSmall
              ? 56
              : screenBreakLevel > 2
                ? 56
                : 0
            : 0)
        }px)`,
      },
    },
    // TODO: Peter ... are we going to use the bottom toolbar here? If not, remove this.
    // muiBottomToolbarProps: {
    //   sx: {
    //     minHeight: '40px',
    //     '& .MuiBox-root': {
    //       paddingTop: '0px',
    //       paddingBottom: '0px',
    //       minHeight: '0px',
    //     },
    //     '& .MuiTablePagination-root': {
    //       padding: '0px',
    //     },
    //   },
    // },
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
  });

  /* UI */
  const err = error || buildingsError;
  const isLoading = loading || buildingsLoading;
  err && console.error('[units-list] Error', error, buildingsError);

  return err ? (
    err.message === 'Unauthorized' ? (
      <>
        <Typography variant="h4">
          You are not authorized to access the requested resource.
        </Typography>
        <Typography variant="body1">
          Please contact your administrator or Embue support for assistance.
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="h4">
          Error accessing the requested resource.
        </Typography>
        <Typography variant="body1">
          Please contact your administrator or Embue support for assistance.
        </Typography>
      </>
    )
  ) : isLoading ? (
    <FullWidthLoadingSkeleton />
  ) : (
    <Box
      sx={{
        display: 'flex',
        maxWidth: isSmall
          ? '100vw'
          : screenBreakLevel > 2
            ? 'calc(100vw - 85px)'
            : 'calc(100vw - 65px)',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        cursor: 'pointer',
        overflowY: 'scroll',
      }}
    >
      {showFilter && (
        <FilterButtons
          theme={theme}
          category={unitTypes}
          enabledCategories={enabledUnitTypes}
          selectedCategory={selectedUnitType}
          buildings={buildingsData?.propertyById}
          selectedBuildingIds={selectedBuildingIds}
          selectedFloorIds={selectedFloorIds}
          handleSelect={handleCategoryButtons}
          handleBuildingSelect={handleBuildingSelect}
          handleFloorSelect={handleFloorSelect}
        />
      )}
      {showSearch && (
        <SearchText
          handleChange={handleSearchChange}
          padding={isSmall ? '6px' : '2vw'}
        />
      )}
      {tableColumns && tableRows && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            paddingTop: isSmall ? '6px' : '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              height: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto',
                maxWidth: isSmall ? '0px' : undefined,
              }}
            ></div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '0 1 auto',
                marginLeft: '2px',
                width: isSmall
                  ? 'calc(100vw - 4px)'
                  : screenBreakLevel > 4
                    ? '82vw'
                    : screenBreakLevel > 3
                      ? '84vw'
                      : screenBreakLevel > 2
                        ? '86vw'
                        : '88vw',
                height: 'auto',
              }}
            >
              <MaterialReactTable table={table} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto',
              }}
            ></div>
          </div>
        </div>
      )}
    </Box>
  );
}
