import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FormValues } from './types/unitDetailFormValues';
import {
  Unit,
  Property,
  CreateUnitMutationVariables,
  UpdateUnitMutationVariables,
  useCreateUnitMutation,
  useUpdateUnitMutation,
} from '../../../types/generated-types';
import { Form, Formik, FormikHelpers, useFormik } from 'formik';
import { UnitGeneralTab } from './unit-general-tab';
import { useAuthenticator } from '../../auth/AuthenticationContext';
import * as yup from 'yup';
// import { ActivityIndicator } from '../shared/activityIndicator';
import { Notifier } from '../../system/services/notificationManager';

import { SetpointProfileOverrideSelection } from '../shared/setpoint-profile-override-selection';
import {
  Fahrenheit,
  TemperatureUnit,
} from '../../system/models/temperatureUnits';
import { ZonePolicyOverrideSelection } from '../shared/zone-policies-override-selection';
import { useTheme } from '@mui/material/styles';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../system/services/injectableComponentsManager';
import EditIcon from '@mui/icons-material/Edit';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import { Visibility } from '@mui/icons-material';
import { UnitDetailHeader } from './ui/unit-detail-header';
import { ActivityIndicator } from '../shared/activityIndicator';
import { basePropertyMenuItems } from '../properties/ui/base-property-context-menu';
import { useAuthorizer } from '../../auth/AuthorizationContext';
import { PropertyUnitDetailScheduleTemplates } from '../properties/components/property-unit-detail-schedule-templates';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>{children}</Box>
      )}
    </div>
  );
}

const validationSchema = yup.object({
  unitName: yup.string().required('Unit name is required'),
  unitType: yup.string().required('Type is required'),
  buildingId: yup.string().required('Building is required'),
  sourceId: yup.string().required('Source is required'),
});

interface UnitDetailFormProps {
  unitLoading?: boolean;
  propertyLoading?: boolean;
  unit: Partial<Unit>;
  property: Partial<Property>;
  viewId?: string;
}

export const UnitDetailForm = (props: UnitDetailFormProps) => {
  const {
    unit,
    property,
    viewId,
    unitLoading = false,
    propertyLoading = false,
  } = props;

  const unitExists = !!unit._id;

  const auth = useAuthenticator();
  const { can, cannot } = useAuthorizer();

  const navigate = useNavigate();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const initialValues: FormValues = {
    unitName: unit.name ?? '',
    unitType: unit.type ?? '',
    buildingId: unit.buildingId ?? '',
    sourceId: unit.sourceId ?? '',
    residentEmail: unit?.resident?.email ?? '',
    residentMobile: unit?.resident?.mobile ?? '',
    residentName: unit?.resident?.name ?? '',
    residentPhone: unit?.resident?.phone ?? '',
  };

  const [preferredUnits, setPreferredUnits] = React.useState<TemperatureUnit>();
  const [selectedSetpointProfileId, setSelectedSetpointProfileId] =
    React.useState<string>();
  const [selectedZonePolicyId, setSelectedZonePolicyId] =
    React.useState<string>(
      unit.zonePolicyId ?? unit.property?.zonePolicyId ?? '0',
    );

  const [ViewIdToValueMap, setViewIdToValueMap] = React.useState<
    Record<string, number>
  >({});
  const [ValueToViewIdMap, setValueToViewIdMap] = React.useState<
    Record<number, string>
  >({});

  React.useEffect(() => {
    const myViewIdToValueMap: Record<string, number> = can(
      'manage',
      'SetpointProfile',
    )
      ? can('manage', 'ZonePolicy')
        ? {
            general: 0,
            'setpoint-profiles': 1,
            'zone-policies': 2,
            'schedule-template': 3,
          }
        : {
            general: 0,
            'setpoint-profiles': 1,
            'schedule-template': 2,
          }
      : can('manage', 'ZonePolicy')
        ? {
            general: 0,
            'zone-policies': 1,
            'schedule-template': 2,
          }
        : {
            general: 0,
            'schedule-template': 1,
          };

    const myValueToViewIdMap: Record<number, string> = can(
      'manage',
      'SetpointProfile',
    )
      ? can('manage', 'ZonePolicy')
        ? {
            0: 'general',
            1: 'setpoint-profiles',
            2: 'zone-policies',
            3: 'schedule-template',
          }
        : {
            0: 'general',
            1: 'setpoint-profiles',
            2: 'schedule-template',
          }
      : can('manage', 'ZonePolicy')
        ? {
            0: 'general',
            1: 'zone-policies',
            2: 'schedule-template',
          }
        : {
            0: 'general',
            2: 'schedule-template',
          };
    setViewIdToValueMap(myViewIdToValueMap);
    setValueToViewIdMap(myValueToViewIdMap);
  }, [can, auth?.user]);

  const [value, setValue] = React.useState<number>(0);

  React.useEffect(() => {
    if (viewId) {
      const initialTabId: number = ViewIdToValueMap[viewId];
      if (initialTabId !== undefined) {
        setValue(initialTabId);
      }
    } else {
      navigate('general', { replace: true });
    }
  }, [viewId, ViewIdToValueMap, navigate]);

  const handleTabChange = React.useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      if (formik.dirty && newValue === 1) {
        Notifier.warn(
          'Please save all other changes before editing a setpoint profile override.',
        );
      } else if (formik.dirty && newValue === 2) {
        Notifier.warn(
          'Please save all other changes before editing a zone policy override.',
        );
      } else {
        if (newValue !== value && viewId) {
          let newUrl = pathname;
          const pathParts = pathname.split('/');

          if ([7, 8].includes(pathParts.length)) {
            pathParts[6] = ValueToViewIdMap[newValue];
            if (pathParts.length === 8) {
              pathParts.pop();
            }
            newUrl = pathParts.join('/');
          }
          navigate(newUrl);
        }
      }
    },
    [ValueToViewIdMap],
  );

  const [createUnit] = useCreateUnitMutation();
  const [updateUnit] = useUpdateUnitMutation();

  useEffect(() => {
    if (auth?.user?._id) {
      setPreferredUnits(auth?.user?.preferredUnits ?? Fahrenheit);
    }
  }, [auth]);

  useEffect(() => {
    if (unit && preferredUnits) {
      const initialProfileId =
        unit.setpointProfileId || unit.property?.setpointProfileId || '0';
      setSelectedSetpointProfileId(initialProfileId);
    }
  }, [preferredUnits, unit]);

  useEffect(() => {
    if (unit) {
      const initialPolicyId =
        unit.zonePolicyId || unit.property?.zonePolicyId || '0';
      setSelectedZonePolicyId(initialPolicyId);
    }
  }, [unit]);

  const onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    const unitData:
      | CreateUnitMutationVariables['unit']
      | UpdateUnitMutationVariables['unit'] = {
      name: values.unitName,
      type: values.unitType,
      buildingId: values.buildingId,
      sourceId: values.sourceId,
      propertyId: property._id,
      resident: {
        email: values.residentEmail,
        mobile: values.residentMobile,
        name: values.residentName,
        phone: values.residentPhone,
      },
    };
    if (unit?._id) {
      updateUnit({ variables: { id: unit._id, unit: unitData } })
        .then(() => {
          formik.setSubmitting(false);
          Notifier.success('Unit updated successfully');
          navigate(-1);
        })
        .catch((updateError) => {
          formik.setSubmitting(false);
          Notifier.error(updateError.message);
        });
    } else {
      createUnit({
        variables: { unit: unitData },
      })
        .then(() => {
          formik.setSubmitting(false);
          Notifier.success('Unit added successfully');
          navigate(-1);
        })
        .catch((createError) => {
          formik.setSubmitting(false);
          Notifier.error(createError.message);
        });
    }
  };

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const {
    subtitle,
    setSubtitle,
    setSubtitlePath,
    setTitle,
    setTitlePath,
    setContextMenuItems,
    setPrimaryBottomNavigationWidget,
  } = useInjectableComponents();

  const { pathname } = useLocation();

  React.useEffect(() => {
    if (property && unit?._id) {
      const contextMenuItems: BottomMenuItems = [
        ...basePropertyMenuItems(property._id, pathname),
        {
          label: 'Unit Actions',
          items: [
            {
              id: 'view-unit-menu-item',
              label: 'View Unit Details',
              icon: <Visibility fontSize="medium" />,
              action: () => {
                navigate(
                  `/properties/${property._id}/units/${unit._id}/view-unit`,
                );
              },
              permit: {
                action: 'view',
                subject: unit as Unit,
              },
            },
            {
              id: 'manage-unit-menu-item',
              label: 'Manage Unit / View Devices',
              icon: <AutoAwesomeMosaicIcon fontSize="medium" />,
              action: () => {
                navigate(`/properties/${property._id}/units/${unit._id}`);
              },
              permit: {
                action: 'update',
                subject: unit as Unit,
              },
            },
          ],
        },
      ];

      setTitle(property.title ?? 'Untitled Property');
      setTitlePath(`/properties/${property._id}/units`);

      setSubtitle(`${unit.name ?? 'Unnamed Unit'} - Editing Unit`);
      setSubtitlePath(`/properties/${property._id}/units/${unit._id}`);

      setContextMenuItems(contextMenuItems, unit as Unit);
    } else if (property) {
      setTitle(property.title ?? 'Untitled Property');
      setTitlePath(`/properties/${property._id}/summary`);

      setSubtitle('Creating Unit');
      setSubtitlePath(`/properties/${property._id}/units`);

      setContextMenuItems(undefined);
    }

    return () => {
      setTitle(undefined);
      setTitlePath(undefined);
      setSubtitle(undefined);
      setSubtitlePath(undefined);
      setContextMenuItems(undefined);
    };
  }, [unit, property, pathname]);

  return property ? (
    auth.user ? (
      <Card
        elevation={4}
        sx={{
          margin: isSmall ? '0px' : '20px',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'stretch',
          '& .MuiCardHeader-root': {
            padding: '4px 10px!important',
            borderBottom: '1px solid darkgray',
            borderRight: '1px solid lightgray',
            boxShadow: '0px 8px 6px -6px rgba(0, 0, 0, 0.35)',
          },
          '& .MuiCardContent-root': {
            paddingTop: '0px !important',
            borderRight: '1px solid lightgray !important',
            padding: isSmall ? '0px !important' : 'inherit',
          },
        }}
      >
        <UnitDetailHeader
          unitLoading={unitLoading}
          propertyLoading={propertyLoading}
          propertyId={property._id}
          unitId={unit._id}
        />
        <CardContent>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto',
                fontSize: '16px',
                marginRight: '-4px',
              }}
              onSubmit={formik.handleSubmit}
            >
              <div style={{ height: '100%' }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                  }}
                >
                  <Tabs
                    centered
                    TabIndicatorProps={{
                      style: { color: '#000' },
                    }}
                    indicatorColor="secondary"
                    textColor="secondary"
                    value={value}
                    onChange={handleTabChange}
                    aria-label="Unit Tabs"
                  >
                    <Tab key="General" label="General" {...a11yProps(0)} />
                    {/* Can't edit setpoint profiles or zone policies for a unit that hasn't been created yet */}
                    {unitExists && can('manage', 'SetpointProfile') ? (
                      <Tab
                        key="Setpoints"
                        label={isSmall ? 'Setpoints' : 'Setpoint Profile'}
                        {...a11yProps(1)}
                      />
                    ) : null}
                    {unitExists && can('manage', 'ZonePolicy') ? (
                      <Tab key="Zones" label="Zone Policy" {...a11yProps(2)} />
                    ) : null}
                    {unitExists &&
                    can('manage', 'ThermostatScheduleTemplate') ? (
                      <Tab
                        key="Schedule Template"
                        label={isSmall ? 'Schedule' : 'Schedule Template'}
                        {...a11yProps(3)}
                      />
                    ) : null}
                  </Tabs>
                </Box>
                <ActivityIndicator activityInProgress={formik.isSubmitting} />
                <div
                  style={{
                    padding: '0 20px',
                  }}
                >
                  <TabPanel value={value} index={0}>
                    <UnitGeneralTab
                      currentUser={auth.user}
                      formik={formik}
                      property={property}
                      onChange={(e) => {
                        formik.setFieldTouched((e.target as any).id, true);
                        formik.handleChange(e);
                      }}
                    />
                  </TabPanel>
                  {can('manage', 'SetpointProfile') ? (
                    <TabPanel value={value} index={1}>
                      <SetpointProfileOverrideSelection
                        returnLabel="Return to Unit List"
                        targetLevel="unit"
                        returnPath={`/properties/${property._id}/units`}
                        propertyId={property._id}
                        unitId={unit._id}
                        targetProfileId={
                          unit.property?.setpointProfileId ?? '0'
                        }
                        value={selectedSetpointProfileId}
                        onSetpointProfileSelected={(id: string) => {
                          setSelectedSetpointProfileId(id);
                        }}
                        preferredUnits={preferredUnits}
                      />
                    </TabPanel>
                  ) : null}
                  {can('manage', 'ZonePolicy') ? (
                    <TabPanel
                      value={value}
                      index={can('manage', 'SetpointProfile') ? 2 : 1}
                    >
                      <ZonePolicyOverrideSelection
                        returnLabel="Return to Unit List"
                        returnPath={`/properties/${property._id}/units`}
                        unitId={unit._id}
                        targetPolicyId={unit.property?.zonePolicyId ?? '0'}
                        availablePolicies={unit.assignableZonePolicies || []}
                        value={selectedZonePolicyId}
                        onZonePolicySelected={(id: string) => {
                          setSelectedZonePolicyId(id);
                        }}
                      />
                    </TabPanel>
                  ) : null}
                  {can('manage', 'ThermostatScheduleTemplate') ? (
                    <TabPanel
                      value={value}
                      index={
                        can('manage', 'SetpointProfile')
                          ? can('manage', 'ZonePolicy')
                            ? 3
                            : 2
                          : can('manage', 'ZonePolicy')
                            ? 2
                            : 1
                      }
                    >
                      <PropertyUnitDetailScheduleTemplates targetLevel="unit" />
                    </TabPanel>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  marginTop: '10px',
                  marginLeft: '10px',
                  paddingBottom: '75px',
                  paddingLeft: '24px',
                  height: '70px',
                }}
              >
                {value === 0 ? (
                  <Button
                    disabled={!formik.isValid || !formik.dirty}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{ color: '#fff', marginRight: '10px' }}
                  >
                    Save
                  </Button>
                ) : null}
                <Button
                  sx={{ marginLeft: '10px' }}
                  variant="text"
                  onClick={() => navigate(-1)}
                  color={
                    !formik.isValid || !formik.dirty ? 'secondary' : 'primary'
                  }
                >
                  {value === 0 ? 'Cancel' : 'Return'}
                </Button>
              </div>
            </Form>
          </Formik>
        </CardContent>
      </Card>
    ) : (
      <Typography variant="h2">Unauthorized</Typography>
    )
  ) : null;
};
