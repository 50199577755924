import { IRootModel } from './rootModel';

export interface IAlertTriggerEntitySelectionCriterion {
  selectionPath: string;
  comparator: AlertTriggerComparator;
  template?: string;
  valueType: AlertTriggerComparatorValueType;
  value: string;
  editable: boolean;
  legacyPath?: string;
}

export interface IAlertTriggerCriteria {
  basisType: AlertTriggerSelectionBasis;
  basisName: string;
  selectionCriteria: IAlertTriggerEntitySelectionCriterion[];
}

export interface IAlertTrigger extends IRootModel {
  key: string;
  legacyKey: string;
  name: string;
  alertCategoryId: string;
  // organizationId: string;
  propertyId: string;
  entityTypes: string[];
  triggerCriteria: IAlertTriggerCriteria;
  editMode: AlertTriggerEditMode;
  enabled: boolean;
}
export enum AlertTriggerEditMode {
  LOCKED = 0,
  'CRITERIA_ONLY' = 1,
  UNRESTRICTED = 2,
}

export enum AlertTriggerComparatorValueType {
  TEMPERATURE = 'TEMPERATURE',
  HUMIDITY = 'HUMIDITY',
  PRESSURE = 'PRESSURE',
  AIRFLOW = 'AIRFLOW',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
}

export enum AlertTriggerSelectionBasis {
  SOURCE = 'SOURCE',
  DEVICE = 'DEVICE',
  ALERT = 'ALERT',
}

export enum AlertTriggerComparator {
  NOT_EQUAL_TO = 'NOT_EQUAL_TO',
  EQUAL_TO = 'EQUAL_TO',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO',
  IN_RANGE = 'IN_RANGE',
  NOT_IN_RANGE = 'NOT_IN_RANGE',
  IN_COLLECTION = 'IN_COLLECTION',
  NOT_IN_COLLECTION = 'NOT_IN_COLLECTION',
}

export const AlertTriggerComparatorToStringMap: Record<
  AlertTriggerComparator,
  string | string[]
> = {
  NOT_EQUAL_TO: 'not equal to',
  EQUAL_TO: '=',
  LESS_THAN: '<',
  LESS_THAN_OR_EQUAL_TO: '<=',
  GREATER_THAN: '>',
  GREATER_THAN_OR_EQUAL_TO: '>=',
  IN_RANGE: 'in range',
  NOT_IN_RANGE: 'not in range',
  IN_COLLECTION: 'is in the collection',
  NOT_IN_COLLECTION: 'is not in the collection',
};

export type AlertTriggerComparatorToMongoOperatorConverter = (
  inputValue: string,
  valueType: AlertTriggerComparatorValueType,
) => string[] | null;

const validFloatAlertTriggerRangeRegEx =
  /* eslint-disable-next-line no-useless-escape */
  /^[\(\[](\d+(\.\d+)?|\d+),(\d+(\.\d+)?|\d+)[\)\]]/;

/* eslint-disable-next-line no-useless-escape */
const validIntegerAlertTriggerRangeRegEx = /^[\(\[](\d+),(\d+)[\)\]]/;
const calculateInRangeOperators = (inputValue: string) => [
  inputValue.includes('(') ? '$gt' : '$gte',
  inputValue.includes(')') ? '$lt' : '$lte',
];
const calculateNotInRangeOperators = (inputValue: string) => [
  inputValue.includes('(') ? '$lt' : '$lte',
  inputValue.includes(')') ? '$gt' : '$gte',
];

export const AlertTriggerComparatorToMongoOperatorMap: Record<
  AlertTriggerComparator,
  AlertTriggerComparatorToMongoOperatorConverter
> = {
  NOT_EQUAL_TO: () => ['$ne'],
  EQUAL_TO: () => ['$eq'],
  LESS_THAN: () => ['$lt'],
  LESS_THAN_OR_EQUAL_TO: () => ['$lte'],
  GREATER_THAN: () => ['$gt'],
  GREATER_THAN_OR_EQUAL_TO: () => ['$gte'],
  IN_RANGE: (
    inputValue: string,
    valueType: AlertTriggerComparatorValueType,
  ) => {
    if (
      [
        AlertTriggerComparatorValueType.BOOLEAN,
        AlertTriggerComparatorValueType.STRING,
      ].includes(valueType)
    ) {
      console.log(
        `Invalid timestamp range value for AlertTrigger: ${inputValue}.`,
      );
      return null;
    } else if (
      AlertTriggerComparatorValueType.DATE === valueType &&
      validIntegerAlertTriggerRangeRegEx.test(inputValue)
    ) {
      return calculateInRangeOperators(inputValue);
    } else if (
      AlertTriggerComparatorValueType.DATE !== valueType &&
      validFloatAlertTriggerRangeRegEx.test(inputValue)
    ) {
      return calculateInRangeOperators(inputValue);
    } else {
      console.log(
        `Invalid timestamp range value for AlertTrigger: ${inputValue}.`,
      );
      return null;
    }
  },
  NOT_IN_RANGE: (
    inputValue: string,
    valueType: AlertTriggerComparatorValueType,
  ) => {
    if (
      [
        AlertTriggerComparatorValueType.BOOLEAN,
        AlertTriggerComparatorValueType.STRING,
      ].includes(valueType)
    ) {
      console.log(
        `Invalid timestamp range value for AlertTrigger: ${inputValue}.`,
      );
      return null;
    } else if (
      AlertTriggerComparatorValueType.DATE === valueType &&
      validIntegerAlertTriggerRangeRegEx.test(inputValue)
    ) {
      return calculateNotInRangeOperators(inputValue);
    } else if (
      AlertTriggerComparatorValueType.DATE !== valueType &&
      validFloatAlertTriggerRangeRegEx.test(inputValue)
    ) {
      return calculateNotInRangeOperators(inputValue);
    } else {
      console.log(
        `Invalid timestamp range value for AlertTrigger: ${inputValue}.`,
      );
      return null;
    }
  },
  IN_COLLECTION: () => ['$in'],
  NOT_IN_COLLECTION: () => ['$nin'],
};

export type AlertTriggerMongoEntitySelectionOperator = '$and' | '$or';
export type AlertTriggerMongoEntitySelectionPrimitiveValue =
  | string
  | number
  | boolean;
export type AlertTriggerMongoEntitySelectionLeafClause = Record<
  string,
  AlertTriggerMongoEntitySelectionPrimitiveValue
>;
export type AlertTriggerMongoEntitySelectionClause = Record<
  string,
  | AlertTriggerMongoEntitySelectionPrimitiveValue
  | AlertTriggerMongoEntitySelectionLeafClause
  | AlertTriggerMongoEntitySelectionLeafClause[]
  | Record<
      string,
      | AlertTriggerMongoEntitySelectionPrimitiveValue
      | AlertTriggerMongoEntitySelectionLeafClause
      | AlertTriggerMongoEntitySelectionLeafClause[]
    >
  | Record<
      AlertTriggerMongoEntitySelectionOperator,
      | AlertTriggerMongoEntitySelectionLeafClause
      | AlertTriggerMongoEntitySelectionLeafClause[]
    >
>;
export type AlertTriggerMongoEntitySelectionCompoundClause =
  | AlertTriggerMongoEntitySelectionClause
  | AlertTriggerMongoEntitySelectionClause[]
  | Record<
      string,
      | AlertTriggerMongoEntitySelectionPrimitiveValue
      | AlertTriggerMongoEntitySelectionClause
      | AlertTriggerMongoEntitySelectionClause[]
    >
  | Record<
      string,
      | AlertTriggerMongoEntitySelectionPrimitiveValue
      | AlertTriggerMongoEntitySelectionClause
      | AlertTriggerMongoEntitySelectionClause[]
    >[]
  | Record<
      AlertTriggerMongoEntitySelectionOperator,
      | AlertTriggerMongoEntitySelectionClause
      | AlertTriggerMongoEntitySelectionClause[]
    >[];

export type AlertTriggerMongoEntitySelectionFilter = Record<
  string,
  | AlertTriggerMongoEntitySelectionPrimitiveValue
  | AlertTriggerMongoEntitySelectionClause
  | AlertTriggerMongoEntitySelectionClause[]
  | AlertTriggerMongoEntitySelectionCompoundClause
  | AlertTriggerMongoEntitySelectionCompoundClause[]
  | Record<
      string,
      | AlertTriggerMongoEntitySelectionPrimitiveValue
      | AlertTriggerMongoEntitySelectionClause
      | AlertTriggerMongoEntitySelectionClause[]
      | AlertTriggerMongoEntitySelectionCompoundClause
      | AlertTriggerMongoEntitySelectionCompoundClause[]
    >
  | Record<
      AlertTriggerMongoEntitySelectionOperator,
      | AlertTriggerMongoEntitySelectionClause
      | AlertTriggerMongoEntitySelectionClause[]
      | AlertTriggerMongoEntitySelectionCompoundClause
      | AlertTriggerMongoEntitySelectionCompoundClause[]
    >
>;

export interface NamedEntity {
  name: string;
  _id: string;
  id: string;
}

export interface Unit extends NamedEntity {
  buildingId: string;
  floorId: string;
}

export type LookupMap = Record<string, string>;

export type UnitLookupMap = Record<string, Unit>;
