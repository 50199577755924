import * as React from 'react';
import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../routes/app-routes';
import { unitBreadcrumbRoutes } from '../../routes/units-routes';
import {
  Divider,
  MenuItem,
  MenuList,
  Paper,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { PropertyDetail } from '../../features/properties/property-detail';
import { PropertyDetailBreadcrumb } from '../../features/properties/property-detail-breadcrumbs';
import { propertyBreadcrumbRoutes } from '../../routes/property-routes';

interface BottomBreadcrumbsProps {
  open: boolean;
  anchorHistoryEl?: HTMLElement | null;
  onClose: any;
}

// TODO: Peter/Calvin: add comments about why we are doing something special with the breadcrumbs routes for units.
export function BottomBreadcrumbs(props: BottomBreadcrumbsProps) {
  const navigate = useNavigate();
  const propRoute = {
    path: 'properties/:id',
    element: <PropertyDetail />,
    breadcrumb: ({
      match: {
        params: { id },
      },
    }) => <PropertyDetailBreadcrumb id={id} />,
  } as BreadcrumbsRoute;

  const breadcrumbs = useBreadcrumbs([
    ...propertyBreadcrumbRoutes,
    ...unitBreadcrumbRoutes,
    propRoute,
    ...appRoutes.filter((x) => x.path !== 'properties/:id/*'),
  ]);
  const itemsToShowInPopover: JSX.Element[] = [];

  breadcrumbs.forEach(({ match, breadcrumb }, index) => {
    if (index === 0) {
      itemsToShowInPopover.push(
        <div>
          <Typography variant="h4" sx={{ padding: '10px 16px' }}>
            History
          </Typography>
          <Divider />,
        </div>,
      );
    }
    if (Array.isArray(itemsToShowInPopover)) {
      if (match.pathname !== '/') {
        itemsToShowInPopover.push(
          <MenuItem
            key={index}
            onClick={() => {
              navigate(match.pathname);
              props.onClose();
            }}
          >
            <ListItemText>{breadcrumb}</ListItemText>
          </MenuItem>,
        );
      }
    }
  });

  return (
    <Popover
      style={{
        marginLeft: '10px',
        marginRight: '10px',
        paddingLeft: '5px',
        paddingRight: '5px',
      }}
      open={props.open}
      onClose={props.onClose}
      anchorEl={props.anchorHistoryEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Paper sx={{ width: 320, maxWidth: '100%' }}>
        <MenuList>{itemsToShowInPopover}</MenuList>
      </Paper>
    </Popover>
  );
}
