import React, { useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import _ from 'lodash';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  AreaChart,
  Area,
  TooltipProps,
  ReferenceArea,
  LegendProps,
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CheckIcon from '@mui/icons-material/Check';
import HelpIcon from '@mui/icons-material/Help';
import {
  useUnitDevicesQuery,
  useChartDataQuery,
  useUnitAndPropertyBasicsQuery,
  ChartDataQuery,
  ChartVisType,
  UnitDevicesQuery,
  ChartType,
  NumericDatapoint,
  CategoricalDatapoint,
  UnitAndPropertyBasicsQuery,
  Unit,
} from '../../../../types/generated-types';
import { useQueryHook } from '../../../system/services/statusNotifier';
import { QueryResultsWrapper } from '../../shared/query-results-wrapper';
import { Notifier } from '../../../system/services/notificationManager';
import FullWidthLoadingSkeleton from '../../shared/fullWidthLoadingSkeleton';
import './unit-charts.css';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../../system/services/injectableComponentsManager';
import { basePropertyMenuItems } from '../../properties/ui/base-property-context-menu';
import EditIcon from '@mui/icons-material/Edit';
import AddChartIcon from '@mui/icons-material/Addchart';
import { useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});

// ISO date format (date only, no time)
const ISO_DATE_FORMAT = 'YYYY-MM-DD';

type Chart = ChartDataQuery['chartData'][number];
type Series = Chart['series'][number];
type LabeledSeries = Series & { key: string; label: string };
type LabeledChart = Chart & { series: LabeledSeries[] };
type Device = UnitDevicesQuery['devices'][number];

type AggregateInterval = 'day' | 'week' | 'month';

// TODO: Calvin: finalize chart color palette, and maybe add to mui theme object?
const colors = [
  '#fd7f6f',
  '#7eb0d5',
  '#b2e061',
  '#bd7ebe',
  '#ffb55a',
  '#ffee65',
  '#beb9db',
  '#fdcce5',
  '#8bd3c7',
];

// Default selectable time periods
enum TimePeriod {
  '24h' = '24h',
  '7d' = '7d',
  '1m' = '1m',
  '3m' = '3m',
  'Custom' = 'Custom',
}

// TODO: EnhancedCharting: consider breaking this into multiple subcomponents, if possible
export default function UnitCharts() {
  const { unitId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const period = searchParams.get('period');
  const start = searchParams.get('start');
  const end = searchParams.get('end');
  const devices = searchParams.get('devices');
  const charts = searchParams.get('charts');

  const theme = useTheme();

  const now = dayjs();
  const [selectedPeriod, setSelectedPeriod] = useState(TimePeriod['24h']);
  const [selectedStart, setSelectedStart] = useState(
    now.subtract(1, 'day').format(),
  );
  const [selectedEnd, setSelectedEnd] = useState(now.format());
  const [rotateLabels, setRotateLabels] = useState(false);
  const [checkedDevices, setCheckedDevices] = useState<{
    [deviceId: string]: boolean;
  }>({});
  const [checkedCharts, setCheckedCharts] = useState<{
    [chartType: string]: boolean;
  }>({});
  const [zoomInEnabled, setZoomInEnabled] = useState(false);
  const [refArea1, setRefArea1] = useState('');
  const [refArea2, setRefArea2] = useState('');

  const [unit, setUnit] = useState<UnitAndPropertyBasicsQuery['unitById']>();

  const { data: unitData } = useUnitAndPropertyBasicsQuery({
    variables: {
      id: unitId ?? '',
    },
  });

  useEffect(() => {
    if (unitData?.unitById) {
      setUnit(unitData.unitById);
    }
  }, [unitData]);

  function handleDeviceCheck(deviceId: string, checked: boolean) {
    const newCheckedDevices = {
      ...checkedDevices,
      [deviceId]: checked,
    };
    setCheckedDevices(newCheckedDevices);

    if (checkedDevices[deviceId] && !newCheckedDevices[deviceId]) {
      // Device was unchecked - uncheck any charts that no longer apply to the remaining devices
      const availableCharts = getAvailableCharts(
        getCheckedDevices(newCheckedDevices),
      );
      const newCheckedCharts = { ...checkedCharts };
      for (const chart in newCheckedCharts) {
        if (!availableCharts.has(chart as ChartType)) {
          newCheckedCharts[chart] = false;
        }
      }
      setCheckedCharts(newCheckedCharts);
    }
  }

  function handleChartCheck(chartType: string, checked: boolean) {
    setCheckedCharts({
      ...checkedCharts,
      [chartType]: checked,
    });
  }

  // Use the given URL params to set the initially selected start date, end date devices, and chart types
  useEffect(() => {
    if (period) {
      setSelectedPeriod(period as TimePeriod);
    }
    if (start) {
      setSelectedStart(start);
    }
    if (end) {
      setSelectedEnd(end);
    }
    if (devices) {
      const newCheckedDevices: { [deviceId: string]: boolean } = {};
      devices.split(',').forEach((deviceId) => {
        newCheckedDevices[deviceId] = true;
      });
      setCheckedDevices(newCheckedDevices);
    }
    if (charts) {
      const newCheckedCharts: { [chartType: string]: boolean } = {};
      charts.split(',').forEach((chartType) => {
        newCheckedCharts[chartType] = true;
      });
      setCheckedCharts(newCheckedCharts);
    }
  }, [period, start, end, devices, charts]);

  useEffect(() => {
    const periodLength =
      (new Date(selectedEnd).valueOf() - new Date(selectedStart).valueOf()) /
      1000 /
      60 /
      60 /
      24;
    setRotateLabels(periodLength < 2);
  }, [selectedEnd, selectedStart]);
  // Load all devices assigned to the current unit
  const { loading, error, data } = useUnitDevicesQuery({
    variables: { unitId: unitId || '' },
    skip: !unitId,
  });

  useEffect(() => {
    if (!loading && error) {
      Notifier.error(
        'There was a problem fetching device information: ',
        error,
      );
    }
  }, [loading, error]);

  // Only include devices that have chartable data. Sort by name, group by type
  const unitDevices = [...(data?.devices || [])]
    .filter((d) => d.chartTypes.length > 0)
    .sort((a, b) => formatDeviceName(a).localeCompare(formatDeviceName(b)))
    .sort((a, b) => a.type!.localeCompare(b.type!));

  // Filter unit devices to only those that are checked
  function getCheckedDevices(checkedDevicesMap: {
    [deviceId: string]: boolean;
  }) {
    return unitDevices.filter((d) => checkedDevicesMap[d.deviceId ?? '']);
  }

  // Get combined set of all available charts for the given devices
  function getAvailableCharts(
    devices: UnitDevicesQuery['devices'],
  ): Set<ChartType> {
    const charts = new Set<ChartType>();
    devices.forEach((device) => {
      device.chartTypes.forEach((type) => {
        charts.add(type);
      });
    });
    return charts;
  }

  // Construct list of available chart types based on what devices are selected
  const availableCharts = [
    ...getAvailableCharts(getCheckedDevices(checkedDevices)),
  ];

  function toggleAllDevices() {
    const checkedCount = getCheckedDevices(checkedDevices).length;
    const totalCount = unitDevices.length;
    if (checkedCount === totalCount) {
      // All devices selected - deselect all devices (and charts)
      setCheckedDevices({});
      setCheckedCharts({});
    } else {
      // Otherwise select all devices
      const newCheckedDevices = { ...checkedDevices };
      unitDevices.forEach((d) => {
        newCheckedDevices[d.deviceId ?? ''] = true;
      });
      setCheckedDevices(newCheckedDevices);
    }
  }

  function toggleAllCharts() {
    const checkedCount = Object.entries(checkedCharts).filter(
      ([, checked]) => checked,
    ).length;
    const totalCount = availableCharts.length;
    if (checkedCount === totalCount) {
      // All charts selected - deselect them all
      setCheckedCharts({});
    } else {
      // Otherwise select all charts
      const newCheckedCharts = { ...checkedCharts };
      availableCharts.forEach((c) => {
        newCheckedCharts[c] = true;
      });
      setCheckedCharts(newCheckedCharts);
    }
  }

  // Load chart data, based on the params stored in the URL
  const {
    loading: chartLoading,
    error: chartError,
    data: chartData,
  } = useQueryHook(
    useChartDataQuery,
    {
      variables: {
        startDate: start || '',
        endDate: end || '',
        deviceIds: devices?.split(',') || [],
        chartTypes: (charts?.split(',') as ChartType[]) || [],
      },
      skip: !start || !end || !devices || !charts,
    },
    'Loading chart data ...',
    'Rendering ...',
    1500,
  );

  useEffect(() => {
    if (!chartLoading && chartError) {
      console.error(chartError);
    }
  }, [chartLoading, chartError]);

  // Update the URL search params based on current form values. Setting the params will trigger a re-fetch of chart data
  function loadCharts() {
    if (validateForm()) {
      const devices = Object.keys(checkedDevices)
        .filter((deviceId) => checkedDevices[deviceId])
        .join(',');

      const charts = Object.keys(checkedCharts)
        .filter((chartType) => checkedCharts[chartType])
        .join(',');

      setSearchParams({
        period: selectedPeriod,
        start: selectedStart,
        end: selectedEnd,
        devices,
        charts,
      });

      if (zoomInEnabled) {
        toggleZoomIn();
      }
    }
  }

  // Ensure form values are valid, and if not set an appropriate error message
  function validateForm(): boolean {
    if (!selectedStart) {
      Notifier.error('Start date is required');
      return false;
    } else if (!selectedEnd) {
      Notifier.error('End date is required');
      return false;
    } else if (!dayjs(selectedEnd).isAfter(selectedStart)) {
      Notifier.error('Start date must be before end date');
      return false;
    } else if (!Object.values(checkedDevices).includes(true)) {
      Notifier.error('At least 1 device must be selected');
      return false;
    } else if (!Object.values(checkedCharts).includes(true)) {
      Notifier.error('At least 1 chart type must be selected');
      return false;
    } else {
      return true;
    }
  }

  // Add a label to each chart series
  const labeledCharts: LabeledChart[] =
    chartData?.chartData.map((chart) => {
      return {
        ...chart,
        series: chart.series.map((s) => {
          const device = data?.devices.find((dv) => dv.deviceId === s.deviceId);
          let label: string = device ? formatDeviceName(device) : s.deviceId;
          // Handle cases where multiple reading types are displayed on a single chart
          if (device?.__typename === 'PowerMonitor') {
            if (s.readingType.includes('phaseA')) {
              label += ' (Phase A)';
            } else if (s.readingType.includes('phaseB')) {
              label += ' (Phase B)';
            } else if (s.readingType.includes('phaseC')) {
              label += ' (Phase C)';
            } else if (s.readingType.includes('threePhase')) {
              label += ' (Combined)';
            }
          } else if (device?.__typename === 'ModbusAirHandler') {
            label += `: ${_.startCase(s.readingType)}`;
            label = label
              .replace('Zmb', 'ZMB')
              .replace('Cpu', 'CPU')
              .replace('H 2 O', 'H₂O')
              .replace('Recvd', 'Received')
              .replace('Ahu', 'AHU');
          }
          const key = label + '_' + s.readingType;
          return {
            ...s,
            key,
            label,
          };
        }),
      };
    }) || [];

  // Format reading type for display
  function formatReadingType(type: string) {
    return _.lowerCase(type)
      .split(' ')
      .map((s) => _.upperFirst(s))
      .join(' ')
      .replace('Zmb', 'ZMB')
      .replace('Cpu', 'CPU')
      .replace('H 2 O', 'H₂O')
      .replace('Recvd', 'Received')
      .replace('Ahu', 'AHU');
  }

  // Format chart type for display
  function formatChartType(type: string) {
    return _.lowerCase(
      type.replace('TSTAT_', 'THERMOSTAT_').replace('PM_', 'POWER_MONITOR_'),
    )
      .split(' ')
      .map((s) => _.upperFirst(s))
      .join(' ')
      .replace('Zmb', 'ZMB')
      .replace('Cpu', 'CPU')
      .replace('H 2 O', 'H₂O')
      .replace('Recvd', 'Received')
      .replace('Ahu', 'AHU')
      .replace('Spo', '');
  }

  // Format device name for display
  function formatDeviceName(device: Device) {
    let name: string | null | undefined;
    if (device.panelId) {
      const panel = data?.unitById?.panels.find(
        (p) => p?._id === device.panelId,
      );
      switch (device.__typename) {
        case 'PowerMonitor':
        case 'OpenWeather':
        case 'OutdoorSensor':
          name = `${panel?.displayName}`;
          break;
        case 'VSDPump':
        case 'Thermistor':
        case 'PressureSensor':
          if (panel?.type === 'Loop') {
            // e.g 'Building Loop: Primary', 'Cooling Loop: Supply', etc.
            name = `${panel?.role} ${panel?.type}: ${device.role}`;
          }
          break;
        case 'Boiler':
          // e.g 'Lead', 'Lag', etc.
          name = `${device.role}`;
      }
    }
    if (!name) {
      name = device.name || device.meta?.name || device.deviceId;
    }
    return device.typeDisplayName + ' - ' + name;
  }

  // Format x-axis ticks (timestamps)
  function tickFormatter(t: string): string {
    return formatChartDate(dayjs(t), rotateLabels);
  }

  // Format y-axis ticks (percentage)
  function percentageTickFormatter(t: number): string {
    return t * 100 + '%';
  }

  // Format a date for display, based on the selected time range
  function formatChartDate(
    date: Dayjs,
    useShortFormat = false,
    forceIncludeTime = false,
  ): string {
    const start = dayjs(selectedStart);
    const end = dayjs(selectedEnd);
    let shortFormat;
    let longFormat;
    if (start.add(2, 'days').isAfter(end) || forceIncludeTime) {
      shortFormat = 'M/D h:mma';
      longFormat = 'M/D/YY h:mma';
    } else if (start.add(1, 'year').isAfter(end)) {
      shortFormat = 'M/D';
      longFormat = 'M/D/YY';
    } else {
      shortFormat = 'M/D/YY';
      longFormat = 'M/D/YY';
    }
    return date.format(useShortFormat ? shortFormat : longFormat);
  }

  function renderChartTooltip(args: {
    visType: ChartVisType;
    series: LabeledSeries[];
    units?: string;
    aggregateInterval?: AggregateInterval;
  }) {
    if (zoomInEnabled) {
      // Do not display tooltip while zoom in is enabled - it gets in the way of seeing what area of the chart
      // is being highlighted
      return <></>;
    }

    // Custom chart tooltip component
    const ChartTooltip = ({
      active,
      payload,
      label,
    }: TooltipProps<any, any>) => {
      // `label` is the timestamp of the current datapoint (milliseconds), use that unless the datapoint includes
      // a `tooltipTimestamp` field to use as an override
      const timestamp =
        payload && payload[0]?.payload?.tooltipTimestamp
          ? payload[0].payload.tooltipTimestamp
          : label;
      const tooltipTime = dayjs(timestamp);
      const isTimeInterval = args.aggregateInterval;
      const includeTime = !isTimeInterval;
      let timestampLabel = formatChartDate(tooltipTime, false, includeTime);
      if (isTimeInterval) {
        timestampLabel += ` - ${formatChartDate(
          tooltipTime.add(1, args.aggregateInterval),
          false,
        )} (1${args.aggregateInterval})`;
      }
      if (active) {
        return (
          <Paper sx={{ p: 1 }}>
            <Typography variant="subtitle2">{timestampLabel}</Typography>
            {payload?.map((entry, index) => {
              // `payload` contains an entry for each chart series. For each entry, grab the matching series,
              // then find/calculate the datapoint to display at this timestamp. We need to do this manually
              // for series of type NumericChartSeries and CategoricalChartSeries because of a bug in Recharts:
              // https://github.com/recharts/recharts/issues/3387. Essentially, Recharts will only calculate
              // `entry.value` correctly if each series contains the exact same number of data points, at the
              // exact same timestamps. This is true of AggregateCategoricalChartSeries, where aggregate values
              // are calculated at evenly-spaced intervals, but not true for our other series.
              const matchingSeries = args.series.find(
                (s) => s.label === entry.name,
              );
              let np: Partial<NumericDatapoint> | undefined;
              let cp: Partial<CategoricalDatapoint> | undefined;
              if (matchingSeries?.__typename === 'NumericChartSeries') {
                // Handle numeric chart series. First, see if there's a datapoint for this exact timestamp.
                np = matchingSeries.numericData.find((d) =>
                  tooltipTime.isSame(d.timestamp),
                );
                if (!np) {
                  // No datapoint at this exact time. Find the closest two data points before and after this time,
                  // and use them to calculate an estimated intermediate value.
                  let np1: Partial<NumericDatapoint> | undefined;
                  let np2: Partial<NumericDatapoint> | undefined;
                  // Ensure data is sorted in ascending order
                  const data = [...matchingSeries.numericData].sort(
                    (a, b) =>
                      dayjs(a.timestamp).valueOf() -
                      dayjs(b.timestamp).valueOf(),
                  );
                  for (const d of data) {
                    if (tooltipTime.isAfter(d.timestamp)) {
                      np1 = d;
                    } else {
                      np2 = d;
                      break;
                    }
                  }
                  if (np1 && np2) {
                    const v1 = np1.value ?? 0;
                    const v2 = np2.value ?? 0;
                    const t1 = dayjs(np1.timestamp);
                    const t2 = dayjs(np2.timestamp);
                    const totalTimeDiff = t2.valueOf() - t1.valueOf();
                    const firstTimeDiff = tooltipTime.valueOf() - t1.valueOf();
                    const fraction = firstTimeDiff / totalTimeDiff;
                    const estimatedOffset = Math.abs(v1 - v2) * fraction;
                    if (v1 < v2) {
                      const value = v1 + estimatedOffset;
                      np = {
                        ...np1,
                        timestamp: tooltipTime.toISOString(),
                        value,
                      };
                    } else if (v1 > v2) {
                      const value = v1 - estimatedOffset;
                      np = {
                        ...np1,
                        timestamp: tooltipTime.toISOString(),
                        value,
                      };
                    } else {
                      np = { ...np1, timestamp: tooltipTime.toISOString() };
                    }
                  } else if (np1) {
                    np = np1;
                  } else if (np2) {
                    np = np2;
                  }
                }
              } else if (
                matchingSeries?.__typename === 'CategoricalChartSeries'
              ) {
                // Handle categorical chart series. First, see if there's a datapoint for this exact timestamp.
                cp = matchingSeries.categoricalData.find((d) =>
                  tooltipTime.isSame(d.timestamp),
                );
                if (!cp) {
                  // No datapoint at this exact time. Find the closest datapoint prior to this time.
                  // Ensure data is sorted in ascending order
                  const data = [...matchingSeries.categoricalData].sort(
                    (a, b) =>
                      dayjs(a.timestamp).valueOf() -
                      dayjs(b.timestamp).valueOf(),
                  );
                  for (const d of data) {
                    if (tooltipTime.isAfter(d.timestamp)) {
                      cp = d;
                    } else {
                      break;
                    }
                  }
                }
              }
              if (np || cp) {
                // Tooltip for numeric or categorical charts
                const numDecimals =
                  args.visType === ChartVisType.NumericLine ? 2 : 0;
                const displayVal = np
                  ? np.value?.toFixed(numDecimals)
                  : cp?.value;
                return (
                  <Typography key={index} variant="body2" lineHeight={1.2}>
                    <span style={{ color: entry.color }}>{entry.name}:</span>{' '}
                    <span>
                      {displayVal}
                      {args.units || ''}
                    </span>
                  </Typography>
                );
              } else {
                // Tooltip for aggregate categorical charts
                return (
                  <Typography key={index} variant="body2" lineHeight={1.2}>
                    <span style={{ color: entry.color }}>{entry.name}:</span>{' '}
                    <span>
                      {entry.value.toFixed(2)}
                      {args.units || ''}
                    </span>
                  </Typography>
                );
              }
            })}
          </Paper>
        );
      }

      return null;
    };

    return <ChartTooltip />;
  }

  function renderLegend() {
    const ChartLegend = ({ payload }: LegendProps) => {
      return (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {payload?.map((p) => {
            return (
              <div
                key={p.value}
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: 10,
                    height: 10,
                    marginRight: 5,
                    backgroundColor: p.color,
                  }}
                />
                <Typography
                  color={p.color}
                  variant="body2"
                  lineHeight={1.2}
                  marginRight={2}
                >
                  {p.value}
                </Typography>
              </div>
            );
          })}
        </div>
      );
    };

    return <ChartLegend />;
  }

  function ChartHeader(props: { chartName: string }) {
    return (
      <>
        <Typography variant="h5" fontWeight="bold">
          {props.chartName}
        </Typography>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            color="info"
            onClick={zoomLeft}
            sx={{ mt: 2 }}
            startIcon={<ChevronLeftIcon />}
            size="small"
          >
            -{leftZoom.from(start, true)}
          </Button>
          <Button
            color="info"
            onClick={toggleZoomIn}
            sx={{ mt: 2 }}
            startIcon={<ZoomInIcon />}
            size="small"
          >
            {zoomInEnabled ? 'Cancel Zoom In' : 'Zoom In'}
          </Button>
          <Button
            color="info"
            onClick={zoomRight}
            sx={{ mt: 2 }}
            endIcon={<ChevronRightIcon />}
            size="small"
          >
            +{rightZoom.from(end, true)}
          </Button>
        </div>
      </>
    );
  }

  // Zoom in based on the highlighted reference area of the chart
  function zoomIn() {
    const r1 = dayjs(refArea1);
    const r2 = dayjs(refArea2);
    const start = r1.isBefore(r2) ? r1.format() : r2.format();
    const end = r1.isBefore(r2) ? r2.format() : r1.format();
    const devices = Object.keys(checkedDevices)
      .filter((deviceId) => checkedDevices[deviceId])
      .join(',');
    const charts = Object.keys(checkedCharts)
      .filter((chartType) => checkedCharts[chartType])
      .join(',');
    setSearchParams({
      period: TimePeriod.Custom,
      start,
      end,
      devices,
      charts,
    });
    setRefArea1('');
    setRefArea2('');
  }

  const timeDiff = dayjs(end).diff(dayjs(start));
  const leftZoom = dayjs(start).subtract(0.5 * timeDiff, 'ms');
  const rightZoom = dayjs(end).add(0.5 * timeDiff, 'ms');

  // Zoom out, extending time period to the left
  function zoomLeft() {
    setSearchParams({
      period: TimePeriod.Custom,
      start: leftZoom.format(),
      end: end || '',
      devices: devices || '',
      charts: charts || '',
    });
  }

  // Zoom out, extending time period to the right
  function zoomRight() {
    setSearchParams({
      period: TimePeriod.Custom,
      start: start || '',
      end: rightZoom.format(),
      devices: devices || '',
      charts: charts || '',
    });
  }

  // Toggle zoom in mode
  function toggleZoomIn() {
    setZoomInEnabled(!zoomInEnabled);
    if (!zoomInEnabled) {
      // Display toast with instructions for zooming in
      Notifier.info(
        'Zoom in enabled. Highlight an area of the chart to zoom in.',
        null,
        { toastId: 'zoomInToast' },
      );
    } else {
      // Dismiss instructions toast if it's still displayed
      toast.dismiss('zoomInToast');
    }
  }

  function handleTimePeriodChange(tp: TimePeriod) {
    // `tp` will be null if the user clicks an already selected time period button.
    // In that case, rather than deselecting the period, it should stay the same.
    const newPeriod = tp ?? selectedPeriod;
    const now = dayjs();
    let newStart: string, newEnd: string;
    switch (newPeriod) {
      case TimePeriod['24h']:
        newEnd = now.format();
        newStart = now.subtract(1, 'day').format();
        break;
      case TimePeriod['7d']:
        newEnd = now.format();
        newStart = now.subtract(7, 'days').format();
        break;
      case TimePeriod['1m']:
        newEnd = now.format();
        newStart = now.subtract(1, 'month').format();
        break;
      case TimePeriod['3m']:
        newEnd = now.format();
        newStart = now.subtract(3, 'months').format();
        break;
      default:
        newEnd = selectedEnd;
        newStart = selectedStart;
        break;
    }
    setSelectedPeriod(newPeriod);
    setSelectedStart(newStart);
    setSelectedEnd(newEnd);
  }

  function areaChartData(series: {
    __typename: 'AggregateCategoricalChartSeries';
    distinctValues: string[];
    aggregateInterval: string;
    readingType: string;
    deviceId: string;
    units?: string | null | undefined;
    aggregateCategoricalData: { timestamp: string }[];
  }) {
    const data = [...series.aggregateCategoricalData] as any[];
    const numDataPoints = series.aggregateCategoricalData.length;
    const lastDatapoint = series.aggregateCategoricalData[numDataPoints - 1];
    if (lastDatapoint.timestamp !== selectedEnd) {
      // Add an artificial datapoint to the end of the series, so that the fill of the area chart
      // extends to the edge of the graph. Use the previous datapoint's timestamp to calculate
      // the date range displayed in the tooltip, since this artificial datapoint actually
      // falls within the previous timestamp's date range
      const artificialEndDatapoint = {
        ...lastDatapoint,
        timestamp: selectedEnd,
        tooltipTimestamp: lastDatapoint.timestamp,
      };
      data.push(artificialEndDatapoint);
    }
    return data;
  }

  const isCustomTimePeriod = selectedPeriod === TimePeriod.Custom;
  const noSelectedDevices =
    Object.values(checkedDevices).filter((checked) => checked).length === 0;

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    setContextMenuItems,
    setSubtitle,
    setTitle,
    setTitlePath,
    setSubtitlePath,
  } = useInjectableComponents();

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (unit && unit.property && pathname) {
      const property = unit.property;
      const pathComponent = pathname.split('/');

      /* Only populate the list of tools here if we are in fact on the correct (non-installer) path */

      const items: BottomMenuItems = [
        ...basePropertyMenuItems(property._id, pathname),
      ];

      const editPath = [...pathComponent, 'edit-unit'].join('/');
      const viewPath = [...pathComponent, 'view-unit'].join('/');

      items.push({
        label: 'Unit Actions',
        items: [
          {
            id: 'view-unit-menu-item',
            label: 'View Unit Details',
            icon: <Icon icon="mdi:information-outline" />,
            action: () => {
              navigate(viewPath);
            },
            permit: {
              action: 'view',
              subject: unit as Unit,
            },
          },
          {
            id: 'edit-unit-menu-item',
            label: 'Edit Unit Details',
            icon: <EditIcon fontSize="small" />,
            action: () => {
              navigate(editPath);
            },
            permit: {
              action: 'update',
              subject: unit as Unit,
            },
          },
          {
            id: 'charts-unit-menu-item',
            label: 'View Charts',
            icon: <AddChartIcon fontSize="small" />,
            action: () => {
              navigate(pathname + '/charts');
            },
            permit: {
              action: 'viewCharts',
              subject: unit as Unit,
            },
          },
        ],
      });

      // items.push({
      //   label: 'Help',
      //   items: [
      //     {
      //       id: 'pendo-help-unit-charts',
      //       label: 'Charting Help',
      //       icon: <HelpIcon fontSize="small" />,
      //       permit: {
      //         action: 'viewCharts',
      //         subject: unit as Unit,
      //       },
      //     },
      //   ],
      // });

      setTitle(property.title ?? 'Untitled Property');
      setTitlePath(`/properties/${property._id}/summary`);

      let typeName;

      switch (unit.type) {
        case 'residential':
          typeName = isSmall ? 'residential' : 'Residential';
          break;
        case 'commonAreas':
          typeName = isSmall ? 'common' : 'Common Areas';
          break;
        case 'centralEquipment':
          typeName = isSmall ? 'central' : 'Central Equipment';
          break;
        default:
          typeName = isSmall ? 'unknown type)' : 'Unknown Unit Type';
          break;
      }

      setSubtitle(`Charts: ${unit?.name ?? 'Unnamed Unit'} (${typeName})`);
      setSubtitlePath(`/properties/${property._id}/units/${unit._id}`);

      setContextMenuItems(items.length ? items : undefined);
    }
  }, [unit, pathname]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card>
          <CardContent
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <FormGroup sx={{ overflowX: 'scroll', maxWidth: '100%', mb: 2 }}>
              <ToggleButtonGroup
                exclusive
                value={selectedPeriod}
                size="small"
                onChange={(e, v) => handleTimePeriodChange(v)}
                color="secondary"
              >
                <ToggleButton value={TimePeriod['24h']}>
                  {TimePeriod['24h']}
                </ToggleButton>
                <ToggleButton value={TimePeriod['7d']}>
                  {TimePeriod['7d']}
                </ToggleButton>
                <ToggleButton value={TimePeriod['1m']}>
                  {TimePeriod['1m']}
                </ToggleButton>
                <ToggleButton value={TimePeriod['3m']}>
                  {TimePeriod['3m']}
                </ToggleButton>
                <ToggleButton value={TimePeriod['Custom']}>
                  {TimePeriod['Custom']}
                </ToggleButton>
              </ToggleButtonGroup>
            </FormGroup>
            <FormGroup row={true} sx={{ mb: 2 }}>
              <FormControl sx={{ mr: 2 }}>
                <InputLabel htmlFor="selectedStart">Start Date</InputLabel>
                <Input
                  id="selectedStart"
                  type="date"
                  value={dayjs(selectedStart).format(ISO_DATE_FORMAT)}
                  onChange={(e) => setSelectedStart(e.target.value)}
                  disabled={!isCustomTimePeriod}
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="selectedEnd">End Date</InputLabel>
                <Input
                  id="selectedEnd"
                  type="date"
                  value={dayjs(selectedEnd).format(ISO_DATE_FORMAT)}
                  onChange={(e) => setSelectedEnd(e.target.value)}
                  disabled={!isCustomTimePeriod}
                />
              </FormControl>
            </FormGroup>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <FormGroup sx={{ mb: 2 }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <InputLabel sx={{ marginRight: 2 }}>Devices</InputLabel>
                      <Button
                        onClick={toggleAllDevices}
                        color="secondary"
                        startIcon={<CheckIcon />}
                        size="small"
                      >
                        Toggle All
                      </Button>
                    </div>
                    {unitDevices.map((d) => {
                      const id = d.deviceId || '';
                      return (
                        <FormControlLabel
                          key={id}
                          control={
                            <Checkbox
                              color="secondary"
                              checked={checkedDevices[id] ?? false}
                              onChange={(e) =>
                                handleDeviceCheck(id, e.target.checked)
                              }
                            />
                          }
                          label={formatDeviceName(d)}
                        />
                      );
                    })}
                  </FormGroup>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <FormGroup sx={{ mb: 2 }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <InputLabel sx={{ marginRight: 2 }}>
                        Chart Types
                      </InputLabel>
                      {!noSelectedDevices && (
                        <Button
                          onClick={toggleAllCharts}
                          color="secondary"
                          startIcon={<CheckIcon />}
                          size="small"
                        >
                          Toggle All
                        </Button>
                      )}
                    </div>
                    {noSelectedDevices && (
                      <Typography sx={{ mt: 1, fontStyle: 'italic' }}>
                        Select 1 or more device(s) to view available chart types
                      </Typography>
                    )}
                    {availableCharts.map((t) => {
                      return (
                        <FormControlLabel
                          key={t}
                          control={
                            <Checkbox
                              color="secondary"
                              checked={checkedCharts[t] ?? false}
                              onChange={(e) =>
                                handleChartCheck(t, e.target.checked)
                              }
                            />
                          }
                          label={formatChartType(t)}
                        />
                      );
                    })}
                  </FormGroup>
                </div>
              </Grid>
            </Grid>
            <Button onClick={loadCharts} color="secondary" variant="outlined">
              Load Charts
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <QueryResultsWrapper
          loading={chartLoading}
          loadingComponent={
            <>
              <FullWidthLoadingSkeleton padding={2} />
              <FullWidthLoadingSkeleton padding={2} />
              <FullWidthLoadingSkeleton padding={2} />
            </>
          }
        >
          {labeledCharts.map((chart) => {
            const { chartType, visType, series } = chart;
            const chartName = formatChartType(chartType);
            if (series.length === 0) {
              return (
                <Paper
                  key={chartName}
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: 4,
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    {chartName}
                  </Typography>
                  <Typography variant="body2">
                    No data for the selected time period
                  </Typography>
                </Paper>
              );
            }
            switch (visType) {
              case ChartVisType.NumericLine:
              case ChartVisType.NumericStep:
              case ChartVisType.CategoricalLine:
                const isNumeric =
                  visType === ChartVisType.NumericLine ||
                  visType === ChartVisType.NumericStep;
                const isNumericStep = visType === ChartVisType.NumericStep;
                const { readingType, units } = series[0] || {};
                const typeName = formatReadingType(readingType);
                let max = -1000000;
                let min = 1000000;
                let manualRange = false;
                if (isNumeric) {
                  for (const seriesItem of series) {
                    if (
                      seriesItem.__typename !== 'CategoricalChartSeries' &&
                      seriesItem.__typename !==
                        'AggregateCategoricalChartSeries'
                    ) {
                      for (const value of seriesItem.numericData) {
                        if (value.value > max) {
                          max = value.value;
                        }
                        if (value.value < min) {
                          min = value.value;
                        }
                      }
                    }
                  }
                  max = Math.ceil(max) + 1;
                  min = Math.floor(min) - 1;
                  manualRange = max - min < 5;
                }
                return (
                  <Paper
                    key={chartType}
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      height: 450,
                      marginBottom: 4,
                      overflowX: 'scroll',
                    }}
                  >
                    <ChartHeader chartName={chartName} />
                    <ResponsiveContainer
                      className={
                        rotateLabels ? 'embue-charts-rotated_labels' : ''
                      }
                    >
                      <LineChart
                        margin={{
                          top: 16,
                          right: 16,
                          bottom: 60,
                          left: 24,
                        }}
                        onMouseDown={(e) => {
                          if (zoomInEnabled && e?.activeLabel) {
                            setRefArea1(e.activeLabel);
                          }
                        }}
                        onMouseMove={(e) => {
                          if (zoomInEnabled && refArea1 && e?.activeLabel) {
                            setRefArea2(e.activeLabel);
                          }
                        }}
                        onMouseUp={() => {
                          if (zoomInEnabled && refArea1 && refArea2) {
                            zoomIn();
                          }
                        }}
                        style={{
                          cursor: zoomInEnabled ? 'col-resize' : 'crosshair',
                        }}
                      >
                        <XAxis
                          stroke={theme.palette.text.secondary}
                          style={theme.typography.caption}
                          dataKey={(v) => dayjs(v.timestamp).valueOf()}
                          type="number"
                          domain={['auto', 'auto']}
                          angle={rotateLabels ? -45 : 0}
                          tickCount={12}
                          tickMargin={rotateLabels ? 30 : 10}
                          tickFormatter={tickFormatter}
                        />
                        <YAxis
                          stroke={theme.palette.text.secondary}
                          style={theme.typography.caption}
                          height={300}
                          dataKey="value"
                          type={isNumeric ? 'number' : 'category'}
                          domain={manualRange ? [min, max] : ['auto', 'auto']}
                          interval={isNumeric ? 1 : undefined}
                          tickCount={isNumeric && manualRange ? 5 : undefined}
                          padding={{ top: 20, bottom: 20 }}
                          tickFormatter={
                            isNumeric
                              ? (t: number) =>
                                  Math.round(t * 10) / 10 + (units || '')
                              : undefined
                          }
                        >
                          <Label
                            angle={270}
                            position="left"
                            style={{
                              textAnchor: 'middle',
                              fill: theme.palette.text.primary,
                              ...theme.typography.caption,
                            }}
                          >
                            {series.length === 1 ? typeName : chartName}
                          </Label>
                        </YAxis>
                        <Tooltip
                          content={renderChartTooltip({
                            visType,
                            series,
                            units: units || '',
                          })}
                        />
                        <CartesianGrid strokeDasharray="3 3" />
                        {refArea1 && refArea2 ? (
                          <ReferenceArea
                            x1={refArea1}
                            x2={refArea2}
                            strokeOpacity={0.3}
                          />
                        ) : null}
                        <Legend content={renderLegend()} />
                        {series.map((s, index) => {
                          const ps = s as LabeledSeries;
                          if (s.__typename === 'NumericChartSeries') {
                            return (
                              <Line
                                key={ps.key}
                                name={ps.label}
                                data={s.numericData}
                                type={isNumericStep ? 'stepBefore' : 'monotone'}
                                dataKey="value"
                                stroke={colors[index % colors.length]}
                                strokeWidth={2}
                                connectNulls
                                dot={false}
                                activeDot={false}
                              />
                            );
                          } else if (
                            s.__typename === 'CategoricalChartSeries'
                          ) {
                            return (
                              <Line
                                key={ps.key}
                                name={ps.label}
                                data={s.categoricalData}
                                type="stepBefore"
                                dataKey="value"
                                stroke={colors[index % colors.length]}
                                strokeWidth={2}
                                connectNulls
                                dot={false}
                                activeDot={false}
                              />
                            );
                          }
                          return null;
                        })}
                      </LineChart>
                    </ResponsiveContainer>
                  </Paper>
                );
              case ChartVisType.Area:
                return series.map((series) => {
                  const { readingType } = series;
                  const typeName = formatReadingType(readingType);
                  const device = data?.devices.find(
                    (d) => d.deviceId === series.deviceId,
                  );
                  const deviceName = device
                    ? formatDeviceName(device)
                    : series.deviceId;
                  const fullChartName = `${chartName}: ${deviceName}`;
                  return (
                    <Paper
                      key={fullChartName}
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: 450,
                        marginBottom: 4,
                        overflowX: 'scroll',
                      }}
                    >
                      <ChartHeader chartName={fullChartName} />
                      <ResponsiveContainer
                        className={
                          rotateLabels ? 'embue-charts-rotated_labels' : ''
                        }
                      >
                        {series.__typename ===
                        'AggregateCategoricalChartSeries' ? (
                          <AreaChart
                            data={areaChartData(series)}
                            margin={{
                              top: 16,
                              right: 16,
                              bottom: 0,
                              left: 24,
                            }}
                            stackOffset="expand"
                            onMouseDown={(e) => {
                              if (e?.activeLabel) {
                                setRefArea1(e.activeLabel);
                              }
                            }}
                            onMouseMove={(e) => {
                              if (refArea1 && e?.activeLabel) {
                                setRefArea2(e.activeLabel);
                              }
                            }}
                            onMouseUp={() => {
                              if (refArea1 && refArea2) {
                                zoomIn();
                              }
                            }}
                            style={{
                              cursor: zoomInEnabled
                                ? 'col-resize'
                                : 'crosshair',
                            }}
                          >
                            <XAxis
                              stroke={theme.palette.text.secondary}
                              style={theme.typography.caption}
                              dataKey={(v) => dayjs(v.timestamp).valueOf()}
                              type="number"
                              domain={['auto', 'auto']}
                              angle={rotateLabels ? -45 : 0}
                              tickMargin={rotateLabels ? 30 : 10}
                              tickFormatter={tickFormatter}
                            />
                            <YAxis
                              tickFormatter={percentageTickFormatter}
                              stroke={theme.palette.text.secondary}
                              style={theme.typography.caption}
                            >
                              <Label
                                angle={270}
                                position="left"
                                style={{
                                  textAnchor: 'middle',
                                  fill: theme.palette.text.primary,
                                  ...theme.typography.caption,
                                }}
                              >
                                {typeName}
                              </Label>
                            </YAxis>
                            <Tooltip
                              content={renderChartTooltip({
                                visType,
                                series: [series] as LabeledSeries[],
                                units: '%',
                                aggregateInterval:
                                  series.aggregateInterval as AggregateInterval,
                              })}
                            />
                            <CartesianGrid strokeDasharray="3 3" />
                            {refArea1 && refArea2 ? (
                              <ReferenceArea
                                x1={refArea1}
                                x2={refArea2}
                                strokeOpacity={0.3}
                              />
                            ) : null}
                            <Legend content={renderLegend()} />
                            {series.distinctValues.map((v, index) => {
                              return (
                                <Area
                                  key={v}
                                  name={v}
                                  type="stepAfter"
                                  dataKey={(d) => d.values[v]}
                                  stroke={colors[index % colors.length]}
                                  strokeWidth={2}
                                  fill={colors[index % colors.length]}
                                  stackId="100"
                                  connectNulls
                                />
                              );
                            })}
                          </AreaChart>
                        ) : (
                          <></>
                        )}
                      </ResponsiveContainer>
                    </Paper>
                  );
                });
              default:
                return null;
            }
          })}
        </QueryResultsWrapper>
      </Grid>
    </Grid>
  );
}
