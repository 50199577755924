import React, {
  ReactNode,
  createContext,
  useState,
  useContext,
  useEffect,
} from 'react';

const PendoContext = createContext<pendo.Pendo | undefined>(undefined);
PendoContext.displayName = 'PendoContext';

export function PendoProvider(props: { children: ReactNode }) {
  const [client, setClient] = useState<pendo.Pendo>();

  // On initial page load pendo is uninitialized, and the global `pendo` object is undefined.
  // Even after the object is defined, some of its methods may not be. We need to wait until
  // the `pendo.isReady` method is defined and returns true before it is safe to call other
  // pendo methods.
  const ready = pendo && pendo.isReady && pendo.isReady();
  useEffect(() => {
    if (ready) {
      console.debug('pendo ready');
      setClient(pendo);
    } else {
      console.debug('pendo not ready');
    }
  }, [ready]);

  return (
    <PendoContext.Provider value={client}>
      {props.children}
    </PendoContext.Provider>
  );
}

/**
 * Hook that provides access to the pendo client. It's essentially just a wrapper around the
 * global `pendo` object. However, it will return undefined until the global `pendo` object
 * is defined, initialized, and safe to use.
 */
export function usePendoClient() {
  return useContext(PendoContext);
}
