import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { InstallationStatus } from '../types';

interface ProgressIndicatorProps {
  show?: boolean;
  progress?: number;
  status?: InstallationStatus;
}

export function ProgressIndicator(props: ProgressIndicatorProps) {
  const { show, progress, status } = props;
  const size = 100;
  const color = () => {
    switch (status) {
      case InstallationStatus.NETWORK_OPEN_REQUESTED:
        return 'secondary';
      case InstallationStatus.NETWORK_OPEN:
        return 'info';
      case InstallationStatus.CONFIGURATION_IN_PROGRESS:
        return 'success';
      default:
        return 'secondary';
    }
  };

  return show ? (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <CircularProgress
        style={{
          padding: '1rem',
          width: `${size}px`,
          height: `${size}px`,
        }}
        color={color()}
        value={progress}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant={'h3'}>
          {progress && Math.round(progress)}
        </Typography>
      </Box>
    </Box>
  ) : (
    <></>
  );
}
