import {
  AlertTriggerComparatorToMongoOperatorMap,
  AlertTriggerComparatorValueType,
  AlertTriggerMongoEntitySelectionClause,
  AlertTriggerMongoEntitySelectionCompoundClause,
  AlertTriggerMongoEntitySelectionFilter,
  IAlertTrigger,
  IAlertTriggerEntitySelectionCriterion,
} from '../types';

export const alertTriggerComparatorValuesFromSelectionCriterion = (
  criterion: IAlertTriggerEntitySelectionCriterion,
) => {
  let comparatorValues: string[] | number[] | boolean[] | null;

  try {
    switch (criterion.valueType) {
      case AlertTriggerComparatorValueType.TEMPERATURE:
        comparatorValues = [parseFloat(criterion.value)];
        break;
      case AlertTriggerComparatorValueType.HUMIDITY:
        comparatorValues = [parseFloat(criterion.value)];
        break;
      case AlertTriggerComparatorValueType.AIRFLOW:
        comparatorValues = [parseFloat(criterion.value)];
        break;
      case AlertTriggerComparatorValueType.PRESSURE:
        comparatorValues = [parseFloat(criterion.value)];
        break;
      case AlertTriggerComparatorValueType.STRING:
        comparatorValues = [criterion.value];
        break;
      case AlertTriggerComparatorValueType.NUMBER:
        comparatorValues = [parseFloat(criterion.value)];
        break;
      case AlertTriggerComparatorValueType.BOOLEAN:
        comparatorValues = new Array(1).fill(criterion.value === 'true');
        break;
      case AlertTriggerComparatorValueType.DATE:
        comparatorValues = [parseInt(criterion.value)];
        break;

      default:
        console.log(
          '[trigger-selection-criteria-to-selection-clause] Unexpected valueType value:',
          criterion.valueType,
        );
        comparatorValues = [criterion.value];
        break;
    }
  } catch (error) {
    console.log(
      `[trigger-selection-criteria-to-selection-clause] Unexpected conversion error for value ${criterion.value}.`,
      error,
    );
    comparatorValues = null;
  }

  return comparatorValues;
};

export const alertTriggerSelectionCriterionToSelectionClause = (
  criterion: IAlertTriggerEntitySelectionCriterion,
): AlertTriggerMongoEntitySelectionClause => {
  const comparatorOperators = AlertTriggerComparatorToMongoOperatorMap[
    criterion.comparator
  ](criterion.value, criterion.valueType);
  const comparatorValues =
    alertTriggerComparatorValuesFromSelectionCriterion(criterion);
  const queryClauseValue =
    comparatorOperators === null || comparatorValues === null
      ? { $eq: 0, $ne: 0 }
      : comparatorOperators.length === 1
        ? { [comparatorOperators[0]]: comparatorValues[0] }
        : comparatorOperators.reduce(
            (res, operator, index) => {
              res[operator] = comparatorValues[index];
              return res;
            },
            {} as Record<string, string | number | boolean>,
          );
  return {
    [`'${criterion.selectionPath}'`]: queryClauseValue,
  };
};

export const alertTriggerToMongoQueryClause = (
  trigger: IAlertTrigger,
): AlertTriggerMongoEntitySelectionCompoundClause => {
  const clauses = trigger.triggerCriteria.selectionCriteria.map((trigger) =>
    alertTriggerSelectionCriterionToSelectionClause(trigger),
  );
  return clauses.length === 0
    ? ({ _id: { $exist: false } } as AlertTriggerMongoEntitySelectionClause)
    : clauses.length === 1
      ? clauses[0]
      : { $and: clauses };
};
