import React, { CSSProperties, useEffect, useState } from 'react';
import {
  PressureSensor,
  usePressureSensorDetailQuery,
  usePressureSensorDetailUpdateSubscription,
} from '../../../../types/generated-types';
import BorderedSection from '../../shared/borderedSection';
import { DisplayValue } from '../shared-ui';
import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';

export function PressureSensorComponent({
  deviceId,
  label,
  style = {},
}: {
  deviceId: string;
  label: string;
  style?: CSSProperties | undefined;
}) {
  const [device, setDevice] = useState<Partial<PressureSensor>>();
  const {
    data,
    // TODO: Loading_error: handle loading/errors for query.
    // loading,
    // error,
  } = usePressureSensorDetailQuery({
    variables: { id: deviceId },
  });

  usePressureSensorDetailUpdateSubscription({
    variables: { ids: [deviceId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (data?.pressureSensorById) {
      setDevice(data.pressureSensorById as Partial<PressureSensor>);
    }
  }, [data]);

  return device ? (
    <div
      style={{
        display: 'flex',
        flex: '1 1 auto',
        width: '100%',
        ...style,
      }}
    >
      <BorderedSection
        title={label}
        labelStyle={{ fontSize: '10px' }}
        style={{
          height: '45px',
          width: '100%',
        }}
        ccStyle={{
          padding: '6px',
          width: '100%',
        }}
      >
        <DisplayValue
          value={device?.pressure?.value}
          units={'psi'}
          valueSize={'16px'}
          unitsSize={'12px'}
          style={{ width: '100%', textAlign: 'center' }}
        />
      </BorderedSection>
    </div>
  ) : (
    <DeviceLoadingSkeleton sx={{ margin: '2px' }} />
  );
}
