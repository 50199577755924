import * as React from 'react';

import { MRT_Row } from 'material-react-table';

import { Typography } from '@mui/material';

import { RowData } from './types/propertyAlertTriggerRowData';
import { MobileListCard } from '../shared/mobile-list-card';

export const PropertyAlertTriggerCardView = ({
  row,
}: {
  row: MRT_Row<RowData>;
}) => {
  const types = row.original.entityTypes ?? [];
  const criteria = types.includes('Device') ? (
    'Any Device'
  ) : types.includes('Source') ? (
    'Any Source'
  ) : (
    <div style={{ fontSize: '10px', marginLeft: '10px' }}>
      {types.map((type) => (
        <div key={type}>{type}</div>
      ))}
    </div>
  );

  const triggerCriteria = (
    <div style={{ fontSize: 10, marginLeft: '10px' }}>
      {' '}
      {row.original.triggerCriteria?.map((type, index) => (
        <div key={`${row.original.id}-${index}`}>{type}</div>
      ))}
    </div>
  );

  return (
    <MobileListCard>
      <Typography sx={{ fontSize: 14, fontWeight: 800 }} gutterBottom>
        {row.original.name ?? 'Unnamed alert trigger'}
        {row.original.enabled ? '' : ' *'}
      </Typography>
      <div style={{ marginLeft: '5px' }}>
        <Typography
          sx={{ fontSize: 12, fontWeight: 600 }}
          color="text.secondary"
          component="div"
        >
          Entity types:
        </Typography>
        {criteria}
        <Typography
          sx={{ fontSize: 12, fontWeight: 600 }}
          color="text.secondary"
          component="div"
        >
          Criteria:
        </Typography>
        <div>{triggerCriteria}</div>
      </div>
    </MobileListCard>
  );
};
