/**
 * Status Icons Component
 * - display a row of icons indicating current system state below current readings
 * - hover over icons to display a status message on the thermostat indicating a description of the icon
 * - only the main component is exported
 */
import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import { StatusMessage } from './status-message';
import { ResumeSchedule } from './resume-schedule';
import { Thermostat } from '../../../../../../types/generated-types';
import './status-icons.css';

interface StatusIconProps {
  thermostat?: Partial<Thermostat>;
  statusMessage: string;
  showStatusMessage: (message: string) => void;
  showResumeSchedule: boolean;
  briefScheduleStatus?: string;
}

/* Unicode Constants */
const BLANK = '\u00a0';

/* Main Component */
export function StatusIcons(props: StatusIconProps) {
  const {
    thermostat,
    statusMessage,
    showStatusMessage,
    showResumeSchedule,
    briefScheduleStatus,
  } = props;
  return (
    <>
      <Box
        sx={{
          p: 0,
          padding: '0px',
          paddingTop: '6px',
          paddingBottom: statusMessage.trim().length ? '0px' : '6px',
        }}
      >
        {/* State Icons */}
        <Stack
          direction={'row'}
          justifyContent={'space-evenly'}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <div
            onMouseOver={() => {
              showStatusMessage(thermostat?.hvacIconHelpText || BLANK);
            }}
            onMouseOut={() => showStatusMessage(BLANK)}
            className={`zone-icon ${thermostat?.hvacIconClass?.join(' ')}`}
          >
            &nbsp;
          </div>
          <div
            onMouseOver={() =>
              showStatusMessage(thermostat?.fanIconHelpText || BLANK)
            }
            onMouseOut={() => showStatusMessage(BLANK)}
            className={`zone-icon zone-fan-icon ${thermostat?.fanIconClass?.join(
              ' ',
            )}`}
          >
            &nbsp;
          </div>
          <div
            onMouseOver={() =>
              showStatusMessage(thermostat?.scheduleIconHelpText || BLANK)
            }
            onMouseOut={() => showStatusMessage(BLANK)}
            className={`zone-icon ${thermostat?.scheduleIconClass?.join(' ')}`}
          >
            &nbsp;
          </div>
          <div
            onMouseOver={() =>
              showStatusMessage(thermostat?.keypadLockoutIconHelpText || BLANK)
            }
            onMouseOut={() => showStatusMessage(BLANK)}
            className={`zone-icon ${thermostat?.keypadLockoutIconClass?.join(
              ' ',
            )}`}
          >
            &nbsp;
          </div>
          <div
            onMouseOver={() =>
              showStatusMessage(thermostat?.profileIconHelpText || BLANK)
            }
            onMouseOut={() => showStatusMessage(BLANK)}
            className={`zone-icon ${thermostat?.profileIconClass?.join(' ')}`}
          >
            &nbsp;
          </div>
        </Stack>
      </Box>
      {statusMessage.trim().length ? (
        <StatusMessage message={statusMessage} />
      ) : null}
      {showResumeSchedule && (
        <>
          <hr
            style={{
              padding: 0,
              margin: 0,
              border: '0.5px solid lightgray',
            }}
          />
          <ResumeSchedule
            deviceId={thermostat?.deviceId || ''}
            briefScheduleStatus={briefScheduleStatus}
            holdEnd={thermostat?.getHoldEnd ?? undefined}
            updatePending={thermostat?.hasPendingUpdates}
          />
        </>
      )}
    </>
  );
}
