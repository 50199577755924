import React, { CSSProperties, ReactElement } from 'react';

import SvgIcon from '@mui/material/SvgIcon';
import styles from './borderedSection.module.css';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import EditIcon from '@mui/icons-material/Edit';
import { useMediaQuery, useTheme } from '@mui/material';

// const borderColor = 'rgba(0, 0, 0, 0.2) !important';
// const oldBorderColor = '#b2b2b2';

function BorderedSection({
  innerRef,
  icon,
  title,
  subTitle,
  subTitleStyle = { fontStyle: 'italic' },
  children,
  error,
  hint,
  labelStyle,
  style,
  ccStyle,
  visible = true,
  compact = false,
  titleBubble = 'none',
  collapsible = false,
  initiallyExpanded = false,
  onExpandToggle = (_expanded: boolean) => {},
  collapsedWidget = undefined,
  expandedIcon = <UnfoldLessIcon style={{ fontSize: '20px' }} />,
  collapsedIcon = <UnfoldMoreIcon style={{ fontSize: '20px' }} />,
  actionable = false,
  actionIcon = <EditIcon style={{ fontSize: '20px' }} color="action" />,
  clickAction = () => {},
}: {
  innerRef?: React.RefObject<HTMLDivElement>;
  icon?: React.ElementType;
  title: string;
  subTitle?: string;
  subTitleStyle?: CSSProperties | undefined;
  children: React.ReactNode;
  error?: boolean;
  hint?: string | string[];
  labelStyle?: CSSProperties | undefined;
  style?: CSSProperties | undefined;
  ccStyle?: CSSProperties | undefined;
  visible?: boolean;
  compact?: boolean;
  titleBubble?: string;
  collapsible?: boolean;
  initiallyExpanded?: boolean;
  onExpandToggle?: (expanded: boolean) => void;
  collapsedWidget?: React.ReactNode;
  expandedIcon?: ReactElement;
  collapsedIcon?: ReactElement;
  actionable?: boolean;
  actionIcon?: ReactElement;
  clickAction?: () => void;
}) {
  const [expanded, setExpanded] = React.useState<boolean>(initiallyExpanded);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return visible ? (
    <div ref={innerRef} style={{ width: '100%' }}>
      <div
        style={style}
        className={error ? styles.mainContainerError : styles.mainContainer}
      >
        <div className={styles.header}>
          {actionable ? (
            <div
              style={{
                width: '100%',
                zIndex: 999,
                position: 'absolute',
                marginTop: '-11px',
                right: isSmall ? '26px' : '40px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 0 auto',
                  }}
                >
                  &nbsp;
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '0 1 auto',
                    backgroundColor: 'white',
                    paddingLeft: '2px',
                    paddingRight: '2px',
                    fontSize: '10px',
                    color: 'darkgrey',
                    border: '1px solid lightgrey',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={clickAction}
                >
                  {actionIcon}
                </div>
              </div>
            </div>
          ) : null}
          {collapsible && !actionable ? (
            <div
              style={{
                width: '100%',
                zIndex: 999,
                position: 'absolute',
                marginTop: '-11px',
                right: isSmall ? '26px' : '40px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 0 auto',
                  }}
                >
                  &nbsp;
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '0 1 auto',
                    backgroundColor: 'white',
                    paddingLeft: '2px',
                    paddingRight: '2px',
                    fontSize: '10px',
                    color: 'darkgrey',
                    border: '1px solid lightgrey',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const newExpanded = !expanded;
                    setExpanded(newExpanded);
                    setTimeout(() => {
                      onExpandToggle(newExpanded);
                    });
                  }}
                >
                  {expanded ? expandedIcon : collapsedIcon}
                </div>
              </div>
            </div>
          ) : null}
          <div
            className={
              error ? styles.headerBorderErrorBefore : styles.headerBorderBefore
            }
          ></div>
          {(icon || title || subTitle) && (
            <div
              className={
                titleBubble !== 'none'
                  ? styles.headerTitleBubble
                  : styles.headerTitle
              }
              style={{
                ...labelStyle,
                ...(titleBubble !== 'none'
                  ? { backgroundColor: titleBubble }
                  : {}),
              }}
            >
              {icon && <SvgIcon component={icon} />}
              {title && <span className={styles.title}>{title}</span>}
              {subTitle && (
                <span className={styles.title} style={subTitleStyle}>
                  {subTitle}
                </span>
              )}
            </div>
          )}
          <div
            className={
              error ? styles.headerBorderErrorAfter : styles.headerBorderAfter
            }
            style={{ width: compact ? '0em' : '1em' }}
          ></div>
        </div>
        <div className={styles.childrenContainer} style={ccStyle}>
          {actionable || !collapsible || expanded
            ? children
            : collapsedWidget ?? null}
        </div>
      </div>
      {error && hint && (
        <div
          style={{
            marginTop: '4px',
            marginLeft: '14px',
            fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
            fontSize: '0.75rem',
            fontWeight: 400,
            color: '#B04C48',
          }}
        >
          {Array.isArray(hint) ? hint.map((h) => <span>{h}</span>) : hint}
        </div>
      )}
    </div>
  ) : null;
}

export default BorderedSection;
