/**
 * Update Info Box
 * This is the effect handler function used to set device information shown in the info section
 * of the thermostat interface.
 * The function returns an array that is passed to the state setter function in the main component.
 */
import dayjs from 'dayjs';
import {
  CurrentAttributes,
  DeviceInfo,
  MyThermostat,
  ThermostatComponentProps,
} from '../types';
import { DEG_C, DEG_F } from '../../shared-ui/constants';
import { Celsius } from '../../../../system/models/temperatureUnits';
import { systemChoices } from '../components/helpers/choices';
import { formatHoldEnd } from './format-hold-end';
import { formatTemperatureString } from '../../util';

export function updateInfoBox(
  props: ThermostatComponentProps,
  device: MyThermostat,
  newValues?: CurrentAttributes,
) {
  const { pairedSensor, preferredUnits } = props;
  if (device) {
    /* Zone Info */
    const zoneInfo: DeviceInfo = {
      Zone: device.zone?.name || 'Unnamed Zone',
      'Zone ID': device.zone?._id || 'Unidentified Zone',
      Source: device.source?.name || 'Unnamed Source',
    };

    /* Base Info */
    const baseInfo: DeviceInfo = {
      baseInfo: {
        Model:
          device.meta?.model || device.meta?.modelNumber || 'Unknown model',
        Version: device.meta?.version || 'Unknown version',
      },
      Name: device.name || 'Unnamed Thermostat',
      'Device ID': device.deviceId || 'Unknown ID',
    };

    if (device.supportsPasscode) {
      baseInfo['Passcode'] =
        device.passCode?.value?.toString() || 'Unknown Passcode';
    }

    if (pairedSensor) {
      const val = device.remoteSensorTempDeltaValue
        ? formatTemperatureString(
            device.remoteSensorTempDeltaValue,
            preferredUnits,
          )
        : 'Unknown';
      baseInfo['Remote Sensor Temperature Delta'] = val ?? 'Unknown';
    }

    /* Time Info - timestamp, last setpoint change, etc. */
    const timeInfo: DeviceInfo = {
      'Last Updated': device.timestamp
        ? dayjs(device.timestamp).fromNow()
        : 'Never reported',
    };

    if (device.lastSetpointChangeValid) {
      timeInfo['Last Setpoint Change'] = device.lastSetpointChange?.value
        ? `${dayjs(device.lastSetpointChange.value).fromNow()} (${
            device.getSetpointChangeSource ?? 'Unknown'
          })`
        : 'Unchanged';
    }

    /* Device Type Info */
    const deviceTypeInfo: DeviceInfo = {};
    if (device.supportsSystemType) {
      deviceTypeInfo['System Type'] = device.modbusRTU
        ? 'Smart Air Handling Unit'
        : systemChoices.systemTypes.find(
            (item) => item.id === device.systemType?.value,
          )?.label ?? 'Unknown';
    }
    if (device.supportsShowFanButton) {
      deviceTypeInfo['Show Fan Button'] =
        systemChoices.showFanButtons.find(
          (item) => item.id === device.showFanButton?.value?.toString(),
        )?.label ?? 'Invalid';
    }

    deviceTypeInfo['Keypad Lockout Level'] =
      systemChoices.lockoutLevels.find(
        (item) => item.id === device.keypadLockoutLevel?.value?.toString(),
      )?.label ?? 'Invalid';

    /* Operating State and Mode Info */
    const operationInfo: DeviceInfo = {
      Operating: `${device.operatingMode?.value ?? 'Unknown mode'} | ${
        device.operatingState?.value ?? 'Unknown state'
      }`,
    };
    if (device.supportsFanOperations || device.modbusRTU) {
      operationInfo[
        device.modbusRTU
          ? 'Smart Fans'
          : `Fan ${device.isMultiSpeedThermostat ? 'Speed' : 'Cycling'}`
      ] = device.modbusRTU
        ? device.operatingMode?.value === 'Off'
          ? `Turned Off via Tstat`
          : 'Operating'
        : `${device.fanModeInfo} | ${device.getFanState}`;
    }

    /* 6. Setpoint Limit Info */
    const setpointInfo = {
      'Setpoint Limits':
        systemChoices.setpointLimitSources[
          newValues?.setpointLimitBasis ?? 'default'
        ],
    };

    /* 7. Schedule Info */
    const scheduleInfo: DeviceInfo = {
      Schedule: device.scheduleStatus ?? 'Not Set',
    };

    if (
      device.hasSchedule &&
      device.isScheduleEnabled &&
      device.isOffSchedule &&
      (device.supportsLastSetpointChange || device.supportsHoldExpiryTime)
    ) {
      scheduleInfo['Hold Ends'] = formatHoldEnd(device);
    }

    return {
      'Zone Info': zoneInfo,
      'Basic Info': baseInfo,
      'Last updated at': timeInfo,
      'Device Type': deviceTypeInfo,
      'Operation State': operationInfo,
      Setpoints: setpointInfo,
      'Schedule Settings': scheduleInfo,
    };
  }
}
