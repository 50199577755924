import React from 'react';
import { ApolloError } from '@apollo/client';
import { Notifier } from '../../system/services/notificationManager';
import Typography from '@mui/material/Typography';
import { Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';

export interface QueryResultsWrapperProps {
  children?: any;
  error?: ApolloError;
  errorMessage?: string;
  loading?: boolean;
  loadingMessage?: string;
  loadingComponent?: JSX.Element;
}
export const QueryResultsWrapper: React.FC<QueryResultsWrapperProps> = ({
  children,
  error = undefined,
  errorMessage = undefined,
  loading = false,
  loadingMessage = undefined,
  loadingComponent = undefined,
}: QueryResultsWrapperProps) => {
  if (error) {
    Notifier.error(error.message, {}, { autoClose: false });
    return (
      <Typography variant="h4">
        {errorMessage ||
          'Error loading data. Please try your request later or contact support if the problem persists.'}
      </Typography>
    );
  } else {
    return loading ? (
      loadingMessage ? (
        <Typography variant="h4">{loadingMessage}</Typography>
      ) : loadingComponent ? (
        loadingComponent
      ) : (
        <div style={{ flex: '1 1 auto', flexDirection: 'row' }}>
          <div style={{ flexDirection: 'column', flex: '1 0 auto' }}>
            &nbsp;
          </div>
          <div style={{ flexDirection: 'column', flex: '0 0 auto' }}>
            <Stack spacing={1}>
              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton variant="rectangular" width={210} height={60} />
              <Skeleton variant="rectangular" width={210} height={80} />
              <Skeleton variant="rectangular" width={210} height={100} />
            </Stack>
          </div>
          <div style={{ flexDirection: 'column', flex: '1 0 auto' }}>
            &nbsp;
          </div>
        </div>
      )
    ) : children ? (
      children
    ) : null;
  }
};
