import React, { useCallback, useEffect, useRef } from 'react';

import DataGrid, { type Column, DataGridHandle } from 'react-data-grid';

import CheckIcon from '@mui/icons-material/Check';

import {
  PropertyDetailRow,
  PropertyRow,
  PropertySubscriptionRow,
} from './types';

import 'react-data-grid/lib/styles.css';
import './alert-subscription-grid.css';

// function rowKeyGetter(row: PropertyRow | PropertySubscriptionRow) {
//   return row.id;
// }
export const AlertSubscriptionGrid = ({
  parentId,
  isSmall,
  smsSubscribed,
  emailSubscribed,
  propertyRows,
  subscriptionPropertyMap,
}: {
  parentId: string;
  isSmall: boolean;
  smsSubscribed: boolean;
  emailSubscribed: boolean;
  propertyRows: PropertyRow[];
  subscriptionPropertyMap: Record<string, PropertySubscriptionRow[]>;
}) => {
  const gridRef = useRef<DataGridHandle>(null);
  const [height, setHeight] = React.useState<number>(0);
  const subscriptions = (subscriptionPropertyMap[parentId] ?? []).filter(
    (sub) => sub,
  );

  const rowKeyGetter = useCallback(
    (row: PropertyRow | PropertySubscriptionRow) => {
      return `${parentId ? parentId : 'M'}_${row.id}`;
    },
    [parentId],
  );

  useEffect(() => {
    const newHeight =
      (
        propertyRows.find(
          (row) => row && row.type === 'DETAIL' && row.parentId === parentId,
        ) as PropertyDetailRow | null
      )?.height ?? 300;
    setHeight(newHeight);
  }, [parentId, propertyRows]);

  const subscriptionColumns: Column<PropertySubscriptionRow>[] = [
    { key: 'name', name: 'Alert Trigger Name' },
  ];

  if (emailSubscribed) {
    subscriptionColumns.push({
      key: 'email',
      name: isSmall ? 'Email' : 'via Email',
      width: 'min-content',
      renderCell: (row) =>
        row.row.email ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flex: '1 0 100%',
              height: '34px',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CheckIcon style={{ fontSize: '16px', justifySelf: 'center' }} />
          </div>
        ) : (
          ''
        ),
    });
  }

  if (smsSubscribed) {
    subscriptionColumns.push({
      key: 'sms',
      name: isSmall ? 'SMS' : 'via SMS',
      width: 'min-content',
      renderCell: (row) =>
        row.row.sms ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flex: '1 0 100%',
              height: '34px',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CheckIcon style={{ fontSize: '16px', justifySelf: 'center' }} />
          </div>
        ) : (
          ''
        ),
    });
  }

  return (
    <DataGrid
      className="rdg-light"
      ref={gridRef}
      rows={subscriptions}
      columns={subscriptionColumns}
      rowKeyGetter={rowKeyGetter}
      style={{
        margin: isSmall ? '8px -2px 0px -2px' : '10px 2px 10px 2px',
        height: `${height}px`,
        border: '1px solid green',
        boxShadow:
          '0 8px 12px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    />
  );
};
