import React, { useEffect, useState } from 'react';
import { isAndroid } from 'react-device-detect';
import { startAuthentication } from '@simplewebauthn/browser';

import {
  IAuthenticationResult,
  useAuthenticator,
} from '../AuthenticationContext';
import { IAuthState } from '../../system/SessionStateManager';

import { EmbueError } from '../../system/models/embueError';
import { Notifier } from '../../system/services/notificationManager';
import { ColoredLinearProgress } from '../../features/shared/linear-progress';
import Box from '@mui/material/Box';
import { Fade, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import EJSON from 'ejson';
import { useTheme } from '@mui/material/styles';
import { WebAuthn } from '@embue/capacitor-native-webauthn';

export const Passkey = ({
  onAuthResult,
  authState,
  name,
  promptImmediately,
  smart,
}: {
  onAuthResult: (res: IAuthenticationResult | void) => void;
  authState: IAuthState | undefined;
  name: string | undefined | null;
  promptImmediately: boolean;
  smart: boolean;
}) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const auth = useAuthenticator();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [displayName, setDisplayName] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setDisplayName(authState?.current?.context?.display);
  }, [authState]);

  const ActivityIndicator = (props: { activityInProgress: boolean }) => {
    return props.activityInProgress ? (
      <ColoredLinearProgress />
    ) : (
      <Box style={{ height: '22px' }}>&nbsp;</Box>
    );
  };

  const authenticateWithPasskey = () => {
    setSubmitting(true);
    auth
      .getPasskeyAuthenticationOptions()
      .then((authOptions) => {
        if (authOptions) {
          if (isAndroid) {
            WebAuthn.isWebAuthnAvailable()
              .then((polyfilled) => {
                if (polyfilled) {
                  WebAuthn.startAuthentication(authOptions)
                    .then((authResponse) => {
                      const inputVal = EJSON.stringify(authResponse);

                      auth
                        .authenticate({ value: inputVal, smart })
                        .then((authResult) => {
                          onAuthResult(authResult);
                        })
                        .catch((authError: EmbueError) => {
                          Notifier.error(
                            `[Validate Passkey] Failed: ${authError.message}.`,
                          );
                        })
                        .finally(() => {
                          setSubmitting(false);
                        });
                    })
                    .catch((err) => {
                      Notifier.error(
                        `[Validate Passkey] Failed: ${err.message}.`,
                      );
                    });
                } else {
                  Notifier.error(
                    `[Validate Passkey] Passkeys are not available on this device.`,
                  );
                }
              })
              .catch((_polyfilledError) => {
                Notifier.error(
                  `[Validate Passkey] Passkeys are not available on this device.`,
                );
              });
          } else {
            startAuthentication(authOptions)
              .then((authResponse) => {
                const inputVal = EJSON.stringify(authResponse);

                auth
                  .authenticate({ value: inputVal, smart })
                  .then((authResult) => {
                    onAuthResult(authResult);
                  })
                  .catch((authError: EmbueError) => {
                    Notifier.error(
                      `[Validate Passkey] Failed: ${authError.name} : ${authError.message}.`,
                    );
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              })
              .catch((err) => {
                const errorAny = err as unknown as any;
                if (errorAny.name === 'NotAllowedError') {
                  // Passkey attempt cancelled by user.
                } else if (errorAny.name === 'AbortError') {
                  Notifier.error(
                    `[Passkey Not Found] Unable to locate a suitable passkey for use on this device.`,
                  );
                } else {
                  Notifier.error(
                    `[Obtain Passkey] Failed: ${errorAny.code}: ${errorAny.name} : ${errorAny.message}.`,
                  );
                }
              })
              .finally(() => {
                setSubmitting(false);
              });
          }
        }
      })
      .catch((e) => {
        Notifier.error(`[Validate Passkey] Failed: ${e.message}.`);
      });
  };

  const authenticateWithAlternate = () => {
    setSubmitting(true);
    auth
      .authenticate({ value: '', smart }, true)
      .then((authResult) => {
        onAuthResult(authResult);
      })
      .catch((authError: EmbueError) => {
        Notifier.error(`[Validate Passkey] Failed: ${authError.message}.`);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    // TODO: This was meant to automatically engage the passkey flow on page load.
    //   Leaving this here as a reminder to revisit and see if we want to do that.
    if (!showPrompt && promptImmediately) {
      setShowPrompt(true);
      setTimeout(() => authenticateWithPasskey());
    }
  }, [promptImmediately]);

  return (
    <Fade
      style={{ transitionDuration: '500ms' }}
      in={true}
      mountOnEnter
      unmountOnExit
    >
      <div>
        <ActivityIndicator activityInProgress={submitting} />
        <Box
          component="form"
          noValidate
          sx={{ width: 'auto', marginTop: '0px', marginBottom: '10px' }}
        >
          <Grid
            container
            spacing={2}
            style={{ marginTop: '0px', paddingTop: '0px' }}
          >
            <Grid item xs={12} style={{ marginTop: '0px', paddingTop: '0px' }}>
              <div
                style={{ textAlign: 'center', marginTop: '-20px' }}
                onClick={() => {
                  console.log(authState);
                }}
              >
                Click the button below to authenticate
                <br />
                using {name ?? 'your previously registered Passkey'}.
                <br />
                <button
                  style={{ fontSize: '16px', marginTop: '10px' }}
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    authenticateWithPasskey();
                  }}
                >
                  Log in with Passkey
                </button>
              </div>
            </Grid>
          </Grid>
        </Box>

        {/* <Box sx={{ width: 'auto', marginTop: '8px', textAlign: 'center' }}>
          <Link
            id="pendo-help-login-passkeys"
            sx={{ cursor: 'pointer' }}
            variant="body2"
          >
            Help with Passkeys
          </Link>
        </Box> */}
        <div
          style={{
            display: 'flex',
            flexDirection: isSmall ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '8px',
          }}
        >
          <Link
            sx={{ cursor: 'pointer' }}
            component={Link}
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              authenticateWithAlternate();
            }}
            variant="body2"
          >
            Use an alternate factor
          </Link>
          {displayName && (
            <Link
              sx={{
                marginLeft: isSmall ? '0px' : '20px',
                marginTop: isSmall ? '10px' : '0px',
                cursor: 'pointer',
              }}
              component={Link}
              onClick={() =>
                auth
                  .resetAuthState()
                  .then(onAuthResult)
                  .catch((resetError) => {
                    Notifier.error(
                      'Unable to reset authentication state. Please contact Embue support.',
                      { authState, resetError },
                    );
                  })
              }
              variant="body2"
            >
              Not {displayName}?
            </Link>
          )}
        </div>
      </div>
    </Fade>
  );
};
