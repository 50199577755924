import {
  AirFlowUnit,
  CubicFeetPerMinute,
  LitersPerSecond,
} from '../../../system/models/temperatureUnits';

import { round } from './convert-temp-units';

export function convertToAirFlowUnits(
  value: number | null | undefined,
  toUnit: AirFlowUnit = CubicFeetPerMinute,
  fromUnit: AirFlowUnit = LitersPerSecond,
  digits = 0,
): number | undefined {
  if (value) {
    if (fromUnit === toUnit) {
      return round(value, digits);
    }

    if (fromUnit === LitersPerSecond && toUnit === CubicFeetPerMinute) {
      return round(value * 0.0353147 * 60, digits);
    }

    if (fromUnit === CubicFeetPerMinute && toUnit === LitersPerSecond) {
      return round((value * 28.3168466) / 60, digits);
    }

    throw new Error('Invalid units provided');
  } else {
    return undefined;
  }
}

export const formatAirflowString = (value: number, units: string) => {
  return `${value} ${units}`;
};
