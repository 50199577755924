import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { Notifier } from '../../system/services/notificationManager';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { Warehouse } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
  AlertTrigger,
  useAlertTriggerDetailQuery,
} from '../../../types/generated-types';

export function PropertyAlertTriggerDetail() {
  const { tid } = useParams<{ tid: string }>();
  const {
    data,
    error,
    // TODO: Loading_error: handle loading state.
    // loading,
  } = useAlertTriggerDetailQuery({
    variables: { id: tid ?? '' },
  });
  useEffect(() => {
    if (error) {
      Notifier.error(
        'There was a problem fetching alert trigger information: ',
        error,
      );
    }
  }, [error]);
  const [alertTrigger, setAlertTrigger] = useState<Partial<AlertTrigger>>();
  useEffect(() => {
    if (data?.alertTriggerById) {
      setAlertTrigger(data.alertTriggerById);
    }
  }, [data]);

  const name = alertTrigger?.name ?? 'No Name';
  const type = alertTrigger?.entityTypes ?? [];

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe">
            <Warehouse />
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={name}
        subheader={`AlertTrigger: ${type}`}
      />
    </Card>
  );
}
