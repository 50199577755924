/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';

import TextField from '@mui/material/TextField';
import { validateEmailAddress } from '../../../common/helpers';

export const EmailInputField = ({
  onValidityChange,
  onChange,
  initialValue,
  id = 'email',
  required = true,
  fullWidth = true,
  label = id,
  name = id,
  autoFocus = false,
}: {
  id?: string;
  autoFocus?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  label?: string;
  name?: string;
  initialValue?: string;
  onValidityChange?: (valid: boolean) => void;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}) => {
  const [dirty, setDirty] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const handleValueChanged = useCallback(
    (newValue: string) => {
      // noinspection RegExpRedundantEscape

      setValue(newValue);
      const newValid = validateEmailAddress(newValue);
      setValid(newValid);

      valid !== newValid && onValidityChange && onValidityChange(newValid);
    },
    [onValidityChange, valid],
  );

  const handleEmailChanged: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const newValue = event.target.value;
    !dirty && setDirty(true);

    handleValueChanged(newValue);
    onChange && onChange(event);
  };

  useEffect(() => {
    if (initialValue) {
      handleValueChanged(initialValue);
    }
  }, [handleValueChanged, initialValue]);

  return (
    <div style={{ width: 'auto' }}>
      <TextField
        autoCapitalize="none"
        required={required}
        fullWidth={fullWidth}
        id={id}
        name={name}
        label={label}
        value={value}
        autoFocus={autoFocus}
        autoCorrect="none"
        type="email"
        inputProps={{
          'aria-label': 'enter-email',
        }}
        autoComplete="none"
        onChange={handleEmailChanged}
        error={dirty && !valid}
        style={{ width: '100%', minWidth: '280px' }}
        css={
          !valid && dirty
            ? css`
                fieldset {
                  border-color: red !important;
                  border-width: 2px !important;
                }
              `
            : ''
        }
      />
    </div>
  );
};
