import React, { useEffect, useState } from 'react';

import { Theme, useTheme } from '@mui/material/styles';

import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// import Badge from '@mui/material/Badge';
// import MailIcon from '@mui/icons-material/Mail';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useNavigate, useLocation } from 'react-router-dom';

import { useAuthenticator } from '../../auth/AuthenticationContext';

import { AppBar } from './components/app-bar';
import { NavMenuItem } from '../../ui/app-bar';
import { ProfileMenu } from './components/profile-menu';
import { SystemTimer } from '../../system/shared/SystemTimer';

import { Logo } from '../shared/logo';

import styles from './app-bar.module.css';
import { Button, Grid, Popover, Tooltip, useMediaQuery } from '@mui/material';
import { Notifier } from '../../system/services/notificationManager';
import {
  useStatusNotifier,
  StatusMessagePanel,
} from '../../system/services/statusNotifier';
import { useInjectableComponents } from '../../system/services/injectableComponentsManager';

interface AppBarProps extends MuiAppBarProps {
  drawerOpen: boolean;
  drawerWidth: number;
}

interface MainAppBarProps extends AppBarProps {
  theme: Theme;
  appName?: string;
  appBarItems: NavMenuItem[];
  toggleDrawer: () => void;
  handleRouteChange: (route: string) => void;
  currentRoute: string;
}

export function MainAppBar({
  drawerOpen,
  drawerWidth,
  appBarItems,
  toggleDrawer,
  currentRoute,
  handleRouteChange,
}: MainAppBarProps) {
  const auth = useAuthenticator();
  const { sessionExpiresAt, expirationWarning } = useAuthenticator();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPop, setPop] = React.useState(expirationWarning);
  const { title, navigateToTitlePath } = useInjectableComponents();
  const [expAnchorEl, setExpAnchorEl] = React.useState<HTMLElement | null>(
    document.getElementById('profile-menu'),
  );
  const handleCloseExpMenu = () => {
    setPop(false);
  };
  const { manageAsyncOp } = useStatusNotifier();
  const [extendButtonDisabled, setExtendButtonDisabled] =
    useState<boolean>(false);

  const [userInfo, setUserInfo] = useState<string>();
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if ((expirationWarning && !openPop) || (!expirationWarning && openPop)) {
      setExpAnchorEl(document.getElementById('profile-menu'));
      setPop(expirationWarning);
    }
  }, [expirationWarning, openPop]);

  useEffect(() => {
    setUserInfo(auth.userName || auth.user?.name.lastName || 'Not logged in');
  }, [auth]);

  const menuId = 'primary-search-account-menu';

  const profileMenuProps = {
    menuId: 'profile-menu',
    anchorEl: anchorEl,
    isMenuOpen: isMenuOpen,
    handleMenuClose: handleProfileMenuClose,
    handleRouteChange: handleRouteChange,
    appBarItems: appBarItems,
  };

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  return (
    <div style={{ height: '64px' }}>
      <AppBar
        className={styles.appBar}
        position="fixed"
        drawerOpen={drawerOpen}
        drawerWidth={drawerWidth}
      >
        <Toolbar>
          {/* Hamburger */}
          <IconButton
            color="inherit"
            edge="start"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              paddingBottom: 0,
              mr: 0,
              ...(drawerOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* Logo */}
          <IconButton
            sx={{
              width: '74px',
              ...(drawerOpen && { display: 'none' }),
            }}
            onClick={() => navigate('/properties')}
          >
            <Logo />
          </IconButton>
          {/* -- AppBar Title */}
          <Button
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
              textTransform: 'none',
            }}
            onClick={() => navigateToTitlePath(`/${pathname.split('/')[1]}`)}
          >
            <Typography
              component="div"
              variant="h4"
              noWrap
              sx={{
                marginLeft: '0px',
                padding: '0px',
                display: 'flex',
                flex: '1 1 auto',
                justifyContent: 'center',
                textAlign: 'left',
                overflow: 'inherit',
                color: 'rgba(0, 0, 0, 0.70)',
              }}
            >
              {/*{appName ? appName : 'Super (default)'}&nbsp;*/}
              {title ?? currentRoute}
            </Typography>
          </Button>
          {isSmall ? null : <Box sx={{ flexGrow: 1 }} />}
          {/*<SearchInput />*/}
          <Box
            style={{ display: 'flex', flex: '0 1 auto', justifyContent: 'end' }}
          >
            {/*<Box sx={{ display: { xs: 'none', md: 'flex' } }}>*/}
            {/*<IconButton*/}
            {/*  size="large"*/}
            {/*  aria-label="show 4 new mails"*/}
            {/*  color="inherit"*/}
            {/*>*/}
            {/*  <Badge badgeContent={4} color="error">*/}
            {/*    <MailIcon />*/}
            {/*  </Badge>*/}
            {/*</IconButton>*/}
            {/*<IconButton*/}
            {/*  size="large"*/}
            {/*  aria-label="show 17 new notifications"*/}
            {/*  color="inherit"*/}
            {/*>*/}
            {/*  <Badge badgeContent={17} color="error">*/}
            {/*    <NotificationsIcon />*/}
            {/*  </Badge>*/}
            {/*</IconButton>*/}
            <Tooltip title={userInfo}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="secondary"
              >
                <>
                  <span
                    style={{
                      marginRight: '6px',
                      fontSize: '12px',
                      display: isSmall ? 'none' : 'inherit',
                    }}
                  >
                    {userInfo}
                  </span>
                  <span style={{ fontSize: '60px !important' }}>
                    <AccountCircle style={{ width: '60px !important' }} />
                  </span>
                </>
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
        <StatusMessagePanel />
        <ProfileMenu {...profileMenuProps} />
      </AppBar>

      <Popover
        open={Boolean(openPop)}
        anchorEl={expAnchorEl}
        onClose={(val, reason) => {
          console.log(val, reason);
          // handleCloseExpMenu();
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          width: 'auto',
          height: 'auto',
        }}
      >
        <Box
          sx={{
            px: 4,
            py: 2,
            width: isSmall ? 200 : 300,
            height: 'auto',
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                component="div"
                align="center"
                width="100%"
                // sx={{ display: 'inherit' }}
              >
                <SystemTimer
                  expirationTimestamp={sessionExpiresAt || new Date()}
                  label={isSmall ? '' : 'Session'}
                  prefix={isSmall ? 'Logout in' : 'expiring in'}
                  completedMessage="expired"
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Tooltip title="Click to extend session time">
                <div
                  style={{
                    paddingTop: '10px',
                    borderTop: '1px solid lightgray',
                    marginLeft: '10px',
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{ color: '#fff' }}
                    onClick={() => {
                      if (!extendButtonDisabled) {
                        setExtendButtonDisabled(true);
                        setTimeout(() => {
                          manageAsyncOp(
                            'extendSession',
                            true,
                            'Extending session ...',
                          );
                          setTimeout(() => {
                            auth
                              .extendSessionTime()
                              .then(() => {
                                Notifier.info('Session extended successfully');
                                handleCloseExpMenu();
                              })
                              .catch((extendError) => {
                                Notifier.error(extendError.message);
                              })
                              .finally(() => {
                                manageAsyncOp('extendSession', false);
                                setExtendButtonDisabled(false);
                              });
                          });
                        });
                      }
                    }}
                  >
                    Extend
                  </Button>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </div>
  );
}
