import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';

type ListGroup<T> = {
  id: string;
  items: T[];
};

interface SelectListProps<T> {
  header: string;
  records: T[];
  checked: T[];
  groupId?: keyof T;
  handleClick: (r: T) => void;
  itemLabel: (a: T) => string;
  itemValue: (a: T) => string;
  groupLabel?: (groupId: string) => string;
}

export function SelectList<T>(props: SelectListProps<T>) {
  const {
    header,
    records,
    checked,
    groupId,
    handleClick,
    itemLabel,
    itemValue,
    groupLabel,
  } = props;
  const [listGroups, setListGroups] = React.useState<ListGroup<T>[]>();

  React.useEffect(() => {
    if (!groupId || !records) return undefined;

    const _listGroups = records.reduce((a: ListGroup<T>[], c: T) => {
      const group = a.find((g) => g.id === c[groupId]);
      const index = group ? a.indexOf(group) : undefined;

      if (group && index !== undefined) {
        // this group already exists
        a[index].items = [...a[index].items, c];
        return a;
      } else {
        // this is a new group
        return a.concat({ id: c[groupId], items: [c] } as ListGroup<T>);
      }
    }, [] as ListGroup<T>[]);
    setListGroups(_listGroups);
  }, [records, groupId]);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 250,
        bgcolor: 'background.paper',
        position: 'relative',
        border: '1px solid darkgray',
      }}
    >
      <ListSubheader
        sx={{
          borderBottom: '1px solid darkgray',
          lineHeight: '24px',
          px: 1,
          py: 0,
        }}
        component="div"
        id="nested-list-subheader"
      >
        {header}:
      </ListSubheader>
      <List
        dense
        sx={{
          overflow: 'scroll',
          maxHeight: 250,
          '& li': { p: 0, m: 0, lineHeight: '32px' },
          '& ul': { px: 1, m: 0 },
        }}
        subheader={<li />}
      >
        {!!listGroups
          ? listGroups?.map((group) => (
              <li key={`section-${group.id}`}>
                <ul>
                  <ListSubheader>{`${groupLabel && groupLabel(group.id)}`}</ListSubheader>
                  {group.items.map((item) => {
                    const labelId = `item-${itemValue(item)}`;

                    return (
                      <SelectItem
                        item={item}
                        checked={checked.indexOf(item) !== -1}
                        key={labelId}
                        handleClick={handleClick}
                        itemLabel={itemLabel}
                        itemValue={itemValue}
                      />
                    );
                  })}
                </ul>
              </li>
            ))
          : records.map((item) => {
              const labelId = `item-${itemValue(item)}`;
              return (
                <SelectItem
                  item={item}
                  checked={checked.indexOf(item) !== -1}
                  key={labelId}
                  handleClick={handleClick}
                  itemLabel={itemLabel}
                  itemValue={itemValue}
                />
              );
            })}
      </List>
    </Box>
  );
}

interface SelectItemProps<T> {
  item: T;
  checked: boolean;
  handleClick: (r: T) => void;
  itemLabel: (a: T) => string;
  itemValue: (a: T) => string;
}
function SelectItem<T>(props: SelectItemProps<T>) {
  const { item, checked, itemValue, itemLabel, handleClick } = props;
  const labelId = `item-${itemValue(item)}`;
  return (
    <ListItem key={labelId}>
      <ListItemButton
        role={undefined}
        onClick={() => handleClick(item)}
        sx={{
          pt: 0,
          pb: 0,
          '& MuiListItemIcon-root': {
            minWidth: '32px',
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: 'unset' }}>
          <Checkbox
            edge="start"
            checked={checked}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText primary={itemLabel(item)} />
      </ListItemButton>
    </ListItem>
  );
}
