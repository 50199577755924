import React from 'react';
import { GraphQLError } from 'graphql/error/GraphQLError';

import { Button } from '@mui/material';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import TextField from '@mui/material/TextField';

import { Notifier } from '../../../system/services/notificationManager';

import {
  ClearOptInViaSmsMutation,
  SendOptInRequestSmsMutation,
  useClearOptInViaSmsMutation,
  useSendOptInRequestSmsMutation,
} from '../../../../types/generated-types';

import { SmsOptInStatus } from '../../../../common/types';
import { validatePhoneNumber } from '../../../../common/helpers';

export const SmsOptInMobileNumberInput = (props: {
  id: string;
  index: number;
  isDirty: boolean;
  propertyId: string;
  smsOptInStatus: SmsOptInStatus;
  mobileNumber: string;
  onChange: StandardInputProps['onChange'];
  isSmall: boolean;
  touched: boolean;
  errorText: string | undefined;
  postOpUpdater: (
    data:
      | SendOptInRequestSmsMutation['sendOptInRequestSms']
      | ClearOptInViaSmsMutation['clearOptInViaSms'],
    errors: readonly GraphQLError[] | undefined,
  ) => void;
  handleOptOutHelpRequest: (mobileNumber: string) => void;
}) => {
  const {
    id,
    index,
    touched,
    errorText,
    propertyId,
    isDirty,
    smsOptInStatus,
    mobileNumber,
    onChange,
    isSmall,
    postOpUpdater,
    handleOptOutHelpRequest,
  } = props;

  const [sendOptInSms] = useSendOptInRequestSmsMutation();
  const [clearOptInStatusViaSms] = useClearOptInViaSmsMutation();

  const sendOptInRequestViaSms = async (
    mobileNumber: string,
    contactIndexNumber: number,
    targetPropertyId: string,
  ) => {
    try {
      const res = await sendOptInSms({
        variables: {
          destinationNumber: mobileNumber,
          contactIndex: contactIndexNumber,
          propertyId: targetPropertyId,
        },
      });
      postOpUpdater(res.data?.sendOptInRequestSms, res.errors);
    } catch (smsSendError) {
      console.log('[Property Editor][Alert Config] smsSendError', smsSendError);
      Notifier.warn(
        'Unable to send opt-in SMS. Check the system configuration and phone number.',
      );
    }
  };

  const removeOptInViaSms = async (
    mobileNumber: string,
    contactIndexNumber: number,
    targetPropertyId: string,
  ) => {
    try {
      const res = await clearOptInStatusViaSms({
        variables: {
          destinationNumber: mobileNumber,
          contactIndex: contactIndexNumber,
          propertyId: targetPropertyId,
        },
      });
      postOpUpdater(res.data?.clearOptInViaSms, res.errors);
    } catch (smsSendError) {
      console.log('[Property Editor][Alert Config] smsSendError', smsSendError);
      Notifier.warn(
        'Unable to send opt-in SMS. Check the system configuration and phone number.',
      );
    }
  };

  const onSmsNumberChange = (event: any) => {
    const { value } = event.target;

    let cleanedString = value.replace(/\D/g, '');

    // Remove leading 0 or 1 digits
    cleanedString = cleanedString.replace(/^[01]+/, '');

    if (onChange) {
      onChange({
        ...event,
        target: { ...event.target, id: id, value: cleanedString },
      });
    }
  };

  return (
    <TextField
      sx={{
        padding: '0px',
        '& .MuiInputLabel-root': {
          margin: `${mobileNumber ? 4 : -12}px 0px 0px 0px`,
        },
        '& .MuiInputLabel-root.Mui-focused': {
          margin: '4px 0px 0px 0px',
        },
        '& .MuiInputBase-root': {
          marginTop: mobileNumber ? '12px' : '0px',
        },
        '& .MuiInputBase-root.Mui-focused': {
          marginTop: '12px',
        },
      }}
      fullWidth
      id={id}
      name={`alertMobile${index === 0 ? 'One' : index === 1 ? 'Two' : 'Three'}`}
      variant="standard"
      label="Mobile"
      value={mobileNumber}
      onChange={onSmsNumberChange}
      InputProps={{
        endAdornment: (
          <>
            <span style={{ marginRight: '6px', minWidth: '75px' }}>
              {smsOptInStatus === SmsOptInStatus.pending
                ? isSmall
                  ? 'Awaiting Response'
                  : 'Awaiting Opt-In Response'
                : smsOptInStatus === SmsOptInStatus.stopped
                  ? 'Opted Out / Blocked'
                  : smsOptInStatus === SmsOptInStatus.subscribed
                    ? 'Subscribed'
                    : ''}
            </span>
            <Button
              color="info"
              sx={{
                minWidth: isSmall ? '80px' : '185px',
                width: isSmall ? '100px' : '185px',
                m: '6px',
                p: '0px 4px',
                border: '1px solid darkgrey',
              }}
              aria-label="send opt-in request"
              disabled={
                isDirty || !propertyId || !validatePhoneNumber(mobileNumber)
              }
              onClick={async () => {
                [SmsOptInStatus.pending, SmsOptInStatus.subscribed].includes(
                  smsOptInStatus as SmsOptInStatus,
                )
                  ? await removeOptInViaSms(mobileNumber, index, propertyId)
                  : smsOptInStatus === SmsOptInStatus.stopped
                    ? handleOptOutHelpRequest(mobileNumber)
                    : await sendOptInRequestViaSms(
                        mobileNumber,
                        index,
                        propertyId,
                      );
              }}
            >
              {smsOptInStatus === SmsOptInStatus.pending
                ? isSmall
                  ? 'Cancel'
                  : 'Cancel Request'
                : smsOptInStatus === SmsOptInStatus.subscribed
                  ? 'Opt Out'
                  : smsOptInStatus === SmsOptInStatus.stopped
                    ? 'View Help'
                    : isSmall
                      ? 'Opt In'
                      : 'Send Opt-In Request'}
            </Button>
          </>
        ),
      }}
      error={touched && Boolean(errorText)}
      helperText={touched && errorText}
    />
  );
};
