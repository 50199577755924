import React, { useEffect } from 'react';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import {
  useSmartPowerOutletDetailQuery,
  useSmartPowerOutletDetailUpdateSubscription,
} from '../../../../../types/generated-types';
import { updateCacheFromSubscriptionEvent } from '../../../../../helpers/subscriptionUtils';

export const SmartPowerOutletLink = ({ id }: { id: string }) => {
  const [isSmartPowerOutletActive, setIsSmartPowerOutletActive] =
    React.useState<boolean>(false);
  const [hasAlerts, setHasAlerts] = React.useState(false);

  const { data, loading, error } = useSmartPowerOutletDetailQuery({
    variables: { id: id ?? 'no-id' },
  });

  useSmartPowerOutletDetailUpdateSubscription({
    variables: { ids: [id] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (data?.smartPowerOutletById) {
      const hasDeviceAlerts = Object.values(
        data.smartPowerOutletById.deviceAlerts ?? {},
      ).some((value) => value);
      const hasSourceAlerts = Object.values(
        data.smartPowerOutletById.sourceAlerts ?? {},
      ).some((value) => value);
      setIsSmartPowerOutletActive(
        data.smartPowerOutletById.isOn?.value ?? false,
      );
      setHasAlerts(hasDeviceAlerts || hasSourceAlerts);
    }
  }, [data]);

  return id ? (
    <div
      className="MuiButtonGroup-root MuiButtonGroup-text"
      style={{
        display: 'inline-flex',
        borderRight: '1px solid rgba(0, 0, 0, 0.23)',
        height: '100%',
        marginTop: '4px',
      }}
    >
      {isSmartPowerOutletActive ? (
        <PowerIcon style={{ marginRight: '10px', height: '42px' }} />
      ) : (
        <PowerOffIcon
          color={hasAlerts ? 'error' : 'warning'}
          style={{
            marginRight: '10px',
            height: '42px',
          }}
        />
      )}
    </div>
  ) : null;
};
