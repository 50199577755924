import React, { useEffect, useState } from 'react';

/* MUI */
import { List, FormControl, NativeSelect } from '@mui/material';
import { ThermostatScheduleTemplateRowData } from '../../devices/thermostat/components/schedule/thermostat-schedule';
import { ThermostatScheduleTemplateDetail } from '../../devices/thermostat/components/schedule/thermostat-schedule-template-detail';

/* NAVIGATION */
import { useParams } from 'react-router-dom';

/* TYPES */
import {
  Unit,
  Property,
  usePropertyScheduleTemplateListQuery,
  usePropertyDataForScheduleTemplatesQuery,
  useUnitDataForScheduleTemplatesQuery,
  usePropertyDataForScheduleTemplatesUpdatesSubscription,
  useUnitDataForScheduleTemplatesUpdatesSubscription,
} from '../../../../types/generated-types';

/* ICONS */
import { Notifier } from '../../../system/services/notificationManager';
import _ from 'lodash';
import { ThermostatScheduleDetailDialog } from '../../devices/thermostat/components/schedule/thermostat-schedule-detail-dialog';
import BorderedSection from '../../shared/borderedSection';
import { findAndSetTemplateById } from '../../devices/thermostat/components/helpers/setters';
import { ThermostatScheduleAction } from '../../devices/thermostat/components/schedule/thermostat-schedule-action';
import { ThermostatSchedulePropagationStatus } from '../../devices/thermostat/components/schedule/thermostat-schedule-propagation-status';
import { ThermostatAction } from '../types/thermostatAction';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';

export const PropertyUnitDetailScheduleTemplates = ({
  targetLevel,
}: {
  targetLevel: 'property' | 'unit';
}) => {
  /* State */
  const { id, unitId } = useParams<{ id: string; unitId: string }>();
  const [open, setOpen] = useState(false);
  const [propagationStatusView, setPropagationStatusView] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();
  const [property, setProperty] = useState<Partial<Property>>();
  const [unit, setUnit] = useState<Partial<Unit>>();
  const [showApply, setShowApply] = useState(false);
  const [templates, setTemplates] =
    useState<ThermostatScheduleTemplateRowData[]>();
  const [incompatibleTemplates, setIncompatibleTemplates] =
    useState<ThermostatScheduleTemplateRowData[]>();
  const [action, setAction] = useState<ThermostatAction>('apply');
  const [template, setTemplate] = useState<
    ThermostatScheduleTemplateRowData | undefined
  >();

  /* Data */

  const {
    data: templateList,
    loading: templateLoading,
    error: templateError,
    refetch: refetchTemplateData,
  } = usePropertyScheduleTemplateListQuery({
    variables: { propertyId: id || '' },
    fetchPolicy: 'network-only',
  });

  /* Subscriptions */
  usePropertyDataForScheduleTemplatesUpdatesSubscription({
    variables: { id: id || '' },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });
  useUnitDataForScheduleTemplatesUpdatesSubscription({
    variables: { id: unitId || '' },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  /* Property Data */
  const {
    data: propertyData,
    error: propertyError,
    loading: propertyLoading,
  } = usePropertyDataForScheduleTemplatesQuery({
    variables: { id: id ?? '' },
    fetchPolicy: 'network-only',
  });

  /* USE EFFECTS */

  useEffect(() => {
    if (propertyData?.propertyById) {
      setProperty(propertyData.propertyById);
    }
  }, [propertyData]);

  /* Unit Data */
  const {
    data: unitData,
    error: unitError,
    loading: unitLoading,
  } = useUnitDataForScheduleTemplatesQuery({
    variables: { id: unitId ?? '' },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (unitData?.unitById) {
      setUnit(unitData.unitById);
    }
  }, [unitData]);

  /* Property Schedule Template List */
  useEffect(() => {
    if (templateList?.thermostatScheduleTemplates) {
      const incompatibleTemplates: ThermostatScheduleTemplateRowData[] = [];
      const compatibleTemplates: ThermostatScheduleTemplateRowData[] = [];
      let compatibleSetpointProfileId: string = '';
      if (
        unit?.setpointProfileId &&
        unit?.setpointProfileId !== null &&
        unit?.setpointProfileId !== '' &&
        targetLevel === 'unit'
      ) {
        compatibleSetpointProfileId = unit.setpointProfileId;
      } else if (
        property?.setpointProfileId &&
        property?.setpointProfileId !== null &&
        property?.setpointProfileId !== '' &&
        (targetLevel === 'property' || targetLevel === 'unit')
      ) {
        compatibleSetpointProfileId = property.setpointProfileId;
      }
      templateList.thermostatScheduleTemplates.forEach((template, index) => {
        if (compatibleSetpointProfileId === '') {
          compatibleTemplates.push(template);
        } else if (
          template?.setpointLimitProfileId &&
          template?.setpointLimitProfileId !== null &&
          template?.setpointLimitProfileId !== ''
        ) {
          if (compatibleSetpointProfileId !== template.setpointLimitProfileId) {
            incompatibleTemplates.push(template);
            // templateList.thermostatScheduleTemplates.splice(index, 1);
          } else {
            compatibleTemplates.push(template);
          }
        } else {
          incompatibleTemplates.push(template);
          // templateList.thermostatScheduleTemplates.splice(index, 1);
        }
      });
      if (unit?.thermostatScheduleTemplateId && targetLevel === 'unit') {
        const t = findAndSetTemplateById(
          unit.thermostatScheduleTemplateId,
          templateList.thermostatScheduleTemplates,
        );
        if (t) {
          setSelectedTemplateId(unit.thermostatScheduleTemplateId);
          setTemplate(t);
        }
      }
      if (
        property?.thermostatScheduleTemplateId &&
        targetLevel === 'property'
      ) {
        const t = findAndSetTemplateById(
          property.thermostatScheduleTemplateId,
          templateList.thermostatScheduleTemplates,
        );
        if (t) {
          setSelectedTemplateId(property.thermostatScheduleTemplateId);
          setTemplate(t);
        }
      }
      setIncompatibleTemplates(incompatibleTemplates);
      setTemplates(compatibleTemplates);
    }
  }, [templateList, unit, property]);

  useEffect(() => {
    if (targetLevel === 'unit') {
      if (unit?.thermostatScheduleTemplateId === selectedTemplateId) {
        setShowApply(false);
      } else {
        setShowApply(true);
      }
    } else if (targetLevel === 'property') {
      if (property?.thermostatScheduleTemplateId === selectedTemplateId) {
        setShowApply(false);
      } else {
        setShowApply(true);
      }
    } else {
      setShowApply(true);
    }
  }, [selectedTemplateId, unit, property]);

  /* HANDLERS */

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSetAction = (a: ThermostatAction) => {
    setAction(a);
  };

  const handleSelectedTemplateId = (templateId: string) => {
    if (templateId === '-1') {
      setTemplate(undefined);
      setSelectedTemplateId('-1');
      setTimeout(() => {
        setOpen(true);
      }, 100);
    } else if (templateId === '0') {
      setSelectedTemplateId(templateId);
      setTemplate(undefined);
    } else {
      const t = findAndSetTemplateById(
        templateId,
        templateList?.thermostatScheduleTemplates ?? [],
      );
      if (t) {
        setSelectedTemplateId(templateId ?? '0');
        setTemplate(t);
      }
    }
  };

  const handleOpenPropagationStatusView = () => {
    setPropagationStatusView(true);
  };

  const handleResetSelection = () => {
    setTemplate(undefined);
    setSelectedTemplateId('0');
  };

  const handleClosePropagationStatusView = () => {
    setPropagationStatusView(false);
  };

  const handleClose = async (newTemplateId?: string, update?: boolean) => {
    if (newTemplateId && update) {
      await refetchTemplateData()
        .then((response) => {
          const t = findAndSetTemplateById(
            newTemplateId ?? '',
            response.data.thermostatScheduleTemplates ?? [],
          );
          if (t) {
            setSelectedTemplateId(newTemplateId ?? '0');
            setTemplate(t);
          }
        })
        .catch((err) => {
          Notifier.error(
            'A problem occurred while updating the template list. Please refresh the page and try again',
          );
          console.log('ERROR Refetching Thermostat Schedule Templates: ', err);
        });
    }
    setOpen(false);
  };

  return (
    <BorderedSection title="Schedule Template Override">
      <List sx={{ paddingTop: 0 }}>
        <>
          <FormControl fullWidth>
            <NativeSelect
              // disabled={creating || editing || verifying}
              style={{ width: '100%', marginTop: '5px' }}
              value={selectedTemplateId}
              onChange={(event) => {
                handleSelectedTemplateId(event.target.value);
              }}
            >
              <option key="0" value="0">
                Select a Template
              </option>
              <optgroup label="Compatible Templates:">
                {templates
                  ? templates.map(
                      (template: ThermostatScheduleTemplateRowData) => (
                        <option key={template._id} value={template._id}>
                          {template.name}
                        </option>
                      ),
                    )
                  : []}
              </optgroup>
              <optgroup label="Incompatible Templates:">
                {incompatibleTemplates
                  ? incompatibleTemplates.map(
                      (template: ThermostatScheduleTemplateRowData) => (
                        <option
                          key={template._id}
                          value={template._id}
                          disabled
                        >
                          {template.name}
                        </option>
                      ),
                    )
                  : []}
              </optgroup>
              <optgroup label="Template Actions">
                <option key="-1" value="-1">
                  Create a new Template
                </option>
              </optgroup>
            </NativeSelect>
          </FormControl>
          {template && !propagationStatusView ? (
            <>
              <ThermostatScheduleTemplateDetail template={template} />
              <ThermostatScheduleAction
                handleOpenThermostatScheduleDetailDialog={handleOpen}
                unitId={unit?._id}
                targetLevel={targetLevel}
                action={action}
                handleResetSelection={handleResetSelection}
                handleSelectedTemplateId={handleSelectedTemplateId}
                showApply={showApply}
                setShowApply={setShowApply}
                handleOpenPropagationStatusView={
                  handleOpenPropagationStatusView
                }
                handleSetAction={handleSetAction}
                propertyId={property?._id}
                template={template ?? {}}
              />
            </>
          ) : null}
        </>
      </List>
      <ThermostatScheduleDetailDialog
        templateId={template?._id ?? ''}
        open={open}
        disableSetpointProfileSelection={true}
        handleClose={handleClose}
      />
      <>
        {propagationStatusView ? (
          <ThermostatSchedulePropagationStatus
            template={template}
            action={action}
            handleClosePropagationStatusView={handleClosePropagationStatusView}
          />
        ) : null}
      </>
    </BorderedSection>
  );
};
