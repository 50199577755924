import { DayOfWeek, MyWeeklySchedule } from '../types';

/**
 * Determines whether the two given schedules are equal
 */
export function areSchedulesEqual(
  schedule1: MyWeeklySchedule,
  schedule2: MyWeeklySchedule,
): boolean {
  // Iterate over weekly schedules. These will have a value for each day,
  // even if there are no transitions for that day
  for (const day in schedule1) {
    const dailySchedule1 = schedule1[day as DayOfWeek];
    const dailySchedule2 = schedule2[day as DayOfWeek];
    const dailyTransitions1 = Object.keys(dailySchedule1);
    const dailyTransitions2 = Object.keys(dailySchedule2);
    if (dailyTransitions1.length !== dailyTransitions2.length) {
      // Different number of transitions for the current day. Schedules are not equal
      return false;
    }
    for (const transition of dailyTransitions1) {
      if (!dailyTransitions2.includes(transition)) {
        // Same number of transitions for the current day, but not all transition times
        // are equal. Schedules are not equal
        return false;
      }
      const settings1 = dailySchedule1[Number(transition)];
      const settings2 = dailySchedule2[Number(transition)];
      if (
        settings1.minimum !== settings2.minimum ||
        settings1.maximum !== settings2.maximum
      ) {
        // Daily transition times are the same, but not all min/max setpoints are equal.
        // Schedules are not equal
        return false;
      }
    }
  }
  // If all the above conditions are met, schedules are equal
  return true;
}
