import React from 'react';

/* MUI */
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

/* Icons */
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import ClearIcon from '@mui/icons-material/Clear';

import { ViewMode, MenuAction } from '../../modbus-air-handler';
import { useAuthorizer } from '../../../../../auth/AuthorizationContext';

interface MenuButtonsProps {
  viewMode: ViewMode;
  handleClick: (param: MenuAction) => void;
  isValid: boolean;
  hasErrors: boolean;
  isEditable: boolean;
}

export function MenuButtons(props: MenuButtonsProps) {
  const { viewMode, handleClick, isValid, hasErrors } = props;
  const { cannot } = useAuthorizer();

  let buttons: MenuAction[];
  switch (viewMode) {
    case ViewMode.NORMAL:
      buttons = hasErrors ? [] : [MenuAction.EDIT_VALUES];
      break;
    case ViewMode.EDIT_UNCHANGED:
      buttons = [MenuAction.CANCEL];
      break;
    case ViewMode.EDIT:
      buttons = isValid
        ? [MenuAction.SAVE_CHANGES, MenuAction.REVERT_CHANGES]
        : [MenuAction.CANCEL];
      break;
    case ViewMode.OFFLINE:
      buttons = [];
      break;
    default:
      buttons = [];
  }

  if (cannot('update', 'Thermostat')) {
    buttons = buttons.filter(
      (button) =>
        ![
          MenuAction.EDIT_VALUES,
          MenuAction.CANCEL,
          MenuAction.SAVE_CHANGES,
          MenuAction.REVERT_CHANGES,
        ].includes(button),
    );
  }
  const iconMap = {
    [MenuAction.CANCEL]: <ClearIcon fontSize="inherit" />,
    [MenuAction.EDIT_VALUES]: <EditIcon fontSize="inherit" />,
    [MenuAction.REVERT_CHANGES]: <UndoIcon fontSize="inherit" />,
    [MenuAction.SAVE_CHANGES]: <CheckIcon fontSize="inherit" />,
  };

  return (
    <ButtonGroup variant="text" color="inherit">
      {buttons &&
        buttons.map((val, index, arr) => (
          <Button
            key={`${val}_button`}
            onClick={() => handleClick(val)}
            sx={{ color: '#1E3D1D' }}
          >
            <Avatar
              sx={{
                color: 'inherit',
                width: {
                  xs: '16px',
                  sm: '24px',
                  md: '32px',
                },
                height: {
                  xs: '16px',
                  sm: '24px',
                  md: '32px',
                },
                fontSize: {
                  xs: 'medium',
                  sm: 'large',
                  md: 'x-large',
                },
                backgroundColor: 'inherit',
              }}
            >
              {iconMap[val]}
            </Avatar>
          </Button>
        ))}
    </ButtonGroup>
  );
}
