import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { MultiSelectProps } from './multi-select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

export function MultiSelectFew<T>(props: MultiSelectProps<T>) {
  return (
    <Select
      multiple
      value={props.selected.map((s) => props.itemValue(s))}
      onChange={(e) => props.onChange(e.target.value as string[])}
      renderValue={props.renderValue}
      sx={{ backgroundColor: 'white' }}
    >
      {props.selectables.map((selectable) => {
        const isSelected = props.selected.some(
          (selected) =>
            props.itemValue(selected) === props.itemValue(selectable),
        );
        const selectableId = props.itemValue(selectable);
        return (
          <MenuItem key={selectableId} value={selectableId}>
            <Checkbox checked={isSelected} color="info" />
            <ListItemText primary={props.itemLabel(selectable)} />
          </MenuItem>
        );
      })}
    </Select>
  );
}
