import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export interface BulkConfigAction {
  name: string;
  action: () => void;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

export interface BulkConfigStepProps {
  title: string;
  children: ReactNode;
  topActions: BulkConfigAction[];
  bottomActions: BulkConfigAction[];
  error?: string;
}

// Reusable UI component for any bulk config step
export default function BulkConfigStep(props: BulkConfigStepProps) {
  const { title, children, topActions, bottomActions, error } = props;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h3" textAlign="center" gutterBottom>
        {title}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', columnGap: 1 }}>
        {topActions.map((a) => (
          <Button
            key={a.name}
            color="secondary"
            onClick={a.action}
            startIcon={a.startIcon}
            endIcon={a.endIcon}
          >
            {a.name}
          </Button>
        ))}
      </Box>
      {children}
      <Box sx={{ display: 'flex', justifyContent: 'center', columnGap: 1 }}>
        {bottomActions.map((a) => (
          <Button
            key={a.name}
            color="secondary"
            onClick={a.action}
            startIcon={a.startIcon}
            endIcon={a.endIcon}
          >
            {a.name}
          </Button>
        ))}
      </Box>
      {error && (
        <Typography color="red" textAlign="center" marginTop={1}>
          {error}
        </Typography>
      )}
    </Box>
  );
}
