import React, { ChangeEvent, useEffect, useState } from 'react';

/* MUI */
import { Switch, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import NativeSelect from '@mui/material/NativeSelect';

/* Custom Components */
import BorderedSection from '../../../../shared/borderedSection';

/* Types */
import { CurrentAttributes, ModbusAirHandler } from '../../types';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const ventilationModeOptions = [
  { value: 'Recirc', label: 'Recirculate' },
  { value: 'AirExch', label: 'Air Exchange' },
  { value: 'HeatPump', label: 'Heat Pump' },
  { value: 'Smart', label: 'Smart' },
  { value: 'Int10', label: 'Intermittent 10' },
  { value: 'Int20', label: 'Intermittent 20' },
  { value: 'Int30', label: 'Intermittent 30' },
  { value: 'Int40', label: 'Intermittent 40' },
];

interface SystemEditProps {
  currentValues: CurrentAttributes;
  device?: Partial<ModbusAirHandler>;

  handleChange: (
    changes: Record<string, number | string | boolean>,
    isValid: boolean,
  ) => void;
}
export function SystemEdit(props: SystemEditProps) {
  const { currentValues, device, handleChange } = props;

  const [errors, setErrors] = useState({
    ahuModbusId: false,
    altitude: false,
    freshAirSetpoint: false,
    rhSetpoint: false,
    zmbPollingFrequency: false,
    zmbModbusRequestDelay: false,
  });

  const [values, setValues] = useState<Partial<CurrentAttributes>>();

  useEffect(() => {
    if (currentValues) {
      const {
        airflowUnits,
        ahuModbusId,
        altitude,
        freshAirSetpoint,
        rhSetpoint,
        ventilationMode,
        zmbModbusRequestDelay,
        zmbPollingFrequency,
      } = currentValues;

      setValues({
        ...currentValues,
        airflowUnits: airflowUnits ?? 'LPS',
        ahuModbusId: ahuModbusId ?? 3,
        altitude: altitude ?? 500,
        freshAirSetpoint: freshAirSetpoint ?? 10,
        rhSetpoint: rhSetpoint ?? 40,
        ventilationMode: ventilationMode ?? 'Smart',
        zmbModbusRequestDelay: zmbModbusRequestDelay ?? 500,
        zmbPollingFrequency: zmbPollingFrequency ?? 30,
      });
    } else {
      setValues({
        airflowUnits: 'LPS',
        ahuModbusId: 3,
        altitude: 500,
        freshAirSetpoint: 10,
        ventilationMode: 'Smart',
        rhSetpoint: 40,
        zmbPollingFrequency: 30,
        zmbModbusRequestDelay: 500,
      });
    }
  }, [currentValues]);

  const handleVentilationModeChange = (
    event: ChangeEvent<HTMLSelectElement>,
  ) => {
    const value = event.target.value;
    setValues((current) => ({ ...current, ventilationMode: value }));
    handleChange(
      { ventilationMode: value },
      !Object.values(errors).some((value) => value),
    );
  };

  const handleModbusIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Validate input

    let isValid = /^\d+$/.test(inputValue);
    const value = isValid ? parseInt(inputValue) : inputValue;
    isValid = isValid && value > 0 && value < 208;

    const newErrors = { ...errors, ahuModbusId: !isValid };
    setErrors(newErrors);
    handleChange(
      {
        ahuModbusId: value,
      },
      !Object.values(newErrors).some((value) => value),
    );
  };

  const handleAltitudeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Validate input

    let isValid = /^\d+$/.test(inputValue);
    const value = isValid ? parseInt(inputValue) : inputValue;
    isValid = isValid && value > 499 && value < 3001;

    const newErrors = { ...errors, altitude: !isValid };
    setErrors(newErrors);
    handleChange(
      {
        altitude: value,
      },
      !Object.values(newErrors).some((value) => value),
    );
  };

  const handlePollingFrequencyChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = event.target.value;

    // Validate input

    let isValid = /^\d+$/.test(inputValue);
    const value = isValid ? parseInt(inputValue) : inputValue;
    isValid = isValid && value > 9 && value < 301;

    const newErrors = { ...errors, zmbPollingFrequency: !isValid };
    setErrors(newErrors);
    handleChange(
      {
        zmbPollingFrequency: value,
      },
      !Object.values(newErrors).some((value) => value),
    );
  };

  const handleZmbModbusRequestDelayChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = event.target.value;

    // Validate input

    let isValid = /^\d+$/.test(inputValue);
    const value = isValid ? parseInt(inputValue) : inputValue;
    isValid = isValid && value > 499 && value < 3001;

    const newErrors = { ...errors, zmbModbusRequestDelay: !isValid };
    setErrors(newErrors);
    handleChange(
      {
        zmbModbusRequestDelay: value,
      },
      !Object.values(newErrors).some((value) => value),
    );
  };

  const handleHardwareToggleChange = (
    checked: boolean,
    key: keyof CurrentAttributes,
  ) => {
    handleChange(
      {
        [key]: checked,
      },
      true,
    );
  };

  return values ? (
    <form>
      <Box sx={{ p: 0, marginTop: '6px', marginBottom: '3px' }}>
        <BorderedSection
          title="Configuration Settings"
          labelStyle={{ fontSize: '10px', padding: '0px 4px' }}
          style={{ marginLeft: '4px', marginRight: '4px', padding: '0px' }}
          ccStyle={{ margin: '0px', padding: '0px' }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0px',
              margin: '0px',
            }}
          >
            <BorderedSection
              title="Ventilation Mode"
              labelStyle={{ fontSize: '10px', padding: '0px 4px' }}
              style={{ margin: '8px 4px 4px 4px' }}
              ccStyle={{
                width: '100%',
                paddingTop: '0px',
                paddingLeft: '8px',
                paddingRight: '8px',
                paddingBottom: '8px',
              }}
            >
              <NativeSelect
                value={values.ventilationMode}
                onChange={handleVentilationModeChange}
                style={{ width: '100%' }}
              >
                {ventilationModeOptions.map((choice) => (
                  <option
                    key={`ventMode-${choice.value}`}
                    value={choice.value}
                    label={choice.label}
                  >
                    {choice.label}
                  </option>
                ))}
              </NativeSelect>
            </BorderedSection>
            <BorderedSection
              title="Modbus ID"
              labelStyle={{ fontSize: '10px', padding: '0px 4px' }}
              style={{ margin: '8px 4px 4px 4px' }}
              ccStyle={{
                width: '100%',
                paddingTop: '0px',
                paddingLeft: '8px',
                paddingRight: '8px',
                paddingBottom: '8px',
              }}
              error={errors.ahuModbusId}
            >
              <TextField
                fullWidth
                id="ahuModbusId"
                name="ahuModbusId"
                variant="standard"
                value={values.ahuModbusId}
                label=""
                onChange={handleModbusIdChange}
                error={errors.ahuModbusId}
                helperText={
                  errors.ahuModbusId
                    ? 'Please enter a valid integer between 1 and 207'
                    : ''
                }
              />
            </BorderedSection>
            <BorderedSection
              title="Altitude"
              labelStyle={{ fontSize: '10px', padding: '0px 4px' }}
              style={{ margin: '8px 4px 4px 4px' }}
              ccStyle={{
                width: '100%',
                paddingTop: '0px',
                paddingLeft: '8px',
                paddingRight: '8px',
                paddingBottom: '8px',
              }}
              error={errors.altitude}
            >
              <TextField
                fullWidth
                id="altitude"
                name="altitude"
                variant="standard"
                value={values.altitude}
                label=""
                onChange={handleAltitudeChange}
                error={errors.altitude}
                helperText={
                  errors.altitude
                    ? 'Please enter a valid integer between 500 and 3000'
                    : ''
                }
              />
            </BorderedSection>
            <BorderedSection
              title="Polling Frequency"
              labelStyle={{ fontSize: '10px', padding: '0px 4px' }}
              style={{ margin: '8px 4px 4px 4px' }}
              ccStyle={{
                width: '100%',
                paddingTop: '0px',
                paddingLeft: '8px',
                paddingRight: '8px',
                paddingBottom: '8px',
              }}
              error={errors.zmbPollingFrequency}
            >
              <TextField
                fullWidth
                id="zmbPollingFrequency"
                name="zmbPollingFrequency"
                variant="standard"
                value={values.zmbPollingFrequency}
                label=""
                onChange={handlePollingFrequencyChange}
                error={errors.zmbPollingFrequency}
                helperText={
                  errors.zmbPollingFrequency
                    ? 'Please enter a valid integer between 10s and 300s'
                    : ''
                }
              />
            </BorderedSection>
            <BorderedSection
              title="Polling Delay"
              labelStyle={{ fontSize: '10px', padding: '0px 4px' }}
              style={{ margin: '8px 4px 4px 4px' }}
              ccStyle={{
                width: '100%',
                paddingTop: '0px',
                paddingLeft: '8px',
                paddingRight: '8px',
                paddingBottom: '8px',
              }}
              error={errors.zmbModbusRequestDelay}
            >
              <TextField
                fullWidth
                id="zmbModbusRequestDelay"
                name="zmbModbusRequestDelay"
                variant="standard"
                value={values.zmbModbusRequestDelay}
                label=""
                onChange={handleZmbModbusRequestDelayChange}
                error={errors.zmbModbusRequestDelay}
                helperText={
                  errors.zmbModbusRequestDelay
                    ? 'Please enter a valid integer between 100ms to 10000ms'
                    : ''
                }
              />
            </BorderedSection>
          </Box>

          <BorderedSection title="Hardware Settings" style={{ margin: '6px' }}>
            <FormGroup style={{ paddingLeft: '6px' }}>
              {[
                { key: 'auxCoolingEnabled', label: 'Auxiliary Cooling' },
                { key: 'winterRhAdjustEnabled', label: 'Winter RH Adjustment' },
                { key: 'showerDetectorEnabled', label: 'Shower Detector' },
              ].map(({ key, label }) => (
                <FormControlLabel
                  // disabled={!editingEnabled}
                  key={key}
                  control={
                    <Switch
                      size="small"
                      onChange={(
                        event: ChangeEvent<HTMLInputElement>,
                        checked: boolean,
                      ) => {
                        handleHardwareToggleChange(
                          checked,
                          key as keyof CurrentAttributes,
                        );
                      }}
                      name={key}
                      id={key}
                      checked={
                        values[key as keyof CurrentAttributes] as boolean
                      }
                    />
                  }
                  label={
                    <span
                      style={{
                        fontSize: '18px',
                        color:
                          values[key as keyof CurrentAttributes] === true
                            ? 'green'
                            : 'darkgray',
                      }}
                    >
                      {label}
                    </span>
                  }
                  // labelPlacement="start"
                />
              ))}
            </FormGroup>
          </BorderedSection>
        </BorderedSection>
      </Box>
    </form>
  ) : null;
}
