import React from 'react';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';

interface BatteryProps {
  charge?: number | null;
}
enum Colors {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
}

const chargeLevel = [
  { icon: Battery0BarIcon, color: Colors.ERROR, fontSize: 'inherit' },
  { icon: Battery1BarIcon, color: Colors.ERROR, fontSize: 'inherit' },
  { icon: Battery2BarIcon, color: Colors.WARNING },
  { icon: Battery3BarIcon, color: Colors.WARNING },
  { icon: Battery4BarIcon, color: Colors.WARNING },
  { icon: Battery5BarIcon, color: Colors.SUCCESS },
  { icon: Battery6BarIcon, color: Colors.SUCCESS },
  { icon: BatteryFullIcon, color: Colors.SUCCESS },
  { icon: BatteryUnknownIcon, color: Colors.ERROR, fontSize: 'inherit' },
];

export function Battery(props: BatteryProps) {
  const { charge } = props;
  let level = 0;
  if (charge === undefined || charge === null) {
    level = 8;
  } else if (charge > 0) {
    // level = Math.floor(charge);
    level = Math.round(charge);
  }

  const BatteryIcon = chargeLevel[level].icon;
  const color = chargeLevel[level].color;
  return <BatteryIcon color={color} />;
}

// full charge = 7, empty = 0, unknown/error = 8
export function getBatteryLevel(voltage: number | undefined | null): number {
  if (voltage === undefined || voltage === null) {
    return 8;
  } else if (voltage === 8.4) {
    // msensor two-stage battery level = full
    return 7;
  } else if (voltage === 4.2) {
    // msensor two-stage battery level = minimal
    // Not sure whether to show a quarter (because it could run for a while at 2.5V)
    // or to show empty (and possibly urge them to change a battery with some life left)
    return 2; // ~25% full
  } else if (voltage > 3.0) {
    return 7; // 100% full
  } else if (voltage > 2.8) {
    return 5; // ~75% full
  } else if (voltage > 2.6) {
    return 4; // ~50% full
  } else if (voltage > 2.4) {
    return 2; // ~25% full
  } else {
    return 0;
  }
}

export function formatVoltage(
  value: number | null | undefined,
  digits = 2, // Default to two digits of precision
): string {
  // validate input
  if (!value || value <= 0) return '--';

  // TODO: Changed formatting for the benefit of SmartPowerOutlet.  Improve rounding precision while also avoiding exponential number formatting.
  // const voltage = value.toPrecision(digits);
  const voltage = Math.round(value).toString();

  // discrete case for sensors not supporting actual voltage measurement
  if (voltage === '8.4') return 'Good';
  if (voltage === '4.2') return 'Replace Soon!';

  // Continuous voltage measurement case
  return `${voltage} V`;
}

export function getBatteryMessage(voltage: number | undefined | null): string {
  const batteryLevel = getBatteryLevel(voltage);
  let batteryMessage = 'Unknown battery state';
  switch (batteryLevel) {
    case 8:
      batteryMessage = 'Unknown battery level';
      break;
    case 7:
    case 6:
      batteryMessage = 'Full charge';
      break;
    case 5:
    case 4:
      batteryMessage = 'Ok';
      break;
    case 3:
    case 2:
      batteryMessage = 'Replace Soon';
      break;
    case 1:
      batteryMessage = 'Critical';
      break;
    case 0:
      batteryMessage = 'Empty';
      break;
    default:
      batteryMessage = 'Unknown battery condition';
  }
  return batteryMessage;
}
