export enum InstallationAction {
  JOIN_INITIATE = 'joinInitiate',
  JOIN_CANCEL = 'joinCancel',
  REPLACE_INITIATE = 'deviceReplace',
  REPLACE_CANCEL = 'replaceCancel',
  REPLACE_JOIN_INITIATE = 'replaceJoinInitiate',
  RESTART_INSTALLATION = 'restartInstallation',
  SCAN_START = 'scanStart',
  SCAN_CANCEL = 'scanCancel',
  TOGGLE_TORCH = 'toggleTorch',
  INSTALLER_EXIT = 'installerExit',
  ENTER_EUID = 'enterEuid',
}
