import React, { useEffect, useState } from 'react';
/* MUI Icons */
import SensorDoorOutlinedIcon from '@mui/icons-material/SensorDoorOutlined';
import SensorDoorRounded from '@mui/icons-material/SensorDoorRounded';
/* Components */
import {
  Battery,
  getBatteryLevel,
  formatVoltage,
} from '../../../ui/shared/battery';
/* MUI */
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
/* Gen Types */
import {
  DoorSensor,
  useDoorSensorDetailQuery,
  useDoorSensorDetailUpdateSubscription,
} from '../../../../types/generated-types';
/* Shared */
import { DeviceInfo, DeviceInformation } from '../shared-ui/device-info';
import {
  DeviceAlerts,
  ToggleAlertDetailsButton,
} from '../shared-ui/alerts-info';
import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
import moment from 'moment';

interface DoorSensorComponentProps {
  deviceId: string;
}

function ContactSensorIcon(device: Partial<DoorSensor>) {
  const status = device?.contactState?.value;

  if (status !== 'Open') {
    return (
      <SensorDoorRounded
        sx={{ color: 'rgba(0, 0, 0, 0.63)', fontSize: '1rem' }}
      />
    );
  } else {
    return (
      <SensorDoorOutlinedIcon sx={{ color: '#80BB52', fontSize: '1rem' }} />
    );
  }
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: '-8px',
  marginRight: '-8px',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function DoorSensorComponent(props: DoorSensorComponentProps) {
  const { deviceId } = props;
  const { data, loading, error } = useDoorSensorDetailQuery({
    variables: { id: deviceId },
  });
  const [device, setDevice] = useState<Partial<DoorSensor>>();
  const [alertsExpanded, setAlertsExpanded] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState<DeviceInformation[]>([]);

  useDoorSensorDetailUpdateSubscription({
    variables: { ids: [deviceId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (data?.doorSensorById) {
      setDevice(data.doorSensorById as Partial<DoorSensor>);
    }
  }, [data]);

  useEffect(() => {
    if (device) {
      const panelInfo: DeviceInformation = {
        Zone: device.zone?.name ?? 'Unnamed',
        'Zone ID': device.zoneId ?? 'Unidentified',
        Source: device.source?.name ?? 'Unnamed source',
      };

      const devicesInfo: DeviceInformation[] = [
        {
          'Device ID': device.deviceId ?? device._id ?? 'Unidentified',
          Name: device.name ?? 'Unnamed',
          Type: device.typeDisplayName ?? 'Unknown',
          firmwareInfo: {
            Model:
              device.meta?.model || device.meta?.modelNumber || 'Unknown model',
            Version: device.meta?.version || 'Unknown version',
          },
          'Battery Voltage': formatVoltage(device.voltage?.value),
          'Last Updated': moment(device.timestamp).fromNow(),
        },
      ];

      setDeviceInfo([panelInfo, ...devicesInfo]);
    }
  }, [device]);

  const name = device?.name || 'null';
  const voltage = device?.voltage?.value || 0;
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const statusText = device?.contactState?.value;
  const responsiveText = name + ': ' + statusText;
  const hasAlerts =
    device?.sourceAlerts?.length || device?.deviceAlerts?.length;

  return error ? (
    <div>Error</div>
  ) : loading ? (
    <DeviceLoadingSkeleton />
  ) : device ? (
    <Card
      sx={{
        backgroundColor: hasAlerts ? '#ffd5d5' : '#ebf7f6',
        boxShadow: 1,
        p: 0,
        width: '100%',
        border: hasAlerts ? '1px solid red' : '',
        borderRadius: '4px',
        marginTop: '4px',
      }}
    >
      <CardHeader
        sx={{
          marginLeft: '-10px',
          paddingTop: '0px',
          paddingLeft: '10px',
          paddingRight: '10ox',
          paddingBottom: '0px',
          border: '1px solid rgba(0,0,0, 0.1)',
        }}
        title={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: '0 0 auto', flexDirection: 'column' }}>
              <Box
                sx={{
                  padding: '0px',
                }}
              >
                <div
                  style={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: '0px',
                    }}
                  >
                    {responsiveText}
                  </Typography>
                </div>
              </Box>
            </div>
          </div>
        }
        avatar={
          <Avatar
            sx={{
              backgroundColor: 'transparent',
              padding: '0px',
              margin: '0px 2px 0px 2px',
              width: '25px',
              height: '25px',
            }}
          >
            {ContactSensorIcon(device)}
          </Avatar>
        }
        action={
          <List dense={false}>
            <ListItem
              sx={{
                '&:hover': { backgroundColor: 'InfoBackground' },
                padding: 0,
              }}
            >
              <ListItemIcon>
                <ToggleAlertDetailsButton
                  device={device}
                  expand={alertsExpanded}
                  onClick={(event) => {
                    event.preventDefault();
                    setAlertsExpanded(!alertsExpanded);
                  }}
                  buttonLabel="Show alerts"
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <IconButton
                    sx={{
                      padding: '0px 6px 0px px',
                      marginLeft: '-10px',
                    }}
                    edge="end"
                    aria-label="delete"
                  >
                    <Battery charge={getBatteryLevel(voltage)} />
                  </IconButton>
                  <div
                    style={{
                      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                      borderRightWidth: 'thin',
                      margin: '2px 6px',
                    }}
                  >
                    &nbsp;
                  </div>
                </div>
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon style={{ padding: '0px', margin: '0px' }} />
                </ExpandMore>
              </ListItemIcon>
            </ListItem>
          </List>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <DeviceInfo infoEntries={deviceInfo} />
      </Collapse>
      <DeviceAlerts device={device} alertsExpanded={alertsExpanded} />
    </Card>
  ) : null;
}
