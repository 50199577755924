import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  useTheme,
  Typography,
} from '@mui/material';

export const ThermostatClearScheduleDialog = ({
  open,
  handleClearSchedule,
  handleClose,
}: {
  open: boolean;
  handleClearSchedule: () => void;
  handleClose: () => void;
}) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        margin: 0,
        '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
          margin: 0,
          width: '100%',
        },
      }}
    >
      <DialogContent>
        <Typography variant="h3">
          Are you sure you want to clear the current schedule?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container rowSpacing={1} direction={'row'}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              color={'info'}
              sx={{ color: '#fff' }}
              onClick={() => {
                handleClearSchedule();
                handleClose();
              }}
            >
              Clear Schedule
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              sx={{ color: theme.embue.bad }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClose();
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
