import React from 'react';
import { useFormik } from 'formik';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { Fade } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FormValues } from '../types/setpointLimitProfileDetailFormValues';
import BorderedSection from '../../shared/borderedSection';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Slider } from '../../devices/thermostat/components/system-edit/slider';
import Box from '@mui/material/Box';
import { defaultAbsoluteLimits } from '../../helpers';
import { useTheme } from '@mui/material/styles';
import { TemperatureUnit } from '../../../system/models/temperatureUnits';

export function SetpointLimitProfileGeneralTab({
  formik,
  onChange,
  handleSetpointLimitChange,
  targetUnits,
  isSuper,
}: {
  formik: ReturnType<typeof useFormik<FormValues>>;
  onChange: StandardInputProps['onChange'];
  isSuper: boolean;

  handleChange: (
    changes: Record<string, number | string>,
    ensureConformity?: boolean,
  ) => void;
  handleSetpointLimitChange: (
    key: string,
    value: number,
  ) => Record<string, number | string | boolean | string[]>;
  targetUnits: TemperatureUnit;
}) {
  const theme = useTheme();
  const defaultLimits = defaultAbsoluteLimits[targetUnits];

  // noinspection DuplicatedCode
  const handleSlider = (
    keys: [string, string],
    newValues: number | number[],
    activeThumb: number,
  ) => {
    /* required check because slider API requires both number and number[] */
    if (!Array.isArray(newValues)) {
      return;
    }

    if (activeThumb === 0) {
      handleSetpointLimitChange(keys[0], newValues[0] as number);
    } else {
      handleSetpointLimitChange(keys[1], newValues[1] as number);
    }
  };

  const minSliderValue = Math.min(
    defaultLimits.heat.min,
    defaultLimits.cool.min,
  );
  const maxSliderValue = Math.max(
    defaultLimits.heat.max,
    defaultLimits.cool.max,
  );

  return (
    <Fade in={true} style={{ transitionDuration: '1100ms' }}>
      <div>
        <TextField
          fullWidth
          autoFocus
          id="name"
          name="name"
          placeholder="Setpoint Profile Name"
          label="Name"
          value={formik.values.name}
          onChange={onChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        {isSuper ? (
          <div>
            <br />
            <br />
            <TextField
              fullWidth
              id="id"
              name="id"
              disabled={true}
              placeholder="Setpoint Profile ID"
              label="Policy Id"
              value={formik.values._id}
              onChange={onChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </div>
        ) : null}
        <br />
        <br />
        <BorderedSection title={`Setpoint Limits (in  \xB0${targetUnits})`}>
          <Box
            sx={{
              justifyContent: 'center',
              px: 4,
              py: 2,
            }}
          >
            <Stack
              spacing={2}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant="button">{minSliderValue}</Typography>
              <Slider
                getAriaLabel={() => 'Heat Setpoint Limits'}
                value={[
                  formik.values.minHeatSetpointLimit as number,
                  formik.values.maxHeatSetpointLimit as number,
                ]}
                min={minSliderValue}
                max={maxSliderValue}
                onChange={(
                  event: Event,
                  newValues: number | number[],
                  activeThumb: number,
                ) =>
                  handleSlider(
                    ['minHeatSetpointLimit', 'maxHeatSetpointLimit'],
                    newValues,
                    activeThumb,
                  )
                }
                valueLabelDisplay="on"
                // getAriaValueText={valuetext}
                disableSwap
                sx={{
                  color: theme.embue.heating,
                  py: 2,
                }}
              />
              <Typography variant="button">{maxSliderValue}</Typography>
            </Stack>

            <Stack
              spacing={2}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant="button">{minSliderValue}</Typography>
              <Slider
                getAriaLabel={() => 'Cool Setpoint Limits'}
                value={[
                  formik.values.minCoolSetpointLimit as number,
                  formik.values.maxCoolSetpointLimit as number,
                ]}
                min={minSliderValue}
                max={maxSliderValue}
                onChange={(
                  event: Event,
                  newValues: number | number[],
                  activeThumb: number,
                ) =>
                  handleSlider(
                    ['minCoolSetpointLimit', 'maxCoolSetpointLimit'],
                    newValues,
                    activeThumb,
                  )
                }
                valueLabelDisplay="on"
                disableSwap
                sx={{
                  color: theme.embue.cooling,
                  py: 2,
                }}
              />
              <Typography variant="button">{maxSliderValue}</Typography>
            </Stack>
          </Box>
        </BorderedSection>
      </div>
    </Fade>
  );
}
