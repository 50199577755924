/**
 * Unit Schedule Overview
 * - renders a list of zones along with their associated thermostat schedules
 */
import React from 'react';
import { MyUnitSchedule } from '../../types/scheduleList';
import { UnitZoneSchedule } from './unit-zone-schedule';
import { Zone } from '../../../../../types/generated-types';

interface UnitScheduleOverviewProps {
  data?: MyUnitSchedule;
}

export function UnitScheduleOverview(props: UnitScheduleOverviewProps) {
  const { data } = props;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      {data?.zones
        ? data?.zones.map((zone, index) => {
            return (
              <UnitZoneSchedule key={index} zone={zone as Zone} index={index} />
            );
          })
        : null}
    </div>
  );
}
