import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogContent, useMediaQuery } from '@mui/material';

import BorderedSection from '../../shared/borderedSection';
import { useRetrieveSmsNumberQuery } from '../../../../types/generated-types';

import { formatPhoneNumber } from '../../../../common/helpers';

export interface PropertySmsOptedOutHelpDialogProps {
  dialogOpen: boolean;
  handleClose: () => void;
  mobileNumber: string;
}

export function PropertySmsOptedOutHelpDialog(
  props: PropertySmsOptedOutHelpDialogProps,
) {
  const { dialogOpen, handleClose, mobileNumber } = props;
  const [outboundSmsNumber, setOutboundSmsNumber] = useState<string>('');

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const { data } = useRetrieveSmsNumberQuery();

  useEffect(() => {
    if (data?.retrieveSmsNumber) {
      setOutboundSmsNumber(data.retrieveSmsNumber);
    }
  }, [data]);

  return mobileNumber && outboundSmsNumber ? (
    <Dialog
      open={dialogOpen}
      aria-labelledby="alert-sms-opted-out-help-dialog-title"
      aria-describedby="alert-sms-opted-out-help-dialog-description"
    >
      <div
        id="alert-sms-opted-out-help-dialog-title"
        style={{ textAlign: 'center', marginTop: '6px', marginBottom: '6px' }}
      >
        <span
          style={{
            fontSize: '18px',
            fontWeight: 'bolder',
          }}
        >
          Opted-Out / Blocked SMS Help
        </span>
      </div>
      <DialogContent
        sx={
          isSmall
            ? { margin: '-2px 2px 2px', padding: '2px 2px' }
            : { marginTop: '-10px' }
        }
      >
        <div style={{ padding: '6px' }}>
          <BorderedSection
            title="What is wrong?"
            ccStyle={{ padding: '6px 6px 8px', marginTop: '-6px' }}
          >
            <div>
              Previously, we have received an OPT-OUT response (typically STOP)
              from the mobile number you are trying to subscribe,{' '}
              {formatPhoneNumber(mobileNumber)}. This prevents us from
              communicating via SMS with any device associated with that phone
              number.
            </div>
          </BorderedSection>
          <BorderedSection
            title="What you can do"
            ccStyle={{ padding: '6px 6px 8px', marginTop: '-6px' }}
          >
            <div>
              To (re)start receiving alerts at {formatPhoneNumber(mobileNumber)}
              , you must clear the OPTED-OUT status for{' '}
              {formatPhoneNumber(mobileNumber)} by sending an SMS message
              containing the word START to{' '}
              {formatPhoneNumber(outboundSmsNumber)}
            </div>
          </BorderedSection>
          <div
            style={{
              margin: isSmall ? '8px 0px 0px 0px' : '10px 0px 6px 0px',
              // width: isSmall ? 'unset' : 'calc(100vw - 80px)',
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              padding: '10px',
              border: '1px solid grey',
              borderRadius: '4px',
            }}
          >
            <Button
              fullWidth
              style={
                isSmall
                  ? {
                      alignSelf: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      flex: '1 1 auto',
                      marginTop: '12px',
                      marginBottom: '0px',
                      width: '100%',
                      border: '1px solid darkgrey',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)',
                    }
                  : {
                      alignSelf: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: '8px',
                      maxWidth: '450px',
                      marginTop: '20px',
                      border: '1px solid darkgrey',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)',
                    }
              }
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  ) : null;
}
