import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

interface EnterEuidDialogProps {
  show: boolean;
  handleInput: (input: string) => void;
  handleClose: () => void;
}

/* Valid string input format allows for hexadecimal digits as well as the '-' used for masking */
const VALID_INPUT_STRING = /^[a-fA-F0-9-]+$/;
const VALID_INPUT_LENGTH = 16;

export function EnterEuidDialog(props: EnterEuidDialogProps) {
  const { show, handleInput, handleClose } = props;

  const [formattedInput, setFormattedInput] = React.useState<string>('');
  const [input, setInput] = React.useState<string>('');
  const [showSave, setShowSave] = React.useState<boolean>(false);
  const [showInstructions, setShowInstructions] = React.useState<boolean>(true);

  React.useEffect(() => {
    setInput(parseInput(formattedInput));
    setShowSave(isInputValid(formattedInput));
    setShowInstructions(() => {
      return parseInput(formattedInput).length !== VALID_INPUT_LENGTH;
    });
  }, [formattedInput]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const rawValue = parseInput(value);

    /* discard invalid input */
    if (
      (value !== '' && !VALID_INPUT_STRING.test(value)) ||
      rawValue.length > VALID_INPUT_LENGTH
    )
      return;

    setFormattedInput(() => {
      return formatInput(rawValue);
    });
  };

  return (
    <Dialog fullWidth open={show} onClose={handleClose}>
      <DialogTitle>Enter Device EUID</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter 16-digit EUID:</DialogContentText>
        {showInstructions && (
          <DialogContentText variant="caption">
            [Entry needs to be valid hex code (0-9, a-f) -{' '}
            {VALID_INPUT_LENGTH - input.length} characters remaining]
          </DialogContentText>
        )}
        <TextField
          autoFocus
          margin="dense"
          id="euid"
          label="Device EUID"
          type="text"
          fullWidth
          variant="standard"
          sx={{
            '& input': {
              fontFamily: 'monospace',
              fontSize: {
                xs: '20px',
                sm: '30px',
                md: '40px',
              },
            },
          }}
          value={formattedInput}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant={'contained'} color={'error'} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant={'contained'}
          color={'success'}
          disabled={!showSave}
          onClick={() => {
            handleInput(input);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * isInputValid
 * - ensure that the input string is valid according to validity rules (hex code) and length
 * - used to toggle the 'save' button
 * @param input string entered in the text dialog
 * @returns boolean
 */
function isInputValid(input: string): boolean {
  return (
    parseInput(input).length === VALID_INPUT_LENGTH &&
    VALID_INPUT_STRING.test(input)
  );
}

/**
 * formatInput
 * - mask text field input for better legibility
 * - insert a dash '-' after every fourth character, but not after the trailing character.
 * @param input
 * @returns string
 */
function formatInput(input: string) {
  return input
    .split('')
    .map((v, i, a) => {
      if (a.length - 1 > i && (i + 1) % 4 === 0) {
        return v + '-';
      }
      return v;
    })
    .join('');
}

/**
 * parseInput
 * - extract the raw value of the input string, discarding the formatting delimiters
 * @param input string entered in the text dialog
 * @returns string
 */
function parseInput(input: string) {
  return input.toLowerCase().replace(/[-]/g, '');
}
