import React from 'react';
import { useFormik } from 'formik';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { Fade } from '@mui/material';
import TextField from '@mui/material/TextField';
import BorderedSection from '../../shared/borderedSection';
import { FormValues } from '../types/zonePolicyDetailFormValues';
import { AuthorizedUser } from '../../../auth/models';
import JSONInput from 'react-json-editor-ajrm';

export const locale: (typeof JSONInput.arguments)['locale'] = {
  format: '{reason} at line {line}',
  symbols: {
    colon: 'colon',
    // :
    comma: 'comma',
    // ,  ،  、
    semicolon: 'semicolon',
    // ;
    slash: 'slash',
    // /  relevant for comment syntax support
    backslash: 'backslash',
    // \  relevant for escaping character
    brackets: {
      round: 'round brackets',
      // ( )
      square: 'square brackets',
      // [ ]
      curly: 'curly brackets',
      // { }
      angle: 'angle brackets', // < >
    },
    period: 'period',
    // . Also known as full point, full stop, or dot
    quotes: {
      single: 'single quote',
      // '
      double: 'double quote',
      // "
      grave: 'grave accent', // ` used on Javascript ES6 Syntax for String Templates
    },
    space: 'space',
    //
    ampersand: 'ampersand',
    //	&
    asterisk: 'asterisk',
    //	*  relevant for some comment syntax
    at: 'at sign',
    //	@  multiple uses in other coding languages including certain data types
    equals: 'equals sign',
    //	=
    hash: 'hash',
    //	#
    percent: 'percent',
    //	%
    plus: 'plus',
    //	+
    minus: 'minus',
    //	−
    dash: 'dash',
    //	−
    hyphen: 'hyphen',
    //	−
    tilde: 'tilde',
    //	~
    underscore: 'underscore',
    //	_
    bar: 'vertical bar', //	|
  },
  types: {
    key: 'key',
    value: 'value',
    number: 'number',
    string: 'string',
    primitive: 'primitive',
    boolean: 'boolean',
    character: 'character',
    integer: 'integer',
    array: 'array',
    float: 'float', //... Reference: https://en.wikipedia.org/wiki/List_of_data_structures
  },
  invalidToken: {
    tokenSequence: {
      prohibited:
        "'{firstToken}' token cannot be followed by '{secondToken}' token(s)",
      permitted:
        "'{firstToken}' token can only be followed by '{secondToken}' token(s)",
    },
    termSequence: {
      prohibited: 'A {firstTerm} cannot be followed by a {secondTerm}',
      permitted: 'A {firstTerm} can only be followed by a {secondTerm}',
    },
    double: "'{token}' token cannot be followed by another '{token}' token",
    useInstead: "'{badToken}' token is not accepted. Use '{goodToken}' instead",
    unexpected: "Unexpected '{token}' token found",
  },
  brace: {
    curly: {
      missingOpen: "Missing '{' open curly brace",
      missingClose: "Open '{' curly brace is missing closing '}' curly brace",
      cannotWrap: "'{token}' token cannot be wrapped in '{}' curly braces",
    },
    square: {
      missingOpen: "Missing '[' open square brace",
      missingClose: "Open '[' square brace is missing closing ']' square brace",
      cannotWrap: "'{token}' token cannot be wrapped in '[]' square braces",
    },
  },
  string: {
    missingOpen: "Missing/invalid opening string '{quote}' token",
    missingClose: "Missing/invalid closing string '{quote}' token",
    mustBeWrappedByQuotes: 'Strings must be wrapped by quotes',
    nonAlphanumeric:
      "Non-alphanumeric token '{token}' is not allowed outside string notation",
    unexpectedKey: 'Unexpected key found at string position',
  },
  key: {
    numberAndLetterMissingQuotes:
      'Key beginning with number and containing letters must be wrapped by quotes',
    spaceMissingQuotes: 'Key containing space must be wrapped by quotes',
    unexpectedString: 'Unexpected string found at key position',
  },
  noTrailingOrLeadingComma:
    'Trailing or leading commas in arrays and objects are not permitted',
};

export const removeTypeNames = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeTypeNames);
  } else if (obj === null) {
    return null;
  } else if (typeof obj !== 'object') {
    return obj;
  } else {
    const res: any = {};
    for (const key in obj) {
      if (key !== '__typename') {
        res[key] = removeTypeNames(obj[key]);
      }
    }
    return res;
  }
};

export function ZonePolicyGeneralTab(props: {
  currentUser: AuthorizedUser;
  formik: ReturnType<typeof useFormik<FormValues>>;
  onChange: StandardInputProps['onChange'];
}) {
  return (
    <Fade in={true} style={{ transitionDuration: '1100ms' }}>
      <div>
        <TextField
          fullWidth
          autoFocus
          id="name"
          name="name"
          placeholder="Zone Policy Name"
          label="Name"
          value={props.formik.values.name}
          onChange={props.onChange}
          error={props.formik.touched.name && Boolean(props.formik.errors.name)}
          helperText={props.formik.touched.name && props.formik.errors.name}
        />
        <br />
        <br />
        <TextField
          fullWidth
          id="id"
          name="id"
          disabled={true}
          placeholder="Zone Policy ID"
          label="Policy Id"
          value={props.formik.values._id}
          onChange={props.onChange}
          error={props.formik.touched.name && Boolean(props.formik.errors.name)}
          helperText={props.formik.touched.name && props.formik.errors.name}
        />
        <br />
        <br />
        <BorderedSection title="Zone Policy Rules">
          <JSONInput
            id="zone-policy-json-editor"
            placeholder={
              props.formik.values.rules
                ? removeTypeNames(props.formik.values.rules)
                : 'No Rules Defined'
            }
            height="100%"
            width="100%"
            viewOnly={true}
            style={{
              body: { borderRadius: '4px' },
              contentBox: {
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px',
              },
              labelColumn: {
                backgroundColor: 'rgb(6 106 27)',
                textAlign: 'right',
                paddingRight: '4px',
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
              },
            }}
            locale={locale}
          />
        </BorderedSection>
      </div>
    </Fade>
  );
}
