// Configure the base setpoint limits for this thermostat, from any assigned unit or property

import { CurrentAttributes, Maybe, SetpointProfile } from '../types';
import { defaultAbsoluteLimits } from '../../../helpers';
import { convertToTempUnits } from '../../util/convert-temp-units';
import { TemperatureUnit } from '../../../../system/models/temperatureUnits';

// setpoint limit profile, if one exists, falling back to the default absolute setpoint limits.
export const configureBaseSetpointLimits = (
  settings: CurrentAttributes,
  preferredUnits: TemperatureUnit,
  unitSetpointProfile?: Maybe<SetpointProfile>,
  propertySetpointProfile?: Maybe<SetpointProfile>,
): CurrentAttributes => {
  const myDefaultAbsoluteLimits = defaultAbsoluteLimits[preferredUnits];
  const newSettings = { ...settings };
  if (unitSetpointProfile) {
    newSettings.setpointLimitBasis = 'unit';
    newSettings.baseMaxCoolSetpointLimit =
      convertToTempUnits(unitSetpointProfile.cool?.max, preferredUnits) ??
      myDefaultAbsoluteLimits.cool.max;
    newSettings.baseMaxHeatSetpointLimit =
      convertToTempUnits(unitSetpointProfile.heat?.max, preferredUnits) ??
      myDefaultAbsoluteLimits.heat.max;
    newSettings.baseMinCoolSetpointLimit =
      convertToTempUnits(unitSetpointProfile.cool?.min, preferredUnits) ??
      myDefaultAbsoluteLimits.cool.min;
    newSettings.baseMinHeatSetpointLimit =
      convertToTempUnits(unitSetpointProfile.heat?.min, preferredUnits) ??
      myDefaultAbsoluteLimits.heat.min;
  } else if (propertySetpointProfile) {
    newSettings.setpointLimitBasis = 'property';
    newSettings.baseMaxCoolSetpointLimit =
      convertToTempUnits(propertySetpointProfile.cool?.max, preferredUnits) ??
      myDefaultAbsoluteLimits.cool.max;
    newSettings.baseMaxHeatSetpointLimit =
      convertToTempUnits(propertySetpointProfile.heat?.max, preferredUnits) ??
      myDefaultAbsoluteLimits.heat.max;
    newSettings.baseMinCoolSetpointLimit =
      convertToTempUnits(propertySetpointProfile.cool?.min, preferredUnits) ??
      myDefaultAbsoluteLimits.cool.min;
    newSettings.baseMinHeatSetpointLimit =
      convertToTempUnits(propertySetpointProfile.heat?.min, preferredUnits) ??
      myDefaultAbsoluteLimits.heat.min;
  } else {
    newSettings.setpointLimitBasis = 'default';
    newSettings.baseMaxCoolSetpointLimit = myDefaultAbsoluteLimits.cool.max;
    newSettings.baseMaxHeatSetpointLimit = myDefaultAbsoluteLimits.heat.max;
    newSettings.baseMinCoolSetpointLimit = myDefaultAbsoluteLimits.cool.min;
    newSettings.baseMinHeatSetpointLimit = myDefaultAbsoluteLimits.heat.min;
  }
  return newSettings;
};
