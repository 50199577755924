/**
 * Global theme object:
 * - current palette is using generated colors based on Embue color
 * - added theme option for custom Embue colors
 * - declare module and interfaces for custom colors
 */

import React from 'react';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    embue: {
      iconColor: string;
      textColor: string;
      darkBackground: string;
      heating: string;
      cooling: string;
      humidity: string;
      good: string;
      bad: string;
      off: string;
    };
  }

  // TODO: Calvin: Do we need this? It is not referenced anywhere.
  interface ThemeOptions {
    embue: {
      iconColor: React.CSSProperties['color'];
      textColor: React.CSSProperties['color'];
      darkBackground: React.CSSProperties['color'];
      heating: React.CSSProperties['color'];
      cooling: React.CSSProperties['color'];
      humidity: React.CSSProperties['color'];
      good: React.CSSProperties['color'];
      bad: React.CSSProperties['color'];
      off: React.CSSProperties['color'];
    };
  }
}

export const mdTheme = createTheme({
  typography: {
    subtitle1: {
      fontSize: '1.5rem',
    },
  },
  palette: {
    primary: {
      main: '#466BA5',
      light: '#7899D7',
      dark: '#074176',
    },
    secondary: {
      main: '#36AFA5',
      light: '#6FE1D6',
      dark: '#007F76',
    },
    error: {
      main: '#B04C48',
    },
    warning: {
      main: '#B07925',
    },
    success: {
      main: '#2EB05B',
    },
  },
  embue: {
    iconColor: '#36AFA5',
    textColor: '#133D3A',
    darkBackground: '#4A776E',
    heating: '#F4A53B',
    cooling: '#54BDE1',
    humidity: '#0b77c0',
    good: '#80BB52',
    bad: '#F26A4B',
    off: 'darkgrey',
  },
});

const sharedMediaQueries = {
  // fontSize: '1rem',
  // '@media (min-width:600px)': {
  //   fontSize: '1.3rem !important',
  // },
};

export const mdLightTheme = responsiveFontSizes(
  createTheme({
    typography: {
      h6: {
        fontSize: '.57rem',
        '@media (min-width:600px)': {
          fontSize: '.67rem !important',
        },
      },
      h5: {
        fontSize: '.763rem',
        '@media (min-width:600px)': {
          fontSize: '.863rem !important',
        },
      },
      h4: {
        fontSize: '1rem',
        '@media (min-width:600px)': {
          fontSize: '1.3rem !important',
        },
      },
      h3: {
        fontSize: '1.25rem',
        '@media (min-width:600px)': {
          fontSize: '1.5rem !important',
        },
      },
      subtitle1: {
        fontSize: '1.5rem',
      },
    },
    components: {
      MuiIconButton: {
        defaultProps: {
          sx: { ...sharedMediaQueries },
        },
      },
      MuiSvgIcon: {
        defaultProps: {
          sx: { ...sharedMediaQueries },
        },
      },
    },
    palette: {
      primary: {
        main: '#b0b0b0', // NK: changed to make primary colored controls visible
        light: '#0f0f0f',
        dark: '#c7c7c7',
      },
      secondary: {
        main: '#36AFA5',
        light: '#6FE1D6',
        dark: '#007F76',
      },
      error: {
        main: '#F26A4B',
      },
      warning: {
        // changed warning color from brown to yellow
        // main: '#B07925',
        main: 'rgba(233,217,1,1)',
      },
      success: {
        main: '#80BB52',
      },
    },
    embue: {
      iconColor: '#36AFA5',
      textColor: '#133D3A',
      darkBackground: '#4A776E',
      heating: '#F4A53B',
      cooling: '#54BDE1',
      humidity: '#0b77c0',
      good: '#80BB52',
      bad: '#F26A4B',
      off: 'darkgrey',
    },
  }),
);
