/**
 * Thermostat Display Screen
 * - prominently display the three main readings of the thermostat
 * - if offline display a warning label with a timestamp
 */
import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { SetPoints } from '..';
import { DisplayValue } from '../../../shared-ui';
import { ViewMode } from '../../thermostat';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';

interface DisplayScreenProps {
  coolSetpoint?: number;
  heatSetpoint?: number;
  humidity?: number;
  systemType?: string;
  operatingMode?: string;
  operatingState?: string;
  preferredUnits?: string;
  viewMode?: ViewMode;
  temperature?: number;
  timestamp?: number;
  handleTargetChange: (targetMode: 'heat' | 'cool', delta: number) => void;
  handleChange: (changes: Record<string, number | string | boolean>) => void;
}

export function DisplayScreen(props: DisplayScreenProps) {
  const {
    preferredUnits,
    temperature,
    heatSetpoint,
    coolSetpoint,
    humidity,
    systemType,
    operatingMode,
    operatingState,
    viewMode,
    timestamp,
    handleTargetChange,
    handleChange,
  } = props;
  const border = (viewMode === ViewMode.OFFLINE && '5px solid red') || '';
  let formatTimestamp = '';

  if (timestamp && timestamp > 0) {
    formatTimestamp = dayjs(timestamp).format('LLLL');
  }

  return (
    <Box sx={{ border, px: 0, py: 0 }}>
      {/* Display current temp, set temperatures, and current humidity */}
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-evenly'}
        divider={<Divider orientation="vertical" flexItem />}
      >
        {/* Current Temp */}
        <DisplayValue
          value={temperature}
          label="Temperature"
          units={preferredUnits}
          mode={operatingState} // mode={operatingMode} - switched to operatingState for current values to mimic old super behavior
          size="h2"
          unitsSize="22px"
        />
        {/* Setpoints - With buttons in edit mode*/}
        {operatingMode === 'Off' ? (
          <DisplayValue
            value="Off"
            label={`Setpoints:
                  ${
                    !systemType
                      ? 'None'
                      : ['HeatAndCool', 'SmartAhu'].includes(systemType)
                        ? heatSetpoint + ' | ' + coolSetpoint
                        : systemType === 'Cool'
                          ? coolSetpoint
                          : heatSetpoint
                  }`}
            mode="Off"
            size="h2"
            unitsSize="22px"
          />
        ) : (
          <SetPoints
            operatingMode={operatingMode}
            heatSetpoint={heatSetpoint}
            coolSetpoint={coolSetpoint}
            units={preferredUnits}
            editable={
              viewMode === ViewMode.EDIT || viewMode === ViewMode.EDIT_UNCHANGED
            }
            handleTargetChange={handleTargetChange}
            handleChange={handleChange}
          />
        )}
        {/* Current RH */}
        <DisplayValue
          value={humidity}
          units={'%'}
          label="relative humidity"
          mode="Humidity" //hard-coded, since the operating mode and state never change or even exist.
          size="h2"
          unitsSize="22px"
        />
      </Stack>
      {viewMode === ViewMode.OFFLINE ? (
        <Box
          sx={{
            margin: '0px',
            marginTop: '4px',
            marginY: '4px',
            padding: '0px',
            width: '100%',
          }}
          height="auto"
          textAlign={'center'}
        >
          <Button
            disabled
            variant={'contained'}
            sx={{
              width: '95%',
              fontSize: '12px',
              '&.Mui-disabled': {
                backgroundColor: 'red',
                color: 'white',
              },
            }}
          >
            <Stack>
              <Typography variant={'h6'}>Device Offline</Typography>
              <Typography variant={'h6'}>{formatTimestamp}</Typography>
            </Stack>
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}
