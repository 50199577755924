import React, { useEffect, useState } from 'react';
import { useLongPress, LongPressDetectEvents } from 'use-long-press';

/* MUI */
import {
  Dialog,
  DialogContent,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  DialogContentText,
  DialogActions,
  Button,
  useMediaQuery,
  Box,
  Typography,
} from '@mui/material';
import { BottomBreadcrumbs } from './bottom-breadcrumbs';
import { useTheme } from '@mui/material/styles';

/* COMPONENTS */
import {
  BottomNavigation,
  BottomNavigationAction,
  PrimaryBottomNavigationComponent,
} from './components/bottom-navigation';
import { Notifier } from '../../system/services/notificationManager';

/* ICONS */
import { Icon } from '@iconify/react';

import {
  useInjectableComponents,
  BottomMenu,
  ConfirmableAction,
} from '../../system/services/injectableComponentsManager';
import { useNavigate } from 'react-router-dom';
import { useSystemHistory } from '../history/history-manager';

export interface BreadcrumbDialogProps {
  anchorHistoryEl?: HTMLElement | null;
  onClose: (value: string) => void;
  open: boolean;
}

export interface SharedDialogProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleCloseMenu: () => void;
}

function BreadcrumbDialog(props: BreadcrumbDialogProps) {
  const { onClose, anchorHistoryEl, open } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <BottomBreadcrumbs
        open={open}
        anchorHistoryEl={anchorHistoryEl}
        onClose={onClose}
      />
    </Dialog>
  );
}

function InjectableBottomMenu(
  props: SharedDialogProps & {
    menu: BottomMenu;
  },
) {
  const { open, anchorEl, handleCloseMenu, menu } = props;
  const { items } = menu;
  const [showDialog, setShowDialog] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState('');
  const [confirmableAction, setConfirmableAction] =
    useState<ConfirmableAction>();

  // Update confirmable action any time a different menu item is selected, or the selected item has any changes.
  // The selected item might change if it has a confirmable action that includes any custom content to render.
  useEffect(() => {
    if (selectedItemId) {
      items.forEach((item) => {
        item.items.forEach((subItem) => {
          if (subItem.id === selectedItemId) {
            setConfirmableAction(subItem.confirmableAction);
          }
        });
      });
    } else {
      setConfirmableAction(undefined);
    }
  }, [items, selectedItemId]);

  return (
    <Popover
      open={open}
      onClose={() => {
        handleCloseMenu();
        setSelectedItemId('');
        setConfirmableAction(undefined);
      }}
      keepMounted
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Paper sx={{ width: 320, maxWidth: '100%' }}>
        <MenuList>
          {items.map((item, index) => {
            const res = [];
            if (
              item.showDivider ||
              ((index > 0 || item.label) && false !== item.showDivider)
            ) {
              res.push(
                item.label ? (
                  <Divider
                    key={`menu-divider-${index}`}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: 'lightblue',
                    }}
                    textAlign="left"
                  >
                    {item.label}
                  </Divider>
                ) : (
                  <Divider key={`menu-divider-${index}`} />
                ),
              );
            }

            item.items.forEach((subItem, subIndex) => {
              let action: () => void;
              if (subItem.confirmableAction) {
                action = () => {
                  setSelectedItemId(subItem.id);
                  setShowDialog(true);
                };
              } else {
                action = subItem.action ?? (() => {});
              }
              res.push(
                <MenuItem
                  key={`menu-${index}-${subItem.id ?? subIndex}`}
                  id={subItem.id}
                  onClick={() => {
                    action();
                    handleCloseMenu();
                  }}
                >
                  {subItem.icon && <ListItemIcon>{subItem.icon}</ListItemIcon>}
                  <ListItemText>{subItem.label}</ListItemText>
                </MenuItem>,
              );
            });

            return res;
          })}
        </MenuList>
        {confirmableAction && (
          <ActionMenuConfirmationDialog
            confirmableAction={confirmableAction}
            open={showDialog}
            handleClose={() => setShowDialog(false)}
          />
        )}
      </Paper>
    </Popover>
  );
}

const ActionMenuConfirmationDialog = ({
  confirmableAction,
  open,
  handleClose,
}: {
  confirmableAction: ConfirmableAction;
  open: boolean;
  handleClose: () => void;
}) => {
  const { title, action, prompt, actionLabel, cancelLabel, customContent } =
    confirmableAction;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography variant="h4" sx={{ padding: '15px' }}>
          {title}
        </Typography>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {prompt}
          </DialogContentText>
          {customContent && <Box sx={{ mt: 2 }}>{customContent}</Box>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{cancelLabel}</Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ color: '#fff' }}
            onClick={async () => {
              try {
                await action();
                handleClose();
              } catch (error) {
                if (error instanceof Error) {
                  Notifier.error(error.message);
                } else {
                  Notifier.error('Unknown error while performing action');
                }
              }
            }}
            autoFocus
          >
            {actionLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const SecondaryActionIcon = () => {
  const { secondaryNavigationMenu } = useInjectableComponents();

  if (secondaryNavigationMenu) {
    return <Icon icon="solar:hamburger-menu-bold" fontSize="30px" />;
  } else {
    return null;
  }
};

interface SecondaryActionMenuProps {
  anchorEl: any;
  handleCloseMenu: () => void;
}

const SecondaryActionMenu = (props: SecondaryActionMenuProps) => {
  const { anchorEl, handleCloseMenu } = props;
  const { secondaryNavigationMenu } = useInjectableComponents();
  const open = Boolean(anchorEl);

  if (secondaryNavigationMenu) {
    return (
      <InjectableBottomMenu
        open={open}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        menu={secondaryNavigationMenu}
      />
    );
  } else {
    return null;
  }
};

interface BottomNavbarProps {
  handleRouteChange: (route: string) => void;
  primaryNavigationAction?: React.ReactNode;
  secondaryNavigationAction: {
    value: any;
    icon: React.ReactNode;
  };
}

export function BottomNavbar(props: BottomNavbarProps) {
  const { handleRouteChange } = props;

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  const [value, setValue] = useState('');
  // TODO: Calvin: do we need this any longer? it is only used for the
  //  long-press handler which, seemingly, now does nothing as
  //  the 'openCrumbs' is never called anywhere!
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_openCrumbs, enableCrumbs] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [anchorHistoryEl, setAnchorHistoryEl] =
    React.useState<HTMLElement | null>(null);

  const { secondaryNavigationMenu } = useInjectableComponents();

  const handleOpenHistoryMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorHistoryEl(event.currentTarget);
  };
  const handleCloseHistoryMenu = () => {
    setAnchorHistoryEl(null);
  };

  const { primaryNavigationWidget } = useInjectableComponents();

  const navigate = useNavigate();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const bind = useLongPress((event: any) => handleOpenHistoryMenu(event), {
    onStart: () => enableCrumbs(true),
    onFinish: () => enableCrumbs(false),
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH,
  });

  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { back, forward, hasPrevious, hasNext } = useSystemHistory();

  const openBreadcrumbDialog = Boolean(anchorHistoryEl);

  return (
    <BottomNavigation
      // showLabels={true}
      // value={value}
      // onChange={(event, newValue) => {
      //   setValue(newValue);
      //   return handleRouteChange(newValue);
      // }}
      sx={isSmall ? { height: '80px', paddingBottom: '17px' } : null}
    >
      <BottomNavigationAction
        style={{
          maxWidth: '120px',
          minWidth: isSmall ? '60px' : 'inherit',
          borderLeft: isSmall ? '1px solid lightgray' : '1px solid lightgrey',
        }}
        disabled={!hasPrevious}
        {...bind()}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          back();
        }}
        showLabel
        icon={<Icon icon="ion:arrow-back-circle-sharp" fontSize="30px" />}
        label="Back"
      />
      <BottomNavigationAction
        style={{
          maxWidth: '120px',
          borderLeft: '1px solid lightgrey',
          minWidth: isSmall ? '60px' : 'inherit',
        }}
        disabled={!hasNext}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          forward();
        }}
        showLabel
        icon={<Icon icon="ion:arrow-forward-circle-sharp" fontSize="30px" />}
        label="Forward"
      />
      {primaryNavigationWidget ? (
        <PrimaryBottomNavigationComponent>
          {primaryNavigationWidget}
        </PrimaryBottomNavigationComponent>
      ) : (
        // <div
        //   style={{
        //     display: 'flex',
        //     flex: isSmall ? '1 1 auto' : '0 1 auto',
        //     minWidth: '200px',
        //     color: 'red',
        //     borderLeft: '1px solid lightgrey',
        //   }}
        // >
        //   {primaryNavigationWidget}
        // </div>
        <BottomNavigationAction
          style={{
            flex: isSmall ? '1 1 auto' : '0 1 auto',
            borderLeft: '1px solid lightgrey',
            minWidth: '100px',
          }}
          label="Properties"
          onClick={() => {
            // TODO: Peter: this should actually navigate to the user's preferred home page.
            navigate('/properties');
          }}
          showLabel
          value="properties"
          icon={<Icon icon="mdi:home-circle" fontSize="30px" />}
        />
      )}
      <BreadcrumbDialog
        open={openBreadcrumbDialog}
        anchorHistoryEl={anchorHistoryEl}
        onClose={() => handleCloseHistoryMenu()}
      />
      <SecondaryActionMenu
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
      />
      <BottomNavigationAction
        onClick={handleOpenMenu}
        style={{
          minWidth: isSmall ? '65px' : 'inherit',
          borderLeft: '1px solid lightgrey',
          borderRight: isSmall ? '1px solid lightgray' : '1px solid lightgrey',
        }}
        showLabel
        label="Menu"
        value={null}
        icon={<SecondaryActionIcon />}
      />
    </BottomNavigation>
  );
}
