import React, { useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import Check from '@mui/icons-material/Check';
import BulkConfigStep from '../ui/bulk-config-step';
import { Building, UnitFilters } from '../types';

export interface SelectBuildingsProps {
  buildings: Building[];
  unitFilters: UnitFilters;
  error?: string;
  onSelectBuilding: (buildingId: string, checked: boolean) => void;
  toggleBuildings: (buildingIds: string[]) => void;
  onBack: () => void;
  onNext: () => void;
}

// Step for selecting specific buildings to configure
export default function SelectBuildings(props: SelectBuildingsProps) {
  const {
    buildings,
    unitFilters,
    error,
    onSelectBuilding,
    toggleBuildings,
    onBack,
    onNext,
  } = props;

  function toggleAll() {
    toggleBuildings(buildings.map((b) => b?._id || ''));
  }

  // Select all buildings by default
  useEffect(() => {
    if (unitFilters.selectedBuildings.length === 0) {
      toggleAll();
    }
    // eslint-disable-next-line
  }, []);

  const topActions = [
    { name: 'Toggle All', action: toggleAll, startIcon: <Check /> },
  ];

  const bottomActions = [
    { name: 'Back', action: onBack, startIcon: <ArrowLeft /> },
    { name: 'Next', action: onNext, endIcon: <ArrowRight /> },
  ];

  return (
    <BulkConfigStep
      title="Select Buildings"
      topActions={topActions}
      bottomActions={bottomActions}
      error={error}
    >
      <FormControl>
        <FormGroup>
          {buildings.map((b) => {
            return (
              <FormControlLabel
                key={b?._id}
                value={b?._id}
                control={
                  <Checkbox
                    checked={unitFilters.selectedBuildings.includes(
                      b?._id || '',
                    )}
                    onChange={(_, v) => onSelectBuilding(b?._id || '', v)}
                    name={b?.name}
                    color="secondary"
                  />
                }
                label={b?.name}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </BulkConfigStep>
  );
}
