import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { Maybe } from '../../../types/generated-types';
import { Log } from './logger';

const defaultOptions: ToastOptions = {
  autoClose: 4000,
};

const toastLines = (message: string) => {
  const messageParts = message.split('<br />');
  if (messageParts.length === 1) {
    return <span>{message}</span>;
  } else {
    return (
      <div style={{ height: '100%' }}>
        {messageParts.map((part, index) => {
          return (
            <div key={index}>
              <span>{part}</span>
              <br />
            </div>
          );
        })}
      </div>
    );
  }
};

/**
 * This class wraps the idea of a client-facing notification service.
 * Currently, uses 'react-toastify' under the covers.
 */
class NotificationManager {
  private notifier: typeof toast;
  private lastMessage: string | undefined;

  constructor() {
    this.notifier = toast;
  }

  private debounce(message: string) {
    this.lastMessage = message;
    setTimeout(() => {
      this.lastMessage = undefined;
    }, 1000);
  }

  public error(
    message: string | Maybe<string> | undefined,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    data?: any,
    options?: ToastOptions,
    log = true,
  ) {
    if (message && this.lastMessage !== message) {
      this.debounce(message);
      if (log) {
        Log.error(message, data);
      }
      this.notifier.error(toastLines(message), {
        ...defaultOptions,
        ...(options || {}),
      });
    }
  }

  public warn(
    message: string | Maybe<string> | undefined,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    data?: any,
    options?: ToastOptions,
    log?: boolean,
  ) {
    if (message && this.lastMessage !== message) {
      this.debounce(message);
      if (log) {
        Log.warn(message, data);
      }
      this.notifier.warn(toastLines(message), {
        ...defaultOptions,
        ...(options || {}),
      });
    }
  }

  public success(
    message: string | Maybe<string> | undefined,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    data?: any,
    options?: ToastOptions,
    log?: boolean,
  ) {
    if (message && this.lastMessage !== message) {
      this.debounce(message);
      if (log) {
        Log.info(message, data);
      }
      this.notifier.success(toastLines(message), {
        ...defaultOptions,
        ...(options || {}),
      });
    }
  }

  public info(
    message: string | Maybe<string> | undefined,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    data?: any,
    options?: ToastOptions,
    log?: boolean,
  ) {
    if (message && this.lastMessage !== message) {
      this.debounce(message);
      if (log) {
        Log.info(message, data);
      }
      this.notifier.info(toastLines(message), {
        ...defaultOptions,
        ...(options || {}),
      });
    }
  }
}

export const Notifier = new NotificationManager();
