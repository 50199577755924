import React from 'react';
import Card from '@mui/material/Card';
import {
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { AlertsIndicator } from '../../alerts/alerts-indicator';
import { useNavigate } from 'react-router-dom';
import { PropertiesQuery } from '../../../../types/generated-types';
import { useTheme } from '@mui/material/styles';

type Property = PropertiesQuery['properties'][number];

export interface PropertyCardProps {
  property: Property;
  detailButton?: boolean;
  showUnits?: boolean;
}

export function PropertyCard(props: PropertyCardProps) {
  const { property } = props;
  const { title, _id, unitsSummary, address, units, imageUrl } = property || {};
  const { line1, city, state, postalCode } = address;
  const cityText = city ? city + ', ' : ' ';
  const unitLength = units.length;
  const navigate = useNavigate();
  const defaultPropImgUrl = '/defaultPropImage.jpg'; // '../../../assets/property-f.jpeg';

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return property ? (
    <Card
      sx={{
        height: isSmall ? 'inherit' : '224px',
        boxShadow: 3,
        maxWidth: '450px',
        width: isSmall ? '350px' : '400px',
        // TODO: Calvin (consult with Robert) We need to have a better strategy for showing which properties have alerts.
        // border: unitsSummary?.majorAlerts || 0 ? '1px solid #FF2222' : '',
      }}
    >
      <CardActionArea
        onClick={() => {
          navigate(`/properties/${_id}`);
        }}
        aria-label="property-detail"
      >
        <CardContent>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Grid container>
              <Grid item xs={6}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 1 65%',
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{ textOverflow: 'ellipsis' }}
                    noWrap
                  >
                    {title}
                  </Typography>
                  <Typography
                    color={'rgba(0, 0, 0, 0.65)'}
                    sx={{ textOverflow: 'ellipsis', fontSize: 14 }}
                  >
                    {line1}
                  </Typography>
                  <Typography
                    color={'rgba(0, 0, 0, 0.65)'}
                    sx={{ textOverflow: 'ellipsis', fontSize: 14 }}
                  >
                    {cityText + (state || ' ')}
                  </Typography>
                  <Typography
                    color={'rgba(0, 0, 0, 0.65)'}
                    sx={{ textOverflow: 'ellipsis', fontSize: 14 }}
                  >
                    {postalCode || ' '}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 1 35%',
                    marginLeft: '10px',
                  }}
                >
                  <img
                    alt="Property"
                    src={`${imageUrl || defaultPropImgUrl}`}
                    width="100%"
                    style={{
                      objectFit: 'cover',
                      objectPosition: '100% 0',
                      maxHeight: '135px',
                    }}
                    //srcSet={`${imageUrl}?w=30&fit=crop&auto=format&dpr=2 2x`}
                    loading="lazy"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 65%',
              }}
            >
              <Typography
                color={'rgba(0, 0, 0, 0.47)'}
                sx={{
                  textOverflow: 'ellipsis',
                  fontSize: 16,
                  fontStyle: 'italic',
                }}
              >
                {property.owner?.name}
              </Typography>
              <Typography
                color={'rgba(0, 0, 0, 0.47)'}
                sx={{
                  textOverflow: 'ellipsis',
                  fontSize: 16,
                  fontStyle: 'italic',
                }}
              >
                {property.propMgr?.name}
              </Typography>
              <Typography
                color={'rgba(0, 0, 0, 0.47)'}
                sx={{
                  textOverflow: 'ellipsis',
                  fontSize: 16,
                  fontStyle: 'italic',
                }}
              >
                {property.propMgr?.mobile}
              </Typography>
              <Typography
                color={'rgba(0, 0, 0, 0.47)'}
                sx={{ textOverflow: 'ellipsis', fontSize: 14 }}
              >
                {'Units: ' + unitLength}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 35%',
              }}
            >
              <AlertsIndicator unitsSummary={unitsSummary} />
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  ) : null;
}
