import React from 'react';

import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogContent, useMediaQuery } from '@mui/material';

export interface AlertOptOutConfirmationDialogProps {
  open: boolean;
  message: string;
  handleConfirm: () => void;
  handleCancel: () => void;
}

export function AlertOptOutConfirmationDialog(
  props: AlertOptOutConfirmationDialogProps,
) {
  const { open, message, handleConfirm, handleCancel } = props;

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return open ? (
    <Dialog
      open={open}
      aria-labelledby="alert-opt-out-confirmation-title"
      aria-describedby="alert-opt-out-confirmation-description"
    >
      <div
        id="alert-opt-out-confirmation-title"
        style={{ marginLeft: '22px', marginTop: '16px' }}
      >
        <span
          style={{
            fontSize: '18px',
            fontWeight: 'bolder',
          }}
        >
          Confirm Alert Opt-Out
        </span>
      </div>
      <DialogContent
        sx={
          isSmall
            ? { margin: '2px ', padding: '2px 2px' }
            : { marginTop: '0px' }
        }
      >
        <div>
          <div
            style={{
              margin: isSmall ? '0px 0px 0px 0px' : '-10px 8px 6px 8px',
              // width: isSmall ? 'unset' : 'calc(100vw - 80px)',
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              padding: '10px',
              border: '1px solid grey',
              borderRadius: '4px',
            }}
          >
            <div>{message}</div>

            <Button
              fullWidth
              style={
                isSmall
                  ? {
                      alignSelf: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: '#3be73b',
                      color: 'black',
                      flex: '1 1 auto',
                      marginTop: '12px',
                      marginBottom: '0px',
                      width: '100%',
                      border: '1px solid #159015',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                    }
                  : {
                      alignSelf: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: '#3be73b',
                      color: 'black',
                      border: '1px solid #159015',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
                      marginLeft: '8px',
                      maxWidth: '450px',
                      marginTop: '20px',
                    }
              }
              variant="contained"
              color="primary"
              onClick={handleConfirm}
            >
              {isSmall ? 'Continue' : 'Delete and Continue'}
            </Button>
            <Button
              fullWidth
              style={
                isSmall
                  ? {
                      alignSelf: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      flex: '1 1 auto',
                      marginTop: '12px',
                      marginBottom: '0px',
                      width: '100%',
                      border: '1px solid darkgrey',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)',
                    }
                  : {
                      alignSelf: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: '8px',
                      maxWidth: '450px',
                      marginTop: '20px',
                      border: '1px solid darkgrey',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)',
                    }
              }
              variant="contained"
              color="primary"
              onClick={handleCancel}
            >
              Cancel and Close
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  ) : null;
}
