import React, { useState } from 'react';

import {
  IAuthenticationResult,
  useAuthenticator,
} from '../AuthenticationContext';

import { Notifier } from '../../system/services/notificationManager';

import { EmbueError } from '../../system/models/embueError';
import { IAuthState } from '../../system/SessionStateManager';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  Fade,
  FormControlLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from '@mui/material';
import { ColoredLinearProgress } from '../../features/shared/linear-progress';
import { AuthenticationProfileFactors } from '../../../types/generated-types';
import { useTheme } from '@mui/material/styles';
import BorderedSection from '../../features/shared/borderedSection';

/**
 * Represents the 'second step' in an MFA workflow. Collects the user's password
 * and submits it to the server. Returns the new factor requested by the server
 * for the next step in the MFA process via the provided callback ... OR ...,
 * if the server indicates that it is satisfied by populating the 'user' field
 * of the result, the authenticator will automatically store the returned user
 * object in local storage and sign the user in via the auth context which
 * triggers a re-rendering of the app which will activate the normal,
 * authenticated user home component.
 * @param alternateFactors
 * @param onAuthResult
 * @param authState
 * @constructor
 */
export const AlternateFactors = ({
  alternateFactors,
  onAuthResult,
}: // authState,
{
  alternateFactors: AuthenticationProfileFactors[];
  onAuthResult: (res: IAuthenticationResult | void) => void;
  authState: IAuthState | undefined;
}) => {
  // TODO: Peter: Should we do something with the current auth state? Perhaps pre-select an alternative factor? Exclude any?
  const auth = useAuthenticator();

  const [selectedFactorId, setSelectedFactorId] = useState<string>('0');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const selectFactor = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    if (selectedFactorId) {
      setSubmitting(true);
      auth
        .authenticate({ value: selectedFactorId }, true)
        .then((authResult) => {
          onAuthResult(authResult);
        })
        .catch((authError: EmbueError) => {
          Notifier.error(`[Validate Password] Failed: ${authError.message}.`);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      Notifier.error(
        '[Select Alternate Factor] Unable to select alternate factor. No factor selected.',
      );
    }
  };

  const ActivityIndicator = (props: { activityInProgress: boolean }) => {
    return props.activityInProgress ? (
      <ColoredLinearProgress />
    ) : (
      <Box style={{ height: '22px' }}>&nbsp;</Box>
    );
  };

  return (
    <Fade
      in={true}
      style={{
        transitionDuration: '1100ms',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'stretch',
          margin: isSmall ? '-10px 8px 0px 8px' : '8px 8px 4px 8px',
        }}
      >
        <ActivityIndicator activityInProgress={submitting} />
        {alternateFactors.length > 0 ? (
          <BorderedSection title="Available Authentication Methods">
            <RadioGroup
              style={{ marginBottom: '-16px' }}
              aria-label="passkey type"
              name="passkeyType"
              value={selectedFactorId}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSelectedFactorId(event.target.value);
              }}
            >
              {alternateFactors.map((altFactor, index) => (
                <FormControlLabel
                  style={{
                    marginBottom: '0px',
                    marginTop: isSmall ? '-12px' : '0px',
                  }}
                  // classes={{ root: 'margin-top: -12px' }}
                  key={`passkey-type-rb-${index}`}
                  value={altFactor._id}
                  control={<Radio />}
                  label={altFactor.name}
                />
              ))}
            </RadioGroup>
          </BorderedSection>
        ) : (
          <div style={{ width: '100%', textAlign: 'left' }}>
            No Authentication Methods found.
            <br />
            Contact your administrator, restart the application or refresh to
            try again.
          </div>
        )}
        {selectedFactorId?.length ? (
          <div
            style={{
              display: 'block',
              paddingTop: '6px',
              marginTop: '6px',
              marginBottom: '10px',
              marginRight: '2px',
              textAlign: 'center',
            }}
          >
            <Button
              onClick={selectFactor}
              disabled={
                !selectedFactorId || selectedFactorId === '0' || submitting
              }
              fullWidth
              variant="contained"
              sx={isSmall ? { mt: 0, mb: -6 } : { mt: 3, mb: 2 }}
            >
              Use selected factor
            </Button>
          </div>
        ) : alternateFactors.length ? (
          <div
            style={{
              display: 'block',
              paddingTop: '6px',
              marginTop: '6px',
              marginBottom: '10px',
              marginRight: '2px',
              textAlign: isSmall ? 'center' : 'left',
            }}
          >
            {isSmall
              ? 'Choose an Authentication Method'
              : 'Select an Authentication Method to continue.'}
          </div>
        ) : null}
        {/* TODO: Peter: Add link to help for passkeys */}
        {/*<div*/}
        {/*  style={{*/}
        {/*    width: isSmall ? 'unset' : '350px',*/}
        {/*    display: isSmall ? 'flex' : 'unset',*/}
        {/*    flexDirection: isSmall ? 'row' : 'unset',*/}
        {/*    flex: isSmall ? '0 1 auto' : 'unset',*/}
        {/*    cursor: 'pointer',*/}
        {/*    backgroundColor: 'lightseagreen',*/}
        {/*    borderRadius: '4px',*/}
        {/*    padding: '4px 10px',*/}
        {/*    marginTop: '16px',*/}
        {/*    marginBottom: '6px',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      marginRight: '3px',*/}
        {/*      width: '100%',*/}
        {/*      textAlign: 'center',*/}
        {/*      color: '#FFFFFF',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    New to Passkeys? Click here for details*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </Fade>
  );
};
