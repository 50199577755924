import React from 'react';
import Typography from '@mui/material/Typography';
import { InstallationStatus } from '../types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

interface StatusMessageProps {
  status?: InstallationStatus;
  message?: string | null;
  plain?: boolean; ///* Return a plain one-line component instead of the full message box */
}

export function StatusMessage(props: StatusMessageProps) {
  const { status, message, plain } = props;
  let title: string;
  switch (status) {
    case InstallationStatus.READY_TO_INSTALL:
      title = `This device is ready for installation.`;
      break;
    case InstallationStatus.SCAN_IN_PROGRESS:
      title = `Scanning QR code ...`;
      break;
    case InstallationStatus.SCAN_SUCCESSFUL:
      title = `QR code scan successful.  Press 'JOIN DEVICE' to proceed with the installation`;
      break;
    case InstallationStatus.NETWORK_OPEN_REQUESTED:
      title = `Requesting network connection ...`;
      break;
    case InstallationStatus.NETWORK_OPEN:
      title = `The network is open. Press the 'JOIN' button on the device.`;
      break;
    case InstallationStatus.NETWORK_OPEN_FAILED:
      title = `The network failed to open.  Make sure a working Source is installed.`;
      break;
    case InstallationStatus.INSTALLATION_SUCCESSFUL:
      title = `This device has been installed successfully.`;
      break;
    case InstallationStatus.INSTALLATION_FAILED:
      title = `The device has failed to join the network.`;
      break;
    case InstallationStatus.CONFIGURATION_IN_PROGRESS:
      title = `The device has successfully joined the network.  Please stand by while final configuration is completed.`;
      break;
    case InstallationStatus.CONFIGURATION_FAILED:
      title = `Device configuration failed.  Please try again.`;
      break;
    case InstallationStatus.READY_TO_REPLACE:
      title = `To proceed with device replacement, either scan the QR code or enter the device EUID`;
      break;
    default:
      title = `Error - Unknown Installation Status`;
  }
  return plain ? (
    <Typography variant={'caption'}>
      Status: {(message || title) ?? '?'}
      {` (${status})`}
    </Typography>
  ) : (
    <Box
      sx={{
        padding: 3,
        margin: 3,
        border: '1px solid',
        borderRadius: '1rem',
        borderColor: 'darkgreen',
        background: '#fff',
      }}
    >
      {title ? (
        <Typography align={'center'} variant={'h3'}>
          {title}
        </Typography>
      ) : (
        <></>
      )}
      {message ? (
        <>
          <Divider sx={{ borderBottom: 0, marginBottom: 3 }} />
          <Typography align={'center'} variant={'h4'}>
            {message}
          </Typography>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
}
