import * as React from 'react';
/* MUI */
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TableContainer,
  Fade,
} from '@mui/material';
/* HELPERS */
import dayjs from 'dayjs';
import { convertToTempUnits } from '../../../util/convert-temp-units';
/* COMPONENTS */
import { DisplayValue } from '../../../shared-ui';
/* TYPES */
import {
  Fahrenheit,
  TemperatureUnit,
} from '../../../../../system/models/temperatureUnits';
import { useAuthenticator } from '../../../../../auth/AuthenticationContext';
import { eventLabels } from '../../types';

interface ThermostatScheduleProps {
  dailySchedule: any;
}

export function ThermostatScheduleTable(props: ThermostatScheduleProps) {
  const { user } = useAuthenticator();
  const { dailySchedule } = props;
  const preferredUnits: TemperatureUnit = user?.preferredUnits ?? Fahrenheit;
  const entries = Object.entries(dailySchedule);
  const thermostatScheduleRows: any[] = entries.map(
    ([timeOfDay, scheduleSetting]) => {
      const time = dayjs()
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(parseInt(timeOfDay))
        .format('LT');
      return {
        time,
        setting: scheduleSetting,
      };
    },
  );

  return (
    <TableContainer>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Event</TableCell>
            <TableCell>Time</TableCell>
            <TableCell align="center">Heat to</TableCell>
            <TableCell align="center">Cool to</TableCell>
          </TableRow>
        </TableHead>
        <Fade in={thermostatScheduleRows.length > 0} timeout={1200} appear>
          <TableBody>
            {thermostatScheduleRows.map((row, index) => (
              <TableRow
                key={`${index}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={{ width: '20px' }}>
                  <Typography noWrap>{eventLabels[index]}</Typography>
                </TableCell>
                <TableCell component="th" scope="row" sx={{ width: '20px' }}>
                  <Typography noWrap>{row.time}</Typography>
                </TableCell>
                <TableCell align="center" sx={{ width: '20px' }}>
                  <DisplayValue
                    valueSize="20px"
                    value={convertToTempUnits(
                      row.setting.minimum,
                      preferredUnits,
                    )}
                    mode="Heat"
                    units={preferredUnits}
                  />
                </TableCell>
                <TableCell align="center" sx={{ width: '20px' }}>
                  <DisplayValue
                    valueSize="20px"
                    value={convertToTempUnits(
                      row.setting.maximum,
                      preferredUnits,
                    )}
                    mode="Cool"
                    units={preferredUnits}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Fade>
      </Table>
      {thermostatScheduleRows.length === 0 ? (
        <div>
          <Fade in={thermostatScheduleRows.length === 0} timeout={1200}>
            <div style={{ padding: '30px' }}>
              <Typography sx={{ textAlign: 'center', width: '100%' }}>
                Cleared Schedule
              </Typography>
            </div>
          </Fade>
        </div>
      ) : (
        <></>
      )}
    </TableContainer>
  );
}
