/**
 * ViewSelector
 * - UI component used by Property component
 * - Renders drop-down view selector on small screens and tab selector on larger screens
 * - Used to select property-detail view.
 */
import React from 'react';
import { ListItemText, useMediaQuery } from '@mui/material';

import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import {
  EnabledUnitTypes,
  UnitType,
  UnitTypeName,
  unitTypes,
} from '../types/unitListTypes';
import { useTheme } from '@mui/material/styles';

interface BottomDetailSelectorProps {
  selectedUnitType: UnitType;
  enabledUnitTypes: EnabledUnitTypes;
  changeHandler: (event?: any, newValue?: any) => void;
}

export function UnitFilterSelector(props: BottomDetailSelectorProps) {
  const { changeHandler, enabledUnitTypes, selectedUnitType } = props;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  /* convert the 'enabledUnitTypes' object into an array of 'UnitTypes' that are enabled */
  const viewOptions: UnitType[] = Object.entries(enabledUnitTypes).reduce(
    (result: UnitType[], [_unitTypeName, isEnabled]) => {
      const unitTypeName = _unitTypeName as UnitTypeName; // TS insists upon itself
      return isEnabled ? result.concat(unitTypes[unitTypeName]) : result;
    },
    [],
  );

  const selectHandler = (event: SelectChangeEvent<UnitTypeName>) => {
    const newVal = event.target.value;
    changeHandler(newVal);
  };

  return viewOptions.length ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
      }}
    >
      <FormControl
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
        }}
        variant="standard"
        sx={{
          my: '.4rem',
          padding: '0px 0px 0px 0px',
        }}
      >
        {viewOptions.length < 2 ? (
          <div
            style={{
              width: '100%',
              marginTop: '4px',
              textAlign: 'center',
              color: 'darkgray',
              padding: isSmall ? '0px 0px' : '0px 4px',
            }}
          >
            {viewOptions.length === 0 ? (
              'No Units Found'
            ) : isSmall ? (
              <>
                <div style={{ marginTop: '-2px' }}>Found only</div>
                <div>{`${viewOptions[0].shortLabel} units`}</div>
              </>
            ) : (
              `Found only ${viewOptions[0].label} units`
            )}
          </div>
        ) : (
          <>
            <Select
              labelId="unit-type-filter-select-dropdown"
              id="unit-type-filter-select-dropdown"
              label="Select Unit Type"
              onChange={selectHandler}
              value={selectedUnitType.key}
              style={{
                margin: '-4px 10px 0px 10px',
              }}
              disableUnderline
            >
              {viewOptions.map((option, index) => (
                <MenuItem key={index} value={option.key}>
                  <ListItemText<'div', 'div'>
                    sx={{ textAlign: 'center', width: '100%' }}
                  >
                    {isSmall ? option.shortLabel : option.label}
                  </ListItemText>
                </MenuItem>
              ))}
            </Select>
            <div
              style={{
                fontSize: '13px',
                color: 'rgb(0 186 174)',
                textAlign: 'center',
                width: '100%',
                marginTop: '-8px',
              }}
            >
              Listing units of type
            </div>
          </>
        )}
      </FormControl>
    </div>
  ) : null;
}
