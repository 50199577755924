import React, { useEffect, useState } from 'react';
import {
  useZ210PumpDetailQuery,
  useZ210PumpDetailUpdateSubscription,
  Z210Pump,
} from '../../../../types/generated-types';
import BorderedSection from '../../shared/borderedSection';
import { DisplayValue } from '../shared-ui';
import DeviceLoadingSkeleton from '../shared-ui/deviceLoadingSkeleton';
import { updateCacheFromSubscriptionEvent } from '../../../../helpers/subscriptionUtils';
import { Typography } from '@mui/material';

export function Z210PumpComponent({ deviceId }: { deviceId: string }) {
  const [device, setDevice] = useState<Partial<Z210Pump>>();
  const {
    data,
    // TODO: Loading_error: should we handle loading/errors for this query?
    // loading,
    // error,
  } = useZ210PumpDetailQuery({
    variables: { id: deviceId },
  });

  useZ210PumpDetailUpdateSubscription({
    variables: { ids: [deviceId] },
    fetchPolicy: 'no-cache',
    onData: updateCacheFromSubscriptionEvent,
  });

  useEffect(() => {
    if (data?.z210PumpById) {
      setDevice(data.z210PumpById as Partial<Z210Pump>);
    }
  }, [data]);

  const deviceName = device?.name ?? device?.meta?.name;
  const deviceLabel = deviceName ? ` (${deviceName})` : '';

  return !device ? (
    <DeviceLoadingSkeleton sx={{ mt: '2px', mb: '2px' }} />
  ) : device.isConfigured ? (
    <div style={{ width: '100%' }}>
      <BorderedSection
        title={`${deviceLabel}`}
        // title={`${deviceLabel} Pump Status: ${device.stopOrStart?.value === 1 ? 'Active' : 'Standby'}`}
        labelStyle={{ fontSize: '10px' }}
        style={{ marginTop: '16px', marginBottom: '-6px' }}
        ccStyle={{
          margin: '0px',
          padding: '4px 6px',
          display: 'flex',
          width: '100%',
          justifyContent: 'space-evenly',
          flexDirection: 'column',
        }}
      >
        {device.hasTargetSpeed && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {device.hasTargetSpeed && (
              <BorderedSection
                title="Target"
                labelStyle={{ fontSize: '10px' }}
                ccStyle={{ padding: '6px' }}
                style={{
                  padding: '0px',
                  height: '45px',
                  width: 'auto',
                  marginRight: '5px',
                }}
              >
                <DisplayValue
                  value={device.targetSpeed?.value}
                  units={'%'}
                  valueSize={'16px'}
                  unitsSize={'16px'}
                />
              </BorderedSection>
            )}
            {device.hasCurrentSpeed && (
              <BorderedSection
                title="Speed"
                labelStyle={{ fontSize: '10px' }}
                ccStyle={{ padding: '6px' }}
                style={{
                  padding: '0px',
                  height: '45px',
                  width: 'auto',
                  marginRight: '5px',
                }}
              >
                <DisplayValue
                  value={device.currentSpeed?.value}
                  units={'%'}
                  valueSize={'16px'}
                  unitsSize={'16px'}
                />
              </BorderedSection>
            )}
            {device.hasCurrentSpeed && (
              <BorderedSection
                title="Setting"
                labelStyle={{ fontSize: '10px' }}
                ccStyle={{ padding: '6px' }}
                style={{
                  padding: '0px',
                  height: '45px',
                  width: 'auto',
                  marginRight: '5px',
                }}
              >
                <Typography style={{ fontSize: '16px' }}>
                  {/*{device.override?.value ? 'Manual' : 'Automatic'}*/}
                  Automatic
                </Typography>
              </BorderedSection>
            )}
            {!device.hasAutoSwitchoverPeriod && device.hasActivePump && (
              <BorderedSection
                title="Active Pump"
                compact={true}
                labelStyle={{ fontSize: '10px' }}
                ccStyle={{ padding: '6px' }}
                style={{
                  padding: '0px',
                  height: '45px',
                  width: 'auto',
                  marginLeft: '5px',
                }}
              >
                <DisplayValue
                  value={device.activePump?.value}
                  units={''}
                  valueSize={'16px'}
                />
              </BorderedSection>
            )}
          </div>
        )}
      </BorderedSection>
    </div>
  ) : null;
}
