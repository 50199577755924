import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { MRT_Row } from 'material-react-table';

import BorderedSection from '../../shared/borderedSection';
import { PropertyAlertTriggerCardView } from '../property-alert-trigger-card-view';

import {
  EditableAlertTriggerSelectionCriteria,
  RowData,
} from '../types/propertyAlertTriggerRowData';
import Checkbox from '@mui/material/Checkbox';

export interface PropertyAlertTriggerUpdateDialogProps {
  alertTriggerDialogOpen: boolean;
  handleSave: (updatedTrigger: RowData) => Promise<void>;
  handleCancel: () => void;
  selectedAlertTriggerRow: MRT_Row<RowData> | null;
}

export const PropertyAlertTriggerUpdateDialog = ({
  alertTriggerDialogOpen,
  handleSave,
  handleCancel,
  selectedAlertTriggerRow,
}: PropertyAlertTriggerUpdateDialogProps) => {
  const [currentEditableCriterion, setCurrentEditableCriterion] =
    useState<EditableAlertTriggerSelectionCriteria | null>(null);
  const [originalEditableCriterion, setOriginalEditableCriterion] =
    useState<EditableAlertTriggerSelectionCriteria | null>(null);

  const [currentEnabledStatus, setCurrentEnabledStatus] =
    useState<boolean>(false);
  const [originalEnabledStatus, setOriginalEnabledStatus] =
    useState<boolean>(false);

  const [hasChanges, setHasChanges] = useState(false);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (selectedAlertTriggerRow) {
      setCurrentEnabledStatus(selectedAlertTriggerRow.original?.enabled);
      setOriginalEnabledStatus(selectedAlertTriggerRow.original?.enabled);

      const criterionToEdit =
        (selectedAlertTriggerRow?.original?.rawCriteria?.find(
          (criterion) => criterion?.editable,
        ) as EditableAlertTriggerSelectionCriteria) ?? null;
      if (criterionToEdit) {
        setOriginalEditableCriterion(criterionToEdit);
        setCurrentEditableCriterion({
          ...criterionToEdit,
        } as EditableAlertTriggerSelectionCriteria);
      } else {
        setOriginalEditableCriterion(null);
        setCurrentEditableCriterion(null);
      }
      setHasChanges(false);
    }
  }, [selectedAlertTriggerRow]);

  return alertTriggerDialogOpen && selectedAlertTriggerRow ? (
    <Dialog
      open={alertTriggerDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div
        id="alert-dialog-title"
        style={{ textAlign: 'center', marginTop: '6px', marginBottom: '6px' }}
      >
        <span
          style={{
            fontSize: '18px',
            fontWeight: 'bolder',
          }}
        >
          Edit Alert Trigger Value
        </span>
      </div>
      <DialogContent
        sx={
          isSmall
            ? { margin: '-2px 2px 2px', padding: '2px 2px' }
            : { marginTop: '-10px' }
        }
      >
        <div style={{ padding: '6px' }}>
          <BorderedSection
            title="Current Alert Trigger State"
            ccStyle={{ padding: '6px 6px 8px', marginTop: '-6px' }}
          >
            <PropertyAlertTriggerCardView row={selectedAlertTriggerRow} />
          </BorderedSection>
          <BorderedSection
            title="Edit Alert Trigger Value"
            style={{ marginTop: '12px' }}
          >
            <div>
              {currentEditableCriterion ? (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField
                    fullWidth
                    autoFocus
                    required
                    id="value"
                    name="value"
                    variant="standard"
                    label="Comparison Value"
                    value={currentEditableCriterion.editableValue}
                    onChange={(e) => {
                      setHasChanges(
                        e.target.value !==
                          `${originalEditableCriterion?.editableValue}` ||
                          currentEnabledStatus !== originalEnabledStatus,
                      );
                      setCurrentEditableCriterion({
                        ...currentEditableCriterion,
                        editableValue: e.target.value,
                      });
                    }}
                  />
                  <div style={{ marginTop: '24px' }}>
                    {currentEditableCriterion?.valueDisplayUnits}
                  </div>
                </div>
              ) : (
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bolder',
                  }}
                >
                  No editable criteria
                </Typography>
              )}
            </div>
          </BorderedSection>
          <BorderedSection title={'Enable/Disable Alert Trigger'}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentEnabledStatus}
                    onChange={(e) => {
                      const newStatus = e.target.checked;
                      setHasChanges(
                        originalEditableCriterion?.editableValue !==
                          currentEditableCriterion?.editableValue ||
                          newStatus !== originalEnabledStatus,
                      );
                      setCurrentEnabledStatus(newStatus);
                    }}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label="Enable Alert Trigger"
              />
            </div>
          </BorderedSection>
          <div
            style={{
              margin: isSmall ? '8px 0px 0px 0px' : '10px 0px 6px 0px',
              // width: isSmall ? 'unset' : 'calc(100vw - 80px)',
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              padding: '10px',
              border: '1px solid grey',
              borderRadius: '4px',
            }}
          >
            <Button
              fullWidth
              style={
                isSmall
                  ? {
                      alignSelf: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      flex: '1 1 auto',
                      marginTop: '0px',
                      marginBottom: '0px',
                      width: '90%',
                      border: '1px solid darkgrey',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)',
                    }
                  : {
                      alignSelf: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      // marginLeft: '8px',
                      maxWidth: '90%',
                      // marginTop: '10px',
                      border: '1px solid darkgrey',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)',
                    }
              }
              disabled={!hasChanges}
              variant="contained"
              color="secondary"
              onClick={async () => {
                const updatedData = { ...selectedAlertTriggerRow.original };
                updatedData.enabled = currentEnabledStatus;
                updatedData.rawCriteria = updatedData.rawCriteria.map(
                  (criterion) => {
                    return criterion?.editable
                      ? currentEditableCriterion
                      : criterion;
                  },
                );
                return await handleSave(updatedData);
              }}
            >
              Update
            </Button>
            <Button
              fullWidth
              style={
                isSmall
                  ? {
                      alignSelf: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      flex: '1 1 auto',
                      marginTop: '12px',
                      marginBottom: '0px',
                      width: '90%',
                      border: '1px solid darkgrey',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)',
                    }
                  : {
                      alignSelf: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      // marginLeft: '8px',
                      maxWidth: '90%',
                      marginTop: '20px',
                      border: '1px solid darkgrey',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)',
                    }
              }
              variant="contained"
              color="primary"
              onClick={handleCancel}
            >
              Close
            </Button>
            {/* TODO: Peter: in future, we will want to enable advanced editing of triggers allowing them to change
                 the name, description, targeted entity types and selection criteria */}
            {/*<Button*/}
            {/*  onClick={() => {*/}
            {/*    navigate(`./alert-triggers/${triggerId}/edit-alert-trigger`);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Advanced Editing*/}
            {/*</Button>*/}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  ) : null;
};
