import * as React from 'react';
import {
  Property,
  usePropertyAlertsQuery,
} from '../../../../types/generated-types';
import { AlertsList } from '../../alerts/alerts-list';
import { MyProperty } from '../property-detail';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import {
  BottomMenuItems,
  useInjectableComponents,
} from '../../../system/services/injectableComponentsManager';
import { basePropertyMenuItems } from '../ui/base-property-context-menu';
import { useLocation } from 'react-router-dom';

interface PropertyAlertsProps {
  data?: MyProperty;
}

export function PropertyAlerts(props: PropertyAlertsProps) {
  const propertyData = props.data;
  const { data, loading, error } = usePropertyAlertsQuery({
    variables: { propertyId: propertyData?._id || '' },
  });
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    setContextMenuItems,
    setSubtitle,
    setTitle,
    setTitlePath,
    setSubtitlePath,
  } = useInjectableComponents();
  const { pathname } = useLocation();

  useEffect(() => {
    if (propertyData?._id) {
      const contextMenuItems: BottomMenuItems = basePropertyMenuItems(
        propertyData._id,
        pathname,
      );

      setTitle(propertyData.title ?? 'Unnamed Property');
      setTitlePath(`/properties/${propertyData?._id}/summary`);

      setSubtitle('Property Alerts');
      setSubtitlePath(`/properties/${propertyData._id}/units`);

      setContextMenuItems(contextMenuItems, propertyData as Property);
    }
    return () => {
      setTitle(undefined);
      setTitlePath(undefined);
      setSubtitle(undefined);
      setSubtitlePath(undefined);
      setContextMenuItems(undefined);
    };
  }, [propertyData, pathname]);

  return (
    <div
      style={{
        margin: isSmall ? '0px' : '10px 4px',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      {error ? (
        <div style={{ flex: 1, textAlign: 'center' }}>
          Error loading alerts: {error.message}
        </div>
      ) : (
        <AlertsList alerts={data?.alerts ?? []} loading={loading} />
      )}
    </div>
  );
}
