/**
 * TimePicker
 * - modified MobileTimePicker
 * - renders selected time to a ListItem component
 * - uses Dayjs to manage date and time objects
 */
import React from 'react';

/* Time Picker Dependencies */
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker as MuiTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

// Note: Editing of time with DesktopTimePicker (rendered by TimePicker on web) wasn't functioning as expected,
// I think because of differences in how onChange/onAccept are called when compared to MobileTimePicker.
// For now, we'll just render MobileTimePicker on all clients, mobile and web. Noting this in case we ever
// want to revisit this. // import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker'; //

/* MUI */
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';

/* Icons */
import ClockIcon from '@mui/icons-material/AccessTime';

/* Types */
import { TimeOfDay } from '../../types';

interface TimePickerProps {
  time: TimeOfDay;
  handleEdit: (oldTime: TimeOfDay, newTime: TimeOfDay) => void;
}

export function TimePicker(props: TimePickerProps) {
  const { time, handleEdit } = props;
  const [value, setValue] = React.useState<Dayjs | null>(
    /* initialize to NOW and then remove all current day time values (h, m, s), and set
     *  the ms value to the timestamp from the time props
     **/
    dayjs().hour(0).minute(0).second(0).millisecond(parseInt(time)),
  );
  const [open, setOpen] = React.useState<boolean>(false);
  const oldValue: TimeOfDay = time;
  const returnNewValue = (acceptedValue: Dayjs) => {
    try {
      if (acceptedValue) {
        const newValue = String(
          (
            (acceptedValue.hour() * 60 + acceptedValue.minute()) *
            60000
          ).valueOf(),
        );

        handleEdit(oldValue, newValue);
      }
    } catch (e) {
      setValue(dayjs().hour(0).minute(0).second(0).millisecond(parseInt(time)));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/*<IconButton onClick={() => setOpen(true)} edge="start">*/}
      {/*  <Avatar sx={{ color: 'inherit', backgroundColor: 'transparent' }}>*/}
      {/*    <ClockIcon sx={{ fontSize: '2rem' }} />*/}
      {/*  </Avatar>*/}
      {/*</IconButton>*/}
      <MuiTimePicker
        value={value}
        open={open}
        minutesStep={15}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onAccept={(acceptedValue) => {
          if (acceptedValue) {
            returnNewValue(acceptedValue);
          }
        }}
      />
    </LocalizationProvider>
  );
}
