import * as React from 'react';

/* TYPES */
import {
  AuthenticatorDevice,
  AuthenticationProfileFactors,
} from '../../../types/generated-types';

/* SHARED COMPONENTS */
import DataTable from '../shared/data-table';

/* MUI */
import {
  GridRowParams,
  GridRenderCellParams,
  GridColDef,
} from '@mui/x-data-grid';
import { useMediaQuery, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/* ICONS */
// import DeleteIcon from '@mui/icons-material/Delete';
import { MobileListCard } from '../shared/mobile-list-card';

import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
// TODO: Peter: remove these?
//   import { useAuthenticator } from '../../auth/AuthenticationContext';
//   import { startAuthentication } from '@simple web authn/browser';
//   import { AuthenticationResponseJSON } from '@simple web authn/typescript-types';

import { useFormik } from 'formik';
import { FormValues } from './types/userProfileFormValues';

export type PasskeyFactor = Partial<
  AuthenticationProfileFactors &
    Pick<
      AuthenticatorDevice,
      | 'userVerified'
      | 'credentialDeviceType'
      | 'credentialBackedUp'
      | 'transports'
      | 'credentialIsExternal'
      | 'enforceUserVerification'
      | 'credentialEcosystem'
      | 'credentialOS'
      | 'lastUsed'
      | 'createdAt'
    >
>;

interface PasskeyListProps {
  formik: ReturnType<typeof useFormik<FormValues>>;
  data?: Partial<PasskeyFactor>[];
  onChange: StandardInputProps['onChange'];
  onSelect: (selectedId: string | undefined) => void;
}

function formatData(data: any[]) {
  const formattedData: any[] = [];
  data.forEach((row) => {
    let date = row?.lastUsed ? row.lastUsed : null;
    if (date) {
      date = new Date(row.lastUsed);

      // TODO: Peter: looks like this can be extracted and shared.
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? '0' + minutes : minutes;

      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }
      date =
        month +
        '/' +
        day +
        '/' +
        year +
        ' ' +
        hours +
        ':' +
        minutes +
        ' ' +
        ampm;
    } else {
      date = 'no date';
    }
    formattedData.push({
      id: row?._id,
      name: row?.name,
      preferred: row?.preferred ? 'Yes' : 'No',
      enforceUserVerification: row?.enforceUserVerification ? 'Yes' : 'No',
      lastUsed: date,
      credentialIsExternal: row?.credentialIsExternal ? 'Yes' : 'No',
      ecosystem: row?.credentialEcosystem?.toString() ?? 'None',
      boundTo: row?.credentialBoundTo?.toString() ?? 'None',
      credentialOS: row?.credentialOS?.toString() ?? 'None',
      credentialBoundTo:
        row?.credentialDeviceType === 'multi-device'
          ? 'Ecosystem'
          : row?.credentialBoundTo?.toString() ?? 'None',
      credentialEcosystem:
        (row?.credentialEcosystem?.toString() ?? 'NONE') === 'NONE'
          ? 'Device Only'
          : row?.credentialEcosystem?.toString(),
    });
  });
  return formattedData;
}

export function PasskeyList(props: PasskeyListProps) {
  const {
    data,
    onSelect,
    // TODO: Peter: left here as a reminder of what props are available. We should remove if not used.
    // onChange,
    // formik,
  } = props;
  const rows = data || [];
  const formattedData = formatData(rows);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const handleRowClick = async (params: GridRowParams) => {
    onSelect(params.row.id);
  };

  const nameColumn = isSmall
    ? ({
        field: 'name',
        headerName: 'Name',
        align: 'center',
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<PasskeyFactor & { lastUsed: string }>,
        ) => {
          return (
            <MobileListCard>
              <Typography
                sx={{ fontSize: 14, fontWeight: 800 }}
                color="text.secondary"
                gutterBottom
              >
                {params.row.name}
              </Typography>
              <Typography variant="h5" component="div">
                User Verification Enforced: {params.row.enforceUserVerification}
              </Typography>
              <Typography variant="h5" component="div">
                Preferred: {params.row.preferred}
              </Typography>
              <Typography sx={{ mb: 1.5, fontSize: 10 }} color="text.secondary">
                Last Used: {params.row.lastUsed}
              </Typography>
            </MobileListCard>
          );
        },
      } as GridColDef)
    : { field: 'name', headerName: 'Name', width: 220 };

  const columns = [
    { ...nameColumn },
    {
      field: 'enforceUserVerification',
      headerName: 'Verify ID?',
      width: 100,
    },
    { field: 'credentialIsExternal', headerName: 'External?', width: 100 },
    { field: 'preferred', headerName: 'Preferred?', width: 100 },
    { field: 'credentialEcosystem', headerName: 'Ecosystem', width: 100 },
    { field: 'credentialBoundTo', headerName: 'Available on', width: 100 },
    { field: 'lastUsed', headerName: 'Last Used', width: 180 },
  ];

  // TODO: Peter: is this code needed any longer?
  // const auth = useAuthenticator();
  //
  // const testPasskey = async () => {
  //   const authOptions = await auth.getPasskeyAuthenticationOptions();
  //   console.log('got options', authOptions);
  //   if (authOptions) {
  //     const authResponse: AuthenticationResponseJSON =
  //       await startAuthentication(authOptions);
  //     const res = await auth.validatePasskeyAuthentication(authResponse);
  //     console.log('got auth response', res);
  //   }
  // };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'stretch',
        cursor: 'pointer',
        '& .MuiDataGrid-columnHeaders': {
          display: isSmall ? 'none' : 'inherit',
        },
      }}
    >
      <DataTable
        rowHeight={isSmall ? 120 : 80}
        columnVisibilityModel={{
          name: true,
          enforceUserVerification: !isSmall,
          lastUsed: !isSmall,
          credentialIsExternal: !isSmall,
          credentialEcosystem: !isSmall,
          credentialBoundTo: !isSmall,
        }}
        hideFooter={true}
        hideFilterSorting={true}
        additionalProps={{
          onRowClick: (i: GridRowParams) => handleRowClick(i),
        }}
        rows={formattedData}
        columns={columns}
        // apiRef={apiRef}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'desc' }],
          },
        }}
        suppressAutoPageSize={true}
      />
    </Box>
  );
}
