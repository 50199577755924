import {
  Maybe,
  ZonePolicy,
  useUpdatePropertyZonePolicyOverrideMutation,
  useUpdateUnitZonePolicyOverrideMutation,
} from '../../../types/generated-types';
import React, { useEffect } from 'react';
import { Notifier } from '../../system/services/notificationManager';
import BorderedSection from './borderedSection';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Box, Button } from '@mui/material';
import { BulkUpdateStatus } from './bulk-update-status';
import { useNavigate } from 'react-router-dom';
import { ZonePolicyUpdateVerification } from '../zone-policies/types/zonePolicyDetailFormValues';
import { VerifyZonePolicyUpdate } from './verify-zone-policy-update';
import JSONInput from 'react-json-editor-ajrm';
import {
  locale,
  removeTypeNames,
} from '../zone-policies/components/zone-policy-detail-general-tab';

const defaultSystemZonePolicy: ZonePolicy['rules'] = [];
export const ZonePolicyOverrideSelection = ({
  returnLabel,
  returnPath,
  propertyId,
  unitId,
  targetPolicyId,
  availablePolicies = [],
  value,
  onZonePolicySelected,
}: {
  returnLabel: string;
  returnPath: string;
  propertyId?: string;
  unitId?: string;
  targetPolicyId: string;
  availablePolicies?: Maybe<ZonePolicy>[];
  value: string | undefined;
  onZonePolicySelected?: (id: string) => void;
}) => {
  // const {
  //   onZonePolicySelected,
  //   propertyId,
  //   returnLabel,
  //   returnPath,
  //   unitId,
  //   targetPolicyId,
  //   value,
  //   availablePolicies,
  // } = dataProps;
  const readOnly = !onZonePolicySelected;
  const forProperty = !!propertyId;
  const [verifying, setVerifying] = React.useState(false);
  const [propagating, setPropagating] = React.useState(false);
  const [propagationComplete, setPropagationComplete] = React.useState(false);
  const [forceUpdate, setForceUpdate] = React.useState(false);
  const [
    updateUnit,
    {
      data: unitData,
      error: unitError,
      // TODO: Loading_error: should we handle loading for this mutation?
      // loading: unitLoading,
    },
  ] = useUpdateUnitZonePolicyOverrideMutation();
  const [
    updateProperty,
    {
      data: propertyData,
      error: propertyError,
      // TODO: Loading_error: should we handle loading for this mutation?
      // loading: propertyLoading,
    },
  ] = useUpdatePropertyZonePolicyOverrideMutation();

  useEffect(() => {
    if (unitError) {
      Notifier.error(
        `Unable to update unit zone policy override: ${unitError.message}`,
      );
      setPropagating(false);
    } else if (unitData && onZonePolicySelected) {
      // setPropagating(false);
      Notifier.success('Unit zone policy override updated and propagated');
      onZonePolicySelected(selectedZonePolicyId);
    }
  }, [unitData, unitError]);

  useEffect(() => {
    if (propertyError) {
      Notifier.error(
        `Unable to update property zone policy override: ${propertyError.message}`,
      );
      setPropagating(false);
    } else if (propertyData && onZonePolicySelected) {
      // setPropagating(false);
      Notifier.success('Property zone policy override updated and propagated');
      onZonePolicySelected(selectedZonePolicyId);
    }
  }, [propertyData, propertyError]);

  const navigator = useNavigate();
  const myPolicies: Partial<ZonePolicy>[] = availablePolicies?.filter(
    (policy) => policy !== null,
  ) as Partial<ZonePolicy>[];
  const [assignableZonePolicies, setAssignableZonePolicies] = React.useState<
    Partial<ZonePolicy>[]
  >([]);
  const [selectedZonePolicy, setSelectedZonePolicy] = React.useState<
    Partial<ZonePolicy>
  >({});
  const [selectedZonePolicyId, setSelectedZonePolicyId] =
    React.useState<string>('');
  const [verifierReady, setVerifierReady] = React.useState<boolean>(false);

  useEffect(() => {
    const assignablePolicies: Partial<ZonePolicy>[] = [
      ...myPolicies
        .filter((policy) => policy._id !== targetPolicyId)
        .map((policy) => {
          return {
            _id: policy._id,
            name: policy.name,
            rules: policy.rules,
          };
        }),
    ];
    if (targetPolicyId) {
      if (targetPolicyId !== '0') {
        const propPolicy = availablePolicies?.find(
          (policy) => policy?._id === targetPolicyId,
        );
        if (propPolicy) {
          assignablePolicies.push({
            _id: propPolicy._id,
            name: forProperty
              ? propPolicy.name
              : `Use property default policy: ${
                  propPolicy.name ?? 'Unnamed Zone Policy'
                }`,
            rules: propPolicy.rules,
          });
        } else {
          Notifier.error('Zone Policy retrieval resulted in inconsistent set.');
        }
      }
    }
    if (!targetPolicyId || targetPolicyId === '0' || forProperty) {
      assignablePolicies.push({
        _id: '0',
        name: 'Use system default Zone Policy',
        rules: defaultSystemZonePolicy,
      });
    }
    setAssignableZonePolicies(assignablePolicies);
    setSelectedZonePolicyId(value ?? '0');
    // setSelectedZonePolicy(
    //   assignablePolicies.find(
    //     (policy) => policy._id === selectedZonePolicyId,
    //   ),
    // );
  }, [availablePolicies]);

  useEffect(() => {
    //if (selectedZonePolicyId) {
    setSelectedZonePolicy(
      (assignableZonePolicies || []).find(
        (policy) => policy._id === selectedZonePolicyId,
      ) || {},
    );
    //}
  }, [selectedZonePolicyId]);

  const [verifierParams, setVerifierParams] =
    React.useState<ZonePolicyUpdateVerification>();

  useEffect(() => {
    setVerifierParams({
      _id: selectedZonePolicyId ?? '',
      properties: propertyId !== undefined ? [propertyId] : undefined,
      unitId: unitId || undefined,
    });
  }, [selectedZonePolicyId]);

  return (
    <BorderedSection
      title="Zone Policy Override"
      style={{ marginBottom: '10px' }}
    >
      {propagating && selectedZonePolicy ? (
        <BulkUpdateStatus
          title="Applying Zone Policy ..."
          initiator={selectedZonePolicy}
          completeCallback={() => {
            if (onZonePolicySelected) {
              onZonePolicySelected(selectedZonePolicyId ?? '0');
            }
            setPropagationComplete(true);
          }}
          actions={[
            {
              label: returnLabel,
              handler: () => navigator(returnPath),
            },
            {
              label: 'Return to editing',
              handler: () => setPropagating(false),
            },
          ]}
        />
      ) : verifying && selectedZonePolicy?._id && verifierParams ? (
        <div>
          <VerifyZonePolicyUpdate
            values={verifierParams}
            force={forceUpdate}
            dataReadyCallback={setVerifierReady}
          />
          <div
            style={
              verifying
                ? {
                    marginTop: '10px',
                  }
                : {}
            }
          >
            {verifierReady ? (
              <button
                style={{ marginLeft: '10px' }}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (forProperty) {
                    if (propertyId) {
                      if (selectedZonePolicy?._id) {
                        setPropagating(true);
                        setVerifying(false);
                        await updateProperty({
                          variables: {
                            id: propertyId,
                            zonePolicyId: selectedZonePolicy._id,
                            force: forceUpdate,
                          },
                        });
                      } else {
                        Notifier.warn(
                          'Unable to apply zone policy override. No zone policy selected.',
                        );
                      }
                    } else {
                      Notifier.warn(
                        'Unable to update zone policy selection. No property specified.',
                      );
                    }
                  } else {
                    if (unitId) {
                      if (selectedZonePolicy?._id) {
                        setPropagating(true);
                        setVerifying(false);
                        await updateUnit({
                          variables: {
                            id: unitId,
                            zonePolicyId: selectedZonePolicy._id,
                            force: forceUpdate,
                          },
                        });
                      } else {
                        Notifier.warn(
                          'Unable to apply zone policy override. No zone policy selected.',
                        );
                      }
                    } else {
                      Notifier.warn(
                        'Unable to update zone policy selection. No unit specified.',
                      );
                    }
                  }
                }}
              >
                Commit Zone Policy Override selection and update Zones
              </button>
            ) : null}
            <button
              style={{ marginLeft: '10px' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setVerifying(false);
                setVerifierReady(false);
              }}
            >
              Return to Select Zone Policy Override editor
            </button>
            <button
              style={{
                backgroundColor: forceUpdate ? 'red' : 'inherit',
                marginLeft: '10px',
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setVerifying(false);
                setVerifierReady(false);
                setForceUpdate(!forceUpdate);
              }}
            >
              Force Apply Zone Policy
            </button>
          </div>
        </div>
      ) : (
        <div>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              {readOnly
                ? 'Selected Zone Policy'
                : 'Select a Zone Policy that will override the default Policy for this Unit'}
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={selectedZonePolicyId}
              defaultValue={value ?? ''}
              onChange={(e) => {
                if (!readOnly) {
                  setSelectedZonePolicyId(e.target.value);
                } else {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              name="radio-buttons-group"
            >
              {assignableZonePolicies.map((policy) => (
                <FormControlLabel
                  key={policy._id}
                  value={policy._id}
                  control={<Radio />}
                  label={policy.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {selectedZonePolicy ? (
            <>
              <hr />
              <BorderedSection
                title={`Selected Zone Policy`}
                style={{ marginTop: '20px' }}
              >
                <Box
                  sx={{
                    justifyContent: 'center',
                    padding: '6px',
                    marginTop: '-4px',
                  }}
                >
                  <JSONInput
                    id="zone-policy-json-editor"
                    placeholder={
                      (selectedZonePolicy.rules?.length ?? 0) > 0
                        ? removeTypeNames(selectedZonePolicy.rules)
                        : { rules: 'No Rules Defined' }
                    }
                    height="100%"
                    width="100%"
                    viewOnly={true}
                    style={{
                      body: { borderRadius: '4px' },
                      contentBox: {
                        borderTopRightRadius: '4px',
                        borderBottomRightRadius: '4px',
                      },
                      labelColumn: {
                        backgroundColor: 'rgb(6 106 27)',
                        textAlign: 'right',
                        paddingRight: '4px',
                        borderTopLeftRadius: '4px',
                        borderBottomLeftRadius: '4px',
                      },
                    }}
                    locale={locale}
                  />
                </Box>
              </BorderedSection>
            </>
          ) : null}
        </div>
      )}
      <div
        style={
          !verifying
            ? {
                marginTop: '10px',
              }
            : {}
        }
      >
        {(value !== selectedZonePolicyId || forceUpdate) && !verifying ? (
          <Button
            variant="contained"
            color="secondary"
            sx={{ color: '#fff', marginLeft: '10px' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setVerifying(true);
            }}
          >
            Verify Changes
          </Button>
        ) : null}
        {!verifying && !propagating ? (
          <button
            style={{
              backgroundColor: forceUpdate ? 'red' : 'inherit',
              marginLeft: '10px',
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setVerifying(false);
              setVerifierReady(false);
              setForceUpdate(!forceUpdate);
            }}
          >
            Force Apply Zone Policy
          </button>
        ) : null}
      </div>
    </BorderedSection>
  );
};
