import React, { ChangeEvent } from 'react';

import { useFormik } from 'formik';

import { TextField, useMediaQuery, useTheme } from '@mui/material';

import { FormValues } from '../userProfile/types/userProfileFormValues';

import { formatPhoneNumber } from '../../../common/helpers';
import { capitalize } from '../../../helpers/stringUtils';

export const FormikPhoneInput = ({
  formik,
  label,
  fieldName,
}: {
  formik: ReturnType<typeof useFormik<FormValues>>;
  label?: string;
  fieldName: keyof FormValues;
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMobileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatPhoneNumber(event.target.value);

    formik.setFieldTouched(
      fieldName,
      formatPhoneNumber(`${formik.initialValues[fieldName]}`) !==
        formattedValue,
    );
    formik.handleChange({
      target: {
        name: fieldName,
        value: formattedValue,
      },
    });
  };

  return (
    <div>
      <TextField
        fullWidth // ={isSmall}
        sx={{
          display: 'flex',
          flexDirection: isSmall ? 'row' : 'row',
          flex: isSmall ? 'unset' : '1 1 auto',
          marginBottom: isSmall ? '8px' : '16px',
        }}
        autoFocus
        id={fieldName}
        name={fieldName}
        placeholder={`Enter ${label ?? capitalize(fieldName)} Phone Number`}
        label={`${label ?? capitalize(fieldName)} Phone Number`}
        value={formik.values[fieldName]}
        onChange={handleMobileChange}
        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
        // helperText={formik.touched.mobile && formik.errors.mobile}
      />
      {formik.touched[fieldName] && formik.errors[fieldName] ? (
        <div
          style={{
            color: 'red',
            fontSize: '12px',
            marginTop: '-10px',
            marginBottom: '10px',
          }}
        >
          {`${formik.errors[fieldName]}`}
        </div>
      ) : null}
    </div>
  );
};
