import React from 'react';

export const PrivacyPolicy = () => (
  <div>
    <div>
      <h1>Privacy Policy</h1>
    </div>

    <div>
      We do not share your information with anyone. All data you provide to us
      is used only for internal processing and analysis.
    </div>
  </div>
);
