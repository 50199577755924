/**
 * PropertyDetail
 * - parent component for several property detail view options
 * - select view using drop-down (xs) or tabs (sm & up)
 * - provide data as props to child components
 * - TODO: Calvin: Add functionality to allow deep-links to detail tabs using ? or #
 */

import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAuthenticator } from '../../auth/AuthenticationContext';
import { Notifier } from '../../system/services/notificationManager';
import { useInjectableComponents } from '../../system/services/injectableComponentsManager';

import { Card, useMediaQuery, useTheme } from '@mui/material';

import {
  PropertyQuery,
  Unit,
  usePropertyQuery,
} from '../../../types/generated-types';

import { DetailHeader } from './ui/detail-header';
import { DetailPanel } from './ui/detail-panel';
import FullWidthLoadingSkeleton from '../shared/fullWidthLoadingSkeleton';
import { viewOptionsMap, ViewOption } from './ui/property-detail-selector';

export type MyProperty = PropertyQuery['property'];

export function PropertyDetail() {
  const navigate = useNavigate();
  const { user } = useAuthenticator();
  const { pathname } = useLocation();

  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const { data, error } = usePropertyQuery({ variables: { _id: id } });
  const [selectedProperty, setSelectedProperty] = useState<MyProperty>();
  const [selectedUnit, setSelectedUnit] = useState<Partial<Unit>>();
  const [selectedViewOption, setSelectedViewOption] = useState<ViewOption>(
    viewOptionsMap.Summary,
  );
  const { setTitle } = useInjectableComponents();

  useEffect(() => {
    if (selectedProperty?.title) {
      setTitle(selectedProperty.title);
    }
    return () => {
      setTitle(undefined);
    };
  }, [selectedProperty, setTitle]);

  useEffect(() => {
    if (error) {
      Notifier.error(
        'There was a problem fetching the property information: ',
        error,
      );
    }
  }, [error]);

  useEffect(() => {
    if (pathname && user) {
      const parts = pathname.split('/');
      const tab = parts[3]?.replace('-', '');
      if (tab === undefined || tab === '') {
        setTimeout(() => {
          navigate(`./${user?.isInstaller ? 'installer' : 'summary'}`, {
            replace: true,
          });
        });
      } else {
        setSelectedViewOption(
          Object.values(viewOptionsMap).find(
            (option) => option.type.toLowerCase() === tab,
          ) || viewOptionsMap.Summary,
        );
      }
    }
  }, [pathname, user, navigate]);

  useEffect(() => {
    const thisProperty = data?.property;
    if (thisProperty) {
      setSelectedProperty(thisProperty);
    }
  }, [data]);

  useEffect(() => {
    if (selectedProperty && pathname) {
      const pathParts = pathname.split('/');
      const unitId = pathParts[4];
      if (unitId) {
        const selectedUnit = selectedProperty.units.find(
          (aUnit) => aUnit?._id === unitId,
        );
        if (selectedUnit) {
          setSelectedUnit(selectedUnit);
        }
      } else {
        setSelectedUnit(undefined);
      }
    }
  }, [selectedProperty, pathname]);

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return error ? (
    <div>
      An error was encountered while loading property with id {id}:{' '}
      {error.message}.
    </div>
  ) : !selectedProperty ? (
    <FullWidthLoadingSkeleton />
  ) : (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'top',
        flex: 1,
        alignItems: 'stretch',
        overflowY: 'scroll',
        background: 'inherit',
        margin: isSmall ? '0px' : '20px',
        '& .MuiCardHeader-root': {
          padding: '4px 10px !important',
          borderBottom: '1px solid darkgray',
          boxShadow: '0px 8px 6px -6px rgba(0, 0, 0, 0.35)',
        },
      }}
      elevation={4}
    >
      <DetailHeader property={selectedProperty} unit={selectedUnit} />
      <DetailPanel
        theme={theme}
        viewOption={selectedViewOption}
        data={selectedProperty}
      />
    </Card>
  );
}
