/* MUI */
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { Button, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
/* MENU */
import { useInjectableComponents } from '../../../system/services/injectableComponentsManager';
import {
  Unit,
  useUnitAndPropertyBasicsQuery,
} from '../../../../types/generated-types';
/* ICONS */
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import VisibilityIcon from '@mui/icons-material/Visibility';

export function UnitDetailHeader({
  unitLoading,
  propertyLoading,
  propertyId,
  unitId,
}: {
  unitLoading: boolean;
  propertyLoading: boolean;
  propertyId: string | undefined;
  unitId: string | undefined;
}) {
  const {
    data: unitData,
    error,
    loading,
  } = useUnitAndPropertyBasicsQuery({
    variables: { id: unitId ?? '' },
  });

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    subtitle,
    navigateToSubtitlePath,
    setSubtitleActionWidget,
    subtitleActionWidget,
  } = useInjectableComponents();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (unitData?.unitById) {
      const pathComponent = pathname.split('/').slice(0, 6);
      setSubtitleActionWidget({
        widget: (
          <IconButton
            aria-label="Edit Unit"
            onClick={() =>
              navigate(
                `/properties/${propertyId}/units/${unitId}/${
                  pathComponent[5] === 'edit-unit' ? 'view-unit' : 'edit-unit'
                }`,
              )
            }
          >
            {pathComponent[5] === 'edit-unit' ? (
              <VisibilityIcon fontSize="small" />
            ) : (
              <EditIcon fontSize="small" />
            )}
          </IconButton>
        ),
        permit: {
          action: pathComponent[5] === 'edit-unit' ? 'view' : 'update',
          subject: unitData.unitById as Unit,
        },
      });

      return () => setSubtitleActionWidget(undefined);
    }
  }, [unitData, pathname, propertyId, unitId]);

  return (
    <CardHeader
      avatar={
        <Avatar
          sx={{
            backgroundColor: '#fff',
            fontSize: '60px',
          }}
          aria-label="Unit Icon"
        >
          <HomeIcon color="secondary" />
        </Avatar>
      }
      action={subtitleActionWidget}
      title={
        unitLoading || propertyLoading ? (
          <Skeleton />
        ) : (
          <Button
            sx={{
              display: 'flex',
              justifyContent: 'start',
              textTransform: 'none',
              width: '100%',
              marginBottom: '0px',
            }}
            onClick={() =>
              navigateToSubtitlePath(
                `/properties/${propertyId}/units/${unitId}`,
              )
            }
          >
            <Typography
              variant="h3"
              sx={{
                color: 'rgba(0, 0, 0, 0.87)',
              }}
              noWrap
            >
              {subtitle ?? ''}
            </Typography>
          </Button>
        )
      }
      spacing={0}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 'unset',
        padding: '4px',
        borderBottom: '1px solid darkgray',
        '& .MuiCardHeader-content': {
          maxWidth: isSmall ? 'unset' : 'calc(100vw - 150px)',
        },
      }}
    />
  );
}
