import * as React from 'react';
import { useUserDetailBreadcrumbQuery } from '../../../types/generated-types';

export function UserDetailBreadcrumb({ id }: { id?: string }) {
  const { data } = useUserDetailBreadcrumbQuery({
    variables: { id: id || '' },
  });

  return (
    <span>{`${
      id
        ? data?.userById?.name || data?.userById?.email || 'un-named user'
        : 'New User'
    }${
      !(data?.userById?.name || data?.userById?.email) ||
      data?.userById?.state === 'active'
        ? ''
        : ' (' + data?.userById?.state + ')'
    }`}</span>
  );
}
