import Avatar from '@mui/material/Avatar';
import DoneIcon from '@mui/icons-material/DoneRounded';
import NotDoneIcon from '@mui/icons-material/CancelRounded';
import React from 'react';

export function InstallationStatusIndicator({ isDone }: { isDone: boolean }) {
  return isDone ? (
    <Avatar
      sx={{
        backgroundColor: 'green',
        color: 'white',
        mt: 0.5,
        mr: 0.5,
        fontSize: 'xx-large',
      }}
    >
      <DoneIcon fontSize="inherit" />
    </Avatar>
  ) : (
    <Avatar
      sx={{
        backgroundColor: 'red',
        color: 'white',
        mt: 0.5,
        mr: 0.5,
        fontSize: 'xxx-large',
      }}
    >
      <NotDoneIcon fontSize="inherit" />
    </Avatar>
  );
}
