import React, {
  createContext,
  FC,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { Actions, Subjects } from '../../auth/types/ability';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthorizer } from '../../auth/AuthorizationContext';
import { refreshActionMenuItem } from '../../ui/navigation/base-context-menu';
import { useSystemConnection } from '../ConnectionManager';

export interface ConfirmableAction {
  title: string;
  prompt: string;
  actionLabel: string;
  cancelLabel: string;
  action: () => Promise<void>;
  customContent?: JSX.Element;
}

export type PathTransformation = (path: string) => string;
export type VisibleTransformation = (path: string) => boolean;
export type PermitCriterion = { action: Actions; subject: Subjects };
export type PermitCriteria = Actions | PermitCriterion;

export interface BottomMenuItem {
  id: string;
  icon?: React.ReactElement;
  label: string;
  navTarget?: PathTransformation | string;
  isVisible?: boolean | VisibleTransformation;
  action?: () => void;
  confirmableAction?: ConfirmableAction;
  permit?: PermitCriteria;
}

export interface BottomMenuItemGroup {
  showDivider?: boolean;
  label?: string;
  items: BottomMenuItem[];
}

export type BottomMenuItems = Array<BottomMenuItemGroup>;

export interface BottomMenu {
  items: BottomMenuItems;
}

export type PathTransformer = (path: string) => string;
export type PathNavigator = (fallback: string | undefined) => void;

export interface IInjectableComponents {
  title: string | JSX.Element | undefined;
  subtitle: string | JSX.Element | undefined;
  subtitleActionWidget: JSX.Element | undefined;
  primaryNavigationWidget: JSX.Element | undefined;
  secondaryNavigationMenu?: BottomMenu | undefined;
  setTitle(title: string | JSX.Element | undefined): void;
  setTitlePath(path: string | PathTransformer | undefined): void;
  navigateToTitlePath: PathNavigator;
  setSubtitle(subtitle: string | JSX.Element | undefined): void;
  setSubtitlePath(path: string | PathTransformer | undefined): void;
  setSubtitleActionWidget(
    actionWidget: IInjectableActionWidget | undefined,
  ): void;
  navigateToSubtitlePath(fallbackPath: string | undefined): void;
  setPrimaryBottomNavigationWidget(widget: JSX.Element | undefined): void;
  setContextMenuItems(
    items: BottomMenuItems | undefined,
    subject?: Subjects | undefined,
  ): void;
}

export interface IInjectableActionWidget {
  widget: JSX.Element | undefined;
  permit?: PermitCriterion | undefined;
}

export interface IInjectableComponentsManager extends IInjectableComponents {
  setTitle(title: string | JSX.Element | undefined): void;
  setTitlePath(path: string | PathTransformer | undefined): void;
  navigateToTitlePath(fallbackPath: string | undefined): void;
  setSubtitle(subtitle: string | JSX.Element | undefined): void;
  setSubtitlePath(path: string | PathTransformer | undefined): void;
  setSubtitleActionWidget(
    actionWidget: IInjectableActionWidget | undefined,
  ): void;
  navigateToSubtitlePath(fallbackPath: string | undefined): void;
  setPrimaryBottomNavigationWidget(widget: JSX.Element): void;
  setContextMenuItems(
    items: BottomMenuItems | undefined,
    subject: Subjects | undefined,
  ): void;
}

const InjectableComponentsContext = createContext<IInjectableComponents>({
  title: undefined,
  subtitle: undefined,
  subtitleActionWidget: undefined,
  primaryNavigationWidget: undefined,
  secondaryNavigationMenu: undefined,
  setTitle: () => {},
  setTitlePath: () => {},
  navigateToTitlePath: (_path: string | undefined) => {},
  setSubtitle: () => {},
  setSubtitlePath: () => {},
  setSubtitleActionWidget: () => {},
  navigateToSubtitlePath: () => {},
  setPrimaryBottomNavigationWidget: () => {},
  setContextMenuItems: () => {},
});

InjectableComponentsContext.displayName = 'InjectableComponentsContext';

interface InjectableComponentsProviderProps {
  children: React.ReactNode;
}

export const InjectableComponentsProvider: FC<
  InjectableComponentsProviderProps
> = ({ children }) => {
  const [primaryNavigationWidget, setPrimaryNavigationWidget] =
    useState<JSX.Element>();

  const { client } = useSystemConnection();

  const [secondaryNavigationMenu, setSecondaryNavigationMenu] = useState<
    BottomMenu | undefined
  >({
    items: [refreshActionMenuItem(client)],
  });

  const [subtitleActionWidget, _setSubtitleActionWidget] =
    useState<JSX.Element>();

  const { pathname } = useLocation();
  const { cannot } = useAuthorizer();
  const navigate = useNavigate();

  const setSubtitleActionWidget = useCallback(
    (injectableWidget: IInjectableActionWidget | undefined) => {
      if (injectableWidget) {
        const { widget, permit } = injectableWidget;
        if (widget) {
          if (permit) {
            const { action, subject } = permit;
            if (action) {
              if (cannot(action, subject ?? 'Property')) {
                _setSubtitleActionWidget(undefined);
              } else {
                _setSubtitleActionWidget(widget);
              }
            }
          } else {
            _setSubtitleActionWidget(widget);
          }
        } else {
          _setSubtitleActionWidget(undefined);
        }
      } else {
        _setSubtitleActionWidget(undefined);
      }
    },
    [cannot],
  );

  const filteredMenuItem = useCallback(
    (item: BottomMenuItem | undefined, subject: Subjects = 'Property') => {
      if (item) {
        if (typeof item.isVisible === 'function') {
          if (!item.isVisible(pathname)) {
            return null;
          }
        } else if (typeof item.isVisible === 'boolean') {
          if (!item.isVisible) {
            return null;
          }
        }
        if (item.permit) {
          if (
            typeof item.permit === 'object' &&
            item.permit instanceof Object &&
            'action' in item.permit
          ) {
            if ('subject' in item.permit && item.permit.subject) {
              if (cannot(item.permit.action, item.permit.subject)) {
                return null;
              }
            } else {
              if (cannot(item.permit.action, subject)) {
                return null;
              }
            }
          } else if (typeof item.permit === 'string') {
            if (cannot(item.permit, subject)) {
              return null;
            }
          }
        }
        let action = item.action;
        if (!action) {
          if (item.navTarget) {
            const navPath =
              typeof item.navTarget === 'function'
                ? item.navTarget(pathname)
                : item.navTarget;
            action = () => navigate(navPath);
          }
        }

        return {
          id: item.id,
          icon: item.icon,
          label: item.label,
          action: action,
          confirmableAction: item.confirmableAction,
        };
      }
      return null;
    },
    [cannot, navigate, pathname],
  );

  const setContextMenuItems = useCallback(
    (items: BottomMenuItems | undefined, subject?: Subjects | undefined) => {
      const filteredItems: BottomMenuItemGroup[] = [];
      (items ?? []).forEach((item) => {
        const subItems: BottomMenuItem[] = [];
        item.items.forEach((subItem) => {
          const filteredItem = filteredMenuItem(subItem, subject);
          if (filteredItem) {
            subItems.push(filteredItem);
          }
        });
        if (subItems.length > 0) {
          filteredItems.push({
            ...item,
            items: subItems,
          });
        }
      });

      filteredItems.push(refreshActionMenuItem(client));

      setSecondaryNavigationMenu({
        items: filteredItems,
      });
    },
    [filteredMenuItem],
  );

  const [title, setTitle] = useState<string | JSX.Element>();
  const [titlePath, setTitlePath] = useState<
    string | PathTransformer | undefined
  >();

  const [subtitle, setSubtitle] = useState<string | JSX.Element>();
  const [subtitlePath, setSubtitlePath] = useState<
    string | PathTransformer | undefined
  >();

  const navigateToTitlePath = useCallback<PathNavigator>(
    (fallbackPath: string | undefined) => {
      if (titlePath) {
        if (typeof titlePath === 'function') {
          const newPath = titlePath(pathname) ?? fallbackPath ?? '/properties';
          navigate(newPath);
        } else if (typeof titlePath === 'string') {
          navigate(titlePath ?? fallbackPath ?? '/properties');
        }
      } else {
        navigate(fallbackPath ?? '/properties');
      }
    },
    [navigate, pathname, titlePath],
  );

  const navigateToSubtitlePath = useCallback(
    (fallbackPath: string | undefined) => {
      if (subtitlePath) {
        if (typeof subtitlePath === 'function') {
          const newPath =
            subtitlePath(pathname) ?? fallbackPath ?? '/properties';
          navigate(newPath);
        } else if (typeof subtitlePath === 'string') {
          navigate(subtitlePath ?? fallbackPath ?? '/properties');
        }
      } else {
        navigate(fallbackPath ?? '/properties');
      }
    },
    [navigate, pathname, subtitlePath],
  );

  const value: IInjectableComponents = useMemo(() => {
    return {
      title,
      subtitle,
      subtitleActionWidget,
      primaryNavigationWidget,
      secondaryNavigationMenu,
      setTitle: setTitle,
      setTitlePath: setTitlePath,
      navigateToTitlePath: navigateToTitlePath,
      setSubtitle: setSubtitle,
      setSubtitlePath: setSubtitlePath,
      setSubtitleActionWidget: setSubtitleActionWidget,
      navigateToSubtitlePath: navigateToSubtitlePath,
      setPrimaryBottomNavigationWidget: setPrimaryNavigationWidget,
      setContextMenuItems: setContextMenuItems,
    };
  }, [
    primaryNavigationWidget,
    secondaryNavigationMenu,
    setContextMenuItems,
    subtitle,
    subtitleActionWidget,
    setSubtitle,
    setSubtitlePath,
    setSubtitleActionWidget,
    navigateToSubtitlePath,
    title,
    setTitle,
    setTitlePath,
    navigateToTitlePath,
  ]);

  return (
    <InjectableComponentsContext.Provider value={value}>
      {children}
    </InjectableComponentsContext.Provider>
  );
};

export const useInjectableComponents = (): IInjectableComponents => {
  const context = useContext(InjectableComponentsContext);
  if (context === undefined) {
    throw new Error(
      'useInjectableComponents must be used within a InjectableComponentsProvider',
    );
  }
  return context;
};
