import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AcceptNewPassword } from '../passwords/AcceptNewPassword';
import { AuthenticationPanel } from '../components/AuthenticationPanel';
import { AwaitResetToken } from '../passwords/AwaitResetToken';
import { IdentifyUser } from '../passwords/IdentifyUser';
import { IAwaitParams } from '../types/resetPassword';
import { decodeEmail } from '../helpers/emailSanitizer';

const defaultParams: IAwaitParams = { email: undefined, instructions: [] };

/**
 * This component acts as the container and coordinator for the reset password
 * workflow, displaying the various workflow components as appropriate.
 * @constructor
 */
export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const [awaitParams, setAwaitParams] = useState<IAwaitParams>(defaultParams);

  const token = searchParams.get('token');
  const encodedEmail = searchParams.get('email') || undefined;
  const email = encodedEmail
    ? decodeEmail(encodedEmail) ?? undefined
    : undefined;

  return (
    <AuthenticationPanel title="Reset Password">
      {awaitParams.email ? (
        <AwaitResetToken {...awaitParams} />
      ) : token ? (
        <AcceptNewPassword />
      ) : (
        <IdentifyUser initialEmail={email} onAwaitParams={setAwaitParams} />
      )}
    </AuthenticationPanel>
  );
};
