import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';

interface QRScannerProps {
  show?: boolean;
  error?: string;
}
export function QRScanner(props: QRScannerProps) {
  const { show, error } = props;
  const size = 200;
  return show ? (
    error ? (
      <Typography
        sx={{
          color: alpha('#e74c3c', 1),
          backgroundColor: alpha('#ffffff', 1),
        }}
        variant={'caption'}
      >
        {error}
      </Typography>
    ) : (
      <Box
        sx={{
          opacity: 0,
          position: 'relative',
          display: 'inline-flex',
          width: `${size}px`,
          height: `${size}px`,
        }}
      ></Box>
    )
  ) : (
    <></>
  );
}
