import React, { useState } from 'react';
import {
  BulkConfigStatus,
  useBulkConfigProgressUpdateSubscription,
} from '../../../types/generated-types';
import BorderedSection from './borderedSection';
import { ExpandSectionButton } from '../devices/shared-ui/alerts-info';

import AlertIcon from '@mui/icons-material/Warning';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { ApolloError } from '@apollo/client';
import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
  useTheme,
} from '@mui/material';

export const BulkUpdateStatus = <
  T extends { _id?: string; __typename?: string },
>({
  title,
  initiator,
  completeCallback,
  errorCallback,
  actions,
}: {
  title: string;
  initiator: T;
  completeCallback: () => void;
  errorCallback?: () => void;
  actions: { label: string; handler: <Fn>(cb?: Fn) => void }[];
}) => {
  const [expanded, setExpanded] = useState(false);
  const [propagating, setPropagating] = useState(false);
  const [error, setError] = useState<ApolloError>();
  const [propagationStatus, setPropagationStatus] =
    useState<BulkConfigStatus>();

  useBulkConfigProgressUpdateSubscription({
    fetchPolicy: 'no-cache',
    variables: {
      initiatorId: initiator._id ?? '',
      initiatorType: initiator.__typename ?? '',
    },
    onData: (result) => {
      const status = result.data.data?.bulkConfigProgressUpdate;
      if (status) {
        if (!propagating) {
          setPropagating(true);
        }
        setPropagationStatus(status);
        if (status.isComplete) {
          console.log('[bulk-update-status] has finished');
          // completeCallback();
        }
      }
    },
    onError: (error) => {
      console.warn('[bulk-update-status] error', error);
      setError(error);
    },
  });

  const theme = useTheme();

  const textStyle = {
    progressStatusText: {
      margin: '.4em 0em',
    },
  };

  return (
    <div>
      <BorderedSection title={title}>
        {error ? (
          <>
            <Typography variant={'h3'}>An Error Occurred</Typography>
            <Typography>{error.message}</Typography>
            {errorCallback && (
              <Button
                variant={'contained'}
                color={'error'}
                onClick={errorCallback}
              >
                Return To Setpoint Limit Profile Editor
              </Button>
            )}
          </>
        ) : (
          propagationStatus && (
            <div>
              <Stack
                useFlexGap
                spacing={2}
                alignItems={'center'}
                justifyContent={'space-between'}
                direction={'row'}
              >
                <Typography variant={'h3'}>
                  {(propagationStatus.percentProcessed ?? 0) < 100
                    ? 'Propagation in progress...'
                    : 'Propagation complete!'}
                </Typography>
                <Box>
                  <CircularProgress
                    variant={'determinate'}
                    sx={{ color: theme.palette.info.light }}
                    value={propagationStatus.percentProcessed ?? 0}
                  />
                </Box>
              </Stack>
              <p style={textStyle.progressStatusText}>
                Completed:{' '}
                <span style={{ color: theme.embue.good }}>
                  {propagationStatus.completedCount}
                </span>
              </p>
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <p style={textStyle.progressStatusText}>
                  Failed:{' '}
                  <span style={{ color: theme.embue.bad }}>
                    {propagationStatus.errorCount}{' '}
                  </span>
                </p>
                {(propagationStatus.errorCount ?? 0) > 0 ? (
                  <ExpandSectionButton
                    expand={expanded}
                    buttonLabel="Retry"
                    onClick={() => {
                      setExpanded(!expanded);
                    }}
                  >
                    <AlertIcon
                      sx={{ color: 'rgba(255, 0, 0, 1)' }}
                      fontSize="inherit"
                    />
                  </ExpandSectionButton>
                ) : null}
              </div>
              <Collapse in={expanded} timeout="auto">
                <BorderedSection title="Processing Errors">
                  <div>
                    {propagationStatus.errors.map((error, index) =>
                      error ? (
                        <div
                          key={error.deviceId}
                          style={
                            index < propagationStatus.errors.length - 1
                              ? {
                                  borderBottom: '1px solid lightgray',
                                  paddingBottom: '6px',
                                  marginBottom: '6px',
                                }
                              : {}
                          }
                        >
                          <p key={`${error.deviceId}_Header`}>
                            Device Name: {error.deviceName} ({error.deviceId})
                          </p>
                          <p key={`${error.deviceId}_Body`}>
                            Error: {error.message}
                          </p>
                        </div>
                      ) : null,
                    )}
                  </div>
                </BorderedSection>
              </Collapse>
              <p style={textStyle.progressStatusText}>
                Total:{' '}
                <span style={{ color: theme.palette.info.light }}>
                  {propagationStatus.totalCount}
                </span>
              </p>
              {(propagationStatus?.percentProcessed ?? 0) < 100 ? (
                <LinearProgress color="info" />
              ) : null}
              <Stack
                direction={'row'}
                spacing={1}
                style={{
                  justifyContent: 'flex-start',
                  marginTop: '10px',
                  borderTop: '1px solid gray',
                  paddingTop: '10px',
                }}
              >
                {(propagationStatus.percentProcessed ?? 0) >= 100
                  ? actions.map(({ label, handler }) => (
                      <Button
                        variant={'contained'}
                        size={'small'}
                        color={'secondary'}
                        key={label}
                        onClick={handler}
                      >
                        {label}
                      </Button>
                    ))
                  : null}
              </Stack>
            </div>
          )
        )}
      </BorderedSection>
    </div>
  );
};
