export interface Choice {
  id: string;
  label: string;
}

export const systemChoices: {
  operatingModes: Choice[];
  fanDutyCycleModes: Choice[];
  fanSpeedModes: Choice[];
  showFanButtons: Choice[];
  systemTypes: Choice[];
  lockoutLevels: Choice[];
  setpointLimitSources: Record<string, string>;
  scheduleLevels: Record<string, string>;
  scheduleCategories: Record<string, string>;
} = {
  operatingModes: [
    { id: 'Cool', label: 'Cool' },
    { id: 'Heat', label: 'Heat' },
    { id: 'Off', label: 'Off' },
    { id: 'Auto', label: 'Auto' },
  ],
  fanDutyCycleModes: [
    { id: 'Auto', label: 'Automatic' },
    { id: 'On', label: 'Always On' },
    { id: 'High', label: 'High [75%]' },
    { id: 'Medium', label: 'Medium [50%]' },
    { id: 'Moderate', label: 'Moderate [35%]' },
    { id: 'Low', label: 'Low [20%]' },
    { id: 'SmartAhu', label: 'Smart AHU' },
  ],
  fanSpeedModes: [
    { id: 'Auto', label: 'Automatic' },
    { id: 'High', label: 'High' },
    { id: 'Medium', label: 'Medium' },
    { id: 'Low', label: 'Low' },
  ],
  showFanButtons: [
    { id: 'true', label: 'Show' },
    { id: 'false', label: 'Hide' },
  ],
  systemTypes: [
    { id: 'HeatAndCool', label: 'Heat and Cool' },
    { id: 'HeatOnly', label: 'Heat Only' },
    { id: 'HeatPump', label: 'Heat Pump' },
    { id: 'HeatWithFan', label: 'Heat with Fan' },
    { id: 'CoolOnly', label: 'Cool Only' },
    { id: 'AlternateHeat', label: 'Alternate Heat' },
    { id: 'SmartAhu', label: 'Smart AHU' },
  ],
  lockoutLevels: [
    { id: '0', label: 'No Restrictions' },
    { id: '1', label: 'Mode/Setpoint' },
    { id: '2', label: 'Setpoint' },
    { id: '3', label: 'Locked' },
  ],
  setpointLimitSources: {
    custom: 'Using Custom Setpoint Limits',
    unit: 'Using Unit Setpoint Limits',
    property: 'Using Property Setpoint Limits',
    default: 'Using Default Setpoint Limits',
  },
  scheduleLevels: {
    custom: 'Custom Schedule',
    unit: 'Unit Schedule Template',
    property: 'Property Schedule Template',
    device: 'Device Schedule Template',
    default: 'Default Schedule Template',
  },
  scheduleCategories: {
    template: 'template-schedule',
    custom: 'custom-schedule',
    default: 'default-schedule',
  },
};
