import React from 'react';
import {
  BreadcrumbComponentType,
  BreadcrumbsRoute,
} from 'use-react-router-breadcrumbs';
import { UnitsMain } from '../features/units/units-main';
import { UnitsList } from '../features/units/units-list/units-list';
import { UnitDetail } from '../features/units/unit-detail';
import { UnitDetailBreadcrumb } from '../features/units/unit-detail-breadcrumb';
import UnitCharts from '../features/units/charts/unit-charts';
import { InstallerTools } from '../features/installer/installer-tools';
import { RouteObject } from 'react-router-dom';
import {
  IndexRouteObject,
  NonIndexRouteObject,
} from 'react-router/dist/lib/context';
import { Actions, SubjectTypes } from '../auth/types/ability';
import { IAuthorizedRoute } from '../auth/types/can';
import { UnitSchedule } from '../features/units/schedule/unit-schedule';

type ParentBreadcrumbsRoute<ParamKey extends string = string> = (RouteObject &
  IAuthorizedRoute) & {
  breadcrumb?: BreadcrumbComponentType<ParamKey> | string | null;
} & {
  children: (
    | (IndexRouteObject & {
        authAction?: Actions;
        authType: SubjectTypes;
        breadcrumb?: BreadcrumbComponentType<ParamKey> | string | null;
      })
    | (NonIndexRouteObject & {
        authAction?: Actions;
        authType?: SubjectTypes;
        breadcrumb?: BreadcrumbComponentType<ParamKey> | string | null;
      })
  )[];
};

export const unitRoutes: ParentBreadcrumbsRoute[] = [
  {
    path: 'installer',
    element: <UnitsMain />,
    children: [
      {
        index: true,
        element: <UnitsList installerView={true} />,
        authType: 'Unit',
        authAction: 'install',
      },
      {
        path: ':unitId',
        element: <UnitDetail installerView={true} />,
        authType: 'Unit',
        authAction: 'install',
      },
      {
        path: ':unitId/:deviceId',
        element: <InstallerTools />,
        authType: 'Unit',
        authAction: 'install',
      },
    ],
  },
  {
    path: 'units',
    element: <UnitsMain />,
    breadcrumb: () => <span>Units</span>,
    children: [
      {
        index: true,
        element: <UnitsList />,
        authType: 'Unit',
        authAction: 'index',
      },
      {
        path: ':unitId',
        element: <UnitDetail />,
        breadcrumb: ({ location }) => {
          return <UnitDetailBreadcrumb id={'unitDetailBreadcrumb'} />;
        },
        authType: 'Unit',
        authAction: 'view',
      },
      {
        path: ':unitId/charts',
        element: <UnitCharts />,
        breadcrumb: ({ location }) => {
          return <UnitDetailBreadcrumb id={'unitDetailBreadcrumb'} />;
        },
        authType: 'Unit',
        authAction: 'viewCharts',
      },
      {
        path: ':unitId/schedule',
        element: <UnitSchedule />,
        breadcrumb: ({ location }) => {
          return <UnitDetailBreadcrumb id={'unitDetailBreadcrumb'} />;
        },
        authType: 'Unit',
        authAction: 'viewSchedule',
      },
    ],
  },
];

export const unitBreadcrumbRoutes: (BreadcrumbsRoute & { state?: any })[] = [
  {
    index: true,
    element: <UnitsList />,
  },
  {
    path: '/properties/:id/units',
    element: <UnitsMain />,
    breadcrumb: () => <span>Units</span>,
    state: {
      ignore: [
        /^\/properties\/[a-zA-Z0-9]+\/units$/,
        /^\/properties\/[a-zA-Z0-9]+\/units\/$/,
      ],
    },
  },
  {
    path: '/properties/:id/installer',
    element: <UnitsMain />,
    breadcrumb: () => <span>Units Installer List</span>,
    state: {
      ignore: [
        /^\/properties\/[a-zA-Z0-9]+\/installer$/,
        /^\/properties\/[a-zA-Z0-9]+\/installer\/$/,
      ],
    },
  },
  {
    path: '/properties/:id/units/:unitId',
    element: <UnitDetail />,
    breadcrumb: ({
      match: {
        params: { unitId },
      },
    }) => <UnitDetailBreadcrumb id={unitId} />,
  },
  {
    path: '/properties/:id/units-installer/:unitId',
    element: <UnitDetail installerView={true} />,
    breadcrumb: ({
      match: {
        params: { unitId },
      },
    }) => <UnitDetailBreadcrumb id={unitId} />,
  },
  {
    path: '/properties/:id/units/:unitId/charts',
    element: <UnitCharts />,
  },
];
