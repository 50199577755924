import * as React from 'react';
import { useSetpointProfileDetailBreadcrumbQuery } from '../../../types/generated-types';

export function SetpointLimitProfileDetailBreadcrumb({ id }: { id?: string }) {
  const { data } = useSetpointProfileDetailBreadcrumbQuery({
    variables: { id: id || '' },
  });

  return (
    <span>{`${
      id
        ? data?.setpointProfileById?.name || 'un-named setpoint limit profile'
        : 'New Setpoint Limit Profile'
    }`}</span>
  );
}
