import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import {
  LOGGING_OUT_KEY,
  RECONNECT_TIMER_ID_KEY,
  RECONNECT_TIMER_OVERRIDE_ID_KEY,
  SERVER_CONNECTION_INITIALIZED_KEY,
} from './helpers/config';

import { LocalState } from './app/system/services/localStateManager';

import App from './App';
import { ReconnectAttemptTimer } from './app/system/models/reconnectAttemptTimer';
import { SessionStateManagerProvider } from './app/system/SessionStateManager';
import { Log, LogCategory } from './app/system/services/logger';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

Log.silly('rendering index', null, LogCategory.RENDERING);

LocalState.removeItems([
  RECONNECT_TIMER_OVERRIDE_ID_KEY,
  RECONNECT_TIMER_ID_KEY,
  SERVER_CONNECTION_INITIALIZED_KEY,
  LOGGING_OUT_KEY,
]);

LocalState.transitionConnectionState();

ReconnectAttemptTimer.reset();

root.render(
  <React.StrictMode>
    <SessionStateManagerProvider>
      <App />
    </SessionStateManagerProvider>
  </React.StrictMode>,
);
